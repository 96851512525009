<template>
  <div style="justify-content: center">
    <button
      class="btn btn-sm ml-2"
      v-b-tooltip="'Edit Token'"
      @click="editHandler()"
      :disabled="isTokenExpired()"
      v-if="
        hasAccess({
          permissions: [PERMISSIONS['SHARED-TOKEN'].UPDATE],
          checkAll: false,
        })
      "
    >
      <b-icon icon="pencil" variant="info"></b-icon></button
    ><button
      class="btn btn-sm ml-2"
      v-b-tooltip="'Copy Token'"
      @click="copyHandler()"
    >
      <b-icon icon="files" variant="success"></b-icon></button
    ><button
      class="btn btn-sm ml-2"
      v-b-tooltip="'Delete Token'"
      @click="deleteHandler()"
      v-if="
        hasAccess({
          permissions: [PERMISSIONS['SHARED-TOKEN'].DELETE],
          checkAll: false,
        })
      "
    >
      <b-icon icon="trash" variant="danger"></b-icon>
    </button>
  </div>
</template>

<script>
import {
  addSuccessSnackbarMessage,
  addSnackbarMessage,
} from "../../Common/_plugin/error-response";
import { hasAccess } from "@/modules/User/_utils/actions";
import PERMISSIONS from "@/modules/Common/permissions";
export default {
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    copyHandler() {
      const cellValue = this.params.data.token;
      navigator.clipboard
        .writeText(cellValue)
        .then(() => {
          addSuccessSnackbarMessage("Token copied successfully");
        })
        .catch((error) => {
          addSnackbarMessage(error, "Unable to copy content", true);
        });
    },
    goToAddTokenPage() {
      this.$router.push({ name: "AddToken" });
    },
    isTokenExpired() {
      const now = new Date();
      const expiryDate = new Date(this.params.data.expiredAt);
      return expiryDate < now;
    },
    editHandler() {
      if (this.isTokenExpired()) {
        return;
      }
      const id = this.params.data.id;
      this.$router.push({
        name: "EditToken",
        params: { tokenId: id, editMode: true },
      });
    },
    deleteHandler() {
      const id = this.params.data.id;
      this.params.handleDeleteToken(id);
    },
  },
};
</script>
