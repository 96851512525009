import Vue from "vue";
import "./registerServiceWorker";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import App from "./App";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import moment from "moment";

import "./config";

import "bootstrap/dist/js/bootstrap.min";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "./assets/js/bootstrap-tagsinput.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

import "../node_modules/bootstrap-select/dist/js/bootstrap-select";
import "../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css";

import "../node_modules/summernote/dist/summernote.css";
import "../node_modules/summernote/dist/summernote";
import "./assets/css/vue-bootstrap-datetimepicker.css";
import "./assets/css/bootstrap-tagsinput.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import "./filters/date-format-filter";
import CommanModal from "./modules/Common/_plugin/common-modal";
import VueCookie from "vue-cookie";
import Tooltip from "vue-directive-tooltip";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";
import VeeValidate from "vee-validate";
import { Validator } from "vee-validate";

import { dictionary } from "./modules/Common/_plugin/validation";
import VueSimpleAlert from "vue-simple-alert";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import numeral from "numeral";

import datePickerIcons from "../src/modules/Common/_utils/date-picker-icons";
import "./assets/css/utilities.scss";
import "./assets/css/responsive.css";
import "./assets/css/style.css";
import "./assets/css/default-styles.scss";
import CommonHeader from "./modules/Common/_components/common-header.vue";
import {
  textSanitize,
  urlSanitize,
} from "./modules/Common/_utils/sanitization";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(CommanModal);
Vue.use(VueCookie);
Vue.use(Vuelidate);
Vue.use(Tooltip);
Vue.use(VeeValidate);
Vue.use(VueSimpleAlert);
Vue.prototype.$datePickerIcons = datePickerIcons;
Vue.component("v-select", vSelect);
Vue.component("common-header", CommonHeader);

// safe content tag, use it instead of directly using v-html.
Vue.component("safe-content", {
  props: {
    content: {
      type: String,
      required: false,
    },
    tag: {
      type: String,
      default: "div",
    },
    className: {
      type: [Array, String],
      default: "",
    },
  },
  render: function (createElement) {
    const sanitizedContent = textSanitize(this.content);
    return createElement(this.tag, {
      class: this.className,
      domProps: {
        innerHTML: sanitizedContent,
      },
    });
  },
});

// safe link tag, use it instead of directly passing the link in href.
Vue.component("safe-link", {
  props: {
    link: {
      type: String,
      required: true,
    },
    className: {
      type: [String, Array],
      default: "",
    },
  },
  render: function (createElement) {
    const sanitizedLink = urlSanitize(this.link);
    return createElement(
      "a",
      {
        class: this.className,
        domProps: {
          href: sanitizedLink,
          target: "_blank",
          rel: "noopener noreferrer",
        },
      },
      this.$slots.default
    );
  },
});

Vue.config.productionTip = false;

Validator.localize(dictionary);

let filter = function (text, length, clamp) {
  clamp = clamp || "...";
  let node = document.createElement("div");
  node.innerHTML = text;
  let content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter("truncate", filter);

Vue.filter("formatNumber", function (value, pattern) {
  return numeral(value).format(pattern); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatDate", function (value) {
  return moment(value).format("DD/MM/YYYY"); // displaying other groupings/separators is possible, look at the docs
});
Vue.filter("formatDateTime", function (value) {
  return moment(value).format("DD/MM/YYYY hh:mma"); // displaying other groupings/separators is possible, look at the docs
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
