import auditHome from "../AuditTrail/index.vue";
import auditReport from "../AuditTrail/_components/audit-report.vue";
import auditReportDetail from "../AuditTrail/_components/audit-ver-details.vue";
import PERMISSIONS from "../Common/permissions";
export default {
  path: "report/audit-trail",
  component: auditHome,

  children: [
    {
      path: "",
      component: auditReport,
      name: "auditTrail",
      meta: {
        title: "Audit Trail",
        user: "employee",
        permissions: [PERMISSIONS["AUDIT-LOG"].MENU],
        checkAll: true,
      },
    },
    {
      path: ":auditId",
      component: auditReportDetail,
      name: "auditTrailDetail",
      meta: {
        title: "Audit Trail Detail",
        user: "employee",
        permissions: [PERMISSIONS["AUDIT-LOG"].MENU],
        checkAll: true,
      },
    },
  ],
};
