const fetchCategoriesWithJobs = (state, data) => {
  state.categoriesWithJobs = data;
};
const getReportByJobs = (state, data) => {
  state.filteredData = data;
};
const getDetailedReport = (state, data) => {
  state.detailedReport = data;
};
const GET_ROUND_STATS = (state, data) => {
  state.roundData = data;
};
const CLEAR_STORE_DATA = (state) => {
  state.detailedReport = [];
  state.filteredData = {};
};
export default {
  GET_ROUND_STATS,
  fetchCategoriesWithJobs,
  getReportByJobs,
  getDetailedReport,
  CLEAR_STORE_DATA,
};
