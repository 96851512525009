export const FILTER_TYPES_MAPPING = {
  contains: "CONTAINS",
  equals: "EQUALS",
  notContains: "NOT_CONTAINS",
  notEqual: "NOT_EQUAL",
  lessThan: "LESS_THAN",
  greaterThan: "GREATER_THAN",
  inRange: "BETWEEN",
  startsWith: "STARTS_WITH",
  endsWith: "ENDS_WITH",
  lessThanOrEqual: "LESS_THAN_OR_EQUAL",
  greaterThanOrEqual: "GREATER_THAN_OR_EQUAL",
  blank: "BLANK",
  notBlank: "NOT_BLANK",
};
