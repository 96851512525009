import { render, staticRenderFns } from "./applications-list.vue?vue&type=template&id=c4471032&scoped=true"
import script from "./applications-list.vue?vue&type=script&lang=js"
export * from "./applications-list.vue?vue&type=script&lang=js"
import style0 from "./applications-list.vue?vue&type=style&index=0&id=c4471032&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4471032",
  null
  
)

export default component.exports