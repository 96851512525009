import sessionStore from "./session-report/index";

/**
 * @memberOf module:Report
 * @namespace store
 * @description local store for Report
 */
export default {
  namespaced: true,
  state: {
    ...sessionStore.state,
  },
  actions: {
    ...sessionStore.actions,
  },
  getters: {
    ...sessionStore.getters,
  },
  mutations: {
    ...sessionStore.mutations,
  },
};
