<template>
  <b-modal
    :id="modalId"
    ref="confirmationModal"
    :title="modalTitle"
    @ok.prevent="confirmAction"
  >
    <div class="col-12 pr-sm-2 mb-3">
      <p>{{ modalMessage }}</p>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="secondary" @click="cancel()"
        >Cancel</b-button
      >
      <b-button size="sm" @click="ok()" class="btn btn-cp ml-2"
        >Confirm</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    modalMessage: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
  },
  methods: {
    confirmAction() {
      this.$nextTick(() => {
        this.$refs.confirmationModal.hide();
        this.action();
      });
    },
  },
};
</script>
