/**
 * @memberOf module:ScheduleDashboard.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of interview schedules
 */
const fetchInterviewSchedules = (state) => state.interviewSchedules;

/**
 * @memberOf module:ScheduleDashboard.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of review schedules
 */
const fetchReviewSchedules = (state) => state.reviewSchedules;

const getSelectedCampusEvent = (state) => state.selectedCampusEvent;
/**
 * @memberOf module:ScheduleDashboard
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  fetchInterviewSchedules,
  fetchReviewSchedules,
  getSelectedCampusEvent,
};
