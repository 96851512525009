<template>
  <div>
    <div v-if="interviewData">
      <div v-if="showHeader">
        <h3>Add Review</h3>
      </div>
      <div>
        <form>
          <div class="form-group">
            <common-rich-text-box
              modalId="add-review-summernote"
              @onKeyUp="onKeyUp"
              @onBlur="onBlur"
            />
            <p class="alert alert-danger" v-if="isReviewLengthValid">
              Reviews must not exceed 5000 characters
            </p>
          </div>
        </form>
      </div>
      <div class="modal-footer text-center" v-if="showFooter">
        <button type="button" class="btn btn-cp" @click="saveReview()">
          Save
        </button>
      </div>
    </div>
    <div v-else>
      <h3 class="text-danger text-center my-4 py-4">Data is required!</h3>
    </div>
  </div>
</template>

<script>
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_interview_review_model
 * @description Add interview review model component
 */
export default {
  components: {
    CommonRichTextBox,
  },
  props: {
    applicantView: { type: Boolean, default: true },
    modalId: { type: String, default: null },
    interviewData: { type: Object },
    showHeader: { type: Boolean, default: false },
    showFooter: { type: Boolean, default: false },
    callback: { type: Function },
  },
  data() {
    return {
      interview: {
        id: null,
        interviewer_remarks: null,
        round_id: null,
        interviewer_id: null,
        round_name: null,
      },
      review: {
        id: null,
        reviewer_remarks: null,
        round_id: null,
        reviewer_id: null,
        round_name: null,
      },
    };
  },
  methods: {
    /**
     * @memberOf module:ApplicantProfile.components.add_interview_review_model
     * @description Save the review for a round of applicant
     */
    saveReview() {
      if (this.interviewData.round_name == "Review") {
        this.$store.dispatch("$_applicant_profile/addReviewRoundReview", {
          ...this.review,
          applicantView: this.applicantView,
          callback: this.callback,
        });
      } else {
        this.$store.dispatch("$_applicant_profile/addInterviewReview", {
          ...this.interview,
          applicantView: this.applicantView,
          callback: this.callback,
        });
      }
    },
    /**
     * set values of comments
     */
    onKeyUp(value) {
      this.review.reviewer_remarks = value;
      this.interview.interviewer_remarks = value;
    },
    /**
     * set values of comments
     */
    onBlur(value) {
      this.review.reviewer_remarks = value;
      this.interview.interviewer_remarks = value;
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantProfile.components.add_interview_review_model
     * @param {Object} value
     * @description Get interviewer review if exists and fill inside modal
     */
    interviewData: {
      handler: function (value) {
        if (!value) {
          this.interview = {
            id: null,
            interviewer_remarks: null,
            round_id: null,
            interviewer_id: null,
            round_name: null,
          };
          this.review = {
            id: null,
            reviewer_remarks: null,
            round_id: null,
            reviewer_id: null,
            round_name: null,
          };
          return;
        }
        this.interview.interviewer_remarks =
          this.interviewData.interviewer_remarks;
        this.interview.id = this.interviewData.id;
        this.interview.round_id = this.interviewData.round_id;
        this.interview.interviewer_id = this.interviewData.interviewer_id;
        this.interview.round_name = this.interviewData.round_name;

        this.review.reviewer_remarks = this.interviewData.interviewer_remarks;
        this.review.id = this.interviewData.id;
        this.review.round_id = this.interviewData.round_id;
        this.review.reviewer_id = this.interviewData.interviewer_id;
        this.review.round_name = this.interviewData.round_name;
        $("#add-review-summernote").summernote(
          "code",
          this.interviewData.interviewer_remarks
        );
      },
      immediate: true,
    },
  },
  computed: {
    /**
     * @memberOf module:ApplicantProfile.components.add_interview_review_model
     * checks length for the review
     */
    isReviewLengthValid() {
      let reviewLength;
      if (this.interviewData && this.interviewData.round_name == "Review") {
        reviewLength = this.review.reviewer_remarks
          ? this.review.reviewer_remarks.length
          : null;
      } else {
        reviewLength = this.interview.interviewer_remarks
          ? this.interview.interviewer_remarks.length
          : null;
      }
      return reviewLength ? reviewLength > 5000 : false;
    },
  },
};
</script>
