var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row mx-0 review-category nopadding"},[_c('h3',{staticClass:"flex-grow-1 pl-2 pointer",attrs:{"data-toggle":"collapse","data-target":'#panel' + _vm.reviewCategory.id,"aria-controls":'panel' + _vm.reviewCategory.id,"id":'flip' + _vm.reviewCategory.id}},[_vm._v(" "+_vm._s(_vm.reviewCategory.review_category_name)+" ")]),_c('div',{staticClass:"text-right d-flex align-items-center"},[_c('div',{staticClass:"d-none d-md-inline"},[(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.CREATE] }))?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Add Review'),expression:"'Add Review'",modifiers:{"top":true}}],staticClass:"add-icon mr-3",attrs:{"id":"add_cat_icon","href":"#","role":"button","data-toggle":"modal","data-target":"#addReviewModal","data-backdrop":"static","data-keyboard":"false"},on:{"click":function($event){return _vm.setModalData(
              _vm.reviewCategory.id,
              _vm.reviewCategory.review_category_name
            )}}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.UPDATE] }))?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Edit Category'),expression:"'Edit Category'",modifiers:{"top":true}}],staticClass:"edit-icon mr-3",attrs:{"href":"#","role":"button","data-toggle":"modal","data-target":"#editReviewCategoryModal","data-backdrop":"static","data-keyboard":"false","id":"edit_cat_icon"},on:{"click":_vm.editReviewCategory}},[_c('i',{staticClass:"fa fa-pencil p-0",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.DELETE] }))?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:('Delete Category'),expression:"'Delete Category'",modifiers:{"top":true}}],staticClass:"delete-icon mr-3",attrs:{"id":"delete_cat_icon","href":"#","role":"button","data-toggle":"modal","data-target":"#deleteModal","data-backdrop":"static","data-keyboard":"false"},on:{"click":function($event){return _vm.deleteReviewCategory(_vm.reviewCategory.id, 'reviewCategory')}}},[_c('i',{staticClass:"fa fa-trash p-0",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.UPDATE] }))?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
            _vm.reviewCategory.status === 'active'
              ? 'Deactivate Category'
              : 'Activate Category'
          ),expression:"\n            reviewCategory.status === 'active'\n              ? 'Deactivate Category'\n              : 'Activate Category'\n          ",modifiers:{"top":true}}],staticClass:"status-icon mr-3",style:([
            _vm.reviewCategory.status === 'active'
              ? { color: 'green' }
              : { color: 'red' },
          ]),attrs:{"id":"update_cat_status_icon","href":"#","role":"button"},on:{"click":function($event){return _vm.updateReviewCategoryWithReviewStatus()}}},[_c('i',{staticClass:"fa fa-circle",attrs:{"aria-hidden":"true"}})]):_vm._e()]),_c('button',{staticClass:"btn btn-custom dropdown-toggle d-md-none",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('b-icon',{staticStyle:{"color":"#8f1452"},attrs:{"icon":"three-dots-vertical"}})],1),_c('div',{staticClass:"dropdown-menu"},[(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.UPDATE] }))?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-toggle":"modal","data-target":"#editReviewCategoryModal"},on:{"click":_vm.editReviewCategory}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"pencil-square","variant":"primary"}}),_vm._v("Edit Category ")],1):_vm._e(),(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.DELETE] }))?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-toggle":"modal","data-target":"#deleteModal"},on:{"click":function($event){return _vm.deleteReviewCategory(_vm.reviewCategory.id, 'reviewCategory')}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"trash","variant":"danger"}}),_vm._v("Delete Category ")],1):_vm._e(),(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.UPDATE] }))?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.updateReviewCategoryWithReviewStatus()}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"circle-fill","variant":_vm.reviewCategory.status === 'active' ? 'success' : 'danger'}}),_vm._v(" "+_vm._s(_vm.reviewCategory.status === "active" ? "Deactivate" : "Activate")+" Category ")],1):_vm._e(),(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.CREATE] }))?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-toggle":"modal","data-target":"#addReviewModal"},on:{"click":function($event){return _vm.setModalData(
              _vm.reviewCategory.id,
              _vm.reviewCategory.review_category_name
            )}}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus-circle","variant":"secondary"}}),_vm._v("Add Review ")],1):_vm._e()])])]),_c('div',{staticClass:"collapse table-responsive nopadding m-t10",attrs:{"id":'panel' + _vm.reviewCategory.id}},[(_vm.reviewCategory.reviews.length)?_c('table',{staticClass:"table",attrs:{"aria-describedby":"ReqreviewCategories"}},[_vm._m(0),_vm._l((_vm.reviewCategory.reviews),function(review){return _c('review-list-element',{key:review.id,attrs:{"review":review,"reviewCategoryId":_vm.reviewCategory.id}})})],2):_c('p',{staticClass:"p-l10"},[_vm._v("No reviews to show")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"tableheader"},[_c('th',{staticClass:"col6",attrs:{"id":"Actions"}},[_vm._v("Actions")]),_c('th',{staticClass:"col6",attrs:{"id":"ReviewName"}},[_vm._v("Review Name")]),_c('th',{staticClass:"col6",attrs:{"id":"Status"}},[_vm._v("Status")])])
}]

export { render, staticRenderFns }