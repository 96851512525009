import store from "../../../store/index";
import { addSnackbarMessage } from "../../Common/_plugin/error-response";

export function getConfigByKey(key) {
  try {
    const configs = store.getters["system_config/systemConfigsObject"];
    let config = configs[key] ? configs[key] : null;
    return config;
  } catch (error) {
    addSnackbarMessage(error, "Problem retrieving data!", true);
    throw error;
  }
}
