<template>
  <BaseModal
    id="addScoreSlot"
    :title="
      modal.title +
      (modal.type == 'rounds' ? (passRoundModal ? ' - Pass' : ' - Fail') : '')
    "
    :size="modal.size == 'large' ? 'lg' : 'md'"
    @onClose="resetValidations($v)"
  >
    <template v-slot:footer>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        data-dismiss="modal"
      >
        Close
      </button>
    </template>
    <div v-if="modal.type == 'rounds'">
      <div class="active-round">
        <div
          v-for="(round, index) in activeSlotRound"
          :key="index"
          class="mb-4"
          :class="{
            'border-bottom': index != activeSlotRound.length - 1,
            'pb-4': index != activeSlotRound.length - 1,
          }"
        >
          <h4>
            <strong>{{ round.round_name }}</strong>
          </h4>
          <div v-if="round.round_name == 'Interview'">
            <template
              v-if="round.interviewers && round.interviewers.length !== 0"
            >
              <h5>
                <strong>Reviews</strong>
              </h5>
              <table
                aria-describedby="interviewerReview"
                class="table table-condensed"
              >
                <thead>
                  <th id="Interviewer">Interviewer</th>
                  <th id="Review">Review</th>
                </thead>
                <tbody
                  v-for="(interviewer, index2) in round.interviewers"
                  :key="index2"
                >
                  <tr>
                    <td class="col-md-4">
                      {{ interviewer.interviewer_name }}
                      <br />
                      <button
                        :disabled="round.round_result !== 'Pending'"
                        class="btn btn-default btn-sm"
                        style="margin-top: 6px"
                        @click="
                          addReview(
                            interviewer.id,
                            interviewer.interviewer_remarks,
                            round.id,
                            interviewer.interviewer_id,
                            round.round_name,
                            interviewer.formMasterId,
                            interviewer.formVersionId
                          )
                        "
                      >
                        <i class="fa fa-edit fa-lg" aria-hidden="true"></i
                        >Review
                      </button>
                    </td>
                    <td
                      class="col-md-9"
                      v-if="
                        interviewer.formMasterId &&
                        interviewer.interviewer_remarks
                      "
                    >
                      <table
                        class="w-100"
                        aria-describedby="InterviewerRemarks"
                      >
                        <tbody>
                          <template
                            v-if="
                              JSON.parse(interviewer.interviewer_remarks)[
                                'model'
                              ]
                            "
                          >
                            <tr
                              v-for="(value, property) in JSON.parse(
                                interviewer.interviewer_remarks
                              )['label']"
                              :key="property"
                            >
                              <th class="border-0 p-1 m-0">
                                {{ property }}
                              </th>
                              <td class="border-0 p-1 m-0">
                                {{ value }}
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr
                              v-for="(value, property) in JSON.parse(
                                interviewer.interviewer_remarks
                              )"
                              :key="property"
                            >
                              <th class="border-0 p-1 m-0">
                                {{ property }}
                              </th>
                              <td class="border-0 p-1 m-0">
                                {{ value }}
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </td>
                    <td class="col-md-9" v-else>
                      {{ interviewer.interviewer_remarks }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
          <form class="content-form">
            <div class="form-group">
              <label class="col-sm-3 control-label" htmlFor="roundType"
                >Round Type</label
              >
              <div class="col-sm-9">
                <input
                  id="roundType"
                  type="text"
                  class="form-control"
                  v-model="data.round_name"
                  readonly
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 required control-label" htmlFor="grade"
                >Grade</label
              >
              <div class="col-sm-9">
                <select
                  id="grade"
                  class="form-control"
                  v-model="round.grade"
                  @blur="$v.activeSlotRound.$each[index].grade.$touch()"
                >
                  <option>Excellent</option>
                  <option>Very Good</option>
                  <option>Good</option>
                  <option>Moderate</option>
                  <option>Lacking</option>
                  <option>Weak</option>
                </select>
                <p
                  class="alert alert-danger"
                  v-if="
                    !$v.activeSlotRound.$each[index].grade.required &&
                    $v.activeSlotRound.$each[index].grade.$error
                  "
                >
                  This field is required
                </p>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label" htmlFor="comments"
                >Comments</label
              >
              <div class="w-100">
                <common-rich-text-box
                  width="100%"
                  :modalId="'add-score-modal-summernoteSlot' + index"
                  :updateCode="round.comments"
                  @onKeyUp="onKeyUp(index)"
                  @onBlur="onBlur(index)"
                  class="w-100"
                />
                <p
                  class="alert alert-danger"
                  v-if="checkCommentLengthNotValid(round.comments, index)"
                >
                  Comments must not exceed 5000 characters
                </p>
              </div>
            </div>
            <div class="form-group">
              <label
                class="checkbox-inline"
                style="margin-top: 5px; margin-left: 16px"
              >
                <input
                  style="margin-top: 5px"
                  type="checkbox"
                  value="true"
                  v-model="sendMail"
                />
                Send Mail
              </label>
              <div v-if="sendMail" class="col-sm-9">
                <div class="d-flex justify-content-between align-items-center">
                  <label class="control-label" htmlFor="mailTemplate"
                    >Select Mail Template</label
                  >
                </div>
                <select
                  id="mailTemplate"
                  class="custom-select main college mr-2"
                  v-model="mail_template"
                >
                  <option disabled value="">Select Mail Template</option>
                  <option
                    v-for="(mailTemplate, index) in filteredMailTemplates"
                    :value="mailTemplate.id"
                    :key="index"
                  >
                    {{ mailTemplate.name }}
                  </option>
                </select>
                <label>
                  <input
                    style="margin-top: 5px"
                    type="checkbox"
                    v-model="includeCC"
                  />
                  Include entity contact person email in CC
                </label>
              </div>
            </div>
          </form>
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-cp ml-2 btn-sm m-1"
              :disabled="!round.grade || (sendMail && !mail_template)"
              @click="
                interviewResult(round, passRoundModal ? 'Passed' : 'Failed')
              "
            >
              <i class="fa fa-book fa-lg mr-2" aria-hidden="true"></i>Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modal.type == 'formRender'">
      <form-render
        :applicantView="false"
        :interviewData="interviewData"
        :callback="backToRound"
        :showFooter="true"
        :handleClose="handleFormClose"
      ></form-render>
    </div>
    <div v-if="modal.type == 'addReview'">
      <add-interview-review
        :applicantView="false"
        :interviewData="interviewData"
        :callback="backToRound"
        :showFooter="true"
        :handleClose="handleFormClose"
      >
      </add-interview-review>
    </div>
  </BaseModal>
</template>

<script>
import api from "../_api/index";
import { required, numeric } from "vuelidate/lib/validators";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
import { mapGetters } from "vuex";
import AddInterviewReview from "../../ApplicantProfile/_components/add-interview-review.vue";
import FormRender from "../../ApplicantProfile/_components/form-render.vue";
import { REVIEWERS_COUNT } from "../../../../config/configurations";
import BaseModal from "../../Common/_components/base-modal.vue";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_score_modal
 * @description Add score modal component
 */
export default {
  components: {
    CommonRichTextBox,
    AddInterviewReview,
    FormRender,
    BaseModal,
  },
  props: [
    "roundData",
    "roundDataAction",
    "selectedSlotData",
    "setIterviewData",
    "interviewData",
    "refreshSlotPage",
  ],
  data() {
    return {
      includeCC: false,
      passRoundModal: true,
      mail_template: null,
      modalTypes: {
        rounds: {
          size: "medium",
          type: "rounds",
          title: "Round Result",
        },
        formRender: {
          size: "large",
          type: "formRender",
          title: "Add Review",
        },
        addReview: {
          size: "large",
          type: "addReview",
          title: "Add Review",
        },
      },
      modal: {
        size: "medium",
        type: "rounds",
        title: "Round Result",
      },
      activeSlotRound: [],
      data: {
        round_name: null,
        round_schedule: null,
        isMailed: false,
        round_result: null,
        interviewers_id: [],
        applicantIterationMasterId: null,
        score: null,
        grade: null,
        comments: null,
        outOf: null,
      },
      isAptitude: false,
      isReview: false,
      isCommentLengthNotValid: false,
      sendMail: true,
    };
  },
  validations: {
    activeSlotRound: {
      required,
      $each: {
        comments: {
          required,
        },
        grade: {
          required,
        },
      },
    },
    data: {
      score: {
        required,
        numeric,
      },
      outOf: {
        required,
        numeric,
      },
      grade: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      eventData: "$_campus_recruitment/getEventData",
      mailTemplates: "$_mailbox/getTemplates",
      loggedInUser: "user/loggedInUser",
      systemConfigs: "system_config/systemConfigs",
    }),
    requiredReviewCount() {
      const reviewCountData = this.systemConfigs.find(
        (item) => item.key === REVIEWERS_COUNT.key
      );
      const requiredReviewCount = reviewCountData?.is_active
        ? parseInt(reviewCountData.value[0], 10)
        : parseInt(REVIEWERS_COUNT.default[0], 10);
      return requiredReviewCount;
    },
    filteredMailTemplates() {
      if (this.mailTemplates) {
        return this.mailTemplates.filter((mail) => {
          return mail.key == "Application Status";
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantProfile.components.add_score_modal
     * @param {Object} value
     * @description Marks if the round is aptitude
     */
    roundData(value) {
      this.data = value;
      if (this.data.round_name == "Aptitude") {
        this.isAptitude = true;
      }
      if (this.data.round_name == "Review") {
        this.isReview = true;
      }
      $("#add-score-modal-summernote").summernote("code", this.data.comments);
    },
  },
  methods: {
    handleFormClose() {
      this.changeModal("rounds");
    },
    setPassRoundModal(value) {
      this.passRoundModal = value;
    },
    getAllMailTemplates() {
      this.$store.dispatch("$_mailbox/getTemplates");
    },
    backToRound() {
      api
        .activeRoundSlotApplicant(
          this.roundData.applicantIterationMasterId,
          this.selectedSlotData.id
        )
        .then((res) => {
          this.activeSlotRound = res.data.filter((round) => {
            return round.round_result == "Pending";
          });
          this.changeModal("rounds");
        })
        .catch(() => {
          this.changeModal("rounds");
        });
    },
    changeModal(type) {
      if (this.modalTypes[type]) {
        this.modal = this.modalTypes[type];
      }
    },
    addReview(
      id,
      remarks,
      round_id,
      interviewer_id,
      round_name,
      formMasterId,
      formVersionId
    ) {
      this.setIterviewData({
        id: id,
        interviewer_remarks: remarks,
        round_id: round_id,
        interviewer_id: interviewer_id,
        round_name: round_name,
        formMasterId: formMasterId,
        formVersionId: formVersionId,
      });
      if (formMasterId) {
        this.changeModal("formRender");
      } else {
        this.changeModal("addReview");
      }
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_score_modal
     * @param {number} index
     * @description Pass and fail  round of applicant
     */
    interviewResult(round, action) {
      if (round.round_name == "Written Test") {
        if (round.round_result == "Pending") {
          this.addScore(
            { ...round, mail_template: this.mail_template },
            action
          );
        }
      } else if (round.round_name == "Interview") {
        if (round.round_result == "Pending") {
          if (round.interviewers) {
            const remark = this.isReviewAvailable(round.interviewers);
            if (!remark && round.round_name === "Interview") {
              this.$alert(
                `Atleast ${this.reviewCountDisplay(
                  round.interviewers
                )} review is required`
              );
              return;
            } else {
              this.addScore(
                { ...round, mail_template: this.mail_template },
                action
              );
            }
          }
        }
      }
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_score_modal
     * @param {number} index
     * @description Tells about the review count and eligibility to pass and fail
     */
    isReviewAvailable(interviewerData) {
      let interviewers = 0;
      let actualreviewCount = 0;
      interviewerData.forEach((data) => {
        if (data.interviewer_remarks) {
          actualreviewCount++;
        }
        interviewers++;
      });

      return (
        actualreviewCount >= this.requiredReviewCount ||
        actualreviewCount >= interviewers
      );
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_score_modal
     * @param {number} index
     * @description displays the required review count in alert box
     */
    reviewCountDisplay(interviewData) {
      const interviewer_count = interviewData.length;
      if (interviewer_count < this.requiredReviewCount)
        return interviewer_count;
      return this.requiredReviewCount;
    },

    checkCommentLengthNotValid(comments) {
      return comments ? comments.length > 500 : false;
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @param {Object} v
     * @description Resets validations on the page
     */
    resetValidations(v) {
      v.$reset();
      $("#add-score-modal-summernote").summernote("code", "");
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description set the tempData
     */
    setTempData(round, action) {
      return {
        mail_template: round.mail_template,
        id: round.id,
        round_result: action,
        round_name: round.round_name,
        grade: round.grade,
        score: round.score,
        outOf: round.outOf,
        comments: round.comments,
        applicantIterationMasterId: round.applicantIterationMasterId,
        sendMail: this.sendMail,
      };
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description Add score for a round
     */
    addScore(round, action) {
      let tempData = this.setTempData(round, action);
      tempData.includeCC = this.includeCC;
      if (
        ["Passed", "Failed", "Cancelled", "Processed", "Differed"].includes(
          action
        )
      ) {
        api
          .updateRoundFromSlot(tempData)
          .then(() => {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "success",
                message: "Round status has been succesfully changed.",
              },
              {
                root: true,
              }
            );
            this.includeCC = false;
            api
              .activeRoundSlotApplicant(
                this.roundData.applicantIterationMasterId,
                this.selectedSlotData.id
              )
              .then((res) => {
                this.activeSlotRound = res.data.filter((round) => {
                  return round.round_result == "Pending";
                });
                if (this.activeSlotRound.length == 0) {
                  $("#addScoreSlot").modal("hide");
                }
              });
          })
          .catch(() => {
            api
              .activeRoundSlotApplicant(
                this.roundData.applicantIterationMasterId,
                this.selectedSlotData.id
              )
              .then((res) => {
                this.activeSlotRound = res.data.filter((round) => {
                  return round.round_result == "Pending";
                });
                if (this.activeSlotRound.length == 0) {
                  $("#addScoreSlot").modal("hide");
                }
              });
            this.sendMail = false;
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "Problem passing round! Try again later",
              },
              {
                root: true,
              }
            );
          });
      }
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description set values of comments
     */
    onKeyUp(index) {
      this.activeSlotRound[index].comments = $(
        "#add-score-modal-summernoteSlot" + index
      ).summernote("code");
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description set values of comments
     */
    onBlur(index) {
      this.activeSlotRound[index].comments = $(
        "#add-score-modal-summernoteSlot" + index
      ).summernote("code");
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * checks length for the comments
     */
    isCommentLengthValid(comments) {
      this.isCommentLengthNotValid = comments.length > 5000;
    },

    getRoundScheduleById() {
      api
        .activeRoundSlotApplicant(
          this.roundData.applicantIterationMasterId,
          this.selectedSlotData.id
        )
        .then((res) => {
          this.activeSlotRound = res.data.filter((round) => {
            return round.round_result == "Pending";
          });
        });
    },
    refreshSlotPageData() {
      this.refreshSlotPage();
    },
  },
  mounted() {
    $("#addScoreSlot").on("shown.bs.modal", () => {
      this.changeModal("rounds");
      this.getRoundScheduleById();
      this.getAllMailTemplates();
    });
    $("#addScoreSlot").on("hidden.bs.modal", () => {
      this.changeModal("rounds");
      this.refreshSlotPageData();
    });
  },
};
</script>
