<template>
  <div id="mail-container" class="index-style">
    <router-view></router-view>
  </div>
</template>

<script>
import store from "./_store";
import fileTemplateStore from "../FileTemplate/_store";
import PERMISSIONS from "../Common/permissions";
import { hasAccess } from "../../modules/User/_utils/actions";

/**
 * @module MailBox
 * @description mailbox module
 */
export default {
  computed: {
    FiLE_TEMPLATE_PERMISSION() {
      return PERMISSIONS["FILE-TEMPLATE"].MENU;
    },
  },
  created() {
    const STORE_KEY = "$_mailbox";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
    const STORE_KEY2 = "$_file_template";
    if (!(STORE_KEY2 in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY2, fileTemplateStore);
    }
    if (hasAccess({ permissions: [this.FiLE_TEMPLATE_PERMISSION] })) {
      this.$store.dispatch("$_file_template/getAllTemplateMasters");
    }
  },
};
</script>

<style scoped>
#mail-container {
  white-space: normal;
}

.index-style {
  height: 100%;
}
</style>
