/**
 * @memberOf module:ScheduleDashboard.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data data of Interview Schedules to be get
 * @description commits data of Interview Schedules
 */
const FETCH_INTERVIEW_SCHEDULES = (state, data) => {
  state.interviewSchedules = data;
};

/**
 * @memberOf module:ScheduleDashboard.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data data of Review Schedules to be get
 * @description commits data of Review Schedules
 */
const FETCH_REVIEW_SCHEDULES = (state, data) => {
  state.reviewSchedules = data;
};

const SET_SELECTED_CAMPUS_EVENT = (state, data) => {
  state.selectedCampusEvent = data;
};

/**
 * @memberOf module:ScheduleDashboard
 * @namespace store.mutations
 * @description mutations for schedule dashboard module
 */
export default {
  FETCH_INTERVIEW_SCHEDULES,
  FETCH_REVIEW_SCHEDULES,
  SET_SELECTED_CAMPUS_EVENT,
};
