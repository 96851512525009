import cronHome from "./index.vue";
import cronJobs from "./_components/home.vue";
import PERMISSIONS from "../Common/permissions";
export default {
  path: "cron-jobs",
  component: cronHome,

  children: [
    {
      path: "",
      component: cronJobs,
      name: "cronJobs",
      meta: {
        title: "Cron jobs",
        user: "employee",
        permissions: [PERMISSIONS["CRON-JOB"].MENU],
      },
    },
  ],
};
