var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.params.data.hasDuplicate.length > 0 ||
    (_vm.params.data.hasServerDuplicates &&
      _vm.params.data.hasServerDuplicates.length > 0)
  )?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"b-button",attrs:{"id":'popover-target-ag-grid' + _vm.params.data.id}},[_c('i',{staticClass:"fa fa-link",staticStyle:{"color":"red","cursor":"pointer"},attrs:{"aria-hidden":"true"}})]),_c('b-popover',{staticClass:"popover",attrs:{"target":'popover-target-ag-grid' + _vm.params.data.id,"triggers":"hover","placement":"right"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Match Duplicate Found")]},proxy:true}],null,false,2898929583)},[(
        _vm.params.data.hasServerDuplicates &&
        _vm.params.data.hasServerDuplicates.length > 0
      )?_c('div',[_c('p',{staticClass:"m-0"},[_vm._v("Server duplicates")]),_c('ul',[_c('li',{staticClass:"has-duplicate-ls"},[_c('span',{staticClass:"server-li-key m-1"},[_vm._v("Mobile_no ")]),_c('span',{staticClass:"server-li-key m-1"},[_vm._v("Email ")])])]),_c('ul',_vm._l((_vm.params.data.hasServerDuplicates),function(item,index){return _c('li',{key:index,staticClass:"has-duplicate-ls"},[_c('span',{staticClass:"bulk-li-id m-1"},[_vm._v(_vm._s(item.mobile_no)+" -")]),_c('span',{staticClass:"bulk-li-key m-1"},[_vm._v(_vm._s(item.email))])])}),0)]):_vm._e(),(_vm.params.data.hasDuplicate && _vm.params.data.hasDuplicate.length > 0)?_c('div',[_c('p',{staticClass:"m-0"},[_vm._v("Local duplicates")]),_c('ul',[_c('li',{staticClass:"has-duplicate-ls"},[_c('span',{staticClass:"bulk-li-id m-1"},[_vm._v("id ")]),_c('span',{staticClass:"bulk-li-key m-1"},[_vm._v("key ")]),_c('span',{staticClass:"bulk-li-type m-1"},[_vm._v("value")])])]),_c('ul',_vm._l((_vm.params.data.hasDuplicate),function(item,index){return _c('li',{key:index,staticClass:"has-duplicate-ls"},[_c('span',{staticClass:"bulk-li-id m-1"},[_vm._v(_vm._s(item.to_applicant_id)+" -")]),_c('span',{staticClass:"bulk-li-key m-1"},[_vm._v(_vm._s(item.key)+" -")]),_c('span',{staticClass:"bulk-li-type m-1"},[_vm._v(_vm._s(item[item.key]))])])}),0)]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }