<template>
  <div class="common-margin">
    <div class="container-fluid page-bg main-container nopadding">
      <div class="text-center mt-30">
        <img
          class="logoSize"
          id="argus-logo"
          src="../../assets/images/newLogo.png"
          alt="Argusoft Logo"
        />
      </div>
      <h1>401</h1>
      <div class="container">
        <h3>Unauthorized: Access to this resource is denied.</h3>
        <hr />
        <p>Make sure you have rights to the thing you tried to access.</p>
        <p>
          Please contact your Argusoft administrator if you think this is a
          mistake.
        </p>
        <router-link :to="'/'">Go to Homepage</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    $("#navmenu").hide();
    $("#footer").hide();
  },
};
</script>

<style scoped>
.common-margin {
  margin-top: 75px;
}
.main-container {
  color: #666;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: auto;
  font-size: 14px;
}

h1 {
  font-size: 56px;
  line-height: 100px;
  font-weight: 400;
  color: #456;
}

h3 {
  color: #456;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

hr {
  max-width: 800px;
  margin: 18px auto;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid white;
}

img {
  max-width: 40vw;
  display: block;
  margin: 40px auto;
}

a {
  line-height: 100px;
  font-weight: 400;
  color: #4a8bee;
  font-size: 18px;
  text-decoration: none;
}
</style>
