<template>
  <div>
    <div class="row m-1">
      <div class="col-md-4 col-sm-12">Position</div>
      <div class="col-md-8 col-sm-8">
        <multiselect
          v-model="selectedPos"
          placeholder="Search or add a position"
          :options="posOptions"
          :multiple="true"
          :taggable="true"
          @tag="addPosition"
          :close-on-select="false"
        ></multiselect>
      </div>
    </div>
    <div class="row m-1">
      <div class="col-md-4 col-sm-12">State</div>
      <div class="col-md-8 col-sm-8">
        <multiselect
          v-model="selectedLoc"
          placeholder="Search or add a state"
          :options="locOptions"
          :multiple="true"
          :taggable="true"
          @tag="addLocation"
          :close-on-select="false"
        ></multiselect>
      </div>
    </div>
    <div class="row m-1">
      <div class="col-md-4 col-sm-12">Application Status</div>
      <div class="col-md-8 col-sm-8">
        <multiselect
          v-model="selectedStatus"
          placeholder="Search or add a status"
          :options="statusOptions"
          :multiple="true"
          :taggable="true"
          @tag="addStatus"
          :close-on-select="false"
        ></multiselect>
      </div>
    </div>
    <div class="row m-1">
      <div class="col-md-4 col-sm-12">Skills</div>
      <div class="col-md-8 col-sm-8">
        <multiselect
          v-model="selectedSkill"
          placeholder="Search or add a skill"
          :options="skillOptions"
          :multiple="true"
          :taggable="true"
          @tag="addSkill"
          :close-on-select="false"
        ></multiselect>
      </div>
    </div>
    <div class="row m-1">
      <div class="col-md-4 col-sm-12">Gender</div>
      <div class="col-md-8 col-sm-12">
        <b-form-checkbox-group
          id="checkbox-group-2"
          v-model="selectedGen"
          name="flavour-2"
        >
          <b-form-checkbox value="Male">Male</b-form-checkbox>
          <b-form-checkbox value="Female">Female</b-form-checkbox>
          <b-form-checkbox value="Other">Other</b-form-checkbox>
        </b-form-checkbox-group>
      </div>
    </div>
    <div class="row m-1">
      <div class="col-md-4 col-sm-12">Experience</div>
      <div
        class="col-md-8 col-sm-12 d-flex flex-column flex-md-row align-items-center"
      >
        <input
          type="number"
          min="0"
          max="45"
          v-model="rangeExperience[0]"
          class="responsive-input mb-2 mb-md-0"
        />
        <vue-slider
          v-model="rangeExperience"
          :min="0"
          :max="45"
          class="mx-md-3 w-100"
        ></vue-slider>
        <input
          type="number"
          min="0"
          max="45"
          v-model="rangeExperience[1]"
          class="responsive-input"
        />
      </div>
    </div>
    <div class="row m-1">
      <div class="col-md-4 col-sm-12">Notice Period</div>
      <div
        class="col-md-8 col-sm-12 d-flex flex-column flex-md-row align-items-center"
      >
        <input
          type="number"
          min="0"
          max="120"
          v-model="rangeNoticePeriod[0]"
          class="responsive-input mb-2 mb-md-0"
        />
        <vue-slider
          v-model="rangeNoticePeriod"
          :min="0"
          :max="120"
          class="mx-md-3 w-100"
        ></vue-slider>
        <input
          type="number"
          min="0"
          max="120"
          v-model="rangeNoticePeriod[1]"
          class="responsive-input"
        />
      </div>
    </div>
    <div class="row m-1">
      <div class="col-md-4 col-sm-12">Age</div>
      <div
        class="col-md-8 col-sm-12 d-flex flex-column flex-md-row align-items-center"
      >
        <input
          type="number"
          min="18"
          max="75"
          v-model="rangeAge[0]"
          class="responsive-input mb-2 mb-md-0"
        />
        <vue-slider
          v-model="rangeAge"
          :min="18"
          :max="75"
          class="mx-md-3 w-100"
        ></vue-slider>
        <input
          type="number"
          min="18"
          max="75"
          v-model="rangeAge[1]"
          class="responsive-input"
        />
      </div>
    </div>
    <div class="row m-2 justify-content-center">
      <button class="btn btn-danger m-1" @click="resetFilters()">Reset</button>
      <button class="btn btn-primary m-1" @click="filterData()">Apply</button>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Multiselect from "vue-multiselect";
import api from "../../_api/candidate-report-api";
export default {
  name: "candidate-filter",
  components: {
    VueSlider,
    Multiselect,
  },
  data() {
    return {
      rangeExperience: [0, 45],
      rangeAge: [18, 75],
      rangeNoticePeriod: [0, 120],
      posOptions: [],
      genderOptions: [],
      locOptions: [],
      statusOptions: [],
      skillOptions: [],
      data: [],
      selectedPos: [],
      selectedLoc: [],
      selectedStatus: [],
      selectedGen: [],
      selectedSkill: [],
    };
  },
  emits: ["filters"],
  methods: {
    addPosition(tag) {
      this.selectedPos.push(tag);
      this.posOptions.push(tag);
    },
    addLocation(tag) {
      this.selectedLoc.push(tag);
      this.locOptions.push(tag);
    },
    addStatus(tag) {
      this.selectedStatus.push(tag);
      this.statusOptions.push(tag);
    },
    addSkill(tag) {
      this.selectedSkill.push(tag);
      this.statusOptions.push(tag);
    },
    resetFilters() {
      this.rangeExperience = [0, 45];
      this.rangeAge = [18, 75];
      this.rangeNoticePeriod = [0, 120];
      this.selectedPos = [];
      this.selectedLoc = [];
      this.selectedStatus = [];
      this.selectedGen = [];
      this.selectedSkill = [];
      this.filterData();
    },
    filterData() {
      const filterOpts = [];
      if (this.selectedPos.length) {
        filterOpts.push({
          operator: "IN",
          column: "position",
          params: this.selectedPos,
        });
      }
      if (this.selectedLoc.length) {
        filterOpts.push({
          operator: "IN",
          column: "state",
          params: this.selectedLoc,
        });
      }
      if (this.selectedStatus.length) {
        filterOpts.push({
          operator: "IN",
          column: "application_status",
          params: this.selectedStatus,
        });
      }
      if (this.selectedGen.length) {
        filterOpts.push({
          operator: "IN",
          column: "gender",
          params: this.selectedGen,
        });
      }
      if (this.selectedSkill.length) {
        filterOpts.push({
          operator: "IN",
          column: "skills",
          params: this.selectedSkill,
        });
      }
      if (this.rangeExperience[0] != 0 || this.rangeExperience[1] != 45) {
        filterOpts.push({
          operator: "BETWEEN",
          column: "curr_experience",
          params: {
            min: this.rangeExperience[0],
            max: this.rangeExperience[1],
          },
        });
      }
      if (this.rangeNoticePeriod[0] != 0 || this.rangeNoticePeriod[1] != 120) {
        filterOpts.push({
          operator: "BETWEEN",
          column: "notice_period",
          params: {
            min: this.rangeNoticePeriod[0],
            max: this.rangeNoticePeriod[1],
          },
        });
      }
      if (this.rangeAge[0] != 18 || this.rangeAge[1] != 75) {
        filterOpts.push({
          operator: "BETWEEN",
          column: "age",
          params: { min: this.rangeAge[0], max: this.rangeAge[1] },
        });
      }
      this.$emit("filters", filterOpts);
    },
  },
  mounted() {
    api.getFilterOptionsData().then((res) => {
      for (let index in res.data[0]) {
        this.posOptions.push(res.data[0][index].position);
      }
      for (let index in res.data[1]) {
        this.locOptions.push(res.data[1][index].state);
      }
      for (let index in res.data[2]) {
        this.statusOptions.push(res.data[2][index].application_status);
      }
      for (let index in res.data[3]) {
        this.skillOptions.push(res.data[3][index].skill);
      }
    });
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.col-4 {
  padding: 8px 15px;
}
.responsive-input {
  width: 50px;
  text-align: center;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
