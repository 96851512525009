<template>
  <div>
    <div id="applicationList" style="height: inherit" v-if="eventData">
      <div class="row pb-2 mx-0">
        <div
          style="width: 100%"
          class="d-flex justify-content-between flex-wrap"
        >
          <div class="btn-group dropup">
            <div>
              <select
                @change="filterStatusChanged($event)"
                class="form-control form-control-sm select-cp my-1 mr-1"
              >
                <option class="pagesizeoption" value="Pending" selected="">
                  Pending
                </option>
                <option class="pagesizeoption" value="Scheduled">
                  Scheduled
                </option>
                <option class="pagesizeoption" value="Passed">Passed</option>
                <option class="pagesizeoption" value="Failed">Failed</option>
              </select>
            </div>
          </div>
          <div class="ml-auto">
            <div class="d-flex align-items-center flex-wrap">
              <template v-if="filterStatus == 'Pending'">
                <p
                  class="alert alert-info alert-xs my-auto m-1"
                  v-if="selectedApplicants.length > 0"
                >
                  {{ selectedApplicants.length }}
                </p>
                <button
                  class="btn btn-sm btn-cp m-1"
                  v-if="totalVisibleRows > 0 && selectedApplicants.length == 0"
                  @click="selectAllOnCurrentPage"
                >
                  <i class="fa fa-square" aria-hidden="true"></i> Select all
                </button>
                <button
                  class="btn btn-sm btn-cp m-1"
                  v-else-if="
                    totalVisibleRows > 0 &&
                    selectedApplicants.length > 0 &&
                    selectedApplicants.length <
                      maxApplicantCountToCurrentPage &&
                    selectedApplicants.length < totalVisibleRows
                  "
                  @click="selectAllOnCurrentPage"
                >
                  <i class="fa fa-minus-square" aria-hidden="true"></i>Select
                  all
                </button>
                <button
                  class="btn btn-sm btn-cp m-1"
                  v-else-if="totalVisibleRows > 0"
                  @click="deselectAllOnCurrentPage"
                >
                  <i class="fa fa-check-square" aria-hidden="true"></i> Deselect
                  all
                </button>
              </template>
              <div class="my-1 ml-1 mr-0">
                <select
                  @change="onPageSizeChanged($event)"
                  class="form-control form-control-sm select-cp"
                >
                  <option class="pagesizeoption" value="1">50</option>
                  <option class="pagesizeoption" value="100" selected="">
                    100
                  </option>
                  <option class="pagesizeoption" value="200">200</option>
                  <option class="pagesizeoption" value="300">300</option>
                  <option class="pagesizeoption" value="500">500</option>
                  <option class="pagesizeoption" value="1000">1000</option>
                  <option class="pagesizeoption" value="2000">2000</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="grid-theme-wrapper" class="w-100">
        <ag-grid-vue
          class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
          :enableBrowserTooltips="true"
          :gridOptions="gridOptions"
          :showDisabledCheckboxes="true"
          @selection-changed="onSelectionChanged"
          @filter-changed="onFilterChanged"
          @sort-changed="onSortChanged"
          rowSelection="multiple"
          @gridReady="onGridReady"
          :key="paginationPageSize"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
</template>

<script>
import { Drag, Drop } from "vue-drag-drop";
import dragFromCell from "./drag-from-cell.vue";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import { mapGetters } from "vuex";
import { COMMON_DATA_COL_DEFS } from "../_utils/event_applicant_cols";
import moment from "moment";
export default {
  props: ["selectedStatus"],
  data() {
    return {
      paginationPageSize: 100,
      currentDate: moment(),
      columnDefs: COMMON_DATA_COL_DEFS,
      selectedRowCount: 0,
      gridOptions: null,
      flag: null,
      includeAll: false,
      employmentStatus: "P",
      filterStatus: "Pending",
      loadingTemplate: "Loading...",
      noRowsTemplate: "No applicants found",
      showRowStyles: true,
      totalVisibleRows: null,
      maxApplicantCountToCurrentPage: null,
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Drag,
    // eslint-disable-next-line vue/no-unused-components
    drag: Drop,
    // eslint-disable-next-line vue/no-unused-components
    "drag-from-cell": dragFromCell,
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{ name: 'applicantprofile', params: { slug: params.data.slug } }"><i title="View Profile" class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "resolve-multiple-round": {
      template: `<div>
        <router-link v-if="params.data && params.data.activeRounds && params.data.activeRounds.length>0" target='_blank' :to="{ name: 'applicantprofile', params: { slug: params.data.slug } }"><i title="Active rounds found for this applicant!" class="fa fa-link" aria-hidden="true" style="color:red"></i></router-link>
      </div>`,
    },
  },
  watch: {
    eventData() {
      this.setDataSource();
    },
  },

  computed: {
    _isLastDateOver() {
      moment();
      if (this.selectedSession) {
        return moment(this.selectedSession.last_date).isBefore(
          this.currentDate
        );
      }
      return true;
    },

    ...mapGetters({
      eventData: "$_campus_recruitment/getEventData",
      selectedApplicants: "$_campus_recruitment/eventApplicantsSelectedRows",
    }),
  },
  methods: {
    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description check if row is selectable of applicant list table
     */
    isRowSelectable(rowNode) {
      return rowNode.data
        ? /Passed|Processed/.test(rowNode.data.application_status) &&
            this.filterStatus == "Pending"
        : false;
    },

    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description method to refresh page data
     */
    refreshData() {
      this.currentDate = moment();
      this.gridOptions.api.setFilterModel(null);
      this.refreshGrid();
    },

    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description method to set applicant list in data table
     */
    setDataSource() {
      let employmentStatus = "G";
      let includeAll = this.includeAll;
      if (this.eventData.EventDetails.campusId) {
        let dependentEventId = this.eventData.EventDetails.dependency[0]
          ? this.eventData.EventDetails.dependency[0]
          : null;
        let eventId = this.eventData.EventDetails.id;
        let campusId = this.eventData.EventDetails.campusId;
        let type = this.filterStatus;
        let queryParams = `?${
          dependentEventId ? `dependentEventId=${dependentEventId}&` : ""
        }type=${type}&campusId=${campusId}&eventId=${eventId}&includeAll=${includeAll}&employmentStatus=${employmentStatus}`;
        axios
          .post(`campus-drive/schedules/applicants/data_list` + queryParams, {})
          .then(
            (data) => {
              this.$store.dispatch(
                "$_campus_recruitment/eventApplicantsSelectedRows",
                []
              );
              this.totalVisibleRows = data.data.length;
              if (data.data.length > 0) {
                this.gridOptions.api.hideOverlay();
              } else {
                this.gridOptions.api.showNoRowsOverlay();
              }
              this.gridOptions.api.setGridOption("rowData", data.data);
            },
            (err) => {
              console.log(err);
            }
          );
      }
    },

    getLastRowValue(rowCount, endRow, defaultValue = -1) {
      if (rowCount < endRow) return rowCount;
      return defaultValue;
    },

    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description handle on change in pag size
     */
    onPageSizeChanged(event) {
      const rowCount = Number(event.target.value);
      this.gridOptions.paginationPageSize = rowCount;
      this.gridOptions.cacheBlockSize = rowCount;
      this.paginationPageSize = rowCount;
      this.setDataSource();
    },
    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description handle on change in pag size
     */
    filterStatusChanged(event) {
      const status = event.target.value;
      this.filterStatus = status;
      this.setDataSource();
    },

    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description check if object is empty
     */
    isEmpty(obj) {
      for (let prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },

    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description handles on refresh grid
     */
    refreshGrid() {
      this.gridOptions.api.paginationGoToFirstPage();
      this.setDataSource();
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description select all applicant on current page
     */
    selectAllOnCurrentPage() {
      const maxNumbers =
        this.gridOptions.api.paginationGetPageSize() *
        (this.gridOptions.api.paginationGetCurrentPage() + 1);
      this.gridOptions.api.forEachNodeAfterFilterAndSort((node, index) => {
        node.setSelected(index < maxNumbers);
      });
    },

    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description deselect all applicant on current page
     */

    deselectAllOnCurrentPage() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },

    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description Called everytime the selected rows get changed in the grid
     */
    onSelectionChanged() {
      const rowData = this.gridOptions.api.getSelectedRows();
      this.$store.dispatch(
        "$_campus_recruitment/eventApplicantsSelectedRows",
        rowData
      );
      if (this.gridOptions.api.paginationGetRowCount() > 0) {
        this.gridOptions.api.hideOverlay();
      } else {
        this.gridOptions.api.showNoRowsOverlay();
      }
    },
    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description Called everytime the filter applied in the grid
     */
    onFilterChanged() {
      const rowData = [];
      let totalVisibleRows = 0;
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
      this.gridOptions.api.forEachNodeAfterFilter(() => {
        totalVisibleRows++;
      });
      this.totalVisibleRows = totalVisibleRows;
      this.$store.dispatch(
        "$_campus_recruitment/eventApplicantsSelectedRows",
        rowData
      );
      if (this.gridOptions.api.paginationGetRowCount() > 0) {
        this.gridOptions.api.hideOverlay();
      } else {
        this.gridOptions.api.showNoRowsOverlay();
      }
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description Called everytime the column sorted in the grid
     */
    onSortChanged() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },
    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description method to get data table list on ag grid ready
     */
    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.setDataSource();
    },
    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description method to set date format
     */
    dateFormatter(date) {
      let tempDate = new Date(date);
      tempDate = new Date(
        tempDate.getTime() - tempDate.getTimezoneOffset() * 60 * 1000
      );
      tempDate = JSON.stringify(tempDate);
      tempDate = tempDate.slice(1, -1);
      return tempDate;
    },

    async resetFilter() {
      const resetColumnFilter = async (columnName) => {
        const instance = await this.gridOptions.api.getColumnFilterInstance(
          columnName
        );
        const data = await instance;
        data.setModel(null);
        this.gridOptions.api.onFilterChanged();
      };

      await resetColumnFilter("createdAt");
      await resetColumnFilter("application_status");
    },
    getRowStyle(params) {
      if (params && params.data && this.showRowStyles) {
        if (this.filterStatus == "Passed") {
          return { background: "#b2ff8f" };
        } else if (this.filterStatus == "Failed") {
          return { background: "#f58f82" };
        }
      }
    },
    onPaginationChanged() {
      if (this.gridOptions && this.gridOptions.api) {
        this.maxApplicantCountToCurrentPage =
          this.gridOptions.api.paginationGetPageSize() *
          (this.gridOptions.api.paginationGetCurrentPage() + 1);
      }
    },
  },

  beforeMount() {
    this.gridOptions = {
      headerHeight: 30,
      rowHeight: 30,
      overlayLoadingTemplate: this.loadingTemplate,
      overlayNoRowsTemplate: this.noRowsTemplate,
      rowMultiSelectWithClick: true,
      enableCellTextSelection: true,
      suppressCellSelection: false,
      showDisabledCheckboxes: true,
      rowSelection: "multiple",
      suppressPropertyNamesCheck: true,
      enableServerSideFilter: true,
      enableColResize: true,
      // rowDeselection: true,
      onPaginationChanged: (event) => this.onPaginationChanged(event),
      isRowSelectable: (event) => this.isRowSelectable(event),
      getRowStyle: (event) => this.getRowStyle(event),
      // rowModelType: "infinite",
      defaultColDef: {
        flex: 1,
        floatingFilter: true,
        resizable: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        // set filtering on for all columns
        filter: true,
      },
      columnDefs: this.columnDefs,
      infiniteInitialRowCount: 0,
      animateRows: true,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      pagination: true,
      paginationPageSize: 100,
      paginationPageSizeSelector: false,
      cacheBlockSize: 100,
      context: {
        componentParent: this,
      },
    };
  },

  mounted() {},
};
</script>

<style scoped>
.ag-grid-style {
  min-height: 500px;
  height: calc(100vh - 185px);
}
</style>
