<template>
  <div
    class="cp-cards rounded border"
    :class="parentClass"
    style="position: relative"
  >
    <template v-if="data">
      <template v-for="indexKey in Object.keys(data)">
        <div
          v-if="$slots['data' + indexKey]"
          :key="indexKey"
          class="card-class my-2"
          :class="cardClass"
          style="min-height: 2rem"
        >
          <div
            :style="{
              position: data[indexKey].header
                ? 'relative'
                : data[indexKey].isOpen
                ? 'absolute'
                : 'relative',
              right: 0,
            }"
          >
            <div
              v-if="data[indexKey].header || !data[indexKey].isOpen"
              class="header-class p-2 d-flex"
              :class="headerClass"
            >
              <div
                class="flex-grow-1 px-1 d-flex align-items-center"
                style="min-width: 0px"
              >
                <div
                  class="left-class px-1"
                  :class="leftClass"
                  v-if="$slots['left' + indexKey] || data[indexKey].left"
                >
                  <slot
                    v-if="$slots['left' + indexKey]"
                    :name="'left' + indexKey"
                  ></slot>
                  <div v-else-if="data[indexKey].left">
                    {{ data[indexKey].left }}
                  </div>
                </div>
                <div
                  role="button"
                  @click="collapse && handleHeaderClick($event, indexKey)"
                  class="user-select-none title-class px-1 flex-grow-1"
                  :class="titleClass"
                >
                  <slot
                    v-if="$slots['title' + indexKey]"
                    :name="'title' + indexKey"
                  ></slot>
                  <div v-else-if="data[indexKey].title">
                    {{ data[indexKey].title }}
                  </div>
                </div>
                <div
                  class="right-class px-1"
                  :class="rightClass"
                  v-if="$slots.right || data[indexKey].right"
                >
                  <slot
                    v-if="$slots['right' + indexKey]"
                    :name="'right' + indexKey"
                  ></slot>
                  <div v-else-if="data[indexKey].right">
                    {{ data[indexKey].right }}
                  </div>
                </div>
              </div>
              <div
                role="button"
                @click="collapse && handleHeaderClick($event, indexKey)"
                class="px-1"
                :class="arrowClass"
                v-if="
                  (data[indexKey].header || !data[indexKey].isOpen) && collapse
                "
              >
                <up-arrow v-if="data[indexKey].isOpen"></up-arrow>
                <down-arrow v-else></down-arrow>
              </div>
            </div>
            <div
              role="button"
              @click="collapse && handleHeaderClick($event, indexKey)"
              v-else-if="collapse"
              class="px-1"
            >
              <corner-arrow></corner-arrow>
            </div>
          </div>
          <div v-show="data[indexKey].isOpen">
            <hr class="m-0 mx-2" v-if="data[indexKey].header" />
            <div class="content-class" :class="contentClass">
              <slot v-if="$slots[data + indexKey]" :name="'data' + indexKey">
              </slot>
            </div>
          </div>
        </div>
        <hr
          :key="indexKey + 'hr'"
          v-if="indexKey != dataLength - 1 && divider"
          class="my-0"
          :class="dividerClass"
        />
      </template>
    </template>
    <div v-else class="card-class" :class="cardClass">
      <div
        :style="{
          position: header ? 'relative' : isOpen ? 'absolute' : 'relative',
          right: 0,
        }"
      >
        <div
          v-if="header || !isOpen"
          class="header-class p-2 d-flex"
          :class="headerClass"
        >
          <div
            class="flex-grow-1 px-1 d-flex align-items-center"
            style="min-width: 0px"
          >
            <div
              class="left-class px-1"
              :class="leftClass"
              v-if="$slots.left || left"
            >
              <slot v-if="$slots.left" name="left"></slot>
              <div v-else-if="left">{{ left }}</div>
            </div>
            <div
              role="button"
              class="user-select-none title-class px-1 flex-grow-1"
              :class="titleClass"
              @click="collapse && toggleContent()"
            >
              <slot v-if="$slots.title" name="title"></slot>
              <div v-else-if="title">{{ title }}</div>
            </div>
            <div
              class="right-class px-1"
              :class="rightClass"
              v-if="$slots.right || right"
            >
              <slot v-if="$slots.right" name="right"></slot>
              <div v-else-if="right">{{ right }}</div>
            </div>
          </div>
          <div
            role="button"
            @click="collapse && toggleContent()"
            class="px-1 d-flex align-items-center"
            v-if="(header || !isOpen) && collapse"
          >
            <up-arrow v-if="isOpen"></up-arrow>
            <down-arrow v-else></down-arrow>
          </div>
        </div>
        <div
          role="button"
          @click="collapse && toggleContent()"
          v-else-if="collapse"
          class="px-1"
        >
          <corner-arrow></corner-arrow>
        </div>
      </div>
      <div v-show="isOpen">
        <hr class="m-0 mx-2" v-if="header && midDivider" />
        <div class="content-class" :class="contentClass">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collapse: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 0,
    },

    //multiple cards
    multiple: {
      type: Array,
      default: () => [],
    },
    // should card open on load
    open: {
      type: Boolean,
      default: true,
    },
    // should header be included when card is in open state
    header: {
      type: Boolean,
      default: true,
    },
    //card's title
    title: {
      type: String,
    },
    // card's right data text
    right: {
      type: [String, Number],
    },
    // card's left data text
    left: {
      type: [String, Number],
    },
    // parent div class
    parentClass: {
      type: String,
      default: "",
    },
    // card's main class
    cardClass: {
      type: String,
      default: "",
    },
    // card's header class
    headerClass: {
      type: String,
      default: "",
    },
    // header title div class
    titleClass: {
      type: String,
      default: "",
    },
    // header left div class
    leftClass: {
      type: String,
      default: "",
    },
    // header right div class
    rightClass: {
      type: String,
      default: "",
    },
    // arrow div class
    arrowClass: {
      type: String,
      default: "",
    },
    // card's content class
    contentClass: {
      type: String,
      default: "",
    },
    // toggle divider
    divider: {
      type: Boolean,
      default: true,
    },
    // card divider class
    dividerClass: {
      type: String,
      default: "",
    },
    // will only keep current card open
    seperate: {
      type: Boolean,
      default: false,
    },
    // hover effect
    hover: {
      type: Boolean,
      default: true,
    },
    midDivider: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: null,
      dataLength: 0,
      isOpen: this.open || false,
    };
  },
  components: {
    upArrow: {
      template: `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-chevron-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"
                  />
                </svg>`,
    },
    downArrow: {
      template: `<svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-chevron-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                  />
                </svg>`,
    },
    cornerArrow: {
      template: `<svg
                style="transform: rotate(-135deg)"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-chevron-down"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708"
                />
              </svg>
      `,
    },
  },
  created() {
    const jsObj = {};
    if (
      (this.size && typeof this.size === "number") ||
      (this.multiple && this.multiple.length)
    ) {
      (this.size && typeof this.size === "number"
        ? Array.from({ length: this.size })
        : this.multiple
      ).forEach((data, index) => {
        data = data ? data : {};
        jsObj[index] = {
          ...data,
          isOpen: data.open || false,
          title: data.title || "",
          left: data.left || "",
          right: data.right || "",
          header: this.header,
        };
      });
      this.data = jsObj;
      this.dataLength =
        this.size && typeof this.size === "number"
          ? this.size
          : this.multiple.length;
    }
  },
  methods: {
    toggleContent() {
      this.isOpen = !this.isOpen;
    },
    handleHeaderClick(event, indexKey) {
      if (this.seperate) {
        this.data[indexKey].isOpen = !this.data[indexKey].isOpen;
      } else {
        for (const data in this.data) {
          if (data == indexKey) {
            this.data[data].isOpen = !this.data[indexKey].isOpen;
          } else {
            this.data[data].isOpen = false;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-default {
  .header-class {
    background-color: rgb(250, 250, 250);
  }
  .header-class:hover {
    background-color: rgb(255, 241, 253);
  }
}
.theme-white {
  .header-class {
    background-color: white;
  }
}
.content-class {
  position: relative;
}
.title-class {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
