import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  processedApplicantsData: [],
  ongoingApplicantsData: [],
  holdApplicantsData: [],
  dashboardStatsData: {},
  hiringPipelineData: {},
  applicantsData: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
