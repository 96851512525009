<template>
  <div style="margin-top: -10px">
    <div>
      <table
        class="table table-condensed"
        style="font-size: 14px"
        aria-label="Form Response 's Details"
      >
        <thead>
          <th colspan="10">
            <h4>
              <strong>Profile Details</strong>
            </h4>
          </th>
        </thead>
        <tbody v-if="applicantMaster">
          <tr>
            <td class="col-md-1"><strong>Name</strong></td>
            <td class="col-md-2">
              {{ applicantMaster.first_name }} {{ applicantMaster.last_name }}
            </td>
            <td class="col-md-2" v-if="applicantMaster.email">
              {{ applicantMaster.email }}
            </td>
            <td class="col-md-2" v-else>-</td>
            <td class="col-md-2" v-if="applicantMaster.mobile_no">
              {{ applicantMaster.mobile_no }}
            </td>
            <td class="col-md-2" v-else>-</td>
            <td class="col-md-2" v-if="applicantMaster.alternate_mobile_no">
              {{ applicantMaster.alternate_mobile_no }}
            </td>
            <td class="col-md-2" v-else>-</td>
            <td class="col-md-2" v-if="applicantMaster.dob">
              {{ applicantMaster.dob | dateFormat }}
            </td>
            <td class="col-md-2" v-else>-</td>
            <td class="col-md-2" v-if="applicantMaster.gender">
              {{ applicantMaster.gender }}
            </td>
            <td class="col-md-2" v-else>-</td>
            <td class="col-md-2" v-if="applicantMaster.address_line_1">
              {{ applicantMaster.address_line_1 }},
              {{ applicantMaster.address_line_2 }}, {{ applicantMaster.city }},
              {{ applicantMaster.state }}
            </td>
            <td class="col-md-2" v-else>-</td>
            <td class="col-md-2" v-if="applicantMaster.current_address_line_1">
              {{ applicantMaster.current_address_line_1 }},
              {{ applicantMaster.current_address_line_2 }},
              {{ applicantMaster.current_city }},
              {{ applicantMaster.current_state }}
            </td>
            <td class="col-md-2" v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        class="table table-condensed"
        style="font-size: 14px"
        aria-label="Form Response 's Details"
      >
        <thead>
          <th colspan="10">
            <h4>
              <strong>Form Response Details</strong>
            </h4>
          </th>
        </thead>
        <tbody v-if="applicantData">
          <tr>
            <td class="col-md-1">
              {{ applicantData.first_name }} {{ applicantData.last_name }}
            </td>
            <td class="col-md-2" v-if="applicantData.email">
              {{ applicantData.email }}
            </td>
            <td class="col-md-2" v-else>-</td>
            <td class="col-md-1" v-if="applicantData.mobile_no">
              {{ applicantData.mobile_no }}
            </td>
            <td class="col-md-1" v-else>-</td>
            <td class="col-md-1" v-if="applicantData.alternate_mobile_no">
              {{ applicantData.alternate_mobile_no }}
            </td>
            <td class="col-md-1" v-else>-</td>
            <td class="col-md-1" v-if="applicantData.dob">
              {{ applicantData.dob | dateFormat }}
            </td>
            <td class="col-md-1" v-else>-</td>
            <td class="col-md-1" v-if="applicantData.gender">
              {{ applicantData.gender }}
            </td>
            <td class="col-md-1" v-else>-</td>
            <td class="col-md-2" v-if="applicantData.address_line_1">
              {{ applicantData.address_line_1 }},
              {{ applicantData.address_line_2 }}, {{ applicantData.city }},
              {{ applicantData.state }}
            </td>
            <td class="col-md-2" v-else>-</td>
            <td class="col-md-2" v-if="applicantData.current_address_line_1">
              {{ applicantData.current_address_line_1 }},
              {{ applicantData.current_address_line_2 }},
              {{ applicantData.current_city }},
              {{ applicantData.current_state }}
            </td>
            <td class="col-md-2" v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    applicantMaster: {
      type: Object,
    },
    applicantData: {
      type: Object,
    },
  },
};
</script>
