import axios from "axios";

/**
 * @memberof module:MailBox.api
 * @param {Object} postMailData mail data to be sent
 * @returns {Promise}
 */
const sendMail = (postMailData) => {
  let formData = new FormData();
  let files = postMailData["mail_attachment"];
  if (files) {
    for (let i = 0; i < files.length; i++) {
      formData.append("mail_attachments[" + i + "]", files[i]);
    }
  }
  let mailData = JSON.stringify(postMailData);
  formData.append("data", mailData);
  return axios.post("mails/create", formData, {});
};

/**
 * @memberof module:MailBox.api
 * @param {Object} template template data to be added
 * @returns {Promise}
 */
const addTemplate = (template) => {
  const templateData = {
    data: template,
  };
  return axios.post("mails/templates", templateData);
};

/**
 * @memberof module:MailBox.api
 * @param {String} key identifier for retrieving templates
 * @returns {Promise}
 */
const getTemplates = (key) => {
  if (key) {
    return axios.get("mails/templates?key=" + key);
  }
  return axios.get("mails/templates");
};

/**
 * @memberof module:MailBox.api
 * @param {Number} id id of template to be deleted
 * @returns {Promise}
 */
const deleteTemplate = (id) => {
  return axios.delete("mails/templates/" + id);
};

/**
 * @memberof module:MailBox.api
 * @param {Object} template template data to be updated
 * @returns {Promise}
 */
const updateTemplate = (data) => {
  const templateData = {
    data,
  };
  return axios.put("mails/templates", templateData);
};
const updateTemplateOrder = (data) => {
  const templateData = {
    data,
  };
  return axios.put("mails/templatesOrder", templateData);
};

/**
 * @memberof module:MailBox.api
 * @param {Object} object mail data to be updated
 * @returns {Promise}
 */
const updateMailData = (data) => {
  const mailData = {
    data,
  };
  return axios.put("mails/update", mailData);
};

const getClaimedMailIds = (ids) => {
  return axios.post(`users/by/ids`, { ids });
};

/**
 * @memberof module:MailBox
 * @namespace api
 * @description api for backend interactions
 */
export default {
  addTemplate,
  getTemplates,
  deleteTemplate,
  updateTemplate,
  sendMail,
  updateMailData,
  getClaimedMailIds,
  updateTemplateOrder,
};
