<template>
  <div id="myCampusSchedule" class="content">
    <common-header
      :data="['home', 'mySchedule', 'campusDrive']"
      :componentName="
        campusEventData?.campusDetail.recruitment_session_name +
        ' ~ ' +
        campusEventData?.round_title +
        (campusEventData?.round_name === 'Written Test' ? ' (WT)' : ' (I)')
      "
      :componentTooltip="`
              <div class='d-flex flex-column align-items-start'>
                  <div class='my-1'>Recruitment: <strong>${campusEventData?.campusDetail.recruitment_session_name}</strong></div>
                  <div class='my-1'>Event: <strong>${campusEventData?.round_title}</strong></div>
                  <div class='my-1'>Type: <strong>${campusEventData?.round_name}</strong></div>
              </div>`"
    >
      <div class="d-flex justify-content-end flex-wrap">
        <div class="mr-2">
          <p class="m-0 p-0 font-size-small text-right">
            {{ formatDate(campusEventData?.round_schedule) }}
            &nbsp;&nbsp;-&nbsp;&nbsp;
            {{ formatDate(campusEventData?.round_schedule_end) }}
          </p>
          <div class="p-0 d-flex align-items-center justify-content-end">
            <div class="p-0 m-0">
              <div v-if="campusEventData" class="d-flex">
                <p class="font-size-small m-0 p-0 font-weight-bold">
                  Dependent Event -&nbsp;
                </p>
                <p class="p-0 m-0 font-size-small">
                  {{
                    campusEventData?.dependentEventDetails
                      ? campusEventData?.dependentEventDetails.round_title
                      : "No Dependency"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </common-header>
    <div class="container-fluid">
      <div class="row mx-0">
        <div class="col-md-7 col-xs-7 mb-2 px-0 pr-md-2">
          <slots-calendar
            ref="slotsCalendarModal"
            :selectedEvent="selectedCampusEvent"
          ></slots-calendar>
        </div>
        <div class="col-md-5 col-xs-5 border-left px-0 pl-md-2">
          <div class="d-flex flex-row-reverse">
            <router-link
              v-if="
                selectedCampusEvent &&
                hasAccess({
                  permissions: [PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU],
                })
              "
              class="btn btn-sm btn-cp text-nowrap float-right d-flex align-items-center"
              :to="{
                name: 'scheduleEventSlot',
                params: { eventId: selectedCampusEvent },
              }"
              >View Selected Event<i
                class="fa fa-arrow-circle-right ml-1"
                aria-hidden="true"
              ></i
            ></router-link>
            <div class="mx-2 my-schedule-event-select" style="width: 300px">
              <select
                id="mycampuseventpicker"
                class="form-control"
                data-live-search="true"
                data-width="100%"
                :data-dropdown-align-right="true"
                v-model="selectedCampusEvent"
              >
                <option
                  v-for="(event, index) in campusEvents"
                  :value="event.id"
                  :key="index"
                >
                  {{
                    event.status == "Pending"
                      ? event.round_title
                      : `${event.round_title}(${event.status})`
                  }}
                </option>
              </select>
            </div>
          </div>
          <common-chart
            chartId="my-schedule-status-chart"
            chartTitle="Status-wise"
            :colorIdx="0"
            :dataSlices="statusCountSlices"
            v-on:filter-data="filterApplicationStatus($event)"
          >
            <div class="filter-text" v-if="currentRoundStatus">
              <span v-if="currentRoundStatus"
                ><em class="fa fa-filter"></em>
                Round Result :
                {{ currentRoundStatus }}</span
              ><br />
            </div>
          </common-chart>
          <interview-round-form-data-view
            :round="selectedRoundData"
          ></interview-round-form-data-view>
        </div>
      </div>
      <div
        class="d-flex flex-wrap justify-content-between m-0 mt-4"
        style="width: 100%"
      >
        <div class="d-flex mb-1 mb-md-0">
          <div class="btn-group dropup float-left">
            <button
              class="btn dropdown-toggle btn-cp"
              type="button"
              data-toggle="dropdown"
              id="dropdownMenuButtonli"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-tasks" aria-hidden="true"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonli">
              <li class="dropdown-header">Manage</li>
              <template>
                <li
                  class="dropdown-item"
                  @click="openPassQualified"
                  v-if="
                    campusEventData &&
                    campusEventData?.round_name === 'Written Test'
                  "
                >
                  Pass Qualified
                </li>
                <li
                  class="dropdown-item"
                  @click="sendGoogleMeet"
                  v-if="
                    campusEventData &&
                    campusEventData?.round_name === 'Interview'
                  "
                >
                  Send Google Meet
                </li>
              </template>
            </ul>
          </div>
          <div class="mx-2" style="width: 120px">
            <select
              v-model="currentRoundStatus"
              class="form-control select-cp"
              @change="changeCurrentRoundStatus()"
            >
              <option class="pagesizeoption" value="" selected="">All</option>
              <option class="pagesizeoption" value="Pending">Pending</option>
              <option class="pagesizeoption" value="Passed">Passed</option>
              <option class="pagesizeoption" value="Failed">Failed</option>
            </select>
          </div>
        </div>
        <div class="">
          <div class="float-right">
            <select
              @change="onPageSizeChanged($event)"
              class="form-control select-cp"
            >
              <option class="pagesizeoption" value="50">50</option>
              <option class="pagesizeoption" value="100" selected="">
                100
              </option>
              <option class="pagesizeoption" value="200">200</option>
              <option class="pagesizeoption" value="500">500</option>
              <option class="pagesizeoption" value="1000">1000</option>
              <option class="pagesizeoption" value="2000">2000</option>
            </select>
          </div>
          <div
            class="refresh-div float-right d-flex"
            v-if="selectedCampusEvent"
          >
            <p
              class="alert alert-info alert-sm"
              v-if="selectedApplicants && selectedApplicants.length > 0"
            >
              <span class="hide-on-mobile">
                {{ selectedApplicants.length }} selected.
              </span>
              <span class="hide-on-desktop px-2">
                {{ selectedApplicants.length }}
              </span>
            </p>
            <div class="mx-2">
              <button
                class="btn btn-cp"
                v-if="totalVisibleRows > 0 && selectedApplicants.length == 0"
                @click="selectAllOnCurrentPage"
              >
                <i class="fa fa-square" aria-hidden="true"></i>
                <span class="hide-on-mobile">Select all</span>
              </button>
              <button
                class="btn btn-cp"
                v-else-if="
                  totalVisibleRows > 0 &&
                  selectedApplicants.length > 0 &&
                  selectedApplicants.length < maxApplicantCountToCurrentPage &&
                  selectedApplicants.length < totalVisibleRows
                "
                @click="selectAllOnCurrentPage"
              >
                <i class="fa fa-minus-square pl-1" aria-hidden="true"></i>
                <span class="hide-on-mobile">Select all</span>
              </button>
              <button
                class="btn btn-cp"
                v-else-if="totalVisibleRows > 0"
                @click="deselectAllOnCurrentPage"
              >
                <i class="fa fa-check-square pl-1" aria-hidden="true"></i>
                <span class="hide-on-mobile">Deselect all</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="my-3">
        <div id="grid-theme-wrapper" class="w-100">
          <ag-grid-vue
            class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
            v-if="gridOptions"
            style="height: 80vh"
            @selection-changed="onSelectionChanged"
            rowSelection="multiple"
            @filter-changed="onFilterChanged"
            @sort-changed="onSortChanged"
            :columnDefs="columns"
            :overlayLoadingTemplate="loadingTemplate"
            :overlayNoRowsTemplate="noRowsTemplate"
            :getRowStyle="getRowStyle"
            :enableBrowserTooltips="true"
            :gridOptions="gridOptions"
            @gridReady="onGridReady"
            :key="paginationPageSize"
          >
          </ag-grid-vue>
        </div>
      </div>
      <add-score-modal
        ref="addEventRoundScore"
        :roundData="roundData"
        :interviewData="interviewData"
        :setIterviewData="setIterviewData"
        :refreshPage="refreshPage"
      ></add-score-modal>
      <pass-qualified-modal
        :isEventView="true"
        :pendingApplicantIds="pendingApplicantIds"
        :selectedSlotData="selectedSlotData"
        :eventId="campusEventData ? campusEventData.id : null"
        :selectedCampusEventData="campusEventData"
        @submitted="onPassQualified"
      ></pass-qualified-modal>
      <common-modal
        :id="commonModalData.id"
        :modal-title="commonModalData.title"
        :modal-body="commonModalData.body"
        :show-cancel="commonModalData.showCancel"
        :modal-color="commonModalData.color"
        :modal-size-class="commonModalData.sizeClass"
        @actionPerformed="emitOnCurrent"
      />
      <MyScheduleAddDateModal :handleSendMeeting="handleSendMeeting" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import campusRecruitmentApi from "../../CampusRecruitment/_api/index";
import axios from "axios";
import { AgGridVue } from "ag-grid-vue";
import ResolveDuplicateLink from "../../CampusRecruitment/_components/resolve-duplicate-link.vue";
import { SCHEDULE_APPLICANTS_DATA_COL_DEFS } from "../_utils/schedule_applicant_data_list";
import CommonChart from "../../Common/_components/common-highchart.vue";
import SlotsCalendar from "./slots-calendar.vue";
import myScheduleApplicantActionCellVue from "./my-schedule-applicant-action-cell.vue";
import myScheduleInterviewFormResponseCellVue from "./my-schedule-interview-form-response-cell.vue";
import CommonModal from "../../Common/_components/modal";
import addScoreModal from "./my-schedule-add-score-modal.vue";
import InterviewRoundFormDataView from "./interview-round-form-data-view.vue";
import PassQualifiedModal from "../../CampusRecruitment/_components/pass-qualified-modal.vue";
import moment from "moment";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import EditApplicantLink from "../../Common/_components/edit-applicant-link.vue";
import MyScheduleAddDateModal from "./my-schedule-add-date-modal.vue";

export default {
  components: {
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "resolve-link": ResolveDuplicateLink,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{name:'applicantprofile', params:{slug:params.data.slug}}"><i title="View Profile" class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "edit-profile-data": EditApplicantLink,
    CommonChart,
    SlotsCalendar,
    // eslint-disable-next-line vue/no-unused-components
    "slot-applicant-action": myScheduleApplicantActionCellVue,
    // eslint-disable-next-line vue/no-unused-components
    "interview-form-response-cell": myScheduleInterviewFormResponseCellVue,
    // SelectTemplateModal,
    addScoreModal,
    InterviewRoundFormDataView,
    CommonModal,
    PassQualifiedModal,
    MyScheduleAddDateModal,
  },
  data() {
    return {
      paginationPageSize: 100,
      selectedSlotData: null,
      interviewData: null,
      selectedRoundData: null,
      applicationStatus: "",
      statusCount: [],
      gridOptions: null,
      getSelectedCampusEvent: this.$route.params.event,
      columnDefs: SCHEDULE_APPLICANTS_DATA_COL_DEFS,
      loadingTemplate: "No applicants found",
      noRowsTemplate: "No applicants found",
      selectedApplicants: [],
      selectedRowCount: null,
      campusEvents: [],
      applicantList: [],
      selectedCampusEvent: null,
      maxApplicantCountToCurrentPage: null,
      totalSelectable: null,
      filterStatus: "",
      showRowStyles: true,
      response: null,
      totalVisibleRows: null,
      roundData: null,
      roundDataAction: "",
      currentRoundStatus: "Pending",
      pendingApplicants: null,
      filters: {
        // status: 'inProgress'
      },
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    pendingApplicantIds() {
      if (!this.campusEventData || !this.response) return [];
      const filterApplicants = this.response.applicants.filter((applicant) => {
        return (
          applicant.currentRoundData &&
          applicant.currentRoundData.round_result == "Pending"
        );
      });
      return filterApplicants.map((applicant) => applicant.id);
    },
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
    }),
    campusEventData() {
      if (!this.selectedCampusEvent) return null;
      else
        return this.campusEvents.find(
          (event) => event.id == this.selectedCampusEvent
        );
    },
    columns() {
      if (this.response && this.response.events) {
        let eventsCols = this.response.events.map((event, index) => {
          const lastEvent = index == this.response.events.length - 1;
          const action = {
            headerName: "action",
            field: "current_round_status",
            suppressFilter: true,
            width: 150,
            minWidth: 150,
            filter: true,
            cellRenderer: "slot-applicant-action",
            pinned: "right",
            valueGetter: function getValue(params) {
              if (params.data) {
                const roundData = lastEvent
                  ? params.data.currentRoundData
                  : params.data.oldDependentEvent[index];
                return roundData.round_result;
              }
              return null;
            },
          };
          const score = {
            headerName: "Score",
            width: 150,
            minWidth: 150,
            valueGetter: function getValue(params) {
              if (params.data) {
                const roundData = lastEvent
                  ? params.data.currentRoundData
                  : params.data.oldDependentEvent[index];
                if (roundData.score || roundData.outOf)
                  return `${roundData.score || "NA"} / ${
                    roundData.outOf || "NA"
                  }`;
              }
              return "NA";
            },
          };
          const grade = {
            headerName: "Grade",
            width: 150,
            minWidth: 150,
            valueGetter: function getValue(params) {
              if (params.data) {
                const roundData = lastEvent
                  ? params.data.currentRoundData
                  : params.data.oldDependentEvent[index];
                return roundData.grade || "NA";
              }
              return "NA";
            },
          };

          const FormResponse = {
            headerName: "Response",
            width: 80,
            filter: false,
            cellRenderer: "interview-form-response-cell",
            params: { index, lastEvent },
            cellStyle: { textAlign: "center", padding: 0 },
          };
          let children = [];
          if (event.round_name === "Written Test") children = [score];
          else children = [grade, FormResponse];
          const getRoundName = (roundName) => {
            return roundName === "Interview" ? "I" : "W";
          };
          return {
            minWidth: 400,
            headerName: lastEvent
              ? `[ ${getRoundName(event.round_name)} ] ${
                  event.round_title
                } [ Selected ]`
              : `[ ${getRoundName(event.round_name)} ] ${event.round_title}`,
            children: lastEvent ? [...children, action] : children,
          };
        });
        return [...this.columnDefs, ...eventsCols];
      }
      return this.columnDefs;
    },
    statusCountSlices() {
      return this.statusCount.map((status) => {
        if (parseInt(status.status_count) !== 0) {
          return {
            name: status.status_name,
            y: parseInt(status.status_count),
          };
        }
      });
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    formatDate(date) {
      return moment(date).format("MMM Do YYYY");
    },
    onPassQualified() {
      this.refreshData();
    },
    openPassQualified() {
      $("#passQualified").modal("show");
    },
    sendGoogleMeet() {
      const pendingApplicants = [];
      for (const applicant of this.selectedApplicants) {
        if (
          applicant.currentRoundData &&
          applicant.currentRoundData.round_result == "Pending"
        ) {
          pendingApplicants.push(applicant.email);
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Please select only pending applicants",
            false,
            "danger",
            "modal-m"
          );
          return;
        }
      }
      if (pendingApplicants.length == 0) {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select atleast one applicant",
          false,
          "danger",
          "modal-m"
        );
        return;
      }
      pendingApplicants.push(this.loggedInUser.email_address);
      this.pendingApplicants = pendingApplicants;
      $("#sendMeetLink").modal("show");
    },
    handleSendMeeting(time) {
      const { from_time, to_time } = time;
      const data = {
        emails: [...this.pendingApplicants],
        from_time: from_time,
        to_time: to_time,
        position: "Fresher",
      };
      campusRecruitmentApi
        .sendGoogleMeetInvitation(data)
        .then(() => {
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Google meet link sent successfully!" },
            { root: true }
          );
        })
        .catch((err) => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message:
                err.response && err.response.data.message
                  ? err.response.data.message
                  : "Something went wrong",
            },
            { root: true }
          );
        });
    },
    refreshPage(callback) {
      this.selectedApplicants = [];
      this.refreshData(callback);
    },
    setIterviewData(data) {
      this.interviewData = data;
    },
    showFormResponse(round) {
      this.selectedRoundData = round;
      $("#interview-round-fomr-data-modal").modal("show");
    },
    passApplicantRound(applicant) {
      if (
        applicant.currentRoundData &&
        applicant.currentRoundData.round_result === "Pending"
      ) {
        this.roundData = applicant.currentRoundData;
        this.roundDataAction = "Passed";
        this.$refs.addEventRoundScore.setPassRoundModal(true);
        $("#addEventRoundScore").modal("show");
      }
    },
    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.setDataSource();
    },
    failApplicantRound(applicant) {
      if (
        applicant.currentRoundData &&
        applicant.currentRoundData.round_result === "Pending"
      ) {
        this.roundData = applicant.currentRoundData;
        this.roundDataAction = "Failed";
        this.$refs.addEventRoundScore.setPassRoundModal(false);
        $("#addEventRoundScore").modal("show");
      }
    },
    onSelectionChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      if (this.gridOptions.api.paginationGetRowCount() > 0) {
        this.gridOptions.api.hideOverlay();
      } else {
        this.gridOptions.api.showNoRowsOverlay();
      }
    },

    onFilterChanged() {
      const rowData = [];
      let totalVisibleRows = 0;
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
      this.gridOptions.api.forEachNodeAfterFilter(() => {
        totalVisibleRows++;
      });
      this.totalVisibleRows = totalVisibleRows;
      this.selectedApplicants = rowData;
      if (this.gridOptions.api.paginationGetRowCount() > 0) {
        this.gridOptions.api.hideOverlay();
      } else {
        this.gridOptions.api.showNoRowsOverlay();
      }
    },

    onSortChanged() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },
    selectAllOnCurrentPage() {
      const maxNumbers =
        this.gridOptions.api.paginationGetPageSize() *
        (this.gridOptions.api.paginationGetCurrentPage() + 1);
      this.gridOptions.api.forEachNodeAfterFilterAndSort((node, index) => {
        node.setSelected(index < maxNumbers);
      });
    },
    deselectAllOnCurrentPage() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },
    onPageSizeChanged(event) {
      const rowCount = Number(event.target.value);
      this.gridOptions.paginationPageSize = rowCount;
      this.gridOptions.cacheBlockSize = rowCount;
      this.setDataSource();
    },
    changeCurrentRoundStatus() {},
    filterApplicationStatus(event) {
      const selected = !event.point.selected;
      if (selected) {
        this.currentRoundStatus = event.point.name;
      } else this.currentRoundStatus = "";
    },
    generateEventsColumns() {},
    onPaginationChanged() {
      if (this.gridOptions && this.gridOptions.api) {
        this.maxApplicantCountToCurrentPage =
          this.gridOptions.api.paginationGetPageSize() *
          (this.gridOptions.api.paginationGetCurrentPage() + 1);
      }
    },

    getRowStyle(params) {
      if (params && params.data && this.showRowStyles) {
        if (params.data.hasDuplicate && params.data.hasDuplicate.length > 0) {
          return { background: "#fff396" };
        } else if (params.data.status == "Duplicate") {
          return { background: "#fff396" };
        } else if (/Passed/.test(params.data.currentRoundData.round_result)) {
          return { background: "#b2ff8f" };
        } else if (
          /Failed|Differ/.test(params.data.currentRoundData.round_result)
        ) {
          return { background: "#f58f82" };
        } else if (
          /Shortlisted/.test(params.data.currentRoundData.round_result)
        ) {
          return { background: "#3788d8" };
        }
      }
    },
    getFilters() {
      return {
        eventId: this.selectedCampusEvent,
        ...(this.currentRoundStatus ? { type: this.currentRoundStatus } : {}),
      };
    },
    refreshData(callback) {
      this.setDataSource(callback);
      this.fetchApplicantCountByStatus();
    },
    fetchApplicantCountByStatus() {
      axios
        .get(
          `campus-drive/schedules/count/status/${this.selectedCampusEvent}`,
          { params: {} }
        )
        .then((res) => {
          this.statusCount = res.data;
        })
        .catch(() => (this.statusCount = []));
    },
    /**
     * @memberof module:CampusRecruitment.event-applications-list
     * @description method to set applicant list in data table
     */
    setDataSource(callback) {
      if (this.selectedCampusEvent) {
        let filters = this.getFilters();
        axios
          .post(
            `applicants/my_schedule/campus_applicant_list/${filters.eventId}`,
            {},
            { params: filters }
          )
          .then(
            (data) => {
              this.totalVisibleRows = data.data.applicants.length;
              if (data.data.applicants.length > 0) {
                this.gridOptions.api.hideOverlay();
              } else {
                this.gridOptions.api.showNoRowsOverlay();
              }
              this.response = data.data;
              if (callback) {
                const roundData = data.data.applicants.find(
                  (d) => d.currentRoundData.id === this.roundData.id
                );
                this.roundData = roundData?.currentRoundData;
                callback();
              }
              this.gridOptions.api.setGridOption(
                "rowData",
                data.data.applicants
              );
            },
            (err) => {
              console.log(err);
            }
          );
      }
    },
    getFormsTemplates() {
      this.$store.dispatch("$_form_template/getAllFormMasters");
    },
    getRounds() {
      const fetchRounds = this.hasAccess({
        permissions: [this.PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.MENU],
      })
        ? "fetchRoundSchedules"
        : "fetchMyCampusRoundSchedules";
      campusRecruitmentApi[fetchRounds]()
        .then((res) => {
          if (this.getSelectedCampusEvent) {
            let selectedEventData = false;
            const filterData = res.data.filter((event) => {
              if (event.id == this.getSelectedCampusEvent)
                selectedEventData = true;
              return (
                event.id == this.getSelectedCampusEvent ||
                event.status == "Pending"
              );
            });
            this.campusEvents = filterData;
            if (selectedEventData) {
              this.selectedCampusEvent = this.getSelectedCampusEvent;
            } else {
              this.$store.dispatch(
                "openSnackbar",
                {
                  type: "danger",
                  message:
                    "Selected event is not present in your scheduled list!",
                },
                { root: true }
              );
            }
          } else {
            this.campusEvents = res.data;
            this.selectedCampusEvent = this.campusEvents[0].id;
          }
        })
        .catch((error) => {
          console.log("error", error.message);
        });
    },
  },
  created() {
    this.getFormsTemplates();
    this.getRounds();
  },
  updated() {
    $("#mycampuseventpicker").selectpicker("refresh");
  },
  watch: {
    selectedCampusEvent(value) {
      if (
        this.$route.name !== "myCampusSchedule" ||
        (this.$route.name === "myCampusSchedule" &&
          this.$route.params.event !== value)
      ) {
        this.$router.replace({
          name: "myCampusSchedule",
          params: { event: value },
        });
      }

      this.refreshData();
    },
    currentRoundStatus() {
      this.setDataSource();
    },
  },
  beforeDestroy() {
    this.$store.dispatch("$_schedule_dashboard/setSelectedCampusEvent", null);
  },
  beforeMount() {
    this.gridOptions = {
      applyColumnDefOrder: true,
      rowData: null,
      rowHeight: 30,
      headerHeight: 35,
      suppressPropertyNamesCheck: true,
      suppressRowClickSelection: true,
      enableServerSideFilter: true,
      enableCellTextSelection: true,
      enableColResize: true,
      suppressMovableColumns: true,
      rowSelection: "multiple",
      // rowDeselection: true,
      getRowStyle: (event) => this.getRowStyle(event),
      onPaginationChanged: (event) => this.onPaginationChanged(event),
      // rowModelType: "infinite",
      defaultColDef: {
        flex: 1,
        resizable: true,
        floatingFilter: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        // set filtering on for all columns
        filter: true,
      },
      infiniteInitialRowCount: 0,
      animateRows: true,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      pagination: true,
      paginationPageSize: 100,
      paginationPageSizeSelector: false,
      context: {
        componentParent: this,
      },
    };
  },
};
</script>
<style>
.font-size-small {
  font-size: 12px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .hide-on-desktop {
    display: none !important;
  }
}
</style>
