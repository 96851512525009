<template>
  <tbody>
    <tr>
      <td class="job-icon">
        <div class="dropdown">
          <a class="btn dropdown-toggle"
            ><i class="fa fa-wrench" aria-hidden="true"></i
          ></a>
          <div class="dropdown-content">
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
              class=""
              id="edit_job_icon"
              href="#"
              role="button"
              data-toggle="modal"
              data-target="#editJobModal"
              data-backdrop="static"
              data-keyboard="false"
              @click="editJob"
            >
              <i class="fa fa-pencil edit-icon" aria-hidden="true"></i> Edit
            </a>
            <a role="button" @click="copyJobUrl">
              <i class="fa fa-clone" aria-hidden="true"></i> Copy Url
            </a>
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.DELETE] })"
              class=""
              id="delete_job_icon"
              href="#"
              role="button"
              data-toggle="modal"
              data-target="#deleteModal"
              data-backdrop="static"
              data-keyboard="false"
              @click="deleteJob"
            >
              <i class="fa fa-trash-o delete-icon" aria-hidden="true"></i>
              Delete
            </a>
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
              class="status-icon"
              id="update_job_status_icon"
              href="#"
              role="button"
              @click="updateJobStatus"
            >
              <i
                class="fa fa-circle"
                :style="[
                  job.status === 'active'
                    ? { color: 'green' }
                    : { color: 'red' },
                ]"
                aria-hidden="true"
              ></i>
              {{ job.status === "active" ? "Deactivate" : "Activate" }} Job
            </a>
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
              class=""
              id="open_positions_icon"
              href="#"
              role="button"
              data-toggle="modal"
              data-target="#openPositionsModal"
              data-backdrop="static"
              data-keyboard="false"
              @click="openPositions"
            >
              <i
                class="fa fa-battery-three-quarters open-positions-icon"
                aria-hidden="true"
              ></i>
              Open Positions
            </a>
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
              class=""
              id="copy_job_icon"
              href="#"
              role="button"
              data-toggle="modal"
              data-target="#editJobModal"
              data-backdrop="static"
              data-keyboard="false"
              @click="createDuplicateJob"
            >
              <i class="fa fa-clone copy-icon" aria-hidden="true"></i> Create
              Duplicate
            </a>
          </div>
        </div>
      </td>
      <td v-if="job.job_name.length > 15" v-tooltip.top="job.job_name">
        {{ job.job_name }}
      </td>
      <td v-else>{{ job.job_name }}</td>

      <td
        v-if="job.required_skills.toString().length > 15"
        v-tooltip.top="job.required_skills.toString()"
      >
        {{ job.required_skills.join(", ") }}
      </td>
      <td v-else>{{ job.required_skills.join(", ") }}</td>

      <td
        v-if="job.required_qualifications.toString().length > 15"
        v-tooltip.top="job.required_qualifications.toString()"
      >
        {{ job.required_qualifications.join(", ") }}
      </td>
      <td v-else>{{ job.required_qualifications.join(", ") }}</td>

      <td>{{ job.required_experience_min }}</td>
      <td>{{ job.required_experience_max }}</td>
      <td>{{ job.status }}</td>
    </tr>
  </tbody>
</template>

<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import { BASE_URL } from "../../../config";

/**
 * @memberof module:ManageJob
 * @namespace components.job_list_element
 */
export default {
  props: ["job", "categoryId"],
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.JOB;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:ManageJob.components.job_list_element
     * @description method - dispatch request to open positions modal
     */
    openPositions() {
      this.$store.dispatch("$_manage_jobs/openPositionsModal", this.job);
    },
    /**
     * @memberof module:ManageJob.components.job_list_element
     * @description method - dispatch request to delete job
     */
    deleteJob() {
      this.$store.dispatch("$_manage_jobs/deleteItem", {
        categoryId: this.categoryId,
        jobId: this.job.id,
        type: "job",
      });
    },
    /**
     * @memberof module:ManageJob.components.job_list_element
     * @description method - dispatch request to delete job
     */
    editJob() {
      this.$store.dispatch("$_manage_jobs/editJobModal", {
        jobInfo: this.job,
        isEdit: true,
      });
    },
    /**
     * @memberof module:ManageJob.components.job_list_element
     * @description method - dispatch request to update job status
     */
    updateJobStatus() {
      let updatedJobInfo = { ...this.job };
      if (this.job.status === "active") {
        updatedJobInfo.status = "inactive";
      } else {
        updatedJobInfo.status = "active";
      }
      delete updatedJobInfo.createdAt;
      delete updatedJobInfo.updatedAt;
      delete updatedJobInfo.slug;
      this.$store.dispatch("$_manage_jobs/editJobStatus", updatedJobInfo);
    },
    /**
     * @memberof module:ManageJob.components.job_list_element
     * @description method - dispatch request to create duplicate job
     */
    createDuplicateJob() {
      this.$store.dispatch("$_manage_jobs/editJobModal", {
        jobInfo: this.job,
        isEdit: false,
      });
    },
    /**
     * @memberof module:ManageJob.components.job_list_element
     * @description method - copies the application form link for this job
     */
    copyJobUrl() {
      let url = `${BASE_URL}application-form?jobId=${this.job.slug}`;
      navigator.clipboard.writeText(url).then(() => {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "success",
            message: "Successfully Copied URL !",
          },
          {
            root: true,
          }
        );
      });
    },
  },
};
</script>

<style scoped>
td {
  text-align: center;
  /* text-align: center; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.copy-icon {
  color: #337ab7;
}

/* Dropdown Button */
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  position: relative;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: inherit;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: left;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: gainsboro;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
</style>
