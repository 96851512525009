<template>
  <div id="add-event-modal-body">
    <div v-if="!isEdit">
      <p>Select a recruitment drive to schedule event</p>
      <select
        class="custom-select main"
        v-model="selectedCampusLocal"
        @change="changeSelectedCampus($event)"
      >
        <option disabled value="">Select recruitment drive</option>
        <option
          v-for="campus in activeCampuses"
          :value="campus.id"
          :key="campus.id"
        >
          {{ campus.recruitment_session_name }}
        </option>
      </select>
      <hr />
    </div>
    <div v-if="selectedCampus">
      <div class="d-flex justify-content-center align-items-center">
        <h4 class="text-center edit-or-create">
          Recruitment drive - {{ selectedCampus.recruitment_session_name }} ({{
            selectedCampus.last_date | dateFormatFilter
          }})
        </h4>
      </div>
      <hr />
      <b-tabs content-class="mt-3" fill pills horizontal justified>
        <b-tab title="Event Details" active v-if="!!isEdit">
          <div class="well container table-responsive">
            <table
              class="table table-condensed"
              aria-label="Entity Details"
              v-if="editEvent"
              aria-hidden="true"
            >
              <tbody>
                <tr>
                  <td class="col-md-4">
                    <strong>Title</strong>
                  </td>
                  <td>{{ editEvent.round_title }}</td>
                </tr>
                <tr>
                  <td class="col-md-4">
                    <strong>Status</strong>
                  </td>
                  <td>{{ editEvent.status }}</td>
                </tr>
                <tr>
                  <td class="col-md-4">
                    <strong>Date</strong>
                  </td>
                  <td>
                    {{ editEvent.round_schedule | dateFormatFilter }}{{ " - "
                    }}{{ editEvent.round_schedule_end | dateFormatFilter }}
                  </td>
                </tr>
                <tr>
                  <td class="col-md-4">
                    <strong>Type</strong>
                  </td>
                  <td>{{ editEvent.round_name }}</td>
                </tr>
                <tr>
                  <td class="col-md-4">
                    <strong>Dependency</strong>
                  </td>
                  <td>
                    {{
                      editEvent.dependency[0]
                        ? getDependencyData(editEvent.dependency[0]).round_title
                        : " - "
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab
          title="Pending Events Details"
          :active="!isEdit && selectedCampus.linkedEventDetails.length > 0"
          v-if="!isEdit"
        >
          <div
            class="well container table-responsive"
            v-if="
              selectedCampus && selectedCampus.linkedEventDetails.length > 0
            "
          >
            <table class="table table-condensed" aria-label="Entity Details">
              <thead>
                <tr>
                  <th class="">Title</th>
                  <th class="">Start Date</th>
                  <th class="">End Date</th>
                  <th class="">Status</th>
                  <th class="">Dependecy</th>
                  <th class="">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(
                    pendingEventData, index
                  ) in selectedCampus.linkedEventDetails"
                  :key="index"
                >
                  <td>{{ pendingEventData.round_title }}</td>
                  <td>
                    {{ pendingEventData.round_schedule | dateFormatFilter }}
                  </td>
                  <td>
                    {{ pendingEventData.round_schedule_end | dateFormatFilter }}
                  </td>
                  <td>{{ pendingEventData.status }}</td>
                  <td>
                    {{
                      pendingEventData.dependency[0]
                        ? getDependencyData(pendingEventData.dependency[0])
                            .round_title
                        : " - "
                    }}
                  </td>
                  <td>{{ pendingEventData.round_name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p class="text-center">No pending events found</p>
          </div>
        </b-tab>
        <b-tab
          title="Campus Details"
          :active="!isEdit && selectedCampus.linkedEventDetails.length == 0"
        >
          <div text-centerclass="well container table-responsive">
            <table
              class="table table-condensed"
              aria-label="Campus Details"
              aria-hidden="true"
            >
              <tbody>
                <tr>
                  <td class="col-md-4">
                    <strong>Status</strong>
                  </td>
                  <td>{{ selectedCampus.status }}</td>
                </tr>

                <tr>
                  <td class="col-md-4">
                    <strong>Last Date</strong>
                  </td>
                  <td>{{ selectedCampus.last_date | dateFormatFilter }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Coordinator Details">
          <div class="well container table-responsive">
            <table
              class="table table-condensed"
              aria-label="Coordinator Details"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Status</th>
                  <th>isPrimary</th>
                  <th>Entity</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(contact, index) in selectedCampusContacts"
                  :key="index"
                >
                  <td>{{ contact.name }}</td>
                  <td>{{ contact.email }}</td>
                  <td>{{ contact.mobile_no }}</td>
                  <td>{{ contact.status }}</td>
                  <td>{{ contact.isPrimary ? "YES" : "NO" }}</td>
                  <td>
                    {{ contact.entity_name + ` (${contact.entity_type})` }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
        <b-tab title="Entity Details">
          <div class="table-div table-responsive">
            <table
              class="table table-condensed"
              aria-label="Entity Details"
              v-if="selectedCampus.entitiesInvolved.length > 0"
            >
              <thead>
                <tr>
                  <th class="table-th">Name</th>
                  <th class="table-th-small">Type</th>
                  <th class="table-th-small">Status</th>
                  <th class="table-th-remarks">Applicant Count</th>
                  <th class="table-th">Ratings</th>
                  <th class="table-th">Address</th>
                  <th class="table-th-remarks">Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(entity, index) in selectedCampus.entitiesInvolved"
                  :key="index"
                >
                  <td class="">{{ entity.entityDetail.entity_name }}</td>
                  <td class="">{{ entity.entityDetail.entity_type }}</td>
                  <td class="">{{ entity.entityDetail.status }}</td>
                  <td class="">{{ entity.hasApplicants }}</td>
                  <td class="">
                    <span
                      class="fa fa-star fa-lg mx-1"
                      v-b-tooltip.hover
                      :title="rating.value"
                      :class="{
                        'star-checked':
                          rating.rating <=
                          ratingConverter(entity.entityDetail.ratings),
                      }"
                      v-for="(rating, index) in ratingsArray"
                      :key="index"
                    >
                    </span>
                  </td>
                  <td class="table-th-remarks">
                    {{
                      entity.entityDetail.city +
                      ", " +
                      entity.entityDetail.state +
                      ", " +
                      entity.entityDetail.pincode
                    }}
                  </td>
                  <td class="table-th-remarks">
                    {{
                      entity.entityDetail.remarks
                        ? entity.entityDetail.remarks
                        : " - "
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <p class="text-danger text-center" v-else>
              No entiy found for this campus.
            </p>
          </div>
        </b-tab>
      </b-tabs>
      <hr />
      <div v-if="!isEdit">
        <div v-if="selectedCampus">
          <div v-if="selectedCampus.entitiesInvolved.length == 0">
            <h6 class="text-danger">
              * No entity found for this Campus. You can not schedule events for
              this Campus.
            </h6>
          </div>
          <div v-else>
            <p v-if="false" class="text-danger">
              * An active event is already exist for this Campus. You can not
              schedule multiple events.
            </p>
            <button
              v-else-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
              class="btn btn-cp btn-sm ml-2 ml-sm-2 mb-2 mb-sm-0"
              @click="generateRound"
            >
              <i class="fa fa-plus fa-lg mr-1" aria-hidden="true"></i>
              <strong>Generate Event</strong>
            </button>
          </div>
        </div>
        <div v-else>Select Entity to schedule events</div>
      </div>
      <div v-else>
        <div
          v-if="
            editEvent.status == 'Pending' &&
            scheduleDateValid(editEvent.round_schedule_end)
          "
        >
          <div>
            <p
              class="text-danger ml-3"
              v-if="
                campusSchedule && !scheduleDateValid(editEvent.round_schedule)
              "
            >
              Event has started. You can't reschedule this event.
            </p>
            <div class="d-flex justify-content-end align-items-center mr-3">
              <div v-if="campusSchedule" class="d-flex align-item-center">
                <button
                  v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
                  class="btn btn-cp btn-sm ml-2 ml-sm-2 mb-2 mb-sm-0"
                  @click="updateEvent"
                  :disabled="!scheduleDateValid(editEvent.round_schedule)"
                >
                  <i class="fa fa-calendar fa-lg" aria-hidden="true"></i>
                  <strong>Reschedule Event</strong>
                </button>
                <button
                  v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
                  class="btn btn-cp btn-sm ml-2 ml-sm-2 mb-2 mb-sm-0"
                  @click="updateEvent"
                >
                  <i class="fa fa-ban fa-lg" aria-hidden="true"></i>
                  <strong>Cancel Event</strong>
                </button>
              </div>
              <div class="d-flex align-item-center">
                <button
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="
                    myScheduleDisble
                      ? 'Selected event is not present in your scheduled list!'
                      : ''
                  "
                  @click="goTocampusSchedule(editEvent.id)"
                  class="btn btn-cp btn-sm ml-2 ml-sm-2 mb-2 mb-sm-0"
                >
                  <i
                    class="fa fa-calendar-minus-o fa-lg"
                    aria-hidden="true"
                  ></i>
                  <strong>Manage Rounds</strong>
                </button>
                <router-link
                  v-if="
                    hasAccess({
                      permissions: [PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU],
                    })
                  "
                  :to="{
                    name: 'scheduleEventSlot',
                    params: { eventId: editEvent.id },
                  }"
                >
                  <button class="btn btn-cp btn-sm ml-2 ml-sm-2 mb-2 mb-sm-0">
                    <i
                      class="fa fa-calendar-minus-o fa-lg"
                      aria-hidden="true"
                    ></i>
                    <strong>Manage slots</strong>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-content-start align-items-center">
            <div v-if="editEvent.status == 'Pending'">
              <p class="text-danger my-0 mb-2 ml-3">
                Event has been Expired. You can't cancel or reschedule this
                Event.
              </p>
            </div>
            <div v-else>
              <p class="text-danger my-0 mb-2 ml-3">
                Event has been {{ editEvent.status }}. You can't manage or
                reschedule this Event.
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-lg-end justify-content-center">
            <button
              v-if="
                editEvent.status == 'Pending' &&
                campusSchedule &&
                hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })
              "
              class="btn btn-dark btn-sm ml-2 ml-sm-2 mb-2 mb-sm-0"
              @click="updateEvent"
            >
              <i class="fa fa-check-square-o fa-lg" aria-hidden="true"></i>
              <strong>Mark Completed</strong>
            </button>
            <button
              data-toggle="tooltip"
              data-placement="top"
              :title="
                myScheduleDisble
                  ? 'Selected event is not present in your scheduled list!'
                  : ''
              "
              @click="goTocampusSchedule(editEvent.id)"
              class="btn btn-cp btn-sm ml-2 ml-sm-2 mb-2 mb-sm-0"
            >
              <i class="fa fa-calendar-minus-o fa-lg" aria-hidden="true"></i>
              <strong>Manage Rounds</strong>
            </button>
            <router-link
              v-if="
                hasAccess({
                  permissions: [PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU],
                })
              "
              :to="{
                name: 'scheduleEventSlot',
                params: { eventId: editEvent.id },
              }"
            >
              <button class="btn btn-cp btn-sm ml-2 ml-sm-2 mb-2 mb-sm-0">
                <i class="fa fa-calendar-minus-o fa-lg" aria-hidden="true"></i>
                <strong>Manage slots</strong>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  components: {},
  props: {
    campusSchedule: {
      default: true,
      type: Boolean,
    },
    isEdit: Boolean,
    editEvent: Object,
    selectedDate: Object,
  },
  data() {
    return {
      ratingsArray: [
        { rating: 1, value: "poor" },
        { rating: 2, value: "moderate" },
        { rating: 3, value: "good" },
        { rating: 4, value: "very good" },
        { rating: 5, value: "excellent" },
      ],
      selectedEntityEvents: null,
      selectedCampusLocal: "",
    };
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    goTocampusSchedule(id) {
      this.$store.dispatch("$_schedule_dashboard/setSelectedCampusEvent", id);
      this.$router.push({ name: "myCampusSchedule", params: { event: id } });
    },
    getDependencyData(id) {
      const dependecyData = this.selectedCampus.linkedEventDetails.find(
        (event) => {
          return event.id == id;
        }
      );
      return dependecyData;
    },
    scheduleDateValid(date) {
      return moment(date).isAfter(new Date());
    },

    checkLastDateOver(last_date) {
      const currentDate = moment();
      return moment(last_date).isBefore(currentDate);
    },

    ratingConverter(rating) {
      if (typeof rating === "string") {
        switch (rating) {
          case "poor":
            return 1;
          case "moderate":
            return 2;
          case "good":
            return 3;
          case "very good":
            return 4;
          case "excellent":
            return 5;
          default:
            return null;
        }
      } else {
        return rating;
      }
    },

    changeSelectedCampus(e) {
      const found = this.activeCampuses.find((campus) => {
        return campus.id == e.target.value;
      });
      this.$store.dispatch("$_campus_recruitment/setSelectedCampus", found);
    },

    generateRound() {
      this.$emit("generateRound");
    },

    updateEvent() {
      this.$emit("updateEvent", this.editEvent);
    },

    checkValidSession(entity) {
      return (
        entity.status === "inProgress" &&
        !this.checkLastDateOver(entity.last_date)
      );
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE;
    },
    selectedCampusContacts() {
      let contactList = [];
      this.selectedCampus.entitiesInvolved.forEach((campus) => {
        campus.entityDetail.contactPersonDetails.forEach((contact) => {
          contactList.push({
            ...contact,
            entity_name: campus.entityDetail.entity_name,
            entity_type: campus.entityDetail.entity_type,
          });
        });
      });
      return contactList;
    },

    eventAlreadyExist() {
      if (this.selectedEntityEvents && this.selectedEntityEvents.length > 0) {
        return this.selectedEntityEvents.some((event) => {
          return (
            event.status == "Pending" &&
            !this.checkLastDateOver(event.round_schedule)
          );
        });
      } else {
        return false;
      }
    },
    ...mapGetters({
      activeCampuses: "$_campus_recruitment/getActiveCampuses",
      selectedCampus: "$_campus_recruitment/getSelectedCampus",
      loggedInUser: "user/loggedInUser",
    }),
    myScheduleDisble() {
      if (
        !this.editEvent ||
        !this.editEvent.slotsInvolved ||
        !this.editEvent.slotsInvolved.length
      )
        return true;
      const interviewIds = this.editEvent.slotsInvolved.flatMap(
        (event) => event.interviewerId
      );
      return !interviewIds.includes(parseInt(this.loggedInUser.user_id));
    },
  },
  watch: {},
  filters: {
    dateFormatFilter(date) {
      return moment(date).format(" MMMM Do YYYY, h:mm a");
    },
  },
  mounted() {
    $("#interviewerpicker").on("hidden.bs.select", () => {
      this.isInterviewersTouched = true;
    });
  },
};
</script>

<style>
.star-checked {
  color: orange;
}

thead th,
tbody td {
  color: #62555b;
}

.edit-or-create {
  display: inline;
}

.table-div {
  overflow-x: auto;
}

.table-th {
  min-width: 180px;
  max-width: 200px;
}

.table-th-small {
  min-width: 150px;
  max-width: 150px;
}

.table-th-remarks {
  min-width: 180px;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .btn.btn-cp,
  .btn.btn-dark {
    font-size: 12px;
    padding: 6px 8px;
    justify-items: center;
    /* width: 180px; */
  }
}
</style>
