import { render, staticRenderFns } from "./moodle.vue?vue&type=template&id=056dbd12&scoped=true"
import script from "./moodle.vue?vue&type=script&lang=js"
export * from "./moodle.vue?vue&type=script&lang=js"
import style0 from "./moodle.vue?vue&type=style&index=0&id=056dbd12&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "056dbd12",
  null
  
)

export default component.exports