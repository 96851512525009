import axios from "axios";

/**
 * @memberOf module:Home.api
 * @returns {Promise}
 */
const fetchReviewCategoriesWithReviews = () => {
  return axios.get("reviews");
};

const fetchCategoriesWithJobs = () => {
  return axios.get("jobs/jobopening");
};

const fetchActiveCategoriesWithJobs = () => {
  return axios.get("jobs/activejobopening");
};

const fetchJobBySlug = (id) => {
  return axios.get(`jobs/${id}`);
};

const fetchJobOpeningKeys = () => {
  return axios.get("system-configs/job-opening-key");
};

/**
 * @memberOf module:Home.api
 * @param {Object} postUserdata Application data to be sent
 * @returns {Promise}
 */
const postUserdata = (postUserdata) => {
  let formData = new FormData();

  formData.append("resume", postUserdata["resume"]);
  formData.append("profile_picture", postUserdata["profile_picture"]);
  let userData = JSON.stringify(postUserdata);
  formData.append("data", userData);
  return axios.post("applicants/applicant", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @memberOf module:Home.api
 * @param {Object} data Applicat's details or Application Id to be sent to get current status of Application
 * @returns {Promise}
 */
const checkStatus = (data) => {
  if (data.email && data.mobile_no) {
    return axios.get(
      "applicants/applicant/status?email=" +
        data.email +
        "&mobile_no=" +
        data.mobile_no
    );
  } else return axios.get("applicants/applicant/status?slug=" + data);
};

/**
 * @memberOf module:Home.api
 * @param {Object} data Applicat's details or Application Id to be sent to get current status of Application
 * @returns {Promise}
 * @description sends notification of a new applicant to "jobs@argusoft.com"
 */
const sendNewApplicationMail = (applicantData) => {
  return axios.post("/applicants/mail/send_notification", applicantData);
};

/**
 * @memberOf module:Home
 * @namespace api
 * @description api for backend interactions
 */
export default {
  fetchReviewCategoriesWithReviews,
  fetchCategoriesWithJobs,
  fetchActiveCategoriesWithJobs,
  postUserdata,
  checkStatus,
  sendNewApplicationMail,
  fetchJobOpeningKeys,
  fetchJobBySlug,
};
