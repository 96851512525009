<template>
  <div id="container" class="index-style">
    <router-view></router-view>
  </div>
</template>

<script>
import store from "./_store";
import mailboxstore from "../MailBox/_store";
import formTemplateStore from "../FormTemplate/_store";
import applicantProfileStore from "../ApplicantProfile/_store";
import campusRecruitmentStore from "../CampusRecruitment/_store";

/**
 * @module ScheduleDashboard
 * @description schedule dashboard module
 */
export default {
  /**
   * @memberof module:ScheduleDashboard
   * @namespace components
   * @description components of schedule dashboard module
   */
  components: {
    // ScheduleDashboard
  },
  created() {
    const STORE_KEY = "$_schedule_dashboard";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
    const MAILBOX_STORE_KEY = "$_mailbox";
    if (!(MAILBOX_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(MAILBOX_STORE_KEY, mailboxstore);
    }

    const FORM_TEMPLATE_STORE_KEY = "$_form_template";
    if (!(FORM_TEMPLATE_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(FORM_TEMPLATE_STORE_KEY, formTemplateStore);
    }

    const APPLICANT_PROFILE_STORE_KEY = "$_applicant_profile";
    if (!(APPLICANT_PROFILE_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(
        APPLICANT_PROFILE_STORE_KEY,
        applicantProfileStore
      );
    }

    const CAMPUS_RECRUITMENT_STORE_KEY = "$_campus_recruitment";
    if (
      !(CAMPUS_RECRUITMENT_STORE_KEY in this.$store._modules.root._children)
    ) {
      this.$store.registerModule(
        CAMPUS_RECRUITMENT_STORE_KEY,
        campusRecruitmentStore
      );
    }
  },
};
</script>
