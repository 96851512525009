<template>
  <div>
    <div class="logo-div">
      <img
        class="logoSize"
        src="../../../assets/images/newLogo.png"
        alt="Argusoft Logo"
      />
    </div>
    <form class="content-form m-0 p-0" @submit.prevent="submit">
      <div class="">
        <div class="col-12 form-group">
          <label class="required control-label">Email </label>
          <input
            name="email"
            type="text"
            class="form-control"
            v-model="data.email"
            v-validate="'required|email'"
            :class="{ 'is-invalid': errors.first('email') }"
          />
          <span
            v-if="errors.first('email')"
            class="valid-feedback alert-danger"
          >
            {{ errors.first("email") }}
          </span>
        </div>
        <div class="col-12 form-group">
          <label class="required control-label">Password </label>
          <div class="input-group">
            <input
              :type="passwordFieldType"
              name="password"
              class="form-control"
              v-model="data.password"
              v-validate="'required|min:8|max:20|customPassword'"
            />
            <button
              type="button"
              class="eye-button"
              @click="togglePasswordVisibility"
            >
              <i
                :class="
                  passwordFieldType === 'password'
                    ? 'fa fa-eye'
                    : 'fa fa-eye-slash'
                "
              ></i>
            </button>
          </div>
          <span
            v-if="errors.first('password')"
            class="valid-feedback alert-danger"
          >
            {{ errors.first("password") }}
          </span>
        </div>
      </div>
      <div class="px-3 mb-2 d-flex justify-content-between">
        <router-link :to="{ name: 'applicantRegistration' }"
          >Register</router-link
        >
        <router-link :to="{ name: 'applicantForgotPasswordRequest' }"
          >Forgot Password?</router-link
        >
      </div>
      <div class="text-center">
        <button
          style="width: 150px"
          type="submit"
          class="btn btn-cp my-2 rounded-pill"
          :disabled="!isDisabled"
        >
          Login
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
      passwordVisible: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.passwordVisible ? "text" : "password";
    },
    isDisabled() {
      return (
        this.data.email &&
        this.data.password &&
        this.$validator.errors.items.length === 0
      );
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    async submit() {
      this.$validator.validateAll().then(() => {
        if (this.$validator.errors.items.length === 0) {
          this.$store.dispatch("$_applicant_master/applicantLogin", this.data);
        } else {
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Please enter valid values!",
          });
        }
      });
    },
    passwordValidator(value) {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~\-\\|`]).{8,}$/.test(
        value
      );
    },
  },
  created() {
    this.$validator.extend("customPassword", {
      getMessage: () =>
        "It requires an uppercase, lowercase, number, and special character.",
      validate: (value) => this.passwordValidator(value),
    });
  },
};
</script>

<style scoped>
.logo-div {
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}

.custom-btn {
  padding: 10px 50px 10px 50px;
  border-radius: 25px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

h3 {
  text-align: center;
  border-bottom: 1px solid #efe6e6;
  padding-bottom: 15px;
}
.input-wrapper {
  position: relative;
}

.input-wrapper {
  position: relative;
}

.eye-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  z-index: 9999;
}

.eye-button i {
  font-size: 1.2rem;
  color: #6c757d;
}

.eye-button:hover i {
  color: #495057;
}

.eye-button:focus {
  outline: none;
}
</style>
