/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Marker to check whether data in the grid has changed
 */
const fetchApplicantsDataStatus = (state) => state.applicantsDataStatus;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch the count of each application status
 */
const applicationStatusCount = (state) => state.applicationStatusCount;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch the count of each application position
 */
const applicationPositionCount = (state) => state.applicationPositionCount;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch the count of each application employment status
 */
const applicationEmploymentStatusCount = (state) =>
  state.applicationEmploymentStatusCount;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch the filter options
 */
const fetchFilterData = (state) => state.filterData;

/**
 * @memberof module:ApplicantsData.store.getters
 * @param {*} state
 * @returns Fetch the applicants data by filtered options
 */
const fetchFilteredApplicantsData = (state) => state.filteredApplicantData;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch the shortlisted applicants data
 */
const fetchShortlistedApplicantsData = (state) =>
  state.shortlistedApplicantsData;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch the joined applicants data
 */
const fetchJoinedApplicantsData = (state) => state.joinedApplicantsData;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch selected applicants in the grid from store
 */
const fetchSelectedApplicants = (state) => state.selectedApplicants;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Check whether round has been added successfully or not and then redirect to Round Schedules page
 */
const fetchConfirmRoundScheduleStatus = (state) =>
  state.confirmRoundScheduleStatus;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch Co-ordinator emails from store
 */
const fetchCampusMails = (state) => state.campusData;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch the count of each registered application status
 */
const getRegisteredApplicationStatusCount = (state) =>
  state.registeredApplicantStatusCount;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description fetch the count of fresh professionals
 */
const fetchFreshProfessionalsCount = (state) => state.freshProfessionalsCount;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description fetch the count of fresh graduates
 */
const fetchFreshGraduatesCount = (state) => state.freshGraduatesCount;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description fetch the open job positions
 */
const fetchOpenJobPositions = (state) => state.openJobPositions;

/**
 * @memberof module:ApplicantsData.store.getters
 * @param {Object} state
 * @description fetch the user specific notifications
 */
const fetchUserNotifications = (state) => state.userNotification;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description fetch user's mail data
 */
const fetchUserMailData = (state) => state.userMailData;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description fetch attachment data
 */
const fetchMailAttachment = (state) => state.attachment;

/**
 * @memberOf module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch round schedules from store
 */
const fetchRoundSchedules = (state) => state.roundSchedules;

const fetchAllFollowUps = (state) => state.followups;

const getAllLinkRequests = (state) => state.linkRequests;

const getApplicantMasterData = (state) => state.applicantMasterData;

/**
 * @memberOf module:ApplicantsData
 * @namespace store.getters
 * @description getters for Applicants data
 */
export default {
  fetchApplicantsDataStatus,
  applicationStatusCount,
  applicationPositionCount,
  applicationEmploymentStatusCount,
  fetchFilterData,
  fetchSelectedApplicants,
  fetchConfirmRoundScheduleStatus,
  fetchCampusMails,
  fetchFreshProfessionalsCount,
  fetchFreshGraduatesCount,
  fetchOpenJobPositions,
  fetchFilteredApplicantsData,
  fetchShortlistedApplicantsData,
  fetchJoinedApplicantsData,
  getRegisteredApplicationStatusCount,
  fetchUserMailData,
  fetchMailAttachment,
  fetchUserNotifications,
  fetchRoundSchedules,
  fetchAllFollowUps,
  getAllLinkRequests,
  getApplicantMasterData,
};
