<template>
  <div>
    <label :for="'input-radio-' + _uid">
      {{ label }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <div
      class="form-check my-2"
      v-for="(option, idx) in options"
      :key="'input-radio-' + _uid + '-option-' + idx"
    >
      <input
        class="form-check-input"
        type="radio"
        :value="option.value"
        :name="'input-radio-' + _uid"
        :id="'input-radio-' + _uid + '-' + idx"
        :checked="value === option.value"
        @click="handleInput(option.value)"
      />
      <label class="form-check-label" :for="'input-radio-' + _uid + '-' + idx">
        {{ option.label }}
      </label>
    </div>
    <div class="form-check my-2" v-if="properties.otherOption">
      <input
        class="form-check-input"
        type="radio"
        :name="'input-radio-' + _uid"
        :id="'input-radio-' + _uid + '-other'"
        :checked="this.options.findIndex((o) => o.value === value) === -1"
        @click="selectOther = true"
      />
      <input
        class="form-control form-control-sm border-top-0 border-left-0 border-right-0 rounded-0"
        type="text"
        placeholder="Other"
        @input="handleInput($event.target.value)"
      />
    </div>
    <p
      class="text-danger"
      v-for="(error, idx) in errorList"
      :key="'input-radio-' + _uid + '-error-' + idx"
    >
      <b-icon-exclamation-circle></b-icon-exclamation-circle>
      <span class="ms-2">{{ error }}</span>
    </p>
  </div>
</template>

<script>
import validators from "../../_utils/validators";

export default {
  name: "radio-input-renderer",
  props: {
    value: String,
    label: String,
    properties: Object,
    useValidation: Boolean,
    validators: Array,
  },
  data() {
    return {
      options: [],
      selectOther: false,
      selectOtherValue: null,
      errorList: [],
      previewInputClass: "",
      previewError: "",
    };
  },
  computed: {
    isRequired() {
      return (
        this.useValidation &&
        this.validators.some((e) => e.validator === "required")
      );
    },
  },
  watch: {
    properties: {
      deep: true,
      handler: function (properties) {
        this.options = properties.options;
      },
    },
  },
  methods: {
    checkError(input) {
      this.errorList = [];
      for (const validator of this.validators) {
        const err = validators[validator.validator](input, validator);
        if (err) {
          this.errorList.push(err);
        }
      }
      if (this.errorList.length !== 0) {
        this.$emit("error", {
          key: this.key,
          errors: this.errorList,
        });
        return true;
      }
      return false;
    },
    handleInput(value) {
      this.$emit("input", value);
      this.useValidation && this.checkError(value);
    },
  },
  beforeMount() {
    this.options = this.properties.options;
  },
};
</script>
