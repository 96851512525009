import axios from "axios";

const prefix = "/audit-trail"; // backend api

const fetchAuditLogs = (queryParams = {}, filterOptions = []) => {
  return axios.post(prefix + "/data_list", filterOptions, {
    params: queryParams,
  });
};
const fetchAuditLogsById = (id) => {
  return axios.get(prefix + "/by/id/" + id);
};

export default {
  fetchAuditLogs,
  fetchAuditLogsById,
};
