<template>
  <div
    class="row mx-0"
    style="width: 100%"
    v-if="hasAccess({ permissions: [PERMISSIONS.APPLICANT.READ] })"
  >
    <div
      class="col-lg-12"
      v-for="(matchedApplicant, index) in matchedApplicantsData"
      :key="index"
    >
      <div class="well container">
        <h4>
          <strong>Matched Applicant's Details</strong>
        </h4>
        <div class="table-responsive">
          <table
            class="table table-condensed"
            style="font-size: 14px"
            aria-label="Matched Applicant's Details"
            aria-hidden="true"
          >
            <tbody>
              <tr>
                <td class="col-md-1">
                  <strong>Name</strong>
                </td>
                <td class="col-md-2">
                  {{ matchedApplicant.isDuplicate.first_name }}
                  {{ matchedApplicant.isDuplicate.last_name }}
                  <em
                    title="Belongs to Campus Drive"
                    v-if="
                      matchedApplicant.isDuplicate.belongsToCampusRecruitement
                    "
                    class="fa fa-graduation-cap fa-lg"
                  >
                  </em>
                </td>
                <td class="col-md-1">
                  <strong>Application Date</strong>
                </td>
                <td class="col-md-2">
                  {{ matchedApplicant.isDuplicate.createdAt | dateTimeFormat }}
                </td>

                <td class="col-md-1">
                  <strong>Applied for Position</strong>
                </td>
                <td class="col-md-2">
                  {{ matchedApplicant.isDuplicate.position }}
                </td>
                <td class="col-md-1">
                  <strong>Application Status</strong>
                </td>
                <td class="col-md-2">
                  {{ matchedApplicant.isDuplicate.application_status }}
                </td>
              </tr>

              <tr>
                <td class="col-md-1">
                  <strong>Email</strong>
                </td>
                <td class="col-md-2">
                  {{ matchedApplicant.isDuplicate.email }}
                </td>
                <td class="col-md-1">
                  <strong>Mobile No</strong>
                </td>
                <td class="col-md-2">
                  {{ matchedApplicant.isDuplicate.mobile_no }}
                </td>

                <td class="col-md-1">
                  <strong>Alt. Mobile No</strong>
                </td>
                <td class="col-md-2">
                  {{ matchedApplicant.isDuplicate.alternate_mobile_no }}
                </td>
                <td class="col-md-1">
                  <strong>Date Of Birth</strong>
                </td>
                <td class="col-md-2">
                  {{ matchedApplicant.isDuplicate.dob | dateFormat }}
                </td>
              </tr>

              <tr>
                <td class="col-md-1">
                  <strong>Gender</strong>
                </td>
                <td class="col-md-2">
                  {{ matchedApplicant.isDuplicate.gender }}
                </td>

                <template>
                  <td class="col-md-1">
                    <strong>Permenent Address</strong>
                  </td>
                  <td
                    class="col-md-2"
                    v-if="matchedApplicant.isDuplicate.address_line_1"
                  >
                    {{ matchedApplicant.isDuplicate.address_line_1 }},
                    {{ matchedApplicant.isDuplicate.address_line_2 }},
                    {{ matchedApplicant.isDuplicate.city }},
                    {{ matchedApplicant.isDuplicate.state }}
                  </td>
                  <td class="col-md-2" v-else>-</td>
                </template>

                <td class="col-md-1">
                  <strong>Current Address</strong>
                </td>
                <td
                  class="col-md-2"
                  v-if="matchedApplicant.isDuplicate.current_address_line_1"
                >
                  {{ matchedApplicant.isDuplicate.current_address_line_1 }},
                  {{ matchedApplicant.isDuplicate.current_address_line_2 }},
                  {{ matchedApplicant.isDuplicate.current_city }},
                  {{ matchedApplicant.isDuplicate.current_state }}
                </td>
                <td class="col-md-2" v-else>-</td>

                <td class="col-md-1">
                  <strong>View Profile</strong>
                </td>
                <td class="col-md-2">
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'applicantprofile',
                      params: { slug: matchedApplicant.isDuplicate.slug },
                    }"
                  >
                    <i
                      class="fa fa-user-circle-o fa-lg"
                      aria-hidden="true"
                      style="color: #832e5b"
                    ></i
                    >View
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <button
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            class="btn btn-cp"
            style="margin: 5px"
            @click="
              confirmDuplicate(
                matchedApplicant.newApplicantId,
                matchedApplicant.matchedApplicantId
              )
            "
          >
            Confirm Duplicate
          </button>
        </div>
      </div>
    </div>
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonModal from "../../Common/_components/modal";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

/**
 * @memberof module:ResolveDuplicate
 * @namespace components.matched_applicant_data
 */
export default {
  components: {
    CommonModal,
  },

  /**
   * @memberof module:ResolveDuplicate.components.matched_applicant_data
   * @description lifecycle method - dispatch request of fetching matched applicants data
   */
  created() {
    this.getMatchedApplicants();
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT.DUPLICATE;
    },
    ...mapGetters({
      matchedApplicantsData: "$_resolve_duplicate/fetchMatchedApplicants",
      confirmDuplicateStatus: "$_resolve_duplicate/confirmDuplicateStatus",
    }),
  },

  watch: {
    /**
     * @memberof module:ResolveDuplicate.components.matched_applicant_data
     * @param {String} value status of confirmaion of duplicates
     * @description watch - checks status of confirmation of duplicates
     */
    confirmDuplicateStatus(value) {
      if (value === "SUCCESS") {
        this.$router.go(-1);
      } else if (value === "FAILURE") {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Unable to perform required action",
        });
      }
      this.$store.commit("$_resolve_duplicate/CONFIRM_DUPLICATE_STATUS", "");
    },
  },
  methods: {
    getMatchedApplicants() {
      if (!this.hasAccess({ permissions: [this.PERMISSIONS.APPLICANT.READ] }))
        return;
      this.$store.dispatch(
        "$_resolve_duplicate/fetchMatchedApplicants",
        this.$route.params.id
      );
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:ResolveDuplicate.components.matched_applicant_data
     * @param {Number} newApplicantId Id of new applicant
     * @param {Number} matchedApplicantId Id of matched applicant
     * @description method - handles modal to confirm duplicate
     */
    confirmDuplicate(newApplicantId, matchedApplicantId) {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.UPDATE] }))
        return;

      this.openDialog(
        "commonModal1",
        "Confirmation",
        "Are you sure you want to confirm this applicant as duplicate ?",
        true,
        "warn",
        ""
      )
        .then(() => {
          let data = {
            data: {
              newApplicantId: newApplicantId,
              matchedApplicantId: matchedApplicantId,
            },
          };
          this.$store.dispatch("$_resolve_duplicate/confirmDuplicate", data);
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.well {
  background-color: transparent;
  margin-top: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 5px;
}

.well:hover {
  box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

h4 {
  padding-top: 5px;
}

.table > tbody > tr > td {
  border-top: 1px solid #878a91;
}
</style>
