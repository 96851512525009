<template>
  <div class="d-flex p-0 m-1">
    <button
      @click="showFormResponse"
      :id="popover_target"
      class="btn p-0 m-0 text-secondary icon-button rounded-circle"
    >
      <i class="fa fa-info-circle" aria-hidden="true"></i>
    </button>
    <b-popover :target="popover_target" triggers="hover" placement="top"
      >View Form Response</b-popover
    >
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    popover_target() {
      return `popover-target-${this.params.data.id}`;
    },
  },
  methods: {
    showFormResponse() {
      if (
        this.params.data &&
        this.params.data.currentRoundData &&
        this.params.data.oldDependentEvent.length
      ) {
        const roundData = this.params.colDef.params.lastEvent
          ? this.params.data.currentRoundData
          : this.params.data.oldDependentEvent[this.params.colDef.params.index];
        this.params.context.componentParent.showFormResponse(roundData);
      }
    },
  },
};
</script>

<style scoped>
.icon-button {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
