export default class CustomFloatingFilterComponent {
  init(params) {
    this.eGui = document.createElement("div");
    this.eGui.innerHTML =
      '<input ref="eInput" class="ag-input-field-input ag-text-field-input mt-2" type="text" disabled/>';
    this.currentValue = null;
    this.eFilterInput = this.eGui.querySelector("input");
    this.eFilterInput.style.color = params.color;

    const onInputBoxChanged = () => {
      if (this.eFilterInput.value === "") {
        // Remove the filter
        params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged(null, null);
        });
        return;
      }

      this.currentValue = this.eFilterInput.value;
      params.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged(this.currentValue);
      });
    };

    this.eFilterInput.addEventListener("input", onInputBoxChanged);
  }

  onParentModelChanged(parentModel) {
    // When the filter is empty we will receive a null message her
    if (!parentModel) {
      this.eFilterInput.value = "";
      this.currentValue = null;
    } else {
      this.eFilterInput.value = parentModel.filterFullName + "";
      this.currentValue = parentModel.filterFullName;
    }
  }

  getGui() {
    return this.eGui;
  }
}
