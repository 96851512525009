<template>
  <div>
    <div class="content">
      <div class="page-header">
        <common-header :data="['home', 'mail', 'htmlTemplate']">
        </common-header>
      </div>
      <div class="container-fluid">
        <div class="template-list">
          <div
            v-for="(template, index) in templateList"
            :key="template.id"
            class="template-item"
          >
            <div @click="toggleDetails(index)" class="template-header">
              <h3 class="template-name">{{ template.name }}</h3>
              <i
                class="fa fa-eye view-icon"
                @click.stop="openViewModal(template)"
                aria-hidden="true"
              ></i>
            </div>
            <div v-if="activeIndex === index" class="template-details">
              <hr class="mt-0 mb-2" />
              <p class="m-0"><strong>Desc:</strong> {{ template.desc }}</p>
              <p class="m-0"><strong>Path:</strong> {{ template.path }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <view-template-modal
      v-if="showViewModal"
      :template="selectedTemplate"
      :template-html="TemplateHtml"
      @close="closeViewModal"
    ></view-template-modal>
  </div>
</template>

<script>
import viewTemplateModal from "./view-template-modal.vue";
import staticTemplateService from "../_api/index";
export default {
  components: { "view-template-modal": viewTemplateModal },
  data() {
    return {
      templateList: [],
      TemplateHtml: null,
      formData: {
        templateName: "",
        templateDesc: "",
        templatePath: "",
      },
      activeIndex: null,
      showViewModal: true,
      selectedTemplate: null,
    };
  },
  methods: {
    resetForm() {
      this.formData = {
        templateName: "",
        templateDesc: "",
        templatePath: "",
      };
    },
    toggleDetails(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    getTemplateFile() {
      staticTemplateService
        .fetchStaticTemplates()
        .then((res) => {
          this.templateList = res.data;
        })
        .catch((error) => {
          console.error("Error fetching template data:", error);
        });
    },
    openViewModal(template) {
      this.showViewModal = true;
      staticTemplateService
        .fetchStaticTemplateHtml({ path: template.path.split("/").pop() })
        .then((res) => {
          this.TemplateHtml = res.data;
          $("#viewTemplateModal").modal("show");
        })
        .catch((error) => {
          console.error("Error fetching template HTML:", error);
        });
    },
    closeViewModal() {
      this.showViewModal = false;
      this.selectedTemplate = null;
    },
  },
  computed: {},
  mounted() {
    this.getTemplateFile();
  },
};
</script>

<style scoped>
.template-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.template-item {
  width: 100%;
  cursor: pointer;
  border: 1px solid #d8cbda;
  background-color: #f4f4f4;
  padding: 5px;
}

.template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-name {
  font-size: 17px;
  margin-top: 8px;
  padding-left: 0.5rem !important;
  font-weight: 600; /* Adjust color */
}

.template-details {
  font-size: 14px; /* Adjust font size for template details */
  color: #666;
  padding: 10px;
  padding-left: 0.5rem !important; /* Adjust color */
}

.fa {
  color: #8f1452 !important;
  padding: 5px;
}
</style>
