const FETCH_PROCESSED_APPLICANTS_DATA = (state, data) => {
  state.processedApplicantsData = data;
};

const FETCH_HOLD_APPLICANTS_DATA = (state, data) => {
  state.holdApplicantsData = data;
};

const FETCH_ONGOING_APPLICANTS_DATA = (state, data) => {
  state.ongoingApplicantsData = data;
};

const FETCH_DASHBOARD_STATS_DATA = (state, data) => {
  state.dashboardStatsData = data;
};

const FETCH_HIRING_PIPELINE_STATS_DATA = (state, data) => {
  state.hiringPipelineData = data;
};
const FETCH_APPLICANTS_DATA = (state, data) => {
  state.applicantsData = data;
};
export default {
  FETCH_PROCESSED_APPLICANTS_DATA,
  FETCH_HOLD_APPLICANTS_DATA,
  FETCH_ONGOING_APPLICANTS_DATA,
  FETCH_DASHBOARD_STATS_DATA,
  FETCH_HIRING_PIPELINE_STATS_DATA,
  FETCH_APPLICANTS_DATA,
};
