<template>
  <div
    class="modal fade"
    id="addTemplateModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Add Template</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="resetTemplateData($v)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <form class="content-form" id="addForm">
                <div class="form-group">
                  <label class="control-label"
                    >Name <span class="text-danger"> *</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="template.name"
                    @blur="$v.template.name.$touch()"
                  />
                  <p
                    class="alert alert-danger"
                    v-if="!$v.template.name.required && $v.template.name.$error"
                  >
                    This field is required
                  </p>
                </div>

                <div class="form-group">
                  <label class="control-label tkey-label" for="key"
                    >Template Key <span class="text-danger"> *</span></label
                  >
                  <select
                    class="form-control"
                    id="key"
                    v-model="template.key"
                    @blur="isKeyValueTouched = true"
                  >
                    <option v-for="(key, index) in templateKeys" :key="index">
                      {{ key }}
                    </option>
                  </select>
                  <p
                    class="alert alert-danger"
                    v-if="isKeyValueTouched && !isKeyValueValid"
                  >
                    This field is required
                  </p>
                </div>

                <div class="form-group">
                  <label class="control-label"
                    >Subject <span class="text-danger"> *</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="template.template_subject"
                    @blur="$v.template.template_subject.$touch()"
                  />
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.template.template_subject.required &&
                      $v.template.template_subject.$error
                    "
                  >
                    This field is required
                  </p>
                </div>

                <tags-input
                  :id="'add-template-cc'"
                  :label="'CC'"
                  :isRequired="false"
                  :setValues="emptyArray"
                  @valuesUpdated="updateCcValue($event)"
                />

                <tags-input
                  :id="'add-template-bcc'"
                  :label="'BCC'"
                  :isRequired="false"
                  :setValues="emptyArray"
                  @valuesUpdated="updateBccValue($event)"
                />
              </form>
            </div>
            <div class="col-md-6 mt-1">
              <label>Body <span class="text-danger"> *</span></label>
              <div class="row" style="margin: auto" @input="onMailBodyChange">
                <common-rich-text-box
                  modalId="add-template-summernote"
                  width="366"
                  :mentions="mentionList"
                  @onKeyDown="onKeyDown"
                  @onBlur="onBlur"
                  @onFocus="onFocus"
                />
              </div>
              <p
                class="alert alert-danger"
                v-if="isMailBodyTouched && !isMailBodyValid"
              >
                This field is required
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button
            id="btn1"
            type="button"
            class="btn btn-cp"
            @click="
              addTemplate();
              resetTemplateData($v);
            "
            :disabled="
              $v.template.$invalid || !isKeyValueValid || !isMailBodyValid
            "
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import TagsInput from "../../Common/_components/tags-input-v2";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";

/**
 * @memberof module:MailBox
 * @namespace components.add_template_modal
 * @description Modal for adding template
 */
export default {
  components: {
    TagsInput,
    CommonRichTextBox,
  },

  /**
   * @memberof module:MailBox.components.add_template_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      mentionList: [
        "first_name",
        "last_name",
        "full_name",
        "email",
        "mobile_no",
        "slug",
        "round_name",
        "round_schedule_time",
        "round_schedule_date",
        "event_title",
        "moodle_username",
        "moodle_password",
        "application_status",
        "interviewee_list_table",
        "reviewee_list_table",
      ],
      templateKeys: [
        "Schedule Round",
        "Application Status",
        "Reschedule",
        "Schedule Cancel",
      ],
      template: {
        key: "",
        name: "",
        template_subject: "",
        template_body: "",
        bcc: [],
        cc: [],
      },
      emptyArray: [],
      isKeyValueTouched: false,
      isMailBodyValid: false,
      isMailBodyTouched: false,
    };
  },
  validations: {
    template: {
      template_subject: {
        required,
      },
      name: {
        required,
      },
    },
  },

  /**
   * @memberof module:MailBox.components.add_template_modal
   * @description lifecycle method - initializes add-template-modal and binds modal event callbacks
   */
  mounted() {
    $("#addTemplateModal").on("show.bs.modal", () => {
      $("#add-template-summernote").summernote("code");
      this.emptyArray = [];
    });
    $("#addTemplateModal").on("hidden.bs.modal", () => {
      $("#add-template-summernote").summernote("code", "");
    });
  },

  computed: {
    /**
     * @memberof module:MailBox.components.add_template_modal
     * @returns {Boolean}
     * @description computed - validates template key
     */
    isKeyValueValid: function () {
      return this.template.key !== "";
    },

    /**
     * @memberof module:MailBox.components.add_template_modal
     * @returns {Boolean}
     * @description computed - validates mail body for template
     */
  },

  methods: {
    onKeyDown(value) {
      this.template.template_body = value;
    },
    onBlur(value) {
      this.isMailBodyTouched = true;
      this.template.template_body = value;
    },
    onFocus() {
      setTimeout(() => {
        $(document.body).addClass("modal-open");
      }, 500);
    },
    onMailBodyChange(content) {
      if (content.target.innerHTML === "") {
        this.isMailBodyValid = false;
      } else {
        this.isMailBodyValid = true;
      }
    },

    /**
     * @memberof module:MailBox.components.add_template_modal
     * @description method - adds template
     */
    addTemplate() {
      this.$store.dispatch("$_mailbox/addTemplate", this.template);
      $("#addTemplateModal").modal("hide");
    },

    /**
     * @memberof module:MailBox.components.add_template_modal
     * @param {Object} event received from tags-input component
     * @description method - updates bcc data value when tags-input fires update event
     */
    updateBccValue(event) {
      this.template.bcc = event.values;
    },

    /**
     * @memberof module:MailBox.components.add_template_modal
     * @param {Object} event received from tags-input component
     * @description method - updates cc data value when tags-input fires update event
     */
    updateCcValue(event) {
      this.template.cc = event.values;
    },

    /**
     * @memberof module:MailBox.components.add_template_modal
     * @param {Object} v vuelidate: $v validation object
     * @description method - resets data, validation object and summernote for next time use
     */
    resetTemplateData(v) {
      v.$reset();
      this.isKeyValueTouched = false;
      this.isMailBodyTouched = false;
      this.template = {
        key: "",
        name: "",
        template_subject: "",
        template_body: "",
        bcc: [],
        cc: [],
      };
      $("#add-template-summernote").summernote("code", "");
    },
  },
  beforeDestroy() {
    $("#addTemplateModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.tkey-label {
  padding: 0;
  padding-top: 4px;
}
</style>
