<template>
  <div
    id="addLinkRequest"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">
            Link Application Request
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group">
              <label class="control-label"
                >Application Id
                <span class="text-danger">*</span>
              </label>
              <input type="text" class="form-control" v-model="applicationId" />
              <p
                class="alert alert-danger"
                v-if="!$v.applicationId.required && $v.applicationId.$error"
              >
                This field is required
              </p>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="btn btn-cp ml-2"
            @click="submitData"
            :disabled="$v.$invalid"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

/**
 * @memberof module: ApplicationMaster
 * @namespace components.link_request_modal
 */
export default {
  data() {
    return {
      applicationId: null,
    };
  },
  validations() {
    return {
      applicationId: {
        required,
      },
    };
  },
  computed: {},
  components: {},
  mounted() {
    $("#addLinkRequest").on("hidden.bs.modal", () => {
      this.applicationId = null;
    });
  },
  methods: {
    async submitData() {
      await this.$store.dispatch("$_applicant_master/postLinkRequest", {
        applicationId: this.applicationId,
      });

      this.applicationId = null;
      $("#addLinkRequest").modal("hide");
      this.$emit("submitted");
    },
  },
  beforeDestroy() {
    $("#addLinkRequest").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.m-15 {
  margin: 15px;
}
.bld {
  font-weight: bold;
}

#contanerFileUpload {
  margin: -15px;
}
</style>
