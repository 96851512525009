<template>
  <div
    class="modal fade"
    id="addInterviewReview"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Add Review</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <add-interview-review
            ref="addInterviewReview"
            modalId="addInterviewReview"
            :interviewData="interviewData"
            :isModal="true"
          ></add-interview-review>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="btn btn-cp"
            data-dismiss="modal"
            @click="$refs.addInterviewReview.saveReview()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_interview_review_model
 * @description Add interview review model component
 */
import addInterviewReview from "./add-interview-review.vue";

export default {
  components: {
    addInterviewReview,
  },
  props: ["interviewData"],
  beforeDestroy() {
    $("#addInterviewReview").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
