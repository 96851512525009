<template>
  <BaseModal
    id="sendMeetLink"
    size="md"
    title="Google meet time"
    :scrollable="false"
  >
    <template v-slot:footer>
      <div class="p-2">
        <button type="button" class="btn btn-dark" data-dismiss="modal">
          Cancel
        </button>
        <button
          :disabled="!isValid"
          type="button"
          class="btn btn-cp ml-2"
          @click="sendLink"
          data-dismiss="modal"
        >
          Send Invite
        </button>
      </div>
    </template>
    <div>
      <div class="form-group">
        <label class="col-sm-3 control-label"
          >Schedule
          <span class="text-danger">*</span>
        </label>
        <div class="col-sm-9">
          <div class="input-group">
            <date-picker
              v-model="round_schedule"
              :config="config"
              id="datePicker"
              :minDate="new Date()"
              @dp-hide="datePickerRequired"
            >
            </date-picker>
            <span class="input-group-addon" @click="toggleDatePicker">
              <i class="fa fa-calendar m-2" aria-hidden="false"> </i>
            </span>
          </div>
          <p v-if="datePickerFlag" class="alert alert-danger">
            This field is required
          </p>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-3 control-label">Duration</label>
        <div class="col-sm-9">
          <select v-model="interviewDuration" class="form-control">
            <option
              v-for="(minutes, index) in interviewDurationMinutes"
              :key="index"
              :value="minutes"
            >
              {{ minutes + " minutes" }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from "../../Common/_components/base-modal.vue";
import datePicker from "vue-bootstrap-datetimepicker";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_score_modal
 * @description Add score modal component
 */
export default {
  components: {
    BaseModal,
    datePicker,
  },
  props: ["handleSendMeeting"],
  data() {
    return {
      currentDate: new Date(),
      round_schedule: null,
      interviewDuration: 30,
      interviewDurationMinutes: [15, 30, 45, 60, 90],
      config: {
        format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
      },
      datePickerFlag: false,
    };
  },
  computed: {
    isValid() {
      if (!this.round_schedule || !this.interviewDuration) {
        return false;
      }
      return true;
    },
  },
  watch: {},
  methods: {
    datePickerRequired() {
      if (!this.round_schedule) {
        this.datePickerFlag = true;
      } else {
        this.datePickerFlag = false;
      }
    },
    toggleDatePicker() {
      $("#datePicker").data("DateTimePicker").toggle();
    },
    sendLink() {
      const data = {
        from_time: new Date(this.round_schedule).toISOString(),
        to_time: new Date(
          new Date(this.round_schedule).getTime() +
            this.interviewDuration * 60000
        ).toISOString(),
      };
      this.handleSendMeeting(data);
    },
  },
  mounted() {},
};
</script>
