<template>
  <div class="content">
    <common-header :data="['home', 'profile']" type="applicant" />
    <div class="container-fluid">
      <div class="profileContainer">
        <div class="profileHeader">
          <div class="headerLeft">
            <div class="profilepicture">
              <b-img
                v-if="profilePic"
                class="imgSize"
                :src="'data:image/jpeg;base64,' + profilePic"
              ></b-img>
              <b-img v-else class="imgSize" :src="avatar"></b-img>
            </div>
            <div class="TopCard_detailsRight__3FMp1">
              <h3 class="title">
                {{ applicantData.first_name }} {{ applicantData.last_name }}
              </h3>
              <p>{{ applicantData.email }}</p>
            </div>
          </div>
          <div class="headerRight">
            <div></div>
          </div>
        </div>
        <div class="profileFooter">
          <div class="footerTabs">
            <p
              :class="{ activeTab: activeTab === 'Personal' }"
              @click="changeTab('Personal')"
            >
              Personal
            </p>

            <p
              :class="{ activeTab: activeTab === 'Education' }"
              @click="changeTab('Education')"
            >
              Education
            </p>
            <p
              :class="{ activeTab: activeTab === 'Professional' }"
              @click="changeTab('Professional')"
            >
              Professional
            </p>
            <p
              :class="{ activeTab: activeTab === 'Work Experience' }"
              @click="changeTab('Work Experience')"
            >
              Work Experience
            </p>
            <p
              :class="{ activeTab: activeTab === 'Projects' }"
              @click="changeTab('Projects')"
            >
              Projects
            </p>
            <p
              :class="{ activeTab: activeTab === 'Achievements' }"
              @click="changeTab('Achievements')"
            >
              Achievements
            </p>
            <p
              :class="{ activeTab: activeTab === 'Certifications' }"
              @click="changeTab('Certifications')"
            >
              Certifications
            </p>

            <p
              :class="{ activeTab: activeTab === 'Documents' }"
              @click="changeTab('Documents')"
            >
              Documents
            </p>
          </div>
        </div>
      </div>
      <div class="tabContent">
        <div v-if="activeTab === 'Personal'">
          <personal-tab />
        </div>
        <div v-if="activeTab === 'Education'">
          <education-tab />
        </div>
        <div v-if="activeTab === 'Professional'">
          <professional-tab />
        </div>
        <div v-if="activeTab === 'Projects'">
          <project-tab
            :applicantData.sync="applicantData"
            :skillSet="skillSet"
            :isApplicantLoggedIn="true"
            :isApplicantProfile="true"
          ></project-tab>
        </div>
        <div v-if="activeTab === 'Certifications'">
          <course-tab
            :applicantData.sync="applicantData"
            :skillSet="skillSet"
            :isApplicantLoggedIn="true"
            :isApplicantProfile="true"
          ></course-tab>
        </div>
        <div v-if="activeTab === 'Work Experience'">
          <job-tab
            :applicantData.sync="applicantData"
            :skillSet="skillSet"
            :isApplicantLoggedIn="true"
            :isApplicantProfile="true"
          ></job-tab>
        </div>
        <div v-if="activeTab === 'Documents'">
          <document-tab
            :applicantData.sync="applicantData"
            :isApplicantLoggedIn="true"
            :isApplicantProfile="true"
          ></document-tab>
        </div>
        <div v-if="activeTab === 'Achievements'">
          <div class="commonPadding">
            <div class="addDetailsContainer">
              <div class="addDetailsHeader">
                <div class="addDetailsImage">
                  <b-icon
                    icon="file-earmark"
                    class="h3"
                    aria-hidden="true"
                  ></b-icon>
                </div>
                <div class="addDetailItem">
                  <div class="addDetailItemTitle">
                    Add Achievements / Extracurricular Activity
                  </div>
                  <div class="addDetailItemSubtitle">
                    Add your achievements of Hackathons, NGO services, Exam
                    ranks, Clubs, etc.
                  </div>
                </div>
              </div>
              <button
                @click="showAchievement"
                v-b-modal.edit-achievement-modal
                type="button"
                class="btn btn-cp ml-2"
              >
                Add
              </button>
              <b-modal
                id="edit-achievement-modal"
                ref="modal"
                no-close-on-backdrop
                :title="(isAchievementEdit ? 'Edit' : 'Add') + ' Achievement'"
                @ok.prevent="submitAchievement"
              >
                <div class="col-12 pr-sm-2 mb-3">
                  <label for="achievement" class="required modalfont"
                    >Achievement
                  </label>
                  <textarea
                    id="achievement"
                    name="achievement"
                    key="achievement"
                    v-validate.attach="'required|min:3|max:60'"
                    :class="{ invalid: errors.first('achievement') }"
                    type="text"
                    rows="1"
                    placeholder="Enter Extra Activity"
                    class="ach-input"
                    v-model="newAchievement"
                  >
                  </textarea>
                  <span
                    v-if="errors.first('achievement')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("achievement") }}</span
                  >
                </div>

                <template #modal-footer="{ ok }">
                  <b-button size="sm" @click="ok()" class="btn btn-cp ml-2">{{
                    isAchievementEdit ? "Edit" : "Add"
                  }}</b-button>
                </template>
              </b-modal>
            </div>

            <delete-modal
              :modal-id="'deleteModalAchievements'"
              item="Achievement"
              :on-delete="removeAchievement"
              :item-id="achievementIndex"
            ></delete-modal>
            <div v-if="applicantData.achievements.length > 0">
              <div>
                <table
                  class="table table-striped table-hover"
                  aria-describedby="Achievements Table"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(ach, index) in applicantData.achievements"
                      :key="index"
                    >
                      <td>{{ index + 1 }}</td>
                      <td style="width: 80%">{{ ach }}</td>
                      <td class="d-flex">
                        <button
                          v-b-modal.edit-achievement-modal
                          type="button"
                          class="btn btn-sm"
                          title="Edit"
                          @click="editAchievement(index)"
                        >
                          <b-icon
                            icon="pencil-square"
                            variant="primary"
                          ></b-icon>
                        </button>
                        <button
                          class="btn btn-sm"
                          id="delete_job_icon"
                          href="#"
                          role="button"
                          data-toggle="modal"
                          data-target="#deleteModalAchievements"
                          data-backdrop="static"
                          data-keyboard="false"
                          @click="AchievementToDeleteId(index)"
                        >
                          <b-icon icon="trash" variant="primary"></b-icon>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { employment_status } from "../../Common/commonData";
import { mapGetters } from "vuex";
import config from "../../../config";
import DeleteModal from "./delete-modal.vue";
import ProjectTab from "./tabs/project-tab.vue";
import JobTab from "./tabs/job-tab.vue";
import CourseTab from "./tabs/course-tab.vue";
import DocumentTab from "./tabs/document-tab.vue";
import PersonalTab from "./tabs/personal-tab.vue";
import EducationTab from "./tabs/education-tab.vue";
import ProfessionalTab from "./tabs/professional-tab.vue";
import userProfile from "../../../assets/images/avatar.png";

export default {
  data() {
    return {
      activeTab: "Personal",
      config,
      avatar: userProfile,
      initialApplicantData: {},
      applicantData: {
        achievements: [],
        first_name: null,
        last_name: null,
        email: null,
        dob: null,
        gender: "Male",
        position: null,
        mobile_no: null,
        alternate_mobile_no: null,
        address_line_1: null,
        address_line_2: null,
        current_address_line_1: null,
        current_address_line_2: null,
        current_state: null,
        city: null,
        state: null,
        current_city: null,
        resume: null,
        project_links: [],
        previous_ctc: null,
        current_ctc: null,
        expected_ctc: null,
        current_role: null,
        previous_role: null,
        notice_period: null,
        experience: null,
        relevant_experience: null,
        reason_for_leaving: null,
        skills: [],
        education_details: {
          institute_name: "",
          start_date: "",
          end_date: "",
          semester1: "",
          semester2: "",
          semester3: "",
          semester4: "",
          semester5: "",
          semester6: "",
          semester7: "",
          semester8: "",
          aggregate_percentage: "",
          hsc_percentage: "",
          grad_percentage: "",
          ssc_percentage: "",
          stream: "",
          degree: "",
          grade_points: "",
        },
        employment_status: "Working",
        references: {
          ref1: {
            name: null,
            designation: null,
            contact_no: null,
          },
          ref2: {
            name: null,
            designation: null,
            contact_no: null,
          },
        },
      },
      courseToDeleteId: null,
      projectToDeleteId: null,
      jobToDeleteId: null,
      achievementIndex: null,
      newAchievement: "",
      isAchievementEdit: false,
      selectedFiles: [],
      disabled: {
        from: new Date(
          new Date().getFullYear() - 18,
          new Date().getMonth(),
          new Date().getDate()
        ),
      },
    };
  },
  computed: {
    employment_status() {
      return employment_status;
    },
    ...mapGetters({
      applicantDataFromStore: "$_applicant_master/getLoggedInApplicant",
      skillSet: "system_config/jobOpeningSkills",
      profilePic: "$_applicant_master/getProfilePic",
    }),
  },
  components: {
    PersonalTab,
    EducationTab,
    ProfessionalTab,
    "delete-modal": DeleteModal,
    "project-tab": ProjectTab,
    "job-tab": JobTab,
    "course-tab": CourseTab,
    "document-tab": DocumentTab,
  },
  watch: {
    applicantDataFromStore(val) {
      this.applicantData = { ...val };
      this.initialApplicantData = JSON.parse(JSON.stringify(val));
    },

    "applicantData.employment_status"() {
      this.$validator.reset();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.applicantData = { ...this.applicantDataFromStore };
    this.initialApplicantData = JSON.parse(
      JSON.stringify(this.applicantDataFromStore)
    );
    if (this.applicantData.resume)
      this.$store.dispatch("$_applicant_master/getApplicantResume");
    if (this.applicantData.sscmarksheet)
      this.$store.dispatch("$_applicant_master/getApplicantSscMarksheet");
    if (this.applicantData.hsmarksheet)
      this.$store.dispatch("$_applicant_master/getApplicantHsMarksheet");
  },
  methods: {
    showAchievement() {
      this.newAchievement = "";
      this.isAchievementEdit = false;
    },
    async submitAchievement() {
      await this.$validator.validate("achievement");
      if (this.$validator.errors.items.length > 0) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Please enter valid achievement!",
        });
        return;
      }

      if (this.isAchievementEdit)
        this.applicantData.achievements[this.achievementIndex] =
          this.newAchievement;
      else this.applicantData.achievements.push(this.newAchievement);

      this.$bvModal.hide("edit-achievement-modal");
      this.handleSubmit();
    },

    AchievementToDeleteId(index) {
      this.achievementIndex = index;
    },

    editAchievement(index) {
      this.isAchievementEdit = true;
      this.newAchievement = this.applicantData.achievements[index];
      this.achievementIndex = index;
    },
    removeAchievement() {
      this.applicantData.achievements.splice(this.achievementIndex, 1);
      this.handleSubmit();
    },

    changeTab(tab) {
      this.activeTab = tab;
    },
    handleSubmit() {
      this.$validator.validateAll().then(() => {
        const fieldsToRemove = [];
        switch (this.applicantData.employment_status) {
          case "Freelancer":
          case "Working":
            fieldsToRemove.push("previous_CTC", "previous_role");
            break;
          case "Other":
            fieldsToRemove.push("current_CTC", "notice_period", "current_role");
            break;
        }
        fieldsToRemove.forEach((field) => this.$validator.errors.remove(field));

        if (this.$validator.errors.items.length === 0) {
          const changedData = Object.keys(this.applicantData).reduce(
            (acc, key) => {
              if (key === "achievements") {
                if (
                  this.applicantData[key].length !==
                    this.initialApplicantData[key].length ||
                  this.applicantData[key].some(
                    (value, i) => value !== this.initialApplicantData[key][i]
                  )
                ) {
                  acc[key] = this.applicantData[key];
                }
              } else if (
                this.applicantData[key] !== this.initialApplicantData[key]
              ) {
                acc[key] = this.applicantData[key];
              }
              return acc;
            },
            {}
          );

          const detailsToDelete = [
            "jobDetails",
            "courseDetails",
            "projectDetails",
          ];
          detailsToDelete.forEach((detail) => delete changedData[detail]);

          if (Object.keys(changedData).length > 0) {
            this.$store.dispatch(
              "$_applicant_master/updateApplicantProfile",
              changedData
            );
          }
        } else {
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Please enter valid values!",
          });
        }
        this.newAchievement = "";
      });
    },
  },
};
</script>

<style scoped>
@import "./tabStyles.css";

.mainContainer {
  width: 100%;
  padding: 40px;
}
.profileContainer {
  width: 100%;
  height: 40%;
  background: linear-gradient(
    94.89deg,
    rgba(235, 240, 245, 0.5) 43.87%,
    rgba(247, 248, 250, 0.5)
  );
  border: 1px solid #ebf0f5;
  border-radius: 10px;
  padding: 30px 30px 0;
}
.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerLeft {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}
.profilepicture {
  padding: 3px;
  border-radius: 50%;
  border: 1px solid #ebf0f5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  background-color: #fff;
}
.imgSize {
  height: 72px;
  width: 72px;
  border-radius: 50%;
}
.headerRight {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}
.title {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.6rem;
  letter-spacing: -0.02em;
  color: #1e272e;
  padding-top: 5px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.profileFooter {
  margin-top: 37px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: space-between;
  align-items: flex-end;
}
.footerTabs {
  width: 100%;
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  align-items: center;
  margin-bottom: -1px;
}

.footerTabs p {
  padding-bottom: 15px;
  white-space: nowrap;
  cursor: pointer;
  color: #566474;
}

.addAchievement {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex: 1 1;
  height: auto !important;
}
.addAchievement textarea {
  flex: 1 1;
  height: auto !important;
}

@media screen and (max-width: 1200px) {
  .profileFooter {
    margin-top: 20px;
  }

  .footerTabs {
    flex-wrap: wrap;
    grid-gap: 10px 20px;
    gap: 10px 20px;
    padding-bottom: 10px;
  }

  .footerTabs p {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .commonPadding {
    padding: 20px 0px;
  }

  .addAchievement {
    display: block;
  }
}

@media screen and (max-width: 474px) {
  .footerTabs {
    grid-gap: 12px;
    gap: 12px;
  }
}

.activeTab {
  font-weight: 700;
  border-bottom: 1px solid #445ee2;
  color: #445ee2;
}

.ach-input {
  padding: 12px 10px;
  width: 100%;
  border: 1px solid #ebf0f5;
  color: #1e272e;
  border-radius: 5px;
  font-size: 1rem;
  margin: 3px 0;
  transition: 0.1s;
  flex: 1 1;
  height: auto !important;
}

.cardContainer {
  border: 1px solid #ebf0f5;
  border-radius: 10px;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 30px 30px 30px 45px;
  position: relative;
}
.cardInContainer {
  width: 100%;
}
.cardHeader {
  font-weight: 700;
  color: #222f3e;
  font-size: 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.cardSkillTag {
  background-color: #f7f8fa;
  color: #566474;
  font-size: 1rem;
  border-radius: 20px;
  padding: 4px 12px;
  margin-right: 10px;
  white-space: nowrap;
}
.cardLinks {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
}
.cardDescription {
  font-size: 0.875rem;
  color: #566474;
  margin-top: 16px;
  padding-left: 15px;
}
.form_page_wrapper {
  width: 1140px;
  padding-bottom: 40px;
  margin: 0 auto;
  margin-top: -70px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
}
@media screen and (max-width: 1366px) {
  .form_page_wrapper {
    width: 80%;
  }
}
@media screen and (max-width: 767px) {
  .form_page_wrapper {
    width: 90%;
  }
}
.form_page_wrapper .form_header {
  font-size: 26px;
  text-align: center;
  font-weight: 600;
  padding-top: 15px;
}
.form_page_wrapper .form_page_inner {
  padding: 10px 40px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
}
.form_page_wrapper .form_page_inner .from_subheading {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  position: relative;
  margin-bottom: 24px;
}
.form_page_wrapper .form_page_inner .from_subheading:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 14px;
  width: calc(100% - 180px);
  height: 1px;
  background: #dbdbdb;
}
.form_page_wrapper .form_page_inner .from_subheading-2:after {
  content: "";
  width: calc(100% - 147px);
}
.form_page_wrapper .file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.form_page_wrapper .file-input__label {
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  height: 38px;
  width: 140px;
  text-align: center;
  background-color: #686868;
}

.custom-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 20px;
}

.custom-button2 {
  background-color: #f9f9f9; /* Green */
  border: white solid 0.5;
  color: Black;
  padding: 10px 17px;
  text-align: center;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.form_page_wrapper .file-input__label i {
  font-size: 22px;
  padding-right: 6px;
  position: relative;
  top: 3px;
}
.form_page_wrapper .star {
  color: red;
}
.form_page_wrapper label {
  font-weight: 600;
  font-size: 14px;
}
.form_page_wrapper input:focus {
  box-shadow: none;
}
.form_page_wrapper select:focus {
  box-shadow: none;
}
.form_page_wrapper textarea:focus {
  box-shadow: none;
}
.form_page_wrapper .references_section {
  background: #f5f5f5;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}
.form_page_wrapper .references_section h4 {
  font-size: 20px;
  margin-bottom: 0px;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bottomslide {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes rightslide {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@keyframes spin {
  10% {
    width: 0;
    height: 0;
    border-width: 6px;
  }
  30% {
    width: 0;
    height: 0;
    border-radius: 50%;
    border-width: 1em;
    transform: rotate(0deg);
    border-color: #c7daf5;
  }
  50% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }
  70% {
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }
  90% {
    border-width: 4px;
  }
  100% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    transform: rotate(720deg);
    border-color: transparent;
  }
}
</style>
