<template>
  <div class="container-fluid page-bg main-container nopadding">
    <div class="text-center mt-30">
      <img
        class="logoSize"
        id="argus-logo"
        src="../../assets/images/newLogo.png"
        alt="Argusoft Logo"
      />
    </div>
    <h1>403</h1>
    <div class="container">
      <h3>Forbidden: Access to this resource is forbidden.</h3>
      <hr />
      <p>Make sure you have rights to the thing you tried to access.</p>
      <p>
        Please contact your Argusoft administrator if you think this is a
        mistake.
      </p>
      <router-link
        :to="{
          name: routerName,
        }"
        >Go to
        {{ routerName === "home" ? "Homepage" : "Dashboard" }}</router-link
      >
    </div>
  </div>
</template>

<script>
import VueCookie from "vue-cookie";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      lastUrlIndex: "user/lastUrlIndex",
      loggedInUser: "user/loggedInUser",
    }),
    routerName() {
      if (VueCookie.get("RECRUITER_ACCESS_TOKEN")) {
        return "RecruiterApplicantData";
      } else if (VueCookie.get("APPLICANT_ACCESS_TOKEN")) {
        return "applicantApplications";
      } else if (this.loggedInUser) {
        return "homepage";
      } else {
        return "home";
      }
    },
  },
  mounted() {
    $("#navmenu").hide();
    $("#footer").hide();
  },
  methods: {
    goBack() {
      window.history.go(-2);
    },
  },
};
</script>

<style scoped>
.main-container {
  color: #666;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-top: 75px;
  font-size: 14px;
}

h1 {
  font-size: 56px;
  line-height: 100px;
  font-weight: 400;
  color: #456;
}

h3 {
  color: #456;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

hr {
  max-width: 800px;
  margin: 18px auto;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid white;
}

img {
  max-width: 40vw;
  display: block;
  margin: 40px auto;
}

a {
  line-height: 100px;
  font-weight: 400;
  color: #4a8bee;
  font-size: 18px;
  text-decoration: none;
}
</style>
