<template>
  <div>
    <!--job-banner-->
    <div class="job__banner">
      <div class="container">
        <div class="col-lg-6">
          <h1>Unleash your<span> Talent and Grow!</span></h1>
          <h3>join our Team</h3>
        </div>
      </div>
    </div>
    <!--job-banner-END-->
    <!--breadcrumb-->
    <div class="container">
      <nav aria-label="breadcrumb_custom">
        <ol class="breadcrumb job_breadcrumb text-uppercase bg-white">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'home' }">careers</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">home</li>
        </ol>
      </nav>
    </div>
    <!--breadcrumb-END-->

    <!--Form-->
    <form>
      <div class="container">
        <div class="text-center">
          <h1>Current Openings</h1>
          <p class="text-size-22">
            enhance your career &amp; achieve work-life balance with a holistic
            approach
          </p>
        </div>

        <div class="form_wrapper" style="display: none">
          >
          <div class="row align-items-center">
            <div class="col-sm-6 col-md-3 p-2">
              <div class="form-group">
                <label for="email">Category</label>
                <select
                  class="form-control"
                  style="background-color: white"
                  name="job_categories"
                  v-model="selectedCategory"
                  @change="onChangeCategory"
                  title="Select Category"
                >
                  <option
                    selected
                    v-bind:value="0"
                    data-toggle="popover"
                    title="Popover Header"
                    data-content="Some content inside the popover"
                  >
                    All Jobs
                  </option>
                  <option
                    v-for="category in currentOpenings.categories"
                    :key="category.id"
                    :value="category.id"
                    data-toggle="popover"
                    title="Popover Header"
                    data-content="Some content inside the popover"
                  >
                    {{ category.category_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-6 col-md-2 p-2">
              <div class="form-group">
                <label for="email">Job Title</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="searchByJobTitle"
                  @keydown="isEnter($event)"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-2 p-2">
              <div class="form-group">
                <label for="email">Skills</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="searchBySkill"
                  @keydown="isEnter($event)"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-2 p-2">
              <div class="form-group">
                <label for="email">Experience</label>
                <input
                  type="number"
                  min="0"
                  class="form-control"
                  v-model="searchByExperience"
                  @keydown="isEnter($event)"
                />
              </div>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="row align-items-center" style="margin-top: 13px">
                <button
                  type="button"
                  class="btn btn-cp ml-2"
                  @click="onFindJobs"
                >
                  Find Jobs
                </button>
                <router-link :to="{ name: 'home' }">Cancel</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!--Form--END-->

    <!--job-card-->

    <div class="container">
      <div class="job_card_wrapper" v-if="isDisplayDataAvailable">
        <div class="job_card" v-for="(job, index) in sortedData" :key="index">
          <div class="job_card_header">
            <img alt="Job" src="../../../assets/images/job_icon.jpg" />
            <h2>{{ job.job_name }}</h2>
          </div>
          <p style="height: 70px">
            <span>Required Skills </span> {{ job.required_skills.join(", ") }}
          </p>
          <p>
            <span>Experience Required</span> {{ job.required_experience_min }}
            {{
              job.required_experience_max
                | expFormat(job.required_experience_min)
            }}
            years
          </p>
          <div class="job_card_footer">
            <router-link
              :to="{ name: 'jobProfile', params: { jobId: job.slug } }"
              tag="a"
              >Specification
              <i class="fa fa-long-arrow-right" aria-hidden="true"></i
            ></router-link>
          </div>
        </div>
      </div>
      <div v-else>
        <h4 v-if="isJobOpeningsAvailable">
          &nbsp;&nbsp;There are no current openings matching your criteria.
        </h4>
        <h4 v-else>&nbsp;&nbsp;There are no current openings.</h4>
      </div>
    </div>

    <!--job-card-END-->
    <display-review></display-review>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import displayReview from "./display-review.vue";

/**
 * @memberof module:Home
 * @namespace components.job_openings
 */
export default {
  /**
   * @memberof module:Home.components.job_openings
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  components: {
    "display-review": displayReview,
  },
  data() {
    return {
      isDisplayDataAvailable: true,
      isJobOpeningsAvailable: false,
      displayData: [],
      categorisedDisplayData: null,
      searchByJobTitle: "",
      searchBySkill: "",
      searchBySkillArray: "",
      searchByExperience: "",
      selectedCategory: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({
      currentOpenings: "$_home/activeCategoriesWithJobs",
    }),
    sortedData: function () {
      function compare(a, b) {
        if (a.job_name < b.job_name) return -1;
        if (a.job_name > b.job_name) return 1;
        return 0;
      }
      const sortedArray = [...this.displayData];
      return sortedArray.sort(compare);
    },
  },
  watch: {
    /**
     * @memberof module:Home.components.job_openings
     * @param {Object} value new value
     * @description watch - changes values of Current Opening when data is udpated from server
     */
    currentOpenings(value) {
      this.isJobOpeningsAvailable = value.jobs.length !== 0;
      this.currentOpenings.jobs = value.jobs.filter(
        (data) => data.status !== "inactive"
      );
      this.currentOpenings.categories = value.categories.filter(
        (data) => data.status !== "inactive"
      );
      this.displayData = value.jobs;
    },

    /**
     * @memberof module:Home.components.job_openings
     * @param {Object} value new value
     * @description watch - changes values of flag when data is updating
     */
    displayData(value) {
      this.isDisplayDataAvailable = value.length !== 0;
    },
  },
  filters: {
    /**
     * @memberof module:Home.components.job_opening_element
     * @description filter - handles to change format of experience
     */
    expFormat(value, arg1) {
      if (!value) return "+";
      else {
        if (arg1 == value) return "";
        else return "to " + value.toString();
      }
    },
  },
  methods: {
    /**
     * @memberof module:Home.components.job_openings
     * @param {Object} event event object
     * @description method - changes values of flag when data is updating
     */
    isEnter(event) {
      if (event.keyCode === 13) {
        this.onFindJobs();
      }
    },

    /**
     * @memberof module:Home.components.job_openings
     * @param {Object} jobData Job Detail object
     * @description method - handles to open modal of job description
     */
    openJobDescriptionModal(jobData) {
      let htmlContent = "";
      if (jobData.job_posting_image !== null) {
        htmlContent +=
          "<img src='data:image;base64," +
          jobData.job_posting_image +
          "' style='display: block;margin-left: auto;margin-right: auto;width: 50%;'>" +
          jobData.job_description;
      } else {
        htmlContent = jobData.job_description;
      }
      this.openDialog(
        "jobDescModal",
        "Job description of : " + jobData.job_name,
        htmlContent,
        false
      );
    },

    /**
     * @memberof module:Home.components.job_openings
     * @description method - handles to categorise jobs
     */
    generateCategorisedData() {
      if (this.selectedCategory !== 0) {
        this.displayData = this.categorisedDisplayData.filter(
          (jobs) => jobs.categoryId === this.selectedCategory
        );
      } else {
        this.displayData = this.currentOpenings.jobs;
      }
    },

    /**
     * @memberof module:Home.components.job_openings
     * @description method - handles to clear input fields when job category is changed
     */
    onChangeCategory() {
      this.categorisedDisplayData = this.currentOpenings.jobs;

      this.generateCategorisedData();

      this.searchByJobTitle = "";
      this.searchBySkill = "";
      this.searchByExperience = "";
    },

    /**
     * @memberof module:Home.components.job_openings
     * @description method - handle to filter jobs based on input
     */
    onFindJobs() {
      this.generateCategorisedData();

      this.displayData = this.displayData.filter((job) => {
        // Filter by job title
        const matchTitle =
          this.searchByJobTitle !== "" &&
          job.job_name
            .toLowerCase()
            .includes(this.searchByJobTitle.toLowerCase());

        // Filter by skills
        const matchSkills =
          this.searchBySkill !== "" &&
          this.searchBySkillArray.every((searchedSkill) =>
            job.required_skills.some((skill) =>
              skill.toLowerCase().includes(searchedSkill)
            )
          );

        // Filter by experience
        const matchExperience =
          this.searchByExperience !== "" &&
          (!job.required_experience_min ||
            job.required_experience_min <= this.searchByExperience) &&
          (!job.required_experience_max ||
            job.required_experience_max >= this.searchByExperience);

        return matchTitle && matchSkills && matchExperience;
      });
    },
  },

  /**
   * @memberof module:Home.components.job_openings
   * @description lifecycle method - handles to dispatch request to get Job categories and Jobs
   */
  created() {
    // this.$store.dispatch("$_home/fetchCategoriesWithJobs");
    this.$store.dispatch("$_home/fetchActiveCategoriesWithJobs");
  },
};
</script>

<style scoped>
body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #62555b;
}

/*job__banner-css*/
.job__banner {
  background-image: url(../../../assets/images/home_banner.jpg);
  height: 250px;
  margin-top: 70px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.job__banner h1 {
  font-size: 60px;
  font-weight: 400;
}
.job__banner h1 span {
  color: #903564;
  display: block;
  font-weight: 800;
}
@media screen and (max-width: 991px) {
  .job__banner h1 {
    font-size: 32px;
  }
}
.job__banner h3 {
  font-size: 40px;
  text-transform: uppercase;
}
@media screen and (max-width: 991px) {
  .job__banner h3 {
    font-size: 24px;
  }
}

/*job__banner-css-END*/
/*job_breadcrumb-css*/
.job_breadcrumb li a {
  color: #62555b;
}
.job_breadcrumb .active {
  color: #903564 !important;
}

/*job_breadcrumb-css*/
/*form_wrapper-css*/
.form_wrapper {
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 40px;
}
.form_wrapper .btn {
  height: 38px;
}
@media screen and (max-width: 991px) {
  .form_wrapper .btn {
    padding: 8px 12px;
  }
}
.form_wrapper a {
  color: #2e3941;
  transition: all 0.2s;
  text-decoration: underline;
  padding-left: 20px;
}
@media screen and (max-width: 991px) {
  .form_wrapper a {
    padding-left: 10px;
  }
}
.form_wrapper a:hover {
  color: #8f1452;
}
.form_wrapper input:focus {
  box-shadow: none;
  border-color: #8f1452;
}
.form_wrapper select:focus {
  box-shadow: none;
  border-color: #8f1452;
}

/*form_wrapper-css-END*/
/*job_card-css*/
.job_card_wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.job_card {
  margin: 10px;
  margin-bottom: 30px;
  width: 350px;
  min-height: 380px;
  position: relative;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 0px 20px;
  font-size: 15px;
  line-height: 22px;
}
.job_card .job_card_header {
  margin: 30px 0px;
  text-align: center;
}
.job_card .job_card_header h2 {
  font-size: 22px;
  font-weight: 700;
  margin: 15px 0px;
  color: #2e3941;
  line-height: 24px;
}
.job_card span {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  color: #75828a;
  margin-bottom: 5px;
  font-size: 14px;
}
.job_card .job_card_footer {
  border-top: 1px solid #cccccc;
  padding-top: 15px;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 15px;
  width: calc(100% - 40px);
  text-align: right;
}
.job_card .job_card_footer a {
  color: #903564;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.job_card .job_card_footer a i {
  padding-left: 5px;
}
.job_card .job_card_footer a:hover {
  color: #903564;
}

/*job_card-css*/
/*Job_page_2-css*/
.job_card_lg {
  background: #fff;
  position: relative;
  min-height: 600px;
  padding: 20px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 991px) {
  .job_card_lg {
    margin: 15px 0px;
  }
}
.job_card_lg h2 {
  font-size: 32px;
  margin: 15px 0px;
  font-weight: 700;
  position: relative;
}
@media screen and (max-width: 991px) {
  .job_card_lg h2 {
    font-size: 24px;
  }
}
.job_card_lg h2:after {
  content: "";
  width: 90px;
  height: 5px;
  position: absolute;
  bottom: -8px;
  left: 0px;
  background: #8f1452;
}
.job_card_lg p {
  font-size: 15px;
  margin-top: 25px;
}
.job_card_lg .job_card_lg_footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.job_card_lg .job_card_lg_footer a {
  color: #903564;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.job_card_lg .job_card_lg_footer a i {
  padding-left: 5px;
}
.job_card_lg .job_card_lg_footer a:hover {
  color: #903564;
}

.benefits_content {
  margin-top: 40px;
  text-align: center;
}
.benefits_content h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 12px 0px;
}
.benefits_content p {
  font-size: 16px;
  padding: 0px 40px;
}

.video_play {
  padding: 20px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
}
.video_play video {
  width: 100%;
}

.job_top_spacer_100 {
  margin-top: 100px;
}
@media screen and (max-width: 991px) {
  .job_top_spacer_100 {
    margin-top: 60px;
  }
}

.argonaut h1 {
  font-size: 80px;
  font-weight: 100;
  text-transform: uppercase;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 991px) {
  .argonaut h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
.argonaut .argonaut_img_box {
  width: 260px;
  margin-top: 2px;
  margin-right: 2px;
  height: 260px;
  overflow: hidden;
}
.argonaut .argonaut_img_box img {
  height: 100%;
  object-fit: contain;
}
.argonaut p {
  font-size: 18px;
  margin-top: 40px;
  color: #62555b;
}
@media screen and (max-width: 991px) {
  .argonaut .large_logo {
    width: 270px;
  }
}

h1 {
  font-size: 48px;
  color: #2e3941;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  h1 {
    font-size: 32px;
  }
}

.text-size-22 {
  font-size: 22px;
}
@media screen and (max-width: 991px) {
  .text-size-22 {
    font-size: 18px;
  }
}
</style>
