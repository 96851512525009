<template>
  <div class="content">
    <applicant-info></applicant-info>
    <round-info :slug="this.$route.params.slug"></round-info>
    <comments-section></comments-section>
    <follow-up-status />
    <email-conversation
      v-if="
        hasAccess({
          permissions: [PERMISSIONS.MAIL.MENU],
          checkAll: false,
        })
      "
    ></email-conversation>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApplicantInfo from "./applicant-info.vue";
import RoundInfo from "./round-info.vue";
import CommentsSection from "./comments-section.vue";
import EmailConversation from "./email-conversation.vue";
import FollowUpStatus from "./follow-up-status.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.applicant_profile
 * @description Applicant Profile component
 */
export default {
  components: {
    ApplicantInfo,
    RoundInfo,
    CommentsSection,
    EmailConversation,
    FollowUpStatus,
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
    }),
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
  },
};
</script>
