import store from "../../../store/index";
import recruiterStore from "../_store/index";
import api from "../_api/index";

export const addRecruiterModule = () => {
  const STORE_KEY = "$_recruiter";
  if (!(STORE_KEY in store._modules.root._children)) {
    store.registerModule(STORE_KEY, recruiterStore);
  }
};

export const setLoggedInRecruiter = async () => {
  try {
    const recruiterData = await api.fetchRecruiterData();
    store.dispatch("$_recruiter/setLoggedInUser", recruiterData.data);
  } catch (error) {
    store.dispatch("$_recruiter/setLoggedInUser", null);
    throw error;
  }
};
