<template>
  <div
    class="modal fade"
    id="cancelRoundModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">
            Select template to cancel round
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group mb-0">
              <div class="col-sm-9">
                <label class="d-flex mb-0">
                  <input type="checkbox" class="mr-1" v-model="sendMail" />
                  Send mail
                </label>
              </div>
            </div>
            <div class="form-group" v-if="sendMail">
              <div class="d-flex justify-content-between align-items-center">
                <label class="control-label">Select Mail Template</label>
              </div>
              <select
                class="custom-select main college mr-2"
                v-model="mail_template"
              >
                <option disabled value="">Select Mail Template</option>
                <option
                  v-for="(mailTemplate, index) in filteredMailTemplates"
                  :value="mailTemplate.id"
                  :key="index"
                >
                  {{ mailTemplate.name }}
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark btn-sm"
            data-dismiss="modal"
          >
            <strong>Cancel</strong>
          </button>
          <button
            :disabled="sendMail && !mail_template"
            type="button"
            class="btn btn-cp ml-2"
            @click="submit"
          >
            <i class="fa fa-book fa-lg" aria-hidden="true"></i>
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../_api/index";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  props: ["selectedSlotData"],
  data() {
    return {
      sendMail: true,
      cancelRound: false,
      mail_template: null,
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      mailTemplates: "$_mailbox/getTemplates",
    }),
    filteredMailTemplates() {
      if (this.mailTemplates) {
        return this.mailTemplates.filter((mail) => {
          return mail.key == "Schedule Cancel";
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    selectedInstance(value) {
      if (value) {
        const selectedId = this.instanceList.filter(
          (i) => i.label === this.selectedInstance
        )[0].id;
        api.getAllCourses(selectedId).then((res) => {
          this.courseList = res.data;
        });
      }
    },
    selectedCourse() {},
  },
  methods: {
    getAllMailTemplates() {
      this.$store.dispatch("$_mailbox/getTemplates");
    },
    submit() {
      this.$emit(
        "setSelctedMailTemplateCancelRound",
        this.sendMail ? this.mail_template : null
      );
      $("#cancelRoundModal").modal("hide");
    },
  },
  mounted() {
    $("#cancelRoundModal").on("shown.bs.modal", () => {
      this.mail_template = null;
      this.cancelRound = false;
      this.getAllMailTemplates();
    });
    $("#cancelRoundModal").on("hidden.bs.modal", () => {});
  },
  beforeDestroy() {
    $("#cancelRoundModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.custom-btn-gray {
  height: 35px;
  display: flex;
  align-items: center;
}
</style>
