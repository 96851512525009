<template>
  <div>
    <div class="media">
      <a class="pull-left"
        ><img
          class="img-responsive"
          :src="!!childItem.profile_pic ? childItem.profile_pic : avatar"
          alt="user"
      /></a>
      <div class="media-body">
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="media-heading">{{ childItem.userName }}</h5>
          </div>
          <template>
            <div v-if="loggedInUser.user_id == childItem.created_by">
              <!-- update button -->
              <button
                v-if="!updateParent"
                title="Edit"
                v-on:click="updateParentToggle()"
                class="btn magenta-icon mt-1 mr-1"
              >
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </button>
              <button
                v-else
                title="View"
                v-on:click="updateParentToggle()"
                class="btn magenta-icon mt-1 mr-1"
              >
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button>
            </div>
          </template>
        </div>
        <safe-content
          v-if="!updateParent"
          :content="childItem.comment"
          tag="p"
        ></safe-content>
        <template>
          <div v-if="updateParent">
            <common-rich-text-box
              placeholder="Your comments"
              :modalId="'thread-child-comments' + childItem.id"
              @onKeyUp="onKeyUp"
              @onBlur="onBlur"
              :width="'100%'"
              :height="'50'"
              :minHeight="'auto'"
              :haveMentions="true"
              :updateCode="childItem.comment"
              ref="commonRichTextBox"
            />

            <div class="form-group float-right m-t10">
              <label
                v-if="
                  hasAccess({ permissions: [BASE_PERMISSION['ADMIN-COMMENT']] })
                "
              >
                <input
                  type="checkbox"
                  class="control-label"
                  v-model="adminCheckbox"
                />
                <span class="ml-1">Only for Admins</span>
              </label>
              <button
                type="button"
                class="btn btn-cp ml-2"
                @click="submitUpdateParent(childItem)"
                :disabled="!isCompleteParent"
              >
                <i class="fa fa-paper-plane px-0" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </template>
        <div class="list-unstyled list-inline media-detail pull-left">
          <span class="date-tag">{{ childItem.updatedAt | dateFormat }}</span>
          <span
            v-if="childItem.createdAt != childItem.updatedAt"
            class="date-tag"
            >(edited)</span
          >
          <span v-if="childItem.showAdmin" class="date-tag"
            >(For Admin Only)</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
import { mapGetters } from "vuex";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  components: { commonRichTextBox },
  props: {
    childItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updateParent: false,
      childCommentText: "",
      updateParentText: "",
      adminCheckbox: false,
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
    }),
    isCompleteParent() {
      return this.childCommentText.length > 0;
    },
  },

  mounted() {},

  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    updateParentToggle() {
      this.adminCheckbox = this.childItem.showAdmin;
      this.updateParent = !this.updateParent;
    },

    deleteCommentById(item, user_id) {
      if (item.created_by == user_id) {
        this.$store.dispatch("$_applicant_profile/deleteCommentById", {
          item,
          user_id,
        });
      }
    },
    async submitUpdateParent(item) {
      if (item.created_by == this.loggedInUser.user_id) {
        await this.$store.dispatch("$_applicant_profile/updateComment", {
          data: {
            id: item.id,
            applicantId: item.applicantId,
            comment: this.childCommentText,
            created_by: item.created_by,
            userName: item.userName,
            showAdmin: this.adminCheckbox,
          },
          user_id: this.loggedInUser.user_id,
        });
        this.updateParent = false;
      }
    },
    onKeyUp(value) {
      this.childCommentText = value;
    },

    onBlur(value) {
      this.childCommentText = value;
    },
  },
};
</script>

<style scoped>
.img-responsive {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  margin-top: 2px;
}

.media {
  border-top: 1px solid #dddddd;
  padding-top: 15px;
}

.media > .pull-left {
  margin-right: 20px;
}
.media img {
  max-width: 100px;
}
.media h4 {
  margin: 0 0 10px;
}
.media h4 span {
  font-size: 14px;
  float: right;
  color: #999999;
}

.media p {
  text-align: justify;
  margin: auto;
}

.media-detail {
  margin: 0;
  width: 100%;
}

.media-detail span {
  font-size: 12px;
  font-weight: 600;
}

.date-tag {
  color: #aaaaaa;
  margin-top: 0%;
}

.media-detail a:hover {
  text-decoration: underline;
}

.reply-tag,
.reply-tag .fa-reply {
  color: #903564;
  font-size: 15px;
}

.reply-tag:hover,
.reply-tag .fa-reply:hover {
  text-decoration: underline;
  cursor: pointer;
}

.media-detail span:last-child {
  padding-right: 0;
}
.media-detail span i {
  color: #666666;
  font-size: 15px;
  margin-right: 10px;
}
.media-heading {
  color: #903564;
}
</style>
