<template>
  <select class="form-control" :class="inputClass" @change="emitChange($event)">
    <option value="-1">Select Template</option>
    <option v-for="(t, index) of mailTemplates" :key="index" :value="t.id">
      {{ t.name }}
    </option>
  </select>
</template>

<script>
import { mapGetters } from "vuex";

/**
 * @memberof module:MailBox
 * @namespace components.template_select
 * @description Mail template select input
 */
export default {
  props: {
    forId: {
      type: String,
      required: true,
    },
    inputClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapGetters({
      mailTemplates: "$_mailbox/getTemplates",
    }),
  },
  methods: {
    emitChange(event) {
      if (event.target.value === "-1") return;
      const template = this.mailTemplates.find(
        (t) => t.id === parseInt(event.target.value)
      );
      this.$emit("change", {
        targetId: this.forId,
        template,
      });
    },
  },
};
</script>
