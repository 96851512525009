<template>
  <div>
    <template v-if="hasAccess({ permissions: [BASE_PERMISSION.READ] })">
      <a
        v-if="params.data != undefined && params.data.hasDuplicate.length != 0"
        @click="resolveDuplicate(params.data.id)"
        ><i
          data-toggle="tooltip"
          data-placement="right"
          :title="
            'Resolve Duplicate for ' +
            params.data.first_name +
            ' ' +
            params.data.last_name
          "
          class="fa fa-link"
          aria-hidden="true"
          style="color: red; cursor: pointer"
        ></i
      ></a>
    </template>
    <a
      v-if="
        params.data != undefined &&
        params.data.hasDuplicate.length == 0 &&
        params.data.status == 'Duplicate'
      "
      ><i
        data-toggle="tooltip"
        data-placement="right"
        title="Duplicate applicant"
        class="fa fa-files-o"
        aria-hidden="true"
        style="color: red; cursor: pointer"
      ></i
    ></a>
  </div>
</template>

<script>
import router from "../../../router/index";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
/**
 * @memberof module:CampusRecruitment
 * @namespace components.resolve_duplicate_link
 * @description Resolve Duplicate component
 */
export default {
  data() {
    return {};
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:CampusRecruiment.components.resolve_duplicate_link
     * @description Resolve duplicates when matched for applicants
     */
    resolveDuplicate(id) {
      router.push({ name: "resolveduplicate", params: { id: id } });
    },
  },
};
</script>
