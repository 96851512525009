import { render, staticRenderFns } from "./base-modal.vue?vue&type=template&id=6187bd8e&scoped=true"
import script from "./base-modal.vue?vue&type=script&lang=js"
export * from "./base-modal.vue?vue&type=script&lang=js"
import style0 from "./base-modal.vue?vue&type=style&index=0&id=6187bd8e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6187bd8e",
  null
  
)

export default component.exports