<template>
  <div class="media" :id="'id' + item.id">
    <!-- Parent Comment -->
    <a class="pull-left"
      ><img
        class="img-responsive"
        :src="!!item.profile_pic ? item.profile_pic : avatar"
        alt="user"
    /></a>
    <div class="media-body">
      <div class="d-flex justify-content-between">
        <div>
          <h5 class="media-heading">{{ item.userName }}</h5>
        </div>
        <template>
          <div v-if="loggedInUser.user_id == item.created_by">
            <!-- update button -->
            <button
              v-if="!updateParent"
              title="Edit"
              v-on:click="updateParentToggle(item.id)"
              class="btn magenta-icon mt-1 mr-1"
            >
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
            </button>
            <button
              v-else
              title="View"
              v-on:click="updateParentToggle(item.id)"
              class="btn magenta-icon mt-1 mr-1"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </button>
          </div>
        </template>
      </div>
      <safe-content
        v-if="!updateParent"
        :content="item.comment"
        tag="p"
      ></safe-content>
      <!-- update comment box -->
      <template>
        <div v-if="updateParent">
          <common-rich-text-box
            placeholder="Your comments"
            :modalId="'parents-comments' + item.id"
            @onKeyUp="onKeyUpParent"
            @onBlur="onBlurParent"
            :width="'100%'"
            :height="'50'"
            :minHeight="'auto'"
            :haveMentions="true"
            :updateCode="item.comment"
            ref="commonRichTextBox"
          >
          </common-rich-text-box>
          <div class="form-group float-right m-t10">
            <label
              v-if="
                hasAccess({ permissions: [BASE_PERMISSION['ADMIN-COMMENT']] })
              "
            >
              <input
                type="checkbox"
                class="control-label"
                v-model="adminCheckbox"
              />
              <span class="ml-1">Only for Admins</span>
            </label>
            <button
              type="button"
              class="btn btn-cp ml-2"
              @click="submitUpdateParent(item)"
              :disabled="!isCompleteParent"
            >
              <i class="fa fa-paper-plane px-0" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </template>
      <div class="list-unstyled list-inline media-detail pull-left">
        <span class="date-tag">{{ item.date }}</span>
        <span v-if="item.createdAt != item.updatedAt" class="date-tag"
          >(edited)</span
        >
        <span v-if="item.showAdmin" class="date-tag">(For Admin Only)</span>
        <span class="ml-2">
          {{
            item.childComments && item.childComments.length > 0
              ? item.childComments.length
              : ""
          }}
        </span>
        <span class="reply-tag" @click="childCommentsVisibility" title="Reply">
          <i class="fa fa-reply" aria-hidden="true"></i>
        </span>
        <!-- List out all the child comments using self recursive component -->
        <template>
          <div
            v-show="
              childCommentsVisible &&
              item.childComments &&
              item.childComments.length > 0
            "
          >
            <div v-for="childItem in item.childComments" :key="childItem.id">
              <thread-child-comment
                :childItem="childItem"
                :loggedInUser="loggedInUser"
              ></thread-child-comment>
            </div>
          </div>
        </template>
        <!-- New child comments  -->
        <template>
          <div
            class="media-body"
            style="margin-left: -15px"
            v-if="childCommentsVisible"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="well container">
                  <div>
                    <div
                      class="media"
                      style="
                        border-top: none;
                        padding-top: 10px;
                        margin-bottom: 10px;
                      "
                    >
                      <a class="pull-left">
                        <img
                          class="img-responsive"
                          :src="
                            !!loggedInUser.profile_pic
                              ? loggedInUser.profile_pic
                              : avatar
                          "
                          alt="user"
                        />
                      </a>
                      <div class="w-100">
                        <div class="">
                          <common-rich-text-box
                            placeholder="Your comments"
                            modalId="threaded-comments"
                            @onKeyUp="onKeyUp"
                            @onBlur="onBlur"
                            :width="'100%'"
                            :height="'50'"
                            :minHeight="'auto'"
                            :haveMentions="true"
                            ref="commonRichTextBox"
                          />
                        </div>
                        <div class="form-group float-right m-t10">
                          <label
                            v-if="
                              hasAccess({
                                permissions: [BASE_PERMISSION['ADMIN-COMMENT']],
                              })
                            "
                          >
                            <input
                              type="checkbox"
                              class="control-label"
                              v-model="adminCheckbox"
                            />
                            <span class="ml-1">Only for Admins</span>
                          </label>
                          <button
                            type="button"
                            class="btn btn-cp ml-2"
                            @click="submitComment"
                            :disabled="!isComplete"
                          >
                            <i
                              class="fa fa-paper-plane px-0"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import userProfile from "../../../assets/images/icon.png";
import { mapGetters } from "vuex";
import moment from "moment";
import commonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
import threadChildComment from "./thread-child-comment.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  components: { commonRichTextBox, threadChildComment },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updateParentText: "",
      avatar: userProfile,
      childCommentsVisible: false,
      childCommentText: "",
      updateParent: false,
      adminCheckbox: false,
      updateAdminCheckbox: false,
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      commentList: "$_applicant_profile/getCommentListByApplicantId",
      id: "$_applicant_profile/fetchID",
      loggedInUser: "user/loggedInUser",
    }),
    isComplete() {
      return this.childCommentText.trim() != "";
    },
    isCompleteParent() {
      return this.updateParentText.length > 0;
    },
  },
  mounted() {
    if (this.$route.params.commentId) {
      // Scroll to the comment
      document
        .getElementById(`id${this.$route.params.commentId}`)
        .scrollIntoView({ block: "center", behavior: "smooth" });

      // make comment seen
      this.$store.dispatch(
        "$_applicants_data/setUserNotificationSeen",
        this.$route.params.commentId
      );

      delete this.$route.params.commentId;
    }
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    updateParentToggle() {
      this.adminCheckbox = this.item.showAdmin;
      this.updateParent = !this.updateParent;
    },
    childCommentsVisibility() {
      this.childCommentsVisible = !this.childCommentsVisible;
    },
    submitComment() {
      let commentInfo = {};
      commentInfo.created_by = this.loggedInUser.user_id;
      commentInfo.userName = this.loggedInUser.name;
      commentInfo.profile_pic = this.loggedInUser.profile_pic;
      commentInfo.comment = this.childCommentText;
      commentInfo.applicantId = this.id;
      commentInfo.parentCommentId = this.item.id;
      commentInfo.showAdmin = this.adminCheckbox;
      this.$store.dispatch("$_applicant_profile/addComment", commentInfo);
      this.childCommentText = "";
      this.$refs.commonRichTextBox.resetSummerNote();
      this.adminCheckbox = false;
    },
    deleteCommentById(item, user_id) {
      if (item.created_by == user_id) {
        this.$store.dispatch("$_applicant_profile/deleteCommentById", {
          item,
          user_id,
        });
      }
    },
    async submitUpdateParent(item) {
      if (item.created_by == this.loggedInUser.user_id) {
        await this.$store.dispatch("$_applicant_profile/updateComment", {
          data: {
            id: item.id,
            applicantId: item.applicantId,
            comment: this.updateParentText,
            created_by: item.created_by,
            userName: item.userName,
            showAdmin: this.adminCheckbox,
          },
          user_id: this.loggedInUser.user_id,
        });
        this.updateParent = false;
      }
    },
    onKeyUpParent(value) {
      this.updateParentText = value;
    },
    /**
     * set values of comments
     */
    onBlurParent(value) {
      this.updateParentText = value;
    },
    /**
     * set values of comments
     */
    onKeyUp(value) {
      this.childCommentText = value;
    },
    /**
     * set values of comments
     */
    onBlur(value) {
      this.childCommentText = value;
    },
  },
  filters: {
    dateFormat(date) {
      return moment(date).format(" MMMM Do YYYY, h:mm a");
    },
  },
};
</script>

<style scoped>
.img-responsive {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  margin-top: 2px;
}

.media {
  border-top: 1px solid #dddddd;
  padding-top: 15px;
}

.media > .pull-left {
  margin-right: 20px;
}
.media img {
  max-width: 100px;
}
.media h4 {
  margin: 0 0 10px;
}
.media h4 span {
  font-size: 14px;
  float: right;
  color: #999999;
}

.media p {
  text-align: justify;
  margin: auto;
}

.media-detail {
  margin: 0;
  width: 100%;
}

.media-detail span {
  font-size: 12px;
  font-weight: 600;
}

.date-tag {
  color: #aaaaaa;
  margin-top: 0%;
}

.media-detail a:hover {
  text-decoration: underline;
}

.reply-tag,
.reply-tag .fa-reply {
  color: #903564;
  font-size: 15px;
}

.reply-tag:hover,
.reply-tag .fa-reply:hover {
  text-decoration: underline;
  cursor: pointer;
}

.media-detail span:last-child {
  padding-right: 0;
}
.media-detail span i {
  color: #666666;
  font-size: 15px;
  margin-right: 10px;
}
.media-heading {
  color: #903564;
}
</style>
