<template>
  <div>
    <div class="row mx-0 review-category nopadding">
      <h3
        data-toggle="collapse"
        :data-target="'#panel' + reviewCategory.id"
        :aria-controls="'panel' + reviewCategory.id"
        class="flex-grow-1 pl-2 pointer"
        :id="'flip' + reviewCategory.id"
      >
        {{ reviewCategory.review_category_name }}
      </h3>
      <div class="text-right d-flex align-items-center">
        <div class="d-none d-md-inline">
          <a
            v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
            class="add-icon mr-3"
            id="add_cat_icon"
            v-tooltip.top="'Add Review'"
            href="#"
            role="button"
            data-toggle="modal"
            data-target="#addReviewModal"
            data-backdrop="static"
            data-keyboard="false"
            @click="
              setModalData(
                reviewCategory.id,
                reviewCategory.review_category_name
              )
            "
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </a>
          <a
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            class="edit-icon mr-3"
            href="#"
            role="button"
            data-toggle="modal"
            data-target="#editReviewCategoryModal"
            data-backdrop="static"
            data-keyboard="false"
            @click="editReviewCategory"
            id="edit_cat_icon"
            v-tooltip.top="'Edit Category'"
          >
            <i class="fa fa-pencil p-0" aria-hidden="true"></i>
          </a>
          <a
            v-if="hasAccess({ permissions: [BASE_PERMISSION.DELETE] })"
            class="delete-icon mr-3"
            id="delete_cat_icon"
            v-tooltip.top="'Delete Category'"
            href="#"
            role="button"
            data-toggle="modal"
            data-target="#deleteModal"
            data-backdrop="static"
            data-keyboard="false"
            @click="deleteReviewCategory(reviewCategory.id, 'reviewCategory')"
          >
            <i class="fa fa-trash p-0" aria-hidden="true"></i>
          </a>
          <a
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            class="status-icon mr-3"
            id="update_cat_status_icon"
            v-tooltip.top="
              reviewCategory.status === 'active'
                ? 'Deactivate Category'
                : 'Activate Category'
            "
            href="#"
            role="button"
            :style="[
              reviewCategory.status === 'active'
                ? { color: 'green' }
                : { color: 'red' },
            ]"
            @click="updateReviewCategoryWithReviewStatus()"
          >
            <i class="fa fa-circle" aria-hidden="true"></i>
          </a>
        </div>
        <button
          type="button"
          class="btn btn-custom dropdown-toggle d-md-none"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <b-icon icon="three-dots-vertical" style="color: #8f1452"></b-icon>
        </button>
        <div class="dropdown-menu">
          <a
            class="dropdown-item"
            href="#"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            data-toggle="modal"
            data-target="#editReviewCategoryModal"
            @click="editReviewCategory"
          >
            <b-icon class="mr-2" icon="pencil-square" variant="primary"></b-icon
            >Edit Category
          </a>
          <a
            class="dropdown-item"
            href="#"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.DELETE] })"
            data-toggle="modal"
            data-target="#deleteModal"
            @click="deleteReviewCategory(reviewCategory.id, 'reviewCategory')"
          >
            <b-icon class="mr-2" icon="trash" variant="danger"></b-icon>Delete
            Category
          </a>
          <a
            class="dropdown-item"
            href="#"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            @click="updateReviewCategoryWithReviewStatus()"
          >
            <b-icon
              class="mr-2"
              icon="circle-fill"
              :variant="
                reviewCategory.status === 'active' ? 'success' : 'danger'
              "
            ></b-icon>
            {{ reviewCategory.status === "active" ? "Deactivate" : "Activate" }}
            Category
          </a>
          <a
            class="dropdown-item"
            href="#"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
            data-toggle="modal"
            data-target="#addReviewModal"
            @click="
              setModalData(
                reviewCategory.id,
                reviewCategory.review_category_name
              )
            "
          >
            <b-icon class="mr-2" icon="plus-circle" variant="secondary"></b-icon
            >Add Review
          </a>
        </div>
      </div>
    </div>
    <div
      class="collapse table-responsive nopadding m-t10"
      :id="'panel' + reviewCategory.id"
    >
      <table
        aria-describedby="ReqreviewCategories"
        class="table"
        v-if="reviewCategory.reviews.length"
      >
        <tr class="tableheader">
          <th id="Actions" class="col6">Actions</th>
          <th id="ReviewName" class="col6">Review Name</th>
          <th id="Status" class="col6">Status</th>
        </tr>
        <review-list-element
          v-for="review in reviewCategory.reviews"
          :key="review.id"
          :review="review"
          :reviewCategoryId="reviewCategory.id"
        ></review-list-element>
      </table>
      <p class="p-l10" v-else>No reviews to show</p>
    </div>
  </div>
</template>

<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import ReviewListElement from "./review-list-element.vue";

/**
 * @memberof module:ManageReview
 * @namespace components.review_category_list_element
 */
export default {
  props: ["reviewCategory", "isOpened"],

  components: {
    "review-list-element": ReviewListElement,
  },

  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.REVIEW;
    },
  },

  mounted() {
    if (this.isOpened) {
      const panelId = "#panel" + this.reviewCategory.id;
      $(panelId).addClass("in");
    }
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    setModalData(reviewCategoryId, reviewCategoryName) {
      this.$store.dispatch("$_manage_reviews/setAddReviewModalData", {
        id: reviewCategoryId,
        name: reviewCategoryName,
      });
    },

    deleteReviewCategory(reviewCategoryId, itemType) {
      this.$store.dispatch("$_manage_reviews/deleteItem", {
        id: reviewCategoryId,
        type: itemType,
      });
    },

    editReviewCategory() {
      this.$store.dispatch(
        "$_manage_reviews/editReviewCategoryModal",
        this.reviewCategory
      );
    },

    updateReviewCategoryWithReviewStatus() {
      const info = {};
      if (this.reviewCategory.status === "active") {
        info.status = "inactive";
      } else {
        info.status = "active";
      }
      info.id = this.reviewCategory.id;
      info.review_category_name = this.reviewCategory.review_category_name;
      info.review_category_description =
        this.reviewCategory.review_category_description;
      info.created_by = this.reviewCategory.created_by;
      info.updated_by = this.reviewCategory.updated_by;
      this.$store.dispatch(
        "$_manage_reviews/updateReviewCategoryWithReviewStatus",
        info
      );
    },
  },
};
</script>

<style scoped>
th {
  text-align: center;
}

table {
  table-layout: fixed;
  word-wrap: break-word;
  width: 100%;
  overflow: scroll;
}

.p-l10 {
  padding-left: 10px;
}

.add-new-review-btn {
  margin-top: 4px;
}

.add-new-review-btn:hover {
  background-color: #903564;
  border-radius: 5px;
  color: white !important;
}

.status-icon {
  margin-top: 4px;
}
.pointer {
  cursor: pointer;
}
.review-category {
  border: 1px solid #d8cbda;
  background-color: #f4f4f4;
  padding: 5px;
  margin-bottom: 5px;
}

.review-category h3 {
  font-size: 17px;
  margin-top: 8px;
  font-weight: 600;
}

.btn-custom.dropdown-toggle::after {
  display: none;
}

.add-icon {
  color: #8f1452;
}
</style>
