import axios from "axios";

/**
 * @memberOf module:ApplicantsData.api
 * @returns {Promise}
 * @description Fetch multiple round schedules of all applicants from current date
 */
const fetchMySchedule = (filters) => {
  if (filters) {
    return axios.get(
      "my_schedule?startDate=" +
        filters.startDate +
        "&endDate=" +
        filters.endDate +
        "&status=" +
        filters.status +
        "&round=" +
        filters.round
    );
  } else {
    axios.get("rounds/my_schedule");
  }
};

const getMyScheduleEventSlots = (filters) => {
  return axios.get("applicants/my_schedule/event_slots/" + filters.eventId, {
    params: filters,
  });
};

/**
 * @memberOf module:ScheduleDashboard
 * @namespace api
 * @description api for backend interactions
 */
export default {
  fetchMySchedule,
  getMyScheduleEventSlots,
};
