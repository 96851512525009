function setAllTemplateMasters(state, payload) {
  state.templates.splice(0, state.templates.length, ...payload);
}

function setTemplateMaster(state, payload) {
  const idx = state.templates.findIndex((f) => f.id === payload.id);
  if (idx == -1) {
    return;
  }
  state.templates.splice(idx, 1, { ...state.templates[idx], ...payload });
}

function removeTemplateMaster(state, id) {
  const idx = state.templates.findIndex((f) => f.id === id);
  if (idx == -1) {
    return;
  }
  state.templates.splice(idx, 1);
}

function addTemplateMaster(state, payload) {
  state.templates.unshift(payload);
}

export default {
  setAllTemplateMasters,
  setTemplateMaster,
  removeTemplateMaster,
  addTemplateMaster,
};
