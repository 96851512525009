<template>
  <div class="row">
    <div class="col-12">
      <label :for="'input-line-' + _uid">
        {{ label }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <b-form-rating
        class="mt-2"
        :stars="properties.stars"
        :value="value"
        @input="handleInput"
      ></b-form-rating>
    </div>
    <div class="col-6 mt-2">
      <p>{{ properties.lLabel }}</p>
    </div>
    <div class="col-6 mt-2 text-right">
      <p>{{ properties.rLabel }}</p>
    </div>
    <div class="col-12 my-2">
      <p class="text-danger" v-for="(error, idx) in errorList" :key="idx">
        <b-icon-exclamation-circle></b-icon-exclamation-circle>
        <span class="ms-2">{{ error }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import validators from "../../_utils/validators";

export default {
  name: "rating-input-builder",
  props: {
    value: String,
    label: String,
    properties: Object,
    useValidation: Boolean,
    validators: Array,
  },
  data() {
    return {
      errorList: [],
    };
  },
  computed: {
    isRequired() {
      return (
        this.useValidation &&
        this.validators.some((e) => e.validator === "required")
      );
    },
  },
  methods: {
    checkError(input) {
      this.errorList = [];
      for (const validator of this.validators) {
        const err = validators[validator.validator](input, validator);
        if (err) {
          this.errorList.push(err);
        }
      }
      if (this.errorList.length !== 0) {
        this.$emit("error", {
          key: this.key,
          errors: this.errorList,
        });
        return true;
      }
      return false;
    },
    handleInput(e) {
      this.$emit("input", +e.target.value);
      this.useValidation && this.checkError(+e.target.value);
    },
  },
};
</script>

<style scoped>
.end-point {
  flex-grow: 1;
  background-color: black;
  border-radius: 50%;
  color: black;
  font-size: 0.5rem;
}

.mid-line {
  height: 0.3rem;
  flex-grow: 98;
  background-color: black;
}
</style>
