import moment from "moment";
export const COMMON_DATA_COL_DEFS = [
  {
    cellRenderer: "profile-link",
    width: 35,
    minWidth: 35,
    maxWidth: 35,
    suppressFilter: true,
    filter: false,
    valueGetter: "node.id",
    headerTooltip: "Profile Info",
    tooltipValueGetter: function (params) {
      if (params.data != undefined) {
        return "Profile of " + params.data.first_name;
      }
      return "";
    },
    pinned: "left",
    display_only: true,
    cellStyle: { textAlign: "center", padding: 0 },
  },
  {
    cellRenderer: "edit-profile-data",
    width: 35,
    minWidth: 35,
    maxWidth: 35,
    suppressFilter: true,
    filter: false,
    valueGetter: "node.id",
    headerTooltip: "Edit Applicant Data",
    pinned: "left",
    display_only: true,
    cellStyle: { textAlign: "center", padding: 0 },
  },
  {
    cellRenderer: "resolve-link",
    width: 35,
    minWidth: 35,
    maxWidth: 35,
    suppressFilter: true,
    filter: false,
    headerTooltip: "Profile Info",
    valueGetter: "node.id",
    pinned: "left",
    display_only: true,
    cellStyle: { textAlign: "center", padding: 0 },
  },
  {
    headerName: "Name",
    field: "name",
    valueGetter: function mergeLastNameFirstName(params) {
      if (params.data != undefined) {
        return params.data.first_name + " " + params.data.last_name;
      }
    },
    sortable: true,
    width: 200,
    minWidth: 200,
    checkboxSelection: true,
    pinned: "left",
    suppressHeaderMenuButton: true,
    filterParams: {
      newRowsAction: "keep",
    },
  },
  {
    headerName: "Title",
    field: "title",
    valueGetter: function getStatus(params) {
      if (params.data != undefined) {
        if (params.data.roundData?.eventSlot?.belongToCampusEvents) {
          return params.data.roundData.eventSlot.belongToCampusEvents
            .round_title;
        } else if (params.data.roundData?.round_name) {
          return params.data.roundData.round_name;
        } else {
          return "";
        }
      }
    },
    sortable: true,
    width: 200,
    minWidth: 200,
    pinned: "left",
    suppressHeaderMenuButton: true,
    filter: true,
    display_only: true,
  },
  {
    headerName: "Event Date",
    valueGetter: function getStatus(params) {
      if (params.data != undefined) {
        if (params.data.roundData && params.data.roundData.eventSlot) {
          return `${moment(params.data.roundData.eventSlot.from_time).format(
            "MMM Do YYYY"
          )} (${moment(params.data.roundData.eventSlot.from_time).format(
            "HH:mm A"
          )} - ${moment(params.data.roundData.eventSlot.end_time).format(
            "HH:mm A"
          )})`;
        } else if (params.data.roundData) {
          return `${moment(params.data.roundData.round_schedule).format(
            "MMM Do YYYY, h:mm A"
          )}`;
        } else {
          return "";
        }
      }
    },
    sortable: true,
    width: 240,
    minWidth: 240,
    pinned: "left",
    suppressHeaderMenuButton: true,
    filter: true,
    display_only: true,
  },
  {
    headerName: "Event Status",
    valueGetter: function getStatus(params) {
      if (params.data != undefined) {
        if (params.data.roundData?.round_result) {
          const status =
            params.data.roundData.round_result == "Pending"
              ? "Scheduled"
              : params.data.roundData.round_result;
          return params.data.roundData.round_name + " " + status;
        } else {
          return "";
        }
      }
    },
    sortable: true,
    width: 170,
    minWidth: 170,
    suppressHeaderMenuButton: true,
    pinned: "left",
  },
  {
    headerName: "Email",
    field: "email",
    sortable: true,
    width: 200,
    minWidth: 200,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Mobile",
    field: "mobile_no",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Application Status",
    field: "application_status",
    sortable: true,
    width: 170,
    minWidth: 170,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Application Date",
    field: "createdAt",
    width: 200,
    minWidth: 200,
    sortable: true,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        let dt = new Date(params.data.createdAt);
        return dt.toLocaleDateString().toString();
      }
    },
    filter: "agDateColumnFilter",
    suppressHeaderMenuButton: true,
  },
  // {
  //   headerName: "Notice Period",
  //   field: "notice_period",
  //   sortable: true,
  //   width: 100,
  //   filter: 'agNumberColumnFilter',
  //   minWidth: 100,
  //   suppressHeaderMenuButton: true
  // },
  // {
  //   headerName: "Experience",
  //   field: "experience",
  //   sortable: true,
  //   width: 100,
  //   filter: 'agNumberColumnFilter',
  //   minWidth: 100,
  //   suppressHeaderMenuButton: true
  // },
  {
    headerName: "Position",
    field: "position",
    sortable: true,
    width: 140,
    minWidth: 140,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Applicant",
    field: "status",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Emp. Status",
    field: "employment_status",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  // {
  //   headerName: "Skills",
  //   field: "skills",
  //   sortable: true,
  //   width: 170,
  //   minWidth: 170,
  //   suppressHeaderMenuButton: true,
  //   filterParams: {
  //     filterOptions: ARRAY_FILTER_PARAMS
  //   }
  // },
  {
    headerName: "DOB",
    field: "dob",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        let dt = new Date(params.data.dob);
        return dt.toLocaleDateString().toString();
      }
    },
    filter: "agDateColumnFilter",
  },
  {
    headerName: "Gender",
    field: "gender",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  // {
  //   headerName: "Alt.Mobile",
  //   field: "alternate_mobile_no",
  //   sortable: true,
  //   width: 100,
  //   minWidth: 100,
  //   suppressHeaderMenuButton: true
  // },
  // {
  //   field: "current_city",
  //   sortable: true,
  //   width: 110,
  //   minWidth: 110,
  //   suppressHeaderMenuButton: true
  // },
  // {
  //   headerName: "Home Town",
  //   sortable: true,
  //   field: "city",
  //   width: 110,
  //   minWidth: 110,
  //   suppressHeaderMenuButton: true
  // // },
  {
    headerName: "Expected Joining",
    field: "expected_joining",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    valueGetter: function dateFormat(params) {
      if (params.data !== undefined && params.data.expected_joining) {
        let dt = new Date(params.data.expected_joining);
        return dt.toLocaleDateString().toString();
      } else return "NA";
    },
    filter: "agDateColumnFilter",
  },
  {
    headerName: "College Name",
    field: "college_name",
    sortable: true,
    width: 200,
    minWidth: 200,
  },
];
