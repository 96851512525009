import ApplicantList from "./_components/applicants-list.vue";
import RecuitmentForm from "./_components/edit-recruitment-form.vue";
import ViewRecruitment from "./_components/view-recruitment-form.vue";
import userPasswordCreation from "./_components/user-password-creation.vue";

export default [
  {
    path: "dashboard",
    component: ApplicantList,
    name: "RecruiterApplicantData",
    meta: {
      title: "Applicants Data",
      user: "recruiter",
      credRequired: true,
    },
  },
  {
    path: "applicants/:slug",
    component: ViewRecruitment,
    name: "RecruiterProfileApplicantData",
    meta: {
      title: "Applicant's Profile",
      user: "recruiter",
      credRequired: true,
    },
  },
  {
    path: "applicants/:slug/update",
    component: RecuitmentForm,
    name: "RecruiterEditApplicantData",
    meta: {
      title: "Edit Applicant Data",
      user: "recruiter",
      credRequired: true,
    },
  },
  {
    path: "reset-password/:resetPasswordId",
    component: userPasswordCreation,
    props: true,
    name: "userPasswordCreation",
    meta: {
      title: "Password Creation",
      user: "recruiter",
      credRequired: false,
    },
  },
];
