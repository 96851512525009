<template>
  <div class="modal fade" id="addJobModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Add Job</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="resetValidations($v)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ws-normal">
          <form class="content-form">
            <div class="form-group">
              <label class="control-label">Category</label>
              <div v-if="categoryData">
                <input
                  type="text"
                  class="form-control"
                  :value="categoryData.name"
                  disabled
                />
              </div>
            </div>

            <div class="form-group position-relative">
              <label class="control-label"
                >Job Name <span class="text-danger"> *</span></label
              >
              <input
                type="text"
                name="jobName"
                class="form-control"
                v-validate="'required|regex:^[^-\s][a-zA-Z ]*$'"
                data-vv-as="Job Name"
                data-vv-validate-on="blur|input"
                v-model="data.job_name"
                maxlength="30"
                :class="{ invalid: errors.first('jobName') }"
              />
              <span
                v-if="errors.first('jobName')"
                class="valid-feedback alert-danger"
                >{{ errors.first("jobName") }}</span
              >
            </div>

            <div class="form-group position-relative">
              <label class="control-label"
                >Job Description <span class="text-danger"> *</span></label
              >
              <common-rich-text-box
                modalId="add-job-summernote"
                :width="'100%'"
                @onKeyUp="onKeyUp"
                @onBlur="onBlur"
                @onFocus="onFocus"
              />
              <span
                v-if="isJobDescTouched && !isJobDescValid"
                class="valid-feedback alert-danger"
                >Job Description is Required</span
              >
            </div>

            <div class="form-group position-relative">
              <tags-input
                v-if="doRender"
                id="required_skills"
                label="Required Skills"
                @valuesUpdated="updateSkillsValue($event)"
              />
            </div>
            <tags-input
              v-if="doRender"
              id="required_qualifications"
              label="Required Qualification"
              @valuesUpdated="updateQualificationValue($event)"
            />

            <div class="form-group position-relative">
              <label class="control-label"
                >Req Exp Min <span class="text-danger"> *</span></label
              >
              <input
                type="text"
                name="reqExpMin"
                maxlength="2"
                v-validate="'required|regex:^\d+(?:\.\d{1,4})?$|numeric'"
                data-vv-as="Required Min Experience"
                data-vv-validate-on="blur|input"
                class="form-control"
                v-model="data.required_experience_min"
                :class="{ invalid: errors.first('reqExpMin') }"
              />
              <span
                v-if="errors.first('reqExpMin')"
                class="valid-feedback alert-danger"
                >{{ errors.first("reqExpMin") }}</span
              >
            </div>

            <div class="form-group position-relative">
              <label class="control-label"
                >Req Exp Max <span class="text-danger"> *</span></label
              >
              <input
                type="text"
                name="reqExpMax"
                maxlength="2"
                v-validate="'required|regex:^\d+(?:\.\d{1,4})?$|numeric'"
                data-vv-as="Required Max Experience"
                data-vv-validate-on="blur|input"
                class="form-control"
                v-model="data.required_experience_max"
                :class="{ invalid: errors.first('reqExpMax') }"
              />
              <span
                v-if="errors.first('reqExpMax')"
                class="valid-feedback alert-danger"
                >{{ errors.first("reqExpMax") }}</span
              >
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="$v.data.$invalid || !isJobDescValid"
            type="button"
            class="btn btn-cp ml-2"
            @click="
              addJob();
              resetValidations($v);
            "
            data-dismiss="modal"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TagsInput from "../../Common/_components/tags-input-v2";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
import {
  required,
  // maxLength,
  // maxValue,
  // numeric,
} from "vuelidate/lib/validators";

/**
 * @memberof module:ManageJob
 * @namespace components.add_job_modal
 */
export default {
  /**
   * @memberof module:ManageJob.components.add_job_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      doRender: true,
      data: {
        job_name: "",
        job_description: "",
        required_skills: [],
        required_experience_min: null,
        required_experience_max: null,
        categoryId: null,
        job_posting_image: null,
        status: "active",
        required_qualifications: [],
      },
      isSkillsValid: false,
      isQualificationValid: false,
      isFileValid: false,
      isJobDescTouched: false,
    };
  },
  components: {
    TagsInput,
    CommonRichTextBox,
    // FileUploadInput,
  },

  /**
   * @memberof module:ManageJob.components.add_job_modal
   * @returns {Object} validation object
   * @description data - returns validation object for current vue component
   */
  validations() {
    const data = {
      job_name: {
        required,
        jobNameValidator: this.jobNameValidator,
      },
      required_experience_min: {
        required,
        experienceValidator: this.experienceValidator,
      },
      required_experience_max: {
        required,
        experienceValidator: this.experienceValidator,
      },
    };
    return {
      data,
    };
  },

  /**
   * @memberof module:ManageJob.components.add_job_modal
   * @description lifecycle method - handle data when modal closed and about to open
   */
  mounted() {
    $("#addJobModal").on("show.bs.modal", () => {
      this.doRender = true;
    });
    $("#addJobModal").on("hidden.bs.modal", () => {
      this.data = this.resetData();
      this.doRender = false;
    });
  },
  beforeDestroy() {
    $("#addJobModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
  computed: {
    ...mapGetters({
      categoryData: "$_manage_jobs/categoryDataForAddJobModal",
    }),

    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @returns {Boolean} validated result
     * @description computed - checks whether Job Description is valid or not
     */
    isJobDescValid() {
      return (
        this.data.job_description !== "<p><br></p>" &&
        this.data.job_description !== ""
      );
    },
  },
  watch: {
    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @param {Object} value new value
     * @description watch - handle Category data
     */
    categoryData(value) {
      this.data.categoryId = value.id;
    },
  },
  methods: {
    onKeyUp(value) {
      this.data.job_description = value;
    },
    onBlur(value) {
      this.data.job_description = value;
      this.isJobDescTouched = true;
    },
    onFocus() {
      setTimeout(() => {
        $(document.body).addClass("modal-open");
      }, 500);
    },

    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @param {Object} file file object
     * @description method - handle file upload changes
     */
    fileChanged(file) {
      this.data.job_posting_image = file;
    },

    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @param {Object} v validation object
     * @description method - reset data validation
     */
    resetValidations(v) {
      v.$reset();
      $("#add-job-summernote").summernote("code", "");
      this.isJobDescTouched = false;
    },

    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @returns {Object} reset data object
     * @description method - reset data object
     */
    resetData() {
      return {
        job_name: "",
        job_description: "",
        required_skills: [],
        required_qualifications: [],
        required_experience_min: null,
        required_experience_max: null,
        categoryId: -1,
        job_posting_image: null,
        status: "active",
      };
    },

    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @description method - dispatch request to add job
     */
    addJob() {
      this.$store.dispatch("$_manage_jobs/addJob", this.data);
      this.data = this.resetData();
      $("#addJobModal").modal("hide");
      $(".addJobModal-backdrop").hide();
    },

    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @param {Object} event event object
     * @description method - set skills data
     */
    updateSkillsValue(event) {
      this.data.required_skills = event.values;
      this.isSkillsValid = event.isValid;
    },

    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @param {Object} event event object
     * @description method - set qualifications data
     */
    updateQualificationValue(event) {
      this.data.required_qualifications = event.values;
      this.isQualificationValid = event.isValid;
    },

    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @param {String} value new value
     * @returns {Boolean} validation result
     * @description method - checks whether job name is valid or not
     */
    jobNameValidator(value) {
      return /^[^-\s][a-zA-Z ]*$/.test(value);
    },

    /**
     * @memberof module:ManageJob.components.add_job_modal
     * @param {String} value new value
     * @returns {Boolean} validation result
     * @description method - checks whether experience is valid or not
     */
    experienceValidator(value) {
      return /^\d+(?:\.\d{1,4})?$/.test(value);
    },
  },
};
</script>

<style scoped>
.ws-normal {
  white-space: normal;
}
</style>
