var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('CollapseCard',{staticClass:"theme-default",attrs:{"title":"Follow Ups","midDivider":false,"header-class":"px-0 py-0","collapse":true,"title-class":"h5 m-0"},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mb-0 px-1 pointer",attrs:{"for":"checkbox"}},[_vm._v("Mine")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checked),expression:"checked"}],staticClass:"pointer",attrs:{"type":"checkbox","id":"checkbox"},domProps:{"checked":Array.isArray(_vm.checked)?_vm._i(_vm.checked,null)>-1:(_vm.checked)},on:{"change":function($event){var $$a=_vm.checked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checked=$$c}}}}),_c('b-icon',{staticClass:"ml-2 pointer",staticStyle:{"width":"24px","height":"24px"},attrs:{"id":"addFollowUpIcon","icon":"plus","aria-hidden":"true"},on:{"click":function($event){return _vm.$refs.eventModal.show()}}}),_c('b-popover',{attrs:{"target":"addFollowUpIcon","triggers":"hover","placement":"right"}},[_vm._v(" Click here to add new Follow Up ")])],1)]},proxy:true}])},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body p-2"},[_c('div',{staticClass:"follow-up-container"},[(_vm.getPendingFollowUps().length)?_c('div',_vm._l((_vm.getPendingFollowUps()),function(followUp){return _c('div',{key:followUp.id,staticClass:"row px-1"},[_c('div',{staticClass:"col-6"},[_c('h6',{staticClass:"card-title mb-0 mt-1"},[_c('router-link',{attrs:{"target":"_blank","to":{
                      name: 'applicantprofile',
                      params: { slug: followUp.applicantData.slug },
                    }}},[_c('i',{staticClass:"fa fa-user-circle-o",staticStyle:{"color":"#8f1452"},attrs:{"title":"View Profile","aria-hidden":"true"}})]),_c('strong',[_vm._v(" "+_vm._s(followUp.applicantData.first_name)+" "+_vm._s(followUp.applicantData.last_name)+" ")])],1),_c('p',{staticClass:"mb-2 text-muted",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.formatDate(followUp.schedule))+" ")])]),_c('div',{staticClass:"col-6 text-right text-break"},[_c('span',{staticClass:"badge mb-2",class:{
                    'badge-outline-success': followUp.active_status === 1,
                    'badge-outline-danger': followUp.active_status === 0,
                  }},[_vm._v(" "+_vm._s(followUp.status)+" ")]),_c('h6',{staticClass:"card-subtitle mb-2 text-muted",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(followUp.remarks)+" ")])])])}),0):_c('div',{staticClass:"followups pa-2 text-center"},[_c('p',[_vm._v("No pending followups found")])])]),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('router-link',{attrs:{"to":'/dashboard/applications/follow-ups'}},[_c('button',{staticClass:"btn dashboard-btn py-0 px-4"},[_vm._v(" View All FollowUps ")])])],1)])])]),_c('b-modal',{ref:"eventModal",attrs:{"title":"Add Follow Up","size":"xl","hide-footer":true}},[_c('event-modal',{attrs:{"isEdit":false,"title":"FollowUp"},on:{"generateFollowUp":function($event){return _vm.generateFollowUp($event)}}})],1),_c('add-follow-up-modal',{ref:"followUpModal",attrs:{"applicantData":_vm.applicantInfoData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }