import snackbar from "./snackbar";
import loading_overlay from "./loading-overlay";
import user from "../../User/_store";
import system_config from "../../SystemConfig/_store";
import $_shared_token from "../../SharedToken/_store";
/**
 * @module Common
 * @description Common modules for whole project
 */
export default {
  snackbar,
  loading_overlay,
  user,
  system_config,
  $_shared_token,
};
