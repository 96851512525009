<template>
  <div class="w-100 parent-div">
    <div v-if="!selected">{{ this.value }}</div>
    <drag
      v-if="selected"
      class="drag"
      :transfer-data="eventApplicantsSelectedRows"
    >
      {{ params.value }}
      <div slot="image" class="drag-image w-100">
        <div class="m-4 py-2">
          {{ eventApplicantsSelectedRows.length }} applicants selected
        </div>
      </div>
    </drag>
  </div>
</template>

<script>
import { Drag } from "vue-drag-drop";
import { mapGetters } from "vuex";

export default {
  components: {
    Drag,
    // Drop
  },
  watch: {
    eventApplicantsSelectedRows() {
      this.selected = this.params.node.selected;
    },
  },
  computed: {
    newParams() {
      return this.params;
    },
    ...mapGetters({
      eventData: "$_campus_recruitment/getEventData",
      eventApplicantsSelectedRows:
        "$_campus_recruitment/eventApplicantsSelectedRows",
    }),
  },
  data: function () {
    return {
      selected: false,
    };
  },
  beforeMount() {
    this.value = this.params.value;
  },
};
</script>

<style scoped>
.drag-image {
  opacity: 1;
  background-color: black;
  border-radius: 10px;
  color: white;
  max-height: 10000px;
}

.parent-div {
  min-width: 160px;
}

.drag:hover {
  cursor: move;
}
</style>
