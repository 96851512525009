import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  systemConfigs: null,
  publicSystemConfigsObject: null,
  fresherOpeningEnabled: null,
  professionalOpeningEnabled: null,
  skillSet: [],
  systemConfigsObject: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
