/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description return entities from store
 */
const entities = (state) => state.entities;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description return error message from store
 */
const errorMessages = (state) => state.errorMessages;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description  return campus recruitment detail from store
 */
const campusRecruitments = (state) => state.campusRecruitmentData;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description return entity data for modal to edit this entity from store
 */
const fetchEntityModalData = (state) => state.entityModalData;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description return deleted entity id from store
 */
const fetchDeletedEntityData = (state) => state.deletedEntityData;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description return campus recruitment id from store for updating status of campus recruitment
 */
const fetchCampusRecruitmentId = (state) => state.campusRecruitmentId;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description
 */
const setCampusRecruitmentUrl = (state) => state.campusRecruitmentURL;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description sets total registered applicant count to state
 */
const getRegisteredApplicantCount = (state) => state.registeredApplicantCount;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description get selected entity
 */
const getSelectedEntity = (state) => state.selectedEntity;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description get selected entity sessions
 */
const getSelectedEntitySessions = (state) => state.selectedEntitySessions;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description get active campuses
 */
const getActiveCampuses = (state) => state.activeCampuses;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description get active campuses
 */
const getSelectedCampus = (state) => state.selectedCampus;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description get event data
 */
const getEventData = (state) => state.eventData;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description get selected events applicants
 */
const eventApplicantsSelectedRows = (state) =>
  state.eventApplicantsSelectedRows;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description campus applicant status count
 */
const campusApplicationStatusCount = (state) => state.campusStatusCount;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state holds store properties for current module
 * @description campus applicant position count
 */
const campusApplicationPositionCount = (state) => state.campusPositionCount;

/**
 * @memberof module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch the count of each application status
 */
const applicationStatusCount = (state) => state.applicationStatusCount;

/**
 * @memberof module:ApplicantsData.store.getters
 * @param {Object} state
 * @description Fetch the count of each application position
 */
const applicationPositionCount = (state) => state.applicationPositionCount;

/**
 * @memberof module:campusRecruitment
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  entities,
  campusRecruitments,
  fetchEntityModalData,
  fetchDeletedEntityData,
  errorMessages,
  fetchCampusRecruitmentId,
  setCampusRecruitmentUrl,
  getRegisteredApplicantCount,
  getSelectedEntity,
  getSelectedEntitySessions,
  getActiveCampuses,
  getSelectedCampus,
  getEventData,
  eventApplicantsSelectedRows,
  campusApplicationStatusCount,
  campusApplicationPositionCount,
  applicationStatusCount,
  applicationPositionCount,
};
