import PERMISSIONS from "../Common/permissions";
import _systemConfig from "./index.vue";

export default {
  path: "configurations",
  component: _systemConfig,
  name: "manageSystemConfigs",
  meta: {
    title: "Manage Configurations",
    user: "employee",
    permissions: [PERMISSIONS["CONFIGURATION"].MENU],
  },
};
