import { addSnackbarMessage } from "../../../Common/_plugin/error-response";
import api from "../../_api/session-report-api";

/**
 * @memberof module:Report.store.actions
 * @param {Object} context
 * @param {Object} filterOptions - filter key value pairs
 * @description Fetch report list by createdAt
 */
const fetchSessionReportList = (context, filterOptions) => {
  api
    .sessionReportList(filterOptions)
    .then((res) => {
      context.commit("SET_SESSION_REPORT_LIST", res.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberof module:Report.store.actions
 * @param {Object} context
 * @param {Object} data - filter data
 * @description Fetch application campus count
 */
const sessionApplicationCampusCount = (context, data) => {
  api
    .sessionApplicationCampusCount(data.queryOptions, data.filterOptions)
    .then((res) => {
      context.commit(
        "SET_SESSION_APPLICATION_CAMPUS_COUNT",
        res.data.sort((a, b) => a.campus_count - b.campus_count)
      );
    })
    .catch((error) => {
      context.commit("SET_SESSION_APPLICATION_CAMPUS_COUNT", []);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberof module:Report.store.actions
 * @param {Object} context
 * @param {Object} data - filter data
 * @description Fetch application entity count
 */
const sessionApplicationEntityCount = (context, data) => {
  api
    .sessionApplicationEntityCount(data.queryOptions, data.filterOptions)
    .then((res) => {
      context.commit(
        "SET_SESSION_APPLICATION_ENTITY_COUNT",
        res.data.sort((a, b) => a.entity_count - b.entity_count)
      );
    })
    .catch((error) => {
      context.commit("SET_SESSION_APPLICATION_ENTITY_COUNT", []);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberof module:Report
 * @namespace store.actions
 * @description actions for report module
 */
export default {
  fetchSessionReportList,
  sessionApplicationCampusCount,
  sessionApplicationEntityCount,
};
