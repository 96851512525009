var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"editTemplateModal","tabindex":"-1","role":"dialog","aria-labelledby":"myModalLabel"}},[_c('div',{staticClass:"modal-dialog modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content m-t100"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('form',{staticClass:"content-form",attrs:{"id":"editForm"}},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.template.name),expression:"template.name"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.template.name)},on:{"blur":function($event){return _vm.$v.template.name.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.template, "name", $event.target.value)}}}),(!_vm.$v.template.name.required && _vm.$v.template.name.$error)?_c('p',{staticClass:"alert alert-danger"},[_vm._v(" This field is required ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.template.template_subject),expression:"template.template_subject"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.template.template_subject)},on:{"blur":function($event){return _vm.$v.template.template_subject.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.template, "template_subject", $event.target.value)}}}),(
                    !_vm.$v.template.template_subject.required &&
                    _vm.$v.template.template_subject.$error
                  )?_c('p',{staticClass:"alert alert-danger"},[_vm._v(" This field is required ")]):_vm._e()]),_c('tags-input',{attrs:{"id":'edit-template-cc',"label":'CC',"isRequired":false,"setValues":_vm.template.cc},on:{"valuesUpdated":function($event){return _vm.updateCcValue($event)}}}),_c('tags-input',{attrs:{"id":'edit-template-bcc',"label":'BCC',"isRequired":false,"setValues":_vm.template.bcc},on:{"valuesUpdated":function($event){return _vm.updateBccValue($event)}}})],1)]),_c('div',{staticClass:"col-md-6"},[_vm._m(3),_c('div',{staticClass:"row",staticStyle:{"margin":"auto"}},[_c('common-rich-text-box',{attrs:{"modalId":"edit-template-summernote","width":"418"},on:{"onKeyDown":_vm.onKeyDown,"onBlur":_vm.onBlur,"onFocus":_vm.onFocus}})],1)])])]),_c('div',{staticClass:"modal-footer text-center"},[_c('button',{staticClass:"btn btn-cp",attrs:{"id":"btn1","type":"button","data-dismiss":"modal","disabled":_vm.$v.template.$invalid || !_vm.isMailBodyValid},on:{"click":function($event){return _vm.updateTemplate()}}},[_vm._v(" Update ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"modal-title",attrs:{"id":"myModalLabel"}},[_vm._v("Edit Template")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"control-label",attrs:{"htmlFor":"name"}},[_vm._v("Name "),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"control-label",attrs:{"htmlFor":"subject"}},[_vm._v("Subject "),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"htmlFor":"body"}},[_vm._v("Body "),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])])
}]

export { render, staticRenderFns }