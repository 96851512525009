import api from "../_api";
import { addSnackbarMessage } from "../../Common/_plugin/error-response";

const fetchProcessedApplicants = (context, filterOptions) => {
  if (!filterOptions) {
    filterOptions = {};
  }
  filterOptions.applicationStatus = "Processed";
  api
    .fetchApplicantsDataByStatus(filterOptions)
    .then((response) => {
      context.commit("FETCH_PROCESSED_APPLICANTS_DATA", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};
const fetchApplicantsData = (context, filterOptions) => {
  if (!filterOptions) {
    filterOptions = {};
  }
  api
    .fetchApplicantsDataByStatus(filterOptions)
    .then((response) => {
      context.commit("FETCH_APPLICANTS_DATA", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const fetchHoldApplicants = (context, filterOptions) => {
  if (!filterOptions) {
    filterOptions = {};
  }
  filterOptions.applicationStatus = "Held";
  api
    .fetchApplicantsDataByStatus(filterOptions)
    .then((response) => {
      context.commit("FETCH_HOLD_APPLICANTS_DATA", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const fetchOngoingApplicants = (context, filterOptions) => {
  if (!filterOptions) {
    filterOptions = {};
  }
  const defaultStatus = [
    "Aptitude Scheduled",
    "Written Test Scheduled",
    "Interview Scheduled",
    "Review Scheduled",
    "Interview Passed",
  ];

  filterOptions.applicationStatus = defaultStatus;

  api
    .fetchApplicantsDataByStatus(filterOptions)
    .then((response) => {
      context.commit("FETCH_ONGOING_APPLICANTS_DATA", response.data);
    })
    .catch((error) => {
      console.error("Error fetching ongoing applicants:", error);
      addSnackbarMessage("Problem retrieving data!", error.toString(), true);
    });
};

const fetchDashboardStats = (context) => {
  api
    .fetchDashboardStats()
    .then((result) => {
      context.commit("FETCH_DASHBOARD_STATS_DATA", result.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Could not fetch applicants count");
    });
};

const fetchHiringPipelineStats = (context, filterOptions) => {
  api
    .fetchHiringPipelineStats(filterOptions)
    .then((result) => {
      context.commit("FETCH_HIRING_PIPELINE_STATS_DATA", result.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Could not fetch hiring pipeline count");
    });
};

export default {
  fetchProcessedApplicants,
  fetchHoldApplicants,
  fetchOngoingApplicants,
  fetchDashboardStats,
  fetchHiringPipelineStats,
  fetchApplicantsData,
};
