import axios from "axios";

const prefix = "/cron"; // backend api

const fetchCrons = () => {
  return axios.get(prefix);
};
const updateCron = (data) => {
  return axios.put(prefix, { data });
};

export default {
  fetchCrons,
  updateCron,
};
