import store from "../../../store/index";

export const getErrorMessage = (error, message, messageRequired = false) => {
  if (messageRequired && message) return message;
  const genericErrMessage = "Internal server error!";
  const defaultMessage = "Problem retrieving data!";
  let errMsg = "";
  if (error) {
    if (error.response) {
      if (error.response.data?.message) errMsg = error.response.data.message;
      else if (error.message) errMsg = error.message;
    } else if (error.request) errMsg = "Network Error!";
    else errMsg = error.message || genericErrMessage;
  } else {
    errMsg = message || defaultMessage;
  }
  if (Array.isArray(errMsg)) errMsg = errMsg[0];
  return errMsg || message || defaultMessage;
};

export const addSnackbarMessage = (error, message, messageRequired = false) => {
  store.dispatch(
    "openSnackbar",
    {
      type: "danger",
      message: getErrorMessage(error, message, messageRequired),
    },
    { root: true }
  );
};

export const addSuccessSnackbarMessage = (message) => {
  store.dispatch("openSnackbar", { type: "success", message }, { root: true });
};
