<template>
  <div>
    <div class="email-verification" v-if="success">
      <!-- <div class="checkmark-container">
        <i class="checkmark">✓</i>
      </div> -->
      <h1 class="verify">Verified</h1>
      <p>Your Email has been verified successfully!</p>
      <span><p>Please login to access your account.</p></span>
      <router-link to="/login?as=applicant">
        <button class="btn btn-cp ml-2 my-4 large-btn">Go to Login</button>
      </router-link>
    </div>

    <div class="email-verification" v-if="error">
      <!-- <div class="cross-container"></div> -->
      <h1 class="fail">
        Verification <br />
        Failed
      </h1>
      <p>We're unable to verify your Email.</p>
      <span
        ><p>
          kindly login to our portal with given credentials for new Verification
          link.
        </p></span
      >
      <!-- <router-link to="/">
        <button class="btn btn-cp ml-2 my-4 large-btn">Go to Home</button>
      </router-link> -->
      <router-link to="/login?as=applicant">
        <button class="btn btn-cp ml-2 my-4 large-btn">Go to Login</button>
      </router-link>
    </div>

    <div v-if="loading">Verifying...</div>
  </div>
</template>

<script>
import api from "../_api";
export default {
  data() {
    return {
      loading: true,
      success: false,
      error: false,
    };
  },
  mounted() {
    const token = this.$route.query.token;
    this.verifyEmail(token);
  },
  methods: {
    async verifyEmail(token) {
      try {
        const response = await api.applicantEmailVerification(token);
        this.loading = false;
        this.success = response.data.success;

        if (!this.success) {
          this.error = response.data.message;
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.error = error.response.data.message || "Verification failed";

        if (this.error === "jwt expired")
          this.error = "Verification link expired";
      }
    },
  },
};
</script>

<style scoped>
.email-verification {
  text-align: center;
  padding: 40px 0;
}

.checkmark-container {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #d0eaac;
  margin: 0 auto;
  margin-top: 120px;
}

.cross-container {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  background: #f3d1d1;
  margin: 0 auto;
  margin-top: 120px;
}

.checkmark {
  color: hsla(124, 76%, 23%, 0.918);
  font-size: 120px;
  line-height: 200px;
  margin-left: -15px;
}

.cross {
  color: rgb(207, 23, 23);
  font-size: 90px;
  line-height: 200px;
  margin-left: -15px;
}

p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

.verify {
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 45px;
  margin-bottom: 40px;
  margin-top: 35px;
  color: hsla(124, 76%, 23%, 0.918);
}

.fail {
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 35px;
  margin-bottom: 40px;
  margin-top: 35px;
  color: rgb(148, 7, 7);
}

.large-btn {
  font-size: 20px;
  padding: 6px 14px;
}
</style>
