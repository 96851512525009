var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media",attrs:{"id":'id' + _vm.item.id}},[_c('a',{staticClass:"pull-left"},[_c('img',{staticClass:"img-responsive",attrs:{"src":!!_vm.item.profile_pic ? _vm.item.profile_pic : _vm.avatar,"alt":"user"}})]),_c('div',{staticClass:"media-body"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('h5',{staticClass:"media-heading"},[_vm._v(_vm._s(_vm.item.userName))])]),[(_vm.loggedInUser.user_id == _vm.item.created_by)?_c('div',[(!_vm.updateParent)?_c('button',{staticClass:"btn magenta-icon mt-1 mr-1",attrs:{"title":"Edit"},on:{"click":function($event){return _vm.updateParentToggle(_vm.item.id)}}},[_c('i',{staticClass:"fa fa-pencil-square-o",attrs:{"aria-hidden":"true"}})]):_c('button',{staticClass:"btn magenta-icon mt-1 mr-1",attrs:{"title":"View"},on:{"click":function($event){return _vm.updateParentToggle(_vm.item.id)}}},[_c('i',{staticClass:"fa fa-eye",attrs:{"aria-hidden":"true"}})])]):_vm._e()]],2),(!_vm.updateParent)?_c('safe-content',{attrs:{"content":_vm.item.comment,"tag":"p"}}):_vm._e(),[(_vm.updateParent)?_c('div',[_c('common-rich-text-box',{ref:"commonRichTextBox",attrs:{"placeholder":"Your comments","modalId":'parents-comments' + _vm.item.id,"width":'100%',"height":'50',"minHeight":'auto',"haveMentions":true,"updateCode":_vm.item.comment},on:{"onKeyUp":_vm.onKeyUpParent,"onBlur":_vm.onBlurParent}}),_c('div',{staticClass:"form-group float-right m-t10"},[(
              _vm.hasAccess({ permissions: [_vm.BASE_PERMISSION['ADMIN-COMMENT']] })
            )?_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.adminCheckbox),expression:"adminCheckbox"}],staticClass:"control-label",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.adminCheckbox)?_vm._i(_vm.adminCheckbox,null)>-1:(_vm.adminCheckbox)},on:{"change":function($event){var $$a=_vm.adminCheckbox,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.adminCheckbox=$$a.concat([$$v]))}else{$$i>-1&&(_vm.adminCheckbox=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.adminCheckbox=$$c}}}}),_c('span',{staticClass:"ml-1"},[_vm._v("Only for Admins")])]):_vm._e(),_c('button',{staticClass:"btn btn-cp ml-2",attrs:{"type":"button","disabled":!_vm.isCompleteParent},on:{"click":function($event){return _vm.submitUpdateParent(_vm.item)}}},[_c('i',{staticClass:"fa fa-paper-plane px-0",attrs:{"aria-hidden":"true"}})])])],1):_vm._e()],_c('div',{staticClass:"list-unstyled list-inline media-detail pull-left"},[_c('span',{staticClass:"date-tag"},[_vm._v(_vm._s(_vm.item.date))]),(_vm.item.createdAt != _vm.item.updatedAt)?_c('span',{staticClass:"date-tag"},[_vm._v("(edited)")]):_vm._e(),(_vm.item.showAdmin)?_c('span',{staticClass:"date-tag"},[_vm._v("(For Admin Only)")]):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.item.childComments && _vm.item.childComments.length > 0 ? _vm.item.childComments.length : "")+" ")]),_c('span',{staticClass:"reply-tag",attrs:{"title":"Reply"},on:{"click":_vm.childCommentsVisibility}},[_c('i',{staticClass:"fa fa-reply",attrs:{"aria-hidden":"true"}})]),[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.childCommentsVisible &&
            _vm.item.childComments &&
            _vm.item.childComments.length > 0
          ),expression:"\n            childCommentsVisible &&\n            item.childComments &&\n            item.childComments.length > 0\n          "}]},_vm._l((_vm.item.childComments),function(childItem){return _c('div',{key:childItem.id},[_c('thread-child-comment',{attrs:{"childItem":childItem,"loggedInUser":_vm.loggedInUser}})],1)}),0)],[(_vm.childCommentsVisible)?_c('div',{staticClass:"media-body",staticStyle:{"margin-left":"-15px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"well container"},[_c('div',[_c('div',{staticClass:"media",staticStyle:{"border-top":"none","padding-top":"10px","margin-bottom":"10px"}},[_c('a',{staticClass:"pull-left"},[_c('img',{staticClass:"img-responsive",attrs:{"src":!!_vm.loggedInUser.profile_pic
                            ? _vm.loggedInUser.profile_pic
                            : _vm.avatar,"alt":"user"}})]),_c('div',{staticClass:"w-100"},[_c('div',{},[_c('common-rich-text-box',{ref:"commonRichTextBox",attrs:{"placeholder":"Your comments","modalId":"threaded-comments","width":'100%',"height":'50',"minHeight":'auto',"haveMentions":true},on:{"onKeyUp":_vm.onKeyUp,"onBlur":_vm.onBlur}})],1),_c('div',{staticClass:"form-group float-right m-t10"},[(
                            _vm.hasAccess({
                              permissions: [_vm.BASE_PERMISSION['ADMIN-COMMENT']],
                            })
                          )?_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.adminCheckbox),expression:"adminCheckbox"}],staticClass:"control-label",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.adminCheckbox)?_vm._i(_vm.adminCheckbox,null)>-1:(_vm.adminCheckbox)},on:{"change":function($event){var $$a=_vm.adminCheckbox,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.adminCheckbox=$$a.concat([$$v]))}else{$$i>-1&&(_vm.adminCheckbox=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.adminCheckbox=$$c}}}}),_c('span',{staticClass:"ml-1"},[_vm._v("Only for Admins")])]):_vm._e(),_c('button',{staticClass:"btn btn-cp ml-2",attrs:{"type":"button","disabled":!_vm.isComplete},on:{"click":_vm.submitComment}},[_c('i',{staticClass:"fa fa-paper-plane px-0",attrs:{"aria-hidden":"true"}})])])])])])])])])]):_vm._e()]],2)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }