var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex active-purple active-purple-2"},[_c('div',{staticClass:"d-inline-block",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"form-control border-0",staticStyle:{"padding-left":"5px"},attrs:{"tabindex":"-1","type":"search","placeholder":_vm.searchPlaceholder,"aria-label":"Search","autocomplete":"off"},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value},function($event){_vm.isTyping = true}]}}),_c('i',{staticClass:"fa fa-search",staticStyle:{"padding-right":"5px","display":"flex","align-items":"center"},attrs:{"aria-hidden":"true"}})])])]),_c('div',[_c('ul',{staticClass:"list-group",class:_vm.listClass,style:({ width: '100%' })},[(
          _vm.isTyping &&
          !_vm.haveNoRecords &&
          _vm.searchResult.length === 0 &&
          _vm.searchQuery.length > 0 &&
          _vm.searchQuery.length < 3
        )?_c('li',{staticClass:"list-group-item message-text text-center text-secondary"},[_c('span',[_vm._v("Searching...(Type atleast 3 characters)")])]):_vm._e(),(!_vm.isLoading && _vm.haveNoRecords)?_c('li',{staticClass:"list-group-item message-text text-center text-danger"},[_c('span',[_vm._v("No Records Found...")])]):_vm._e(),(_vm.isCampusRecruitment)?_c('div',_vm._l((_vm.searchResult),function(item,i){return _c('li',{key:i,staticClass:"list-group-item hover-effect d-flex flex-column flex-sm-row align-items-start"},[_c('span',{staticClass:"flex-grow-1 mb-2 mb-sm-0",staticStyle:{"word-break":"break-word"},attrs:{"title":'Email: ' + item.email + ', Mobile: ' + item.mobile_no}},[_vm._v(" "+_vm._s(_vm._f("truncate")((item.first_name + " " + item.last_name + ", " + item.position),45, ".."))+" ")]),_c('router-link',{staticClass:"ml-sm-2 mb-2 mb-sm-0 profile-link",staticStyle:{"flex-shrink":"0"},attrs:{"title":"Click here to open profile","target":"_blank","to":_vm.routerLink(item.slug)}},[_c('i',{staticClass:"fa fa-user-circle-o",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearSearchQuery()}}})]),_c('span',{staticClass:"ml-sm-2",staticStyle:{"flex-shrink":"0","word-break":"break-word"},attrs:{"title":'Employment Status: ' +
              item.employment_status +
              ', Application Status: ' +
              item.application_status}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.application_status,13, ".."))+" ")])],1)}),0):(_vm.fromDashboard)?_c('div',_vm._l((_vm.searchResult),function(item,i){return _c('li',{key:i,staticClass:"list-group-item hover-effect d-flex justify-content-between align-items-start px-3 pt-2 py-1",on:{"click":function($event){_vm.isEmit ? _vm.returnSearchQuery(item) : null}}},[_c('div',{staticClass:"text-left"},[_c('h6',[(!_vm.isEmit)?_c('router-link',{staticClass:"profile-link mr-2",attrs:{"title":"Click here to open profile","target":"_blank","to":_vm.routerLink(item.slug)}},[_c('i',{staticClass:"fa fa-user-circle-o",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearSearchQuery()}}})]):_vm._e(),_c('span',{attrs:{"title":'Email: ' + item.email + ', Mobile: ' + item.mobile_no}},[_vm._v(_vm._s(_vm._f("truncate")((item.first_name + " " + item.last_name),_vm.isEmit ? 60 : 45, ".."))+" ")]),_c('p',{staticClass:"mb-1 mx-4",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.position,_vm.isEmit ? 60 : 45, ".."))+" ")])],1)]),_c('div',{staticClass:"text-right mt-2"},[_c('span',{attrs:{"title":'Employment Status: ' +
                item.employment_status +
                ', Application Status: ' +
                item.application_status}},[_vm._v(_vm._s(_vm._f("truncate")(item.application_status,_vm.isEmit ? 50 : 13, "..")))])])])}),0):_c('div',_vm._l((_vm.searchResult),function(item,i){return _c('li',{key:i,staticClass:"list-group-item hover-effect",on:{"click":function($event){_vm.isEmit ? _vm.returnSearchQuery(item) : null}}},[_c('span',{staticClass:"col-9",attrs:{"title":'Email: ' + item.email + ', Mobile: ' + item.mobile_no}},[_vm._v(_vm._s(_vm._f("truncate")((item.first_name + " " + item.last_name + ", " + item.position),_vm.isEmit ? 60 : 45, "..")))]),(!_vm.isEmit)?_c('router-link',{staticClass:"col-1 float-right profile-link",attrs:{"title":"Click here to open profile","target":"_blank","to":_vm.routerLink(item.slug)}},[_c('i',{staticClass:"fa fa-user-circle-o",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearSearchQuery()}}})]):_vm._e(),_c('span',{staticClass:"col-3 float-right",attrs:{"title":'Employment Status: ' +
              item.employment_status +
              ', Application Status: ' +
              item.application_status}},[_vm._v(_vm._s(_vm._f("truncate")(item.application_status,_vm.isEmit ? 50 : 13, "..")))])],1)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }