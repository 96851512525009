<template>
  <div class="mb-3">
    <CollapseCard
      class="theme-default"
      title="Follow Ups"
      :midDivider="false"
      header-class="px-0 py-0"
      :collapse="true"
      title-class="h5 m-0"
    >
      <template v-slot:right>
        <div class="d-flex align-items-center">
          <label class="mb-0 px-1 pointer" for="checkbox">Mine</label>
          <input
            class="pointer"
            type="checkbox"
            id="checkbox"
            v-model="checked"
          />
          <b-icon
            id="addFollowUpIcon"
            icon="plus"
            style="width: 24px; height: 24px"
            class="ml-2 pointer"
            aria-hidden="true"
            @click="$refs.eventModal.show()"
          ></b-icon>
          <b-popover
            target="addFollowUpIcon"
            triggers="hover"
            placement="right"
          >
            Click here to add new Follow Up
          </b-popover>
        </div>
      </template>
      <div class="card">
        <div class="card-body p-2">
          <div class="follow-up-container">
            <div v-if="getPendingFollowUps().length">
              <div
                v-for="followUp in getPendingFollowUps()"
                :key="followUp.id"
                class="row px-1"
              >
                <div class="col-6">
                  <h6 class="card-title mb-0 mt-1">
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'applicantprofile',
                        params: { slug: followUp.applicantData.slug },
                      }"
                    >
                      <i
                        title="View Profile"
                        class="fa fa-user-circle-o"
                        aria-hidden="true"
                        style="color: #8f1452"
                      ></i>
                    </router-link>
                    <strong>
                      {{ followUp.applicantData.first_name }}
                      {{ followUp.applicantData.last_name }}
                    </strong>
                  </h6>
                  <p class="mb-2 text-muted" style="font-size: 14px">
                    {{ formatDate(followUp.schedule) }}
                  </p>
                </div>
                <div class="col-6 text-right text-break">
                  <span
                    class="badge mb-2"
                    :class="{
                      'badge-outline-success': followUp.active_status === 1,
                      'badge-outline-danger': followUp.active_status === 0,
                    }"
                  >
                    {{ followUp.status }}
                  </span>
                  <h6
                    class="card-subtitle mb-2 text-muted"
                    style="font-size: 14px"
                  >
                    {{ followUp.remarks }}
                  </h6>
                </div>
              </div>
            </div>
            <div v-else class="followups pa-2 text-center">
              <p>No pending followups found</p>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <router-link :to="'/dashboard/applications/follow-ups'">
              <button class="btn dashboard-btn py-0 px-4">
                View All FollowUps
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </CollapseCard>
    <b-modal
      ref="eventModal"
      title="Add Follow Up"
      size="xl"
      :hide-footer="true"
    >
      <event-modal
        :isEdit="false"
        v-on:generateFollowUp="generateFollowUp($event)"
        title="FollowUp"
      />
    </b-modal>
    <add-follow-up-modal
      ref="followUpModal"
      :applicantData="applicantInfoData"
    />
  </div>
</template>

<script>
import EventModal from "@/modules/Applications/_components/event-modal.vue";
import AddFollowUpModal from "@/modules/ApplicantProfile/_components/add-follow-up-modal.vue";

import { mapGetters } from "vuex";
import moment from "moment";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";
export default {
  components: {
    CollapseCard,
    EventModal,
    AddFollowUpModal,
  },
  data() {
    return {
      applicantInfoData: {},
      followUps: [],
      checked: false,
    };
  },
  mounted() {
    this.getFollowups({
      size: 5,
      userId: this.checked ? this.loggedInUser.user_id : null,
    });
  },
  computed: {
    ...mapGetters({
      followUpList: "$_applicants_data/fetchAllFollowUps",
      changedFollowUp: "$_applicant_profile/getFollowUpDetails",
      loggedInUser: "user/loggedInUser",
    }),
  },
  watch: {
    checked() {
      this.getFollowups({
        size: 5,
        userId: this.checked ? this.loggedInUser.user_id : null,
      });
    },
    followUpList(value) {
      this.followUps = value;
    },
  },
  methods: {
    getFollowups(filter) {
      this.$store.dispatch("$_applicants_data/fetchAllFollowUps", filter);
    },
    generateFollowUp(applicant) {
      this.applicantInfoData = applicant;
      this.$store.dispatch("$_applicant_profile/setIDInState", applicant.id);
      this.$store.dispatch(
        "$_applicant_profile/getFollowUpDetails",
        applicant.id
      );
      this.$refs["eventModal"].hide();
      $("#addFollowUp").modal("show");
    },
    formatDate(date) {
      if (date.value === null) return null;
      else if (date.value) return moment(date.value).format("lll");
      else return moment(date).format("lll");
    },
    getPendingFollowUps() {
      let followUps = this.followUps.filter(
        (followUp) => followUp.active_status === 0
      );
      followUps.sort((a, b) => new Date(a.schedule) - new Date(b.schedule));
      return followUps;
    },
  },
};
</script>
<style scoped>
.card {
  border-radius: 8px;
  border-color: white;
}

.badge-outline-success {
  color: #28a745;
  border: 1px solid #28a745;
  background-color: transparent;
}

.badge-outline-danger {
  color: #dc3545;
  border: 1px solid #dc3545;
  background-color: transparent;
}
.dashboard-btn {
  background-color: white;
  color: #8f1452;
  border-radius: 20px;
  border: 1px solid #8f1452;
}
.dashboard-btn:hover {
  background-color: #8f1452;
  color: white;
  border-radius: 20px;
}
.follow-up-container {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.pointer {
  cursor: pointer;
}
</style>
