<template>
  <div>
    <router-link
      v-if="params.data != undefined && params.data.hasDuplicate.length != 0"
      target="_blank"
      :to="{ name: 'resolveduplicate', params: { id: params.data.id } }"
    >
      <i
        class="fa fa-link"
        aria-hidden="true"
        style="color: red; cursor: pointer"
      ></i
    ></router-link>
  </div>
</template>

<script>
import router from "../../../router/index";
/**
 * @memberOf module:ApplicantsData
 * @namespace components.resolve_duplicate_link
 * @description Resolve Duplicate component
 */
export default {
  data() {
    return {};
  },
  methods: {
    /**
     * @memberOf module:ApplicantsData.components.resolve_duplicate_link
     * @description Resolve duplicates when matched for applicants
     */
    resolveDuplicate(id) {
      router.push({ name: "resolveduplicate", params: { id: id } });
    },
  },
};
</script>
