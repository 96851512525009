<template>
  <div
    class="template-view"
    v-if="hasAccess({ permissions: [BASE_PERMISSION.READ] })"
  >
    <div class="row nopadding mb-2 mx-0">
      <h2 class="col-6 px-0 prevent-select">Templates</h2>
      <div class="ml-auto">
        <button
          v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
          class="btn btn-cp ml-auto"
          data-toggle="modal"
          data-target="#addTemplateModal"
        >
          <i class="fa fa-plus" aria-hidden="true"></i> Add Template
        </button>
      </div>
    </div>
    <div :style="dynamicStyle">
      <draggable
        v-model="localTemplates"
        @end="onDragEnd"
        handle=".handle"
        ghost-class="ghost"
        v-bind="dragOptions"
      >
        <div class="col-12 p-0" v-for="(t, index) in Templates" :key="index">
          <div class="templates d-flex justify-content-between handle">
            <div class="template-div">
              <p class="template-name prevent-select">{{ t.name }}</p>
            </div>
            <div class="mr-2 mb-1 d-flex flex-nowrap">
              <a
                class="btn theme-clr theme-clr-hover"
                role="button"
                data-toggle="modal"
                data-target="#viewTemplateModal"
                @click="viewTemplate(t)"
                ><i class="fa fa-eye fa-md" aria-hidden="true"></i
              ></a>
              <a
                v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
                class="btn edit-icon"
                @click="editTemplate(t)"
                role="button"
                data-toggle="modal"
                data-target="#editTemplateModal"
              >
                <i class="fa fa-pencil" aria-hidden="true"></i
              ></a>
              <a
                v-if="hasAccess({ permissions: [BASE_PERMISSION.DELETE] })"
                class="btn delete-icon"
                role="button"
                data-toggle="modal"
                data-target="#deleteModal"
                @click="setDeleteId(t.id)"
                ><i class="fa fa-trash-o" aria-hidden="true"></i
              ></a>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <add-template></add-template>
    <view-template
      :viewTemplate="viewTemplateData"
      :normalMail="isNormalMail"
    ></view-template>
    <delete-template
      :id="deleteTemplateId"
      :templates="Templates"
    ></delete-template>
    <edit-template :editTemplate="editTemplateData"></edit-template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddTemplate from "./add-template-modal";
import ViewTemplate from "./view-template-modal";
import DeleteTemplate from "./delete-template-modal";
import EditTemplate from "./edit-template-modal";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";
import draggable from "vuedraggable";

/**
 * @memberof module:MailBox
 * @namespace components.template
 * @description Consists of template list and their manipulation options
 */
export default {
  /**
   * @memberof module:MailBox.components.template
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      localTemplates: [],
      selectedTemplate: 0,
      deleteTemplateId: -1,
      editTemplateData: {},
      isNormalMail: true,
      viewTemplateData: {},
      templateKey: this.$route.query.key,
      height: 0,
      dynamicCurrentHeight: this.currentHeight,
    };
  },
  props: ["currentHeight"],
  components: {
    AddTemplate,
    ViewTemplate,
    DeleteTemplate,
    EditTemplate,
    draggable,
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.MAIL.TEMPLATE;
    },
    ...mapGetters({
      Templates: "$_mailbox/getTemplates",
      availableMailBodies: "$_mailbox/getAvailableMailBodies",
    }),
    dragOptions() {
      return {
        scrollSensitivity: 200,
        animation: 1000,
        delay: 200,
        delayOnTouchOnly: true,
        forceFallback: true,
      };
    },
    dynamicStyle() {
      return {
        height: this.isSmallScreen
          ? "500px"
          : `${this.dynamicCurrentHeight - 90}px`,
        overflowY: "auto",
      };
    },
    isSmallScreen() {
      return window.innerWidth <= 400;
    },
  },

  watch: {
    /**
     * @memberof module:MailBox.components.template
     * @param {Array} value new value
     * @description watch - sets isNormalMail based on availability of applicants or campus data
     */
    availableMailBodies(value) {
      if (value.length !== 0) {
        this.isNormalMail = false;
      }
    },
    Templates: {
      immediate: true,
      handler(value) {
        this.localTemplates = value;
      },
    },
    currentHeight(newValue, oldValue) {
      if (newValue != oldValue) {
        this.dynamicCurrentHeight = newValue;
      }
    },
    isSmallScreen(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$forceUpdate(); // Force re-render if screen size changes
      }
    },
  },

  /**
   * @memberof module:MailBox.components.template
   * @description lifecycle method - for fetching templates data
   */
  created() {
    this.getTemplates();
  },
  methods: {
    getTemplates() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.$store.dispatch("$_mailbox/getTemplates", this.templateKey);
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:MailBox.components.template
     * @param {Number} id id of template to be deleted
     * @description method - set deleteTemplateId for delete-template modal
     */
    setDeleteId(id) {
      this.deleteTemplateId = id;
    },

    /**
     * @memberof module:MailBox.components.template
     * @param {Object} templateData template data to be previewed
     * @description method - sets templateData for view-template modal
     */
    viewTemplate(templateData) {
      this.viewTemplateData = templateData;
    },

    /**
     * @memberof module:MailBox.components.template
     * @param {Object} templateData template data to be updated
     * @description method - set editTemplateData for edit-template modal
     */
    editTemplate(templateData) {
      this.editTemplateData = templateData;
    },
    async onDragEnd(event) {
      const draggedItem = this.Templates[event.oldIndex];
      this.Templates.splice(event.oldIndex, 1);
      this.Templates.splice(event.newIndex, 0, draggedItem);
      this.updateTemplateOrder(event.oldIndex, event.newIndex);
    },
    updateTemplateOrder(oldIndex, newIndex) {
      if (oldIndex !== newIndex) {
        const order = newIndex + 1;
        this.Templates[order - 1].order = order;

        if (oldIndex < newIndex) {
          for (let idx = oldIndex; idx <= newIndex - 1; idx++) {
            if (this.Templates[idx] && this.Templates[idx].order) {
              this.Templates[idx].order -= 1;
            }
          }
        } else if (oldIndex > newIndex) {
          for (let idx = newIndex + 1; idx <= oldIndex; idx++) {
            if (this.Templates[idx] && this.Templates[idx].order) {
              this.Templates[idx].order += 1;
            }
          }
        }
        for (let idx = 0; idx < this.Templates.length; idx++) {
          if (this.Templates[idx].order === null) {
            this.Templates[idx].order = idx + 1;
          }
        }

        this.$store.dispatch("$_mailbox/updateTemplateOrder", this.Templates);
      }
    },
    handleResize() {
      this.$forceUpdate();
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.templates {
  border: 1px solid #8b509661;
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px #dedcdc;
}

.templates:hover {
  box-shadow: 5px 5px 10px #dedcdc;
}

.template-view {
  overflow-y: hidden;
  overflow-x: hidden;
}

.templates a {
  width: 30px;
  height: 30px;
}

.template-name {
  padding-top: 10px;
  padding-left: 20px;
}

.target-selector {
  margin-bottom: 14px;
  border: 1px solid #8000802b;
  padding: 6px 12px;
}

.target-selector h5 {
  font-size: 16px;
  margin-top: 0px;
}

.m-l10 {
  margin-left: 10px;
}

.m-r138 {
  margin-right: 138px;
}

.template-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ghost {
  opacity: 0;
}

.handle:hover {
  cursor: grab;
}

.handle:active {
  cursor: grabbing;
}
</style>
