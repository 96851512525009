<template>
  <b-modal
    v-if="hasAccess({ permissions: [PERMISSION] })"
    v-model="modalVisible"
    scrollable
    :title="template[0].file_name"
    @hidden="$emit('closeModal')"
    size="lg"
  >
    <div class="modal-content-wrapper">
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase"
        content-class="mt-3"
        justified
      >
        <b-tab active @click="showSingleFields">
          <template #title> Single Input fields </template>
        </b-tab>
        <b-tab @click="showMultiFields">
          <template #title> Multi input Fields </template>
        </b-tab>
      </b-tabs>
      <form>
        <div v-if="viewType === 'singleFields'">
          <div v-show="!singleInputFields?.length" class="text-center">
            <h5>No input fields for this template!</h5>
          </div>
          <div v-for="(field, index) in singleInputFields" :key="index">
            <div class="form-group">
              <label :for="'inputField' + index">
                {{ field?.name }}
                <span class="text-danger">*</span>
              </label>
              <select
                v-if="field?.type === 'select'"
                :name="field?.name"
                :id="'inputField' + index"
                class="form-control"
                v-model="data[field.name]"
              >
                <option disabled value="">
                  Please select a variable value
                </option>
                <option
                  v-for="(option, optIndex) in field?.options"
                  :key="optIndex"
                >
                  {{ option }}
                </option>
              </select>
              <input
                v-else-if="field?.type === 'date'"
                :type="field?.type"
                :name="field?.name"
                :id="'inputField' + index"
                class="form-control"
                v-model="data[field.name]"
              />
              <input
                v-else
                placeholder="please enter variable value"
                :type="field?.type"
                :name="field?.name"
                :id="'inputField' + index"
                class="form-control"
                v-model="data[field.name]"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-show="!multiInputFields?.length" class="text-center">
            <h5>No input fields for this template!</h5>
          </div>
          <div v-if="multiInputFields?.length">
            <div v-for="(user, userIndex) in users" :key="userIndex">
              <div class="toggle-section">
                <h5 @click="toggleUserFields(userIndex)" class="clickable">
                  For {{ user?.email }}
                  <i
                    class="fa fa-chevron-down fa-2xs chevron-icon"
                    :class="{ down: userFieldsVisible[userIndex] }"
                    aria-hidden="true"
                  ></i>
                </h5>
                <div
                  v-show="userFieldsVisible[userIndex]"
                  v-for="(field, fieldIndex) in multiInputFields"
                  :key="fieldIndex"
                  class="form-group"
                >
                  <label :for="'inputField' + userIndex + '-' + fieldIndex">
                    {{ field?.name }}
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    v-if="field?.type === 'select'"
                    :name="field?.name"
                    :id="'inputField' + userIndex"
                    class="form-control"
                    v-model="currentMultiValues[userIndex][field.name]"
                  >
                    <option disabled value="">
                      Please select a variable value
                    </option>
                    <option
                      v-for="(option, optIndex) in field?.options"
                      :key="optIndex"
                    >
                      {{ option }}
                    </option>
                  </select>
                  <input
                    v-else-if="field?.type === 'date'"
                    :type="field.type"
                    :name="field?.name"
                    :id="'inputField' + userIndex + '-' + fieldIndex"
                    class="form-control"
                    v-model="currentMultiValues[userIndex][field.name]"
                  />
                  <input
                    v-else
                    placeholder="please enter variable value"
                    :type="field?.type"
                    :name="field?.name"
                    :id="'inputField' + userIndex + '-' + fieldIndex"
                    class="form-control"
                    v-model="currentMultiValues[userIndex][field.name]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <template #modal-footer="{}">
      <b-button size="sm" @click="$emit('closeModal')" class="btn btn-cp ml-2"
        >Cancel</b-button
      >
      <b-button
        type="submit"
        size="sm"
        @click="onSubmit"
        class="btn btn-cp ml-2"
        :disabled="isDisabled()"
        >Save</b-button
      >
    </template>
  </b-modal>
  <b-modal v-else hide-footer size="md" v-model="modalVisible">
    <div class="text-center p-3">
      <h5>You don't have permission for this resource</h5>
      <b-button size="sm" @click="$emit('closeModal')" class="btn btn-cp mt-3">
        Close
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
export default {
  props: {
    id: {
      type: Number,
    },
    users: {
      type: Array,
    },
  },
  data() {
    return {
      modalVisible: true,
      data: {
        multiValues: [],
      },
      currentMultiValues: this.users.map(() => ({})),
      viewType: "singleFields",
      editingIndex: -1,
      userFieldsVisible: this.users.map((_, index) => index === 0),
    };
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    prefillMultiInputFields() {
      this.users.forEach((user, userIndex) => {
        this.multiInputFields?.forEach((field) => {
          if (user[field.name] !== undefined) {
            this.$set(
              this.currentMultiValues[userIndex],
              field?.name,
              user[field.name]
            );
          }
        });
      });
    },
    showSingleFields() {
      this.viewType = "singleFields";
    },
    showMultiFields() {
      this.viewType = "multiFields";
    },
    toggleUserFields(index) {
      this.$set(this.userFieldsVisible, index, !this.userFieldsVisible[index]);
    },
    async onSubmit() {
      let userValues;
      if (this.multiInputFields?.length) {
        userValues = this.users.map((user, index) => {
          return {
            user: user,
            fileTemplate: {
              id: this.id,
              data: {
                ...this.data,
                multiValues: [this.currentMultiValues[index]],
              },
            },
          };
        });
      } else {
        userValues = this.users.map((user) => {
          return {
            user: user,
            fileTemplate: {
              id: this.id,
              data: {
                ...this.data,
                multiValues: [],
              },
            },
          };
        });
      }

      this.$emit("closeModal", [...userValues]);

      this.modalVisible = false;
    },
    isDisabled() {
      let singleInputFieldResult = true,
        multiInputFieldResult = true;

      singleInputFieldResult = this.singleInputFields.every(
        (field) => this.data[field.name]
      );

      multiInputFieldResult = this.multiInputFields.every((field) =>
        this.currentMultiValues.every((userValues) => userValues[field.name])
      );

      return !(singleInputFieldResult && multiInputFieldResult);
    },
  },
  mounted() {
    this.prefillMultiInputFields();
  },
  computed: {
    PERMISSION() {
      return PERMISSIONS["FILE-TEMPLATE"].MENU;
    },
    template() {
      return this.$store.getters["$_file_template/getTemplates"].filter(
        (template) => template.id === this.id
      );
    },
    singleInputFields() {
      return this.template[0]?.required_fields?.single_input_fields || [];
    },
    multiInputFields() {
      return this.template[0]?.required_fields?.multi_input_fields || [];
    },
  },
};
</script>

<style scoped>
.btn:disabled {
  cursor: not-allowed;
}
.full-width-button {
  width: 100%;
}
.inactive {
  background: #7498b4 !important;
}

.html-content {
  width: 210mm;
  max-width: 100%;
  margin: 0 auto;
  padding: 20mm;
  box-sizing: border-box;
  overflow: hidden;
}

.active {
  background: #8f1452 !important;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.chevron-icon {
  transition: transform 0.3s ease;
  font-size: 12px;
  margin-left: 10px;
}

.down {
  transform: rotate(180deg);
}
.toggle-section {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}
</style>
