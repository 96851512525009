<template>
  <router-view></router-view>
</template>

<script>
import store from "./_store";

export default {
  created() {
    const STORE_KEY = "$_shared_token";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>
