import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  reviewCategoriesWithReviews: [],
  categoriesWithJobs: [],
  activeCategoriesWithJobs: [],
  jobData: {},
  formSubmitStatus: {
    status: "INITIAL",
    uid: null,
  },
  applicationStatus: {},
  fresherOpeningEnabled: null,
  professionalOpeningEnabled: null,
  skillSet: [],
};

/**
 * @memberOf module:Home
 * @namespace store
 * @description central store for home module
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
