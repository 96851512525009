import axios from "axios";

/**
 * @memberof module:Report.api
 * @description - fetches option data in filter interface
 * @returns {Promise} - Array of positions, states & status
 */
const getFilterOptionsData = () => {
  return axios.get("/report/candidate/filterParams");
};

/**
 * @memberof module:Report.api
 * @namespace api
 * @description api for backend interactions
 */
export default {
  getFilterOptionsData,
};
