<template>
  <div
    class="modal fade"
    id="upload-applicant-model"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Upload Applicants -
            {{ selectedCampus && selectedCampus.recruitment_session_name }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="card-view mb-3" v-if="formData">
              <div class="">
                <label class="control-label m-0" htmlFor="college"
                  >Select College/University
                  <span class="text-danger">*</span>
                </label>
                <div class="d-flex" style="margin-top: 6px">
                  <select
                    id="college"
                    class="custom-select main"
                    v-model="parentEntity_id"
                  >
                    <option
                      v-for="option in formData.entitiesInvolved"
                      :value="option.entityDetail.id"
                      :key="option.entityDetail.id"
                    >
                      {{
                        option.entityDetail.entity_name +
                        " - (" +
                        option.entityDetail.entity_type +
                        ")"
                      }}
                    </option>
                  </select>
                  <div v-if="showSubSelection" class="mx-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right mx-1"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                    <select class="custom-select" v-model="subCollege_id">
                      <option
                        v-for="option in subColleges"
                        :value="option.entityDetail.id"
                        :key="option.entityDetail.id"
                      >
                        {{
                          option.entityDetail.entity_name +
                          " - (" +
                          option.entityDetail.entity_type +
                          ")"
                        }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-group mb-2">
              <div class="custom-file">
                <input
                  ref="fileInput"
                  type="file"
                  @click="clearFile"
                  @change="uploadFile"
                  class="custom-file-input"
                  id="my_file_input"
                />
                <label class="custom-file-label" for="my_file_input">{{
                  filename
                }}</label>
              </div>
            </div>

            <div class="d-flex justify-content-between" style="flex-wrap: wrap">
              <div>
                <button
                  v-if="file"
                  class="btn btn-cp btn-sm my-1"
                  @click="clearFile"
                >
                  Clear file
                </button>
              </div>
              <div class="d-flex ml-auto" v-if="applicantList.length > 0">
                <div
                  class="d-flex"
                  style="flex-wrap: wrap; justify-content: flex-end"
                >
                  <p
                    class="alert alert-info alert-xs m-1"
                    v-if="selectedRowCount > 0"
                  >
                    Selected {{ selectedRowCount }}.
                  </p>
                  <button
                    class="btn btn-sm btn-cp m-1"
                    @click="deleteApplicants"
                    v-if="selectedRowCount > 0"
                  >
                    Delete
                  </button>
                  <button
                    v-if="selectedApplicants.length !== applicantList.length"
                    class="btn btn-cp m-1 btn-sm"
                    @click="selectAllOnCurrentPage"
                  >
                    <i class="fa fa-square" aria-hidden="true"></i>Select all
                  </button>
                  <button
                    v-else
                    class="btn btn-cp m-1 btn-sm"
                    @click="deselectAllOnCurrentPage"
                  >
                    <i class="fa fa-check-square" aria-hidden="true"></i>
                    Deselect all
                  </button>
                  <button
                    class="btn btn-cp my-1 btn-sm ml-1"
                    @click="refreshData()"
                  >
                    <i class="fa fa-repeat" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
            <ag-grid-vue
              class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
              v-if="gridOptions"
              style="height: 50vh"
              :overlayLoadingTemplate="loadingTemplate"
              :overlayNoRowsTemplate="noRowsTemplate"
              :enableBrowserTooltips="true"
              :gridOptions="gridOptions"
              @gridReady="onGridReady"
            >
            </ag-grid-vue>
            <div>
              <p class="text-danger mb-0" v-if="errorMessage">
                {{ errorMessage }}
              </p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            ref="close"
            class="btn btn-secondary btn-sm"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            @click="applicantUpload"
            :disabled="
              !canUpload || gridOptions.api.getDisplayedRowCount() === 0
            "
            type="button"
            class="btn btn-cp ml-2"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberOf module:ApplicantsData
 * @namespace components.filter_data_modal
 * @description Filter data modal component
 */
import * as XLSX from "xlsx";
import store from "../../../store/index";
import { AgGridVue } from "ag-grid-vue";
import { COMMON_DATA_COL_DEFS } from "../_utils/applicant_data_list";
import api from "../_api/index";
import moment from "moment";
import {
  RECRUITMENT_FORM_DATA_VALIDATIONS,
  VALIDATIONS_MESSAGES,
} from "../../CampusRecruitment/_utils/recruitment-form-const";
import { generateRandomApplicantsData } from "../../CampusRecruitment/_utils/recruitment-form-const";
import BulkResolveDuplicateLink from "./bulk-resolve-duplicate-link.vue";
export default {
  props: ["selectedCampus"],
  components: {
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "applicant-resolve-link": BulkResolveDuplicateLink,
  },
  data() {
    return {
      dummayDataCount: 500,
      loadingTemplate: "Select File",
      noRowsTemplate: "No Students Found",
      gridOptions: null,
      file: null,
      applicantList: [],
      deletedApplicants: [],
      canUpload: false,
      columnDefs: COMMON_DATA_COL_DEFS,
      api: null,
      columnApi: null,
      selectedApplicants: [],
      selectedRowCount: null,
      parentEntity_id: null,
      subColleges: null,
      subCollege_id: null,
      formId: null,
      formData: null,
      isLastDateOver: null,
      sendingData: [],
      errorMessage: null,
    };
  },
  validations() {
    return {
      sendingData: {
        $each: RECRUITMENT_FORM_DATA_VALIDATIONS,
      },
    };
  },
  watch: {
    parentEntity_id: {
      handler(entity_id) {
        const entity = this.formData.entitiesInvolved.filter((ent) => {
          return ent.entityDetail.id == entity_id;
        });
        if (entity[0].subColleges) {
          this.subColleges = entity[0].subColleges;
          this.subCollege_id = entity[0].subColleges[0].entityDetail.id;
        } else {
          this.subColleges = null;
          this.subCollege_id = null;
        }
      },
      deep: true,
    },
  },
  computed: {
    filename() {
      return this.file ? this.file.name : "Choose file";
    },
    showSubSelection() {
      const selectedEntity = this.formData.entitiesInvolved.find((entity) => {
        return entity.entityDetail.id === this.parentEntity_id;
      });
      return (
        this.parentEntity_id &&
        selectedEntity.entityDetail.entity_type === "University"
      );
    },
  },
  methods: {
    generateRandomApplicantsData() {
      generateRandomApplicantsData();
    },
    checkLastDateOver(session) {
      const currentDate = moment();
      return moment(session.last_date).isBefore(currentDate);
    },
    checkDisabled() {
      if (this.gridOptions && this.gridOptions.api) {
        const duplicatesList = [];
        this.gridOptions.api.forEachNode((node, index) => {
          if (
            (node.data.hasDuplicate && node.data.hasDuplicate.length > 0) ||
            (node.data.hasServerDuplicates &&
              node.data.hasServerDuplicates.length > 0)
          ) {
            duplicatesList.push({ index: index });
          }
        });
        this.canUpload = duplicatesList.length <= 0;
        if (duplicatesList.length) {
          this.errorMessage = `Duplicate Applicants with email/phone no. at IDs ${duplicatesList
            .map((e) => e.index)
            .join(",")}`;
        } else this.errorMessage = null;
      } else {
        return false;
      }
    },
    _isLastDateOver() {
      return moment(this.formData.last_date).isBefore(this.currentDate);
    },

    /**
     * @memberof module:campusRecruitment.components.campus-recruitment-form
     * @description Set default selected entity as a first of list in enitity list
     */
    setDefaultSelectedEntity() {
      if (this.formData.entitiesInvolved.length == 0) return;
      this.parentEntity_id = this.formData.entitiesInvolved[0].entityDetail.id;
    },
    createdUploadPage() {
      api
        .fetchCampusRecruitmentDetailsByUniqueId(
          this.selectedCampus.unique_id,
          true
        )
        .then((response) => {
          this.formData = response.data;

          if (this._isLastDateOver()) {
            this.isLastDateOver = true;
          } else if (this.formData.status === "Completed") {
            this.isCompleted = true;
          } else {
            this.setDefaultSelectedEntity();
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.showSnackbar("danger", "Unable to fetch form data!");
        });
    },
    showSnackbar(type, message) {
      this.$store.dispatch("openSnackbar", {
        type: type,
        message: message,
      });
    },
    clearFile() {
      this.$refs.fileInput.value = null;
      this.file = null;
      this.applicantList = [];
      this.selectedApplicants = [];
      this.selectedRowCount = null;
      this.deletedApplicants = [];
      this.gridOptions.api.setGridOption("rowData", []);
      this.canUpload = false;
      this.errorMessage = null;
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to convert xlsx file to applicants object to upload on server
     */
    uploadFile(event) {
      const numberFields = [
        "mobile_no",
        "ssc_percentage",
        "hsc_percentage",
        "aggregate_percentage",
        "grad_percentage",
        "semester1",
        "semester2",
        "semester3",
        "semester4",
        "semester5",
        "semester6",
        "semester7",
        "semester8",
      ];
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {
            type: "binary",
            row: true,
            cellDates: false,
          });
          let data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
            defval: null,
            raw: false,
          });
          data = data.map((applicant, index) => {
            for (const field in applicant) {
              const value =
                typeof applicant[field] === "string"
                  ? applicant[field].trim()
                  : applicant[field];
              applicant[field] =
                numberFields.includes(field) && value
                  ? parseInt(value) || value
                  : value;
            }
            return { ...applicant, id: index };
          });
          this.applicantList = this.resolveDuplicate(data);
          this.selectedApplicants = [];
          this.selectedRowCount = null;
          this.deletedApplicants = [];
          this.gridOptions.api.setGridOption("rowData", this.applicantList);
          this.checkDisabled();
        };
        reader.readAsBinaryString(this.file);
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to set gridOptions apis
     */
    onGridReady(params) {
      this.gridOptions.api = params.api;
    },
    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to call function on ag grid selection change
     */
    onSelectionChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to select all applicant in current page
     */
    selectAllOnCurrentPage() {
      this.selectedRowCount = 0;
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(true);
        this.selectedRowCount++;
      });
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to deselect all applicant in current page
     */
    deselectAllOnCurrentPage() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
      this.selectedRowCount = 0;
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to refresh data in ag grid data table
     */
    refreshData() {
      this.gridOptions.api.setGridOption("rowData", this.applicantList);
      this.deletedApplicants = [];
      this.selectedApplicants = [];
      this.selectedRowCount = null;
      this.checkDisabled();
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to return an array of all id from array of objects
     */
    getIdFromObject(obj) {
      return obj.map((ob) => {
        return ob.id;
      });
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to delete selected applicants
     */
    deleteApplicants() {
      const ids = [
        ...this.getIdFromObject(this.selectedApplicants),
        ...this.deletedApplicants,
      ];
      const afterDeleted = this.applicantList.filter((applicant) => {
        return !ids.includes(applicant.id);
      });
      this.gridOptions.api.setGridOption(
        "rowData",
        this.resolveDuplicate(afterDeleted)
      );
      this.deletedApplicants = ids;
      this.selectedApplicants = [];
      this.selectedRowCount = null;
      this.checkDisabled();
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to add local duplicat key in each applicant if exist
     */
    resolveDuplicate(list) {
      const checked = list.map((applicant, index) => {
        if (index === 0) {
          return { ...applicant, hasDuplicate: [] };
        } else {
          const hasDuplicate = [];
          for (let j = index - 1; j >= 0; j--) {
            if (applicant.email == list[j].email) {
              hasDuplicate.push({
                to_applicant_id: list[j].id,
                key: "email",
                email: applicant.email,
              });
            }
            if (applicant.mobile_no == list[j].mobile_no) {
              hasDuplicate.push({
                to_applicant_id: list[j].id,
                key: "mobile_no",
                mobile_no: applicant.mobile_no,
              });
            }
          }
          return { ...applicant, hasDuplicate: hasDuplicate };
        }
      });
      return checked;
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to add server duplicates
     */
    addServerDuplicates(errorObject) {
      const newList = [];
      this.gridOptions.api.forEachNode((node, index) => {
        const hasServerDuplicates = errorObject[index].map((duplicate) => {
          return {
            mobile_no: duplicate.mobile_no,
            email: duplicate.email,
          };
        });
        const nodeData = {
          ...node.data,
          hasServerDuplicates: hasServerDuplicates,
        };
        newList.push(nodeData);
      });
      return newList;
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-upload-applicants
     * @description method to upload applicants to selected active session
     */
    async applicantUpload() {
      const applicants = [];
      this.gridOptions.api.forEachNodeAfterFilterAndSort((node) => {
        const data = {
          ...node.data,
          mobile_no: node.data.mobile_no.toString(),
          entity_id: this.subCollege_id
            ? this.subCollege_id
            : this.parentEntity_id,
          campus_unique_id: this.selectedCampus.unique_id,
          dob: moment(node.data.dob),
        };
        delete data.id;
        delete data.hasDuplicate;
        delete data.hasServerDuplicates;
        applicants.push(data);
      });
      this.sendingData = applicants;
      await this.$v.$touch();
      if (this.$v.sendingData.$error)
        for (const index in this.$v.sendingData.$each) {
          if (index[0] !== "$" && this.$v.sendingData.$each[index].$error) {
            for (const field in this.$v.sendingData.$each[index]) {
              if (
                field[0] !== "$" &&
                this.$v.sendingData.$each[index][field].$error
              ) {
                if (this.$v.sendingData.$each[index][field].$error) {
                  this.$alert(
                    `At row ${parseInt(index) + 1} - ${field} - ${
                      VALIDATIONS_MESSAGES[field]
                    }`
                  );
                  return;
                }
              }
            }
          }
        }
      store.dispatch("setLoading", {
        isLoading: true,
        loadingMessage: "Please wait while applicants are uploaded",
      });
      await api
        .sendBulkApplicants(applicants)
        .then(() => {
          this.$store.dispatch("setLoading", {
            isLoading: false,
            loadingMessage: "",
          });
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Student list has been uploaded" },
            { root: true }
          );
          this.$refs.close.click();
          this.$emit("refreshData");
        })
        .catch((err) => {
          this.$store.dispatch("setLoading", {
            isLoading: false,
            loadingMessage: "",
          });
          this.$alert(
            err.response.data?.message
              ? err.response.data.message
              : "Something went wrong"
          );
          if (err.response.data.data) {
            const newList = this.addServerDuplicates(err.response.data.data);
            this.gridOptions.api.setGridOption(
              "rowData",
              this.resolveDuplicate(newList)
            );
            this.checkDisabled();
          }
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: err.response.data?.message
                ? err.response.data.message
                : "Something went wrong",
            },
            { root: true }
          );
        });
    },
  },
  beforeMount() {
    const gridOptions = {
      // PROPERTIES
      // Objects like myRowData and myColDefs would be created in your application
      rowData: null,
      columnDefs: this.columnDefs,
      onSelectionChanged: () => {
        this.onSelectionChanged();
      },
      rowHeight: 30,
      headerHeight: 30,
      // CALLBACKS
      suppressPropertyNamesCheck: true,
      suppressRowClickSelection: true,
      enableCellTextSelection: true,
      enableServerSideFilter: true,
      enableColResize: true,
      rowSelection: "multiple",
      defaultColDef: {
        flex: 1,
        floatingFilter: true,
        resizable: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        // set filtering on for all columns
        filter: true,
      },
      infiniteInitialRowCount: 0,
      animateRows: true,
      // cacheOverflowSize: 2,
      // maxConcurrentDatasourceRequests: 2,
      // pagination: true,
      // paginationPageSize: 100,
      // cacheBlockSize: 100,
      context: {
        componentParent: this,
      },
    };
    this.gridOptions = gridOptions;
  },
};
</script>
<style scoped>
@media (max-width: 576px) {
  .modal-dialog {
    margin: 1.75rem 1.5rem;
  }
}

.ag-grid-style {
  margin-top: 10px;
}

.ag-theme-alpine .ag-header-group-cell-label {
  flex-direction: row-reverse;
}

.dropdown-menu {
  top: 50px;
}

.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 3px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #803059;
  color: white;
}

.dropdown-menu .dropdown-header {
  padding: 3px 20px;
  color: #823e5b;
  font-size: 16px;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

li.dropdown > a {
  color: white;
}

.custom-select.college {
  width: 250px;
  max-width: 100%;
}

.custom-select {
  width: 230px;
  max-width: 100%;
}
</style>
