export const RECRUITMENT_COLUMNS_DEFS = [
  {
    headerName: "Recruitment Name",
    field: "recruitment_session_name",
    cellRenderer: "campus-recruitment-name",
    comparator: (valueA, valueB) => {
      if (valueA && valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      }
      return 0;
    },
    width: 300,
    minWidth: 250,
  },
  {
    headerName: "Entity details",
    children: [
      {
        headerName: "Entities involved",
        field: "entity_name",
        cellRenderer: "entity-list",
        suppressFilter: true,
        filter: true,
        comparator: (valueA, valueB) => {
          if (valueA && valueB) {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          }
          return 0;
        },

        width: 300,
        minWidth: 300,
        wrapText: true,
        autoHeight: true,
        height: 200,
        valueGetter: (params) => {
          return params.data?.entitiesInvolved
            ? params.data.entitiesInvolved
                .map((entity) =>
                  entity.entityDetail ? entity.entityDetail.entity_name : ""
                )
                .join(", ")
            : "";
        },
      },
      {
        headerName: "City",
        field: "entity_city",
        cellRenderer: "entity-city-list",
        suppressFilter: true,
        comparator: (valueA, valueB) => {
          if (valueA && valueB) {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          }
          return 0;
        },
        filter: true,
        width: 150,
        minWidth: 150,
        wrapText: true,
        autoHeight: true,
        height: 200,
        valueGetter: (params) =>
          params.data?.entitiesInvolved
            ? params.data.entitiesInvolved
                .map((entity) =>
                  entity.entityDetail ? entity.entityDetail.city : ""
                )
                .join(", ")
            : "",
      },
    ],
  },
  {
    headerName: "Applicants Registered",
    cellRenderer: "applicant-registered",
    suppressFilter: true,
    filter: false,
    width: 100,
    minWidth: 100,
  },
  {
    headerName: "Contact Person Details",
    children: [
      {
        headerName: "Name",
        cellRenderer: "contact-person-name",
        field: "contact_name",
        suppressFilter: true,
        filter: true,
        minWidth: 150,
        wrapText: true,
        autoHeight: true,
        valueGetter: (params) => {
          const contacts = [];
          if (params.data?.entitiesInvolved) {
            params.data.entitiesInvolved.forEach((entity) => {
              if (entity.entityDetail?.contactPersonDetails) {
                entity.entityDetail.contactPersonDetails.forEach((contact) => {
                  contacts.push(contact.name);
                });
              }
            });
            return contacts.join(", ");
          }
          return "";
        },
      },
      {
        headerName: "Email",
        cellRenderer: "contact-person-email",
        field: "contact_email",
        suppressFilter: true,
        filter: true,
        minWidth: 150,
        wrapText: true,
        autoHeight: true,
        valueGetter: (params) => {
          const contacts = [];
          if (params.data?.entitiesInvolved) {
            params.data.entitiesInvolved.forEach((entity) => {
              if (entity.entityDetail?.contactPersonDetails) {
                entity.entityDetail.contactPersonDetails.forEach((contact) => {
                  contacts.push(contact.email);
                });
              }
            });
            return contacts.join(", ");
          }
          return "";
        },
      },
      {
        headerName: "Contact",
        field: "contact_mobile_no",
        cellRenderer: "contact-person-mobile_no",
        suppressFilter: true,
        filter: true,
        minWidth: 100,
        wrapText: true,
        autoHeight: true,
        valueGetter: (params) => {
          const contacts = [];
          if (params.data?.entitiesInvolved) {
            params.data.entitiesInvolved.forEach((entity) => {
              if (entity.entityDetail?.contactPersonDetails) {
                entity.entityDetail.contactPersonDetails.forEach((contact) => {
                  contacts.push(contact.mobile_no);
                });
              }
            });
            return contacts.join(", ");
          }
          return "";
        },
      },
    ],
  },
  {
    headerName: "Last Date Of Reg.",
    field: "last_date",
    filter: "agDateColumnFilter",
    minWidth: 150,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        let dt = new Date(params.data.last_date);
        return dt.toLocaleDateString().toString();
      }
    },
  },
  {
    headerName: "Status",
    field: "status",
    minWidth: 100,
  },
  {
    headerName: "Actions",
    children: [
      {
        cellRenderer: "copy-form-url",
        suppressFilter: true,
        filter: false,
        minWidth: 40,
        maxWidth: 40,
        pinned: "right",
        cellStyle: { textAlign: "center", padding: 0 },
      },
      {
        cellRenderer: "update-status",
        suppressFilter: true,
        filter: false,
        minWidth: 40,
        maxWidth: 40,
        pinned: "right",
        cellStyle: { textAlign: "center", padding: 0 },
      },
      {
        cellRenderer: "update-recruitment-last-date",
        suppressFilter: true,
        filter: false,
        minWidth: 40,
        maxWidth: 40,
        pinned: "right",
        cellStyle: { textAlign: "center", padding: 0 },
      },
    ],
    pinned: "right",
    cellStyle: { textAlign: "center", padding: 0 },
  },
];
