/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {number} data count od registered applicant
 * @description update registred applicant count
 */
const FETCH_APPLICATION_STATUS_COUNT = (state, data) => {
  state.applicationStatusCount = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set application position and their count in store
 */
const FETCH_APPLICATION_POSITION_COUNT = (state, data) => {
  state.applicationPositionCount = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set recruiter sessions list in store
 */
const SET_SESSION_LIST = (state, data) => {
  state.sessionList = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set recruiter sessions list in store
 */
const SET_SELECTED_SESSION = (state, data) => {
  state.selectedSession = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set recruiter sessions list in store
 */
const SET_SELECTED_COLLEGE = (state, data) => {
  state.selectedCollege = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set logged in user
 */
const SET_LOGGED_IN_USER = (state, data) => {
  state.loggedInUser = data;
};

const ENTITY_LIST_DETAILS = (state, data) => {
  state.entityListDetails = data;
};

/**
 * @memberof module:campusRecruitment
 * @namespace store.mutations
 * @description mutations for mailbox module
 */
export default {
  FETCH_APPLICATION_STATUS_COUNT,
  FETCH_APPLICATION_POSITION_COUNT,
  SET_SESSION_LIST,
  SET_SELECTED_SESSION,
  SET_LOGGED_IN_USER,
  ENTITY_LIST_DETAILS,
  SET_SELECTED_COLLEGE,
};
