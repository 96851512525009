import fileTemplateHome from "./index";
import fileTemplateManager from "./_components/file-template-manager.vue";
import PERMISSIONS from "../Common/permissions";

export default {
  path: "file-templates",
  component: fileTemplateHome,
  children: [
    {
      path: "",
      name: "manageFileTemplates",
      component: fileTemplateManager,
      meta: {
        title: "File Template Manager",
        user: "employee",
        permissions: [PERMISSIONS["FILE-TEMPLATE"].MENU],
        checkAll: true,
      },
    },
    {
      path: "create/",
      name: "create-template",
      component: () => import("./_components/template-create-item.vue"),
      meta: {
        title: "Template creator",
        user: "employee",
        permissions: [PERMISSIONS["FILE-TEMPLATE"].CREATE],
      },
    },

    {
      path: "edit/:id",
      name: "edit-template",
      component: () => import("./_components/template-create-item.vue"),
      meta: {
        title: "Template editor",
        user: "employee",
        permissions: [PERMISSIONS["FILE-TEMPLATE"].UPDATE],
      },
    },
  ],
};
