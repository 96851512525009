import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  ApplicantData: {},
  FormEditedStatus: {
    status: "INITIAL",
  },
  newResume: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
