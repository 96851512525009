<template>
  <div
    class="modal fade"
    id="viewTemplateModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="viewModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100" id="viewModalContent">
        <div class="modal-header">
          <h4 class="modal-title" id="viewModalLabel">Template</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="template-container">
            <p class="sub-margin">
              <span class="heading">Name:</span>{{ viewTemplate.name }}
            </p>
            <p class="sub-margin">
              <span class="heading">Subject:</span
              >{{ viewTemplate.template_subject }}
            </p>
            <div class="sub-margin">
              <span class="heading">CC:</span>
              <ul v-if="viewTemplate.cc && viewTemplate.cc.length">
                <li v-for="(cc, index) in viewTemplate.cc" :key="index">
                  {{ cc }}
                </li>
              </ul>
              <span v-else><em>Not added</em></span>
            </div>
            <div class="sub-margin">
              <span class="heading">BCC:</span>
              <ul v-if="viewTemplate.bcc && viewTemplate.bcc.length">
                <li v-for="(bcc, index) in viewTemplate.bcc" :key="index">
                  {{ bcc }}
                </li>
              </ul>
              <span v-else><em>Not added</em></span>
            </div>
            <p class="sub-margin">
              <span class="heading">Body:</span>
              <safe-content
                v-if="viewTemplate.template_body"
                :content="viewTemplate.template_body"
                tag="span"
              ></safe-content>
            </p>
          </div>
          <p class="text-danger" v-if="!normalMail">
            * Only mail body will be considered in this mail!
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberof module:MailBox
 * @namespace components.view_template_modal
 * @description Modal for preview of template
 */
export default {
  props: ["viewTemplate", "normalMail"],
  beforeDestroy() {
    $("#viewTemplateModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.template-container {
  border: 1px solid #8b509661;
  margin-bottom: 10px;
}
</style>
