import axios from "axios";

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} campusRecruitment
 * @returns {Promise}
 * @description delete contact person details
 */
const deleteEntityContactPersonDetail = (value) => {
  return axios.delete("campus-drive/recruiters/" + value);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} campusRecruitment
 * @returns {Promise}
 * @description delete course details
 */
const deleteEntityCourseDetail = (value) => {
  return axios.delete("campus-drive/courses/" + value);
};
/**
 * @memberof module:campusRecruitment.api
 * @param {Object} campusRecruitment
 * @returns {Promise}
 * @description validate student list data
 */
const validateCampusRecruitment = (campusRecruitment) => {
  const formData = new FormData();

  formData.append("student_list_file", campusRecruitment["student_list_file"]);
  let campusRecruitmentData = JSON.stringify(campusRecruitment);
  formData.append("data", campusRecruitmentData);
  return axios.post("campus-drive/recruitments/validate", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} campusData
 * @returns {Promise}
 * @description Add campus recruitment details
 */
const addCampusRecruitment = (campusData) => {
  const data = { data: campusData };
  return axios.post("campus-drive/recruitments/create", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} entity
 * @returns {Promise}
 * @description Add entity details
 */
const addEntity = (entity) => {
  const data = { data: entity };
  return axios.post("campus-drive/entities/create", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} id
 * @returns {Promise}
 * @description create recruiter
 */
const createUser = (id) => {
  const data = { data: { id } };
  return axios.post("campus-drive/recruiters/create-account", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {String} accountPassword
 * @returns {Promise}
 * @description reset recruiter's password
 */
const resetPassword = (accountPassword) => {
  const data = { data: accountPassword };
  return axios.post("recruiter/set-password", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} id
 * @returns {Promise}
 * @description create recruiter password
 */
const initiatePasswordReset = (id) => {
  return axios.post(
    "campus-drive/recruiters/initiate-password-reset/" + id,
    {}
  );
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} value
 * @returns {Promise}
 * @description check recruiter expiry date
 */
const checkExpiry = (value) => {
  return axios.get("recruiter/password-generation/" + value);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} id
 * @returns {Promise}
 * @description login recruiter
 */
const loginUser = (email) => {
  const data = { data: email };
  return axios.post("/login", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} id
 * @returns {Promise}
 * @description get entity data
 */
const entityId = (id) => {
  return axios.get(`campus-drive/entities/by/id/${id}/included`);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} id
 * @returns {Promise}
 * @description get campus recruitment data
 */
const campusRecruitmentById = (id) => {
  return axios.get(`/campus-drive/recruitments/by/id/${id}`);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} entity
 * @returns {Promise}
 * @description Update entity details
 */
const updateEntity = (entity) => {
  const data = {
    data: entity,
  };
  return axios.put("campus-drive/entities/update", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {}
 * @returns {Promise}
 * @description Get entity details
 */
const fetchEntities = () => {
  return axios.get("campus-drive/entities/data-list");
};

/**
 * @memberof module:campusRecruitment.api
 * @param {}
 * @returns {Promise}
 * @description Get campus recruitment details
 */
const fetchCampusRecruitments = (filter) => {
  return axios.post(
    "campus-drive/recruitments/data-list",
    filter.filterParams,
    { params: filter.queryParams }
  );
};

/**
 * @memberof module:campusRecruitment.api
 * @param {number} id
 * @returns {Promise}
 * @description Block the entity by id
 *
 */
const deleteEntity = (value) => {
  let data = { data: value };
  return axios.put("campus-drive/entities/update/status", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {number} id
 * @returns {Promise}
 * @description Active entity by id
 */
const activeEntity = (id) => {
  let data = { data: { id } };
  return axios.put("campus-drive/entities/update/status", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {number} id
 * @returns {Promise}
 * @description Update campus recruitment details by id
 */
const updateCampusRecruitmentStatus = (id) => {
  let data = { data: { id } };
  return axios.put("campus-drive/recruitments/update/status", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {number} id
 * @returns {Promise}
 * @description update campus recruitment last date by id
 */
const updateCampusRecruitmentLastDate = (id, last_date) => {
  let data = { data: { id, last_date } };
  return axios.put("campus-drive/recruitments/update/last-date", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {number} id
 * @returns {Promise}
 * @description Fetch Campus Recruitment Details by unique id for forms
 */
const fetchCampusRecruitmentDetailsByUniqueId = (id, format = false) => {
  return axios.get(`campus-drive/recruitments/by/unique-id/${id}`, {
    params: {
      format: format,
    },
  });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {data} Object
 * @returns {Promise}
 * @description Register student for campus recruitment
 */
const registerStudentForCampusRecruitment = (studentData) => {
  const data = { data: studentData };
  return axios.post("campus-drive/recruiters/register-applicant", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {data} Object
 * @returns {Promise}
 * @description gets count of registred applicant
 */
const getRegisteredApplicantCount = (campusData) => {
  let id = [];
  campusData.forEach((cdata) => {
    id.push(cdata.id);
  });
  let reqData = { data: id };
  return axios.post("campus-drive/recruitments/count/applicants", reqData);
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description Fetch multiple round schedules of all applicants from current date
 */
const fetchRoundSchedules = (filters) => {
  if (filters) {
    return axios.get("campus-drive/schedules/round-schedule", {
      params: filters,
    });
  } else {
    return axios.get("campus-drive/schedules/round-schedule");
  }
};

const fetchMyCampusRoundSchedules = (filters) => {
  if (filters) {
    return axios.get("campus-drive/schedules/my-campus-schedules", {
      params: filters,
    });
  } else {
    return axios.get("campus-drive/schedules/my-campus-schedules");
  }
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description create entity event
 */
const postRoundSchedules = (data) => {
  return axios.post("campus-drive/schedules/round-schedule", { data });
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description update entity event
 */
const putRoundSchedules = (data) => {
  return axios.put("campus-drive/schedules/round-schedule", { data });
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description get
 */
const fetchCampuses = (filter) => {
  if (filter) {
    return axios.get("campus-drive/recruitments/all_session_details" + filter);
  }
  return axios.get("campus-drive/recruitments/all_session_details");
};

const filterCampusRecruitments = (filter) => {
  return axios.post(
    "campus-drive/recruitments/filter-data-list",
    {},
    { params: filter }
  );
};
/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description fetch event by event id
 */
const fetchEventById = (id) => {
  return axios.get("campus-drive/schedules/round-schedule/by/id/" + id);
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description create slot for event
 */
const createSlot = (slotData) => {
  return axios.post("campus-drive/slots/create", { data: slotData });
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description get list of slot
 */
const getSlots = (filter) => {
  if (filter) {
    return axios.get("campus-drive/slots/data-list" + filter);
  }
  return axios.get("campus-drive/slots/data-list");
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description add applicant to slot
 */
const addApplicantsToSlot = (data) => {
  return axios.put("campus-drive/slots/schedules", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description remove applicant from slot
 */
const removeApplicantsFromSlot = (data) => {
  return axios.post("/campus-drive/slots/schedules/cancel", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description get slot data
 */
const getSelectedSlotData = (id) => {
  return axios.get("campus-drive/slots/by/id/" + id);
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description delete slot
 */
const deleteSlot = (data) => {
  return axios.post("campus-drive/slots/delete", data);
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} data
 * @returns {Promise}
 * @description test connection to moodle server
 */
const testConnection = (data) => {
  return axios.post("campus-drive/moodles/test-connection", { data });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} instanceId
 * @returns {Promise}
 * @description get all course of moodle instance
 */
const getAllCourses = (instanceId) => {
  return axios.post("campus-drive/moodles/courses/data-list", { instanceId });
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description get all moodle instance
 */
const getAllMoodleInstances = () => {
  return axios.get("campus-drive/moodles/instances/data-list");
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} data
 * @returns {Promise}
 * @description add moodle instance
 */
const addMoodleInstance = (data) => {
  return axios.post("campus-drive/moodles/instances/add", { data });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} data
 * @returns {Promise}
 * @description delete moodle instance
 */
const deleteMoodleInstance = (id) => {
  return axios.post("campus-drive/moodles/instances/delete", { id });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} data
 * @returns {Promise}
 * @description update moodle instance
 */
const updateMoodleInstance = (data) => {
  return axios.post("campus-drive/moodles/instances/update", {
    id: data.id,
    data: { label: data.label, url: data.url, ws_token: data.ws_token },
  });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} id
 * @param {Number} eventId
 * @returns {Promise}
 * @description add users moodle credentials
 */
const getMoodleInfo = (id, eventId) => {
  return axios.get(`campus-drive/moodles/get-user-details/${id}`, {
    params: { id: id, eventId: eventId },
  });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} instanceId
 * @param {Number} courseId
 * @param {Number} eventId
 * @param {Object} data
 * @returns {Promise}
 * @description enroll user to moodle course
 */
const enrolMoodleUserToCourse = (instanceId, courseId, eventId, data) => {
  return axios.post("campus-drive/moodles/enrol-user-to-course", {
    instanceId,
    courseId,
    eventId,
    data,
  });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} eventId
 * @param {Object} data
 * @returns {Promise}
 * @description unenroll user from moddle course
 */
const unenrolMoodleUserFromCourse = (eventId, data) => {
  return axios.post("campus-drive/moodles/unenrol-user-from-course", {
    eventId,
    data,
  });
};

const unenrolDeleteUserFromMoodle = (data) => {
  return axios.post("campus-drive/moodles/unenrol-delete-user-from-moodle", {
    data,
  });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} applicantIds
 * @param {Number} minQualPercent
 * @param {Number} eventId
 * @returns {Promise}
 * @description check pass and fail applicants count
 */
const passQualified = (applicantIds, minQualPercent, eventId) => {
  return axios.post("campus-drive/moodles/pass-qualified", {
    applicantIds,
    minQualPercent,
    eventId,
  });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Number} applicantIds
 * @param {Number} minQualPercent
 * @param {Number} eventId
 * @returns {Promise}
 * @description update pass and fail moodle course enrolled applicants
 */
const updateApplicantStatus = (passedApplicant, failedApplicant) => {
  return axios.post("campus-drive/moodles/update-applicant-status", {
    passedApplicant,
    failedApplicant,
  });
};

/**
 * @memberof module:campusRecruitment.api
 * @param {Object} data
 * @returns {Promise}
 * @description update applicant status
 */
const updateApplicantStatusSlot = (data) => {
  return axios.post("campus-drive/moodles/update-applicant-status-slot", data);
};

const updateApplicantStatusEvent = (data) => {
  return axios.post(
    "campus-drive/slots/schedules/update/applicant-status",
    data
  );
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description Fetch application status and their count
 */
const fetchApplicationStatusCount = (data = {}) => {
  return axios.get("campus-drive/recruitments/count/status", { params: data });
};

/**
 * @memberof module:campusRecruitment.api
 * @returns {Promise}
 * @description send bulk applicants
 */
const sendBulkApplicants = (applicants) => {
  return axios.post("campus-drive/recruitments/register/bulk-applicant", {
    data: applicants,
  });
};

/**
 * @memberOf module:ApplicantProfile.api
 * @param {String} applicant_id
 * @returns {Promise}
 * @description Fetch Applicant round data
 */
const activeRoundSlotApplicant = (applicant_id, slotMasterId) => {
  return axios.get(
    "campus-drive/schedules/applicant_slot_round_schedules/" + applicant_id,
    {
      params: { slotMasterId },
    }
  );
};

const activeMultipleRoundSlotApplicant = (applicantIds, slotMasterId) => {
  return axios.get(
    "campus-drive/schedules/applicant_slot_round_schedules/by/ids",
    {
      params: { slotMasterId, applicantIds },
    }
  );
};

/**
 * @memberof module:Campus-recruitment.api
 * @param {Object} roundData
 * @returns {Promise}
 * @description Edit Round Data for Applicant from slot
 */
const updateRoundFromSlot = (roundData) => {
  let stringifiedRoundData = JSON.stringify(roundData);
  stringifiedRoundData = JSON.parse(stringifiedRoundData);
  delete stringifiedRoundData.createdAt;
  delete stringifiedRoundData.updatedAt;
  delete stringifiedRoundData.interviewers;
  delete stringifiedRoundData.reviewers;
  delete stringifiedRoundData.created_by;
  delete stringifiedRoundData.updated_by;
  delete stringifiedRoundData.applicantIterationMaster;
  const data = {
    data: stringifiedRoundData,
  };
  return axios.put("campus-drive/schedules/slot_round_schedule", data);
};
const updateMultipleRoundFromSlot = (roundData) => {
  let stringifiedRoundData = JSON.stringify(roundData);
  stringifiedRoundData = JSON.parse(stringifiedRoundData);
  delete stringifiedRoundData.createdAt;
  delete stringifiedRoundData.updatedAt;
  delete stringifiedRoundData.interviewers;
  delete stringifiedRoundData.reviewers;
  delete stringifiedRoundData.created_by;
  delete stringifiedRoundData.updated_by;
  delete stringifiedRoundData.applicantIterationMaster;
  const data = {
    data: stringifiedRoundData,
  };
  return axios.put("campus-drive/schedules/multiple_slot_round_schedule", data);
};

const sendGoogleMeetInvitation = (data) => {
  return axios.post("campus-drive/slots/schedules/send_meet_invitations", data);
};

/**
 * @memberof module:Campus-recruitment.api
 * @returns {Promise}
 * @description delete multiple applicants
 */
const deleteRecruitmentMultipleApplicants = (ids, campusId) => {
  return axios.post("/campus-drive/recruitments/applicants/delete/bulk", {
    id: ids,
    campusId: campusId,
  });
};

/**
 * @memberof module:Campus-recruitment.api
 * @param {Object} data containing entityId, start-date and end-date for filtering
 * @returns {Promise}
 * @description fetching round schedules for a particular entity.
 */
const getEntityRoundSchedules = (data) => {
  return axios.get(`campus-drive/schedules/by/entityId/${data.entityId}`, {
    params: data,
  });
};

/**
 * @memberof module:campusRecruitment.api
 * @memberof module:campusRecruitment
 * @namespace api
 * @description api for backend interactions
 */
export default {
  validateCampusRecruitment,
  fetchEntities,
  fetchCampusRecruitments,
  addEntity,
  deleteEntity,
  updateEntity,
  addCampusRecruitment,
  activeEntity,
  updateCampusRecruitmentStatus,
  fetchCampusRecruitmentDetailsByUniqueId,
  registerStudentForCampusRecruitment,
  getRegisteredApplicantCount,
  deleteEntityContactPersonDetail,
  deleteEntityCourseDetail,
  createUser,
  resetPassword,
  initiatePasswordReset,
  checkExpiry,
  loginUser,
  entityId,
  campusRecruitmentById,
  fetchRoundSchedules,
  postRoundSchedules,
  putRoundSchedules,
  fetchCampuses,
  fetchEventById,
  createSlot,
  getSlots,
  addApplicantsToSlot,
  getSelectedSlotData,
  removeApplicantsFromSlot,
  deleteSlot,
  fetchApplicationStatusCount,
  getAllMoodleInstances,
  addMoodleInstance,
  deleteMoodleInstance,
  updateMoodleInstance,
  getMoodleInfo,
  getAllCourses,
  enrolMoodleUserToCourse,
  unenrolMoodleUserFromCourse,
  passQualified,
  updateApplicantStatus,
  updateApplicantStatusSlot,
  updateApplicantStatusEvent,
  sendBulkApplicants,
  testConnection,
  activeRoundSlotApplicant,
  updateRoundFromSlot,
  updateCampusRecruitmentLastDate,
  filterCampusRecruitments,
  sendGoogleMeetInvitation,
  fetchMyCampusRoundSchedules,
  deleteRecruitmentMultipleApplicants,
  activeMultipleRoundSlotApplicant,
  updateMultipleRoundFromSlot,
  getEntityRoundSchedules,
  unenrolDeleteUserFromMoodle,
};
