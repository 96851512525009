var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-2"},[_c('div',{staticClass:"well section-head w-auto"},[_c('div',{staticClass:"row mx-0"},[_vm._m(0),_c('div',{staticClass:"col-7 px-0"},[(_vm.hasAccess({ permissions: [_vm.PERMISSIONS.MAIL.CREATE] }))?_c('button',{staticClass:"btn btn-cp float-right",attrs:{"role":"button"},on:{"click":_vm.navigateToMailbox}},[_c('i',{staticClass:"fa fa-plus fa-lg",attrs:{"aria-hidden":"true"}}),_c('strong',[_vm._v("New Email")])]):_vm._e()])])]),_c('div',{staticClass:"p-0 mx-3",attrs:{"id":"comments"}},[(_vm.mails.length > 0)?_c('div',{staticClass:"well container-email mt-2"},_vm._l((_vm.mails),function(mail,index){return _c('div',{key:index,staticClass:"accordion",class:[mail.type === 'received' ? 'received-mail' : 'sent-mail'],attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('accordion-' + index),expression:"'accordion-' + index"}],class:[
                mail.type === 'received' ? 'received-title' : 'sent-title',
              ],attrs:{"block":""}},[_c('div',{staticClass:"row p-1"},[(!_vm.isUnread(mail.labels))?_c('div',{staticClass:"col-8"},[(mail.attachments.length > 0)?_c('i',{staticClass:"fa fa-paperclip"}):_vm._e(),_vm._v("   "+_vm._s(mail.subject)+" ")]):_vm._e(),(_vm.isUnread(mail.labels))?_c('div',{staticClass:"col-8"},[_c('strong',[(mail.attachments.length > 0)?_c('i',{staticClass:"fa fa-paperclip"}):_vm._e(),_vm._v("   "+_vm._s(mail.subject))])]):_vm._e(),_c('div',{staticClass:"col-4 text-right text-secondary"},[_vm._v(" "+_vm._s(_vm.formatDate(mail.date))+" ")])])])]),_c('b-collapse',{attrs:{"id":'accordion-' + index,"accordion":"my-accordion","role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[_c('div',[(mail.to)?_c('p',{staticClass:"mb-2"},[_vm._v(" To :     "+_vm._s(mail.to)+" ")]):_vm._e(),(mail.from)?_c('p',{staticClass:"mb-2"},[_vm._v(" From :     "+_vm._s(mail.from)+" ")]):_vm._e(),(mail.cc)?_c('p',{staticClass:"mb-2"},[_vm._v(" Cc :     "+_vm._s(mail.cc)+" ")]):_vm._e(),(mail.bcc)?_c('p',{staticClass:"mb-2"},[_vm._v(" Bcc :     "+_vm._s(mail.bcc)+" ")]):_vm._e()]),_c('mail-component',{attrs:{"mailData":mail.body}}),(mail.attachments.length > 0)?_c('div',{staticClass:"mt-4"},_vm._l((mail.attachments),function(attachment){return _c('div',{key:attachment.attachmentId,staticClass:"mt-1"},[_c('i',{staticClass:"fa fa-paperclip"}),_vm._v("   "),_c('span',{staticClass:"attachment",on:{"click":function($event){return _vm.getAttachment(
                          mail.messageId,
                          attachment.id,
                          attachment.mimeType
                        )}}},[_vm._v(_vm._s(attachment.fileName))])])}),0):_vm._e()],1)],1)],1)],1)],1)}),0):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5 px-0"},[_c('h4',[_c('strong',[_vm._v("Emails")])])])
}]

export { render, staticRenderFns }