/**
 * @memberOf module:ResolveDuplicate.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of matched applicants
 */
const fetchMatchedApplicants = (state) => state.matchedApplicantsData;

/**
 * @memberOf module:ResolveDuplicate.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of new applicant
 */
const fetchNewApplicantData = (state) => state.newApplicantData;

/**
 * @memberOf module:ResolveDuplicate.store.getters
 * @param {Object} state holds store properties for current module
 * @description return status of confirmation of duplicates
 */
const confirmDuplicateStatus = (state) => state.confirmDuplicateStatus;

/**
 * @memberOf module:ResolveDuplicate
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  fetchMatchedApplicants,
  fetchNewApplicantData,
  confirmDuplicateStatus,
};
