import axios from "axios";

const prefix = "/error-logs"; // backend api

const fetchErrorLogs = () => {
  return axios.post(prefix);
};

const fetchErrorfile = (filename) => {
  return axios.get(prefix + "/" + filename);
};
const emailErrorFile = (data) => {
  return axios.post("/emailErrorFiles", data);
};
export default {
  fetchErrorLogs,
  fetchErrorfile,
  emailErrorFile,
};
