<template>
  <div v-if="newParams?.data">
    <button
      class="btn"
      :disabled="newParams.data.status == 'D'"
      style="
        width: 100%;
        font-size: 14px;
        padding: 0px;
        margin: 0px;
        color: red;
      "
      @click="deleteOpenPositions(params.data)"
    >
      <i class="fa fa-trash" aria-hidden="true"></i>
    </button>
  </div>
</template>
<script>
import Vue from "vue";
import Vuex from "vuex";
import Store from "../_store";

Vue.use(Vuex);

const store = new Vuex.Store({});

/**
 * @memberOf module:RoundSchedules
 * @namespace components.update_button
 * @description Update button component
 */
export default {
  methods: {
    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param positionData
     * @description Revert round of applicant
     */
    deleteOpenPositions(positionData) {
      this.$store.dispatch("$_manage_jobs/deleteJobPositions", positionData);
    },
  },
  computed: {
    newParams() {
      return this.params;
    },
  },
  created() {
    const STORE_KEY = "$_round_schedules";

    if (!(STORE_KEY in store._modules.root._children)) {
      store.registerModule(STORE_KEY, Store);
    }
  },
};
</script>
