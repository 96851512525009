<template>
  <div>
    <div ref="calendarParent">
      <FullCalendar
        id="calendar"
        name="calendar"
        ref="calendar"
        class="fullcalendar schedule-app-calendar fullcalendar-header-style-lg"
        style="height: 450px"
        :options="calendarOptions"
      >
        <template v-slot:eventContent="arg">
          <div
            class="w-100"
            style="overflow: hidden"
            @click="handleEventClick(arg)"
          >
            <div
              style="width: 100%"
              class="d-flex justify-content-between"
              :class="{
                'event-title-inactive':
                  arg.event.extendedProps.status != 'Pending' ||
                  !scheduleDateValid(
                    arg.event.extendedProps.round_schedule_end
                  ),
              }"
            >
              <div class="left-side-div">
                <span
                  class="event-circle span-circle"
                  :title="arg.event.extendedProps.round_name"
                  :class="{
                    'event-circle-inactive':
                      arg.event.extendedProps.status != 'Pending' ||
                      !scheduleDateValid(
                        arg.event.extendedProps.round_schedule_end
                      ),
                  }"
                >
                  {{ arg.event.extendedProps.round }}
                </span>
                <span
                  :title="arg.event.extendedProps.withUsers"
                  class="font-weight-bold"
                  style="cursor: pointer"
                >
                  {{ arg.event.title }}
                </span>
              </div>

              <div class="d-flex">
                <div v-if="arg.view.type == 'listMonth'">
                  {{ arg.event.extendedProps.interviewers }}
                </div>
                <div v-if="arg.view.type != 'listMonth'">
                  {{ extractTime(arg.event.extendedProps.round_schedule) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../_api/index";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import datePickerIcons from "../../Common/_utils/date-picker-icons";
import listPlugin from "@fullcalendar/list";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  props: [
    "selectSessionEvents",
    "selectedEventModal",
    "onHidden",
    "setEditEvent",
    "showEditEvent",
    "setEventModalData",
  ],
  data() {
    return {
      ovserver: null,
      date: null,
      config: {
        format: "DD MMMM YYYY",
        inline: true,
        // format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        sideBySide: false,
        // // minDate: moment(),
        // minDate: new Date("Thu Nov 11 2022 16:59:00 GMT+0530 (India Standard Time)"),
        // // maxDate: true,
        icons: datePickerIcons,
      },
      calendarOptions: {
        customButtons: {
          weekends: {
            text: "weekends",
            hint: "toggle weekends",
            click: () => {
              this.handleWeekendsToggle();
            },
          },
          viewAll: {
            text: "view all",
            hint: "toggle view all",
            click: () => {
              this.handleViewAllToggle();
            },
          },
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right:
            "weekends,viewAll dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        initialView: "dayGridMonth",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        contentHeight: 460,
        weekends: false,
        eventSources: [
          {
            events: this.fetchEvents,
          },
        ],
        lazyFetching: false,
      },
      pendingEvents: null,
      events: null,
    };
  },
  components: {
    FullCalendar,
  },
  computed: {
    ...mapGetters({
      selectedCampus: "$_campus_recruitment/getSelectedCampus",
    }),
  },

  methods: {
    handleViewAllToggle() {
      this.calendarOptions.dayMaxEvents = !this.calendarOptions.dayMaxEvents;
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description refresh calendar events
     */
    refetchEvents() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },

    clickToday() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.today();
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description format date to display
     */
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description extract date from datetime
     */
    extractDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description extract time from datetime
     */
    extractTime(date) {
      return moment(date).format("h:mm A");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description select date range
     */
    handleDateSelect() {},

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description handle click event and open edit event modal
     */
    handleEventClick(clickInfo) {
      this.setEditEvent(null);
      const foundEvent = this.events.find((event) => {
        return event.id == clickInfo.event.id;
      });
      this.setEditEvent(foundEvent);
      this.setEventModalData();
      this.showEditEvent();
    },
    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description fetch events for campus
     */
    fetchEvents(config, successCallback, failureCallback) {
      let filters = {
        startDate: config.start,
        endDate: config.end,
      };
      if (this.selectedCampus) {
        filters.campusId = this.selectedCampus.id;
        api
          .fetchRoundSchedules(filters)
          .then((response) => {
            let calendarApi = this.$refs.calendar.getApi();
            const events = response.data.map((event) => {
              let userNames = [];
              event.interviewers.forEach((user) => {
                if (user.interviewer_name) {
                  userNames.push(user.interviewer_name);
                }
              });
              const roundData = {
                ...event,
                title: event.round_title,
                start: event.round_schedule,
                end: event.round_schedule_end,
                backgroundColor: this.getEventColor(
                  event.status,
                  event.round_schedule_end
                ),
                borderColor: this.getEventColor(
                  event.status,
                  event.round_schedule_end
                ),
                round: this.getTitleAbbreviation(event.round_name),
                roundName: event.round_name,
                interviewers: userNames.join(", "),
                withUsers: this.getCampusWithUsers(
                  event.round_title,
                  event.round_name,
                  event.status,
                  event.campusDetail.recruitment_session_name,
                  userNames.join(", "),
                  event.round_schedule,
                  event.round_schedule_end
                ),
              };
              if (calendarApi && calendarApi.view.type != "dayGridMonth") {
                return {
                  ...roundData,
                  startRecur: moment(new Date(event.round_schedule)).format(
                    "YYYY-MM-DD"
                  ),
                  endRecur: moment(new Date(event.round_schedule_end))
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                  startTime: moment(new Date(event.round_schedule)).format(
                    "HH:mm"
                  ),
                  endTime: moment(new Date(event.round_schedule_end)).format(
                    "HH:mm"
                  ),
                  allDay: false,
                };
              } else {
                return {
                  ...roundData,
                  allDay: moment(event.round_schedule).isSame(
                    event.round_schedule_end,
                    "day"
                  ),
                };
              }
            });
            this.events = events;
            successCallback(events);
          })
          .catch(() => {
            failureCallback([]);
          });
      } else {
        successCallback([]);
      }
    },
    getCampusWithUsers(
      eventTitle,
      eventName,
      eventStatus,
      recruitmentName,
      interviewers,
      fromDate,
      toDate
    ) {
      let message = "";
      if (eventName === "Written Test") {
        message =
          "Title: " +
          eventTitle +
          " \nType: " +
          eventName +
          " \nRecruitmentName: " +
          recruitmentName +
          "\nScheduler/s: " +
          interviewers.toString() +
          " \nStatus: " +
          eventStatus;
      } else if (eventName === "Interview") {
        message =
          "Title: " +
          eventTitle +
          " \nType: " +
          eventName +
          " \nRecruitmentName: " +
          recruitmentName +
          "\nInterviewer/s: " +
          interviewers.toString() +
          " \nStatus: " +
          eventStatus;
      } else {
        message = "Status: " + eventStatus;
      }
      message =
        message +
        "\nfromTime: " +
        moment(fromDate).format("YYYY-MM-DD HH:mm:ss") +
        "\ntoTime: " +
        moment(toDate).format("YYYY-MM-DD HH:mm:ss");
      return message;
    },

    getEventColor(roundStatus, end_time) {
      let color = "line";
      if (roundStatus === "Cancelled") {
        color = "#FEE6AF";
      } else if (
        roundStatus === "Pending" &&
        end_time &&
        !this.scheduleDateValid(end_time)
      ) {
        color = "grey";
      } else if (roundStatus === "Pending") {
        color = "green";
      }
      return color;
    },

    filterEvents() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description get title abbreviation
     */
    getTitleAbbreviation(roundName) {
      let abbreviation = "I";
      if (roundName === "Review") {
        abbreviation = "R";
      } else if (roundName === "Aptitude") {
        abbreviation = "A";
      } else if (roundName === "Written Test") {
        abbreviation = "W";
      }
      return abbreviation;
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description get status abbreviation
     */
    getStatusAbbreviation(status) {
      let abbreviation = "";
      if (status === "Pending") {
        abbreviation = "PND";
      } else if (status === "Completed") {
        abbreviation = "CPD";
      } else if (status === "Cancelled") {
        abbreviation = "CND";
      }
      return abbreviation;
    },

    scheduleDateValid(date) {
      return moment(date).isAfter(new Date());
    },
    onResize() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.updateSize();
    },
  },

  mounted() {
    this.ovserver = new ResizeObserver(this.onResize);
    this.ovserver.observe(this.$refs.calendarParent);
  },
  beforeDestroy() {
    this.ovserver.unobserve(this.$refs.calendarParent);
  },
};
</script>

<style scoped>
.schedule-app-main {
  flex-grow: 1;
  padding-bottom: 10px;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  text-transform: capitalize !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}

.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 16px;
  width: 16px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 16px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.span-circle {
  min-width: 16px;
  min-height: 16px;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-div {
  width: calc(100% - 30px);
}
</style>

<style>
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em !important;
}

.fc .fc-header-toolbar button {
  max-height: 40px;
  padding: 5px;
}

.fc .fc-header-toolbar .fc-toolbar-title {
  font-size: 20px;
}

.fc {
  font-size: 14px;
}

.fc .fc-daygrid-day-number {
  padding: 1px !important;
  font-size: 12px;
  line-height: 14px;
}

.fc .fc-daygrid-day-bottom a {
  font-size: 14px;
}

.fc .fc-daygrid tr td {
  height: 30px !important;
  max-height: 20px !important;
  min-height: 20px !important;
}

.left-side-div {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
</style>
