<template>
  <div>
    <div class="d-flex active-purple active-purple-2">
      <div class="d-inline-block" style="width: 100%">
        <div class="input-group">
          <input
            style="padding-left: 5px"
            class="form-control border-0"
            @input="isTyping = true"
            v-model="searchQuery"
            tabindex="-1"
            type="search"
            :placeholder="searchPlaceholder"
            aria-label="Search"
            autocomplete="off"
          />
          <i
            class="fa fa-search"
            aria-hidden="true"
            style="padding-right: 5px; display: flex; align-items: center"
          ></i>
        </div>
      </div>
    </div>
    <div>
      <ul class="list-group" :class="listClass" :style="{ width: '100%' }">
        <li
          class="list-group-item message-text text-center text-secondary"
          v-if="
            isTyping &&
            !haveNoRecords &&
            searchResult.length === 0 &&
            searchQuery.length > 0 &&
            searchQuery.length < 3
          "
        >
          <span>Searching...(Type atleast 3 characters)</span>
        </li>
        <li
          class="list-group-item message-text text-center text-danger"
          v-if="!isLoading && haveNoRecords"
        >
          <span>No Records Found...</span>
        </li>

        <div v-if="isCampusRecruitment">
          <li
            class="list-group-item hover-effect d-flex flex-column flex-sm-row align-items-start"
            v-for="(item, i) in searchResult"
            :key="i"
          >
            <span
              class="flex-grow-1 mb-2 mb-sm-0"
              :title="'Email: ' + item.email + ', Mobile: ' + item.mobile_no"
              style="word-break: break-word"
            >
              {{
                (item.first_name + " " + item.last_name + ", " + item.position)
                  | truncate(45, "..")
              }}
            </span>
            <router-link
              class="ml-sm-2 mb-2 mb-sm-0 profile-link"
              title="Click here to open profile"
              target="_blank"
              :to="routerLink(item.slug)"
              style="flex-shrink: 0"
            >
              <i
                class="fa fa-user-circle-o"
                @click="clearSearchQuery()"
                aria-hidden="true"
              ></i>
            </router-link>
            <span
              class="ml-sm-2"
              :title="
                'Employment Status: ' +
                item.employment_status +
                ', Application Status: ' +
                item.application_status
              "
              style="flex-shrink: 0; word-break: break-word"
            >
              {{ item.application_status | truncate(13, "..") }}
            </span>
          </li>
        </div>
        <div v-else-if="fromDashboard">
          <li
            class="list-group-item hover-effect d-flex justify-content-between align-items-start px-3 pt-2 py-1"
            v-for="(item, i) in searchResult"
            :key="i"
            @click="isEmit ? returnSearchQuery(item) : null"
          >
            <div class="text-left">
              <h6>
                <router-link
                  v-if="!isEmit"
                  class="profile-link mr-2"
                  title="Click here to open profile"
                  target="_blank"
                  :to="routerLink(item.slug)"
                  ><i
                    class="fa fa-user-circle-o"
                    @click="clearSearchQuery()"
                    aria-hidden="true"
                  ></i>
                </router-link>
                <span
                  :title="
                    'Email: ' + item.email + ', Mobile: ' + item.mobile_no
                  "
                  >{{
                    (item.first_name + " " + item.last_name)
                      | truncate(isEmit ? 60 : 45, "..")
                  }}
                </span>
                <p class="mb-1 mx-4" style="font-size: 14px">
                  {{ item.position | truncate(isEmit ? 60 : 45, "..") }}
                </p>
              </h6>
            </div>
            <div class="text-right mt-2">
              <span
                class=""
                :title="
                  'Employment Status: ' +
                  item.employment_status +
                  ', Application Status: ' +
                  item.application_status
                "
                >{{
                  item.application_status | truncate(isEmit ? 50 : 13, "..")
                }}</span
              >
            </div>
          </li>
        </div>
        <div v-else>
          <li
            class="list-group-item hover-effect"
            v-for="(item, i) in searchResult"
            :key="i"
            @click="isEmit ? returnSearchQuery(item) : null"
          >
            <span
              class="col-9"
              :title="'Email: ' + item.email + ', Mobile: ' + item.mobile_no"
              >{{
                (item.first_name + " " + item.last_name + ", " + item.position)
                  | truncate(isEmit ? 60 : 45, "..")
              }}</span
            >
            <router-link
              v-if="!isEmit"
              class="col-1 float-right profile-link"
              title="Click here to open profile"
              target="_blank"
              :to="routerLink(item.slug)"
              ><i
                class="fa fa-user-circle-o"
                @click="clearSearchQuery()"
                aria-hidden="true"
              ></i>
            </router-link>
            <span
              class="col-3 float-right"
              :title="
                'Employment Status: ' +
                item.employment_status +
                ', Application Status: ' +
                item.application_status
              "
              >{{
                item.application_status | truncate(isEmit ? 50 : 13, "..")
              }}</span
            >
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    apiPromise: {
      type: Function,
      required: true,
    },
    listClass: {
      type: String,
      default: "",
    },
    routerLink: {
      type: Function || null,
      required: false,
    },
    color: {
      type: String,
      default: "#803059",
    },
    isCampusRecruitment: {
      type: Boolean,
      default: false,
    },
    isEmit: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: "Search applicant by name, email, mobile, position, status etc.",
    },
    fromDashboard: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    init() {},
    searchUser: function (searchQuery) {
      this.isLoading = true;
      if (searchQuery.trim().length >= 3) {
        this.apiPromise(this.searchQuery)
          .then((response) => {
            this.isLoading = false;
            this.searchResult = response.data;
            this.haveNoRecords = false;
            if (response.data.length === 0) {
              this.haveNoRecords = true;
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.searchResult = [];
            this.haveNoRecords = true;
          });
      } else {
        this.isLoading = false;
        this.haveNoRecords = false;
        this.searchResult = [];
      }
    },
    clearSearchQuery: function () {
      this.searchQuery = "";
      this.isLoading = false;
      this.haveNoRecords = false;
      this.searchResult = [];
    },
    returnSearchQuery: function (applicant) {
      this.$emit("candidateSelected", applicant);
      this.clearSearchQuery();
    },
  },
  data() {
    return {
      searchQuery: "",
      isTyping: false,
      searchResult: [],
      isLoading: false,
      haveNoRecords: false,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    searchQuery: debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value) {
        this.searchUser(this.searchQuery);
      }
    },
  },
};

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
</script>

<style scoped>
.active-purple-2 .input-group:focus-within {
  border-bottom: 1px solid #8f1452;
  box-shadow: 0 1px 0 0 #8f1452;
}

.active-purple .input-group {
  border-bottom: 1px solid #ced4da;
  box-shadow: 0 1px 0 0 #ced4da;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}

.form-control {
  border-radius: 0;
}

.fa-search {
  margin-top: 10px;
}

.active-purple .fa,
.active-purple-2 .fa {
  color: #8f1452;
}

.list-group {
  margin-top: 5px;
  position: absolute;
  background: white;
  box-shadow: 0 1px 0 0 #ced4da;
  z-index: 2;
  overflow-x: auto;
  max-height: 500px;
  left: 0;
}

.message-text {
  margin-top: 5px;
  background: white;
  z-index: 1;
  width: 100%;
}

.hover-effect:hover {
  background-color: v-bind(color);
  color: white;
}

.list-group-item .fa-user-circle-o {
  color: v-bind(color);
}

.list-group-item:hover .fa-user-circle-o {
  color: white;
}
</style>
