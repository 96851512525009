import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  reviewCategoriesWithReviews: [],
  reviewCategoryDataForAddReviewModal: null,
  itemDeleteData: null,
  reviewDataForEditReviewModal: null,
  reviewCategoryDataForEditReviewCategoryModal: null,
  isEdit: false,
};

/**
 * @memberOf module:ManageReview
 * @namespace store
 * @description central store for manage review module
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
