<template>
  <div class="container p-0 m-0">
    <div class="menubar nopadding d-flex flex-column" id="wrapper">
      <div style="flex-grow: 1">
        <div class="profile-wrapper">
          <div class="profile-overlay">
            <div class="profile-container">
              <img class="profile" :src="profileImage" alt="" />

              <h4 class="username">{{ loggedInUser?.name }}</h4>
            </div>
          </div>
        </div>
        <ul class="nopadding">
          <li v-for="parent in data" :key="parent.key" class="dropdown">
            <safe-link
              className="sidebtn rotate"
              aria-expanded="true"
              data-toggle="collapse"
              :link="'#' + parent.name"
            >
              <i :class="parent.iconClass" aria-hidden="true"></i>
              {{ parent.key }}
              <i
                class="fa fa-chevron-down fa-2xs chevron-icon rotate"
                aria-hidden="true"
              ></i>
            </safe-link>
            <ul class="dropdown-menu sidemenu" :id="parent.name">
              <li
                class="submenu"
                v-for="element in parent.options"
                :key="element.key"
              >
                <router-link :to="{ name: element.name }">{{
                  element.key
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="text-white" @click="$openSearchbar()">
        <button
          class="btn pl-2 py-2 text-white w-100 border"
          style="background-color: #803059"
        >
          Search - ( CTRL + K )
        </button>
      </div>
    </div>
    <!--side menu end-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userProfile from "../../../assets/images/avatar.png";
/**
 * @memberof module:Common
 * @namespace sidebar-section
 */
export default {
  data: function () {
    return {
      menu: {
        MANAGE: false,
        MAIL: false,
        REPORT: false,
        "CAMPUS-DRIVE": false,
      },
      avatar: userProfile,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
      employeeCommonHeader: "user/commonHeader",
      profilePic: "user/fetchProfilePic",
    }),
    data() {
      const options = this.employeeCommonHeader.options.home.options;
      const filterCriteria = (key, value) => {
        return value?.permission && value?.showInSidebar;
      };

      const filtered = Object.keys(options)
        .filter((key) => filterCriteria(key, options[key]))
        .reduce((result, key) => {
          // Filter the inner options based on permission
          const innerOptions = options[key].options;
          const filteredInnerOptions = innerOptions
            ? Object.keys(innerOptions)
                .filter((innerKey) =>
                  filterCriteria(innerKey, innerOptions[innerKey])
                )
                .reduce((innerResult, innerKey) => {
                  innerResult[innerKey] = innerOptions[innerKey];
                  return innerResult;
                }, {})
            : {};

          result[key] = {
            ...options[key],
            options: filteredInnerOptions,
          };
          return result;
        }, {});

      return filtered;
    },
    profileImage() {
      try {
        if (this.profilePic) {
          return `data:image/jpeg;base64,${this.profilePic}`;
        } else {
          return this.avatar;
        }
      } catch (error) {
        console.error("Error processing profile image in sidebar:", error);
        return this.avatar; // Default avatar in case of error
      }
    },
  },
  beforeMount() {
    $(".submenu").click((e) => {
      e.stopPropagation();
    });

    $(".rotate").click(function () {
      $(this).find(".chevron-icon").toggleClass("down");
    });

    if (!this.profilePic && this.loggedInUser) {
      this.$store.dispatch("user/fetchProfilePic", this.loggedInUser.user_id);
    }
  },
};
</script>
<style scoped>
#wrapper {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  transition: all 0.4s ease 0s;
}
#wrapper::-webkit-scrollbar {
  width: 5px;
}
#wrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}
#wrapper::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 10px;
}
#wrapper.active {
  left: 0px;
}
.menubar {
  top: 65px;
  bottom: 0px;
  width: 250px;
  position: fixed;
  left: -250px;
  z-index: 999;
  background: #832e5b;
  /* Old browsers */
  background: -moz-linear-gradient(top, #832e5b 1%, #b65386 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #832e5b 1%, #b65386 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #832e5b 1%, #b65386 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.menubar li {
  list-style-type: none;
  border-bottom: 1px solid #b2628b;
  transition: 0.5s;
}
.menubar li:hover {
  background-color: #803059;
}
.profile {
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 4px solid #e7e7e7;
}
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.profile-wrapper {
  background-image: url(../../../assets/images/user-bg.jpg);
  background-size: cover;
  height: 190px;
  background-repeat: no-repeat;
  position: relative;
}
.profile-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.username {
  color: white;
  text-align: center;
  margin-top: 10px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  overflow: hidden;
  width: 245px;
}
.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}
.dropdown-menu > li > a {
  display: block;
  padding: 3px 0px 3px 30px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
  background-color: #803059;
  color: white;
}
li.dropdown > a {
  color: white;
}
.fa {
  margin-right: 5px;
}
.submenu a {
  line-height: 2 !important;
  font-size: 16px;
  color: white !important;
}
.submenu:hover a {
  background-color: #803059;
  color: white;
}
.sidemenu {
  background-color: #903564;
  width: 250px;
  position: relative;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  margin: 0;
  padding: 0;
}
.router-link-active.router-link-exact-active {
  background: #fff !important;
  color: #903564 !important;
}
.sidebtn {
  transition: 0.5s;
  width: 250px;
  font-size: 17px;
  display: inline-block;
  padding: 10px;
  color: white;
}
.sidebtn:hover {
  background-color: #903564 !important;
  color: white;
  text-decoration: none;
}
.btn .caret {
  margin-left: 7px;
}

.rotate {
  -moz-transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  transition: 0.2s linear;
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.chevron-icon {
  position: absolute;
  transition: transform 0.3s ease;
  right: 8px;
  margin-top: 11px;
  transform: translateY(-50%);
  font-weight: 100;
  font-size: 12px;
}
</style>
