<template>
  <div class="content">
    <common-header :data="['home', 'manage', 'cronJob']"> </common-header>
    <div class="container-fluid">
      <div
        v-for="(cron, index) in crons"
        :key="index"
        class="shadow-sm p-2 rounded-2 hover"
      >
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div class="flex-grow-1 cursor-pointer">
            <h5 class="mb-0 font-monospace lh-base">
              {{ cron.key }}
            </h5>
            <p class="mb-0 mt-2">{{ cron.options?.description || "-" }}</p>
          </div>

          <div class="position-relative">
            <div
              class="d-flex flex-column"
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            >
              <button
                class="ml-auto btn btn-toggle custom-toggle"
                :class="{ active: cron.status }"
                @click="toggleBoolean(cron)"
              ></button>
            </div>
            <i>{{ cron.options?.range || "-" }}</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PERMISSIONS from "@/modules/Common/permissions";
import api from "@/modules/CronJob/_api/index";
import { hasAccess } from "@/modules/User/_utils/actions";

export default {
  name: "cron",
  data() {
    return {
      crons: null,
    };
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    toggleBoolean(cronData) {
      api
        .updateCron({ key: cronData.key, status: !cronData.status })
        .then(() => {
          this.fetchCrons();
        })
        .catch((err) => {
          console.log("errrr", err);
        });
    },
    fetchCrons() {
      api
        .fetchCrons()
        .then((res) => {
          this.crons = res.data;
        })
        .catch((err) => {
          console.log("errors", err);
        });
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CONFIGURATION"];
    },
  },
  beforeMount() {
    this.fetchCrons();
  },
};
</script>

<style scoped>
.hover:hover {
  background: rgba(138, 233, 250, 0.2);
  transition: all ease-out 200ms;
}
</style>
