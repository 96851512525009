const fetchProcessedApplicantsData = (state) => state.processedApplicantsData;
const fetchHoldApplicantsData = (state) => state.holdApplicantsData;
const fetchOngoingApplicantsData = (state) => state.ongoingApplicantsData;
const fetchDashboardStatsData = (state) => state.dashboardStatsData;
const fetchHiringPipelineStatsData = (state) => state.hiringPipelineData;
const fetchApplicantsData = (state) => state.applicantsData;

export default {
  fetchProcessedApplicantsData,
  fetchHoldApplicantsData,
  fetchOngoingApplicantsData,
  fetchDashboardStatsData,
  fetchHiringPipelineStatsData,
  fetchApplicantsData,
};
