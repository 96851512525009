import Vue from "vue";

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} campusRecruitment hold new campus recruitment data
 * @description Set new campus recruitment detail locally
 */
const ADD_CAMPUS_RECRUITMENT = (state, campusRecruitment) => {
  state.campusRecruitmentData.push(campusRecruitment);
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} campusRecruitmentURL hold new campus recruitment drive URL
 * @description Set new campus recruitment drive url
 */
const SET_CAMPUS_RECRUITMENT_URL = (state, campusRecruitmentUrl) => {
  state.campusRecruitmentURL = campusRecruitmentUrl;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {String} errorMessages hold error message
 * @description Set error message
 */
const ADD_ERROR_MESSAGES = (state, errorMessages) => {
  state.errorMessages = errorMessages;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} entity hold new  entity details
 * @description Set new entity details locally
 */
const ADD_ENTITY = (state, entity) => {
  state.entities.push(entity);
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} entityData hold updated entity data
 * @description Update entity details
 */
const UPDATE_ENTITY = (state, entityData) => {
  const entityIndex = state.entities.findIndex(
    (entity) => entity.id === entityData.id
  );
  state.entities[entityIndex].entity_type = entityData.entity_type;
  state.entities[entityIndex].entity_name = entityData.entity_name;
  state.entities[entityIndex].contactPersonDetails = entityData.contactList;
  state.entities[entityIndex].city = entityData.city;
  state.entities[entityIndex].website = entityData.website;
  state.entities[entityIndex].state = entityData.state;
  state.entities[entityIndex].pincode = entityData.pincode;
  state.entities[entityIndex].courseDetails = entityData.courseList;
  state.entities[entityIndex].parent_entity = entityData.parent_entity;
  state.entities[entityIndex].status = entityData.status;
  const newElement = state.entities[entityIndex];
  Vue.set(state.entities, entityIndex, newElement);
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Array} data hole all entity details
 * @description Set all entity details locally
 */
const FETCH_ENTITIES = (state, data) => {
  state.entities = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Array} data hold all campus recruitment data
 * @description Set all campus recruitment details locally
 */
const FETCH_CAMPUS_RECRUITMENTS = (state, data) => {
  state.campusRecruitmentData = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} entityData hold old data of entity for updation
 * @description Update entity data
 */
const SET_UPADATE_ENTITY_MODAL_DATA = (state, entityData) => {
  state.entityModalData = entityData;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {number} deletedEntityId  hold id of entity for deletion
 * @description Set block or active entity id
 */
const SET_DELETE_ENTITY_ID = (state, data) => {
  state.deletedEntityData = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {number} campusRecruitmentId  hold id of campus recruitment for update status
 * @description Set campus recruitment id
 */
const SET_CAMPUS_RECRUITMENT_ID = (state, campusRecruitmentId) => {
  state.campusRecruitmentId = campusRecruitmentId;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {number} deleteEntityId hold id of entity for block entity
 * @description Block the entity which id equal to deleteEntityId
 */
const DELETE_ENTITY = (state, data) => {
  const entityIndex = state.entities.findIndex(
    (entity) => entity.id === data.id
  );
  const newElement = state.entities[entityIndex];
  if (newElement.status === "active") {
    newElement.status = "block";
  } else {
    newElement.status = "active";
  }
  Vue.set(state.entities, entityIndex, newElement);
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {number} id holds id for update status
 * @description Update campus recruitment status
 */
const UPDATE_CAMPUS_STATUS = (state, id) => {
  const campusIndex = state.campusRecruitmentData.findIndex(
    (campusData) => campusData.id === id
  );
  const newElement = state.campusRecruitmentData[campusIndex];
  newElement.status = "Completed";
  Vue.set(state.campusRecruitmentData, campusIndex, newElement);
};

const UPDATE_CAMPUS_RECRUITMENT_LAST_DATE = (state, data) => {
  const updatedCampusRecruitment = state.campusRecruitmentData.map((campus) => {
    if (campus.id == data.id) {
      return { ...campus, last_date: data.last_date };
    } else {
      return campus;
    }
  });
  state.campusRecruitmentData = updatedCampusRecruitment;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {number} data count od registered applicant
 * @description update registred applicant count
 */
const SET_REGISTERED_COUNT = (state, data) => {
  state.registeredApplicantCount = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {number} data selected entity
 * @description set selected entity
 */
const SET_SELECTED_ENTITY = (state, data) => {
  state.selectedEntity = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {number} data selected entity sessions
 * @description set selected entity sessions
 */
const SET_SELECTED_ENTITY_SESSIONS = (state, data) => {
  state.selectedEntitySessions = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @description set active campuses
 */
const SET_ACTIVE_CAMPUSES = (state, data) => {
  state.activeCampuses = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @description set selected campuse
 */
const SET_SELECTED_CAMPUS = (state, data) => {
  state.selectedCampus = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @description set event data
 */
const SET_EVENT_DATA = (state, data) => {
  state.eventData = data;
};

/**
 * @memberof module:campusRecruitment.store.mutations
 * @param {Object} state holds store properties for current module
 * @description set event selected applicants
 */
const EVENT_APPLICANTS_SELECTED_ROWS = (state, data) => {
  state.eventApplicantsSelectedRows = data;
};

/**
 * @memberof module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set application status and their count in store
 */
const FETCH_APPLICATION_STATUS_COUNT = (state, data) => {
  state.applicationStatusCount = data;
};

/**
 * @memberof module:campusRecruitment
 * @namespace store.mutations
 * @description mutations for mailbox module
 */
export default {
  ADD_CAMPUS_RECRUITMENT,
  FETCH_ENTITIES,
  FETCH_CAMPUS_RECRUITMENTS,
  SET_UPADATE_ENTITY_MODAL_DATA,
  SET_DELETE_ENTITY_ID,
  DELETE_ENTITY,
  UPDATE_ENTITY,
  ADD_ENTITY,
  ADD_ERROR_MESSAGES,
  SET_CAMPUS_RECRUITMENT_ID,
  UPDATE_CAMPUS_STATUS,
  SET_CAMPUS_RECRUITMENT_URL,
  SET_REGISTERED_COUNT,
  SET_SELECTED_ENTITY,
  SET_SELECTED_ENTITY_SESSIONS,
  SET_ACTIVE_CAMPUSES,
  SET_SELECTED_CAMPUS,
  SET_EVENT_DATA,
  EVENT_APPLICANTS_SELECTED_ROWS,
  FETCH_APPLICATION_STATUS_COUNT,
  UPDATE_CAMPUS_RECRUITMENT_LAST_DATE,
};
