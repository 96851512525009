var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"well section-head w-auto"},[_c('div',{staticClass:"row mx-0"},[_vm._m(0),_c('div',{staticClass:"col-7 px-0"},[(
            _vm.hasAccess({
              permissions: [_vm.PERMISSIONS.APPLICANT.FOLLOWUP.CREATE],
            })
          )?_c('button',{staticClass:"btn btn-cp float-right",attrs:{"type":"button","data-toggle":"modal","data-target":"#addFollowUp"},on:{"click":function($event){return _vm.$refs.followUpModal.resetFollowUp()}}},[_c('i',{staticClass:"fa fa-plus fa-lg",attrs:{"aria-hidden":"true"}}),_c('strong',[_vm._v("New Follow Up")])]):_vm._e()])])]),_c('div',{staticClass:"p-0 mx-3 mt-2"},[(_vm.followUps.length > 0)?_c('div',{staticClass:"well container-followup mt-0"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-condensed",attrs:{"aria-describedby":"followupStatus"}},[_vm._m(1),_c('tbody',_vm._l((_vm.followUps),function(followUp,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(followUp.createdAt))]),_c('td',[_vm._v(_vm._s(followUp.status))]),_c('td',[_vm._v(_vm._s(followUp.remarks))]),_c('td',[_vm._v(_vm._s(followUp.schedule))]),_c('td',[_vm._v(" "+_vm._s(followUp.active_status === 0 ? "Pending" : "Completed")+" ")])])}),0)])])]):_vm._e()]),_c('add-follow-up-modal',{ref:"followUpModal",attrs:{"applicantData":_vm.applicantInfoData}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-5 px-0"},[_c('h4',[_c('strong',[_vm._v("Follow Up")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('th',[_vm._v("Created Date")]),_c('th',[_vm._v("Followup Status")]),_c('th',[_vm._v("Remarks")]),_c('th',[_vm._v("Next Scheduled FollowUp")]),_c('th',[_vm._v("Status")])])
}]

export { render, staticRenderFns }