<template>
  <div
    class="modal fade"
    id="slotSelectTemplate"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabelSelectTemplate"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabelSelectTemplate">
            Select Template
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div
              class="form-group"
              v-if="
                eventData.EventDetails.round_name == 'Interview' && pageLoaded
              "
            >
              <label class="col-sm-3 control-label">Template</label>
              <div class="col-sm-9">
                <select
                  v-if="
                    eventData.EventDetails.round_name == 'Interview' &&
                    pageLoaded
                  "
                  id="slotTemplatepicker"
                  v-model="form_id"
                  class="selectpicker"
                  data-live-search="true"
                  data-width="100%"
                  multiple
                  data-max-options="1"
                >
                  <option
                    v-for="(template, index) in activeTemplates"
                    :key="index"
                    :value="template.id"
                  >
                    {{ template.title }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="form-group"
              v-if="
                eventData.EventDetails.round_name == 'Interview' && pageLoaded
              "
            >
              <label class="col-12 control-label"
                >Google Meet Invitations</label
              >
              <div class="col-sm-9">
                <label class="d-flex">
                  <input
                    type="radio"
                    value="common"
                    class="mr-1"
                    v-model="sendInvitation"
                  />
                  Send common invitation
                </label>
                <label class="d-flex">
                  <input
                    type="radio"
                    value="individual"
                    class="mr-1"
                    v-model="sendInvitation"
                  />
                  Send individual invitations
                </label>
                <label class="d-flex align-items-center">
                  <input
                    type="radio"
                    value=""
                    class="mr-1"
                    v-model="sendInvitation"
                  />
                  None(mail only)
                </label>
              </div>
            </div>
            <div
              class="form-group"
              v-if="
                eventData.EventDetails.round_name == 'Interview' &&
                pageLoaded &&
                !sendInvitation
              "
            >
              <label class="col-12 control-label">Select Mail Template</label>
              <div class="col-sm-9">
                <select
                  class="custom-select main college mr-2"
                  v-model="mail_template"
                >
                  <option disabled value="">Select Mail Template</option>
                  <option
                    v-for="(mailTemplate, index) in filteredMailTemplates"
                    :value="mailTemplate.id"
                    :key="index"
                  >
                    {{ mailTemplate.name }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark btn-sm"
            data-dismiss="modal"
            @click="$emit('canceled')"
          >
            <strong>Cancel</strong>
          </button>
          <button
            :disabled="!mail_template && !sendInvitation"
            type="button"
            class="btn btn-cp ml-2"
            @click="submit"
          >
            <i class="fa fa-book fa-lg" aria-hidden="true"></i>
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  data() {
    return {
      form_id: [],
      formTemplateChecker: false,
      pageLoaded: false,
      mail_template: null,
      sendInvitation: "",
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      eventData: "$_campus_recruitment/getEventData",
      formTemplates: "$_form_template/getForms",
      mailTemplates: "$_mailbox/getTemplates",
    }),
    filteredMailTemplates() {
      if (this.mailTemplates) {
        return this.mailTemplates.filter((mail) => {
          return mail.key == "Schedule Round";
        });
      } else {
        return [];
      }
    },
    activeTemplates() {
      return this.formTemplates.filter((template) => template.status === "A");
    },
  },

  watch: {},
  methods: {
    getAllMailTemplates() {
      this.$store.dispatch("$_mailbox/getTemplates");
    },
    renderTemplateForm() {
      this.formTemplateChecker = true;
    },
    submit() {
      this.$emit("setSelctedMailTemplate", this.mail_template);
      this.$emit("selected", null, null, this.form_id, this.sendInvitation);
      $("#slotSelectTemplate").modal("hide");
    },
  },
  updated() {
    if (this.formTemplateChecker) {
      let formpicker = document.getElementById("slotTemplatepicker");
      if (formpicker != null) {
        $("#slotTemplatepicker")
          .attr("title", "select template")
          .selectpicker("render");
      }
      this.formTemplateChecker = false;
    }
  },
  mounted() {
    this.$store.dispatch("$_form_template/getAllFormMasters");
    $("#slotSelectTemplate").on("shown.bs.modal", () => {
      this.pageLoaded = true;
      this.formTemplateChecker = true;
      this.mail_template = null;
      this.getAllMailTemplates();
    });
    $("#slotSelectTemplate").on("hidden.bs.modal", () => {
      this.pageLoaded = false;
      this.formTemplateChecker = false;
    });
  },
  beforeDestroy() {
    $("#slotSelectTemplate").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.custom-btn-gray {
  height: 35px;
  display: flex;
  align-items: center;
}

/* .modal {
    overflow-y: auto;
  } */
</style>
