<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row mx-0 p-0">
        <div
          :class="{
            'col-12 col-lg-3 px-0 pr-md-2': rightSideComponents.length,
            'col-12 px-0': !rightSideComponents.length,
          }"
        >
          <component
            v-for="(comp, index) in leftSideComponents"
            :key="`left-${index}`"
            :is="comp.name"
            :leftLength="leftSideComponents.length"
            :rightLength="rightSideComponents.length"
          ></component>
        </div>

        <div
          :class="{
            'col-12 col-lg-9 px-0 pl-md-2': leftSideComponents.length,
            'col-12 px-0': !leftSideComponents.length,
          }"
        >
          <component
            v-for="(comp, index) in rightSideComponents"
            :key="`right-${index}`"
            :is="comp.name"
            :leftLength="leftSideComponents.length"
            :rightLength="rightSideComponents.length"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FollowupCard from "./followup-card.vue";
import CampusRecruitmentCard from "./campus-recruitment-card.vue";
import HiringTarget from "./hiring-targets.vue";
import ApplicantStats from "./applicant-stats.vue";
import EventCalendar from "./event-calendar.vue";
import HiringPipeline from "./hiring-pipeline.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  components: {
    FollowupCard,
    CampusRecruitmentCard,
    HiringTarget,
    ApplicantStats,
    EventCalendar,
    HiringPipeline,
  },
  data() {
    return {
      components: [
        {
          name: "ApplicantStats",
          visible: this.hasAccess({
            permissions: [
              PERMISSIONS.APPLICANT.READ,
              PERMISSIONS.APPLICANT["APPLICANT-SEARCH"],
            ],
            checkAll: true,
          }),
        },
        {
          name: "FollowupCard",
          visible: this.hasAccess({
            permissions: [
              PERMISSIONS.APPLICANT.READ,
              PERMISSIONS.APPLICANT["FOLLOW-UP"],
            ],
            checkAll: true,
          }),
        },
        {
          name: "CampusRecruitmentCard",
          visible: this.hasAccess({
            permissions: [PERMISSIONS.REPORT.SESSION.MENU],
          }),
        },
        {
          name: "HiringTarget",
          visible: this.hasAccess({
            permissions: [
              PERMISSIONS.APPLICANT.READ,
              PERMISSIONS.APPLICANT["APPLICANT-TRACKER"],
            ],
            checkAll: true,
          }),
        },
        {
          name: "EventCalendar",
          visible: true,
        },
        {
          name: "HiringPipeline",
          visible: this.hasAccess({
            permissions: [
              PERMISSIONS.APPLICANT.READ,
              PERMISSIONS.APPLICANT["APPLICANT-TRACKER"],
            ],
            checkAll: true,
          }),
        },
      ],
      order: window.innerWidth < 992 ? [0, 4, 1, 2, 3, 5] : [0, 1, 2, 3, 4, 5],
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.MAIL;
    },
    filteredComponents() {
      return this.order.map((index) => this.components[index]);
      // .filter((comp) => comp.visible);
    },
    leftSideComponents() {
      return this.filteredComponents.slice(0, 4).filter((comp) => comp.visible);
    },
    rightSideComponents() {
      return this.filteredComponents.slice(4, 6).filter((comp) => comp.visible);
    },
  },
  mounted() {
    window.addEventListener("resize", this.adjustLayoutForScreenSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustLayoutForScreenSize);
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    adjustLayoutForScreenSize() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 992) {
        this.order = [0, 4, 1, 2, 3, 5];
      } else {
        this.order = [0, 1, 2, 3, 4, 5];
      }
    },
  },
};
</script>
