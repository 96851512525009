<template>
  <div class="content">
    <common-header
      v-if="currentMode === 'editEntity'"
      :data="[
        'home',
        'campusDrive',
        'entityDetails',
        { key: 'viewEntityDetails', value: data.entity_name },
        currentMode,
      ]"
      :componentName="'Update'"
    />
    <common-header
      v-if="currentMode === !'editEntity'"
      :data="['home', 'campusDrive', 'entityDetails', currentMode]"
      :componentName="entityData.entity_name"
    />
    <common-header
      v-if="currentMode === !'editEntity'"
      :data="['home', 'campusDrive', 'entityDetails', currentMode]"
      :componentName="'Add Entity'"
    />

    <div class="container container-wrapper">
      <h1 class="form_header">
        {{ currentMode === "addEntity" ? "Add Entity" : "Edit Entity" }}
      </h1>

      <form class="content-form p-0">
        <div class="row mx-0">
          <div class="form-group col-sm-6 col-12 px-0 px-sm-2">
            <label class="control-label" htmlFor="entityType"
              >Entity Type
              <span class="text-danger">*</span>
            </label>

            <select
              class="form-control"
              id="sel1"
              v-model="data.entity_type"
              @change="selectEntityType()"
            >
              <option selected value="College">College</option>
              <option value="University">University</option>
              <option value="Recruiter">Recruiter</option>
            </select>
          </div>
          <div class="form-group col-sm-6 col-12 px-0 px-sm-2">
            <label class="control-label"
              >Entity Name
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              name="entityName"
              key="entityName"
              class="form-control"
              v-validate.attach="'required|max:500|alpha_spaces'"
              data-vv-validate-on="blur|input"
              v-model="data.entity_name"
              :class="{ invalid: errors.first('entityName') }"
            />
            <span
              v-if="errors.first('entityName')"
              class="valid-feedback alert-danger"
              >{{ errors.first("entityName") }}</span
            >
          </div>
        </div>
        <div class="row mx-0">
          <div
            class="form-group col-sm-6 col-12 px-0 px-sm-2"
            id="parent"
            v-if="!isUniversity"
          >
            <label class="control-label" htmlFor="parentEntity"
              >Entity Parent
            </label>

            <select
              id="entitypicker"
              title="select parent entity for this college"
              data-live-search="true"
              data-width="100%"
              v-model="data.parent_entity"
            >
              <option
                v-for="(university, index) in universities"
                :key="index"
                :value="university.id"
              >
                {{ university.name }}
              </option>
            </select>
          </div>
          <div class="form-group col-sm-6 col-12 px-0 px-sm-2">
            <label class="control-label" htmlFor="website">Website </label>
            <input
              name="website"
              key="website"
              type="text"
              class="form-control"
              v-model="data.website"
              v-validate.attach="{ url: { require_protocol: true } }"
              :class="{ invalid: errors.first('website') }"
            />
            <span
              v-if="errors.first('website')"
              class="valid-feedback alert-danger"
              >{{ errors.first("website") }}</span
            >
          </div>
        </div>
        <div class="row mx-0">
          <div class="form-group col-sm-6 col-12 px-0 px-sm-2">
            <label class="control-label" htmlFor="city">City </label>

            <span class="text-danger">*</span>
            <input
              type="text"
              class="form-control"
              name="city"
              key="city"
              v-validate.attach="'required|max:100|alpha_spaces'"
              data-vv-validate-on="blur|input"
              v-model="data.city"
              :class="{ invalid: errors.first('city') }"
            />
            <span
              v-if="errors.first('city')"
              class="valid-feedback alert-danger"
              >{{ errors.first("city") }}</span
            >
          </div>
          <div class="form-group col-sm-6 col-12 px-0 px-sm-2">
            <label class="control-label" htmlFor="state">State </label>
            <span class="text-danger">*</span>
            <select class="form-control" v-model="data.state">
              <option v-for="(state, index) in listStates" :key="index">
                {{ state }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mx-0">
          <div class="form-group col-sm-6 col-12 px-0 px-sm-2">
            <label class="control-label" htmlFor="pinCode">Pincode </label>
            <span class="text-danger">*</span>
            <input
              type="number"
              class="form-control"
              name="pincode"
              key="pincode"
              v-model="data.pincode"
              v-validate.attach="'required|numeric|regex:^\\d{6}$'"
              data-vv-validate-on="blur|input"
              :class="{ invalid: errors.first('pincode') }"
            />
            <span
              v-if="errors.first('pincode')"
              class="valid-feedback alert-danger"
              >Pincode must be 6-digit integer</span
            >
          </div>
          <div class="form-group col-sm-6 col-12 px-0 px-sm-2">
            <label class="control-label" htmlFor="ratings">Ratings</label>
            <div>
              <span
                class="fa fa-star fa-lg mx-1"
                v-b-tooltip.hover
                :title="rating.value"
                :class="{ 'star-checked': rating.rating <= data.ratings }"
                @click="setRating(rating.rating)"
                v-for="(rating, index) in ratingsArray"
                :key="index"
              ></span>
            </div>
          </div>
        </div>
        <div class="form-group px-sm-2">
          <label for="formremarks" class="control-label">Remarks</label>
          <common-rich-text-box
            ref="richTextEditor"
            class="entity-rich-text-box"
            :width="'100%'"
            :height="'100px'"
            placeholder="Remark..."
            name="remarks"
            modalId="edit-entity-text-box"
            :value="data.remarks"
            @onKeyUp="onKeyUp"
            @onBlur="onBlur"
          />
        </div>
        <div class="form-group px-sm-2">
          <label class="control-label m-r10" htmlFor="status"
            >Status
            <span class="text-danger">*</span>
          </label>
          <label class="m-r10">
            <input
              class="m-r10"
              type="radio"
              id="active"
              value="active"
              v-model="data.status"
            />Active</label
          >
          <label class="m-r10">
            <input
              class="m-r10"
              type="radio"
              id="block"
              value="blocked"
              v-model="data.status"
            />Inactive</label
          >
        </div>
        <div class="form-group mt-2">
          <div class="d-flex justify-content-between px-sm-2">
            <label class="control-label" htmlFor="contactPerson"
              >Contact Persons
              <span class="text-danger">*</span>
            </label>
            <div class="panel-heading text-right mb-2">
              <button
                :disabled="showContactForm"
                type="button"
                class="btn btn-cp"
                @click="openContactForm"
                data-toggle="tooltip"
                data-placement="top"
                title="Add Contact"
              >
                <em class="fa fa-plus"></em> Add Contact
              </button>
            </div>
          </div>

          <div class="row mx-0">
            <div class="col-xs-12 col-sm-12 px-0 px-sm-3">
              <div class="panel panel-default">
                <template v-if="showContactForm">
                  <div>
                    <div class="form-group position-relative">
                      <label class="control-label" htmlFor="name"
                        >Name
                        <span class="text-danger">*</span>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        name="contactName"
                        key="contactName"
                        v-model="contact.name"
                        v-validate.attach="'required|alpha_spaces|max:55'"
                        data-vv-validate-on="blur|input"
                        data-vv-as="Contact Name"
                        :class="{ invalid: errors.first('contactName') }"
                      />
                      <span
                        v-if="errors.first('contactName')"
                        class="valid-feedback alert-danger"
                        >{{ errors.first("contactName") }}</span
                      >
                    </div>
                    <div class="form-group position-relative">
                      <label class="control-label" htmlFor="contactNumber"
                        >Contact No.
                        <span class="text-danger">*</span>
                      </label>

                      <input
                        type="number"
                        class="form-control"
                        name="contactNumber"
                        key="contactNumber"
                        v-model="contact.mobile_no"
                        v-validate.attach="'required|numeric|regex:^\\d{10}$'"
                        data-vv-as="Contact Number"
                        data-vv-validate-on="blur|input"
                        :class="{ invalid: errors.first('contactNumber') }"
                      />
                      <span
                        v-if="errors.first('contactNumber')"
                        class="valid-feedback alert-danger"
                        >{{ errors.first("contactNumber") }}</span
                      >
                    </div>
                    <div class="form-group position-relative">
                      <label class="control-label" htmlFor="alternateContact"
                        >Alternate Contact&nbsp;
                      </label>
                      <input
                        type="number"
                        name="alternate-Contact"
                        key="alternate-Contact"
                        v-validate.attach="'regex:^\\d{10}$|numeric'"
                        data-vv-as="Contact Number"
                        class="form-control"
                        v-model="contact.alternate_mobile_no"
                        :class="{ invalid: errors.first('alternate-Contact') }"
                      />
                      <span
                        v-if="errors.first('alternate-Contact')"
                        class="valid-feedback alert-danger"
                        >{{ errors.first("alternate-Contact") }}</span
                      >
                    </div>
                    <div class="form-group position-relative">
                      <label class="control-label" htmlFor="email" e
                        >Email
                        <span class="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        name="contactEmail"
                        key="contactEmail"
                        v-model="contact.email"
                        v-validate.attach="'required|email|max:50'"
                        data-vv-as="E-mail"
                        data-vv-validate-on="blur|input"
                        :class="{ invalid: errors.first('contactEmail') }"
                      />
                      <span
                        v-if="errors.first('contactEmail')"
                        class="valid-feedback alert-danger"
                        >{{ errors.first("contactEmail") }}</span
                      >
                    </div>
                    <div class="form-group position-relative">
                      <label class="control-label"
                        >Alternate Email&nbsp;
                      </label>
                      <input
                        type="text"
                        name="alternate-email"
                        key="alternate-email"
                        v-validate.attach="'email|max:50'"
                        data-vv-as="E-mail"
                        class="form-control"
                        v-model="contact.alternate_email"
                        :class="{ invalid: errors.first('alternate-email') }"
                      />
                      <span
                        v-if="errors.first('alternate-email')"
                        class="valid-feedback alert-danger"
                        >{{ errors.first("alternate-email") }}</span
                      >
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="contact.isChecked"
                        id="flexCheckChecked"
                        name="isChecked"
                      />
                      <label class="form-check-label" for="flexCheckChecked">
                        Want to create user ?
                      </label>
                    </div>
                    <div class="form-group">
                      <label class="control-label m-r10" htmlFor="status"
                        >Status
                        <span class="text-danger">*</span>
                      </label>
                      <label class="m-r10">
                        <input
                          class="m-r10"
                          type="radio"
                          id="active"
                          value="Active"
                          v-model="contact.status"
                        />Active</label
                      >
                      <label class="m-r10">
                        <input
                          class="m-r10"
                          type="radio"
                          id="block"
                          value="Blocked"
                          v-model="contact.status"
                        />Inactive</label
                      >
                    </div>
                    <div class="form-group">
                      <div class="text-right">
                        <button
                          type="button"
                          class="btn btn-dark"
                          @click="hideContactForm"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          v-if="!showContactUpdate"
                          class="btn btn-cp ml-2"
                          @click="addContact($v)"
                          :disabled="$v.contact.$invalid"
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          v-if="showContactUpdate"
                          class="btn btn-cp ml-2"
                          @click="updateContact($v)"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-if="data.contactList.length > 0">
                  <hr v-if="showContactForm" />
                  <table class="table table-responsive table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Primary</th>
                        <th scope="col">Name</th>
                        <th scope="col">Mobile</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(contact, index) in data.contactList"
                        :key="index"
                        :class="{
                          'marked-for-deletion':
                            contactMarkedForDelete(contact),
                        }"
                      >
                        <td colspan="1">
                          <input
                            type="radio"
                            :checked="contact.isPrimary"
                            @click="makePrimary(contact, index)"
                          />
                        </td>
                        <td class="col-3">{{ contact.name }}</td>

                        <td class="col-3">
                          {{ contact.mobile_no }}
                        </td>
                        <td class="col-3">
                          {{ contact.email }}
                        </td>
                        <td class="col-1">
                          <i
                            class="fa fa-circle ml-3"
                            aria-hidden="true"
                            :style="[
                              contact.status === 'Active'
                                ? { color: 'green' }
                                : { color: 'red' },
                            ]"
                          ></i>
                        </td>
                        <td class="col-3" style="width: 120px">
                          <div class="d-flex">
                            <a
                              v-if="!contactMarkedForDelete(contact)"
                              class="fa fa-pencil action-icon edit-icon"
                              title="Edit"
                              @click="fillContactForm(contact, index)"
                            ></a>
                            <a
                              v-if="contactMarkedForDelete(contact)"
                              class="fa fa-undo delete-icon action-icon"
                              style="padding-left: 5px"
                              @click="deleteToggleContact(contact)"
                            ></a>
                            <a
                              v-if="
                                !contactMarkedForDelete(contact) &&
                                !contact.accountId &&
                                currentMode === 'editEntity' &&
                                contact.id
                              "
                              class="fa fa-user user-icon action-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Create User"
                              @click="createPasswordApi(contact.id)"
                            ></a>
                            <a
                              v-if="
                                !contactMarkedForDelete(contact) &&
                                contact.accountId &&
                                currentMode === 'editEntity' &&
                                contact.id
                              "
                              class="fa fa-key user-icon action-icon"
                              data-toggle="tooltip"
                              data-placement="top"
                              :title="
                                contact.accountPassword
                                  ? 'Reset Password'
                                  : 'Create Password'
                              "
                              @click="resetPasswordApi(contact.id)"
                            ></a>
                            <a
                              v-if="!contactMarkedForDelete(contact)"
                              class="fa fa-trash delete-icon action-icon"
                              style="padding-left: 5px"
                              @click="deleteToggleContact(contact)"
                            ></a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group mt-2">
          <div class="d-flex justify-content-between px-sm-2">
            <label class="control-label" htmlFor="Courses"
              >Courses
              <span class="text-danger">*</span>
            </label>
            <div class="panel-heading text-right mb-2">
              <button
                :disabled="showCourseForm"
                type="button"
                class="btn btn-cp"
                @click="openCourseForm"
                data-toggle="tooltip"
                data-placement="top"
                title="Add Course"
              >
                <em class="fa fa-plus"></em> Add Course
              </button>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-xs-12 col-sm-12 px-0 px-sm-3">
              <div class="panel panel-default">
                <template v-if="showCourseForm">
                  <div>
                    <div class="form-group position-relative">
                      <div class="row mx-0">
                        <div class="col-12 col-sm-6 px-0 pr-sm-3">
                          <label class="control-label" htmlFor="Name"
                            >Course Name
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="course_Name"
                            key="course_Name"
                            v-validate.attach="'required|max:100'"
                            data-vv-validate-on="blur|input"
                            data-vv-as="Course Name"
                            class="form-control"
                            v-model="course.course_name"
                            :class="{
                              invalid: errors.first('course_Name'),
                            }"
                          />
                          <span
                            v-if="errors.first('course_Name')"
                            class="valid-feedback alert-danger"
                            >{{ errors.first("course_Name") }}</span
                          >
                        </div>
                        <div class="col-12 col-sm-6 px-0 pl-sm-3">
                          <label class="control-label">Select course</label>
                          <select
                            class="form-control"
                            v-model="course.course_name"
                          >
                            <option selected value="">Select Course</option>
                            <option
                              :value="cr"
                              v-for="(cr, index) in courseList"
                              :key="index"
                            >
                              {{ cr }}
                            </option>
                            <option
                              :value="
                                !course.course_name ||
                                courseList.includes(course.course_name)
                                  ? 'Other'
                                  : course.course_name
                              "
                            >
                              Other
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="form-group position-relative">
                      <label class="control-label" htmlFor="courseStrength"
                        >Course Strength
                        <span class="text-danger">*</span>
                      </label>

                      <input
                        type="number"
                        class="form-control"
                        name="course_Strength"
                        key="course_Strength"
                        v-model="course.course_strength"
                        v-validate.attach="
                          'required|numeric|max_value:1000|min_value:0'
                        "
                        data-vv-validate-on="blur|input"
                        data-vv-as="Course Strength"
                        :class="{
                          invalid: errors.first('course_Strength'),
                        }"
                      />
                      <span
                        v-if="errors.first('course_Strength')"
                        class="valid-feedback alert-danger"
                        >{{ errors.first("course_Strength") }}</span
                      >
                    </div>
                    <div class="form-group">
                      <label class="control-label m-r10" htmlFor="status"
                        >Status
                        <span class="text-danger">*</span>
                      </label>
                      <label class="m-r10">
                        <input
                          class="m-r10"
                          type="radio"
                          id="active"
                          value="Active"
                          v-model="course.status"
                        />Active</label
                      >
                      <label class="m-r10">
                        <input
                          class="m-r10"
                          type="radio"
                          id="block"
                          value="Blocked"
                          v-model="course.status"
                        />Inactive</label
                      >
                    </div>
                    <div class="form-group">
                      <div class="text-right">
                        <button
                          type="button"
                          class="btn btn-dark"
                          @click="hideCourseForm"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          v-if="!showCourseUpdate"
                          class="btn btn-cp ml-2"
                          @click="addCourse()"
                          :disabled="$v.course.$invalid"
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          v-if="showCourseUpdate"
                          class="btn btn-cp ml-2"
                          @click="updateCourse($v)"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
                <div v-if="data.courseList.length > 0">
                  <hr v-if="showCourseForm" />

                  <table class="table table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">Course</th>
                        <th scope="col">Strength</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(course, index) in data.courseList"
                        :key="index"
                        :class="{
                          'marked-for-deletion': courseMarkedForDelete(course),
                        }"
                      >
                        <td class="col-4">
                          {{ course.course_name }}
                        </td>
                        <td class="col-4 ml-2">{{ course.course_strength }}</td>
                        <td class="col-4 ml-2">
                          <i
                            class="fa fa-circle"
                            aria-hidden="true"
                            :style="[
                              course.status === 'Active'
                                ? { color: 'green' }
                                : { color: 'red' },
                            ]"
                          ></i>
                        </td>
                        <td class="col-3">
                          <div class="">
                            <a
                              v-if="!courseMarkedForDelete(course)"
                              class="fa fa-pencil action-icon edit-icon"
                              title="Edit"
                              @click="fillCourseForm(course, index)"
                            ></a>
                            <a
                              v-if="!courseMarkedForDelete(course)"
                              class="fa fa-trash delete-icon action-icon"
                              title="Edit"
                              @click="deleteToggleCourse(course)"
                            ></a>
                            <a
                              v-if="courseMarkedForDelete(course)"
                              class="fa fa-undo delete-icon action-icon"
                              title="Edit"
                              @click="deleteToggleCourse(course)"
                            ></a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="form-footer text-right border-top pt-4 mt-4">
        <router-link :to="{ name: 'viewEntity' }">
          <button type="button" class="btn btn-dark">Cancel</button>
        </router-link>
        <button
          type="button"
          class="btn btn-cp ml-2"
          style="margin-right: 10px"
          @click="postData()"
          :disabled="$v.data.$invalid"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { states } from "../../Common/commonData";
import api from "../_api/index";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
import { COURSE_LIST } from "../_utils/course-list-const";

import {
  required,
  minLength,
  maxLength,
  numeric,
  email,
} from "vuelidate/lib/validators";

/**
 * @memberof module:campusRecruitment
 * @namespace components.edit_entity_form
 */
export default {
  // props: ["editData"],
  props: {
    currentMode: {
      type: String,
      required: true,
    },
  },

  /**
   * @memberof module:campusRecruitment.components.edit_entity_form
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      ratingsArray: [
        { rating: 1, value: "poor" },
        { rating: 2, value: "moderate" },
        { rating: 3, value: "good" },
        { rating: 4, value: "very good" },
        { rating: 5, value: "excellent" },
      ],
      courseList: COURSE_LIST,
      data: {
        id: null,
        entity_name: null,
        entity_type: "College",
        parent_entity: null,
        website: null,
        state: "Gujarat",
        city: null,
        pincode: null,
        status: "active",
        ratings: null,
        remarks: null,
        contactList: [],
        courseList: [],
      },
      listStates: [],
      showContactForm: false,
      contact: {},
      course: null,
      universities: [],
      showContactUpdate: false,
      showCourseForm: false,
      showCourseUpdate: false,
      checkerEntityPicker: false,
      chekerPrimary: false,
      isUniversity: false,
      checkerbox: false,
      deletedCourse: [],
      deletedContact: [],
    };
  },
  validations() {
    const data = {
      entity_name: {
        required,
        entityNameValidator: this.entityNameValidator,
      },
      entity_type: {
        required,
      },
      status: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
      pincode: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
      contactList: {
        required,
      },
      courseList: {
        required,
      },
    };
    const contact = {
      name: {
        required,
      },
      mobile_no: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      status: {
        required,
      },
      accountId: {},
      accountPassword: {},
      email: {
        required,
        email,
      },
      alternate_email: {
        email,
      },
      alternate_mobile_no: {
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    };
    const course = {
      course_name: {
        required,
      },
      course_strength: {
        required,
        numeric,
      },
      status: {
        required,
      },
    };
    return { data, contact, course };
  },
  components: {
    CommonRichTextBox,
  },
  computed: {
    ...mapGetters({
      entityData: "$_campus_recruitment/fetchEntityModalData",
      entities: "$_campus_recruitment/entities",
    }),
  },

  /**
   * @memberof module:campusRecruitment.components.edit_entity_form
   * @description lifecycle method - fetch entity
   */
  created() {
    this.$store.dispatch("$_campus_recruitment/fetchEntities");
  },
  updated() {
    if (this.checkerEntityPicker === true) {
      $("#entitypicker").selectpicker("refresh");
      this.checkerEntityPicker = false;
    }
  },
  mounted() {
    this.listStates = states;
    this.resetCourseForm();
    this.resetContactForm();
    if (this.currentMode === "editEntity") {
      api.entityId(parseInt(this.$route.params.entityId)).then((res) => {
        this.$store.dispatch(
          "$_campus_recruitment/setUpdateEntityModalData",
          res.data
        );
      });
    }
  },
  methods: {
    onKeyUp(value) {
      this.data.remarks = value;
    },

    onBlur(value) {
      this.data.remarks = value;
    },

    ratingConverter(rating) {
      if (typeof rating === "string") {
        switch (rating) {
          case "poor":
            return 1;
          case "moderate":
            return 2;
          case "good":
            return 3;
          case "very good":
            return 4;
          case "excellent":
            return 5;
          default:
            return null;
        }
      } else {
        return rating;
      }
    },

    /**
     * set rating
     */
    setRating(rating) {
      this.data.ratings = rating;
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {String} value
     * @returns {boolean}
     * @description Test your session name by defining regex
     */
    async createPasswordApi(id) {
      try {
        await api.createUser(id);
        const contactList = this.data.contactList.map((contact) => {
          if (contact.id === id) {
            return { ...contact, accountId: "exist" };
          }
          return contact;
        });
        this.data.contactList = contactList;
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "success",
            message:
              "User created successfully. A link has been sent to recruiter mail id.",
          },
          { root: true }
        );
      } catch (error) {
        console.log("error", error);
        this.$store.dispatch(
          "openSnackbar",
          { type: "danger", message: "Something went wrong" },
          { root: true }
        );
      }
    },
    async resetPasswordApi(id) {
      try {
        await api.initiatePasswordReset(id);
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "success",
            message: "A link has been sent to recruiter mail id.",
          },
          { root: true }
        );
      } catch (error) {
        this.$store.dispatch(
          "openSnackbar",
          { type: "danger", message: "Something went wrong" },
          { root: true }
        );
      }
    },

    compareContactStatus(a, b) {
      if (!a.status && b.status) return 1;
      if (a.status && !b.status) return -1;
      return 0;
    },
    compareCourseStatus(x, y) {
      if (!x.status && y.status) return 1;
      if (x.status && !y.status) return -1;
      return 0;
    },

    createUserPopUp() {
      this.$alert(
        "User created successfully. A link has been sent to recruiter mail id."
      );
    },
    resetPasswordPopUp() {
      this.$alert("A link has been sent to recruiter mail id.");
    },

    entityNameValidator(value) {
      return /^[^-\s][a-zA-Z. ]*$/.test(value);
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {Object} contact
     * @description Set the primary contact of entity
     */
    makePrimary(contact, index) {
      this.data.contactList.forEach((element, index2) => {
        if (index2 == index) {
          element.isPrimary = true;
        } else {
          element.isPrimary = false;
        }
      });
    },
    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Open course form
     */
    openCourseForm() {
      this.resetCourseForm();
      this.showCourseForm = true;
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Hide course form
     */
    hideCourseForm() {
      this.courseUpdateIndex = null;
      this.showCourseUpdate = false;
      this.showCourseForm = false;
      this.$v.course.$reset();
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {Object} course
     * @description Fill the course form with specific course object
     */
    fillCourseForm(course, index) {
      this.courseUpdateIndex = index;
      this.showCourseUpdate = true;
      let strCourse = JSON.stringify(course);
      this.course = JSON.parse(strCourse);
      this.showCourseForm = true;
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Fill course form with updated course data
     */
    updateCourse(v) {
      v.course.$reset();
      for (const [index, course] of this.data.courseList.entries()) {
        if (index != this.courseUpdateIndex) {
          if (course.course_name == this.course.course_name) {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "Course with this name already exist!",
              },
              { root: true }
            );
            return;
          }
        }
      }
      this.data.courseList[this.courseUpdateIndex] = this.course;
      this.hideCourseForm();
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {Object} course
     * @description Delete course data
     */
    deleteCourse(course) {
      this.deletedCourse.push(course);
      this.data.courseList = this.data.courseList.filter((element) => {
        return element !== course;
      });
    },
    courseMarkedForDelete(course) {
      return this.deletedCourse.includes(course);
    },
    deleteToggleCourse(course) {
      if (course.id) {
        if (this.courseMarkedForDelete(course)) {
          this.deletedCourse = this.deletedCourse.filter((c) => c !== course);
        } else {
          this.deletedCourse.push(course);
        }
      } else {
        this.data.courseList = this.data.courseList.filter((element) => {
          return element !== course;
        });
      }
    },
    contactMarkedForDelete(contact) {
      return this.deletedContact.includes(contact);
    },
    deleteToggleContact(contact) {
      if (contact.id) {
        if (this.contactMarkedForDelete(contact)) {
          this.deletedContact = this.deletedContact.filter(
            (c) => c !== contact
          );
        } else {
          this.deletedContact.push(contact);
        }
      } else {
        this.data.contactList = this.data.contactList.filter((element) => {
          return element !== contact;
        });
      }
    },
    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {Object} v
     * @description Add course data
     */
    addCourse() {
      for (const course of this.data.courseList) {
        if (course.course_name == this.course.course_name) {
          this.$store.dispatch(
            "openSnackbar",
            { type: "danger", message: "Course with this name already exist!" },
            { root: true }
          );
          return;
        }
      }
      this.data.courseList.push(this.course);
      this.hideCourseForm();
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Reset course form data
     */
    resetCourseForm() {
      if (this.data.courseList.length == 0) {
        this.course = {
          course_name: "",
          course_strength: null,
          status: "Active",
        };
        return;
      }
      this.course = {
        course_name: "",
        course_strength: null,
        status: "Active",
      };
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Open contact form
     */
    openContactForm() {
      this.resetContactForm();
      this.showContactForm = true;
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Hide contact form
     */
    hideContactForm() {
      this.showContactUpdate = false;
      this.contactUpdateIndex = null;
      this.showContactForm = false;
      this.$v.contact.$reset();
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {Object} contact
     * @description Fill the conatct form with specific contact object
     */
    fillContactForm(contact, index) {
      this.contactUpdateIndex = index;
      this.showContactUpdate = true;
      let strContact = JSON.stringify(contact);
      this.contact = JSON.parse(strContact);
      this.showContactForm = true;
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Fill contact form with updated contact data
     */
    updateContact(v) {
      v.contact.$reset();
      for (const [index, contact] of this.data.contactList.entries()) {
        if (index != this.contactUpdateIndex) {
          if (contact.email === this.contact.email) {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "User with this email address already exist!",
              },
              { root: true }
            );
            return;
          }
          if (contact.mobile_no === this.contact.mobile_no) {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "User with this phone number already exist!",
              },
              { root: true }
            );
            return;
          }
        }
      }
      if (!this.contact.alternate_mobile_no)
        this.contact.alternate_mobile_no = null;
      if (!this.contact.alternate_email) this.contact.alternate_email = null;
      this.data.contactList[this.contactUpdateIndex] = this.contact;
      this.hideContactForm();
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {Object} contact
     * @description Delete contact data
     */

    deleteContact(contact) {
      if (!contact.id) {
        this.data.contactList = this.data.contactList.filter((element) => {
          return element !== contact;
        });
      }
      this.deletedContact.push(contact);
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {Object}
     * @description Add contact data
     */
    addContact() {
      for (const contact of this.data.contactList) {
        if (contact.email === this.contact.email) {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "User with this email address already exist!",
            },
            { root: true }
          );
          return;
        }
        if (contact.mobile_no === this.contact.mobile_no) {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "User with this phone number already exist!",
            },
            { root: true }
          );
          return;
        }
        if (contact.isPrimary) {
          this.contact.isPrimary = false;
        }
      }
      if (!this.contact.alternate_mobile_no)
        this.contact.alternate_mobile_no = null;
      if (!this.contact.alternate_email) this.contact.alternate_email = null;
      this.data.contactList.push(this.contact);
      this.hideContactForm();
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Reset contact form data
     */
    resetContactForm() {
      if (this.data.contactList.length == 0) {
        this.contact = {
          name: null,
          mobile_no: null,
          alternate_mobile_no: null,
          email: null,
          alternate_email: null,
          isPrimary: true,
          isChecked: true,
          status: "Active",
        };
        return;
      }
      this.contact = {
        name: null,
        mobile_no: null,
        alternate_mobile_no: null,
        email: null,
        alternate_email: null,
        isPrimary: false,
        isChecked: false,
        status: "Active",
      };
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Post campus recruitment data
     */
    postData() {
      if (this.currentMode === "addEntity") {
        this.$store.dispatch("$_campus_recruitment/addEntity", this.data);
      } else {
        if (this.deletedCourse.length === this.data.courseList.length) {
          this.$store.dispatch(
            "openSnackbar",
            { type: "danger", message: "Atleast 1 Course must be present" },
            { root: true }
          );
          return;
        }
        if (this.deletedContact.length === this.data.contactList.length) {
          this.$store.dispatch(
            "openSnackbar",
            { type: "danger", message: "Atleast 1 Contact must be present" },
            { root: true }
          );
          return;
        }
        this.data.contactList.forEach((element) => {
          delete element.createdAt;
          delete element.created_by;
          delete element.updatedAt;
          delete element.updated_by;
          delete element.entityId;
        });
        this.data.courseList.forEach((element) => {
          delete element.createdAt;
          delete element.created_by;
          delete element.updatedAt;
          delete element.updated_by;
          delete element.entityId;
        });
        for (let key in this.deletedContact) {
          this.$store.dispatch(
            "$_campus_recruitment/deleteEntityContactPersonDetail",
            this.deletedContact[key].id
          );
        }
        for (let key in this.deletedCourse) {
          this.$store.dispatch(
            "$_campus_recruitment/deleteEntityCourseDetail",
            this.deletedCourse[key].id
          );
        }
        this.$store.dispatch("$_campus_recruitment/updateEntity", this.data);
      }
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @description Select entity type
     */
    selectEntityType() {
      this.universities = [];
      if (this.data.entity_type == "College") {
        this.isUniversity = false;
        if (this.entities) {
          this.entities.forEach((element) => {
            if (
              element.entity_type.toLowerCase() == "university" &&
              element.status == "active"
            ) {
              this.universities.push({
                id: element.id,
                name: element.entity_name,
              });
            }
          });
        }
      } else {
        this.isUniversity = true;
      }
    },
  },

  watch: {
    entities() {
      this.selectEntityType();
    },

    entityData(data) {
      this.data.id = data.id;
      this.data.entity_name = data.entity_name;
      this.data.entity_type = data.entity_type;
      this.data.city = data.city;
      this.data.website = data.website;
      this.data.state = data.state;
      this.data.pincode = data.pincode;
      this.data.contactList = data.contactPersonDetails;
      this.data.courseList = data.courseDetails;
      this.data.parent_entity = data.parent_entity;
      this.data.status = data.status;
      this.data.ratings = this.ratingConverter(data.ratings);
      this.data.remarks = data.remarks;
      this.selectEntityType();
      $("#" + "edit-entity-text-box").summernote("code", data.remarks);
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {Array} value
     * @description Watch - Make contact list primary
     */
    "data.contactList": function () {
      this.chekerPrimary = true;
      this.checkerbox = true;
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_form
     * @param {String} value
     * @description Watch - Check entity picker
     */
    universities: function () {
      this.checkerEntityPicker = true;
    },
  },
};
</script>

<style scoped>
.updateOptions {
  padding-left: 40px;
}
.table-res {
  width: 100%;
  overflow-x: auto;
}

.m-10 {
  margin: 5px 10px;
}

.star-checked {
  color: orange;
}

.marked-for-deletion {
  text-decoration: line-through;
  opacity: 0.5;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
