import _scheduleDashboard from "./_components/schedule-dashboard.vue";
import campusSchedule from "./_components/my-campus-schedule.vue";
import ScheduleHome from "./index.vue";

export default {
  path: "my-schedule",
  component: ScheduleHome,
  children: [
    {
      path: "",
      component: _scheduleDashboard,
      name: "scheduleDashboard",
      meta: {
        title: "Schedule Dashboard",
        user: "employee",
      },
    },
    {
      path: "campus-drive/:event",
      component: campusSchedule,
      name: "myCampusSchedule",
      meta: {
        title: "Campus Schedule",
        user: "employee",
      },
    },
  ],
};
