<template>
  <div class="content">
    <common-header :data="['home', 'report', 'auditTrail']">
      <div>
        <select
          v-model="selectedPageSize"
          v-on:change="onPageSizeChanged()"
          id="page-size"
          class="select-cp form-control"
        >
          <option value="50">50</option>
          <option selected="" value="100">100</option>
          <option value="200">200</option>
          <option value="300">300</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
          <option value="2000">2000</option>
        </select>
      </div>
    </common-header>
    <div class="container-fluid">
      <ag-grid-vue
        class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
        :enableBrowserTooltips="true"
        :gridOptions="gridData"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :paginationAutoPageSize="false"
        @gridReady="onGridReady"
        :key="paginationSize"
      ></ag-grid-vue>
    </div>

    <audit-diff-modal :rowData="infoData" ref="auditDiffModal" />
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import axios from "axios";
import getFilterByType from "../../Common/_utils/get-filter-by-type";
import auditDiffModal from "./audit-diff-modal.vue";

export default {
  name: "audit-report",
  data() {
    return {
      paginationSize: 100,
      gridData: null,
      auditId: this.$route.params.auditId,
      infoData: {},
      AuditList: [],
      columnDefs: [
        {
          headerName: "Target Model",
          field: "targetModel",
          filter: true,
          floatingFilter: true,
          suppressHeaderMenuButton: true,
          minWidth: 200,
        },
        {
          headerName: "Action",
          field: "action",
          filter: true,
          floatingFilter: true,
          suppressHeaderMenuButton: true,
          minWidth: 120,
        },
        {
          headerName: "Version",
          field: "version",
          filter: "agNumberColumnFilter",
          suppressHeaderMenuButton: true,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "Created By",
          field: "created_by",
          minWidth: 150,
          sortable: false,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          valueGetter: (params) => {
            if (params.data) {
              return params.data.created_by_displayName;
            }
          },
        },
        {
          headerName: "Date",
          field: "createdAt",
          filter: "agDateColumnFilter",
          minWidth: 200,
          valueFormatter: (params) => {
            return moment(params.value).format("DD/MM/YYYY hh:mm:ss a");
          },
          filterParams: {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              const cellDate = new Date(cellValue);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Actions",
          field: "actions",
          pinned: "right",
          sortable: false,
          width: 80,
          minWidth: 80,
          maxWidth: 80,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          cellRenderer: "auditActions",
          cellRendererParams: { showAuditDataModal: this.showAuditDataModal },
        },
      ],
      defaultColDef: {
        resizable: true,
        sortable: true,
        flex: 1,
        floatingFilter: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        filter: true,
      },
      gridApi: null,
      selectedPageSize: 100,
      pageSizeOptions: [50, 100, 200, 300, 400, 500, 1000, 2000],
    };
  },
  components: {
    auditDiffModal,
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    auditActions: {
      template: `<div>
          <div v-if="params && params.data">
            <router-link :to="{name:'auditTrailDetail', params:{auditId:params.data.id}}">
              <i class="fa fa-history" title="History"></i>
            </router-link>
            <router-link :to="{}" class='ml-2'>
              <i class="fa fa-info-circle" @click="openAuditModal()" title="Info"></i>
            </router-link>
          </div>
        </div>`,
      methods: {
        openAuditModal() {
          this.params.showAuditDataModal(this.params.data);
        },
      },
    },
  },
  methods: {
    setDataSource() {
      let dataSource = {
        rowCount: null,
        rowCountPagination: 0,
        rowCountFiltering: 0,
        _self: this,
        getRows: function (params) {
          let includeAll = this._self.includeAll;
          let queryParams = {
            startRow: params.startRow,
            size: params.endRow - params.startRow,
            includeAll: includeAll,
          };
          this.rowCountPagination = 0;
          if (params.startRow == 0) {
            this.rowCountFiltering = 0;
          }

          let queryObj = {};
          if (params.sortModel) {
            queryObj.sort = params.sortModel[0];
          }
          for (const key in params.filterModel) {
            if (Object.prototype.hasOwnProperty.call(params.filterModel, key)) {
              const queryData = getFilterByType(params.filterModel[key]);
              queryObj[key] = queryData;
            }
          }
          if (
            Object.keys(params.filterModel).length != 0 ||
            Object.keys(params.sortModel).length != 0
          ) {
            this.rowCountPagination = 0;
            if (params.startRow == 0) {
              this.rowCountFiltering = 0;
            }
            axios
              .post("/audit-trail/data_list", queryObj, { params: queryParams })
              .then(
                (data) => {
                  this.rowCountFiltering += data.data.length;
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountFiltering,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            axios
              .post("/audit-trail/data_list", {}, { params: queryParams })
              .then(
                (data) => {
                  this.rowCountFiltering += data.data.length;
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountFiltering,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        },
      };
      this.gridData.api.setGridOption("datasource", dataSource);
    },
    getLastRowValue(rowCount, endRow, defaultValue = -1) {
      if (rowCount < endRow) return rowCount;
      return defaultValue;
    },
    onPageSizeChanged() {
      const rowCount = Number($("#page-size option:selected").val());
      this.gridData.paginationPageSize = rowCount;
      this.gridData.cacheBlockSize = rowCount;
      this.paginationSize = rowCount;
    },
    onGridReady(params) {
      if (params.api) {
        this.gridData.api = params.api;
        this.setDataSource();
      }
    },
    openAuditModal() {
      $("#auditDiffModal").modal("show");
    },
    showAuditDataModal(data) {
      this.infoData = data;
      this.openAuditModal();
    },
    configGridOptions() {
      this.gridData = {
        suppressPropertyNamesCheck: true,
        enableServerSideFilter: true,
        enableCellTextSelection: true,
        enableColResize: true,
        columnDefs: this.columnDefs,
        rowModelType: "infinite",
        infiniteInitialRowCount: 0,
        animateRows: true,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        pagination: true,
        paginationAutoPageSize: false,
        paginationPageSize: 100,
        paginationPageSizeSelector: false,
        cacheBlockSize: 100,
        context: {
          componentParent: this,
        },
      };
    },
  },
  beforeMount() {
    this.configGridOptions();
  },
};
</script>

<style scoped>
.ag-grid-style {
  height: calc(100vh - 135px);
  min-height: 500px;
}

/* MEDIA QUERY FOR SMALLER SCREENS */
@media screen and (max-width: 290px) {
  .ag-grid-style {
    min-height: 200px;
    height: calc(100vh - 195px);
  }
}
</style>
