import applications from "./index.vue";
import applicantSchedule from "./_components/applicant-schedule.vue";
import applicantFollowUp from "./_components/applicant-followUp.vue";
import applicantTracker from "./_components/applicant-tracker.vue";
import applicantData from "./_components/applicant-data.vue";
import applicantMaster from "./_components/applicant-master-data.vue";
import PERMISSIONS from "../Common/permissions";

export default {
  path: "applications",
  component: applications,
  children: [
    {
      path: "schedule",
      component: applicantSchedule,
      name: "applicantSchedule",
      meta: {
        title: "Applicant Schedule",
        user: "employee",
      },
    },
    {
      path: "list",
      component: applicantData,
      name: "applicants",
      meta: {
        title: "Applicants",
        user: "employee",
        permissions: [
          PERMISSIONS.APPLICANT.READ,
          PERMISSIONS.APPLICANT["APPLICANT-SEARCH"],
        ],
        checkAll: true,
      },
    },
    {
      path: "follow-ups",
      component: applicantFollowUp,
      name: "applicantFollowup",
      meta: {
        title: "Applicant Followup",
        user: "employee",
        permissions: [
          PERMISSIONS.APPLICANT.READ,
          PERMISSIONS.APPLICANT["FOLLOW-UP"],
        ],
        checkAll: true,
      },
    },
    {
      path: "tracker",
      component: applicantTracker,
      name: "applicantTracker",
      meta: {
        title: "Applicant Tracker",
        user: "employee",
        permissions: [
          PERMISSIONS.APPLICANT.READ,
          PERMISSIONS.APPLICANT["APPLICANT-TRACKER"],
        ],
        checkAll: true,
      },
    },
    {
      path: "master",
      component: applicantMaster,
      name: "applicantMaster",
      meta: {
        title: "Applicant Master",
        user: "employee",
        permissions: [
          PERMISSIONS.APPLICANT.READ,
          PERMISSIONS.APPLICANT["APPLICANT-SEARCH"],
        ],
        checkAll: true,
      },
    },
  ],
};
