const CommonModal = {
  install(Vue) {
    /**
     * @memberof module:Common
     * @namespace common_modal_mixin
     */
    Vue.mixin({
      /**
       * @memberof module:Common.common_modal_mixin
       * @returns {Object} data object
       * @description data - returns data for mixin
       */
      data() {
        return {
          commonModalData: {
            id: "",
            title: "",
            body: "",
            color: "",
            sizeClass: "",
            showCancel: false,
          },
        };
      },
      methods: {
        /**
         * @memberof module:Common.common_modal_mixin
         * @param {Number} modalId id attribute for modal
         * @param {String} modalTitle heading for modal
         * @param {String} modalBody modal body text or html
         * @param {Boolean} showCancel display cancel button or not
         * @param {String} modalColor class for modal heading color
         * @param {String} modalSizeClass class for modal size
         * @returns {Promise} for handling ok and cancel button events
         * @description method - Opens dialog with specified options and gives promise for handling ok and cancel events
         */
        openDialog(
          modalId,
          modalTitle,
          modalBody,
          showCancel = false,
          modalColor = "",
          modalSizeClass = "",
          returnError = false
        ) {
          this.commonModalData.id = modalId;
          this.commonModalData.title = modalTitle;
          this.commonModalData.body = modalBody;
          this.commonModalData.showCancel = showCancel;
          this.commonModalData.color = modalColor;
          this.commonModalData.sizeClass = modalSizeClass;

          const mId = "#" + modalId;
          setTimeout(() => {
            $(mId).modal({ backdrop: "static", keyboard: false });
          }, 100);
          return new Promise((resolve, reject) => {
            this.$on("actionPerformed", (event) => {
              if (event === "ok") resolve();
              else returnError && reject();
            });
          });
        },

        /**
         * @memberof module:Common.common_modal_mixin
         * @param {String} event 'ok' or 'cancel'
         * @description method - emits event on current component to resolve the promise returned from openDialog method
         */
        emitOnCurrent(event) {
          this.$emit("actionPerformed", event);
        },
      },
    });
  },
};

export default CommonModal;
