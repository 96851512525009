<template>
  <div :class="{ 'mb-3': !isFullHeight().marginBottom }">
    <CollapseCard
      class="theme-default"
      title="Events Calendar"
      :midDivider="false"
      header-class="px-0 py-0"
      :collapse="!isFullHeight().marginBottom"
      title-class="h5 m-0"
    >
      <template v-slot:right>
        <div class="d-flex justify-content-end align-items-center">
          <span class="ml-auto mr-1">Pending Events</span>
          <div>
            <span class="bg-secondary px-2 rounded ml-auto ml-2 text-white">{{
              pendingCampusEvents.length + pendingEvents.length
            }}</span>
          </div>
        </div>
      </template>
      <div class="p-2">
        <div class="">
          <div class="row">
            <div class="d-flex w-100 justify-content-between mb-3 px-3">
              <button
                class="btn btn-sm"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFilters"
                aria-expanded="false"
                aria-controls="collapseFilters"
                style="border: 1px solid #8f1452; color: #8f1452"
              >
                <b-icon icon="filter-left"></b-icon>Filters
              </button>
              <button
                class="btn btn-sm"
                type="button"
                data-toggle="collapse"
                data-target="#collapsePendingEvents"
                aria-expanded="false"
                aria-controls="collapsePendingEvents"
                style="border: 1px solid #8f1452; color: #8f1452"
              >
                Pending Events
              </button>
            </div>
            <div class="collapse w-100 filter-pending" id="collapseFilters">
              <div
                class="card card-body p-2"
                style="max-width: 300px; height: 100%"
              >
                <div style="display: flex; justify-content: space-between">
                  <h5>Filters</h5>
                  <div>
                    <button
                      class="btn btn-sm border"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseFilters"
                      aria-expanded="false"
                      aria-controls="collapseFilters"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <div class="applicant-schedule-sidebar-section row pl-4">
                  <div class="col-12">
                    <input
                      id="includeWeekends"
                      type="checkbox"
                      class="form-check-input"
                      :checked="calendarOptions.weekends"
                      @change="handleWeekendsToggle"
                    />
                    <label for="includeWeekends"> Include Weekends</label>
                  </div>
                  <div class="col-12">
                    <input
                      id="showAll"
                      type="checkbox"
                      class="form-check-input"
                      :checked="!calendarOptions.dayMaxEvents"
                      @change="handleDayMaxEventsToggle"
                    />
                    <label for="showAll"> Show All Events</label>
                  </div>
                  <div
                    class="col-12"
                    v-if="
                      hasAccess({
                        permissions: [
                          BASE_PERMISSION.READ,
                          PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                        ],
                        checkAll: false,
                      })
                    "
                  >
                    <input
                      id="mySchedule"
                      type="checkbox"
                      class="form-check-input"
                      @change="toggleMyRounds"
                    />
                    <label for="mySchedule">My Schedules</label>
                  </div>
                  <div
                    class="col-12"
                    v-if="
                      hasAccess({ permissions: [PERMISSIONS.APPLICANT.READ] })
                    "
                  >
                    <input
                      id="claimedByMe"
                      type="checkbox"
                      class="form-check-input"
                      @change="toggleClaimedByMe"
                    />
                    <label for="claimedByMe">Claimed By Me</label>
                  </div>
                </div>
                <div class="applicant-schedule-sidebar-section">
                  <h5>Filters</h5>
                  <div
                    class="col-12 p-0 mb-2"
                    v-if="
                      hasAccess({
                        permissions: [
                          PERMISSIONS.APPLICANT.READ,
                          PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                        ],
                        checkAll: false,
                      })
                    "
                  >
                    <label htmlFor="interviewedBy"
                      >Interviewers/Reviewers</label
                    >
                    <multiselect
                      v-model="selectedUsers"
                      :multiple="true"
                      :options="users"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Pick some"
                      label="name"
                      track-by="name"
                      :preselect-first="true"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel="*"
                      @input="filterEvents"
                    >
                      <!-- eslint-disable-next-line vue/no-unused-vars -->
                      <template slot="selection" slot-scope="{ values, isOpen }"
                        ><span
                          class="multiselect__single"
                          v-if="values.length && !isOpen"
                          >{{ values.length }} options selected</span
                        ></template
                      >
                    </multiselect>
                  </div>
                  <div class="col-12 p-0">
                    <label htmlFor="events">Include Events</label><br />
                    <select
                      v-model="selectedType"
                      class="form-control"
                      @change="filterEvents"
                    >
                      <option value="">All Events</option>
                      <option value="Aptitude">Aptitude Only</option>
                      <option value="Written Test">Written Test Only</option>
                      <option value="Interview">Interviews Only</option>
                      <option value="Review">Reviews Only</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="collapse w-100 filter-pending pending-list"
              id="collapsePendingEvents"
            >
              <div
                class="card card-body p-2"
                style="
                  max-width: 300px;
                  height: 100%;
                  margin-left: auto;
                  overflow-y: auto;
                "
              >
                <div style="display: flex; justify-content: space-between">
                  <h5>Pending Events</h5>
                  <div>
                    <button
                      class="btn btn-sm border"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapsePendingEvents"
                      aria-expanded="false"
                      aria-controls="collapsePendingEvents"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="applicant-schedule-sidebar-section"
                  style="flex-grow: 1; overflow-y: auto"
                  v-if="
                    pendingEvents.length > 0 || pendingCampusEvents.length > 0
                  "
                >
                  <div class="pending-events-section m-0">
                    <h6>Applicant Events - ({{ pendingEvents.length }})</h6>
                    <div
                      class="mb-2"
                      v-for="event in pendingEvents"
                      :key="event.id"
                    >
                      <span
                        style="margin-top: 3px"
                        class="event-circle"
                        :title="event.roundName"
                      >
                        {{ event.round }}
                      </span>
                      <p class="mb-0 font-weight-bold">
                        {{ event.start | formatDateTime }}
                      </p>
                      <div style="margin-left: 30px">
                        <i :title="event.withUsers">
                          <router-link
                            target="_blank"
                            :to="{
                              name: 'applicantprofile',
                              params: { slug: event.slug },
                            }"
                            class="link-class"
                            >{{ event.title | truncate(30, "..") }}</router-link
                          >
                        </i>
                      </div>
                    </div>
                    <h6 class="mt-4">
                      Campus Events - ({{ pendingCampusEvents.length }})
                    </h6>
                    <div
                      class="schedule-app-list"
                      v-for="(event, index) in pendingCampusEvents"
                      :key="index"
                    >
                      <span
                        style="margin-top: 3px"
                        class="event-circle"
                        :title="event.round_name"
                        >{{ event.round }}</span
                      >
                      <b>
                        {{ formatCampusDate(event.round_schedule) }} -
                        {{ formatCampusDate(event.round_schedule_end) }}
                      </b>
                      <div
                        v-if="
                          hasAccess({
                            permissions: [
                              PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                            ],
                            checkAll: false,
                          })
                        "
                      >
                        <i>
                          <span
                            style="margin-top: 3px"
                            class="event-circle event-type-circle"
                            :title="'Campus Event'"
                            >C</span
                          >
                          <router-link
                            target="_blank"
                            :to="{
                              name: 'scheduleEventSlot',
                              params: { eventId: event.id },
                            }"
                            class="link-class"
                          >
                            {{ event.title | truncate(30, "..") }}</router-link
                          >
                        </i>
                      </div>
                      <div v-else>
                        <i>
                          <span
                            style="margin-top: 3px"
                            class="event-circle event-type-circle"
                            :title="'Campus Event'"
                            >C</span
                          >{{ event.title | truncate(30, "..") }}</i
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  <h5 class="m-0">No Pending Events</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-0" ref="calendarParent">
          <FullCalendar
            v-show="isCalendarView"
            id="calendar"
            name="calendar"
            ref="calendar"
            class="fullcalendar schedule-app-calendar fullcalendar-header-style-md"
            :options="calendarOptions"
          >
            <template v-slot:eventContent="arg">
              <div style="width: 100%">
                <div
                  style="width: 100%"
                  :class="{
                    'event-title-inactive':
                      arg.event.extendedProps.status != 'Pending',
                  }"
                  @click="handleEventClick(arg)"
                  v-if="arg.event.extendedProps.eventType === 'applicantEvent'"
                >
                  <div>
                    <b class="event-time">{{ arg.timeText }}</b>
                    <span
                      class="event-circle"
                      :title="arg.event.extendedProps.roundName"
                      :class="{
                        'event-circle-inactive':
                          arg.event.extendedProps.status != 'Pending',
                      }"
                    >
                      {{ arg.event.extendedProps.round }}
                    </span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <i
                      class="title-overflow"
                      v-if="arg.event.extendedProps.status !== 'Pending'"
                      :title="arg.event.extendedProps.withUsers"
                    >
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'applicantprofile',
                          params: { slug: arg.event.extendedProps.slug },
                        }"
                        class="link-class"
                      >
                        {{ arg.event.title }}
                      </router-link>
                    </i>
                    <i
                      class="title-overflow"
                      v-else
                      :title="arg.event.extendedProps.withUsers"
                    >
                      {{ arg.event.title }}
                    </i>
                    <span v-if="arg.view.type == 'listMonth'">
                      {{ arg.event.extendedProps.interviewers }}
                    </span>
                  </div>
                </div>
                <div
                  class="w-100"
                  style="overflow: hidden"
                  @click="handleCampusEventClick(arg)"
                  v-else
                >
                  <div
                    style="width: 100%"
                    class="d-flex justify-content-between"
                    :class="{
                      'event-title-inactive':
                        arg.event.extendedProps.status != 'Pending' ||
                        !scheduleDateValid(
                          arg.event.extendedProps.round_schedule_end
                        ),
                    }"
                  >
                    <div class="left-side-div">
                      <span
                        class="event-circle span-circle"
                        :title="arg.event.extendedProps.round_name"
                        :class="{
                          'event-circle-inactive':
                            arg.event.extendedProps.status != 'Pending' ||
                            !scheduleDateValid(
                              arg.event.extendedProps.round_schedule_end
                            ),
                        }"
                      >
                        {{ arg.event.extendedProps.round }}
                      </span>
                      <span
                        :title="arg.event.extendedProps.withUsers"
                        class="font-weight-bold"
                        style="cursor: pointer"
                      >
                        {{ arg.event.title }}
                      </span>
                    </div>

                    <div class="d-flex">
                      <div v-if="arg.view.type == 'listMonth'">
                        {{ arg.event.extendedProps.interviewers }}
                      </div>
                      <div v-if="arg.view.type != 'listMonth'">
                        {{
                          extractTime(arg.event.extendedProps.round_schedule)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </FullCalendar>
        </div>
      </div>
      <div class="card_footer pb-2 d-flex justify-content-between">
        <div class="d-flex pl-2">
          <router-link
            v-if="
              hasAccess({
                permissions: [PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU],
              })
            "
            :to="{ name: 'RecruitmentCampusSchedule' }"
          >
            Campus Drive
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </router-link>
        </div>
        <div class="text-right pr-2">
          <router-link :to="{ name: 'applicantSchedule' }">
            Full Calendar
            <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
          </router-link>
        </div>
      </div>
    </CollapseCard>
    <b-modal
      ref="eventModal"
      :title="eventModalData.modalTitle"
      :size="eventModalData.modalSize"
      :hide-footer="eventModalData.hideFooter"
    >
      <EventModal
        :isEdit="eventModalData.isEdit"
        v-on:generateRound="generateRound($event)"
        v-on:updateEvent="updateEvent($event)"
        :editEventApplicant="editEventApplicantData"
      />
    </b-modal>
    <b-modal
      ref="campusEventModal"
      :title="eventModalData.modalTitle"
      :size="eventModalData.modalSize"
      :hide-footer="eventModalData.hideFooter"
    >
      <CampusEventModal
        :campusSchedule="false"
        v-on:closeEventModal="closeEventModal"
        :isEdit="eventModalData.isEdit"
        :selectedDate="selectedDate"
        :editEvent="editEvent"
        v-on:generateRound="generateRound($event)"
        v-on:updateEvent="updateEvent($event)"
      />
    </b-modal>
    <edit-round-modal
      v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.UPDATE] })"
      :showApplicantName="true"
      :applicantData="this.applicantData"
    />
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
    <schedule-round-modal
      v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.CREATE] })"
      :applicant="applicantData"
      :calendarDateTime="selectedDate"
      :inPlaceAction="true"
    />
  </div>
</template>

<script>
import api from "../../Applications/_api";
import campusRecruitmentApi from "../../CampusRecruitment/_api/index";
import apiApplicantProfile from "../../ApplicantProfile/_api";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import Multiselect from "vue-multiselect";
import EventModal from "../../Applications/_components/event-modal.vue";
import CommonModal from "../../Common/_components/modal.vue";
import scheduleRoundModal from "../../Applications/_components/schedule-round-modal.vue";
import EditRoundModal from "../../ApplicantProfile/_components/edit-round-modal.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import CampusEventModal from "../../CampusRecruitment/_components/event-modal.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";

export default {
  props: {
    leftLength: {},
    rightLength: {},
  },
  components: {
    FullCalendar,
    Multiselect,
    EventModal,
    CommonModal,
    scheduleRoundModal,
    EditRoundModal,
    CampusEventModal,
    CollapseCard,
  },
  data: function () {
    return {
      isSmallScreen: false,
      myRounds: false,
      ovserver: null,
      users: [],
      selectedUsers: [],
      campusEvents: [],
      editEvent: null,
      selectedType: "",
      calendarOptions: {
        height: this.isFullHeight().height,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridWeek,dayGridDay",
        },
        buttonText: {
          list: "table",
        },
        initialView: window.innerWidth > 576 ? "dayGridWeek" : "dayGridDay",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        select: this.handleDateSelect,
        eventSources: [
          {
            events: this.fetchEvents,
          },
        ],
        lazyFetching: false,
      },
      claimedByMe: false,
      pendingEvents: [],
      pendingCampusEvents: [],
      allEvents: [],
      eventModalData: {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      },
      applicantData: null,
      selectedDate: null,
      isCalendarView: true,
      editEventApplicantData: null,
      currentDate: new Date(),
      rowSelection: null,
      columnDefs: [
        {
          headerName: "Round",
          field: "roundName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Candidate",
          field: "title",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Status",
          field: "status",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Position",
          field: "position",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Time",
          field: "start",
          valueFormatter: this.formatDate,
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            comparator: function (filterDate, cellValue) {
              const formattedDate = moment(cellValue).format("DD/MM/YY");
              const filterText = moment(filterDate).format("DD/MM/YY");
              return formattedDate.includes(filterText) ? 0 : 1;
            },
          },
        },
        {
          headerName: "Interviewer/Reviewer",
          field: "interviewers",
          filter: "agTextColumnFilter",
        },
      ],
    };
  },
  created() {
    this.rowSelection = "single";
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      applicant_Profile_Data: "$_applicant_profile/fetchApplicantData",
      applicantRoundsData: "$_applicant_profile/fetchRoundDetails",
      confirmRoundScheduleStatus:
        "$_applicants_data/fetchConfirmRoundScheduleStatus",
      loggedInUser: "user/loggedInUser",
    }),
  },
  methods: {
    isFullHeight() {
      const screenSize = window.innerWidth;
      const isSmallScreen = screenSize < 992;
      if (isSmallScreen) {
        let height = "480px";
        let fullHeight = this.rightLength === 0 && this.leftLength === 1;
        if (fullHeight) {
          if (screenSize >= 360) {
            height = "calc(100vh - 225px)";
          }
          if (screenSize < 360) {
            height = "calc(100vh - 250px)";
          }
          if (screenSize < 320) {
            height = "calc(100vh - 260px)";
          }
        }
        return {
          fullHeight,
          marginBottom: fullHeight,
          collapse: !fullHeight,
          height,
        };
      } else {
        let fullHeight = this.leftLength === 0 && this.rightLength === 1;
        return {
          fullHeight,
          marginBottom: fullHeight || this.rightLength === 1,
          collapse: !(fullHeight || this.rightLength === 1),
          height:
            fullHeight || this.rightLength === 1
              ? "calc(100vh - 225px)"
              : "480px",
        };
      }
    },
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth < 768; // Example breakpoint for small screens
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    closeEventModal() {
      this.$refs["campusEventModal"].hide();
    },
    checkLastDateOver(last_date) {
      const currentDate = moment();
      return moment(last_date).isBefore(currentDate);
    },
    extractTime(value) {
      return moment(value).format("h:mm A");
    },
    scheduleDateValid(date) {
      return moment(date).isAfter(new Date());
    },
    getStatusAbbreviation(status) {
      let abbreviation = "";
      if (status === "Pending") {
        abbreviation = "PND";
      } else if (status === "Completed") {
        abbreviation = "CPD";
      } else if (status === "Cancelled") {
        abbreviation = "CND";
      }
      return abbreviation;
    },
    /**
     * @memberof module:CampusRecruitment.components.campus_schedule
     * @description handle click event and open edit event modal
     */
    handleCampusEventClick(clickInfo) {
      this.editEvent = null;
      const foundEvent = this.campusEvents.find((event) => {
        return event.id == clickInfo.event.id;
      });
      this.editEvent = foundEvent;
      this.$store.dispatch("$_campus_recruitment/resetSelectedCampus");
      const filter = "?campusId=" + clickInfo.event.extendedProps.campusId;
      campusRecruitmentApi
        .fetchCampuses(filter)
        .then((res) => {
          this.$store.dispatch(
            "$_campus_recruitment/setSelectedCampus",
            res.data[0]
          );
          this.eventModalData = {
            modalTitle: "Edit Event",
            modalSize: "xl",
            hideFooter: true,
            isEdit: true,
          };
          this.$refs["campusEventModal"].show();
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem retrieving data!",
            },
            { root: true }
          );
        });
    },
    toggleView() {
      this.isCalendarView = !this.isCalendarView;
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDayMaxEventsToggle() {
      this.calendarOptions.dayMaxEvents = !this.calendarOptions.dayMaxEvents; // update a property
    },
    toggleClaimedByMe() {
      this.claimedByMe = !this.claimedByMe;
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },
    toggleMyRounds() {
      this.myRounds = !this.myRounds;
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },
    handleDateSelect(selectInfo) {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.eventModalData = {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      };
      const selectDate = new Date(selectInfo.startStr).setHours(0, 0, 0, 0);
      const currentDate = new Date().setHours(0, 0, 0, 0);
      if (selectDate < currentDate) {
        // show invalid modal
        this.openDialog(
          "invalidEventModal",
          "Invalid Input",
          "Please select a future date & time for the event!",
          false,
          "danger",
          "modal-md"
        );
        return;
      }
      this.selectedDate = selectInfo;
      this.$refs["eventModal"].show();
    },

    tabularCreateEvent() {
      this.eventModalData = {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      };
      this.$refs["eventModal"].show();
    },
    tabularEventClick(data) {
      if (data.node.data.status !== "Pending") {
        return;
      }

      this.eventModalData = {
        modalTitle: "Edit Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };

      apiApplicantProfile
        .fetchApplicantData(data.node.data.slug)
        .then((response) => {
          this.editEventApplicantData = response.data;
        });
      this.$refs["eventModal"].show();
    },
    handleEventClick(clickInfo) {
      if (clickInfo.event.extendedProps.status !== "Pending") {
        return;
      }

      this.eventModalData = {
        modalTitle: "Edit Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };

      apiApplicantProfile
        .fetchApplicantData(clickInfo.event.extendedProps.slug)
        .then((response) => {
          this.editEventApplicantData = response.data;
        });
      this.$refs["eventModal"].show();
    },
    generateRound(applicant) {
      this.$refs["eventModal"].hide();
      this.applicantData = applicant;
      this.selectedDate = $("#addRound").modal("show");
    },
    updateEvent(applicant) {
      this.$refs["eventModal"].hide();
      this.applicantData = applicant;
      this.selectedDate = $("#editRound").modal("show");
    },
    formatDate(date) {
      if (date.value === null) return null;
      else if (date.value) return moment(date.value).format("llll");
      else return moment(date).format("llll");
    },
    formatCampusDate(dateString) {
      return moment(dateString).format("DD/MMM/YY");
    },
    fetchEvents(config, successCallback, failureCallback) {
      let filters = {
        startDate: config.start,
        endDate: config.end,
        round: this.selectedType,
        myRounds: this.myRounds,
        ...(this.claimedByMe ? { claimedBy: this.loggedInUser.user_id } : {}),
      };

      this.allEvents = [];
      this.pendingEvents = [];
      this.users = [];

      api
        .fetchAllSchedule(filters)
        .then((response) => {
          let events = response.data.applicantRounds;
          let interviewerId = [];
          events.forEach((event) => {
            let userIds = [];
            let userNames = [];
            event.interviewers.forEach((user) => {
              if (user.interviewer_name) {
                const userExists = interviewerId.some(
                  (userId) => userId === user.interviewer_id
                );
                if (!userExists) {
                  this.users.push({
                    id: user.interviewer_id,
                    name: user.interviewer_name,
                  });
                  interviewerId.push(user.interviewer_id);
                }
                userIds.push(user.interviewer_id);
                userNames.push(user.interviewer_name);
              }
            });
            event.reviewers.forEach((user) => {
              if (user.reviewer_name) {
                const userExists = interviewerId.some(
                  (userId) => userId === user.reviewer_id
                );
                if (!userExists) {
                  this.users.push({
                    id: user.reviewer_id,
                    name: user.reviewer_name,
                  });
                  interviewerId.push(user.reviewer_id);
                }
                userIds.push(user.reviewer_id);
                userNames.push(user.reviewer_name);
              }
            });

            let calEvent = {
              eventType: "applicantEvent",
              id: event.id,
              slug: event.applicantIterationMaster.slug,
              title:
                event.applicantIterationMaster.first_name +
                " " +
                event.applicantIterationMaster.last_name,
              withUsers: this.getWithUsers(
                event.round_name,
                event.round_result,
                userNames.toString(),
                event.applicantIterationMaster.position
              ),
              interviewers: userNames.toString(),
              start: event.round_schedule,
              status: event.round_result,
              position: event.applicantIterationMaster.position,
              users: userIds,
              round: this.getTitleAbbreviation(event.round_name),
              roundName: event.round_name,
              allDay: false,
              backgroundColor: this.getEventColor(event.round_result),
              borderColor: this.getEventColor(event.round_result),
            };

            if (event.round_result === "Pending") {
              this.pendingEvents.push(calEvent);
            }

            this.transformUsers();

            if (this.selectedUsers && this.selectedUsers.length > 0) {
              if (calEvent.users.length > 0) {
                this.selectedUsers.every((selectedUser) => {
                  let result = calEvent.users.find(
                    (id) => id === selectedUser.id
                  );
                  if (result) {
                    this.allEvents.push(calEvent);
                    return false;
                  } else {
                    return true;
                  }
                });
              }
            } else {
              this.allEvents.push(calEvent);
            }
          });

          // campus event
          let calendarApi = this.$refs.calendar.getApi();
          let campusEvents = [];
          response.data.campusRounds.forEach((event) => {
            let userNames = [];
            event.interviewers.forEach((user) => {
              if (user.interviewer_name) {
                const userExists = interviewerId.some(
                  (userId) => userId == user.interviewer_id
                );
                if (!userExists) {
                  this.users.push({
                    id: user.interviewer_id,
                    name: user.interviewer_name,
                  });
                  interviewerId.push(user.interviewer_id);
                }
                userNames.push(user.interviewer_name);
              }
            });
            let eventData = {
              ...event,
              eventType: "campusEvent",
              title:
                event.campusDetail.recruitment_session_name +
                " - " +
                event.round_title,
              start: event.round_schedule,
              end: event.round_schedule_end,
              round: this.getTitleAbbreviation(event.round_name),
              interviewers: userNames.join(", "),
              withUsers: this.getCampusWithUsers(
                event.round_title,
                event.round_name,
                event.status,
                event.campusDetail.recruitment_session_name,
                userNames.join(", ")
              ),
              roundName: event.round_name,
              backgroundColor: this.getCampusEventColor(
                event.status,
                event.round_schedule_end
              ),
              borderColor: this.getCampusEventColor(
                event.status,
                event.round_schedule_end
              ),
              allDay: false,
            };
            const interviewIds = event.slotsInvolved.flatMap(
              (event) => event.interviewerId
            );
            if (
              !this.selectedUsers ||
              !this.selectedUsers.length ||
              this.hasCommonElement(
                interviewIds,
                this.selectedUsers.map((user) => user.id)
              )
            ) {
              if (calendarApi && calendarApi.view.type != "dayGridWeek") {
                campusEvents.push({
                  ...eventData,
                  startRecur: moment(new Date(event.round_schedule)).format(
                    "YYYY-MM-DD"
                  ),
                  endRecur: moment(new Date(event.round_schedule_end))
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                  startTime: moment(new Date(event.round_schedule)).format(
                    "HH:mm"
                  ),
                  endTime: moment(new Date(event.round_schedule_end)).format(
                    "HH:mm"
                  ),
                  backgroundColor: this.getCampusEventColor(
                    event.status,
                    event.round_schedule_end
                  ),
                  borderColor: this.getCampusEventColor(
                    event.status,
                    event.round_schedule_end
                  ),
                });
              } else {
                campusEvents.push({
                  ...eventData,
                  allDay: moment(event.round_schedule).isSame(
                    event.round_schedule_end,
                    "day"
                  ),
                });
              }
            }
          });
          this.campusEvents = campusEvents;
          this.pendingCampusEvents = campusEvents.filter((event) => {
            return (
              event.status == "Pending" &&
              !this.checkLastDateOver(event.round_schedule_end)
            );
          });
          this.allEvents = [...this.allEvents, ...campusEvents];
          successCallback(this.allEvents);
        })
        .catch(() => {
          failureCallback([]);
        });
    },

    hasCommonElement(arrayA, arrayB) {
      for (let item of arrayA) {
        if (arrayB.includes(item)) return true;
      }
      return false;
    },

    filterEvents() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },
    getTitleAbbreviation(roundName) {
      let abbreviation = "I";
      if (roundName === "Review") {
        abbreviation = "R";
      } else if (roundName === "Aptitude") {
        abbreviation = "A";
      } else if (roundName === "Written Test") {
        abbreviation = "W";
      }
      return abbreviation;
    },
    getWithUsers(roundName, roundStatus, withUsers, position) {
      let message = "";
      if (roundName === "Review") {
        message =
          "Reviewer/s: " +
          withUsers.toString() +
          " \nStatus: " +
          roundStatus +
          " \nPosition: " +
          position;
      } else if (roundName === "Interview") {
        message =
          "Interviewer/s: " +
          withUsers.toString() +
          " \nStatus: " +
          roundStatus +
          " \nPosition: " +
          position;
      } else {
        message = "Status: " + roundStatus + "\nPosition: " + position;
      }

      return message;
    },
    getCampusWithUsers(
      eventTitle,
      eventName,
      eventStatus,
      recruitmentName,
      interviewers
    ) {
      let message = "";
      if (eventName === "Written Test") {
        message =
          "Title: " +
          eventTitle +
          " \nType: " +
          eventName +
          " \nRecruitmentName: " +
          recruitmentName +
          "\nScheduler/s: " +
          interviewers.toString() +
          " \nStatus: " +
          eventStatus;
      } else if (eventName === "Interview") {
        message =
          "Title: " +
          eventTitle +
          " \nType: " +
          eventName +
          " \nRecruitmentName: " +
          recruitmentName +
          "\nInterviewer/s: " +
          interviewers.toString() +
          " \nStatus: " +
          eventStatus;
      } else {
        message = "Status: " + eventStatus;
      }

      return message;
    },
    getCampusEventColor(roundStatus, end_time) {
      let color = "line";
      if (roundStatus === "Cancelled") {
        color = "#FEE6AF";
      } else if (
        roundStatus === "Pending" &&
        end_time &&
        !this.scheduleDateValid(end_time)
      ) {
        color = "grey";
      } else if (roundStatus === "Pending") {
        color = "green";
      }
      return color;
    },
    getEventColor(roundStatus) {
      let color = "green";
      if (roundStatus === "Failed" || roundStatus === "Differed") {
        color = "orange";
      } else if (roundStatus === "Cancelled") {
        color = "grey";
      } else if (roundStatus === "Pending") {
        color = "lime";
      }
      return color;
    },
    transformUsers() {
      this.users = this.users.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      this.users.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    onResize() {
      if (this.$refs.calendar) {
        let calendarApi = this.$refs.calendar.getApi();
        calendarApi.updateSize();
      }
    },
    navigateMonth(traverseTo) {
      function getNextMonthLastDate(timestamp) {
        const date = new Date(timestamp);
        date.setMonth(date.getMonth() + 2);
        date.setDate(0);
        return date;
      }
      function getLastDateOfPreviousMonth(timestamp) {
        const currentDate = new Date(timestamp);
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const firstDayOfCurrentMonth = new Date(year, month, 1);
        const lastDayOfPreviousMonth = new Date(
          firstDayOfCurrentMonth.getTime() - 1
        );
        return lastDayOfPreviousMonth;
      }
      if (traverseTo === "next") {
        const config = {
          start: new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth() + 1,
            1
          ),
          end: getNextMonthLastDate(this.currentDate),
        };
        this.fetchEvents(
          config,
          () => {},
          () => {}
        );
        this.currentDate = config.start;
      }
      if (traverseTo === "previous") {
        const config = {
          start: new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth() - 1,
            1
          ),
          end: getLastDateOfPreviousMonth(this.currentDate),
        };
        this.fetchEvents(
          config,
          () => {},
          () => {}
        );
        this.currentDate = config.end;
      }
    },
    getMoodleInstances() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"]["MOODLE"].MENU],
        })
      )
        return;
      this.$store.dispatch("$_applicant_profile/getMoodleInstanceList");
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantsData.components.applicant_schedule
     * @param {String} value
     * @description Checks whether round has been added successfully or not then call filter event
     */
    confirmRoundScheduleStatus(value) {
      if (value === "SUCCESS") {
        this.filterEvents(null, null);
      }
    },
  },
  mounted() {
    this.ovserver = new ResizeObserver(this.onResize);
    this.ovserver.observe(this.$refs.calendarParent);
    this.getMoodleInstances();
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },

  beforeDestroy() {
    this.ovserver.unobserve(this.$refs.calendarParent);
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>
<style scoped>
.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  /* position: relative; */
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-type-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: gray;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-side-div {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
.applicant-schedule {
  width: 100%;
  padding: 20px;
}
.applicant-schedule-sidebar {
  width: 100%;
  height: 77vh;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
}

.applicant-schedule-sidebar-section {
  padding: 5px;
}
.title-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .fc-event {
  margin-top: 15px;
}
::v-deep .fc table thead a {
  font-weight: normal;
}
.card_footer a {
  color: #903564;
  /* text-transform: uppercase; */
  font-weight: 600;
  text-decoration: none;
}
.filter-pending {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(172, 172, 172, 0.486);
}
.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}
</style>
