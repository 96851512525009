<template>
  <div class="content">
    <div class="container-fluid">
      <div v-if="applicantData">
        <h1>
          Profile - {{ applicantData.first_name }}
          {{ applicantData.last_name }} &nbsp;<router-link
            target="_blank"
            :to="'/recruiter/applicants/' + applicantData.slug + '/update'"
            ><i
              v-if="canEdit"
              title="Edit Applicant Profile"
              class="fa fa-edit"
              aria-hidden="true"
              style="color: #832e5b"
            ></i
          ></router-link>
        </h1>
        <div v-if="applicantData">
          <div class="well container section-head mx-auto">
            <div class="row mx-0">
              <div class="col-md-5">
                Applied {{ appliedAt }} ({{
                  applicantData.createdAt | dateFormat
                }})
              </div>
              <div class="col-md-7 text-right">
                <span>
                  {{ applicantData.application_status }}
                </span>
              </div>
            </div>
          </div>
          <div class="container p-0">
            <div class="row mx-0">
              <div class="col-md-12 px-0">
                <div class="well container">
                  <table
                    class="table table-condensed table-compressed"
                    aria-label="Applicant Details"
                  >
                    <thead>
                      <th colspan="10">
                        <h4>
                          <strong>Application Details</strong>
                        </h4>
                      </th>
                    </thead>
                    <tbody v-if="applicantData">
                      <tr>
                        <td class="col-md-2">
                          <strong>Last Updated On</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.updatedAt | dateTimeFormat }}
                        </td>
                        <td class="col-md-2">
                          <strong>Created On</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.createdAt | dateTimeFormat }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Applied for Position</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.position }}</td>
                        <td class="col-md-2">
                          <strong>Application Status</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.application_status }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12 px-0">
                <div class="well container">
                  <table
                    class="table table-condensed table-compressed"
                    aria-label="Personal Details"
                  >
                    <thead>
                      <th colspan="10">
                        <h4>
                          <strong>Personal Details</strong>
                        </h4>
                      </th>
                    </thead>
                    <tbody v-if="applicantData">
                      <tr v-if="applicantData.belongsToCampusRecruitement">
                        <td class="col-md-2">
                          <strong>College Name</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.college_name }}
                        </td>
                        <td class="col-md-2">
                          <strong>Recruitment Session Name</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantData.belongsToCampusRecruitement
                              .recruitment_session_name
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>First Name</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.first_name }}</td>
                        <td class="col-md-2">
                          <strong>Last Name</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.last_name }}</td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Email</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.email }}</td>
                        <td class="col-md-2">
                          <strong>Mobile No.</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.mobile_no }}</td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Date of Birth</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.dob | dateFormat }}
                        </td>
                        <td class="col-md-2">
                          <strong>Gender</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.gender }}</td>
                      </tr>
                      <tr></tr>
                      <tr v-if="applicantData.address_line_1">
                        <td class="col-md-4">
                          <strong>Permanent Address</strong>
                        </td>
                        <td>
                          {{ applicantData.address_line_1 }},
                          {{ applicantData.address_line_2 }},
                          {{ applicantData.city }},
                          {{ applicantData.state }}
                        </td>
                      </tr>
                      <tr v-if="applicantData.current_address_line_1">
                        <td class="col-md-4">
                          <strong>Current Address</strong>
                        </td>
                        <td>
                          {{ applicantData.current_address_line_1 }},
                          {{ applicantData.current_address_line_2 }},
                          {{ applicantData.current_city }},
                          {{ applicantData.current_state }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="col-md-12 px-0"
                v-if="
                  applicantData.belongsToCampusRecruitement &&
                  applicantData.education_details
                "
              >
                <div class="well container">
                  <h4>
                    <strong>Education Details</strong>
                  </h4>
                  <table
                    class="table table-condensed table-compressed"
                    aria-label="Education Details"
                    aria-hidden="true"
                  >
                    <tbody>
                      <tr>
                        <td class="col-md-2">
                          <strong>Degree</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.education_details.degree }}
                        </td>
                        <td class="col-md-2">
                          <strong>Stream</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.education_details.stream }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>SSC Percentage</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.education_details.ssc_percentage }} %
                        </td>

                        <td class="col-md-2">
                          <strong>HSC Percentage</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.education_details.hsc_percentage }} %
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Graduation Percentage</strong>
                        </td>
                        <td class="col-md-4">
                          <span
                            v-if="
                              applicantData.education_details.grad_percentage
                            "
                          >
                            {{
                              applicantData.education_details.grad_percentage
                            }}
                            %
                          </span>
                          <span v-else>N/A</span>
                        </td>
                        <td class="col-md-2">
                          <strong>Aggregate Percentage</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantData.education_details.aggregate_percentage
                          }}
                          %
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Semester 1 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantData.education_details.semester1 || "N/A"
                          }}
                        </td>
                        <td class="col-md-2">
                          <strong>Semester 2 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantData.education_details.semester2 || "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Semester 3 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.education_details.semester3 }}
                        </td>
                        <td class="col-md-2">
                          <strong>Semester 4 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.education_details.semester4 }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Semester 5 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.education_details.semester5 }}
                        </td>
                        <td class="col-md-2">
                          <strong>Semester 6 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.education_details.semester6 }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Semester 7 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantData.education_details.semester7 || "N/A"
                          }}
                        </td>
                        <td class="col-md-2">
                          <strong>Semester 8 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantData.education_details.semester8 || "N/A"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12 px-0">
                <div class="well container">
                  <h4 class="border-bottom pb-2">
                    <strong>Status TIMELINE</strong>
                  </h4>
                  <div class="py-2">
                    <timeline class="mx-0">
                      <timeline-title>Applicant Status TIMELINE</timeline-title>
                      <timeline-item
                        bg-color="#9dd8e0"
                        v-for="(event, index) in applicantRounds"
                        :key="index"
                      >
                        <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center my-1">
                            <div
                              v-if="
                                event.eventSlot &&
                                event.eventSlot.belongToCampusEvents
                              "
                              class="mx-2"
                            >
                              {{
                                event.eventSlot.belongToCampusEvents
                                  .round_title
                              }}&nbsp; &nbsp;-
                            </div>
                            <div class="mx-2 text-secondary format-date">
                              {{ event.round_name }}
                            </div>
                            -
                            <div
                              class="mx-2 px-2 rounded"
                              :class="[getColor(event.round_result)]"
                            >
                              {{ event.round_result }}
                            </div>
                          </div>
                          <div
                            class="d-flex text-secondary my-1"
                            v-if="event.eventSlot"
                          >
                            <div class="mx-2 format-date">
                              {{ formatDate(event.eventSlot.from_time) }}
                            </div>
                            -
                            <div class="mx-2 format-date">
                              {{ formatDate(event.eventSlot.to_time) }}
                            </div>
                          </div>
                          <div v-else>
                            <div class="mx-2 format-date">
                              {{ formatDate(event.round_schedule) }}
                            </div>
                          </div>
                        </div>
                      </timeline-item>
                      <timeline-item :hollow="true">
                        <div class="d-flex justify-content-between">
                          <div>Applied At</div>
                          <div class="mx-2 format-date text-secondary">
                            {{ formatDate(applicantData.createdAt) }}
                          </div>
                        </div>
                      </timeline-item>
                    </timeline>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="apiCallCompleted">
        <p class="text-danger text-center mt-5">No applicant found!</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../_api/index";
import { Timeline, TimelineItem, TimelineTitle } from "vue-cute-timeline";
import "vue-cute-timeline/dist/index.css";
import moment from "moment";
export default {
  data() {
    return {
      slugId: null,
      formData: null,
      applicantData: null,
      apiCallCompleted: false,
    };
  },
  computed: {
    applicantRounds() {
      return this.applicantData.roundData;
    },
    appliedAt() {
      let applicationDate = new Date(this.applicantData.createdAt);
      return moment([
        applicationDate.getFullYear(),
        applicationDate.getMonth(),
        applicationDate.getDate(),
        applicationDate.getHours(),
        applicationDate.getMinutes(),
        applicationDate.getSeconds(),
      ]).fromNow();
    },
  },
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle,
  },
  created() {
    this.slugId = this.$route.params.slug;
    api
      .getApplicantDetailsBySlug(this.$route.params.slug)
      .then((res) => {
        this.applicantData = res.data;
        this.apiCallCompleted = true;
      })
      .catch(() => {
        this.apiCallCompleted = true;
      });
  },
  methods: {
    canEdit() {
      const currentDate = moment();
      return (
        !this.applicantData.belongsToCampusRecruitement.status == "Completed" &&
        !moment(
          this.applicantData.belongsToCampusRecruitement.last_date
        ).isBefore(currentDate)
      );
    },
    getColor(status) {
      const dangerRegex = /Cancelled|Failed|Differed/i;
      const warningRegex = /Pending/i;
      const successRegex = /Passed/i;
      if (dangerRegex.test(status)) {
        return "bg-danger";
      } else if (warningRegex.test(status)) {
        return "bg-warning";
      } else if (successRegex.test(status)) {
        return "bg-success";
      } else {
        return "bg-secondary";
      }
    },

    /**
     * @memberof module:Recruiter.view-recruitment-form
     * @description format date
     */
    formatDate(date) {
      return moment(date).format("MMM Do YYYY, h:mm a");
    },
  },
};
</script>

<style lang="css" scoped>
h4 {
  padding-top: 5px;
}

.format-date {
  font-size: 14px;
}
</style>
