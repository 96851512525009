import { addSnackbarMessage } from "../../Common/_plugin/error-response";
import api from "../_api";

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description get data of Job categories and Jobs
 */
const fetchCategoriesWithJobs = (context) => {
  api
    .fetchCategoriesWithJobs()
    .then((response) => {
      const data = response.data.categories;

      data.forEach((category) => {
        category.jobs = [];
      });

      response.data.jobs.forEach((job) => {
        const categoryIndex = data.findIndex(
          (category) => category.id === job.categoryId
        );
        data[categoryIndex].jobs.push(job);
      });

      context.commit("fetchCategoriesWithJobs", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description get data of Job categories and Jobs
 */
const fetchPositionsWithJob = (context, jobId) => {
  api
    .fetchPositionsWithJob(jobId)
    .then((response) => {
      const data = response.data;
      context.commit("fetchPositionsWithJob", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description add data of Job
 */
const addJob = (context, job) => {
  api
    .addJob(job)
    .then((response) => {
      context.commit("addJob", response.data);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Job successfully added!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem adding job!", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description add data of Job category
 */
const addCategory = (context, category) => {
  api
    .addCategory(category)
    .then((response) => {
      context.commit("addCategory", response.data);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Category successfully added!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem adding category!", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description get data of Job category to add Job
 */
const setAddJobModalData = (context, categoryData) => {
  context.commit("ADD_JOB_MODAL_OPENED", categoryData);
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description delete data of Job category and Jobs
 */
const deleteItem = (context, itemDeleteData) => {
  context.commit("DELETE_ITEM_MODAL", itemDeleteData);
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description delete data of Job category
 */
const deleteCategory = (context, categoryId) => {
  api
    .deleteCategory(categoryId)
    .then(() => {
      context.commit("DELETE_CATEGORY", categoryId);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Category successfully deleted!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "problem deleting category!", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description delete data of Job category
 */
const deleteJobPositions = (context, positionData) => {
  api
    .deleteJobPositions(positionData.id)
    .then(() => {
      context.dispatch("fetchPositionsWithJob", positionData.jobId);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Position successfully deleted!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem deleting position!", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description delete data of Job
 */
const deleteJob = (context, deleteJobData) => {
  api
    .deleteJob(deleteJobData.jobId)
    .then(() => {
      context.commit("DELETE_JOB", deleteJobData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Job successfully deleted!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem deleting job!", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description get id of Job
 */
const editJobModal = (context, editJobData) => {
  context.commit("EDIT_JOB_MODAL", editJobData);
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description get data of Job to edit
 */
const openPositionsModal = (context, jobData) => {
  context.commit("OPEN_POSITIONS_MODAL", jobData);
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description edit data of Job
 */
const editJob = (context, editJobData) => {
  api
    .editJob(editJobData.objectInfo)
    .then(() => {
      context.commit("EDIT_JOB", editJobData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Job successfully updated!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem editing job!", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description get data of Job category to edit
 */
const editCategoryModal = (context, editCategoryData) => {
  context.commit("EDIT_CATEGORY_MODAL", editCategoryData);
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description get data of Job category to edit with status
 */
const updateCategoryWithJobStatus = (context, editedData) => {
  api
    .updateCategoryWithJobStatus(editedData)
    .then(() => {
      context.commit("UPDATE_CATEGORY_WITH_JOBS_STATUS", editedData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Category Status updated",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem updating Category status", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description get data of Job category to edit with status
 */
const editJobStatus = (context, editedJobData) => {
  api
    .editJobStatus(editedJobData)
    .then(() => {
      context.commit("UPDATE_JOB_STATUS", editedJobData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Job Status updated",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem updating Job Status", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description edit data of Job category
 */
const editCategory = (context, editCategoryData) => {
  api
    .editCategory(editCategoryData)
    .then(() => {
      context.commit("EDIT_CATEGORY", editCategoryData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Category successfully updated!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem editing category!", true);
    });
};

/**
 * @memberOf module:ManageJob.store.actions
 * @param {Object} context store context
 * @description add data of Open Positions
 */
const addPositions = (context, positionData) => {
  api
    .addPositions(positionData)
    .then(() => {
      context.dispatch("fetchPositionsWithJob", positionData.jobId);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Positions successfully added!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem adding open positions!", true);
    });
};

/**
 * @memberOf module:ManageJob
 * @namespace store.actions
 * @description actions for manage job module
 */
export default {
  fetchCategoriesWithJobs,
  fetchPositionsWithJob,
  addJob,
  addCategory,
  addPositions,
  setAddJobModalData,
  deleteItem,
  deleteCategory,
  deleteJobPositions,
  deleteJob,
  editJobModal,
  openPositionsModal,
  editJob,
  editCategoryModal,
  editCategory,
  updateCategoryWithJobStatus,
  editJobStatus,
};
