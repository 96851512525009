<template>
  <div class="content">
    <common-header :data="['home', 'manage', 'templates']">
      <div class="d-flex flex-row justify-content-between">
        <input
          v-model="searchForm"
          class="form-control"
          placeholder="Search"
          style="max-width: 360px"
        />
        <button
          v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
          class="btn btn-cp ml-2"
          @click="openAddForm"
        >
          Add
        </button>
      </div>
    </common-header>
    <div class="container-fluid">
      <div
        v-if="addForm"
        class="d-flex flex-row justify-content-between shadow-sm p-2 rounded-2 hover mb-4"
      >
        <!-- Input / Title -->
        <div class="flex-grow-1 cursor-pointer">
          <input
            id="addFormTitleInp"
            class="form-control"
            placeholder="Title"
            v-model="addFormTitle"
          />
          <small v-if="addFormTitle.length < 2" class="text-danger"
            >* Form title is required</small
          >
        </div>
        <!-- Action Buttons -->
        <div>
          <button class="btn px-1" v-b-tooltip="'Save'" @click="onAddForm">
            <b-icon icon="check-circle" variant="success"></b-icon>
          </button>
          <span class="px-1">|</span>
          <button
            class="btn px-1"
            v-b-tooltip="'Cancel'"
            @click="onAddFormCancel"
          >
            <b-icon icon="x-circle" variant="danger"></b-icon>
          </button>
        </div>
      </div>
      <div v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })">
        <FormMasterItem
          v-for="form in masterForms"
          :form="form"
          :key="form.id"
          class="mb-2"
          @deleteForm="openConfirmDeleteModal(form)"
          @click.native="fetchFormVersion(form.id)"
        />
      </div>
    </div>
    <b-modal ref="confirm-delete-modal" title="Delete Form?">
      <p class="my-4">
        Are you sure you want to delete "<i
          ><strong>{{ confirmModalData.formTitle }}</strong></i
        >" form ?
      </p>
      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="$refs['confirm-delete-modal'].hide()"
        >
          Close
        </button>
        <button class="btn btn-danger" @click="deleteForm">Delete</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import FormMasterItem from "./form-master-item.vue";

// activate/deactivate form group

export default {
  components: {
    FormMasterItem,
  },
  data() {
    return {
      searchForm: "",
      addFormTitle: "",
      addForm: false,
      formTemplateInp: "",
      confirmModalData: {
        formTitle: "",
        id: 0,
      },
      selectedForm: -1,
    };
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    fetchFormVersion(id) {
      if (this.selectedForm == id) return;
      this.selectedForm = id;
      this.$store.dispatch("$_form_template/getAllFormVersions", id);
    },
    openAddForm() {
      this.addForm = true;
      this.$nextTick(() => {
        document.getElementById("addFormTitleInp").focus();
      });
    },
    onAddForm() {
      if (this.addFormTitle.length < 2) return;
      this.$store.dispatch("$_form_template/addFormMaster", {
        title: this.addFormTitle,
      });
      this.addForm = false;
      this.addFormTitle = "";
    },
    onAddFormCancel() {
      this.addForm = false;
      this.addFormTitle = "";
    },
    openConfirmDeleteModal(form) {
      this.confirmModalData.id = form.id;
      this.confirmModalData.formTitle = form.title;
      this.$refs["confirm-delete-modal"].show();
    },
    deleteForm() {
      this.$store.dispatch(
        "$_form_template/deleteFormMaster",
        this.confirmModalData.id
      );
      this.$refs["confirm-delete-modal"].hide();
    },
    getFormsTemplates() {
      if (!this.hasAccess({ permissions: [this.PERMISSIONS.FORM.MENU] }))
        return;
      this.$store.dispatch("$_form_template/getAllFormMasters");
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.FORM;
    },
    masterForms() {
      return this.$store.getters["$_form_template/getForms"].filter((form) =>
        form.title.toLowerCase().includes(this.searchForm.toLowerCase())
      );
    },
  },
  // shift to computed later
  beforeMount() {
    this.getFormsTemplates();
  },
};
</script>
<style scoped>
.action-group {
  font-size: 1.25rem;
}

.action-btn {
  border: none;
  background: transparent;
  cursor: pointer;
}

.active-icon {
  color: green;
}

.active-icon:hover {
  color: yellowgreen;
}

.inactive-icon {
  color: red;
}

.inactive-icon:hover {
  color: orange;
}

.view-btn {
  color: rgb(77, 224, 200);
}

.view-btn:hover {
  color: rgb(50, 155, 115);
  transition: all 100ms linear;
}

.edit-btn {
  color: rgb(77, 144, 221);
}

.edit-btn:hover {
  color: rgb(49, 74, 156);
  transition: all 100ms linear;
}

.cancel-btn {
  color: rgb(227, 60, 60);
}

.cancel-btn:hover {
  color: rgb(198, 15, 15);
  transition: all 100ms linear;
}

.container {
  max-width: 100%;
  padding: 10px 20px;
}

@media (max-width: 576px) {
  .container {
    padding: 0 10px;
  }
}
</style>
