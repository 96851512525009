import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  sessionReportList: [],
  sessionApplicationCampusCount: [],
  sessionApplicationEntityCount: [],
};

/**
 * @memberOf module:Report
 * @namespace store
 * @description local store for Report
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
