<template>
  <div class="content">
    <common-header :data="['home', 'report', 'sessionReports']">
      <div class="d-flex flex-wrap">
        <div class="session-dropdown mx-2" v-if="hasMenuAccess()">
          <select
            id="campuspicker"
            class=""
            data-live-search="true"
            data-width="100%"
            v-model="selectedReport"
            :data-dropdown-align-right="true"
          >
            <option
              v-for="(report, index) in sessionReportList"
              :value="report.id"
              :key="index"
            >
              {{ report.title }}
            </option>
          </select>
        </div>
        <div class="d-flex ml-auto my-1 my-sm-0">
          <button
            v-if="
              hasAccess({ permissions: [BASE_PERMISSION.UPDATE] }) &&
              selectedReport &&
              !fromDashboard
            "
            class="btn btn-cp mx-1"
            @click="openCreateModal(true)"
          >
            <i
              class="fa fa-pencil"
              aria-hidden="true"
              v-tooltip.bottom="'Edit Report'"
            ></i>
          </button>
          <button
            v-if="
              hasAccess({ permissions: [BASE_PERMISSION.DELETE] }) &&
              selectedReport &&
              !fromDashboard
            "
            class="btn btn-cp mx-1"
            @click="deleteReport"
          >
            <i
              class="fa fa-trash-o"
              aria-hidden="true"
              v-tooltip.bottom="'Delete Report'"
            ></i>
          </button>
          <button
            v-if="
              hasAccess({ permissions: [BASE_PERMISSION.CREATE] }) &&
              !fromDashboard
            "
            class="btn btn-cp ml-1"
            @click="openCreateModal(false)"
          >
            <i
              class="fa fa-plus"
              aria-hidden="true"
              v-tooltip.bottom="'Create Report'"
            ></i>
          </button>
        </div>
      </div>
    </common-header>
    <div class="container-fluid">
      <div v-if="sessionReportList && sessionReportList.length > 0">
        <div class="row mx-0" v-if="selectedReport && selectedReportData">
          <div class="col-md-12 p-0">
            <div class="well mt-0">
              <div class="table-responsive">
                <table
                  class="table table-condensed"
                  aria-label="Applicant Details"
                >
                  <thead>
                    <th colspan="10">
                      <h4 class="summary-header text-center p-2">
                        <strong>Summary</strong>
                        <span
                          >({{ selectedReportData?.start_date | dateFormat }} -
                          {{ selectedReportData.end_date | dateFormat }})</span
                        >
                      </h4>
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="col-md-2">
                        <strong>Applicants</strong>
                      </td>
                      <td class="col-md-2">
                        {{ selectedReportData.applicantCount }}
                      </td>
                      <td class="col-md-2">
                        <strong>Campuses</strong>
                      </td>
                      <td class="col-md-2">
                        {{ selectedReportData.campusCount }}
                      </td>
                      <td class="col-md-2">
                        <strong>Entities</strong>
                      </td>
                      <td class="col-md-2">
                        {{
                          selectedReportData.collegeCount.length +
                          selectedReportData.universityCount.length +
                          selectedReportData.recruiterCount.length
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-2">
                        <strong>Universities</strong>
                      </td>
                      <td class="col-md-2">
                        {{ selectedReportData.universityCount.length }}
                      </td>
                      <td class="col-md-2">
                        <strong>Colleges</strong>
                      </td>
                      <td class="col-md-2">
                        {{ selectedReportData.collegeCount.length }}
                      </td>
                      <td class="col-md-2">
                        <strong>Recruiters</strong>
                      </td>
                      <td class="col-md-2">
                        {{ selectedReportData.recruiterCount.length }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-2">
                        <strong>Shortlisted</strong>
                      </td>
                      <td class="col-md-2">
                        {{
                          selectedReportData.shortlistedApplicantCount +
                          selectedReportData.joinedApplicantCount
                        }}
                      </td>
                      <td class="col-md-2">
                        <strong>Joined</strong>
                      </td>
                      <td class="col-md-2">
                        {{ selectedReportData.joinedApplicantCount }}
                      </td>
                      <td class="col-md-2">
                        <strong>Differed/Failed</strong>
                      </td>
                      <td class="col-md-2">
                        {{ selectedReportData.failedApplicantCount }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <CollapseCard
          class="theme-default"
          title="View Analytics"
          :midDivider="false"
          header-class="px-0 py-0"
          :collapse="true"
          title-class="h4 m-0"
        >
          <div>
            <div class="row m-0">
              <div class="col-md-6 col-xs-6 border-md-right">
                <common-chart
                  chartId="campus-chart"
                  chartTitle="Campus-wise"
                  :colorIdx="0"
                  :dataSlices="campusCountSlices"
                  v-on:filter-data="filterCampus($event)"
                >
                  <div class="filter-text" v-if="selectedCampusName">
                    <span v-if="selectedCampusName"
                      ><em class="fa fa-filter"></em> Campus Name :
                      {{ selectedCampusName }}</span
                    ><br />
                  </div>
                </common-chart>
              </div>
              <div class="col-md-6 col-xs-6">
                <common-chart
                  chartId="entity-chart"
                  chartTitle="Entity-wise"
                  :colorIdx="2"
                  :dataSlices="entityCountSlices"
                  v-on:filter-data="filterEntity($event)"
                  ><div class="filter-text" v-if="selectedCampusName">
                    <em class="fa fa-filter"></em> Campus name :
                    {{ selectedCampusName }}
                  </div>
                </common-chart>
              </div>
            </div>
            <div
              class="row my-2 mx-0"
              v-if="selectedReport && selectedReportData"
            >
              <div class="col-md-12">
                <div class="well">
                  <div class="table-responsive">
                    <table
                      class="table table-condensed"
                      aria-label="Applicant Details"
                    >
                      <thead>
                        <th colspan="10">
                          <h4 class="text-center p-2">
                            <strong>Campus Counts</strong>
                          </h4>
                        </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="col-md-2">
                            <strong>Shortlisted</strong>
                          </td>
                          <td class="col-md-2">
                            <!-- {{ selectedReportData.shortlistedApplicantCount + selectedReportData.joinedApplicantCount }} -->
                            {{ selectedEntityCounts.shortlisted }}
                          </td>
                          <td class="col-md-2">
                            <strong>Joined</strong>
                          </td>
                          <td class="col-md-2">
                            {{ selectedEntityCounts.joined }}
                          </td>
                          <td class="col-md-2">
                            <strong>Differed/Failed</strong>
                          </td>
                          <td class="col-md-2">
                            {{ selectedEntityCounts.failed }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CollapseCard>
        <div>
          <div class="pt-3">
            <div>
              <div class="row mx-0">
                <div
                  style="width: 100%"
                  class="d-flex justify-content-between flex-wrap"
                >
                  <div class="d-none d-md-flex my-1">
                    <div class="btn-group dropup float-left">
                      <button
                        class="btn dropdown-toggle select-cp mr-1"
                        type="button"
                        data-toggle="dropdown"
                        id="dropdownMenuButtonli"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-tasks" aria-hidden="true"></i>
                      </button>
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButtonli"
                      >
                        <li class="dropdown-header">Download</li>
                        <li class="dropdown-item">
                          <a @click="downloadData">Applicant Data</a>
                        </li>
                        <li class="divider"></li>

                        <li class="dropdown-header">Actions</li>
                        <li class="dropdown-item">
                          <a @click="changeApplicationStatus('DIFFER')"
                            >Differ Applicant(s) and Mail</a
                          >
                        </li>
                        <li class="dropdown-item">
                          <a @click="changeApplicationStatus('JOINED')"
                            >Join Applicant(s) and Mail</a
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="status-pagesize mx-1">
                      <select
                        v-model="filterStatus"
                        id="filter-status"
                        class="select-cp px-2 form-control"
                      >
                        <option class="selectoptions" value="" selected="">
                          All
                        </option>
                        <option class="selectoptions" value="joined">
                          Joined
                        </option>
                        <option class="selectoptions" value="shortlisted">
                          Shortlisted
                        </option>
                        <option class="selectoptions" value="failed">
                          Differed/Failed
                        </option>
                      </select>
                    </div>
                    <div
                      class="status-pagesize mx-1"
                      v-if="selectedReport && selectedReportData"
                    >
                      <select
                        @change="changeCampus"
                        v-model="campusId"
                        id="filter-campus"
                        class="select-cp px-2 form-control"
                      >
                        <option class="selectoptions" :value="null">
                          All Campus
                        </option>
                        <option
                          class="selectoptions"
                          :value="campus.id"
                          v-for="(
                            campus, index
                          ) in selectedReportData.campusData"
                          :key="index"
                        >
                          {{ campus.recruitment_session_name }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="status-pagesize mx-1"
                      v-if="selectedReport && selectedReportData"
                    >
                      <select
                        @change="changeEntity"
                        v-model="entityId"
                        id="filter-entity"
                        class="select-cp px-2 form-control"
                      >
                        <option class="selectoptions" :value="null">
                          All Entity
                        </option>
                        <option
                          class="selectoptions"
                          :value="entity.entityDetail.id"
                          v-for="(entity, index) in entityList"
                          :key="index"
                        >
                          {{ entity.entityDetail.entity_name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <button
                    class="btn btn-primary d-md-none my-1"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseExample"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    style="
                      background-color: #8f1452;
                      color: white;
                      border: #8f1452;
                    "
                  >
                    <b-icon icon="filter-left"></b-icon>Filters
                  </button>
                  <div class="ml-auto my-1">
                    <div
                      class="refresh-div float-right d-flex align-items-center"
                    >
                      <div class="d-flex align-items-center">
                        <div
                          v-if="selectedRowCount > 0"
                          class="alert alert-info alert-sm my-auto mx-2"
                        >
                          <span class="hide-on-mobile"
                            >{{ selectedRowCount }} selected</span
                          >
                          <span class="hide-on-desktop px-2">{{
                            selectedRowCount
                          }}</span>
                        </div>
                        <button
                          class="btn btn-cp hide-on-mobile"
                          v-if="
                            selectedRowCount > 0 &&
                            (rowCountOnCurrentPage === -1 ||
                              selectedRowCount < rowCountOnCurrentPage)
                          "
                          @click="selectAllOnCurrentPage"
                        >
                          <i class="fa fa-minus-square" aria-hidden="true"></i>
                          Select all
                        </button>
                        <button
                          class="btn btn-cp hide-on-mobile"
                          v-else-if="
                            selectedRowCount && rowCountOnCurrentPage !== -1
                          "
                          @click="selectAllOnCurrentPage"
                        >
                          <i class="fa fa-check-square" aria-hidden="true"></i>
                          Deselect all
                        </button>
                        <button
                          class="btn btn-cp hide-on-mobile"
                          v-else
                          @click="selectAllOnCurrentPage"
                        >
                          <i class="fa fa-square" aria-hidden="true"></i> Select
                          all
                        </button>
                        <button
                          class="btn btn-cp hide-on-desktop"
                          @click="selectAllOnCurrentPage"
                        >
                          <i
                            class="fa"
                            :class="{
                              'fa-check-square':
                                selectedRowCount &&
                                rowCountOnCurrentPage !== -1,
                              'fa-square':
                                selectedRowCount === 0 ||
                                rowCountOnCurrentPage === -1,
                              'fa-minus-square':
                                selectedRowCount > 0 &&
                                (rowCountOnCurrentPage === -1 ||
                                  selectedRowCount < rowCountOnCurrentPage),
                            }"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                      <div class="float-right ml-2">
                        <select
                          @change="onPageSizeChanged()"
                          id="page-size"
                          class="select-cp form-control"
                        >
                          <option class="selectoptions" value="50">50</option>
                          <option class="selectoptions" value="100" selected="">
                            100
                          </option>
                          <option class="selectoptions" value="200">200</option>
                          <option class="selectoptions" value="300">300</option>
                          <option class="selectoptions" value="500">500</option>
                          <option class="selectoptions" value="1000">
                            1000
                          </option>
                          <option class="selectoptions" value="2000">
                            2000
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="collapse d-md-none w-100" id="collapseExample">
                  <div class="card card-body mt-2">
                    <div class="row">
                      <div class="col-md-4 col-sm-12">
                        <label class="control-label m-r15" htmlFor="appliedOn"
                          >Applied On</label
                        >
                      </div>
                      <div class="col-md-8 col-sm-12 text-wrap">
                        <label for="all" class="radio-inline m-r10">
                          <input
                            type="radio"
                            id="all"
                            value=""
                            v-model="filterStatus"
                          />
                          All
                        </label>
                        <label for="joined" class="radio-inline m-r10">
                          <input
                            type="radio"
                            id="joined"
                            value="joined"
                            v-model="filterStatus"
                          />
                          Joined
                        </label>
                        <label for="shortlisted" class="radio-inline m-r10">
                          <input
                            type="radio"
                            id="shortlisted"
                            value="shortlisted"
                            v-model="filterStatus"
                          />
                          Shortlisted
                        </label>
                        <label for="failed" class="radio-inline m-r10">
                          <input
                            type="radio"
                            id="failed"
                            value="failed"
                            v-model="filterStatus"
                          />
                          Differed/Failed
                        </label>
                      </div>
                    </div>
                    <div
                      class="row mt-1"
                      v-if="selectedReport && selectedReportData"
                    >
                      <div class="col-sm-4">
                        <label class="control-label m-r15" htmlFor="campusWise"
                          >Campus Wise</label
                        >
                      </div>
                      <div class="col-sm-6">
                        <select
                          @change="changeCampus"
                          v-model="campusId"
                          id="filter-campus"
                          class="form-control"
                        >
                          <option class="selectoptions" :value="null">
                            All Campus
                          </option>
                          <option
                            class="selectoptions"
                            :value="campus.id"
                            v-for="(
                              campus, index
                            ) in selectedReportData.campusData"
                            :key="index"
                          >
                            {{ campus.recruitment_session_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="row mt-1"
                      v-if="selectedReport && selectedReportData"
                    >
                      <div class="col-sm-4">
                        <label class="control-label m-r15" htmlFor="entityWise"
                          >Entity Wise</label
                        >
                      </div>
                      <div class="col-sm-6">
                        <select
                          @change="changeEntity"
                          v-model="entityId"
                          id="filter-entity"
                          class="form-control"
                        >
                          <option class="selectoptions" :value="null">
                            All Entity
                          </option>
                          <option
                            class="selectoptions"
                            :value="entity.entityDetail.id"
                            v-for="(entity, index) in entityList"
                            :key="index"
                          >
                            {{ entity.entityDetail.entity_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-1 p-2">
                      <label
                        class="control-label m-r15 ml-2"
                        style="cursor: pointer"
                        htmlFor="download"
                        ><a style="color: #8f1452" @click="downloadData">
                          <b-icon icon="download"></b-icon> Download Applicant
                          Data</a
                        ></label
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-2">
                <ag-grid-vue
                  v-if="hasMenuAccess()"
                  class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
                  style="height: 70vh"
                  :enableBrowserTooltips="true"
                  :gridOptions="gridOptions"
                  @selection-changed="onSelectionChanged"
                  rowSelection="multiple"
                  @filter-changed="onFilterChanged"
                  @sort-changed="onSortChanged"
                  @gridReady="onGridReady"
                  :key="paginationSize"
                >
                </ag-grid-vue>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="m-3">
        <div class="jumbotron text-center">
          <p class="h5">No session report is availabe to display.</p>
          <hr class="my-4" />
          <button
            v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
            class="btn btn-primary btn-cp"
            href="#"
            role="button"
            @click="openCreateModal(false)"
          >
            <i class="fa fa-plus fa-lg" aria-hidden="true"></i>Create report
          </button>
        </div>
      </div>
    </div>

    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
    <create-report-modal
      ref="create-report-modal"
      :selectedReport="selectedReport"
      :createdReport="createdReport"
      :updatedReport="updatedReport"
      :editReport="selectedReportData"
      :isEdit="isEdit"
    ></create-report-modal>
    <update-application-status-modal
      v-if="selectedApplicants.length > 0"
      modal-id="UpdateApplicationStatusAppInfo"
      :actionType="updateStatusActionType"
      :applicationData="selectedApplicants[0]"
      @onConfirmUpdateApplicationStatus="updateApplicationStatus"
    ></update-application-status-modal>
  </div>
</template>
<script>
import CommonChart from "../../../Common/_components/common-highchart.vue";
import api from "../../_api";
import applicantApi from "../../../Applications/_api/index";
import { mapGetters } from "vuex";
import CommonModal from "../../../Common/_components/modal.vue";
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import createReportModal from "./create-report-modal.vue";
import axios from "axios";
import getFilterByType from "../../../Common/_utils/get-filter-by-type";
import { hasAccess } from "../../../User/_utils/actions";
import PERMISSIONS from "../../../Common/permissions";
import { getFilterColumns } from "../../../Common/_plugin/validation";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";
import updateApplicationStatusModal from "@/modules/ApplicantProfile/_components/update-application-status-modal.vue";

/**
 * @memberof module:Report
 * @namespace components.session-report
 * @description view report data
 */
export default {
  props: {
    fromDashboard: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CommonModal,
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{ name: 'applicantprofile', params: { slug: params.data.slug } }"><i title="View Profile" class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    createReportModal,
    CommonChart,
    CollapseCard,
    updateApplicationStatusModal,
  },
  data() {
    return {
      paginationSize: 100,
      campusId: null,
      entityId: null,
      isEdit: false,
      selectedReport: null,
      selectedReportData: null,
      updateStatusActionType: null,
      loadingTemplate: "Select campus drive",
      noRowsTemplate: "No applicants found",
      applicantData: [],
      rowCountOnCurrentPage: -1,
      selectedRowCount: 0,
      status: "",
      gridOptions: null,
      maxApplicantCountToCurrentPage: null,
      columnDefs: [],
      eventModalData: {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      },
      selectedApplicants: [],
      config: {
        format: "DD MMMM YYYY HH:mm",
        maxDate: moment(new Date()),
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
        icons: {
          time: "fa fa-address-book",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down",
          previous: "fa fa-chevron-left",
          next: "fa fa-chevron-right",
          today: "fa fa-calendar-check",
          clear: "fa fa-trash-alt",
          close: "fa fa-times-circle",
        },
      },
      filterStatus: "",
      totalVisibleRows: null,
      datePickerFlag: false,
      startDate: null,
      endDate: null,
      campusMails: null,
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.REPORT.SESSION;
    },
    ...mapGetters({
      sessionReportList: "$_report/sessionReportList",
      applicationCampusCount: "$_report/sessionApplicationCampusCount",
      applicationEntityCount: "$_report/sessionApplicationEntityCount",
    }),

    selectedEntityCounts() {
      return this.applicationEntityCount.reduce(
        (applicants, entity) => {
          applicants.shortlisted += parseInt(entity.shortlisted);
          applicants.joined += parseInt(entity.joined);
          applicants.failed += parseInt(entity.failed);
          return applicants;
        },
        {
          shortlisted: 0,
          joined: 0,
          failed: 0,
        }
      );
    },

    selectedCampusName() {
      if (this.campusId && this.selectedReportData) {
        const selectedCampus = this.selectedReportData.campusData.find(
          (campus) => campus.id === this.campusId
        );
        if (selectedCampus) {
          return selectedCampus.recruitment_session_name;
        }
        return "";
      }
      return "";
    },

    selectedEntityData() {
      if (this.entityId && this.entityList) {
        return this.entityList.find(
          (entity) => entity.entityDetail.id === this.entityId
        );
      }
      return null;
    },

    /**
     * @memberof module:Report.components.session-report
     * @description all entity list belong to selected campus recruitment
     */
    entityList() {
      if (!this.selectedReportData) return [];
      if (this.campusId) {
        const selectedCampus = this.selectedReportData.campusData.find(
          (campus) => campus.id === this.campusId
        );
        return selectedCampus.entitiesInvolved.filter((entity) => {
          return entity.entityDetail.entity_type != "University";
        });
      } else {
        return [
          ...this.selectedReportData.collegeList,
          ...this.selectedReportData.recruiterList,
        ];
      }
    },
    campusCountSlices() {
      return this.applicationCampusCount.map((status) => {
        if (parseInt(status.campus_count) !== 0) {
          return {
            campus_id: status.id,
            name: status.campus_name,
            y: parseInt(status.campus_count),
          };
        }
      });
    },
    entityCountSlices() {
      return this.applicationEntityCount.map((position) => {
        if (parseInt(position.entity_count) !== 0) {
          return {
            entity_id: position.id,
            name: position.entity_name,
            y: parseInt(position.entity_count),
          };
        }
      });
    },
  },

  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    hasMenuAccess() {
      return this.hasAccess({
        permissions: [
          this.BASE_PERMISSION.MENU,
          this.PERMISSIONS.APPLICANT["CAMPUS-SESSION"],
        ],
        checkAll: false,
      });
    },
    async changeCampus() {
      this.entityId = null;
      const campusInstance = await this.gridOptions.api.getColumnFilterInstance(
        "recruitment_session_name"
      );
      const entityInstance = await this.gridOptions.api.getColumnFilterInstance(
        "entity_name"
      );

      if (campusInstance) {
        await campusInstance.setModel({
          filterType: "text",
          type: "equals",
          filter: this.selectedCampusName,
        });
      }

      if (entityInstance) {
        await entityInstance.setModel({
          filterType: "text",
          type: "equals",
          filter: "",
        });
      }

      this.gridOptions.api.onFilterChanged();
      this.fetchApplicationEntityCount();
    },
    async changeEntity() {
      const entityInstance = await this.gridOptions.api.getColumnFilterInstance(
        "entity_name"
      );

      if (entityInstance) {
        await entityInstance.setModel({
          filterType: "text",
          type: "equals",
          filter: this.selectedEntityData
            ? this.selectedEntityData.entityDetail.entity_name
            : "",
        });
      }

      this.gridOptions.api.onFilterChanged();
    },
    deleteReport() {
      this.openDialog(
        "commonModal",
        "Confirmation",
        "Are you sure you want to delete this session report?",
        true,
        "warn",
        "modal-m"
      ).then(() => {
        api
          .deleteSessionReport(this.selectedReport)
          .then(() => {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "success",
                message: "Report has been deleted.",
              },
              { root: true }
            );
            this.fetchReports();
          })
          .catch((err) => {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "Something went wrong while deleting report.",
              },
              { root: true }
            );
            console.log("err", err);
          });
      });
    },

    async getCampusMails() {
      const { data } = await applicantApi.fetchMultipleCampusEmails(
        this.selectedApplicants.map(
          (applicant) => applicant.belongsToCampusRecruitement.id
        )
      );
      this.campusMails = data;
    },
    changeApplicationStatus(type) {
      if (type === "JOINED") {
        const allShortlistedApplications = this.selectedApplicants.every(
          (applicant) => applicant.application_status === "Shortlisted"
        );
        if (allShortlistedApplications) {
          this.updateStatusActionType = type;
          $("#UpdateApplicationStatusAppInfo").modal("show");
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Applicant(s) must be shortlisted.",
            false,
            "danger",
            "modal-m",
            false
          );
        }
      } else {
        const joinedApplications = this.selectedApplicants.some(
          (applicant) =>
            applicant.application_status === "Joined" ||
            applicant.application_status === "Differed"
        );
        if (!joinedApplications) {
          this.updateStatusActionType = type;
          $("#UpdateApplicationStatusAppInfo").modal("show");
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Applicant(s) must not be joined or already differed.",
            false,
            "danger",
            "modal-m",
            false
          );
        }
      }
    },

    async updateApplicationStatus(offerData) {
      if (offerData.isCoordinator) await this.getCampusMails();
      let dataToBeUpdated = [];
      this.selectedApplicants.forEach((element) => {
        dataToBeUpdated.push({
          id: element.id,
          application_status:
            this.updateStatusActionType === "DIFFER" ? "Differed" : "Joined",
          application_comments: offerData.comments,
        });
      });
      let key = null;
      if (offerData.isCoordinator && offerData.isStudent) {
        key = "both";
      } else if (offerData.isCoordinator) {
        key = "coordinator";
      } else if (offerData.isStudent) {
        key = "student";
      }
      if (key) {
        let action =
          this.updateStatusActionType === "DIFFER"
            ? "$_applicants_data/differCampusApplicants"
            : "$_applicants_data/joinedCampusApplicants";
        this.$store.dispatch(action, {
          data: {
            dataToBeUpdated: dataToBeUpdated,
            selectedApplicants: this.selectedApplicants,
            campusMails: this.campusMails,
            status: key,
          },
          redirect: this.$route.fullPath,
        });
      } else if (this.updateStatusActionType === "DIFFER") {
        applicantApi
          .differApplicants(dataToBeUpdated)
          .then(() => {
            this.setDataSource();
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "success",
                message: "Applicants Differed successfully!",
              },
              { root: true }
            );
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else if (this.updateStatusActionType === "JOINED") {
        applicantApi
          .joinedApplicants(dataToBeUpdated)
          .then(() => {
            this.setDataSource();
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "success",
                message: "Applicants Joined successfully!",
              },
              { root: true }
            );
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    },

    onPaginationChanged() {
      this.maxApplicantCountToCurrentPage =
        this.gridOptions.api.paginationGetPageSize() *
        (this.gridOptions.api.paginationGetCurrentPage() + 1);
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description select all applicant on current page
     */
    selectAllOnCurrentPage() {
      const result = this.rowCountOnCurrentPage !== this.selectedRowCount;
      this.selectedRowCount = 0;
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(result);
        this.selectedRowCount++;
      });
      this.rowCountOnCurrentPage = this.selectedRowCount;
    },

    downloadData() {
      let columns = this.gridOptions.api.getAllGridColumns();
      columns = columns.filter(function (col) {
        return !col.colDef.display_only;
      });
      let excel_param = {
        columnKeys: columns,
        fileName: `Report-${moment(this.selectedReportData?.start_date).format(
          "DD/MM/YYYY"
        )}TO${moment(this.selectedReportData?.start_date).format(
          "DD/MM/YYYY"
        )}.csv`,
      };
      if (this.selectedApplicants.length > 0) {
        excel_param.onlySelected = true;
      }
      this.gridOptions.api.exportDataAsCsv(excel_param);
    },
    /**
     * @memberof module:Common.components.registered_applicant_list
     * @returns {Boolean}
     * @description Check whether there is atleast one applicant selected to perform any action
     */
    isAtleastOneSelected() {
      if (this.selectedApplicants.length > 0) {
        return true;
      } else {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select atleast one applicant",
          false,
          "danger",
          "modal-m"
        );
        return false;
      }
    },
    /**
     * @memberof module:Report.components.session-report
     * @description filter campus recruitment
     */
    async filterCampus(event) {
      const campusId = event.point.campus_id;
      const campusName = event.point.name;
      const selected = !event.point.selected;
      const instance = await this.gridOptions.api.getColumnFilterInstance(
        "recruitment_session_name"
      );

      if (instance) {
        if (selected) {
          await instance.setModel({
            filterType: "text",
            type: "equals",
            filter: campusName,
          });
          this.campusId = campusId;
        } else {
          await instance.setModel({
            filterType: "text",
            type: "equals",
            filter: "",
          });
          this.campusId = null;
        }
        this.gridOptions.api.onFilterChanged();
        this.fetchApplicationEntityCount();
      }
    },
    /**
     * @memberof module:Report.components.session-report
     * @description filter entity
     */
    async filterEntity(event) {
      const entityId = event.point.entity_id;
      const entityName = event.point.name;
      const selected = !event.point.selected;
      const instance = await this.gridOptions.api.getColumnFilterInstance(
        "entity_name"
      );

      if (instance) {
        if (selected) {
          await instance.setModel({
            filterType: "text",
            type: "equals",
            filter: entityName,
          });
          this.entityId = entityId;
        } else {
          await instance.setModel({
            filterType: "text",
            type: "equals",
            filter: "",
          });
          this.entityId = null;
        }
        this.gridOptions.api.onFilterChanged();
      }
    },
    /**
     * @memberof module:Report.components.session-report
     * @description applicant list page size change
     */
    onPageSizeChanged() {
      const rowCount = Number($("#page-size option:selected").val());
      this.gridOptions.paginationPageSize = rowCount;
      this.gridOptions.cacheBlockSize = rowCount;
      this.paginationSize = rowCount;
    },

    /**
     * @memberof module:Report.components.session-report
     * @description hide create modal and fetch reports
     */
    updatedReport() {
      $("#createReportModal").modal("hide");
      this.fetchReports();
    },

    /**
     * @memberof module:Report.components.session-report
     * @description open create modal to create new report
     */
    createdReport() {
      $("#createReportModal").modal("hide");
      this.fetchReports();
    },

    /**
     * @memberof module:Report.components.session-report
     * @description open create modal to edit report
     */
    openCreateModal(isEdit) {
      this.isEdit = isEdit;
      this.$refs["create-report-modal"].updateData(isEdit);
      $("#createReportModal").modal("show");
    },

    /**
     * @memberof module:Report.components.session-report
     * @description fetch campuses
     */
    fetchCampuses() {
      if (!this.hasMenuAccess()) return;
      this.$store.dispatch("$_campus_recruitment/filterCampusRecruitments", {});
    },

    /**
     * @memberof module:Report.components.session-report
     * @description fetch reports list
     */
    fetchReports() {
      if (!this.hasMenuAccess()) return;
      this.$store.dispatch("$_report/fetchSessionReportList", {});
    },

    createColumnDefs() {
      this.columnDefs = [
        {
          cellRenderer: "profile-link",
          width: 35,
          maxWidth: 35,
          suppressFilter: true,
          filter: false,
          valueGetter: "node.id",
          headerTooltip: "Profile Info",
          // tooltipValueGetter: function (params) {
          //   if (params.data != undefined) {
          //     return "Profile of  " + params.data.first_name;
          //   }
          //   return "";
          // },
          pinned: "left",
          display_only: true,
          cellStyle: { textAlign: "center", padding: 0 },
        },
        {
          headerName: "Name",
          field: "name",
          valueGetter: function mergeLastNameFirstName(params) {
            if (params.data != undefined) {
              return params.data?.first_name + " " + params.data?.last_name;
            }
          },
          sortable: true,
          width: 150,
          minWidth: 150,
          pinned: "left",
          suppressHeaderMenuButton: true,
          checkboxSelection: true,
          filterParams: {
            newRowsAction: "keep",
          },
        },
        {
          headerName: "Expected Joining",
          field: "expected_joining",
          sortable: true,
          width: 120,
          minWidth: 120,
          pinned: "left",
          suppressHeaderMenuButton: true,
          cellRenderer: (params) => {
            if (params.data && params.data.expected_joining)
              return moment(params.data.expected_joining).format("DD/MM/YYYY");
            else return "NA";
          },
          filter: "agDateColumnFilter",
        },
        {
          headerName: "College Name",
          field: "entity_name",
          sortable: true,
          width: 180,
          minWidth: 150,
          pinned: "left",
          suppressHeaderMenuButton: true,
          valueGetter: function entityName(params) {
            if (params.data) {
              if (params.data.entityId && params.data.linkedWithEntity) {
                return params.data.linkedWithEntity.entity_name;
              }
            }
          },
        },
        {
          headerName: "Application status",
          field: "application_status",
          sortable: true,
          width: 140,
          minWidth: 120,
          suppressHeaderMenuButton: true,
          headerTooltip: "Application status",
        },
        {
          headerName: "Designation",
          field: "designation_offered",
          sortable: true,
          suppressHeaderMenuButton: true,
          width: 150,
          minWidth: 150,
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          width: 200,
          minWidth: 200,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Mobile",
          field: "mobile_no",
          sortable: true,
          width: 150,
          minWidth: 150,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "DOB",
          field: "dob",
          sortable: true,
          width: 150,
          minWidth: 150,
          suppressHeaderMenuButton: true,
          valueGetter: function dateFormat(params) {
            if (params.data != undefined) {
              let dt = new Date(params.data.dob);
              return dt.toLocaleDateString().toString();
            }
          },
          filter: "agDateColumnFilter",
        },
        {
          headerName: "Gender",
          field: "gender",
          sortable: true,
          width: 100,
          minWidth: 100,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Degree",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.degree;
            }
            return null;
          },
        },
        {
          headerName: "Stream",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.stream;
            }
            return null;
          },
        },
        {
          headerName: "Aggregate Percentage",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.aggregate_percentage;
            }
            return null;
          },
        },
        {
          headerName: "HSC Percentage",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.hsc_percentage;
            }
            return null;
          },
        },
        {
          headerName: "SSC Percentage",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.ssc_percentage;
            }
            return null;
          },
        },
        {
          headerName: "Graduation Percentage",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.grad_percentage;
            }
            return null;
          },
        },
        {
          headerName: "Semester1",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.semester1;
            }
            return null;
          },
        },
        {
          headerName: "Semester2",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.semester2;
            }
            return null;
          },
        },
        {
          headerName: "Semester3",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.semester3;
            }
            return null;
          },
        },
        {
          headerName: "Semester4",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.semester4;
            }
            return null;
          },
        },
        {
          headerName: "Semester5",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.semester5;
            }
            return null;
          },
        },
        {
          headerName: "Semester6",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.semester6;
            }
            return null;
          },
        },
        {
          headerName: "Semester7",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.semester7;
            }
            return null;
          },
        },
        {
          headerName: "Semester8",
          hide: true,
          valueGetter: function getValue(params) {
            if (params.data && params.data.education_details) {
              return params.data.education_details.semester8;
            }
            return null;
          },
        },
        {
          headerName: "Campus Recruitment",
          field: "recruitment_session_name",
          headerTooltip: "Campus Recruitment",
          sortable: true,
          width: 200,
          minWidth: 200,
          valueGetter: function dateFormat(params) {
            if (params.data != undefined) {
              if (params.data.belongsToCampusRecruitement != null) {
                return params.data.belongsToCampusRecruitement
                  .recruitment_session_name;
              }
            }
          },
        },
      ];
    },
    /**
     * @memberof module:Report.components.session-report
     * @description fetch applicant list
     */
    setDataSource() {
      if (!this.hasMenuAccess()) return;
      let dataSource = {
        rowCount: null,
        rowCountPagination: 0,
        rowCountFiltering: 0,
        _self: this,
        getRows: function (params) {
          let campusId = this._self.campusId;
          let entityId = this._self.entityId;
          let status = this._self.filterStatus;
          let queryParams = {
            startRow: params.startRow,
            size: params.endRow - params.startRow,
            includeAll: true,
            startDate: this._self.selectedReportData?.start_date,
            endDate: this._self.selectedReportData?.end_date,
          };
          if (campusId) queryParams["campusId"] = campusId;
          if (entityId) queryParams["entityId"] = entityId;
          if (status) queryParams["status"] = status;
          let queryObj = {};
          if (params.sortModel) {
            queryObj.sort = params.sortModel[0];
          }
          for (const key in params.filterModel) {
            if (Object.prototype.hasOwnProperty.call(params.filterModel, key)) {
              switch (key) {
                // case "first_name":
                //   queryObj.name = getFilterByType(params.filterModel[key]);
                //   break;
                // case "3":
                //   queryObj.recruitment_session_name = getFilterByType(
                //     params.filterModel[key]
                //   );
                //   break;
                default: {
                  const queryData = getFilterByType(params.filterModel[key]);
                  queryObj[key] = queryData;
                  break;
                }
              }
            }
          }
          if (
            Object.keys(params.filterModel).length != 0 ||
            Object.keys(params.sortModel).length != 0
          ) {
            this.rowCountPagination = 0;
            if (params.startRow == 0) {
              this.rowCountFiltering = 0;
            }
            axios
              .post("applicants/data_list", queryObj, { params: queryParams })
              .then(
                (data) => {
                  this.rowCountFiltering += data.data.length;
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountFiltering,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.rowCountFiltering = 0;
            axios
              .post("applicants/data_list", {}, { params: queryParams })
              .then(
                (data) => {
                  if (params.startRow == 0) {
                    this.rowCountPagination = data.data.length;
                  } else {
                    this.rowCountPagination += data.data.length;
                  }
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountPagination,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
          this._self.resetRowCountData();
        },
      };

      this.gridOptions.api.setGridOption("datasource", dataSource);
    },
    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.setDataSource();
    },
    getLastRowValue(rowCount, endRow, defaultValue = -1) {
      if (rowCount < endRow) return rowCount;
      return defaultValue;
    },

    resetRowCountData() {
      this.rowCountOnCurrentPage = -1;
      this.selectedRowCount = 0;
    },

    /**
     * @memberof module:Report.components.session-report
     * @description Called everytime the selected rows get changed in the grid
     */
    onSelectionChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:Report.components.session-report
     * @description Called everytime the filter applied in the grid
     */

    onFilterChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:Report.components.session-report
     * @description Called everytime the column sorted in the grid
     */
    onSortChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:Report.components.session-report
     * @description method to get data table list on ag grid ready
     */
    // async onGridReady() {},

    /**
     * @memberof module:Report.components.session-report
     * @description fetch campus recruitment applicant count
     */
    fetchApplicationCampusCount() {
      if (!this.hasMenuAccess()) return;
      this.$store.dispatch("$_report/sessionApplicationCampusCount", {
        queryOptions: {},
        filterOptions: {
          sessionReportId: this.selectedReport,
          start_date: this.selectedReportData?.start_date,
          end_date: this.selectedReportData.end_date,
          status: this.filterStatus,
        },
      });
    },

    /**
     * @memberof module:Report.components.session-report
     * @description fetch entity applicant count
     */
    fetchApplicationEntityCount() {
      if (!this.hasMenuAccess()) return;
      this.$store.dispatch("$_report/sessionApplicationEntityCount", {
        queryOptions: {},
        filterOptions: {
          campusId: this.campusId,
          sessionReportId: this.selectedReport,
          start_date: this.selectedReportData?.start_date,
          end_date: this.selectedReportData.end_date,
          status: this.filterStatus,
        },
      });
    },

    getReportData() {
      api
        .getSessionReportData(this.selectedReport)
        .then((res) => {
          const campusCount = res.data.campusData.length;
          const collegeCount = [];
          const collegeList = [];
          const universityCount = [];
          const uiversityList = [];
          const recruiterCount = [];
          const recruiterList = [];
          const processEntity = (entity, countArray, listArray) => {
            if (!countArray.includes(entity.entityDetail.id)) {
              countArray.push(entity.entityDetail.id);
              listArray.push(entity);
            }
          };
          for (const campus of res.data.campusData) {
            for (const entity of campus.entitiesInvolved) {
              const entityType = entity.entityDetail.entity_type;
              switch (entityType) {
                case "University":
                  processEntity(entity, universityCount, uiversityList);
                  break;
                case "College":
                  processEntity(entity, collegeCount, collegeList);
                  break;
                case "Recruiter":
                  processEntity(entity, recruiterCount, recruiterList);
                  break;
                default:
                  break;
              }
            }
          }
          this.selectedReportData = {
            ...res.data,
            campusCount,
            collegeCount,
            collegeList,
            universityCount,
            recruiterCount,
            recruiterList,
          };
        })
        .catch(() => (this.selectedReportData = null));
    },
    configGridOptions() {
      this.createColumnDefs();
      if (!this.hasMenuAccess()) return;
      this.gridOptions = {
        overlayLoadingTemplate: this.loadingTemplate,
        overlayNoRowsTemplate: this.noRowsTemplate,
        enableCellTextSelection: true,
        rowData: null,
        columnDefs: getFilterColumns(this.columnDefs, 1),
        rowHeight: 30,
        suppressPropertyNamesCheck: true,
        suppressRowClickSelection: true,
        enableServerSideFilter: true,
        enableColResize: true,
        rowSelection: "multiple",
        suppressRowDeselection: true,
        rowModelType: "infinite",
        defaultColDef: {
          flex: 1,
          resizable: true,
          floatingFilter: true,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
          // set filtering on for all columns
          filter: true,
        },
        infiniteInitialRowCount: 0,
        animateRows: true,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        pagination: true,
        paginationPageSize: 100,
        paginationPageSizeSelector: false,
        cacheBlockSize: 100,
        context: {
          componentParent: this,
        },
      };
    },
  },
  beforeMount() {
    this.configGridOptions();
  },
  watch: {
    /**
     * @memberof module:Report.components.session-report
     * @description filter applicants when status changes
     */
    filterStatus() {
      this.setDataSource();
      this.fetchApplicationCampusCount();
      this.fetchApplicationEntityCount();
    },

    /**
     * @memberof module:Report.components.session-report
     * @description fetch applicants and entity applicant count when campus changes
     */
    campusId() {
      this.entityId = null;
    },

    /**
     * @memberof module:Report.components.session-report
     * @description fetch applicants , campus and entity applicant count when selected report changes
     */
    selectedReport(value) {
      this.campusId = null;
      this.entityId = null;

      if (value) {
        this.getReportData();
      }
    },

    /**
     * @memberof module:Report.components.session-report
     * @description fetch applicants , campus and entity applicant count when selected report changes
     */
    selectedReportData() {
      this.setDataSource();
      this.fetchApplicationCampusCount();
      this.fetchApplicationEntityCount();
    },

    /**
     * @memberof module:Report.components.session-report
     * @description auto select first report when session report loads
     */
    sessionReportList() {
      if (this.sessionReportList.length > 0) {
        this.selectedReport = this.sessionReportList[0].id;
      } else {
        this.selectedReport = null;
      }
    },
  },
  updated() {
    $("#campuspicker").selectpicker("refresh");
  },
  mounted() {
    this.fetchReports();
  },
  filters: {
    dateFormat(date) {
      return moment(date).format(" MMMM Do YYYY");
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.status-pagesize {
  max-width: 200px;
  min-width: 150px;
}

.dropdown-menu .dropdown-header {
  padding: 3px 20px;
  color: #823e5b;
  font-size: 16px;
}
.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-item:hover {
  background-color: #803059;
  color: white;
}

@media screen and (max-width: 767px) {
  .summary-header {
    font-size: medium;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 401px) {
  .summary-header {
    font-size: small;
    margin-bottom: 0;
  }
}

.session-dropdown {
  width: 250px;
}

@media screen and (max-width: 418px) {
  .session-dropdown {
    width: 170px;
  }
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .hide-on-desktop {
    display: none !important;
  }
}
</style>
