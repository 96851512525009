import api from "../_api";
import { addSnackbarMessage } from "../../Common/_plugin/error-response";

/**
 * @memberOf module:ScheduleDashboard.store.actions
 * @param {Object} context store context
 * @description get data of interview schedules
 */
const fetchInterviewSchedules = (context, userId) => {
  api
    .fetchInterviewSchedules(userId)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_INTERVIEW_SCHEDULES", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving Schedules !", true);
    });
};

/**
 * @memberOf module:ScheduleDashboard.store.actions
 * @param {Object} context store context
 * @description get data of review schedules
 */
const fetchReviewSchedules = (context, userId) => {
  api
    .fetchReviewSchedules(userId)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_REVIEW_SCHEDULES", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving Schedules !", true);
    });
};

const problemRetrievingSchedulesErr = (context) => {
  context.dispatch(
    "openSnackbar",
    {
      type: "danger",
      message: "Problem retrieving Schedules !",
    },
    { root: true }
  );
};

const setSelectedCampusEvent = (context, campusId) => {
  context.commit("SET_SELECTED_CAMPUS_EVENT", campusId);
};

/**
 * @memberOf module:ScheduleDashboard
 * @namespace store.actions
 * @description actions for schedule dashboard module
 */
export default {
  fetchInterviewSchedules,
  fetchReviewSchedules,
  setSelectedCampusEvent,
  problemRetrievingSchedulesErr,
};
