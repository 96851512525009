<template>
  <edit-applicant-form></edit-applicant-form>
</template>

<script>
import store from "./_store";
import homeStore from "../Home/_store";
import EditApplicantForm from "./_components/edit-applicant-form.vue";

export default {
  components: {
    EditApplicantForm,
  },

  created() {
    const STORE_KEY = "$_edit_applicant_data";

    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }

    const HOME_KEY = "$_home";
    if (!(HOME_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(HOME_KEY, homeStore);
    }
  },
};
</script>
