function getTemplates(state) {
  return state.templates;
}
function getTemplateFetchError(state) {
  return state.editorFormFetchError;
}
function getIsTemplateSaved(state) {
  return state.templateSaved;
}

export default {
  getTemplates,
  getTemplateFetchError,

  getIsTemplateSaved,
};
