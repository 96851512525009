const categoriesWithJobs = (state) => state.categoriesWithJobs;

const fetchFilteredData = (state) => state.filteredData;

const fetchDetailedReport = (state) => state.detailedReport;

const fetchRoundData = (state) => state.roundData;

export default {
  categoriesWithJobs,
  fetchFilteredData,
  fetchDetailedReport,
  fetchRoundData,
};
