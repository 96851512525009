<template>
  <div class="content">
    <common-header :data="['home', 'applications', 'applicants', 'resolve']" />
    <new-applicant-data></new-applicant-data>
    <matched-applicant-data></matched-applicant-data>
  </div>
</template>

<script>
import NewApplicantData from "./new-applicant-data.vue";
import MatchedApplicantData from "./matched-applicant-data.vue";

/**
 * @memberof module:ResolveDuplicate
 * @namespace components.resolve_duplicate
 */
export default {
  components: {
    NewApplicantData,
    MatchedApplicantData,
  },
};
</script>
