<template>
  <div v-if="hasAccess({ permissions: [BASE_PERMISSION.READ] })">
    <common-header :data="['home', 'applications', 'applicants']">
      <template>
        <div>
          <button class="btn btn-cp" @click="refreshGrid()">
            <i class="fa fa-repeat" aria-hidden="true"></i>
          </button>
          <button
            v-if="isApplicantView"
            class="btn btn-cp btn-filter ml-2"
            @click="filterCards"
          >
            <em class="fa fa-filter"></em>
            <span
              v-if="filterOptions.applicationDate || filterOptions.moreFilters"
              class="filter-indicator"
            ></span>
          </button>
        </div>
      </template>
    </common-header>
    <div class="container-fluid">
      <div class="row m-0 mt-1">
        <div class="col-md-6 col-xs-6 px-0">
          <applicant-search-bar
            :apiPromise="searchApiPromise"
            :routerLink="searchRouterLink"
          />
        </div>
        <div class="col-md-6 col-xs-6 top-buttons px-0">
          <div class="float-right media-button float-right">
            <select
              v-model="employmentStatus"
              class="select-cp form-control"
              id="employment-select"
              style="height: 42px"
            >
              <option value="P">
                Professionals ({{ freshProfessionals }})
              </option>
              <option value="G">Freshers ({{ freshGraduates }})</option>
              <option value="A">
                All ({{
                  parseInt(freshProfessionals) + parseInt(freshGraduates)
                }})
              </option>
            </select>
          </div>
          <label
            class="checkbox-inline float-right"
            style="margin-top: 10px; margin-right: 10px"
          >
            <input
              style="margin-right: 5px"
              @change="refreshGrid()"
              type="checkbox"
              v-model="includeAll"
            />
            <a
              title="Differed, Joined, Talent Pool"
              class="pe-none"
              tabindex="-1"
              aria-disabled="true"
              >Include All</a
            >
          </label>
        </div>
      </div>
      <filter-data-modal></filter-data-modal>

      <div class="row m-0 mt-2">
        <div class="col-md-6 col-xs-6 border-md-right">
          <common-chart
            chartId="status-chart"
            chartTitle="Status-wise"
            :colorIdx="0"
            :dataSlices="statusCountSlices"
            v-on:filter-data="filterApplicationStatus($event)"
          >
          </common-chart>
        </div>
        <div class="col-md-6 col-xs-6">
          <common-chart
            chartId="position-chart"
            chartTitle="Position-wise"
            :colorIdx="2"
            :dataSlices="positionCountSlices"
            v-on:filter-data="filterApplicationPosition($event)"
          >
            <div class="filter-text" v-if="applicationStatus">
              <em class="fa fa-filter"></em> Application Status :
              {{ applicationStatus }}
            </div>
          </common-chart>
        </div>
      </div>
      <div class="d-flex pb-3 m-0 justify-content-between">
        <div class="">
          <div class="btn-group dropup float-left">
            <button
              class="btn dropdown-toggle btn-cp"
              type="button"
              data-toggle="dropdown"
              id="dropdownMenuButton"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-tasks" aria-hidden="true"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <template
                v-if="
                  hasAccess({
                    permissions: [PERMISSIONS.APPLICANT.ROUND.CREATE],
                  })
                "
              >
                <li class="dropdown-header">Scheduling</li>
                <li class="dropdown-item">
                  <a @click="scheduleNextRound">Schedule next Round(s)</a>
                </li>
                <li class="divider"></li>
              </template>
              <li class="dropdown-header">Change Applicant Status</li>
              <template
                v-if="
                  hasAccess({
                    permissions: [PERMISSIONS.APPLICANT.ROUND.UPDATE],
                  })
                "
              >
                <li class="dropdown-item">
                  <a @click="passLastRound">Pass Pending Round(s)</a>
                </li>
                <li class="dropdown-item">
                  <a @click="failLastRound">Fail Pending Round(s)</a>
                </li>
                <li class="dropdown-item">
                  <a @click="revertLastRound">Revert Last Round(s)</a>
                </li>
                <li class="dropdown-item">
                  <a @click="cancelLastRound">Cancel Last Round(s) and Mail</a>
                </li>
              </template>
              <template
                v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
              >
                <li class="dropdown-item">
                  <a @click="handleSendMailButton">Send Mail</a>
                </li>
                <li class="dropdown-item">
                  <a @click="shortlistApplicants"
                    >Shortlist Applicant(s) and Mail</a
                  >
                </li>
                <li class="dropdown-item">
                  <a @click="differApplicants">Differ Applicant(s) and Mail</a>
                </li>
                <li class="dropdown-item">
                  <a @click="moveApplicantsToTalentPool"
                    >Move Applicant(s) to Talent Pool and Mail</a
                  >
                </li>
              </template>
              <template
                v-if="hasAccess({ permissions: [PERMISSIONS.MAIL.CREATE] })"
                ><li class="divider"></li>
                <li class="dropdown-header">Campaign</li>
                <li class="dropdown-item">
                  <a @click="RunCampaign">Run Campaign</a>
                </li></template
              >
              <template
                v-if="hasAccess({ permissions: [BASE_PERMISSION.READ] })"
              >
                <li class="dropdown-header">Download</li>
                <li class="dropdown-item">
                  <a @click="downloadData">Download Applicant Data</a>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="d-flex">
          <p class="alert alert-info alert-sm mr-2" v-if="selectedRowCount > 0">
            <span class="hide-on-mobile">{{ selectedRowCount }} selected</span>
            <span class="hide-on-desktop px-2">{{ selectedRowCount }}</span>
          </p>
          <div>
            <button
              class="btn btn-cp hide-on-mobile"
              v-if="
                selectedRowCount > 0 &&
                (rowCountOnCurrentPage === -1 ||
                  selectedRowCount < rowCountOnCurrentPage)
              "
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-minus-square" aria-hidden="true"></i> Select all
            </button>
            <button
              class="btn btn-cp hide-on-mobile"
              v-else-if="selectedRowCount && rowCountOnCurrentPage !== -1"
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-check-square" aria-hidden="true"></i> Deselect all
            </button>
            <button
              class="btn btn-cp hide-on-mobile"
              v-else
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-square" aria-hidden="true"></i> Select all
            </button>
            <button
              class="btn btn-cp hide-on-desktop ml-0"
              @click="selectAllOnCurrentPage"
            >
              <i
                class="fa"
                :class="{
                  'fa-check-square':
                    selectedRowCount && rowCountOnCurrentPage !== -1,
                  'fa-square':
                    selectedRowCount === 0 || rowCountOnCurrentPage === -1,
                  'fa-minus-square':
                    selectedRowCount > 0 &&
                    (rowCountOnCurrentPage === -1 ||
                      selectedRowCount < rowCountOnCurrentPage),
                }"
                aria-hidden="true"
              ></i>
            </button>
          </div>
          <div class="ml-2">
            <select
              @change="onPageSizeChanged($event)"
              class="form-control select-cp"
            >
              <option class="pagesizeoption" value="50">50</option>
              <option class="pagesizeoption" value="100" selected="">
                100
              </option>
              <option class="pagesizeoption" value="200">200</option>
              <option class="pagesizeoption" value="500">500</option>
              <option class="pagesizeoption" value="1000">1000</option>
              <option class="pagesizeoption" value="2000">2000</option>
            </select>
          </div>
        </div>
      </div>
      <ag-grid-vue
        class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
        style="height: 70vh; margin-bottom: 15px"
        :enableBrowserTooltips="true"
        :gridOptions="gridData"
        @selection-changed="onSelectionChanged"
        @filter-changed="onFilterChanged"
        @sort-changed="onSortChanged"
        rowSelection="multiple"
        @gridReady="onGridReady"
        :key="paginationSize"
      >
      </ag-grid-vue>
      <schedule-round-modal
        v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
      />
      <common-modal
        :id="commonModalData.id"
        :modal-title="commonModalData.title"
        :modal-body="commonModalData.body"
        :show-cancel="commonModalData.showCancel"
        :modal-color="commonModalData.color"
        :modal-size-class="commonModalData.sizeClass"
        @actionPerformed="emitOnCurrent"
      />
      <select-to-be-mailed
        :flag="flag"
        :selectedCampusApplicants="selectedApplicants"
        :refreshParentPage="refreshGrid"
      ></select-to-be-mailed>
    </div>
    <add-round-modal
      v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.CREATE] })"
      :applicantData="applicantData"
      :refreshParentPage="refreshGrid"
    ></add-round-modal>
  </div>
</template>

<script>
import AddRoundModal from "../../ApplicantProfile/_components/add-round-modal.vue";
import { AgGridVue } from "ag-grid-vue";
import ScheduleRoundModal from "../../Applications/_components/schedule-round-modal";
import CommonModal from "./modal";
import ResolveDuplicateLink from "../../Applications/_components/resolve-duplicate-link.vue";
import SelectToBeMailed from "../../Applications/_components/select-to-be-mailed";
import FilterDataModal from "../../Applications/_components/filter-data-modal";
import getFilterByType from "../_utils/get-filter-by-type";
import axios from "axios";
import { mapGetters } from "vuex";
import CommonChart from "./common-highchart";
import ApplicantSearchBar from "./applicant-search-bar";
import ClaimedByFilter from "../_components/claimedByFilterVue";
import CustomFloatingFliter from "./customFloatingFilter.js";
import PERMISSIONS from "../permissions";
import { hasAccess } from "../../User/_utils/actions";
import EditApplicantLink from "./edit-applicant-link.vue";
import { getFilterColumns } from "../_plugin/validation";
export default {
  props: {
    columnDefs: {
      type: Array,
      required: true,
    },
    statusCount: {
      type: Array,
      required: true,
    },
    positionCount: {
      type: Array,
      required: true,
    },
    employmentStatusCount: {
      type: Array,
      required: true,
    },
    isApplicantView: {
      type: Boolean,
      required: true,
    },
    campusID: {
      type: Number,
    },
    refreshRegisteredApplicantListGrid: {
      type: Function,
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      applicantData: "$_applicants_data/fetchSelectedApplicants",
      rowData: "$_applicants_data/fetchApplicantsDataStatus",
      filterOptions: "$_applicants_data/fetchFilterData",
      freshProfessionals: "$_applicants_data/fetchFreshProfessionalsCount",
      freshGraduates: "$_applicants_data/fetchFreshGraduatesCount",
    }),
    positionCountSlices() {
      return this.positionCount.map((position) => {
        if (parseInt(position.position_count) !== 0) {
          return {
            name: position.position_name,
            y: parseInt(position.position_count),
          };
        }
      });
    },
    statusCountSlices() {
      return this.statusCount.map((status) => {
        if (parseInt(status.status_count) !== 0) {
          return {
            name: status.status_name,
            y: parseInt(status.status_count),
          };
        }
      });
    },
  },
  watch: {
    rowData() {
      this.setDataSource();
    },
    filterOptions() {
      this.resetFilter();
      if (this.filterOptions) {
        if (this.filterOptions.moreFilters) {
          this.moreFilters = this.filterOptions.moreFilters;
        }
        if (this.filterOptions.applicationDate) {
          this.filter();
        }
      }
      this.applicationStatusCount(this.filterOptions);
      this.applicationPositionCount(this.filterOptions);
    },
    employmentStatus(value) {
      this.filterOptions.employmentStatus = value;
      this.refreshGrid();
    },
  },
  data() {
    return {
      paginationSize: 100,
      moreFilters: {
        duplicateType: "",
        campusType: "",
      },
      title: "",
      selectedApplicants: [],
      rowCountOnCurrentPage: -1,
      selectedRowCount: 0,
      gridData: null,
      gridOptions: null,
      flag: null,
      includeAll: false,
      applicationStatus: null,
      position: null,
      employmentStatus: this.$route.query?.status
        ? this.$route.query.status
        : "P",
    };
  },
  components: {
    AddRoundModal,
    FilterDataModal,
    ScheduleRoundModal,
    CommonModal,
    SelectToBeMailed,
    // eslint-disable-next-line vue/no-unused-components
    "resolve-link": ResolveDuplicateLink,
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{name:'applicantprofile', params:{slug:params.data.slug}}"><i title="View Profile" class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "edit-profile-data": EditApplicantLink,
    CommonChart,
    ApplicantSearchBar,
    // eslint-disable-next-line vue/no-unused-components
    ClaimedByFilter,
    // eslint-disable-next-line vue/no-unused-components
    CustomFloatingFliter,
  },

  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:Common.components.applications-list
     * @description Called everytime the filter applied in the grid
     */
    onFilterChanged() {
      this.selectedApplicants = this.gridData.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:Common.components.applications-list
     * @description Called everytime the column sorted in the grid
     */
    onSortChanged() {
      this.selectedApplicants = this.gridData.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },
    filterCards() {
      $("#filter-data-modal").modal("show");
    },
    setDataSource() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      let dataSource = {
        rowCount: null,
        rowCountPagination: 0,
        rowCountFiltering: 0,
        _self: this,
        getRows: function (params) {
          let campusId = this._self.campusID;
          let applicantView = this._self.isApplicantView;
          let includeAll = this._self.includeAll;
          let employmentStatus = this._self.employmentStatus;
          let filterOptions = this._self.filterOptions;
          let queryParams = {
            startRow: params.startRow,
            size: params.endRow - params.startRow,
            includeAll: includeAll,
          };
          if (!applicantView) {
            queryParams["campusId"] = campusId;
          } else {
            queryParams["employmentStatus"] = employmentStatus;
          }
          filterOptions.moreFilters &&
            filterOptions.moreFilters.duplicateType &&
            (queryParams["duplicateType"] =
              filterOptions.moreFilters.duplicateType);
          filterOptions.moreFilters &&
            filterOptions.moreFilters.campusType &&
            (queryParams["campusType"] = filterOptions.moreFilters.campusType);
          let queryObj = {};
          if (params.sortModel) {
            queryObj.sort = params.sortModel[0];
          }
          for (const key in params.filterModel) {
            if (Object.prototype.hasOwnProperty.call(params.filterModel, key)) {
              switch (key) {
                // case "first_name":
                //   queryObj.name = getFilterByType(params.filterModel[key]);
                //   break;
                // case "recruitment_session_name":
                //   queryObj.recruitment_session_name = getFilterByType(
                //     params.filterModel[key]
                //   );
                //   break;
                default: {
                  const queryData = getFilterByType(params.filterModel[key]);
                  queryObj[key] = queryData;
                  break;
                }
              }
            }
          }
          if (
            Object.keys(params.filterModel).length != 0 ||
            Object.keys(params.sortModel).length != 0
          ) {
            this.rowCountPagination = 0;
            if (params.startRow == 0) {
              this.rowCountFiltering = 0;
            }
            axios
              .post("applicants/data_list", queryObj, { params: queryParams })
              .then(
                (data) => {
                  this.rowCountFiltering += data.data.length;
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountFiltering,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            this.rowCountFiltering = 0;
            axios
              .post("applicants/data_list", {}, { params: queryParams })
              .then(
                (data) => {
                  if (params.startRow == 0) {
                    this.rowCountPagination = data.data.length;
                  } else {
                    this.rowCountPagination += data.data.length;
                  }
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountPagination,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data, lastRow);
                },
                (err) => {
                  console.log(err);
                }
              );
          }
          this._self.resetRowCountData();
        },
      };

      this.gridData.api.setGridOption("datasource", dataSource);
    },
    getLastRowValue(rowCount, endRow, defaultValue = -1) {
      if (rowCount < endRow) return rowCount;
      return defaultValue;
    },
    /**
     * @memberof module:common
     * @description method to filter data in grid
     */
    async filterApplicationStatus(event) {
      const applicationStatus = event.point.name;
      const instance = await this.gridData.api.getColumnFilterInstance(
        "application_status"
      );

      if (instance.appliedModel) {
        if (instance.appliedModel.filter === applicationStatus) {
          await instance.setModel({
            filterType: "text",
            type: "equals",
            filter: "",
          });
          delete this.filterOptions.applicationStatus;
          this.applicationStatus = "";
        } else {
          await instance.setModel({
            filterType: "text",
            type: "equals",
            filter: applicationStatus,
          });
          this.filterOptions.applicationStatus = applicationStatus;
          this.applicationStatus = applicationStatus;
        }
      } else {
        await instance.setModel({
          filterType: "text",
          type: "equals",
          filter: applicationStatus,
        });
        this.filterOptions.applicationStatus = applicationStatus;
        this.applicationStatus = applicationStatus;
      }

      const instancePosition = await this.gridData.api.getColumnFilterInstance(
        "position"
      );
      await instancePosition.setModel({
        filterType: "text",
        type: "equals",
        filter: "",
      });

      this.gridData.api.onFilterChanged();
      this.applicationPositionCount(this.filterOptions);
    },

    /**
     * @memberof module:common
     * @description method to filter data in grid
     */
    async filterApplicationPosition(event) {
      const position = event.point.name;
      await this.applyApplicationStatusFilter();
      await this.applyPositionFilter(position);
      this.gridData.api.onFilterChanged();
      this.filterOptions.applicationStatus = this.applicationStatus;
    },

    async applyApplicationStatusFilter() {
      if (this.applicationStatus) {
        const instance = await this.gridData.api.getColumnFilterInstance(
          "application_status"
        );
        if (instance) {
          const data = await instance.getModel();
          if (data) {
            data.filterType = "text";
            data.type = "equals";
            data.filter = this.applicationStatus;
            await instance.setModel(data);
          }
        }
      }
    },

    async applyPositionFilter(position) {
      const instancePosition = await this.gridData.api.getColumnFilterInstance(
        "position"
      );
      if (instancePosition) {
        const currentModel = await instancePosition.getModel();
        if (currentModel && currentModel.filter === position) {
          await this.clearPositionFilter(instancePosition);
        } else {
          await this.setPositionFilter(instancePosition, position);
        }
      }
    },

    async clearPositionFilter(instance) {
      await instance.setModel({
        filterType: "text",
        type: "equals",
        filter: "",
      });
      delete this.filterOptions.position;
      this.position = "";
    },

    async setPositionFilter(instance, position) {
      await instance.setModel({
        filterType: "text",
        type: "equals",
        filter: position,
      });
      this.filterOptions.position = position;
      this.position = position;
    },

    /**
     * @memberof module:common
     * @description handle on change in page size
     */
    onPageSizeChanged(event) {
      const rowCount = Number(event.target.value);
      this.gridData.paginationPageSize = rowCount;
      this.gridData.cacheBlockSize = rowCount;
      this.paginationSize = rowCount;
    },

    /**
     * @memberof module:common
     * @description handles on table
     */
    setEmploymentStatus(employmentStatus) {
      this.employmentStatus = employmentStatus;
      this.filterOptions.employmentStatus = employmentStatus;
      this.refreshGrid();
    },
    /**
     * @memberof module:common
     * @description handles on refresh grid
     */
    refreshGrid() {
      this.setDataSource();
      this.resetRowCountData();
      delete this.filterOptions.applicationStatus;
      delete this.filterOptions.position;

      this.updateRoute();
      this.applicationStatus = null;
      this.position = null;
      if (this.isApplicantView) {
        this.applicationStatusCount(this.filterOptions);
        this.applicationPositionCount(this.filterOptions);
      } else {
        this.refreshRegisteredApplicantListGrid();
      }
      this.fetchFreshApplicationCount();
    },
    fetchFreshApplicationCount() {
      this.$store.dispatch(
        "$_applicants_data/fetchFreshApplicationCount",
        "G" //Graduates
      );
      this.$store.dispatch(
        "$_applicants_data/fetchFreshApplicationCount",
        "P" //Professionals
      );
    },
    /**
     * @memberof module:common
     * @description select all applicant on current page
     */
    selectAllOnCurrentPage() {
      const result = this.rowCountOnCurrentPage !== this.selectedRowCount;
      this.selectedRowCount = 0;
      this.gridData.api.forEachNode((node) => {
        node.setSelected(result);
        this.selectedRowCount++;
      });
      this.rowCountOnCurrentPage = this.selectedRowCount;
    },
    resetRowCountData() {
      this.rowCountOnCurrentPage = -1;
      this.selectedRowCount = 0;
    },
    updateRoute() {
      if (this.filterOptions.employmentStatus) {
        this.$route.query.status !== this.filterOptions.employmentStatus &&
          this.$router.replace({
            path: `/dashboard/applications/list?status=${this.filterOptions.employmentStatus}`,
          });
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @returns {Boolean}
     * @description Check whether there is atleast one applicant selected to perform any action
     */
    isAtleastOneSelected() {
      if (this.selectedApplicants.length > 0) {
        return true;
      } else {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select atleast one applicant",
          false,
          "danger",
          "modal-m"
        );
        return false;
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @returns {Boolean}
     * @description Check if there is any applicant from selected applicants who has matching profile
     */
    isUnsolvedConflict() {
      let unsolvedConflict = false;
      //Skip conflict validation for Freshers
      if (this.employmentStatus !== "G") {
        this.selectedApplicants.forEach((element) => {
          if (element.hasDuplicate.length > 0) {
            unsolvedConflict = true;
          }
        });
      }
      if (unsolvedConflict) {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "There are conflicts in one or more selected applicants",
          false,
          "danger",
          "modal-m"
        );
        return true;
      } else {
        return false;
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @returns {Boolean}
     * @description Check whether all the applicants belong to same campus or none of them are from campus
     */
    isCampusConflict() {
      let campusConflict = false;
      this.selectedApplicants.forEach((element) => {
        if (
          this.selectedApplicants[0].belongsToCampusRecruitement &&
          element.belongsToCampusRecruitement
        ) {
          if (
            this.selectedApplicants[0].belongsToCampusRecruitement
              .recruitment_session_name !==
            element.belongsToCampusRecruitement.recruitment_session_name
          ) {
            campusConflict = true;
          }
        } else if (
          !this.selectedApplicants[0].belongsToCampusRecruitement &&
          element.belongsToCampusRecruitement
        ) {
          campusConflict = true;
        } else if (
          this.selectedApplicants[0].belongsToCampusRecruitement &&
          !element.belongsToCampusRecruitement
        ) {
          campusConflict = true;
        }
      });
      if (campusConflict) {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Selected applicants should belong to same campus or should not belong to any Campus",
          false,
          "danger",
          "modal-m"
        );
        return true;
      } else {
        return false;
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @param {String} matchRegex
     * @returns {Boolean}
     * @description Check whether selected applicants are valid to perform required action
     */
    isMatchSelected(matchRegex) {
      let matchSelected = true;
      this.selectedApplicants.forEach((element) => {
        if (matchRegex.test(element.application_status)) {
          matchSelected = false;
        }
      });
      return matchSelected;
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @returns {Boolean}
     * @description Checks for all those validations which are required to perform any action
     */
    isCommonConflict() {
      if (this.isAtleastOneSelected()) {
        if (!this.isUnsolvedConflict()) {
          return this.isCampusConflict();
        } else {
          return true;
        }
      } else {
        return true;
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description Check whether there is atleast one applicant selected to perform any action
     */
    scheduleNextRound() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS.APPLICANT.ROUND.CREATE],
        })
      )
        return;
      if (!this.isCommonConflict()) {
        if (this.isMatchSelected(/Scheduled/)) {
          this.openDialog(
            "commonModal",
            "Confirmation",
            "Are you sure you want to schedule next round for these applicant(s)?",
            true,
            "warn",
            "modal-m",
            true
          )
            .then(() => {
              this.$store.dispatch(
                "$_applicants_data/storeSelectedApplicants",
                this.selectedApplicants
              );
              $("#addRound").modal("show");
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Applicant cannot have previous round as Pending",
            false,
            "danger",
            "modal-m",
            false
          );
        }
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description Called everytime the selected rows get changed in the grid
     */
    onSelectionChanged() {
      this.selectedApplicants = this.gridData.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description Shortlist multiple applicants and change main application status to selected
     */
    shortlistApplicants() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.UPDATE] }))
        return;
      if (!this.isCommonConflict()) {
        if (this.isMatchSelected(/Scheduled|Shortlisted/)) {
          this.openDialog(
            "commonModal",
            "Confirmation",
            "Are you sure you want to shortlist these applicant(s)?",
            true,
            "warn",
            "modal-m",
            true
          )
            .then(() => {
              this.flag = "ShortlistApplicant";
              if (this.selectedApplicants[0].belongsToCampusRecruitement) {
                $("#selectToBeMailed").modal("show");
              } else {
                let dataToBeUpdated = [];
                this.selectedApplicants.forEach((element) => {
                  dataToBeUpdated.push({
                    id: element.id,
                    application_status: "Shortlisted",
                  });
                });
                let campusId;
                if (!this.isApplicantView) campusId = this.campusID;
                this.$store.dispatch("$_applicants_data/shortlistApplicants", {
                  data: {
                    dataToBeUpdated: dataToBeUpdated,
                    selectedApplicants: this.selectedApplicants,
                    isApplicantView: this.isApplicantView,
                    campusId: campusId,
                  },
                  redirect: this.$route.fullPath,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Deselect or Pass any Pending Rounds",
            false,
            "danger",
            "modal-m"
          );
        }
      }
    },

    handleSendMailButton() {
      setTimeout(() => {
        this.$store.dispatch("$_mailbox/setApplicantsData", {
          data: this.selectedApplicants,
        });
      }, 300);
      this.$router.push({
        name: "mailbox",
        query: {
          key: "MailTo",
          redirect: this.$route.fullPath,
        },
      });
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description Differ multiple applicants and change main application status to differed
     */
    differApplicants() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.UPDATE] }))
        return;
      if (!this.isCommonConflict()) {
        if (this.isMatchSelected(/Scheduled/)) {
          this.openDialog(
            "commonModal",
            "Confirmation",
            "Are you sure you want to differ these applicant(s)?",
            true,
            "warn",
            "modal-m",
            true
          )
            .then(() => {
              this.flag = "DifferApplicant";
              if (this.selectedApplicants[0].belongsToCampusRecruitement) {
                $("#selectToBeMailed").modal("show");
              } else {
                let dataToBeUpdated = [];
                this.selectedApplicants.forEach((element) => {
                  dataToBeUpdated.push({
                    id: element.id,
                    application_status: "Differed",
                  });
                });
                let campusId;
                if (!this.isApplicantView) campusId = this.campusID;
                this.$store.dispatch("$_applicants_data/differApplicants", {
                  data: {
                    dataToBeUpdated: dataToBeUpdated,
                    selectedApplicants: this.selectedApplicants,
                    isApplicantView: this.isApplicantView,
                    campusId: campusId,
                  },
                  redirect: this.$route.fullPath,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Deselect or Fail any Pending Rounds",
            false,
            "danger",
            "modal-m"
          );
        }
      }
    },
    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description Move multiple applicants to Talent Pool and change main application status to Talent Pool
     */
    moveApplicantsToTalentPool() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.UPDATE] }))
        return;
      if (!this.isCommonConflict()) {
        if (this.isMatchSelected(/Differed|Joined|Shortlisted/)) {
          this.openDialog(
            "commonModal",
            "Confirmation",
            "Are you sure you want to move these applicant(s) to Talent Pool?",
            true,
            "warn",
            "modal-m",
            true
          )
            .then(() => {
              this.flag = "MoveToTalentPool";
              if (this.selectedApplicants[0].belongsToCampusRecruitement) {
                $("#selectToBeMailed").modal("show");
              } else {
                let dataToBeUpdated = [];
                this.selectedApplicants.forEach((element) => {
                  dataToBeUpdated.push({
                    id: element.id,
                    application_status: "Talent Pool",
                  });
                });
                let campusId;
                if (!this.isApplicantView) campusId = this.campusID;
                this.$store.dispatch(
                  "$_applicants_data/moveApplicantsToTalentPool",
                  {
                    data: {
                      dataToBeUpdated: dataToBeUpdated,
                      selectedApplicants: this.selectedApplicants,
                      isApplicantView: this.isApplicantView,
                      campusId: campusId,
                    },
                    redirect: this.$route.fullPath,
                  }
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Deselect or Fail any Pending Rounds",
            false,
            "danger",
            "modal-m"
          );
        }
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description Pass rounds of multiple applicants
     */
    passLastRound() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS.APPLICANT.ROUND.UPDATE],
        })
      )
        return;
      if (!this.isCommonConflict()) {
        if (
          this.isMatchSelected(
            /Passed|Failed|Cancelled|Shortlisted|Differed|Received/
          )
        ) {
          this.openDialog(
            "commonModal",
            "Confirmation",
            "Are you sure you want to set these round(s) to Passed?",
            true,
            "warn",
            "modal-m",
            true
          )
            .then(() => {
              let dataToBeUpdated = [];
              this.selectedApplicants.forEach((element) => {
                dataToBeUpdated.push({
                  id: element.id,
                  application_status: element.application_status.replace(
                    "Scheduled",
                    "Passed"
                  ),
                });
              });
              let campusId;
              if (!this.isApplicantView) campusId = this.campusID;
              this.$store.dispatch("$_applicants_data/passLastRound", {
                dataToBeUpdated,
                isApplicantView: this.isApplicantView,
                campusId: campusId,
              });
              this.selectedApplicants.length = 0;
              this.resetRowCountData();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Only Scheduled rounds can be passed",
            false,
            "danger",
            "modal-m"
          );
        }
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description Fail rounds of multiple applicants
     */
    failLastRound() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS.APPLICANT.ROUND.UPDATE],
        })
      )
        return;
      if (!this.isCommonConflict()) {
        if (
          this.isMatchSelected(
            /Passed|Failed|Cancelled|Shortlisted|Differed|Received/
          )
        ) {
          this.openDialog(
            "commonModal",
            "Confirmation",
            "Are you sure you want to set these round(s) to Failed?",
            true,
            "warn",
            "modal-m",
            true
          )
            .then(() => {
              let dataToBeUpdated = [];
              this.selectedApplicants.forEach((element) => {
                dataToBeUpdated.push({
                  id: element.id,
                  application_status: element.application_status.replace(
                    "Scheduled",
                    "Failed"
                  ),
                });
              });
              let campusId;
              if (!this.isApplicantView) campusId = this.campusID;
              this.$store.dispatch("$_applicants_data/failLastRound", {
                dataToBeUpdated,
                isApplicantView: this.isApplicantView,
                campusId: campusId,
              });
              this.selectedApplicants.length = 0;
              this.resetRowCountData();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Only Scheduled rounds can be failed",
            false,
            "danger",
            "modal-m"
          );
        }
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description Revert rounds of multiple applicants
     */
    revertLastRound() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS.APPLICANT.ROUND.UPDATE],
        })
      )
        return;
      if (!this.isCommonConflict()) {
        if (this.isMatchSelected(/Scheduled|Shortlisted|Differed|Received/)) {
          this.openDialog(
            "commonModal",
            "Confirmation",
            "Are you sure you want to revert these round(s)?",
            true,
            "warn",
            "modal-m",
            true
          )
            .then(() => {
              let dataToBeUpdated = [];
              this.selectedApplicants.forEach((element) => {
                dataToBeUpdated.push({
                  id: element.id,
                  application_status: element.application_status.replace(
                    /Passed|Failed|Cancelled/,
                    "Scheduled"
                  ),
                });
              });
              let campusId;
              if (!this.isApplicantView) campusId = this.campusID;
              this.$store.dispatch("$_applicants_data/revertLastRound", {
                dataToBeUpdated,
                isApplicantView: this.isApplicantView,
                campusId: campusId,
              });
              this.selectedApplicants.length = 0;
              this.resetRowCountData();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Only Passed, Failed or Cancelled rounds can be reverted",
            false,
            "danger",
            "modal-m"
          );
        }
      }
    },

    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description Cancel round of multiple applicants
     */
    cancelLastRound() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS.APPLICANT.ROUND.UPDATE],
        })
      )
        return;
      if (!this.isCommonConflict()) {
        if (
          this.isMatchSelected(
            /Passed|Failed|Cancelled|Shortlisted|Differed|Received/
          )
        ) {
          this.openDialog(
            "commonModal",
            "Confirmation",
            "Are you sure you want to cancel these round(s)?",
            true,
            "warn",
            "modal-m"
          )
            .then(() => {
              this.flag = "CancelAction";
              if (this.selectedApplicants[0].belongsToCampusRecruitement) {
                $("#selectToBeMailed").modal("show");
              } else {
                let dataToBeUpdated = [];
                this.selectedApplicants.forEach((element) => {
                  dataToBeUpdated.push({
                    id: element.id,
                    application_status: element.application_status.replace(
                      "Scheduled",
                      "Cancelled"
                    ),
                  });
                });
                let campusId;
                if (!this.isApplicantView) campusId = this.campusID;
                this.$store.dispatch("$_applicants_data/cancelLastRound", {
                  data: {
                    dataToBeUpdated: dataToBeUpdated,
                    selectedApplicants: this.selectedApplicants,
                    isApplicantView: this.isApplicantView,
                    campusId: campusId,
                  },
                  redirect: this.$route.fullPath,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.openDialog(
            "commonModal",
            "Invalid action",
            "Cannot perform desired action. Only Scheduled rounds can be cancelled",
            false,
            "danger",
            "modal-m"
          );
        }
      }
    },
    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description mail about campgain to selected applicants
     */
    RunCampaign() {
      if (!this.hasAccess({ permissions: [this.PERMISSIONS.MAIL.CREATE] }))
        return;
      if (this.isAtleastOneSelected()) {
        this.$store.dispatch("$_applicants_data/RunCampaign", {
          data: this.selectedApplicants,
          redirect: this.$route.fullPath,
        });
      }
    },
    /**
     * @memberOf module:Coomon.components.applicants_list
     * @description exporting applicant data to csv
     */
    downloadData() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      if (this.isAtleastOneSelected()) {
        let columns = this.gridData.api.getAllDisplayedColumns();
        columns = columns.filter(function (col) {
          return !col.colDef.display_only;
        });
        let excel_param = {
          columnKeys: columns,
          onlySelected: this.selectApplicants,
          fileName: "Applicant's Data.csv",
        };
        this.gridData.api.exportDataAsCsv(excel_param);
      }
    },
    // onGridReady() {
    //   this.setDataSource();
    // },
    onGridReady(params) {
      if (params.api) {
        this.gridData.api = params.api;
        this.setDataSource();
      }
    },
    async filter() {
      if (this.gridData.api) {
        if (this.filterOptions.applicationDate?.filterType) {
          let fromDate = "";
          let toDate = "";
          if (
            typeof this.filterOptions.applicationDate.fromDate === "object" &&
            this.filterOptions.applicationDate.fromDate != null
          ) {
            fromDate = this.filterOptions.applicationDate.fromDate.toJSON();
            toDate =
              this.filterOptions.applicationDate.toDate || new Date().toJSON();
          } else {
            fromDate = this.filterOptions.applicationDate.fromDate;
            toDate =
              this.filterOptions.applicationDate.toDate || new Date().toJSON();
          }
          fromDate = this.dateFormatter(fromDate);
          toDate = this.dateFormatter(toDate);

          const instance = await this.gridData.api.getColumnFilterInstance(
            "createdAt"
          );
          if (instance) {
            const data = await instance.getModel();
            if (data) {
              data.filterType = "date";
              data.type = "inRange";
              data.dateFrom = fromDate;
              data.dateTo = toDate;
              await instance.setModel(data);
            }
          }
        } else {
          const instance = await this.gridData.api.getColumnFilterInstance(
            "createdAt"
          );
          if (instance) {
            await instance.setModel(null);
          }
        }
        this.gridData.api.onFilterChanged();
      }
    },
    dateFormatter(date) {
      let tempDate = new Date(date);
      tempDate = new Date(
        tempDate.getTime() - tempDate.getTimezoneOffset() * 60 * 1000
      );
      tempDate = JSON.stringify(tempDate);
      tempDate = tempDate.slice(1, -1);
      return tempDate;
    },
    async resetFilter() {
      const instanceCreatedAt = await this.gridData.api.getColumnFilterInstance(
        "createdAt"
      );
      if (instanceCreatedAt) {
        await instanceCreatedAt.setModel(null);
      }

      const instanceApplicationStatus =
        await this.gridData.api.getColumnFilterInstance("application_status");
      if (instanceApplicationStatus) {
        await instanceApplicationStatus.setModel(null);
      }
      const instancePosition = await this.gridData.api.getColumnFilterInstance(
        "position"
      );
      if (instancePosition) {
        await instancePosition.setModel(null);
      }

      this.gridData.api.onFilterChanged();
      this.refreshGrid();
    },
    applicationStatusCount(filterOptions) {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.$store.dispatch("$_applicants_data/fetchApplicationStatusCount", {
        includeAll: this.includeAll,
        ...filterOptions,
        ...filterOptions.applicationDate,
        employmentStatus: this.employmentStatus,
        ...this.filterOptions.moreFilters,
      });
    },

    applicationPositionCount(filterOptions) {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.$store.dispatch("$_applicants_data/fetchApplicationPositionCount", {
        ...filterOptions,
        includeAll: this.includeAll,
        ...filterOptions.applicationDate,
        employmentStatus: this.employmentStatus,
        ...this.filterOptions.moreFilters,
      });
    },
    configGridOptions() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.gridData = {
        rowHeight: 30,
        suppressPropertyNamesCheck: true,
        enableCellTextSelection: true,
        enableServerSideFilter: true,
        enableColResize: true,
        rowSelection: "multiple",
        // rowDeselection: true,
        suppressRowDeselection: true,
        rowModelType: "infinite",
        defaultColDef: {
          flex: 1,
          floatingFilter: true,
          resizable: true,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
          // set filtering on for all columns
          filter: true,
        },
        columnDefs: getFilterColumns(this.columnDefs, 1, "lg"),
        infiniteInitialRowCount: 0,
        animateRows: true,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        pagination: true,
        paginationPageSize: 100,
        paginationPageSizeSelector: false,

        cacheBlockSize: 100,
        context: {
          componentParent: this,
        },
      };
    },
    searchApiPromise(searchQuery) {
      return axios.post("applicants/search?searchQuery=" + searchQuery);
    },
    searchRouterLink(slug) {
      return { name: "applicantprofile", params: { slug } };
    },
  },
  beforeMount() {
    this.configGridOptions();
  },
  mounted() {
    $(document).on("click", "div.dropdown-filters.dropdown-menu", function (e) {
      e.stopPropagation();
    });
    this.title = this.isApplicantView
      ? "Applications"
      : "Campus Recruitment Applications";
    this.applicationStatusCount(this.filterOptions);
    this.applicationPositionCount(this.filterOptions);
    this.fetchFreshApplicationCount();
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
  padding: 10px 20px;
}
#page-size {
  background: #8f1452;
  color: #fff !important;
  padding: 8px 20px;
  border: 1px solid #903564;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 17px;
}

.pagesize {
  margin-top: 2px;
  margin-right: 30px;
  width: 100px;
}

.pagesizeoption {
  background-color: white;
  color: black;
}

.ag-theme-balham
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
}

.ag-grid-style {
  width: 100%;
  height: 86%;
}

.dashboard-card {
  padding: 10px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 15px;
  overflow-x: auto;
}

.dashboard-box-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.application-container {
  margin: 20px 40px 20px 20px;
}

.dropdown-menu {
  top: 50px;
}

.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 3px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #803059;
  color: white;
}

.dropdown-menu .dropdown-header {
  padding: 3px 20px;
  color: #823e5b;
  font-size: 16px;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

li.dropdown > a {
  color: white;
}

.pending-count-circle {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  background-color: #9b3f6f;
  position: absolute;
  top: 7px;
  right: 19px;
}

.pre-number {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  background-color: #9b3f6f;
  position: relative;
  top: -10px;
  right: 5px;
}

@media screen and (max-width: 767px) {
  .spinner {
    display: none;
  }

  .top-buttons {
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .hide-on-desktop {
    display: none !important;
  }
}
.media-button {
  width: 170px;
}
</style>
<!-- <style lang="scss" scoped>
::v-deep .ag-theme-alpine {
  .ag-row {
    border-width: 0px !important;
  }
}
</style> -->
