import { ARRAY_FILTER_PARAMS } from "../../Common/_utils/filter-params-constants";

export const COMMON_DATA_COL_DEFS = [
  {
    cellRenderer: "profile-link",
    width: 30,
    minWidth: 30,
    maxWidth: 30,
    suppressFilter: true,
    filter: false,
    valueGetter: "node.id",
    headerTooltip: "Profile Info",
    tooltipValueGetter: function (params) {
      if (params.data != undefined) {
        return "Profile of " + params.data.first_name;
      }
      return "";
    },
    pinned: "left",
    display_only: true,
    cellStyle: { textAlign: "center", padding: 0 },
  },
  {
    cellRenderer: "resolve-multiple-round",
    width: 30,
    minWidth: 30,
    maxWidth: 30,
    suppressFilter: true,
    filter: false,
    headerTooltip: "Rounds Info",
    valueGetter: "node.id",
    pinned: "left",
    display_only: true,
    cellStyle: { textAlign: "center", padding: 0 },
  },
  {
    headerName: "Name",
    cellRenderer: "drag-from-cell",
    field: "name",
    valueGetter: function mergeLastNameFirstName(params) {
      if (params.data != undefined) {
        return params.data.first_name + " " + params.data.last_name;
      }
    },
    sortable: true,
    width: 200,
    minWidth: 200,
    display_only: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    suppressHeaderMenuButton: true,
    filterParams: {
      newRowsAction: "keep",
    },
    comparator: (valueA, valueB) => {
      if (valueA && valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      }
      return 0;
    },
  },

  {
    headerName: "Application Status",
    field: "application_status",
    cellRenderer: "drag-from-cell",
    sortable: true,
    filter: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Email",
    field: "email",
    cellRenderer: "drag-from-cell",
    sortable: true,
    comparator: (valueA, valueB) => {
      if (valueA && valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      }
      return 0;
    },
    width: 200,
    minWidth: 200,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Mobile",
    field: "mobile_no",
    cellRenderer: "drag-from-cell",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Application Date",
    field: "createdAt",
    cellRenderer: "drag-from-cell",
    width: 200,
    minWidth: 200,
    sortable: true,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        let dt = new Date(params.data.createdAt);
        return dt.toLocaleDateString().toString();
      }
    },
    // pinned: "left",
    filter: "agDateColumnFilter",
    suppressHeaderMenuButton: true,
  },

  {
    headerName: "Applicant",
    field: "status",
    cellRenderer: "drag-from-cell",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Emp. Status",
    field: "employment_status",
    cellRenderer: "drag-from-cell",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Skills",
    field: "skills",
    sortable: true,
    cellRenderer: "drag-from-cell",
    width: 170,
    minWidth: 170,
    suppressHeaderMenuButton: true,
    filterParams: {
      filterOptions: ARRAY_FILTER_PARAMS,
    },
  },
  {
    headerName: "DOB",
    field: "dob",
    cellRenderer: "drag-from-cell",
    sortable: true,
    width: 150,
    minWidth: 150,
    suppressHeaderMenuButton: true,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        let dt = new Date(params.data.dob);
        return dt.toLocaleDateString().toString();
      }
    },
    filter: "agDateColumnFilter",
  },
  {
    headerName: "Gender",
    field: "gender",
    cellRenderer: "drag-from-cell",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "Alt.Mobile",
    field: "alternate_mobile_no",
    cellRenderer: "drag-from-cell",
    sortable: true,
    width: 100,
    minWidth: 100,
    suppressHeaderMenuButton: true,
  },
  {
    headerName: "College Name",
    field: "college_name",
    cellRenderer: "drag-from-cell",
    sortable: true,
    width: 200,
    minWidth: 200,
    comparator: (valueA, valueB) => {
      if (valueA && valueB) {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      }
      return 0;
    },
  },
];
