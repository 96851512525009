<template>
  <base-modal
    :title="mailData ? mailData.mail_subject : ''"
    id="viewMailModal"
    size="lg"
    modalBodyClass="p-2"
    :toggle="true"
  >
    <div slot="headerSlot" v-if="mailData">
      <span class="heading">Status: </span>
      <span :class="this.mailData.mail_status">{{ mailStatus }}</span>
    </div>
    <div class="template-container" v-if="mailData">
      <div>
        <p class="sub-margin"><span class="heading">To:</span>{{ mailTo }}</p>
        <div
          class="sub-margin"
          v-if="mailData.mail_cc && mailData.mail_cc.length && viewAll"
        >
          <span class="heading">CC:</span>
          {{ mailData.mail_cc.join(", ") }}
        </div>
        <div
          class="sub-margin"
          v-if="mailData.mail_bcc && mailData.mail_bcc.length && viewAll"
        >
          <span class="heading">BCC:</span>
          {{ mailData.mail_bcc.join(", ") }}
        </div>
      </div>
      <div>
        <button
          type="button"
          title="View More Info"
          class="btn"
          @click="viewAll = !viewAll"
          v-if="viewEye"
        >
          <i
            class="fa fa-solid"
            :class="viewAll ? 'fa-eye-slash' : 'fa-eye'"
          ></i>
        </button>
      </div>
    </div>
    <mail-component v-if="mailData" :mailData="mailData.mail_body">
    </mail-component>
    <div v-if="mailData">
      <p class="text-danger" v-if="mailData.error">* {{ mailData.error }}</p>
    </div>
    <div slot="footer" v-if="mailData">
      <button type="button" class="btn btn-cp" data-dismiss="modal">Ok</button>
    </div>
  </base-modal>
</template>

<script>
/**
 * @memberof module:MailBox
 * @namespace components.view_template_modal
 * @description Modal for preview of template
 */

import baseModal from "@/modules/Common/_components/base-modal.vue";
import MailComponent from "@/modules/ApplicantProfile/_components/mail-component";
export default {
  props: ["mailData"],
  components: { baseModal, MailComponent },
  computed: {
    mailStatus: function () {
      return this.mailData.mail_status
        .match(/[a-z]+/gi)
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
        })
        .join("");
    },
    mailTo: function () {
      return this.mailData.mail_to.includes(",") && !this.viewAll
        ? this.mailData.mail_to.split(",")[0] + "..."
        : this.mailData.mail_to.split(",").join(", ");
    },
    viewEye: function () {
      return (
        this.mailData.mail_bcc.length ||
        this.mailData.mail_cc.length ||
        this.mailData.mail_to.includes(",")
      );
    },
  },
  watch: {
    mailData() {
      this.viewAll = false;
    },
  },
  data() {
    return {
      viewAll: false,
    };
  },
  beforeDestroy() {
    $("#viewMailModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.template-container {
  border: 1px solid #8b509661;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  margin-bottom: 10px;
  justify-content: space-between;
}
.template-frame {
  border: 1px solid #d1b0d8;
  box-shadow: border-box;
  border-radius: 5px;
  width: 100%;
  min-height: 200px;
}
.mailed {
  color: rgb(77, 211, 77);
  font-weight: 700;
}
.pending {
  color: rgb(225, 72, 72);
  font-weight: 700;
}
</style>
