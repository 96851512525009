<template>
  <div style="margin-top: -10px">
    <form @submit.prevent="handleSubmit">
      <div class="row">
        <div class="col-12">
          <label class="d-block required" htmlFor="status">Status: </label>
          <div class="form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                key="accepted"
                class="form-check-input"
                value="accepted"
                v-model="formData.status"
                v-validate="'required'"
                name="status"
              />
              Accept
            </label>
          </div>
          <div class="form-check-inline">
            <label class="form-check-label">
              <input
                type="radio"
                key="rejected"
                class="form-check-input"
                value="rejected"
                v-model="formData.status"
                v-validate="'required'"
                name="status"
              />
              Reject
            </label>
          </div>
          <span
            v-if="errors.first('status')"
            class="valid-feedback no-lable alert-danger mt-3"
            >{{ errors.first("status") }}</span
          >
        </div>

        <div class="col-12 mt-2">
          <label htmlFor="comments">Comments: </label>
          <textarea
            type="text"
            name="comments"
            key="comments"
            v-validate="'max:50'"
            placeholder="Please write your comments here"
            :class="{
              invalid: errors.first('comments'),
            }"
            class="form-control"
            data-vv-as="Comments"
            v-model="formData.comments"
          ></textarea>
          <span
            v-if="errors.first('comments')"
            class="valid-feedback alert-danger top-15"
            >{{ errors.first("comments") }}</span
          >
        </div>

        <div class="col-12">
          <button type="submit" class="mt-3 btn btn-cp float-right">
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    submitForm: {
      type: Function,
    },
  },
  data() {
    return {
      formData: {
        status: "",
        comments: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.submitForm(this.formData);
        }
      });
    },
  },
};
</script>

<style scoped>
.top-15 {
  top: 15px;
}

label {
  font-weight: 600;
  font-size: 14px;
}
</style>
