<template>
  <div>
    <label :for="'input-para-' + _uid">
      {{ label }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <b-form-textarea
      class="form-control form-control-sm mb-1"
      :id="'input-para-' + _uid"
      :placeholder="properties.placeholder"
      rows="1"
      max-rows="21"
      :value="value"
      @input="handleInput"
    ></b-form-textarea>
    <p class="text-danger" v-for="(error, idx) in errorList" :key="idx">
      <b-icon-exclamation-circle></b-icon-exclamation-circle>
      <span class="ms-2">{{ error }}</span>
    </p>
  </div>
</template>

<script>
import validators from "../../_utils/validators";

export default {
  name: "line-input-builder",
  props: {
    value: String,
    label: String,
    properties: Object,
    useValidation: Boolean,
    validators: Array,
  },
  data() {
    return {
      errorList: [],
      previewInputClass: "",
      previewError: "",
    };
  },
  computed: {
    isRequired() {
      return (
        this.useValidation &&
        this.validators.some((e) => e.validator === "required")
      );
    },
  },
  methods: {
    checkError(input) {
      this.errorList = [];
      for (const validator of this.validators) {
        const err = validators[validator.validator](input, validator);
        if (err) {
          this.errorList.push(err);
        }
      }
      if (this.errorList.length !== 0) {
        this.$emit("error", {
          key: this.key,
          errors: this.errorList,
        });
        return true;
      }
      return false;
    },
    handleInput(inp) {
      this.$emit("input", inp);
      this.useValidation && this.checkError(inp);
    },
  },
};
</script>
