<template>
  <iframe
    v-if="mailData"
    title="static mail template"
    class="template-frame"
    :srcdoc="mailData"
    width="100%"
    @load="getHeight"
    ref="iframe"
  ></iframe>
</template>

<script>
export default {
  props: ["mailData"],

  methods: {
    getHeight() {
      this.$nextTick(() => {
        const iframe = this.$refs.iframe;
        if (iframe && iframe.contentWindow) {
          const doc = iframe.contentWindow.document;

          if (this.resizeObserver) {
            this.resizeObserver.disconnect();
          }

          let timeout;
          this.resizeObserver = new ResizeObserver(() => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              iframe.style.height = doc.body.scrollHeight + 40 + "px";
            }, 100);
          });
          this.resizeObserver.observe(doc.body);
          iframe.style.height = doc.body.scrollHeight + 40 + "px";
        }
      });
    },
  },
  data() {
    return {
      resizeObserver: null,
    };
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
};
</script>

<style scoped>
.template-frame {
  border: 1px solid #d1b0d8;
  box-shadow: border-box;
  border-radius: 5px;
  width: 100%;
  min-height: 200px;
}
</style>
