<template>
  <div>
    <div ref="calendarParent">
      <FullCalendar
        id="calendar"
        name="calendar"
        ref="calendar"
        class="fullcalendar schedule-app-calendar fullcalendar-header-style-md"
        style="height: 250px"
        :options="calendarOptions"
      >
        <template v-slot:eventContent="arg">
          <div
            data-toggle="tooltip"
            data-placement="top"
            title="go to event slot"
            class="w-100 slot-div"
            @click="handleEventClick(arg)"
          >
            <div
              style="width: 100%"
              class="d-flex"
              :class="{
                'event-title-inactive':
                  arg.event.extendedProps.status != 'Pending' ||
                  !scheduleDateValid(arg.event.extendedProps.to_time),
              }"
            >
              <div class="right-div d-flex justify-content-between pr-1">
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="arg.event.start"
                  class="event-time px-1"
                >
                  {{ extractTime(arg.event.extendedProps.round_schedule) }}
                </div>
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="arg.event.start"
                  class="event-time"
                >
                  {{ extractTime(arg.event.extendedProps.to_time) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import api from "../_api/index";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  props: ["selectedEvent"],
  data() {
    return {
      ovserver: null,
      date: null,
      config: {
        format: "DD MMMM YYYY",
        inline: true,
        useCurrent: false,
        showClear: false,
        showClose: false,
        sideBySide: false,
        icons: {
          time: "fa fa-address-book",
          date: "fa fa-calendar",
          up: "fa fa-arrow-up",
          down: "fa fa-arrow-down",
          previous: "fa fa-chevron-left",
          next: "fa fa-chevron-right",
          today: "fa fa-calendar-check",
          clear: "fa fa-trash-alt",
          close: "fa fa-times-circle",
        },
      },
      calendarOptions: {
        customButtons: {
          weekends: {
            text: "weekends",
            hint: "toggle weekends",
            click: () => {
              this.handleWeekendsToggle();
            },
          },
          viewAll: {
            text: "view all",
            hint: "toggle view all",
            click: () => {
              this.handleViewAllToggle();
            },
          },
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "weekends,viewAll dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        contentHeight: 460,
        weekends: false,
        select: this.handleDateSelect,
        eventSources: [
          {
            events: this.fetchEvents,
          },
        ],
        lazyFetching: false,
      },
      pendingEvents: null,
      events: null,
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
  },
  components: {
    FullCalendar,
  },

  watch: {
    selectedEvent(value) {
      if (value) {
        this.refetchEvents();
      }
    },
  },

  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    handleViewAllToggle() {
      this.calendarOptions.dayMaxEvents = !this.calendarOptions.dayMaxEvents;
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description refresh calendar events
     */
    refetchEvents() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },

    clickToday() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.today();
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description format date to display
     */
    formatDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description extract date from datetime
     */
    extractDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description extract time from datetime
     */
    extractTime(date) {
      return moment(date).format("h:mm A");
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description select date range
     */
    handleDateSelect() {},

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description handle click event and open edit event modal
     */
    handleEventClick(clickInfo) {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.MENU],
        })
      )
        return;
      this.$router.push({
        name: "scheduleEventSlot",
        params: { eventId: clickInfo.event.extendedProps.eventId },
        query: { slot: clickInfo.event.extendedProps.slotId },
      });
    },

    /**
     * @memberof module:CampusRecruitment.components.campus-event-modal
     * @description fetch events for campus
     */
    fetchEvents(config, successCallback, failureCallback) {
      let filters = {
        startDate: config.start,
        endDate: config.end,
        eventId: this.selectedEvent,
      };
      if (this.selectedEvent) {
        api
          .getMyScheduleEventSlots(filters)
          .then((response) => {
            let calendarApi = this.$refs.calendar.getApi();
            const events = response.data.map((slot) => {
              let slotData = {
                ...slot,
                start: slot.from_time,
                end: slot.to_time,
                eventId: slot.roundId,
                slotId: slot.id,
                backgroundColor: this.getEventColor(slot.to_time),
                borderColor: this.getEventColor(slot.to_time),
              };
              if (calendarApi && calendarApi.view.type != "dayGridMonth") {
                return {
                  ...slotData,
                  startRecur: moment(new Date(slot.from_time)).format(
                    "YYYY-MM-DD"
                  ),
                  endRecur: moment(new Date(slot.to_time))
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                  startTime: moment(new Date(slot.from_time)).format("HH:mm"),
                  endTime: moment(new Date(slot.to_time)).format("HH:mm"),
                  roundName: slot.round_name,
                  allDay: false,
                };
              } else {
                return {
                  ...slotData,
                  allDay: true,
                };
              }
            });
            this.events = events;
            successCallback(events);
          })
          .catch((error) => {
            console.log("error", error);
            failureCallback([]);
          });
      } else {
        successCallback([]);
      }
    },

    getEventColor(end_time) {
      let color = "line";
      if (!moment(end_time).isAfter(new Date())) {
        return "grey";
      }
      return color;
    },

    filterEvents() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },

    scheduleDateValid(date) {
      return moment(date).isAfter(new Date());
    },
    onResize() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.updateSize();
    },
  },
  mounted() {
    this.ovserver = new ResizeObserver(this.onResize);
    this.ovserver.observe(this.$refs.calendarParent);
  },
  beforeDestroy() {
    this.ovserver.unobserve(this.$refs.calendarParent);
  },
};
</script>

<style scoped>
.slot-div {
  cursor: pointer;
}
.schedule-app-main {
  flex-grow: 1;
  padding-bottom: 10px;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  margin: 0 auto;
  text-transform: capitalize !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}

.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 16px;
  width: 16px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 16px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.span-circle {
  min-width: 16px;
  min-height: 16px;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-div {
  width: 100%;
}
</style>
<style>
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em !important;
}

.fc .fc-header-toolbar button {
  max-height: 40px;
  padding: 5px;
}

.fc .fc-header-toolbar .fc-toolbar-title {
  font-size: 20px;
}

.fc {
  font-size: 14px;
}

.fc .fc-daygrid-day-number {
  padding: 1px !important;
  font-size: 12px;
  line-height: 14px;
}

.fc .fc-daygrid-day-bottom a {
  font-size: 14px;
}

.fc .fc-daygrid tr td {
  height: 30px !important;
  max-height: 20px !important;
  min-height: 20px !important;
}
</style>
