import axios from "axios";

const prefix = "/shared-token";

const createSharedToken = (data) => {
  return axios.post(prefix, { data });
};

const getAllSharedTokens = (params = {}) => {
  return axios.get(prefix, { params });
};

const getEndpointObject = () => {
  return axios.get(prefix + "/endpoints");
};

const getSharedTokenById = (id) => {
  return axios.get(prefix + `/${id}`);
};

const updateSharedToken = (data) => {
  return axios.patch(prefix, { data });
};

const deleteSharedToken = (id) => {
  return axios.delete(prefix + `/${id}`);
};

/**
 * @memberof module:SharedToken.api
 * @memberof module:SharedToken
 * @namespace api
 * @description shared token api for backend interactions
 */
export default {
  createSharedToken,
  getAllSharedTokens,
  getSharedTokenById,
  updateSharedToken,
  deleteSharedToken,
  getEndpointObject,
};
