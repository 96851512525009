<template>
  <div class="modal fade" id="openPositionsModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header row mx-0">
          <div class="col-10">
            <h3 class="modal-title" v-if="jobRecord">Open Positions</h3>
          </div>
          <div class="col-2 mt-2">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="col-12">
            <h5>{{ jobRecord?.job_name }}</h5>
          </div>
        </div>
        <div class="modal-body ws-normal">
          <form class="content-form mb-2 px-0" id="openPositionsForm">
            <div class="content form_wrapper py-1">
              <div class="row">
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label class="required">Positions</label>
                    <input
                      type="number"
                      name="positions"
                      min="1"
                      v-validate="'required'"
                      class="form-control"
                      v-model="positionData.position_count"
                    />
                    <span
                      v-if="errors.first('positions')"
                      class="valid-feedback alert-danger"
                      :title="errors.first('positions')"
                      >{{ errors.first("positions") }}</span
                    >
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label class="required">On Date</label>
                    <div class="input-group mb-3">
                      <date-picker
                        id="onDate"
                        name="on_date"
                        v-model="positionData.on_date"
                        placeholder="Select Date"
                        :config="config"
                        :minDate="new Date()"
                        :class="{
                          'invalid-border': errors.first('on_date'),
                        }"
                        @dp-hide="validateOnDate"
                      >
                      </date-picker>
                      <div class="input-group-append">
                        <span
                          class="input-group-text"
                          id="basic-addon2"
                          @click="toggleDatePicker"
                        >
                          <i class="fa fa-calendar" aria-hidden="false"> </i>
                        </span>
                        <span
                          v-if="errors.first('on_date')"
                          class="valid-feedback alert-danger"
                          style="top: -14px; margin-right: 35px"
                          >{{ errors.first("on_date") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-5 px-0">
                  <button
                    :disabled="isSubmitted"
                    type="button"
                    class="btn btn-cp ml-2"
                    @click="addPositions"
                  >
                    Add Positions
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div>
            <ag-grid-vue
              class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
              style="height: 50vh"
              :gridOptions="gridOptions"
              :columnDefs="columnDefs"
              :rowData="positionRecords"
              :pagination="true"
              :paginationPageSize="5"
            >
            </ag-grid-vue>
          </div>
        </div>
        <delete-positions-button></delete-positions-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import DeletePositionsButton from "./delete-positions-button";
import datePicker from "vue-bootstrap-datetimepicker";

/**
 * @memberof module:ManageJob
 * @namespace components.open_positions_modal
 */
export default {
  /**
   * @memberof module:ManageJob.components.open_positions_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      gridOptions: null,
      columnDefs: null,
      rowData: null,
      positionData: {
        on_date: new Date(),
        position_count: 1,
        jobId: null,
      },
      isSubmitted: false,
      config: {
        format: "DD/MM/YYYY",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
      },
      datePickerFlag: false,
    };
  },
  components: {
    datePicker,
    "ag-grid-vue": AgGridVue,
    "delete-positions-button": DeletePositionsButton,
  },
  computed: {
    ...mapGetters({
      jobRecord: "$_manage_jobs/openPositionsModal",
      positionRecords: "$_manage_jobs/positionsWithJob",
    }),
  },
  methods: {
    createColumnDefs() {
      this.columnDefs = [
        {
          headerName: "On Date",
          valueGetter: function dateFormat(params) {
            let dt = new Date(params.data.on_date);
            return dt.toLocaleDateString().toString();
          },
          width: 150,
        },
        {
          headerName: "Positions",
          field: "position_count",
          width: 120,
          type: "rightAligned",
        },
        {
          headerName: "Shortlisted",
          field: "shortlisted_count",
          width: 120,
          type: "rightAligned",
        },
        {
          headerName: "Joined",
          field: "joined_count",
          width: 120,
          type: "rightAligned",
        },
        {
          headerName: "Status",
          valueGetter: function getStatus(params) {
            switch (params.data.status) {
              case "P":
                return "Pending";
              case "C":
                return "Closed";
              case "D":
                return "Deleted";
              default:
                return params.data.status;
            }
          },
          width: 150,
        },
        {
          headerName: "Actions",
          cellRenderer: "delete-positions-button",
          width: 120,
        },
      ];
    },
    addPositions() {
      if (this.positionData.position_count && this.positionData.on_date) {
        this.isSubmitted = true;
        this.$store.dispatch("$_manage_jobs/addPositions", this.positionData);
        this.positionData = this.resetData();
        let that = this;
        setTimeout(function () {
          that.isSubmitted = false;
        }, 5000);
      }
    },
    resetData() {
      return {
        on_date: new Date(),
        position_count: 1,
        jobId: this.jobRecord.id,
      };
    },
    toggleDatePicker() {
      $("#onDate").data("DateTimePicker").toggle();
    },
    validateOnDate() {
      if (!this.positionData.on_date) {
        this.$validator.errors.add({
          field: "on_date",
          msg: "Required",
        });
      } else {
        this.$validator.errors.remove("on_date");
      }
    },
  },
  beforeMount() {
    this.gridOptions = {
      flex: 1,
      paginationPageSizeSelector: false,
      enableCellTextSelection: true,
      suppressPropertyNamesCheck: true,
      context: {
        componentParent: this,
      },
    };
    this.createColumnDefs();
  },
  /**
   * @memberof module:ManageJob.components.open_positions_modal
   * @description lifecycle method - handle data when modal closed and about to open
   */
  mounted() {
    $("#openPositionsModal").on("show.bs.modal", () => {});
    $("#openPositionsModal").on("hidden.bs.modal", () => {
      this.positionData = this.resetData();
    });
  },
  watch: {
    /**
     * @memberof module:ManageJob.components.open_positions_modal
     * @param {Object} value new value
     * @description watch - handle Job Id
     */
    jobRecord(value) {
      if (value && value.id) {
        this.positionData.jobId = value.id;
        this.$store.dispatch("$_manage_jobs/fetchPositionsWithJob", value.id);
      }
    },
  },
  beforeDestroy() {
    $("#openPositionsModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.form_wrapper {
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 0px 20px;
  margin-top: 0px;
}
.form_wrapper .btn {
  height: 38px;
  margin-top: 32px;
}
@media screen and (max-width: 767px) {
  .form_wrapper .btn {
    padding: 8px 12px;
    margin-top: 5px;
    margin-right: 15px;
    float: right;
    margin-bottom: 10px;
  }
}
.form_wrapper input:focus {
  box-shadow: none;
  border-color: #8f1452;
}
.ag-grid-style {
  width: 100%;
  height: 86%;
}
</style>
