/**
 * @memberOf module:Home.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Job application submission status
 */
const formSubmitStatus = (state) => state.formSubmitStatus;

/**
 * @memberOf module:Home.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of current Job Application status
 */
const applicationStatus = (state) => state.applicationStatus;

/**
 * @memberOf module:Home.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Review categories and Reviews
 */
const reviewCategoriesWithReviews = (state) =>
  state.reviewCategoriesWithReviews;

/**
 * @memberOf module:Home.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Job categories and Jobs
 */
const categoriesWithJobs = (state) => state.categoriesWithJobs;

/**
 * @memberOf module:Home.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of active Job categories and Jobs
 */
const activeCategoriesWithJobs = (state) => state.activeCategoriesWithJobs;

/**
 * @memberOf module:Home.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Job by id
 */
const getJobById = (state) => state.jobData;

/**
 * @memberOf module:Home.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Job opening for fresher
 */
const jobOpeningFresher = (state) => state.fresherOpeningEnabled;

/**
 * @memberOf module:Home.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Job opening for professional
 */
const jobOpeningProfessional = (state) => state.professionalOpeningEnabled;
/**
 * @memberOf module:Home.store.getters
 * @param {Object} state holds store properties for current module
 * @description return Skill set availablr for job
 */
const jobOpeningSkills = (state) => state.skillSet;

/**
 * @memberOf module:Home
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  categoriesWithJobs,
  formSubmitStatus,
  applicationStatus,
  reviewCategoriesWithReviews,
  jobOpeningFresher,
  jobOpeningProfessional,
  jobOpeningSkills,
  activeCategoriesWithJobs,
  getJobById,
};
