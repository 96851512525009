import axios from "axios";
import VueCookie from "vue-cookie";

const instance = axios.create({ baseURL: process.env.VUE_APP_ROOT_API });

instance.interceptors.request.use((req) => {
  if (VueCookie.get("APPLICANT_ACCESS_TOKEN")) {
    req.headers["Authorization"] = `Bearer ${VueCookie.get(
      "APPLICANT_ACCESS_TOKEN"
    )}`;
  }
  return req;
});
export default instance;
