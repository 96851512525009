var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"addScore","tabindex":"-1","role":"dialog","aria-labelledby":"myModalLabel"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content m-t100"},[_c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"modal-title",attrs:{"id":"myModalLabel"}},[_vm._v("Round Result")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.resetValidations(_vm.$v)}}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('form',{staticClass:"content-form"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 control-label"},[_vm._v("Round Type")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.round_name),expression:"data.round_name"}],staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.data.round_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "round_name", $event.target.value)}}})])]),(_vm.isAptitude)?_c('div',[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 control-label"},[_vm._v("Score")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.score),expression:"data.score"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.data.score)},on:{"blur":function($event){return _vm.$v.data.score.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "score", $event.target.value)}}}),(!_vm.$v.data.score.required && _vm.$v.data.score.$error)?_c('p',{staticClass:"alert alert-danger"},[_vm._v(" This field is required ")]):_vm._e(),(
                    !_vm.$v.data.score.numeric &&
                    _vm.$v.data.score.$error &&
                    _vm.$v.data.score.required
                  )?_c('p',{staticClass:"alert alert-danger"},[_vm._v(" Only Digits ")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 control-label"},[_vm._v("Out Of")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.outOf),expression:"data.outOf"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.data.outOf)},on:{"blur":function($event){return _vm.$v.data.outOf.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.data, "outOf", $event.target.value)}}}),(!_vm.$v.data.outOf.required && _vm.$v.data.outOf.$error)?_c('p',{staticClass:"alert alert-danger"},[_vm._v(" This field is required ")]):_vm._e(),(
                    !_vm.$v.data.outOf.numeric &&
                    _vm.$v.data.outOf.$error &&
                    _vm.$v.data.outOf.required
                  )?_c('p',{staticClass:"alert alert-danger"},[_vm._v(" Only Digits ")]):_vm._e()])])]):(_vm.isReview)?_c('div'):_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 control-label"},[_vm._v("Grade")]),_c('div',{staticClass:"col-sm-9"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.grade),expression:"data.grade"}],staticClass:"form-control",on:{"blur":function($event){return _vm.$v.data.grade.$touch()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.data, "grade", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',[_vm._v("Excellent")]),_c('option',[_vm._v("Very Good")]),_c('option',[_vm._v("Good")]),_c('option',[_vm._v("Moderate")]),_c('option',[_vm._v("Lacking")]),_c('option',[_vm._v("Weak")])]),(!_vm.$v.data.grade.required && _vm.$v.data.grade.$error)?_c('p',{staticClass:"alert alert-danger"},[_vm._v(" This field is required ")]):_vm._e()])]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"col-sm-3 control-label"},[_vm._v("Comments")]),_c('div',{staticClass:"col-sm-9"},[_c('common-rich-text-box',{attrs:{"modalId":"add-score-modal-summernote"},on:{"onKeyUp":_vm.onKeyUp,"onBlur":_vm.onBlur}}),(_vm.isCommentLengthNotValid)?_c('p',{staticClass:"alert alert-danger"},[_vm._v(" Comments must not exceed 5000 characters ")]):_vm._e()],1)])])]),_c('div',{staticClass:"modal-footer text-center"},[_c('button',{staticClass:"btn btn-cp",attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.addScore}},[_vm._v(" Generate ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }