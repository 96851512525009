import api from "../_api";
import Routers from "../../../router/index.js";
import { addSnackbarMessage } from "../../Common/_plugin/error-response";

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} selectedApplicants
 * @description store selected applicants in store
 */
const storeSelectedApplicants = (context, selectedApplicants) => {
  context.commit("STORE_SELECTED_APPLICANTS", selectedApplicants);
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterOptions
 * @description get data of each application status and their count
 */
const fetchApplicationStatusCount = (context, filterOptions) => {
  api
    .fetchApplicationStatusCount(filterOptions)
    .then((response) => {
      const data = response.data;
      let sortedData = sortStatusCount(data);
      context.commit("FETCH_APPLICATION_STATUS_COUNT", sortedData);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberof module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterOptions
 * @description get data of all the applicant by filter option
 */
const fetchApplicantsData = (context, queryParams, filterOptions) => {
  api
    .fetchApplicantsData(queryParams, filterOptions)
    .then((response) => {
      context.commit("FETCH_FILTERED_APPLICANTS_DATA", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterOptions
 * @description get data of shortlisted applicants by status and other filters
 */
const fetchShortlistedApplicants = (context, filterOptions) => {
  if (!filterOptions) {
    filterOptions = {};
  }
  filterOptions.applicationStatus = "Shortlisted";
  api
    .fetchApplicantsDataByStatus(filterOptions)
    .then((response) => {
      context.commit("FETCH_SHORTLISTED_APPLICANTS_DATA", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterOptions
 * @description get data of joined applicants by status and other filters
 */
const fetchJoinedApplicants = (context, filterOptions) => {
  if (!filterOptions) {
    filterOptions = {};
  }
  filterOptions.applicationStatus = "Joined";
  api
    .fetchApplicantsDataByStatus(filterOptions)
    .then((response) => {
      context.commit("FETCH_JOINED_APPLICANTS_DATA", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterOptions
 * @description get data of each application status and their count
 */
const fetchApplicationPositionCount = (context, filterOptions) => {
  api
    .fetchApplicationPositionCount(filterOptions)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_APPLICATION_POSITION_COUNT", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterOptions
 * @description get data of each application employment status and their count
 */
const fetchApplicationEmploymentStatusCount = (context, filterOptions) => {
  api
    .fetchApplicationEmploymentStatusCount(filterOptions)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_APPLICATION_EMPLOYMENT_STATUS_COUNT", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {String} employmentStatus
 * @description get count of fresh applicants based on the employment status
 */
const fetchFreshApplicationCount = (context, employmentStatus) => {
  api
    .fetchFreshApplicationCount(employmentStatus)
    .then((response) => {
      if (employmentStatus === "G") {
        context.commit(
          "FETCH_FRESH_GRADUATE_APPLICATION_COUNT",
          response.data.count
        );
      } else {
        context.commit(
          "FETCH_FRESH_PROFESSIONAL_APPLICATION_COUNT",
          response.data.count
        );
      }
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(
        error,
        "Problem retrieving fresh applications data!",
        true
      );
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterData
 * @description get filterData and store it into local storage
 */
const storeFilterData = (context, filterData) => {
  localStorage.setItem("filterParameters", JSON.stringify(filterData));
  context.commit("STORE_FILTER_DATA", filterData);
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @description resets filterData and removes it from local storage
 */
const resetFilterData = (context) => {
  localStorage.removeItem("filterParameters");
  context.commit("RESET_FILTER_DATA");
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Add round and mail for multiple applicants
 */
const addRoundAndMail = (context, cData) => {
  let data = cData.data;
  api
    .addRound(data.roundData)
    .then((response) => {
      response.data.forEach((roundElement, index) => {
        roundElement.applicantIterationMaster = data.applicantData[index];
      });
      delete data.applicantData;
      const finalData = {
        data: response.data,
      };
      Routers.push({
        name: "mailbox",
        query: { key: "Schedule Round", redirect: cData.redirect },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setApplicantsData", finalData, {
          root: true,
        });
      }, 300);
      context.dispatch("fetchApplicationStatusCount");
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");
    })
    .catch(() => {
      context.commit("CONFIRM_ROUND_SCHEDULE_STATUS", "FAILURE");
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} roundData
 * @description Add round of multiple applicants
 */
const addRound = (context, roundData) => {
  api
    .addRound(roundData)
    .then(() => {
      context.dispatch("fetchApplicationStatusCount");
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");
      context.commit("CONFIRM_ROUND_SCHEDULE_STATUS", "SUCCESS");
    })
    .catch(() => {
      context.commit("CONFIRM_ROUND_SCHEDULE_STATUS", "FAILURE");
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} updatedRoundData
 * @description Pass round for multiple applicants
 */
const passLastRound = (context, updatedRoundData) => {
  api
    .passLastRound(updatedRoundData.dataToBeUpdated)
    .then(() => {
      changeStatusAndReturnCount(
        context,
        updatedRoundData.isApplicantView,
        updatedRoundData.campusId
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} updatedRoundData
 * @description Fail round for multiple applicants
 */
const failLastRound = (context, updatedRoundData) => {
  api
    .failLastRound(updatedRoundData.dataToBeUpdated)
    .then(() => {
      changeStatusAndReturnCount(
        context,
        updatedRoundData.isApplicantView,
        updatedRoundData.campusId
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} updatedRoundData
 * @description Revert round for mutiple applicants
 */
const revertLastRound = (context, updatedRoundData) => {
  api
    .revertLastRound(updatedRoundData.dataToBeUpdated)
    .then(() => {
      changeStatusAndReturnCount(
        context,
        updatedRoundData.isApplicantView,
        updatedRoundData.campusId
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} updatedRoundData
 * @description Cancel round for multiple applicants
 */
const cancelLastRound = (context, data) => {
  let updatedRoundData = data.data;
  api
    .cancelLastRound(updatedRoundData.dataToBeUpdated)
    .then(() => {
      updatedRoundData.dataToBeUpdated.forEach((element, index) => {
        element.applicantIterationMaster =
          updatedRoundData.selectedApplicants[index];
      });
      delete updatedRoundData.selectedApplicants;
      const finalData = {
        data: updatedRoundData.dataToBeUpdated,
      };
      Routers.push({
        name: "mailbox",
        query: { key: "Schedule Cancel", redirect: data.redirect },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setApplicantsData", finalData, {
          root: true,
        });
      }, 300);
      updatedRoundData.isApplicantView
        ? context.dispatch("fetchApplicationStatusCount")
        : context.dispatch("getRegisteredApplicantStatusCount", {
            campusId: updatedRoundData.campusId,
          });
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Status updated successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Shortlist multiple applicants and change main application status to Shortlisted
 */
const shortlistApplicants = (context, cData) => {
  let data = cData.data;
  api
    .shortlistApplicants(data.dataToBeUpdated)
    .then(() => {
      if (data.modalId) $(`#${data.modalId}`).modal("hide"); // pass modal id to close modal on success api response
      const finalData = {
        data: data.selectedApplicants,
      };
      Routers.push({
        name: "mailbox",
        query: {
          key: "Application Status",
          redirect: cData.redirect,
        },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setApplicantsData", finalData, {
          root: true,
        });
      }, 300);
      data.isApplicantView
        ? context.dispatch("fetchApplicationStatusCount")
        : context.dispatch("getRegisteredApplicantStatusCount", data.campusId); // if cond se handle krna he
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");

      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Status updated successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Differ multiple applicants and change main application status to Differed.
 */
const differApplicants = (context, cData) => {
  let data = cData.data;
  api
    .differApplicants(data.dataToBeUpdated)
    .then(() => {
      const finalData = {
        data: data.selectedApplicants,
      };
      Routers.push({
        name: "mailbox",
        query: {
          key: "Application Status",
          redirect: cData.redirect,
        },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setApplicantsData", finalData, {
          root: true,
        });
      }, 300);
      data.isApplicantView
        ? context.dispatch("fetchApplicationStatusCount")
        : context.dispatch("getRegisteredApplicantStatusCount", {
            campusId: data.campusId,
          });
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");

      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Status updated successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Move multiple applicants to Talent Pool and change main application status to Talent Pool.
 */
const moveApplicantsToTalentPool = (context, cData) => {
  let data = cData.data;
  api
    .moveApplicantsToTalentPool(data.dataToBeUpdated)
    .then(() => {
      const finalData = {
        data: data.selectedApplicants,
      };
      Routers.push({
        name: "mailbox",
        query: { key: "Application Status", redirect: cData.redirect },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setApplicantsData", finalData, {
          root: true,
        });
      }, 300);
      data.isApplicantView
        ? context.dispatch("fetchApplicationStatusCount")
        : context.dispatch("getRegisteredApplicantStatusCount", {
            campusId: data.campusId,
          });
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");

      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Status updated successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Move multiple applicants to Talent Pool and change main application status to Talent Pool.
 */
const moveCampusApplicantsToTalentPool = (context, cData) => {
  let data = cData.data;
  api
    .moveApplicantsToTalentPool(data.dataToBeUpdated)
    .then(() => {
      let dataToBePassed = {
        applicantData: data.selectedApplicants,
        toBeMailed: {
          coordinatorEmails: data.campusMails,
        },
        type: data.status,
      };
      Routers.push({
        name: "mailbox",
        query: { key: "Application Status", redirect: cData.redirect },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setCampusData", dataToBePassed, {
          root: true,
        });
      }, 300);
      context.dispatch("fetchApplicationStatusCount");
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");

      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Status updated successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} campusMailIDs
 * @description Fetch Co-ordinator emails for particular campus
 */
const fetchCampusEmails = (context, campusId) => {
  api
    .fetchCampusEmails(campusId)
    .then((response) => {
      context.commit("SET_CAMPUS_MAILS", response.data);
    })
    .catch(() => {});
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Add round and mail for multiple applicants belonging to campus
 */
const addCampusRoundAndMail = (context, cData) => {
  let data = cData.data;
  api
    .addRound(data.roundData)
    .then((response) => {
      data.roundData = response.data;
      Routers.push({
        name: "mailbox",
        query: { key: "Schedule Round", redirect: cData.redirect },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setCampusData", data, { root: true });
      }, 300);
      context.dispatch("fetchApplicationStatusCount");
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");
    })
    .catch(() => {
      context.commit("CONFIRM_ROUND_SCHEDULE_STATUS", "FAILURE");
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Cancel round for multiple applicants belonging to campus
 */
const cancelCampusLastRound = (context, cData) => {
  let data = cData.data;
  api.cancelLastRound(data.dataToBeUpdated).then(() => {
    let dataToBePassed = {
      applicantData: data.selectedApplicants,
      toBeMailed: {
        coordinatorEmails: data.campusMails,
      },
      type: data.status,
    };
    Routers.push({
      name: "mailbox",
      query: { key: "Schedule Cancel", redirect: cData.redirect },
    });
    setTimeout(() => {
      context.dispatch("$_mailbox/setCampusData", dataToBePassed, {
        root: true,
      });
    }, 300);
    context.dispatch("fetchApplicationStatusCount");
    context.dispatch("fetchApplicationPositionCount");
    context.dispatch("fetchApplicationEmploymentStatusCount");
  });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Shortlist multiple applicants and change their main application status to Shortlisted
 */
const shortlistCampusApplicants = (context, cData) => {
  let data = cData.data;
  api
    .shortlistApplicants(data.dataToBeUpdated)
    .then(() => {
      let dataToBePassed = {
        applicantData: data.selectedApplicants,
        toBeMailed: {
          coordinatorEmails: data.campusMails,
        },
        type: data.status,
      };
      Routers.push({
        name: "mailbox",
        query: {
          key: "Application Status",
          redirect: cData.redirect,
        },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setCampusData", dataToBePassed, {
          root: true,
        });
      }, 300);
      context.dispatch("fetchApplicationStatusCount");
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");

      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Status updated successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Differ multiple applicants and change their main application status to Differed
 */
const differCampusApplicants = (context, cData) => {
  let data = cData.data;
  api
    .differApplicants(data.dataToBeUpdated)
    .then(() => {
      let dataToBePassed = {
        applicantData: data.selectedApplicants,
        toBeMailed: {
          coordinatorEmails: data.campusMails,
        },
        type: data.status,
      };
      Routers.push({
        name: "mailbox",
        query: { key: "Application Status", redirect: cData.redirect },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setCampusData", dataToBePassed, {
          root: true,
        });
      }, 300);
      context.dispatch("fetchApplicationStatusCount");
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");

      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Applicant Differed Successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};
/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description join multiple applicants and change their main application status to joined
 */
const joinedCampusApplicants = (context, cData) => {
  let data = cData.data;
  api
    .joinedApplicants(data.dataToBeUpdated)
    .then(() => {
      let dataToBePassed = {
        applicantData: data.selectedApplicants,
        toBeMailed: {
          coordinatorEmails: data.campusMails,
        },
        type: data.status,
      };
      Routers.push({
        name: "mailbox",
        query: { key: "Application Status", redirect: cData.redirect },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setCampusData", dataToBePassed, {
          root: true,
        });
      }, 300);
      context.dispatch("fetchApplicationStatusCount");
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");

      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Status updated successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Differ multiple applicants and change their main application status to Differed
 */
const notAppearedRound = (context, cData) => {
  let data = cData.data;
  api
    .notAppearedApplicants(data.dataToBeUpdated)
    .then(() => {
      let dataToBePassed = {
        applicantData: data.selectedApplicants,
        toBeMailed: {
          coordinatorEmails: data.campusMails,
        },
        type: data.status,
      };
      Routers.push({
        name: "mailbox",
        query: { key: "Application Status", redirect: cData.redirect },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setCampusData", dataToBePassed, {
          root: true,
        });
      }, 300);
      context.dispatch("fetchApplicationStatusCount");
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");

      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Status updated successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description mail selected applicant about campaign
 */
const RunCampaign = (context, data) => {
  let mailData = {
    data: data.data,
  };
  Routers.push({
    name: "mailbox",
    query: { key: "RunCampaign", redirect: data.redirect },
  });
  setTimeout(() => {
    context.dispatch("$_mailbox/setApplicantsData", mailData, { root: true });
  }, 300);
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description get data of each registred application status and their count
 */
const getRegisteredApplicantStatusCount = (context, data) => {
  api
    .getRegisteredApplicantStatusCount(data)
    .then((response) => {
      const response_data = response.data;
      let sortedData = sortStatusCount(response_data);
      context.commit("GET_REG_APPLICANT_STATUS_COUNT", sortedData);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem getting registered applicant status count",
        true
      );
    });
};

//common function to avoid code duplication
//changes status return count on pass , fail or reverting last round.
const changeStatusAndReturnCount = (context, isApplicantView, campusId) => {
  context.commit("UPDATE_APPLICATION_STATUS");
  isApplicantView
    ? context.dispatch("fetchApplicationStatusCount")
    : context.dispatch("getRegisteredApplicantStatusCount", campusId); // if cond se handle krna he
  context.dispatch("fetchApplicationPositionCount");
  context.dispatch("fetchApplicationEmploymentStatusCount");

  context.dispatch(
    "openSnackbar",
    {
      type: "success",
      message: "Status updated successfully!",
    },
    { root: true }
  );
};

const sortStatusCount = (data) => {
  const applicationStatusList = [
    "Received",
    "Shortlisted",
    "Joined",
    "Differed",
    "Held",
    "Aptitude Scheduled",
    "Aptitude Passed",
    "Aptitude Cancelled",
    "Aptitude Failed",
    "Written Test Scheduled",
    "Written Test Passed",
    "Written Test Cancelled",
    "Written Test Failed",
    "Interview Scheduled",
    "Interview Passed",
    "Interview Cancelled",
    "Interview Failed",
  ];
  applicationStatusList.forEach((status) => {
    const result = data.find((obj) => obj.status_name === status);
    let tempStatus = {};
    if (result == null) {
      tempStatus.status_name = status;
      tempStatus.status_count = "0";
      data.push(tempStatus);
    }
  });
  data.sort((a, b) => {
    return parseInt(b.status_count) - parseInt(a.status_count);
  });
  return data;
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @description get count of open positions by job
 */
const fetchOpenJobPositions = (context) => {
  api
    .fetchOpenJobPositions()
    .then((response) => {
      context.commit("FETCH_OPEN_JOB_POSITIONS", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving open job positions!", true);
    });
};

const receiveUserMails = (context, mailId) => {
  api
    .receiveUserMails(mailId)
    .then((res) => {
      context.commit("FETCH_USER_MAILS", res.data.mails);
    })
    .catch((error) => {
      console.log(error);
    });
};

const fetchAttachment = (context, attachmentData) => {
  api
    .fetchAttachment(attachmentData.msgId, attachmentData.id)
    .then((res) => {
      context.commit("FETCH_ATTACHMENT", res.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

/**
 * @memberof module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Number} loggedIn userId
 * @description get the user specific notification
 */
const fetchUserNotifications = (context, userId) => {
  api
    .fetchUserNotifications(userId)
    .then((response) => {
      context.commit("FETCH_USER_NOTIFICATIONS", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving user notifications!", true);
    });
};

/**
 * @memberof module:ApplicantsData.store.actions
 * @param {Number} seenCommentId
 * @description set the the user notification to seen
 */
const setUserNotificationSeen = (context, seenCommentId) => {
  api
    .setUserNotificationSeen(seenCommentId)
    .then((response) => {
      context.commit("FETCH_USER_NOTIFICATIONS", response.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving open job positions!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @description Fetch pending round schedules of applicants from current date
 */
const fetchRoundSchedules = (context, filters) => {
  api
    .fetchRoundSchedules(filters)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_ROUND_SCHEDULES", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving Schedules !", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Array} data
 * @description Process multiple applicants and change their main application status to Shortlisted
 */
const processCampusApplicant = (context, cData) => {
  let data = cData.data;
  api
    .processApplicants(data.dataToBeUpdated)
    .then(() => {
      let dataToBePassed = {
        applicantData: data.selectedApplicants,
        toBeMailed: {
          coordinatorEmails: data.campusMails,
        },
        type: data.status,
      };
      Routers.push({
        name: "mailbox",
        query: {
          key: "Application Status",
          redirect: cData.redirect,
        },
      });
      setTimeout(() => {
        context.dispatch("$_mailbox/setCampusData", dataToBePassed, {
          root: true,
        });
      }, 300);
      context.dispatch("fetchApplicationStatusCount");
      context.dispatch("fetchApplicationPositionCount");
      context.dispatch("fetchApplicationEmploymentStatusCount");

      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Applicant Processed Successfully!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem changing status!", true);
    });
};

const fetchAllFollowUps = (context, queryParams) => {
  api
    .fetchAllFollowUps(queryParams)
    .then((response) => {
      context.commit("FETCH_FOLLOWUPS", response.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving FollowUps !", true);
    });
};

const fetchAllLinkRequests = (context) => {
  api
    .fetchAllLinkRequests()
    .then((res) => {
      context.commit("FETCH_LINK_REQUESTS", res.data);
    })
    .catch((err) => {
      addSnackbarMessage(err, "Problem retrieving Link Requests !", true);
    });
};

const updateLinkRequest = (context, entity) => {
  api
    .updateLinkRequests(entity)
    .then((res) => {
      context.commit("UPDATE_LINK_REQUESTS", res.data);
    })
    .catch((err) => {
      console.log(err);
      addSnackbarMessage(err, "Problem updating Link Requests !", true);
    });
};

const fetchApplicantMasterData = (context) => {
  api
    .fetchApplicantMasterData()
    .then((response) => {
      context.commit("FETCH_APPLICANT_MASTER_DATA", response.data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ApplicantsData
 * @namespace store.actions
 * @description actions for Applicants Data
 */
export default {
  storeSelectedApplicants,
  fetchApplicationStatusCount,
  fetchApplicationPositionCount,
  fetchApplicationEmploymentStatusCount,
  fetchFreshApplicationCount,
  fetchOpenJobPositions,
  fetchApplicantsData,
  fetchShortlistedApplicants,
  fetchJoinedApplicants,
  addRound,
  addRoundAndMail,
  passLastRound,
  failLastRound,
  revertLastRound,
  cancelLastRound,
  shortlistApplicants,
  storeFilterData,
  resetFilterData,
  differApplicants,
  moveApplicantsToTalentPool,
  moveCampusApplicantsToTalentPool,
  fetchCampusEmails,
  addCampusRoundAndMail,
  cancelCampusLastRound,
  shortlistCampusApplicants,
  differCampusApplicants,
  RunCampaign,
  getRegisteredApplicantStatusCount,
  receiveUserMails,
  fetchAttachment,
  fetchUserNotifications,
  setUserNotificationSeen,
  fetchRoundSchedules,
  processCampusApplicant,
  fetchAllFollowUps,
  notAppearedRound,
  joinedCampusApplicants,
  fetchAllLinkRequests,
  updateLinkRequest,
  fetchApplicantMasterData,
};
