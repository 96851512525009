<template>
  <base-modal
    id="viewTemplateModal"
    size="lg"
    modalBodyClass="p-2"
    :toggle="true"
  >
    <div style="height: 100%">
      <iframe
        title="static mail template"
        class="template-frame"
        :srcdoc="templateHtml"
        width="100%"
        @load="getHeight"
        ref="iframe"
      ></iframe>
    </div>
  </base-modal>
</template>

<script>
import baseModal from "@/modules/Common/_components/base-modal.vue";
export default {
  components: {
    baseModal,
  },
  props: {
    templateHtml: String,
  },
  data() {
    return {
      resizeObserver: null,
    };
  },
  methods: {
    getHeight() {
      this.$nextTick(() => {
        const iframe = this.$refs.iframe;
        if (iframe && iframe.contentWindow) {
          const doc = iframe.contentWindow.document;

          if (this.resizeObserver) {
            this.resizeObserver.disconnect();
          }

          let timeout;
          const resizeObserver = new ResizeObserver(() => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
              iframe.style.height = doc.body.scrollHeight + 4 + "px";
            }, 100);
          });
          resizeObserver.observe(doc.body);
          iframe.style.height = doc.body.scrollHeight + 4 + "px";
        }
      });
    },
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
};
</script>

<style scoped>
.template-frame {
  min-height: 150px;
  overflow: hidden;
  border: 1px solid #dee2e6;
  box-shadow: border-box;
  border-radius: 5px;
  width: 100%;
}
</style>
