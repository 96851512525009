import {
  addSnackbarMessage,
  addSuccessSnackbarMessage,
} from "../../Common/_plugin/error-response";

import api from "../_api";
import router from "../../../router";

/**
 * @memberOf module:SharedToken.store.actions
 * @param {Object} context store context
 * @description create shared token
 */
const createSharedToken = (context, data) => {
  api
    .createSharedToken(data)
    .then((response) => {
      context.commit("APPEND_SHARED_TOKEN", response.data);
      addSuccessSnackbarMessage("Shared token created successfully");
      router.push("/dashboard/shared-tokens");
    })
    .catch((error) => {
      console.log("createSharedToken error", error);
      addSnackbarMessage(
        error,
        error.response?.data.message || "Problem in creating shared token",
        true
      );
    });
};

/**
 * @memberOf module:SharedToken.store.actions
 * @param {Object} context store context
 * @description get all shared tokens
 */
const fetchAllSharedTokens = (context, query) => {
  api
    .getAllSharedTokens(query)
    .then((response) => {
      if (response.data) {
        context.commit("SET_ALL_SHARED_TOKENS", response.data);
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        error.response?.data.message || "Problem in fetching shared tokens !!",
        true
      );
    });
};

/**
 * @memberOf module:SharedToken.store.actions
 * @param {Object} context store context
 * @description get endpoint object
 */
const fetchEndpointObject = (context) => {
  api
    .getEndpointObject()
    .then((response) => {
      if (response.data) {
        context.commit("SET_ENDPOINT_OBJECT", response.data);
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("fetchEndpointObject error", error);
      addSnackbarMessage(
        error,
        error.response?.data.message ||
          "Problem in fetching endpoint object !!",
        true
      );
    });
};

/**
 * @memberOf module:SharedToken.store.actions
 * @param {Object} context store context
 * @description update shared token
 */
const updateSharedToken = (context, data) => {
  const { isActive } = data;
  api
    .updateSharedToken(data)
    .then((response) => {
      if (response.data) {
        context.commit("UPDATE_SHARED_TOKEN", response.data);
        addSuccessSnackbarMessage("Shared token updated successfully");
        if (isActive == undefined) {
          router.push("/dashboard/shared-tokens");
        }
      } else {
        addSnackbarMessage(response.data, response.data.message, true);
      }
    })
    .catch((error) => {
      console.log("updateSharedToken error", error);
      addSnackbarMessage(
        error,
        error.response?.data.message || "Problem in updating shared token",
        true
      );
    });
};

const deleteSharedToken = (context, id) => {
  api
    .deleteSharedToken(id)
    .then((res) => {
      context.commit("DELETE_SHARED_TOKEN", id);
      addSuccessSnackbarMessage("Shared token deleted successfully");
      context.dispatch("fetchAllSharedTokens");
      return res;
    })
    .catch((err) => {
      addSnackbarMessage(
        err,
        err.response?.data.message || "Problem in deleting shared token",
        true
      );
    });
};

/**
 * @memberOf module:SharedToken
 * @namespace store.actions
 * @description actions for SharedToken module
 */
export default {
  createSharedToken,
  fetchAllSharedTokens,
  updateSharedToken,
  deleteSharedToken,
  fetchEndpointObject,
};
