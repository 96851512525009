<template>
  <div class="my-2">
    <div class="well section-head w-auto">
      <div class="row mx-0">
        <div class="col-5 px-0">
          <h4>
            <strong>Emails</strong>
          </h4>
        </div>
        <div class="col-7 px-0">
          <button
            class="btn btn-cp float-right"
            role="button"
            @click="navigateToMailbox"
            v-if="hasAccess({ permissions: [PERMISSIONS.MAIL.CREATE] })"
          >
            <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
            <strong>New Email</strong>
          </button>
        </div>
      </div>
    </div>
    <div class="p-0 mx-3" id="comments">
      <div v-if="mails.length > 0" class="well container-email mt-2">
        <div
          class="accordion"
          :class="[mail.type === 'received' ? 'received-mail' : 'sent-mail']"
          role="tablist"
          v-for="(mail, index) in mails"
          :key="index"
        >
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <div
                block
                v-b-toggle="'accordion-' + index"
                :class="[
                  mail.type === 'received' ? 'received-title' : 'sent-title',
                ]"
              >
                <div class="row p-1">
                  <div class="col-8" v-if="!isUnread(mail.labels)">
                    <i
                      class="fa fa-paperclip"
                      v-if="mail.attachments.length > 0"
                    ></i>
                    &nbsp; {{ mail.subject }}
                  </div>
                  <div class="col-8" v-if="isUnread(mail.labels)">
                    <strong
                      ><i
                        class="fa fa-paperclip"
                        v-if="mail.attachments.length > 0"
                      ></i>
                      &nbsp; {{ mail.subject }}</strong
                    >
                  </div>
                  <div class="col-4 text-right text-secondary">
                    {{ formatDate(mail.date) }}
                  </div>
                </div>
              </div>
            </b-card-header>
            <b-collapse
              :id="'accordion-' + index"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <div>
                    <p v-if="mail.to" class="mb-2">
                      To : &nbsp;&nbsp;&nbsp;&nbsp;{{ mail.to }}
                    </p>
                    <p v-if="mail.from" class="mb-2">
                      From : &nbsp;&nbsp;&nbsp;&nbsp;{{ mail.from }}
                    </p>
                    <p v-if="mail.cc" class="mb-2">
                      Cc : &nbsp;&nbsp;&nbsp;&nbsp;{{ mail.cc }}
                    </p>
                    <p v-if="mail.bcc" class="mb-2">
                      Bcc : &nbsp;&nbsp;&nbsp;&nbsp;{{ mail.bcc }}
                    </p>
                  </div>
                  <mail-component :mailData="mail.body"></mail-component>
                  <div class="mt-4" v-if="mail.attachments.length > 0">
                    <div
                      class="mt-1"
                      v-for="attachment in mail.attachments"
                      :key="attachment.attachmentId"
                    >
                      <i class="fa fa-paperclip"></i> &nbsp;
                      <span
                        class="attachment"
                        @click="
                          getAttachment(
                            mail.messageId,
                            attachment.id,
                            attachment.mimeType
                          )
                        "
                        >{{ attachment.fileName }}</span
                      >
                    </div>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";
import MailComponent from "./mail-component.vue";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.round_info
 * @description Round info component
 */
export default {
  data() {
    return {
      mails: [],
      attachmentData: null,
      mimeType: "",
      attachmentId: "",
    };
  },
  components: {
    MailComponent,
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    ...mapGetters({
      applicantInfoData: "$_applicant_profile/fetchApplicantData",
      userMails: "$_applicants_data/fetchUserMailData",
      attachment: "$_applicants_data/fetchMailAttachment",
    }),
  },
  watch: {
    applicantInfoData(value) {
      this.getMails(value);
    },
    userMails(value) {
      if (value.error) {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "danger",
            message: "Refresh Google token!",
          },
          {
            root: true,
          }
        );
        this.mails = [];
      } else {
        this.mails = [...value.receivedMails, ...value.sentMails];
        this.mails.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
      }
    },
    attachment(value) {
      this.attachmentData = value;
      this.openAttachment();
    },
  },
  methods: {
    getMails(value) {
      this.$store.dispatch("$_applicants_data/receiveUserMails", value.email);
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    formatDate(date) {
      return moment(date).format("llll");
    },

    formatMailBody(msg) {
      if (msg.includes("wrote:")) {
        const subStr = msg.substring(0, msg.indexOf("wrote"));
        const msgStr = subStr.substring(0, subStr.indexOf("On"));
        return msgStr.trim();
      }
      return msg;
    },

    isUnread(labels) {
      return labels.includes("UNREAD");
    },

    navigateToMailbox() {
      const finalData = {
        data: [{ ...this.applicantInfoData }],
      };
      setTimeout(() => {
        this.$store.dispatch("$_mailbox/setApplicantsData", finalData, {
          root: true,
        });
      }, 300);
      this.$router.push({
        name: "mailbox",
        query: { key: "MailTo", redirect: this.$route.fullPath },
      });
    },

    getAttachment(msgId, id, mimeType) {
      if (this.attachmentId === id) {
        this.openAttachment();
      } else {
        this.attachmentId = id;
        this.mimeType = mimeType;
        this.$store.dispatch("$_applicants_data/fetchAttachment", {
          msgId: msgId,
          id: id,
        });
      }
    },

    openAttachment() {
      const data = this.decode(this.attachmentData);
      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: this.mimeType + ";base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },

    decode(input) {
      input = input.replace(/-/g, "+").replace(/_/g, "/");
      let pad = input.length % 4;
      if (pad) {
        if (pad === 1) {
          throw new Error(
            "InvalidLengthError: Input base64url string is the wrong length to determine padding"
          );
        }
        input += new Array(5 - pad).join("=");
      }

      return input;
    },
  },
};
</script>

<style scoped>
h4 {
  padding-top: 5px;
}

.container-email {
  padding: 10px;
  margin-bottom: 15px;
  display: table;
}

.received-title {
  background: rgba(108, 117, 125, 0.1);
  border-color: rgba(108, 117, 125, 0.1);
  color: black;
}

.sent-title {
  background: rgba(0, 123, 255, 0.3);
  border-color: rgba(0, 123, 255, 0.3);
  color: black;
}

.sent-mail {
  width: 100%;
  float: left;
}

.received-mail {
  width: 90%;
  float: right;
}

.msg {
  word-break: break-all;
  white-space: pre-wrap;
}

.attachment {
  cursor: pointer;
}

.attachment:hover {
  color: blue;
  text-decoration: underline;
}

i {
  font-size: 18px;
}

@media (max-width: 576px) {
  .well {
    border-radius: 0px;
  }
}
</style>
