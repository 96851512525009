<template>
  <div class="row">
    <div :id="containerId" :class="containerClasses">
      <div class="form-group" :class="inputClasses">
        <label
          v-if="label"
          class="control-label"
          :class="{ required: required }"
          :for="id"
          >{{ label }} (Max {{ maxFiles }} file/s allowed)</label
        >
        <div
          class="file-upload-box"
          :class="{
            invalid: errors.first(id),
          }"
        >
          <template v-if="selectedFiles && selectedFiles.length > 0">
            <span
              style="width: 100%"
              v-for="(selectedFile, index) in selectedFiles"
              :key="index"
            >
              <div class="file-tag">
                {{ selectedFile.name }}
                <span class="close-btn" @click="removeFile(index)">
                  <em class="fa fa-close"></em
                ></span>
              </div>
            </span>
          </template>
          <span
            class="upload fa fa-upload"
            style="
              width: 100%;
              text-align: center;
              padding-top: 10px;
              cursor: pointer;
            "
            v-if="!selectedFiles || selectedFiles.length === 0"
            @click="openFileInput"
          >
            <span style="margin: 0px 8px">
              Choose file/s or Drag and Drop
            </span>
          </span>
          <input
            ref="fileInputSingle"
            :id="id"
            type="file"
            :name="id"
            v-validate="
              `${required ? 'required|' : ''}extationValidation:${
                this.fileExtensions
              }|size:${this.fileSize.size}|`
            "
            @change="fileSelected"
            v-if="maxFiles <= 1"
            data-vv-as="file"
          />
          <input
            ref="fileInputMultiple"
            :id="id"
            type="file"
            :name="id"
            v-validate="
              `${required ? 'required|' : ''}extationValidation:${
                this.fileExtensions
              }|size:${this.fileSize.size}|`
            "
            @change="filesSelected"
            multiple
            v-if="maxFiles > 1"
          />
        </div>
        <span
          v-if="errors.first(id)"
          class="valid-feedback alert-danger"
          style="top: 20px"
          >{{ errors.first(id) }}</span
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 100%;
}
.file-upload-box {
  min-height: 5vh;
  padding: 25px 10px 10px 10px;
  border: 2px dashed #b1b1b1;
  z-index: 99;
}
.close-btn {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
}
.file-tag {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  background-color: #6c757d;
  margin-right: 5px;
}
</style>
<script>
import { Validator } from "vee-validate";
import { isextantionValid } from "../_plugin/validation";

Validator.extend("extationValidation", isextantionValid);

/**
 * @memberof module:Common
 * @namespace file_upload_input
 */
export default {
  props: {
    inputId: [],
    containerId: [],

    id: [String],
    name: [String],
    required: {
      type: Boolean,
      default: false,
    },
    containerClasses: [String],
    inputClasses: [String],
    maxFiles: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      required: false,
    },
    fileExtensions: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    fileSize: {
      type: Object,
      required: true,
      validator: function (value) {
        const keys = Object.keys(value);

        if (
          keys.findIndex((el) => el === "size") !== -1 &&
          keys.findIndex((el) => el === "unit") !== -1
        ) {
          if (
            typeof value.size === "number" &&
            typeof value.unit === "string"
          ) {
            if (value.unit.toUpperCase() === "MB") {
              value.size = value.size * 1024;
            }
            return true;
          } else {
            console.error(
              "typeof size and unit should be number and string respectively."
            );
          }
        } else {
          console.error("Object should contain size and unit as keys");
        }
        return false;
      },
      default: function () {
        return {
          size: 5000,
          unit: "KB",
        };
      },
    },
  },

  /**
   * @memberof module:Common.file_upload_input
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      selectedFiles: null,
    };
  },
  methods: {
    openFileInput() {
      if (!this.selectedFiles || this.selectedFiles.length === 0) {
        if (this.maxFiles === 1) {
          this.$refs.fileInputSingle.click();
        } else {
          this.$refs.fileInputMultiple.click();
        }
      }
    },
    /**
     * @memberof module:Common.file_upload_input
     * @param {Object} $event input change event
     * @description method - checks for file validity, its required validation and emits relevant events
     */
    fileSelected($event) {
      let files = $event.target.files;
      if (files === undefined || files[0] === undefined) {
        return;
      }
      let selectedFiles = [];
      $.each(files, function (idx, elm) {
        selectedFiles[idx] = elm;
      });
      this.selectedFiles = selectedFiles;
      this.$emit("fileSelected", files[0]);
    },
    /**
     * @memberof module:Common.file_upload_input
     * @param {Object} $event input change event
     * @description method - checks for file validity, its required validation and emits relevant events
     */
    filesSelected($event) {
      let files = $event.target.files;

      if (files === undefined) {
        return;
      }
      let selectedFiles = [];
      $.each(files, function (idx, elm) {
        selectedFiles[idx] = elm;
      });
      this.selectedFiles = selectedFiles;
      if (this.selectedFiles.length > this.maxFiles) {
        this.selectedFiles.splice(this.maxFiles);
      }
      this.$emit("fileSelected", this.selectedFiles);
    },
    /**
     * @memberof module:Common.file_upload_input
     * @param {Object} $event input change event
     * @description method - checks for file validity, its required validation and emits relevant events
     */
    removeFile(index) {
      if (index > -1) {
        this.selectedFiles.splice(index, 1);
      }
      if (this.selectedFiles.length === 0) {
        this.selectedFiles = null;
        if (this.$refs.fileInputSingle) this.$refs.fileInputSingle.value = null;
      }
      this.$emit("fileSelected", this.selectedFiles);
    },
  },
  inject: ["$validator"],
};
</script>
