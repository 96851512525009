import AddToken from "./_components/add-token.vue";
import PERMISSIONS from "../Common/permissions";
import _sharedToken from "./_components/shared-tokens.vue";
import _index from "./index.vue";

export default {
  path: "shared-tokens",
  component: _index,

  children: [
    {
      path: "",
      component: _sharedToken,
      name: "manageSharedTokens",
      meta: {
        title: "Shared Tokens",
        credRequired: true,
        user: "employee",
        permissions: [PERMISSIONS["SHARED-TOKEN"].MENU],
      },
    },
    {
      path: "create-token",
      component: AddToken,
      name: "AddToken",
      meta: {
        title: "Create Token",
        credRequired: true,
        user: "employee",
        permissions: [PERMISSIONS["SHARED-TOKEN"].CREATE],
      },
    },
    {
      path: "edit-token/:tokenId",
      component: AddToken,
      name: "EditToken",
      meta: {
        title: "Edit Token",
        credRequired: true,
        user: "employee",
        permissions: [PERMISSIONS["SHARED-TOKEN"].UPDATE],
      },
      props: true,
    },
  ],
};
