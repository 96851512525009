import { addSnackbarMessage } from "../../../Common/_plugin/error-response";
import api from "../../_api";
const fetchCategoriesWithJobs = (context) => {
  api
    .fetchCategoriesWithJobs()
    .then((response) => {
      const data = response.data.categories;

      data.forEach((category) => {
        category.jobs = [];
      });

      response.data.jobs.forEach((job) => {
        const categoryIndex = data.findIndex(
          (category) => category.id === job.categoryId
        );
        data[categoryIndex].jobs.push(job);
      });

      context.commit("fetchCategoriesWithJobs", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const getReportByJobs = (context, data) => {
  api
    .getReportByJobs(data)
    .then((response) => {
      const data = response.data;
      context.commit("getReportByJobs", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const getDetailedReport = (context, data) => {
  api
    .getDetailedReport(data)
    .then((response) => {
      const data = response.data;
      context.commit("getDetailedReport", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};
const getRoundData = (context, data) => {
  api
    .getRoundStats(data)
    .then((response) => {
      const data = response.data;
      context.commit("GET_ROUND_STATS", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const clearStoreData = (context) => {
  context.commit("CLEAR_STORE_DATA");
};

export default {
  getRoundData,
  fetchCategoriesWithJobs,
  getReportByJobs,
  getDetailedReport,
  clearStoreData,
};
