<template>
  <div :class="[uiStyle === 'ROW' ? 'row' : '', containerClass]">
    <label
      v-if="label"
      :class="{ 'col-sm-3': uiStyle === 'ROW' }"
      class="control-label"
      >{{ label }}
      <span v-if="required" class="text-danger">*</span>
    </label>
    <div :class="{ 'col-sm-9': uiStyle === 'ROW' }">
      <input
        @input="updateValue"
        :value="value"
        class="form-control custom-input"
        v-on="listeners"
        v-bind="$attrs"
      />
      <slot name="error"></slot>
    </div>
  </div>
</template>

<script>
import { formFieldMixin } from "../_mixins/form-field-mixin";

export default {
  props: ["required", "uiStyle", "containerClass"],
  mixins: [formFieldMixin],
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
      };
    },
  },
};
</script>

<style scoped>
.custom-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #dadce0;
  box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 0px;
  line-height: 24px;
  padding: 6px 8px;
}
</style>
