<template>
  <div>
    <button
      class="btn btn-cp"
      style="width: 100%; font-size: 11px; padding: 0px; margin: 0px"
      @click="fillViewMailModalData(params.data)"
      v-if="params.data != undefined"
    >
      &nbsp;&nbsp;View
    </button>
  </div>
</template>

<script>
/**
 * @memberOf module:ApplicantsData
 * @namespace components.resolve_duplicate_link
 * @description Resolve Duplicate component
 */
export default {
  data() {
    return {};
  },
  methods: {
    /**
     * @memberOf module:ApplicantsData.components.resolve_duplicate_link
     * @description Resolve duplicates when matched for applicants
     */
    // resolveDuplicate(id) {
    //   router.push({ name: "resolveduplicate", params: { id: id } });
    // }

    fillViewMailModalData(data) {
      this.params.context.componentParent.setViewMailModalData(data);
    },
  },
};
</script>
