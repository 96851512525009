<template>
  <div
    class="modal fade file-view"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="pdfModalLabel"
  >
    <div class="modal-dialog modal-fullscreen" role="document">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center"
        >
          <h4 class="modal-title" id="pdfModalLabel">{{ title }}</h4>
          <div
            class="button-container"
            style="display: flex; align-items: center"
          >
            <button
              type="button"
              class="close"
              style="display: flex; align-items: center"
              @click="openResume"
              data-dismiss="modal"
              aria-label="Close"
              :disabled="pdfLoadError"
            >
              <i class="fa fa-external-link cst-size" aria-hidden="true"></i>
            </button>
            <button
              type="button"
              class="close"
              style="display: flex; align-items: center"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body w-100 h-100">
          <iframe
            v-if="!error"
            title=""
            class="iframe w-100 h-100"
            :src="iframeSrc"
          ></iframe>
          <h4 v-if="error" class="text-danger text-center">{{ error }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PdfModal",

  props: {
    modalId: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
      default: "View",
    },
    axios: {
      type: Function,
      required: true,
    },
  },
  data: () => {
    return {
      error: null,
      iframeSrc: null,
      pdfLoadError: false,
    };
  },

  created() {
    this.loadContent();
  },

  methods: {
    openResume() {
      window.open(this.url, "_blank");
    },
    async loadContent() {
      try {
        const response = await this.axios.get(this.url, {
          responseType: "blob",
        });

        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);

        this.iframeSrc = url;
        this.error = null; // Clear any previous errors
      } catch (error) {
        this.pdfLoadError = true;
        console.error("Error loading PDF:", error);
        this.error = "Failed to load PDF";
      }
    },
  },

  beforeDestroy() {
    if (this.iframeSrc) {
      URL.revokeObjectURL(this.iframeSrc);
    }
    if (this.modalId) {
      $(`#${this.modalId}`).modal("hide");
      $(".modal-backdrop").remove();
      $("body").removeClass("modal-open");
    }
  },
};
</script>

<style>
.file-view .modal-content {
  height: 100vh;
}
.file-view .modal-fullscreen {
  width: 100vw;
  max-width: none;
  margin: 0;
}
.file-view .modal-header {
  padding: 9px;
}
.file-view .cst-size {
  font-size: 14px;
  margin-top: 4px;
}
</style>
