import formHome from "./index";
import FormManager from "./_components/form-manager.vue";
import PERMISSIONS from "../Common/permissions";

export default {
  path: "form-templates",
  component: formHome,
  children: [
    {
      path: "",
      name: "manageForms",
      component: FormManager,
      meta: {
        title: "Form Manager",
        user: "employee",
        permissions: [PERMISSIONS.FORM.MENU],
        checkAll: true,
      },
    },
    {
      path: "preview/:master(\\d+)/:version(\\d+)?",
      name: "preview-MV",
      component: () => import("./_components/form-preview.vue"),
      meta: {
        title: "Form Preview",
        user: "employee",
        permissions: [PERMISSIONS.FORM.MENU],
      },
    },
    {
      path: "preview/:slug",
      name: "preview-S",
      component: () => import("./_components/form-preview.vue"),
      meta: {
        title: "Form Preview",
        user: "employee",
        permissions: [PERMISSIONS.FORM.MENU],
      },
    },
    {
      path: "editor/:master(\\d+)/:version(\\d+)?",
      name: "editor-MV",
      component: () => import("./_components/form-editor.vue"),
      meta: {
        title: "Form Editor",
        user: "employee",
        permissions: [PERMISSIONS.FORM.UPDATE],
      },
    },
    {
      path: "editor/:slug",
      name: "editor-S",
      component: () => import("./_components/form-editor.vue"),
      meta: {
        title: "Form Editor",
        user: "employee",
        permissions: [PERMISSIONS.FORM.UPDATE],
      },
    },
  ],
};
