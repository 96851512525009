<template>
  <div class="modal fade" id="addReviewModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Add Review</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="resetValidations($v)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ws-normal">
          <form class="content-form">
            <div class="form-group">
              <label class="control-label">Category</label>
              <div v-if="reviewCategoryData">
                <input
                  type="text"
                  class="form-control"
                  :value="reviewCategoryData.name"
                  disabled
                />
              </div>
            </div>

            <div class="form-group position-relative">
              <label class="control-label"
                >Review Name <span class="text-danger"> *</span></label
              >
              <input
                type="text"
                name="reviewName"
                class="form-control"
                v-validate="'required|regex:^[^-\s][a-zA-Z ]*$'"
                data-vv-as="Review Name"
                data-vv-validate-on="blur|input"
                v-model="data.review_name"
                maxlength="30"
                :class="{ invalid: errors.first('reviewName') }"
              />
              <span
                v-if="errors.first('reviewName')"
                class="valid-feedback alert-danger"
                >{{ errors.first("reviewName") }}</span
              >
            </div>

            <div class="form-group position-relative">
              <label class="control-label"
                >Review Description <span class="text-danger"> *</span></label
              >
              <common-rich-text-box
                modalId="add-review-summernote"
                :width="'100%'"
                @onKeyUp="onKeyUp"
                @onBlur="onBlur"
                @onFocus="onFocus"
              />
              <span
                v-if="isReviewDescTouched && !isReviewDescValid"
                class="valid-feedback alert-danger"
                >Review Description is Required!</span
              >
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="$v.data.$invalid || !isReviewDescValid"
            type="button"
            class="btn btn-cp"
            @click="
              addReview();
              resetValidations($v);
            "
            data-dismiss="modal"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { required } from "vuelidate/lib/validators";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";

export default {
  components: {
    CommonRichTextBox,
  },
  data() {
    return {
      data: {
        review_name: "",
        review_description: "",
        reviewCategoryId: null,
        status: "active",
      },
      doRender: true,
      isReviewDescTouched: false,
    };
  },

  mounted() {
    $("#addReviewModal").on("show.bs.modal", () => {
      this.doRender = true;
    });
    $("#addReviewModal").on("hidden.bs.modal", () => {
      this.data = this.resetData();
      this.doRender = false;
    });
  },

  validations() {
    const data = {
      review_name: {
        required,
        reviewNameValidator: this.reviewNameValidator,
      },
      review_description: {
        required,
      },
    };
    return {
      data,
    };
  },

  computed: {
    ...mapGetters({
      reviewCategoryData:
        "$_manage_reviews/reviewCategoryDataForAddReviewModal",
    }),

    isReviewDescValid() {
      return this.data.review_description !== "";
    },
  },

  watch: {
    reviewCategoryData(value) {
      this.data.reviewCategoryId = value.id;
    },
  },

  methods: {
    /**
     * @memberof module:ManageReview.components.add_review_modal
     * @description method - initialize summernote
     */
    onKeyUp(value) {
      this.data.review_description = value;
    },
    onBlur(value) {
      this.data.review_description = value;
      this.isReviewDescTouched = true;
    },
    onFocus() {
      setTimeout(() => {
        $(document.body).addClass("modal-open");
      }, 500);
    },

    resetValidations(v) {
      v.$reset();
      this.isReviewDescTouched = false;
      $("#add-review-summernote").summernote("code", "");
      this.data = this.resetData();
    },

    resetData() {
      return {
        review_name: "",
        review_description: "",
        reviewCategoryId: -1,
        status: "active",
      };
    },

    addReview() {
      this.$store.dispatch("$_manage_reviews/addReview", this.data);
      this.data = this.resetData();
      $("#addReviewModal").modal("hide");
      $(".modal-backdrop").hide();
    },

    reviewNameValidator(value) {
      return /^[^-\s][a-zA-Z ]*$/.test(value);
    },
  },
  beforeDestroy() {
    $("#addReviewModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.ws-normal {
  white-space: normal;
}
</style>
