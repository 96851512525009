<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h4 class="modal-title" id="myModalLabel">Confirmation</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="resetValidations($v)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group">
              <div v-if="actionType === 'SHORTLIST'">
                <div class="row">
                  <div class="col-12 mb-2 pr-2">
                    <label class="control-label" htmlFor="designation"
                      >Designation
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      id="designation"
                      type="text"
                      name="designation"
                      placeholder="Enter Designation"
                      v-validate="'required|alpha_spaces|min:2|max:100'"
                      :class="{ invalid: errors.first('designation') }"
                      class="form-control"
                      v-model="offerData.designation_offered"
                    />
                    <span
                      v-if="errors.first('designation')"
                      class="valid-feedback alert-danger"
                      >{{ errors.first("designation") }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-2 pr-2">
                    <label class="control-label" htmlFor="package"
                      >Package
                      <span class="text-danger">*</span>
                    </label>
                    <div class="input-group">
                      <input
                        id="package"
                        type="text"
                        name="package"
                        placeholder="e.g. 5.00 (In Lacs)"
                        v-validate="'required|decimal:2'"
                        :class="{ invalid: errors.first('package') }"
                        class="form-control"
                        v-model="offerData.package_offered"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text"> LPA </span>
                      </div>
                    </div>
                    <span
                      v-if="errors.first('package')"
                      class="valid-feedback alert-danger"
                      >{{ errors.first("package") }}
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mb-2 pr-2">
                    <label class="control-label" htmlFor="expectedJoining"
                      >Expected Joining
                      <span class="text-danger">*</span>
                    </label>
                    <div class="input-group mb-3">
                      <date-picker
                        id="expectedJoining"
                        name="expectedJoining"
                        v-model="offerData.expected_joining"
                        placeholder="Select Date"
                        :config="config"
                        :minDate="new Date()"
                        :class="{
                          'invalid-border': errors.first('expectedJoining'),
                        }"
                        @dp-hide="validateExpectedJoining"
                      >
                      </date-picker>
                      <div class="input-group-append">
                        <span
                          class="input-group-text"
                          id="basic-addon2"
                          @click="toggleDatePicker"
                        >
                          <i class="fa fa-calendar" aria-hidden="false"> </i>
                        </span>
                        <span
                          v-if="errors.first('expected_joining')"
                          class="valid-feedback alert-danger"
                          style="top: -14px; margin-right: 35px"
                          >{{ errors.first("expected_joining") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mb-2 pr-2">
                  <label class="control-label" htmlFor="comments"
                    >Comments
                    <span class="text-danger">*</span>
                  </label>
                  <common-rich-text-box
                    :width="'100%'"
                    modalId="update-application-status-summernote"
                    @onKeyUp="onKeyUp"
                    @onBlur="onBlur"
                  />
                  <p
                    class="alert alert-danger"
                    v-if="isCommentsTouched && !isCommentsValid"
                  >
                    Comments are required
                  </p>
                  <p class="alert alert-danger" v-if="!isCommentLengthValid">
                    Comments must not exceed 5000 characters
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3">
                  <p>
                    {{ actionMessage }}
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-cp ml-2" @click="handleSubmit">
            Confirm and Mail
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
import datePicker from "vue-bootstrap-datetimepicker";

export default {
  components: {
    CommonRichTextBox,
    datePicker,
  },
  props: {
    actionType: {
      type: String,
    },
    modalId: {
      type: String,
      required: true,
    },
    selectedApplicants: {
      type: Array,
    },
    jobProfiles: {
      type: Array,
    },
  },
  data() {
    return {
      isCommentsTouched: false,
      offerData: {
        designation_offered: "",
        comments: "",
        package_offered: "",
        expected_joining: "",
      },
      config: {
        format: "DD/MM/YYYY",
        minDate: new Date(),
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
        // icons: datePickerIcons,
      },
      datePickerFlag: false,
    };
  },
  methods: {
    handleSubmit() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          let dataToBeUpdated = [];
          this.selectedApplicants.forEach((element) => {
            dataToBeUpdated.push({
              id: element.id,
              application_status: "Shortlisted",
              expected_joining: this.offerData.expected_joining,
              package_offered: this.offerData.package_offered,
              designation_offered: this.offerData.designation_offered,
              application_comments: this.offerData.comments,
            });
          });
          this.$store.dispatch("$_applicants_data/shortlistApplicants", {
            data: {
              dataToBeUpdated: dataToBeUpdated,
              selectedApplicants: this.selectedApplicants,
              isApplicantView: false,
              campusId: this.selectedApplicants[0].campusRecruitmentId,
              modalId: this.modalId,
            },
            redirect: this.$route.fullPath,
          });
        }
      });
    },
    /**
     * @memberof module:ApplicanProfile.compoenents.update_application_status_modal
     * @description Notify parent with data when action confirmed
     */
    notifyParent() {
      this.$emit("onConfirmUpdateApplicationStatus", this.offerData);
    },
    /**
     * @memberof
     * @param {Object} v validation object
     * @description method - reset data validation
     */
    resetValidations(v) {
      v.$reset();
      this.isCommentsTouched = false;
    },
    /**
     * @memberof module:ApplicanProfile.compoenents.update_application_status_modal
     * @description set values of comments
     */
    onKeyUp(value) {
      this.offerData.comments = value;
    },
    /**
     * @memberof module:ApplicanProfile.compoenents.update_application_status_modal
     * @description set values of comments
     */
    onBlur(value) {
      this.offerData.comments = value;
      this.isCommentsTouched = true;
    },
    toggleDatePicker() {
      $("#expectedJoining").data("DateTimePicker").toggle();
    },
    validateExpectedJoining() {
      if (!this.offerData.expected_joining) {
        this.$validator.errors.add({
          field: "expected_joining",
          msg: "Required",
        });
      } else {
        this.$validator.errors.remove("expected_joining");
      }
    },
  },
  computed: {
    /**
     * @memberof module:ApplicanProfile.compoenents.update_application_status_modal
     * @description Set action confirmation message
     */
    actionMessage() {
      let message;
      switch (this.actionType) {
        case "PROCESS":
          message = "Do you want to confirm process the applicant?";
          break;
        case "SHORTLIST":
          message = "Do you want to confirm shortlist the applicants?";
          break;
        case "DIFFER":
          message = "Do you want to confirm differ the applicant?";
          break;
        case "HOLD":
          message = "Do you want to confirm to put on hold the applicant?";
          break;
        case "NOT APPEARED":
          message = "Do you want to confirm that applicant is not appeared?";
          break;
        case "TALENT POOL":
          message = "Do you want to confirm to move applicant to talent pool?";
          break;
        case "JOINED":
          message = "Do you want to confirm that applicant is joined?";
          break;
        default:
          break;
      }
      return message;
    },
    /**
     *
     * @returns {Boolean} validated result
     * @description computed - checks whether comments are valid or not
     */
    isCommentsValid() {
      return (
        this.offerData.comments !== "<p><br></p>" &&
        this.offerData.comments !== ""
      );
    },
    /**
     * @memberof module:ApplicanProfile.compoenents.update_application_status_modal
     * @description checks length for the review
     */
    isCommentLengthValid() {
      return this.offerData.comments
        ? this.offerData.comments.length <= 5000
        : true;
    },
  },
  validations() {
    const offerData = {
      jobId: {
        required,
      },
      comments: {
        required,
      },
      package_offered: {
        required,
      },
      expected_joining: {
        required,
      },
    };

    return {
      offerData,
    };
  },
};
</script>

<style lang="css" scoped>
.control-label {
  display: inline-flex;
}
</style>
