/**
 * @memberof module:MailBox.store.getters
 * @param {Object} state holds store properties for current module
 * @returns {Array} stored templates
 * @description return stored templates
 */
const getTemplates = (state) => state.storeTemplates;

/**
 * @memberof module:MailBox.store.getters
 * @param {Object} state holds store properties for current module
 * @returns {Number} ID of template
 * @description return data required for deletion of template
 */
const deleteTemplateModal = (state) => state.templateDeleteData;

/**
 * @memberof module:MailBox.store.getters
 * @param {Object} state holds store properties for current module
 * @returns {Object} applicants data passed from other components
 * @description return applicants data passed from other components
 */
const getApplicantsData = (state) => state.applicantsData;

/**
 * @memberof module:MailBox.store.getters
 * @param {Object} state holds store properties for current module
 * @returns {Object} campus data passed from other components
 * @description return campus data passed from other components
 */
const getCampusData = (state) => state.campusData;

/**
 * @memberof module:MailBox.store.getters
 * @param {Object} state holds store properties for current module
 * @returns {String} status of mail sending
 * @description return status of the mail that has been sent
 */
const getMailStatus = (state) => state.mailStatus;

/**
 * @memberof module:MailBox.store.getters
 * @param {Object} state holds store properties for current module
 * @returns {Array} IDs of available mail bodies in compose area
 * @description return IDs of mail bodies available in compose area
 */
const getAvailableMailBodies = (state) => state.availableMailBodies;

/**
 * @memberof module:MailBox.store.getters
 * @param {Object} state holds store properties for current module
 * @returns {Boolean} Returns success or failure for mail data update
 * @description returns mail data update success
 */
const getMailUpdateStatus = (state) => state.mailUpdateSuccessStatus;

/**
 * @memberof module:MailBox
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  getTemplates,
  deleteTemplateModal,
  getApplicantsData,
  getCampusData,
  getMailStatus,
  getAvailableMailBodies,
  getMailUpdateStatus,
};
