<template>
  <div class="content container-fluid page-bg nopadding">
    <div class="forgotbox">
      <div
        class="p-2"
        style="display: flex; flex-direction: column; min-height: 100%"
      >
        <div>
          <h3 class="header pt-1 mb-0">Reset Applicant Password</h3>
          <div class="logo-div">
            <img
              class="logoSize"
              src="../../../assets/images/newLogo.png"
              alt="Argusoft Logo"
            />
          </div>
          <div v-if="resetError">
            <p class="text-danger form-control" style="text-align: center">
              {{ resetError.message }}
            </p>
          </div>

          <div v-else-if="!resetDone">
            <form @submit.prevent="submit">
              <div class="form_page_inner">
                <div class="col-12 mb-2">
                  <label class="control-label required">Password </label>
                  <input
                    :type="passwordFieldType"
                    name="password"
                    placeholder="Enter Password"
                    v-validate="'required|min:8|max:20|customPassword'"
                    class="form-control"
                    v-model="data.password"
                    ref="password"
                  />
                  <button
                    type="button"
                    class="eye-button"
                    @click="togglePasswordVisibility"
                  >
                    <i
                      :class="
                        passwordFieldType === 'password'
                          ? 'fa fa-eye'
                          : 'fa fa-eye-slash'
                      "
                    ></i>
                  </button>

                  <span
                    v-if="errors.first('password')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("password") }}</span
                  >
                </div>
                <div class="col-12 mb-2">
                  <label class="control-label required"
                    >Confirm Password
                  </label>
                  <input
                    :type="confirmPassType"
                    name="confirmPassword"
                    placeholder="Enter Confirm Password"
                    v-validate="
                      'required|min:8|max:20|customPassword|confirmed:password'
                    "
                    v-model="data.confirmPassword"
                    data-vv-as="Confirm Password"
                    class="form-control"
                  />
                  <button
                    type="button"
                    class="eye-button"
                    @click="toggleConfirmPasswordVisibility"
                  >
                    <i
                      :class="
                        confirmPassType === 'password'
                          ? 'fa fa-eye'
                          : 'fa fa-eye-slash'
                      "
                    ></i>
                  </button>
                  <span
                    v-if="errors.first('confirmPassword')"
                    class="valid-feedback alert-danger"
                  >
                    {{ errors.first("confirmPassword") }}
                  </span>
                </div>
              </div>

              <div class="text-center">
                <button
                  type="submit"
                  class="btn btn-cp ml-2 my-2 ml-0 rounded-pill"
                  style="width: 150px"
                  :disabled="!this.isDisabled"
                >
                  Reset
                </button>
              </div>
            </form>
          </div>

          <div v-else class="col-12 mb-2">
            <p class="text-success form-control" style="text-align: center">
              Password Reset Successful
            </p>
          </div>

          <div class="col-12 text-left mb-2">
            <router-link to="/login?as=applicant">Go to Login</router-link>
          </div>
        </div>
        <div class="font-weight-bold mx-3">
          <h3 class="p-0 my-2"></h3>
          <router-link :to="'/'">
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            <span style="padding: 4px">Homepage</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../_api/index";
export default {
  mounted() {
    const token = this.$route.query.token;
    this.data.token = token;
  },
  data() {
    return {
      passwordVisible: false,
      confirmPasswordVisible: false,
      data: {
        password: "",
        confirmPassword: "",
        token: "",
      },
      resetDone: false,
      resetError: null,
    };
  },
  computed: {
    passwordFieldType() {
      return this.passwordVisible ? "text" : "password";
    },
    confirmPassType() {
      return this.confirmPasswordVisible ? "text" : "password";
    },
    isDisabled() {
      return (
        this.data.password &&
        this.data.confirmPassword &&
        this.$validator.errors.items.length === 0
      );
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    },
    async submit() {
      this.resetError = null;
      this.$validator.validateAll().then(() => {
        if (this.$validator.errors.items.length == 0) {
          this.$store
            .dispatch("$_applicant_master/applicantForgotPassword", this.data)
            .then((response) => {
              if (response?.status == 200) {
                this.resetDone = true;
              } else {
                this.resetError =
                  response?.message || "An error occurred try with a new link!";
              }
            });
        } else {
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Please enter valid values!",
          });
        }
      });
    },
    passwordValidator(value) {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~\-\\|`]).{8,}$/.test(
        value
      );
    },
  },
  created() {
    this.$validator.extend("customPassword", {
      getMessage: () =>
        `It requires an uppercase, lowercase, number and special character.`,
      validate: (value) => this.passwordValidator(value),
    });
  },
  beforeMount() {
    this.resetError = null;
    api
      .checkExpiry(this.$route.query.token)
      .then((data) => {
        this.res = data.data;
        this.passwordExist = data.data.passwordExist;
      })
      .catch((err) => {
        this.resetError = err.response.data;
      });
  },
};
</script>

<style scoped>
.custom-btn {
  padding: 10px 50px 10px 50px;
  border-radius: 25px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.header {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
}

.logo-div {
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-around;
}

.forgotbox {
  margin: auto;
  max-width: 420px;
  width: 100%;
  height: fit-content;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 2px 8px #5b5558;
  border-radius: 25px;
}
.forgotbox h5 {
  letter-spacing: 0.5px;
  display: block;
  text-align: center;
  border-radius: 30px;
  padding: 8px 20px;
  border: 1px solid #8f1452;
}

.forgotbox h5 img {
  width: 30px;
  height: 30px;
}

.forgotbox h5 a {
  padding-left: 20px;
}

.page-bg {
  /* height: 100vh; */
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .forgotbox {
    width: 90%;
    height: fit-content;
  }
}

h3 {
  text-align: center;
  border-bottom: 1px solid #efe6e6;
  padding-bottom: 15px;
}

.content {
  margin: auto !important;
}
::v-deep #maincontent {
  margin-top: 0px !important;
}
.eye-button {
  position: absolute;
  right: 20px;
  top: 76%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  z-index: 9999;
}

.eye-button i {
  font-size: 1.2rem;
  color: #6c757d;
}

.eye-button:hover i {
  color: #495057;
}

.eye-button:focus {
  outline: none;
}
</style>
