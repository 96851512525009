import axios from "axios";

/**
 * @memberOf module:ManageStaticTemplate.api
 * @returns {Promise}
 */
const fetchStaticTemplates = () => {
  return axios.get("/html-templates");
};

const fetchStaticTemplateHtml = (queryParams) => {
  return axios.get("/html-templates/by/path", {
    params: queryParams,
  });
};

export default {
  fetchStaticTemplates,
  fetchStaticTemplateHtml,
};
