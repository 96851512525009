import campusHome from "./index";
import _driveForm from "./_components/campus-recruitments.vue";
import _viewEntity from "./_components/view-entity.vue";
import _entityDetails from "./_components/entity-details.vue";
import registeredApplicantlist from "./_components/registered_applicant_list.vue";
import _addEditEntity from "./_components/add-edit-entity.vue";
// import _editEntity from "./_components/add-edit-entity.vue";
import CampusSchedule from "./_components/campus-schedule.vue";
import ScheduleEventSlot from "./_components/schedule-event-slot.vue";
import _moodle from "./_components/moodle.vue";
import PERMISSIONS from "../Common/permissions";

export default {
  path: "campus-drive",
  component: campusHome,

  children: [
    {
      path: "moodles",
      component: _moodle,
      name: "moodle",
      meta: {
        title: "Moodle",
        user: "employee",
        permissions: [PERMISSIONS["CAMPUS-DRIVE"]["MOODLE"].MENU],
      },
    },
    {
      path: "",
      component: _driveForm,
      name: "drive",
      meta: {
        title: "Campus Recruitment",
        user: "employee",
        permissions: [PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT.MENU],
      },
    },
    {
      path: "entities/add",
      name: "addEntityForm",
      component: _addEditEntity,
      props: (route) => ({
        currentMode: route.params.currentMode || "addEntity",
      }),
      meta: {
        title: "Add Entity",
        user: "employee",
        permissions: [PERMISSIONS["CAMPUS-DRIVE"].ENTITY.CREATE],
      },
    },
    {
      path: "entities/:entityId/update",
      name: "editEntityForm",
      component: _addEditEntity,
      props: (route) => ({
        currentMode: route.params.currentMode || "editEntity",
      }),
      meta: {
        title: "Edit Entity",
        user: "employee",
        permissions: [PERMISSIONS["CAMPUS-DRIVE"].ENTITY.UPDATE],
      },
    },
    {
      path: "entities",
      name: "viewEntity",
      component: _viewEntity,
      meta: {
        title: "Entity List",
        user: "employee",
        permissions: [PERMISSIONS["CAMPUS-DRIVE"].ENTITY.MENU],
        checkAll: true,
      },
    },
    {
      path: "recruitments/:recruitmentId",
      component: registeredApplicantlist,
      name: "registeredApplicantList",
      meta: {
        title: "Registered Applicant List",
        user: "employee",
        permissions: [PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT.MENU],
      },
    },

    {
      path: "schedules",
      component: CampusSchedule,
      name: "RecruitmentCampusSchedule",
      meta: {
        title: "Campus Schedule",
        user: "employee",
        permissions: [PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.MENU],
      },
    },
    {
      path: "schedules/:eventId",
      component: ScheduleEventSlot,
      name: "scheduleEventSlot",
      meta: {
        title: "Schedule event slot",
        user: "employee",
        permissions: [PERMISSIONS["CAMPUS-DRIVE"].SCHEDULE.MENU],
      },
    },
    {
      path: "entities/:entityId/view",
      name: "viewEntityDetails",
      component: _entityDetails,
      meta: {
        title: "View Entity",
        user: "employee",
        permissions: [PERMISSIONS["CAMPUS-DRIVE"].ENTITY.MENU],
        checkAll: true,
      },
    },
  ],
};
