const SET_SYSTEM_CONFIGS = (state, data) => {
  state.systemConfigs = data;
};

const SET_PUBLIC_SYSTEM_CONFIGS_OBJECT = (state, data) => {
  state.publicSystemConfigsObject = data;
};

const SET_SYSTEM_CONFIGS_OBJECT = (state, data) => {
  state.systemConfigsObject = data;
};
const JOB_OPENING_FRESHER = (state, data) => {
  state.fresherOpeningEnabled = data ? data.value[0] == "true" : false;
};
const JOB_OPENING_PROFESSIONAL = (state, data) => {
  state.professionalOpeningEnabled = data ? data.value[0] == "true" : false;
};
const JOB_OPENING_SKILLS = (state, data) => {
  if (data) {
    state.skillSet = data.value;
  }
};
export default {
  SET_SYSTEM_CONFIGS,
  SET_SYSTEM_CONFIGS_OBJECT,
  SET_PUBLIC_SYSTEM_CONFIGS_OBJECT,
  JOB_OPENING_FRESHER,
  JOB_OPENING_PROFESSIONAL,
  JOB_OPENING_SKILLS,
};
