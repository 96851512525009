<template>
  <div class="content">
    <common-header :data="['home', 'jobOpenings']" type="applicant" />
    <div class="container-fluid">
      <div v-if="applicant.employment_status === 'Fresher'">
        <div class="job_card_wrapper">
          <div class="job_card">
            <div class="job_card_header">
              <img alt="Job" src="../../../assets/images/job_icon.jpg" />
              <h2>Fresher's Form</h2>
            </div>

            <p class="text-justify">
              We are seeking passionate and driven freshers who are eager to
              learn, grow, and contribute to our team. While specific technical
              skills are a plus, we value qualities such as curiosity,
              adaptability, and a positive attitude.
            </p>

            <div class="job_card_footer">
              <strong v-if="canApplyJobs['fresher']">Already Applied !</strong>
              <router-link
                v-else
                :to="{
                  name: 'jobApplicationForm',
                  query: { isFresher: 'true' },
                }"
                tag="a"
                >Apply Now
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="job_card_wrapper" v-if="isDisplayDataAvailable">
          <div class="job_card" v-for="job in sortedData" :key="job.id">
            <div class="job_card_header">
              <img alt="Job" src="../../../assets/images/job_icon.jpg" />
              <h2>{{ job.job_name }}</h2>
            </div>
            <p style="height: 70px; white-space: break-spaces">
              <span>Required Skills </span> {{ job.required_skills.join(", ") }}
            </p>
            <p>
              <span>Experience Required</span> {{ job.required_experience_min }}
              {{
                job.required_experience_max
                  | expFormat(job.required_experience_min)
              }}
              years
            </p>
            <div class="job_card_footer">
              <div>
                <strong v-if="canApplyJobs[job.id]">Already Applied !</strong>
              </div>
              <router-link
                :to="{
                  name: 'applicantJobProfile',
                  params: { jobId: job.slug },
                }"
                tag="a"
                >Specification
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i
              ></router-link>
            </div>
          </div>
        </div>
        <div v-else>
          <h4 class="center-container">There are no Current Openings.</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

/**
 * @memberof module:ApplicantMaster
 * @namespace components.job_openings
 */
export default {
  /**
   * @memberof module:ApplicantMaster.components.job_openings
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */

  data() {
    return {
      isDisplayDataAvailable: true,
      isJobOpeningsAvailable: false,
      displayData: [],
      categorisedDisplayData: null,
      searchByJobTitle: "",
      searchBySkill: "",
      searchBySkillArray: "",
      searchByExperience: "",
      selectedCategory: 0,
      jobIds: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setCurrentOpenings();
  },
  computed: {
    ...mapGetters({
      currentOpenings: "$_home/activeCategoriesWithJobs",
      canApplyJobs: "$_applicant_master/getCanApplyJobs",
      applicant: "$_applicant_master/getLoggedInApplicant",
    }),
    sortedData: function () {
      function compare(a, b) {
        if (a.job_name < b.job_name) return -1;
        if (a.job_name > b.job_name) return 1;
        return 0;
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.displayData?.sort(compare);
    },
  },
  watch: {
    /**
     * @memberof module:Home.components.job_openings
     * @param {Object} value new value
     * @description watch - changes values of Current Opening when data is udpated from server
     */
    currentOpenings() {
      this.setCurrentOpenings();
    },

    /**
     * @memberof module:Home.components.job_openings
     * @param {Object} value new value
     * @description watch - changes values of flag when data is updating
     */
    displayData(value) {
      this.isDisplayDataAvailable = value?.length !== 0;
    },
  },
  filters: {
    /**
     * @memberof module:Home.components.job_opening_element
     * @description filter - handles to change format of experience
     */
    expFormat(value, arg1) {
      if (!value) {
        return "+";
      }

      if (arg1 == value) {
        return "";
      }

      return "to " + value.toString();
    },
  },
  methods: {
    /**
     * @memberof module:Home.components.job_openings
     * @param {Object} jobData Job Detail object
     * @description method - handles to open modal of job description
     */
    openJobDescriptionModal(jobData) {
      let htmlContent = "";
      if (jobData.job_posting_image !== null) {
        htmlContent +=
          "<img src='data:image;base64," +
          jobData.job_posting_image +
          "' style='display: block;margin-left: auto;margin-right: auto;width: 50%;'>" +
          jobData.job_description;
      } else {
        htmlContent = jobData.job_description;
      }
      this.openDialog(
        "jobDescModal",
        "Job description of : " + jobData.job_name,
        htmlContent,
        false
      );
    },

    setCurrentOpenings() {
      this.isJobOpeningsAvailable = this.currentOpenings?.jobs?.length !== 0;
      this.currentOpenings.jobs = this.currentOpenings?.jobs?.filter(
        (data) => data.status !== "inactive"
      );
      this.currentOpenings.categories =
        this.currentOpenings?.categories?.filter(
          (data) => data.status !== "inactive"
        );
      this.displayData = this.currentOpenings?.jobs;
    },
  },
};
</script>

<style scoped>
.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh; /* Adjust if needed to fit your layout */
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #62555b;
}

/*job_card-css*/
.job_card_wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.job_card {
  width: 350px;
  min-height: 380px;
  position: relative;
  margin: 10px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 0px 20px;
  font-size: 15px;
  line-height: 22px;
}
.job_card .job_card_header {
  margin: 30px 0px;
  text-align: center;
}
.job_card .job_card_header h2 {
  font-size: 22px;
  font-weight: 700;
  margin: 15px 0px;
  color: #2e3941;
  line-height: 24px;
}
.job_card span {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  color: #75828a;
  margin-bottom: 5px;
  font-size: 14px;
}
.job_card .job_card_footer {
  border-top: 1px solid #cccccc;
  padding-top: 15px;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 15px;
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
}
.job_card .job_card_footer a {
  color: #903564;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.job_card .job_card_footer a i {
  padding-left: 5px;
}
.job_card .job_card_footer a:hover {
  color: #903564;
}

/*job_card-css*/
/*Job_page_2-css*/
.job_card_lg {
  background: #fff;
  position: relative;
  min-height: 600px;
  padding: 20px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 991px) {
  .job_card_lg {
    margin: 15px 0px;
  }
}
.job_card_lg h2 {
  font-size: 32px;
  margin: 15px 0px;
  font-weight: 700;
  position: relative;
}
@media screen and (max-width: 991px) {
  .job_card_lg h2 {
    font-size: 24px;
  }
}
.job_card_lg h2:after {
  content: "";
  width: 90px;
  height: 5px;
  position: absolute;
  bottom: -8px;
  left: 0px;
  background: #8f1452;
}
.job_card_lg p {
  font-size: 15px;
  margin-top: 25px;
}
.job_card_lg .job_card_lg_footer {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.job_card_lg .job_card_lg_footer a {
  color: #903564;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.job_card_lg .job_card_lg_footer a i {
  padding-left: 5px;
}
.job_card_lg .job_card_lg_footer a:hover {
  color: #903564;
}

.benefits_content {
  margin-top: 40px;
  text-align: center;
}
.benefits_content h3 {
  font-size: 22px;
  font-weight: 700;
  margin: 12px 0px;
}
.benefits_content p {
  font-size: 16px;
  padding: 0px 40px;
}

.video_play {
  padding: 20px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
}
.video_play video {
  width: 100%;
}

.job_top_spacer_100 {
  margin-top: 100px;
}
@media screen and (max-width: 991px) {
  .job_top_spacer_100 {
    margin-top: 60px;
  }
}

.argonaut h1 {
  font-size: 80px;
  font-weight: 100;
  text-transform: uppercase;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 991px) {
  .argonaut h1 {
    font-size: 60px;
    line-height: 60px;
  }
}
.argonaut .argonaut_img_box {
  width: 260px;
  margin-top: 2px;
  margin-right: 2px;
  height: 260px;
  overflow: hidden;
}
.argonaut .argonaut_img_box img {
  height: 100%;
  object-fit: contain;
}
.argonaut p {
  font-size: 18px;
  margin-top: 40px;
  color: #62555b;
}
@media screen and (max-width: 991px) {
  .argonaut .large_logo {
    width: 270px;
  }
}

h1 {
  font-size: 48px;
  color: #2e3941;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  h1 {
    font-size: 32px;
  }
}

.text-size-22 {
  font-size: 22px;
}
@media screen and (max-width: 991px) {
  .text-size-22 {
    font-size: 18px;
  }
}
</style>
