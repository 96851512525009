<template>
  <div class="content">
    <common-header :data="['home', 'applications', 'tracker']" />
    <div class="container-fluid">
      <div class="row m-0">
        <div class="col-md-5 col-xs-6 pr-md-3 border-md-right px-0">
          <position-tracker></position-tracker>
        </div>
        <div class="col-md-7 col-xs-6 px-0 pl-md-3">
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase"
            content-class="mt-3"
            justified
          >
            <b-tab active lazy>
              <template #title>Shortlisted</template>
              <shortlisted-tracker></shortlisted-tracker>
            </b-tab>
            <b-tab lazy>
              <template #title>Joined</template>
              <joined-tracker></joined-tracker>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PositionTracker from "./position-tracker.vue";
import ShortlistedTracker from "./shortlisted-tracker.vue";
import JoinedTracker from "./joined-tracker.vue";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

export default {
  components: {
    PositionTracker,
    ShortlistedTracker,
    JoinedTracker,
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
  },
};
</script>
<style scoped>
.ag-grid-style {
  width: 100%;
  height: 86%;
}
</style>
