<template>
  <div class="panel panel-default">
    <div
      class="panel-heading to-heading"
      title="Click here to toggle Mail-To list"
      data-toggle="collapse"
      :href="'#' + id"
      aria-expanded="true"
    >
      <h4 class="panel-title">To {{ heading }} <span class="caret"></span></h4>
    </div>
    <div :id="id" class="panel-collapse collapse in show">
      <div class="panel-body">
        <ul class="nav nav-pills">
          <template v-if="!showMore">
            <li
              class="mail-to-pills theme-bg-clr"
              v-for="(mailTo, index) in mailToList.slice(0, 3)"
              :key="index"
            >
              {{ mailTo }}
            </li>
          </template>
          <template v-if="showMore">
            <li
              class="mail-to-pills theme-bg-clr"
              v-for="(mailTo, index) in mailToList"
              :key="index"
            >
              {{ mailTo }}
            </li>
          </template>
          <li
            class="show-more-or-less-pill"
            v-if="mailToList.length > 3 && !showMore"
            @click="showMore = true"
          >
            {{ mailToList.length - 3 }} more
          </li>
          <li
            class="show-more-or-less-pill"
            v-if="mailToList.length > 3 && showMore"
            @click="showMore = false"
          >
            show less
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberof module:MailBox
 * @namespace components.mail_to_list
 * @description Component that holds list of mail recipients
 */
export default {
  /**
   * @memberof module:MailBox.components.mail_to_list
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      showMore: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    mailToList: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style scoped>
.mail-to-pills {
  color: #fff;
  border-radius: 4px;
  position: relative;
  display: block;
  padding: 2px 4px;
  margin: 2px;
}

.show-more-or-less-pill {
  color: rgb(0, 0, 0);
  background-color: #ece9e9;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #832e5b;
  border-radius: 4px;
  position: relative;
  display: block;
  cursor: pointer;
  padding: 1px 4px;
  margin: 2px;
}

.to-heading {
  color: #333;
}

.to-heading:hover {
  color: black;
  text-decoration: underline !important;
  cursor: pointer;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
}

.panel-default {
  border-color: #ddd;
}

.panel {
  padding: 0px 10px 10px 0px;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-body {
  padding-top: 10px;
}
</style>
