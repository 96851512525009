<template>
  <div class="main-app">
    <recruitment-header-section></recruitment-header-section>
    <recruitment-sidebar-vue></recruitment-sidebar-vue>
    <div id="maincontent">
      <router-view></router-view>
    </div>
    <footer-section-vue></footer-section-vue>
  </div>
</template>

<script>
import RecruitmentHeaderSection from "../modules/Recruiter/_components/recruitment-header-section.vue";
import footerSectionVue from "../modules/Common/_components/footer-section.vue";
import recruitmentSidebarVue from "../modules/Recruiter/_components/recruitment-sidebar.vue";

/**
 * @module campusRecruitment
 */
export default {
  /**
   * @memberof module:Recruitment
   * @namespace components
   * @description components of Recruiter module
   */
  components: {
    footerSectionVue,
    recruitmentSidebarVue,
    RecruitmentHeaderSection,
  },
};
</script>

<style scoped>
#maincontent-recruitment {
  transition: all 0.4s ease 0s;
  white-space: nowrap;
  padding-top: 90px;
  min-height: 100vh;
  position: relative;
}

#maincontent {
  transition: all 0.4s ease 0s;
  white-space: nowrap;
  flex-grow: 1;
}
</style>
