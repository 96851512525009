<template>
  <div id="container" class="index-style">
    <router-view></router-view>
  </div>
</template>

<script>
import store from "./_store";

/**
 * @module ManageJob
 * @description manage job module
 */
export default {
  /**
   * @memberof module:ManageJob
   * @namespace components
   * @description components of manage job module
   */
  created() {
    const STORE_KEY = "$_audit_keys";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>
