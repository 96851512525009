<template>
  <div
    class="modal fade"
    id="filter-data-modal-rec"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Filter Data</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <label class="control-label m-r15" htmlFor="appliedOn"
                >Applied On</label
              >
              <div class="col-lg-12 col-md-12 col-sm-12 nopadding text-wrap">
                <label for="all" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="all"
                    value=""
                    v-model="applicationDate.filterType"
                  />
                  All
                </label>
                <label for="lastWeek" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="lastWeek"
                    value="Last One Week"
                    v-model="applicationDate.filterType"
                  />
                  Last One Week
                </label>
                <label for="lastMonth" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="lastMonth"
                    value="Last One Month"
                    v-model="applicationDate.filterType"
                  />
                  Last One Month
                </label>
                <label for="lastThreeMonth" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="lastThreeMonth"
                    value="Last Three Months"
                    v-model="applicationDate.filterType"
                  />
                  Last Three Months
                </label>
                <label for="lastSixMonths" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="lastSixMonths"
                    value="Last Six Months"
                    v-model="applicationDate.filterType"
                  />
                  Last Six Months
                </label>
                <label for="lastYear" class="radio-inline m-r15">
                  <input
                    type="radio"
                    id="lastYear"
                    value="Last One Year"
                    v-model="applicationDate.filterType"
                  />
                  Last One Year
                </label>
                <label for="custom" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="Custom"
                    value="Custom"
                    v-model="applicationDate.filterType"
                  />
                  Custom
                </label>
              </div>
            </div>
            <div v-if="applicationDate.filterType === 'Custom'">
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="control-label" htmlFor="fromDate"
                  >From Date</label
                >
                <date-picker
                  v-model="applicationDate.fromDate"
                  id="fromDate"
                  :config="config"
                  @dp-hide="dateRequired('fromDate')"
                ></date-picker>
                <p v-if="fromDateFlag" class="alert alert-danger">
                  This field is required
                </p>
              </div>
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="control-label" htmlFor="toDate">To Date</label>
                <date-picker
                  v-model="applicationDate.toDate"
                  id="toDate"
                  :config="config"
                ></date-picker>
              </div>
              <p v-if="!isDateValid" class="alert alert-danger">
                From date should not be greater than To date
              </p>
            </div>
            <div>
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="control-label m-r15" htmlFor="duplicates"
                  >Duplicates</label
                >
                <div class="col-lg-12 col-md-12 col-sm-12 nopadding text-wrap">
                  <label class="radio-inline m-r10">
                    <input
                      type="radio"
                      value=""
                      v-model="moreFilters.duplicateType"
                    />
                    All
                  </label>
                  <label class="radio-inline m-r10">
                    <input
                      type="radio"
                      value="resolved"
                      v-model="moreFilters.duplicateType"
                    />
                    Resolved
                  </label>
                  <label class="radio-inline m-r10">
                    <input
                      type="radio"
                      value="duplicate"
                      v-model="moreFilters.duplicateType"
                    />
                    Duplicates
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="disableButton"
            type="button"
            class="btn btn-cp ml-2"
            @click="onApplyFilter"
            data-dismiss="modal"
          >
            Apply
          </button>
          <button
            type="button"
            class="btn btn-cp ml-2"
            data-dismiss="modal"
            @click="onResetFilter"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import { required } from "vuelidate/lib/validators";

/**
 * @memberOf module:CampusRecruitment
 * @namespace components.filter_data_modal_rec
 * @description Filter data modal component
 */
export default {
  props: ["filterOptions", "updateFilterOptions"],
  data() {
    return {
      applicationDate: {
        filterType: "",
        fromDate: null,
        toDate: null,
      },
      moreFilters: {
        duplicateType: "",
        campusType: "",
      },
      fromDateFlag: false,
      toDateFlag: false,
      config: {
        format: "DD MMMM YYYY",
        maxDate: new Date(),
        showClear: true,
        showClose: true,
      },
    };
  },
  components: {
    datePicker,
  },
  validations() {
    const applicationDate = {
      filterType: {
        required,
      },
    };
    if (this.applicationDate.filterType === "Custom") {
      applicationDate.fromDate = {
        required,
      };
    }
    return {
      applicationDate,
    };
  },
  computed: {
    disableButton() {
      if (
        this.applicationDate.filterType ||
        this.moreFilters.duplicateType ||
        this.moreFilters.campusType
      ) {
        if (
          this.applicationDate.filterType &&
          this.applicationDate.filterType == "Custom"
        ) {
          if (this.applicationDate.fromDate) {
            return !this.isDateValid;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
      return true;
    },
    isDateValid() {
      if (this.applicationDate.fromDate && this.applicationDate.toDate) {
        return new Date(this.applicationDate.fromDate) >
          new Date(this.applicationDate.toDate)
          ? false
          : true;
      }
      return true;
    },
  },
  methods: {
    /**
     * @memberOf module:CampusRecruitment.components.filter_data_modal_rec
     * @description Validation for from date and to date
     */
    dateRequired(dateType) {
      if (dateType === "fromDate") {
        this.fromDateFlag = !this.applicationDate.fromDate ? true : false;
      } else {
        this.toDateFlag = !this.applicationDate.toDate ? true : false;
      }
    },
    /**
     * @memberOf module:CampusRecruitment.components.filter_data_modal_rec
     * @description Applies filter to the data
     */
    onApplyFilter() {
      let days;
      let curDate = new Date();
      switch (this.applicationDate.filterType) {
        case "Last One Week":
          days = 7;
          this.applicationDate.toDate = curDate;
          this.applicationDate.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last One Month":
          days = 30;
          this.applicationDate.toDate = curDate;
          this.applicationDate.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last Three Months":
          days = 90;
          this.applicationDate.toDate = curDate;
          this.applicationDate.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last Six Months":
          days = 180;
          this.applicationDate.toDate = curDate;
          this.applicationDate.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last One Year":
          days = 365;
          this.applicationDate.toDate = curDate;
          this.applicationDate.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Custom":
          if (this.applicationDate.toDate == null) {
            this.applicationDate.toDate = null;
          } else {
            this.applicationDate.toDate = new Date(this.applicationDate.toDate);
          }
          this.applicationDate.fromDate = new Date(
            this.applicationDate.fromDate
          );
          break;
        case "":
          this.applicationDate.fromDate = null;
          this.applicationDate.toDate = null;
          break;
      }
      this.updateFilterOptions({
        applicationDate: { ...this.applicationDate },
        moreFilters: { ...this.moreFilters },
      });
    },
    onResetFilter() {
      this.applicationDate = { filterType: "", fromDate: null, toDate: null };
      this.moreFilters = { duplicateType: "", campusType: "" };
      this.updateFilterOptions({
        applicationDate: { ...this.applicationDate },
        moreFilters: { ...this.moreFilters },
      });
    },
  },
  watch: {
    filterOptions(value) {
      this.applicationDate = { ...value.applicationDate };
      this.moreFilters = { ...value.moreFilters };
    },
  },
  beforeDestroy() {
    $("#filter-data-modal-rec").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
