import PERMISSIONS from "../Common/permissions";
import _resolveDuplicate from "./index.vue";

export default {
  path: "duplicates/:id/resolve",
  component: _resolveDuplicate,
  name: "resolveduplicate",
  meta: {
    title: "Resolve Duplicate",
    user: "employee",
    permissions: [PERMISSIONS.APPLICANT.READ],
    checkAll: true,
  },
};
