<template>
  <div id="snackbar">{{ message }}</div>
</template>

<script>
import { mapGetters } from "vuex";

/**
 * @memberof module:Common
 * @namespace snackbar_component
 */
export default {
  computed: {
    ...mapGetters({
      visibility: "snackbar/visibility",
      type: "snackbar/type",
      message: "snackbar/message",
    }),
  },
  watch: {
    /**
     * @memberof module:Common.snackbar_component
     * @param {Boolean} value new value
     * @description watch - displays snackbar based on  the value of visibility received
     */
    visibility(value) {
      if (value) {
        $("#snackbar").addClass("show " + this.type);
        setTimeout(() => {
          $("#snackbar").removeClass("show " + this.type);
          this.$store.dispatch("snackbar/close");
        }, 3000);
      }
    },
  },
};
</script>

<style scoped>
#snackbar {
  visibility: hidden;
  min-width: 250px;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 100000;
  right: 3%;
  bottom: 20px;
  font-size: 17px;
  box-shadow: 5px 5px 14px #888888;
}

.default {
  background-color: #333;
  color: #fff;
}

.success {
  color: #155724;
  background-color: #d4edda;
}

.info {
  color: #0c5460;
  background-color: #d1ecf1;
}

.warning {
  color: #856404;
  background-color: #fff3cd;
}

.danger {
  color: #721c24;
  background-color: #f8d7da;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 20px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 20px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 20px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 20px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
