import _homepageData from "./index.vue";

export default {
  path: "",
  component: _homepageData,
  name: "homepage",
  meta: {
    title: "Dashboard",
    user: "employee",
  },
};
