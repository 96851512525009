<template>
  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-sm" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Delete Template</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h4>Are you sure ?</h4>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-cp"
            @click="deleteItem"
            data-dismiss="modal"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberof module:MailBox
 * @namespace components.delete_template_modal
 * @description Modal for delete template confirmation
 */
export default {
  props: ["id", "templates"],
  methods: {
    /**
     * @memberof module:MailBox.components.delete_template_modal
     * @description method - deletes the template
     */
    deleteItem() {
      this.$store.dispatch("$_mailbox/deleteTemplate", this.id);
      this.updateTemplateOrderOnDel(this.id);
    },
    updateTemplateOrderOnDel(id) {
      const delIdx = this.templates.findIndex((temp) => temp.id === id);
      for (let idx = delIdx + 1; idx < this.templates.length; idx++) {
        this.templates[idx].order -= 1;
      }
      this.$store.dispatch("$_mailbox/updateTemplateOrder", this.templates);
    },
  },
  beforeDestroy() {
    $("#deleteModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
