<template>
  <div class="content">
    <common-header :data="['home', 'mail', 'compose']" />
    <div class="container-fluid">
      <div class="row mx-0">
        <div
          class="col-md-8 col-xs-12 px-0 pr-md-3"
          v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
        >
          <mail-box @height-updated="handleHeightUpdate"></mail-box>
        </div>
        <div
          class="col-md-4 col-xs-12 px-0 pl-md-3"
          v-if="hasAccess({ permissions: [BASE_PERMISSION.TEMPLATE.READ] })"
        >
          <templates :currentHeight="currentHeight"></templates>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Templates from "./template.vue";
import MailBox from "./mailbox.vue";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

/**
 * @module MailBox
 * @description mailbox module
 */
export default {
  /**
   * @memberof module:MailBox
   * @namespace components
   * @description components of mailbox module
   */
  components: {
    Templates,
    MailBox,
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.MAIL;
    },
  },
  data() {
    return {
      currentHeight: 0,
    };
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },

    handleHeightUpdate(height) {
      this.currentHeight = height;
    },
  },
};
</script>

<style scoped>
#mail-container {
  white-space: normal;
}
</style>
