import axios from "axios";

/**
 * @memberOf module:Edit Applicant Data.api
 * @param {string} slug slug to be sent to retrive data
 * @returns {Promise}
 */
const getApplicantData = (slug) => {
  return axios.get("applicants/by/slug/" + slug);
};

/**
 * @memberOf module:Edit Applicant Data.api
 * @param {Object} postUserdata Application data to be sent
 * @returns {Promise}
 */

const postUserdata = (postUserdata) => {
  let formData = new FormData();

  formData.append("resume", postUserdata["resume"]);
  let userData = JSON.stringify(postUserdata);
  formData.append("data", userData);
  return axios.put("applicants/applicant", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @memberOf module:Edit Applicant Data.api
 * @param {Object} userdata applicant name and resume to upload.
 * @returns {Promise}
 */
const uploadNewResume = (userdata) => {
  let formData = new FormData();

  formData.append("resume", userdata["resume"]);
  let userD = JSON.stringify(userdata);
  formData.append("data", userD);
  return axios.post("applicants/upload/new_resume", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export default {
  getApplicantData,
  postUserdata,
  uploadNewResume,
};
