<template>
  <div class="content">
    <common-header
      :data="[
        'home',
        'manage',
        'sharedToken',
        this.editMode ? 'editToken' : 'createToken',
      ]"
    />

    <form @submit.prevent="handleSubmit">
      <div class="container p-3 mb-5 bg-white rounded">
        <hr />
        <div class="row mb-3" style="justify-content: space-between">
          <div class="col-md-4">
            <div class="form-group">
              <label for="title">Title</label>
              <input
                type="text"
                id="title"
                name="title"
                v-model="title"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <div class="d-flex justify-content-between align-items-center">
                <label for="expiryDate"
                  >Expiry Date<span class="text-danger">*</span></label
                >
                <div>
                  <input
                    type="checkbox"
                    name="never"
                    v-model="defaultExpiryChecked"
                    @change="toggleDefaultDate()"
                  />
                  <label for="never" style="margin-left: 5px">Never</label>
                </div>
              </div>

              <div class="input-group">
                <date-picker
                  id="datePickerElement"
                  v-model="expiryDate"
                  :config="config"
                  :min="getCurrentDate()"
                  :disabled="defaultExpiryChecked"
                  name="expiryDate"
                  v-validate="'required'"
                >
                </date-picker>
                <div class="input-group-append">
                  <span
                    class="input-group-text"
                    id="basic-addon2"
                    @click="toggleDatePicker"
                  >
                    <i class="fa fa-calendar" aria-hidden="false"></i>
                  </span>
                </div>
              </div>
              <div
                :class="{
                  visible: showMissingFieldsError && !expiryDate,
                  invisible: !showMissingFieldsError || expiryDate,
                }"
              >
                <p style="font-size: small" class="text-danger">
                  Please fill in all mandatory fields.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3" style="justify-content: space-between">
          <div class="col-md-4">
            <div
              class="form-group"
              :class="{ 'has-error': errors.has('email') }"
            >
              <label for="email"
                >User Email<span class="text-danger">*</span></label
              >
              <input
                type="email"
                id="email"
                name="email"
                v-model="email"
                class="form-control"
                v-validate="'required|email'"
              />
              <span
                v-if="email"
                v-show="errors.has('email')"
                class="text-danger"
              >
                {{ errors.first("email") }}
              </span>
              <div
                :class="{
                  visible: showMissingFieldsError && !email,
                  invisible: !showMissingFieldsError || email,
                }"
              >
                <p style="font-size: small" class="text-danger">
                  Please fill in all mandatory fields.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="description">Description</label>
              <textarea
                id="description"
                name="description"
                v-model="description"
                class="form-control"
                style="height: 40px"
              >
              </textarea>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div>
            <label style="font-weight: bold"
              >Endpoint Groups<span class="text-danger">*</span></label
            >
          </div>
          <div
            :class="{
              visible: !selectedEndpoints.length && showMissingFieldsError,
              invisible: selectedEndpoints.length || !showMissingFieldsError,
            }"
          >
            <p style="font-size: small" class="text-danger">
              Please fill in all mandatory fields.
            </p>
          </div>

          <div v-for="(group, groupName) in endpointGroups" :key="groupName">
            <collapse-card
              :midDivider="false"
              :title="groupName"
              :collapse="true"
              class="my-3 theme-default"
              @click="toggleEndpointGroupVisibility(groupName)"
            >
              <template v-slot:left>
                <input
                  type="checkbox"
                  :id="groupName"
                  v-model="selectedGroups"
                  :value="groupName"
                  class="group-checkbox"
                  @change="toggleGroupSelection(groupName)"
              /></template>
              <div>
                <div
                  v-for="(action, actionKey) in group"
                  :key="actionKey"
                  class="endpoint-item"
                >
                  <div class="card m-2">
                    <div
                      class="card-body d-flex pl-4 justify-content-between py-2"
                    >
                      <div class="">
                        <input
                          type="checkbox"
                          :checked="selectedEndpoints.includes(action.ID)"
                          @change="toggleEndpoint(action.ID, groupName)"
                          class="endpoint-checkbox"
                          style="margin-right: 20px"
                        />
                        {{ action.VALUE }}
                        <div
                          class="card-text"
                          style="margin-left: 30px; font-style: italic"
                        >
                          {{ action.PATH }}
                        </div>
                      </div>
                      <div class="py-2">
                        <span
                          style="font-size: small"
                          class="bg-secondary px-2 py-1 rounded text-white"
                        >
                          {{ action.METHOD }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </collapse-card>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-cp">
            {{ submitButtonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SharedTokenService from "../_api/index";
import { addSnackbarMessage } from "../../Common/_plugin/error-response";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";
import datePicker from "vue-bootstrap-datetimepicker";
import CommonHeader from "../../Common/_components/common-header.vue";
import { Validator } from "vee-validate";

export default {
  components: { CollapseCard, datePicker, CommonHeader },
  props: {
    tokenId: {
      type: [Number, String],
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: "",
      title: "",
      description: "",
      selectedGroups: [],
      selectedEndpoints: [],
      expiryDate: "",
      showMissingFieldsError: false,
      groupVisibility: {},
      defaultExpiryChecked: false,
      config: {
        format: "DD/MM/YYYY",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
        minDate: new Date(),
      },
      datePickerFlag: false,
    };
  },
  computed: {
    ...mapGetters({
      endpointGroups: "$_shared_token/getEndpointObject",
    }),

    submitButtonText() {
      return this.editMode ? "Update" : "Create";
    },
  },
  methods: {
    handleSubmit() {
      if (
        (this.submitButtonText === "Create" ||
          this.submitButtonText === "Update") &&
        (this.email === "" ||
          this.selectedEndpoints.length === 0 ||
          this.expiryDate === "")
      ) {
        this.showMissingFieldsError = true;
        return; // Prevent form submission
      }

      // Check if any validation errors exist
      if (this.$validator.errors.any()) {
        return; // Prevent form submission
      }

      // Validation logic
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.addToken();
        } else {
          return result;
        }
      });
    },
    addToken() {
      const data = {
        email: this.email,
        endpoints: this.selectedEndpoints,
        title: this.title,
        description: this.description,
      };
      if (this.expiryDate) {
        data.expiredAt = this.expiryDate + " 23:59:59";
      }
      if (this.tokenId) {
        data.id = this.tokenId;
      }

      const action = this.tokenId
        ? this.$store.dispatch("$_shared_token/updateSharedToken", data)
        : this.$store.dispatch("$_shared_token/createSharedToken", data);

      action
        .then(() => {})
        .catch((error) => {
          addSnackbarMessage(error, "Unable to create token", true);
        });
    },
    formatDefaultDate(date) {
      // Extract day, month, and year from the date object
      const day = date.getDate();
      const month = date.getMonth() + 1; // Month starts from 0
      const year = date.getFullYear();

      // Format the date as dd-mm-yyyy
      return `${day < 10 ? "0" + day : day}-${
        month < 10 ? "0" + month : month
      }-${year}`;
    },
    toggleDefaultDate() {
      if (this.defaultExpiryChecked) {
        // Checkbox is checked, set expiry date 100 years from current date
        const currentDate = new Date();
        const expiryDate = new Date(currentDate);
        expiryDate.setFullYear(currentDate.getFullYear() + 100);
        const formattedExpiryDate = this.formatDefaultDate(expiryDate);
        this.expiryDate = formattedExpiryDate;
      } else {
        // Checkbox is unchecked, clear expiry date
        this.expiryDate = "";
      }
    },
    getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      let day = currentDate.getDate();

      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      return `${day}-${month}-${year}`;
    },
    isGroupExpanded(groupName) {
      return !!this.groupVisibility[groupName];
    },
    toggleEndpointGroupVisibility(groupName) {
      this.$set(
        this.groupVisibility,
        groupName,
        !this.isGroupExpanded(groupName)
      );
    },
    toggleGroupSelection(groupName) {
      const group = this.endpointGroups[groupName];
      const allSelected = Object.values(group).every((action) =>
        this.selectedEndpoints.includes(action.ID)
      );

      // If all endpoints in the group are selected, deselect them
      if (allSelected) {
        Object.values(group).forEach((action) => {
          const index = this.selectedEndpoints.indexOf(action.ID);
          this.selectedEndpoints.splice(index, 1);
        });
        this.selectedGroups = this.selectedGroups.filter(
          (group) => group !== groupName
        );
      } else {
        // If any endpoint in the group is not selected, select them
        Object.values(group).forEach((action) => {
          if (!this.selectedEndpoints.includes(action.ID)) {
            this.selectedEndpoints.push(action.ID);
          }
        });
        if (!this.selectedGroups.includes(groupName)) {
          this.selectedGroups.push(groupName);
        }
      }
    },

    toggleEndpoint(endpointId, groupName) {
      const group = this.endpointGroups[groupName];
      const selectedGroupEndpoints = Object.values(group).map(
        (action) => action.ID
      );

      // Toggle selection of the endpoint
      if (this.selectedEndpoints.includes(endpointId)) {
        this.selectedEndpoints = this.selectedEndpoints.filter(
          (id) => id !== endpointId
        );
      } else {
        this.selectedEndpoints.push(endpointId);
      }

      // Update group selection based on the state of its endpoints
      const allSelected = selectedGroupEndpoints.every((id) =>
        this.selectedEndpoints.includes(id)
      );
      const someSelected = selectedGroupEndpoints.some((id) =>
        this.selectedEndpoints.includes(id)
      );

      // Update the group checkbox state
      const element = document.getElementById(groupName);
      element.checked = allSelected;
      element.indeterminate = someSelected && !allSelected;

      // Update selectedGroups based on the group checkbox state
      if (!someSelected || allSelected) {
        if (allSelected && !this.selectedGroups.includes(groupName)) {
          this.selectedGroups.push(groupName);
        } else if (!allSelected && this.selectedGroups.includes(groupName)) {
          this.selectedGroups = this.selectedGroups.filter(
            (group) => group !== groupName
          );
        }
      }
    },
    formatDate(dateString) {
      const datePart = dateString.split("T")[0]; // Extracting only the date part
      const [year, month, day] = datePart.split("-");
      return `${day}-${month}-${year}`;
    },
    fetchTokenData(tokenId) {
      SharedTokenService.getSharedTokenById(tokenId)
        .then((res) => {
          const tokenData = res.data;
          this.email = tokenData.email;
          this.title = tokenData.title;
          this.description = tokenData.description;
          this.expiryDate = this.formatDate(tokenData.expiredAt);

          const selectedEndpoints = tokenData.endpoints.map(
            (endpoint) => endpoint
          );

          // Reset selectedGroups array
          this.selectedGroups = [];

          // Loop through each group
          Object.keys(this.endpointGroups).forEach((groupName) => {
            const group = this.endpointGroups[groupName];
            const groupEndpoints = Object.values(group).map(
              (action) => action.ID
            );

            // Check if all endpoints in the group are selected
            const allSelected = groupEndpoints.every((id) =>
              selectedEndpoints.includes(id)
            );

            // Check if any endpoint in the group is selected
            const someSelected = groupEndpoints.some((id) =>
              selectedEndpoints.includes(id)
            );

            // If all endpoints in the group are selected, mark the group checkbox as checked
            const element = document.getElementById(groupName);
            element.checked = allSelected;

            // If some but not all endpoints are selected, set the group checkbox to indeterminate
            element.indeterminate = someSelected && !element.checked;
          });

          // Set selectedEndpoints
          this.selectedEndpoints = selectedEndpoints;
        })
        .catch((error) => {
          console.error("Error fetching token data:", error);
        });
    },

    toggleDatePicker() {
      $("#datePickerElement").data("DateTimePicker").toggle();
    },
  },
  created() {
    if (this.tokenId) {
      this.fetchTokenData(this.tokenId);
    }
    this.$store.dispatch("$_shared_token/fetchEndpointObject");
  },
  mounted() {
    // Initialize the validator
    Validator.localize("en");
    this.$validator.localize("en");
  },
};
</script>
