<template>
  <footer class="footer">
    <div class="copyright">
      <div class="container mb-0">
        <p class="mb-0">© {{ currentYear }} Argusoft | All Rights Reserved</p>
      </div>
    </div>
  </footer>
</template>

<script>
/**
 * @memberof module:Common
 * @namespace footer-section
 */
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.copyright {
  padding: 15px 0px;
  background: #903564;
  text-align: center;
  color: #fff;
  position: relative;
}

.footer {
  /* margin-top: 100px; */
  bottom: 0;
  width: 100%;
}
</style>
