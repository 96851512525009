const DOMPurify = require("dompurify");

// sanitization of html content
export const textSanitize = (inputText) => {
  const sanitizedText = DOMPurify.sanitize(inputText, {
    ALLOWED_ATTR: ["style"],
  });
  return sanitizedText;
};

// sanitization of url
export const urlSanitize = (inputUrl) => {
  const sanitizedUrl = DOMPurify.sanitize(inputUrl, {});
  return sanitizedUrl;
};

// verification of url
export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    console.log("invalid Url");
    return false;
  }
};
