import _applicantDashboard from "./_components/applicant-dashboard.vue";
import _applicantApplications from "./_components/applicant-applications.vue";
import _applicantApplicationBySlug from "./_components/view-application.vue";
import _linkApplications from "./_components/link-applications.vue";
import _jobOpenings from "./_components/applicant-job-openings.vue";
import _jobDescription from "../Home/_components/job-description.vue";
import _jobApplicationForm from "./_components/application-form.vue";
import _applicantProfile from "./_components/applicant-profile.vue";
import _applicantEmailVerification from "./_components/applicant-email-verification.vue";

export default [
  {
    path: "dashboard",
    component: _applicantDashboard,
    meta: {
      title: "Applicant Dashboard",
      credRequired: true,
      user: "applicant",
    },
    children: [
      {
        path: "",
        redirect: "applications",
      },
      {
        path: "openings",
        component: _jobOpenings,
        name: "applicantJobOpenings",
        meta: {
          title: "Current Openings",
          credRequired: true,
          user: "applicant",
        },
      },
      {
        path: "job-profile/:jobId",
        component: _jobDescription,
        name: "applicantJobProfile",
        meta: {
          title: "Job Profile",
          credRequired: true,
          user: "applicant",
        },
        props: {
          isApplicant: true,
        },
      },
      {
        path: "job-application-form",
        component: _jobApplicationForm,
        name: "jobApplicationForm",
        meta: {
          title: "Job Application Form",
          credRequired: true,
          user: "applicant",
        },
        props: {
          isApplicant: true,
        },
      },
      {
        path: "applications",
        component: _applicantApplications,
        name: "applicantApplications",
        meta: {
          title: "Your Applications",
          credRequired: true,
          user: "applicant",
        },
      },
      {
        path: "application/:slug",
        component: _applicantApplicationBySlug,
        name: "applicantApplicationBySlug",
        meta: {
          title: "Application Details",
          credRequired: true,
          user: "applicant",
        },
      },
      {
        path: "link-request",
        component: _linkApplications,
        name: "linkApplicationRequest",
        meta: {
          title: "Link Application Request",
          credRequired: true,
          user: "applicant",
        },
      },
    ],
  },
  {
    path: "profile",
    component: _applicantProfile,
    name: "applicantProfile",
    meta: {
      title: "Applicant Profile",
      credRequired: true,
      user: "applicant",
    },
  },
  {
    path: "email-verification",
    component: _applicantEmailVerification,
    name: "applicantEmailVerification",
    meta: {
      title: "Applicant Email Verification",
      credRequired: false,
      user: "applicant",
    },
  },
];
