<template>
  <div class="content">
    <common-header :data="['home', 'manage', 'manageFiles']"> </common-header>
    <div class="container-fluid">
      <FolderItem
        v-for="(folder, index) in sortedItems"
        :key="index"
        :item="folder"
        :downloadFile="downloadFile"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FolderItem from "./folder-item.vue";

export default {
  data() {
    return {
      folderStructure: [],
    };
  },
  components: {
    FolderItem,
  },
  computed: {
    sortedItems() {
      return this.folderStructure.slice().sort((a, b) => {
        if (a.type === b.type) return 0;
        return a.type === "file" ? 1 : -1;
      });
    },
  },
  created() {
    axios
      .get("/folder-structure")
      .then((response) => {
        this.folderStructure = response.data;
      })
      .catch((error) => {
        console.error("Error fetching folder structure:", error);
      });
  },
  methods: {
    async downloadFile(filePath) {
      try {
        const response = await axios.get(`/download/${filePath}`, {
          responseType: "blob",
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filePath.split("/").pop());
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        // Optionally, revoke the blob URL to free up memory
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },
  },
};
</script>
