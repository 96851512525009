import { addSnackbarMessage } from "../../Common/_plugin/error-response";
import {
  FRESHER_OPENING,
  PROFESSIONAL_OPENING,
  SKILL_SET,
} from "../../../../config/configurations";
import api from "../_api";
import store from "../../../store/index";
/**
 * @memberOf module:Home.store.actions
 * @param {Object} context store context
 * @description get data of Job categories and Jobs
 */
const fetchCategoriesWithJobs = (context) => {
  api
    .fetchCategoriesWithJobs()
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_CATEGORIES_WITH_JOBS", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};
/**
 * @memberOf module:Home.store.actions
 * @param {Object} context store context
 * @description get data of Active Job categories and Active Jobs
 */
const fetchActiveCategoriesWithJobs = (context) => {
  api
    .fetchActiveCategoriesWithJobs()
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_ACTIVE_CATEGORIES_WITH_JOBS", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};
/**
 * @memberOf module:Home.store.actions
 * @param {Object} context store context
 * @description get data of Active Job categories and Active Jobs
 */
const fetchJobBySlug = (context, id) => {
  api
    .fetchJobBySlug(id)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_JOB_BY_ID", data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};
/**
 * @memberOf module:Home.store.actions
 * @param {Object} context store context
 * @description get data of Review categories and Reviews
 */

const fetchReviewCategoriesWithReviews = (context) => {
  api
    .fetchReviewCategoriesWithReviews()
    .then((response) => {
      const data = response.data.reviewCategories;

      data.forEach((reviewCategory) => {
        reviewCategory.reviews = [];
      });

      response.data.reviews.forEach((review) => {
        const reviewCategoryIndex = data.findIndex(
          (reviewCategory) => reviewCategory.id === review.reviewCategoryId
        );
        data[reviewCategoryIndex].reviews.push(review);
      });
      context.commit("FETCH_REVIEW_CATEGORIES_WITH_REVIEWS", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:Home.store.actions
 * @param {Object} context store context
 * @param {Object} payload Job Application data to be sent
 * @description sends data of Job Application to server
 */
const postUserdata = (context, payload) => {
  store.dispatch("setLoading", {
    isLoading: true,
    loadingMessage: "please wait form is being submitted",
  });
  api
    .postUserdata(payload)
    .then((response) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("APPLICANT_DATA_SUBMITTED", {
        status: "SUCCESS",
        uid: response.data.slug,
      });
    })
    .catch((error) => {
      store.dispatch("setLoading", {
        isLoading: false,
        loadingMessage: " ",
      });
      context.commit("APPLICANT_DATA_SUBMITTED", {
        status: "FAILURE",
        error_data: error,
        uid: null,
      });
    });
};

/**
 * @memberOf module:Home.store.actions
 * @param {Object} context store context
 * @param {Object} payload Applicat's details or Application Id to sent
 * @description sends data of Applicat's details or Application Id to get current status of Job Applicantion
 */
const checkStatus = (context, payload) => {
  api
    .checkStatus(payload)
    .then((response) => {
      context.commit("APPLICATION_STATUS", response.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:Home.store.actions
 * @param {Object} context store context
 * @param {Object} applicantData Applicant's data slug
 * @description send notification email as new application is recieved
 */
const sendNewApplicationMail = (context, applicantData) => {
  api
    .sendNewApplicationMail(applicantData)
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
};

/**
 * @memberOf module:Home.store.actions
 * @param {Object} context store context
 * @param {Object} jobOpeningKey Boolean keys for job openings
 * @description send notification email as new application is recieved
 */
const fetchJobOpeningKeys = (context) => {
  api
    .fetchJobOpeningKeys()
    .then((response) => {
      const data = response.data;
      const fresherOpeningData = data.find(
        (item) => item.key === FRESHER_OPENING.key
      );
      const professionalOpeningData = data.find(
        (item) => item.key === PROFESSIONAL_OPENING.key
      );
      const skillSetData = data.find((item) => item.key === SKILL_SET.key);
      context.commit("Job_Opening_Fresher", fresherOpeningData);
      context.commit("Job_Opening_Professional", professionalOpeningData);
      context.commit("Job_Opening_Skills", skillSetData);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving job keys!", true);
    });
};

/**
 * @memberOf module:Home
 * @namespace store.actions
 * @description actions for home module
 */
export default {
  postUserdata,
  fetchReviewCategoriesWithReviews,
  checkStatus,
  fetchCategoriesWithJobs,
  sendNewApplicationMail,
  fetchJobOpeningKeys,
  fetchActiveCategoriesWithJobs,
  fetchJobBySlug,
};
