<template>
  <div class="mb-3">
    <CollapseCard
      class="theme-default"
      title="Hiring Pipeline"
      :midDivider="false"
      header-class="px-0 py-0"
      :collapse="true"
      title-class="h5 m-0"
    >
      <template v-slot:right>
        <div class="d-flex align-items-center flex-wrap">
          <div class="ml-auto">
            <select
              class="form-control form-control-sm"
              v-model="filterOptions.employmentStatus"
              @change="onChangeEmploymentStatus"
            >
              <option selected value="P">Professionals</option>
              <option selected value="G">Freshers</option>
            </select>
          </div>
          <div class="d-flex align-items-center ml-auto pl-2">
            {{ statusByIndex[activeTabIndex].type }}
            <div>
              <span class="bg-secondary px-2 rounded ml-2 text-white">{{
                currentCount
              }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="px-2 p-2">
        <b-tabs
          v-model="activeTabIndex"
          active-nav-item-class="font-weight-bold text-uppercase"
          content-class="mt-3"
          justified
        >
          <b-tab lazy>
            <template #title>
              Processed
              <span
                :class="[
                  'px-2 ml-2 rounded text-white',
                  activeTabIndex === 0 ? 'theme-bg-clr' : 'bg-secondary',
                ]"
                style="font-size: 12px"
              >
                {{ hiringPipelineData?.processed }}
              </span>
            </template>
          </b-tab>
          <b-tab lazy>
            <template #title>
              Ongoing
              <span
                :class="[
                  'px-2 ml-2 rounded text-white',
                  activeTabIndex === 1 ? 'theme-bg-clr' : 'bg-secondary',
                ]"
                style="font-size: 12px"
              >
                {{ hiringPipelineData?.ongoing }}
              </span>
            </template>
          </b-tab>
          <b-tab lazy>
            <template #title>
              Hold
              <span
                :class="[
                  'px-2 ml-2 rounded text-white',
                  activeTabIndex === 2 ? 'theme-bg-clr' : 'bg-secondary',
                ]"
                style="font-size: 12px"
              >
                {{ hiringPipelineData?.held }}
              </span>
            </template>
          </b-tab>
          <b-tab lazy>
            <template #title>
              Shortlisted
              <span
                :class="[
                  'px-2 ml-2 rounded text-white',
                  activeTabIndex === 3 ? 'theme-bg-clr' : 'bg-secondary',
                ]"
                style="font-size: 12px"
              >
                {{ hiringPipelineData?.shortlisted }}
              </span>
            </template>
          </b-tab>
          <b-tab lazy>
            <template #title>
              Joined
              <span
                :class="[
                  'px-2 ml-2 rounded text-white',
                  activeTabIndex === 4 ? 'theme-bg-clr' : 'bg-secondary',
                ]"
                style="font-size: 12px"
              >
                {{ hiringPipelineData?.joined }}
              </span>
            </template>
          </b-tab>
        </b-tabs>
        <div>
          <div v-if="activeTabIndex == 4">
            <select
              class="form-control my-1 width-auto"
              style="width: auto"
              v-model="filterOptions.duration"
              @change="onChangeDuration"
            >
              <option selected value="l7d">In last 7 days</option>
              <option selected value="l15d">In last 15 days</option>
              <option selected value="l1m">In last 1 month</option>
              <option selected value="l3m">In last 3 months</option>
              <option selected value="l6m">In last 6 months</option>
              <option selected value="l1y">In last 1 year</option>
            </select>
          </div>
        </div>
        <div>
          <div class="row mx-0">
            <div class="w-100 d-flex justify-content-between"></div>
            <div style="width: 100%">
              <ag-grid-vue
                class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
                style="height: 75vh"
                :enableBrowserTooltips="true"
                :gridOptions="gridOptions"
                :getRowStyle="getRowStyle"
                :rowData="rowData"
                :columnDefs="columnDefs"
              >
              </ag-grid-vue>
            </div>
          </div>
        </div>
      </div>
    </CollapseCard>
  </div>
</template>
<script>
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";
import { getFilterColumns } from "@/modules/Common/_plugin/validation";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  components: {
    CollapseCard,
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="{name:'applicantprofile', params:{slug:params.data.slug}}"><i class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
  },
  data() {
    return {
      columnDefs: [],
      currentTitle: "Processed", // Default title
      currentCount: 0, // Default count
      filterOptions: {
        employmentStatus: "P",
        fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        toDate: moment().add(1, "days").format("YYYY-MM-DD"),
        duration: "l1m",
      },
      activeTabIndex: 0,
      statusByIndex: {
        0: { status: "Processed", type: "Processed", offCampus: true },
        1: {
          status: [
            "Aptitude Scheduled",
            "Written Test Scheduled",
            "Interview Scheduled",
            "Review Scheduled",
            "Interview Passed",
          ],
          type: "Ongoing",
          offCampus: true,
        },
        2: { status: "Held", type: "Held", offCampus: true },
        3: { status: "Shortlisted", type: "Shortlisted", offCampus: false },
        4: { status: "Joined", type: "Joined", offCampus: false },
      },
    };
  },
  watch: {
    activeTabIndex: {
      immediate: true,
      handler() {
        this.createColumnDefs();
        this.onChangeEmploymentStatus();
      },
    },
    "filterOptions.employmentStatus": function () {
      this.fetchHiringPipelineStats();
    },
    rowData: {
      deep: true,
      handler(newValue) {
        this.currentCount = newValue.length;
      },
    },
  },
  computed: {
    ...mapGetters({
      rowData: "$_dashboard/fetchApplicantsData",
      hiringPipelineData: "$_dashboard/fetchHiringPipelineStatsData",
    }),
  },
  methods: {
    onChangeDuration() {
      switch (this.filterOptions.duration) {
        case "l7d":
          this.filterOptions.fromDate = moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD");
          break;
        case "l15d":
          this.filterOptions.fromDate = moment()
            .subtract(15, "days")
            .format("YYYY-MM-DD");
          break;
        case "l3m":
          this.filterOptions.fromDate = moment()
            .subtract(3, "months")
            .format("YYYY-MM-DD");
          break;
        case "l6m":
          this.filterOptions.fromDate = moment()
            .subtract(6, "months")
            .format("YYYY-MM-DD");
          break;
        case "l1y":
          this.filterOptions.fromDate = moment()
            .subtract(12, "months")
            .format("YYYY-MM-DD");
          break;
        default:
          this.filterOptions.fromDate = moment()
            .subtract(1, "months")
            .format("YYYY-MM-DD");
          break;
      }

      this.fetchHiringPipelineStats();
      this.$store.dispatch("$_dashboard/fetchApplicantsData", {
        ...this.filterOptions,
        applicationStatus: this.statusByIndex[this.activeTabIndex].status,
      });
    },
    updateTitleAndCount(title, count) {
      this.currentTitle = title;
      this.currentCount = count;
    },
    fetchHiringPipelineStats() {
      this.$store.dispatch("$_dashboard/fetchHiringPipelineStats", {
        employment_status: this.filterOptions.employmentStatus,
        fromDate: this.filterOptions.fromDate,
        toDate: this.filterOptions.toDate,
      });
    },
    createColumnDefs() {
      const profile_link = {
        cellRenderer: "profile-link",
        width: 35,
        minWidth: 35,
        maxWidth: 35,
        suppressFilter: true,
        filter: false,
        valueGetter: "node.id",
        headerTooltip: "Profile Info",
        tooltipValueGetter: function (params) {
          if (params.data != undefined) {
            return "Profile of " + params.data.first_name;
          }
          return "";
        },
        display_only: true,
        cellStyle: { textAlign: "center", padding: 0 },
      };
      const name = {
        headerName: "Name",
        field: "name",
        valueGetter: function mergeLastNameFirstName(params) {
          if (params.data != undefined) {
            return params.data.first_name + " " + params.data.last_name;
          }
        },
        comparator: (valueA, valueB) => {
          if (valueA && valueB) {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          }
          return 0;
        },
        sortable: true,
        width: 200,
        minWidth: 200,
        suppressHeaderMenuButton: true,
        filterParams: {
          newRowsAction: "keep",
        },
      };
      const email = {
        headerName: "Email",
        field: "email",
        sortable: true,
        comparator: (valueA, valueB) => {
          if (valueA && valueB) {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          }
          return 0;
        },
        width: 200,
        minWidth: 200,
        suppressHeaderMenuButton: true,
      };
      const mobile = {
        headerName: "Mobile",
        field: "mobile_no",
        sortable: true,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      };
      const position = {
        headerName: "Position",
        field: "position",
        comparator: (valueA, valueB) => {
          if (valueA && valueB) {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          }
          return 0;
        },
        sortable: true,
        width: 140,
        minWidth: 140,
        suppressHeaderMenuButton: true,
      };
      const processedDate = {
        headerName: "Last Update",
        field: "updatedAt",
        sortable: true,
        width: 140,
        minWidth: 140,
        suppressHeaderMenuButton: true,
        cellRenderer: (params) => {
          if (params.data.updatedAt) {
            const updatedDate = moment(params.data.updatedAt);
            const today = moment();
            const dayPassed = today.diff(updatedDate, "days");
            return `${dayPassed} days ago`;
          } else return "NA";
        },
      };
      const joining = {
        headerName: "Joining Date",
        field: "expected_joining",
        sortable: true,
        width: 150,
        minWidth: 150,
        suppressHeaderMenuButton: true,
        cellRenderer: (params) => {
          if (params.data.expected_joining)
            return moment(params.data.expected_joining).format("DD/MM/YYYY");
          else return "NA";
        },
        filter: "agDateColumnFilter",
      };
      const status = {
        headerName: "Application Status",
        field: "application_status",
        sortable: true,
        width: 170,
        minWidth: 170,
        suppressHeaderMenuButton: true,
      };
      if (this.activeTabIndex == 0) {
        this.columnDefs = [
          profile_link,
          name,
          position,
          processedDate,
          email,
          mobile,
        ];
      } else if (this.activeTabIndex == 1) {
        this.columnDefs = [profile_link, name, status, position, email, mobile];
      } else if (this.activeTabIndex == 2) {
        this.columnDefs = [profile_link, name, position, email, mobile];
      } else if (this.activeTabIndex == 3 || this.activeTabIndex == 4) {
        this.columnDefs = [
          profile_link,
          name,
          joining,
          position,
          email,
          mobile,
        ];
      }
    },
    onChangeEmploymentStatus() {
      const filters = {
        applicationStatus: this.statusByIndex[this.activeTabIndex].status,
        employmentStatus: this.filterOptions.employmentStatus,
        offCampus: this.statusByIndex[this.activeTabIndex].offCampus,
      };
      if (this.activeTabIndex == 4) {
        filters.fromDate = this.filterOptions.fromDate;
        filters.toDate = this.filterOptions.toDate;
        filters.duration = this.filterOptions.duration;
      }
      this.$store.dispatch("$_dashboard/fetchApplicantsData", filters);
    },
    getRowStyle(params) {
      if (params.data.updatedAt && this.activeTabIndex == 0) {
        const updatedDate = moment(params.data.updatedAt);
        const today = moment();
        const dayPassed = today.diff(updatedDate, "days");

        if (dayPassed > 10) {
          return { backgroundColor: "#FFAE42" };
        }
      }
      return null;
    },
  },
  mounted() {
    this.fetchHiringPipelineStats();
  },
  beforeMount() {
    this.createColumnDefs();
    this.gridOptions = {
      headerHeight: 35,
      rowHeight: 37,
      columnDefs: getFilterColumns(this.columnDefs, 1),
      suppressPropertyNamesCheck: true,
      enableCellTextSelection: true,
      enableServerSideFilter: true,
      enableColResize: true,
      defaultColDef: {
        flex: 1,
        floatingFilter: true,
        resizable: true,
        filterParams: {
          suppressAndOrCondition: true,
          buttons: ["reset"],
        },
        // set filtering on for all columns
        filter: true,
      },
      pagination: true,
      paginationPageSize: 100,
      paginationPageSizeSelector: false,
      context: {
        componentParent: this,
      },
    };
  },
};
</script>
<style scoped>
#applicantTracker {
  min-height: 500px;
}
.ag-grid-style {
  width: 100%;
  height: 86%;
}
</style>
