<template>
  <div id="container" class="index-style">
    <router-view></router-view>
  </div>
</template>

<script>
import store from "./_store";
import campusRecruitmentStore from "../CampusRecruitment/_store";
import applicantDataStore from "../Applications/_store";
import mailboxstore from "../MailBox/_store";
import applicantProfileStore from "../ApplicantProfile/_store";
import formTemplateStore from "../FormTemplate/_store";
import applicantReportStore from "./_store/applicant-report";
/**
 * @module Report
 */
export default {
  /**
   * @memberof module:Report
   * @namespace components
   * @description components of Report module
   */
  components: {},
  created() {
    const STORE_KEY = "$_report";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }

    const CAMPUS_RECRUTIMENT_STORE_KEY = "$_campus_recruitment";
    if (
      !(CAMPUS_RECRUTIMENT_STORE_KEY in this.$store._modules.root._children)
    ) {
      this.$store.registerModule(
        CAMPUS_RECRUTIMENT_STORE_KEY,
        campusRecruitmentStore
      );
    }

    const APPLICANTDATA_STORE_KEY = "$_applicants_data";
    if (!(APPLICANTDATA_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(APPLICANTDATA_STORE_KEY, applicantDataStore);
    }

    const MAILBOX_STORE_KEY = "$_mailbox";
    if (!(MAILBOX_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(MAILBOX_STORE_KEY, mailboxstore);
    }

    const FORM_TEMPLATE_STORE_KEY = "$_form_template";
    if (!(FORM_TEMPLATE_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(FORM_TEMPLATE_STORE_KEY, formTemplateStore);
    }

    const APPLICANT_PROFILE_STORE_KEY = "$_applicant_profile";
    if (!(APPLICANT_PROFILE_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(
        APPLICANT_PROFILE_STORE_KEY,
        applicantProfileStore
      );
    }
    const APPLICANT_REPORT_STORE_KEY = "$_applicant_report";
    if (!(APPLICANT_REPORT_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(
        APPLICANT_REPORT_STORE_KEY,
        applicantReportStore
      );
    }
  },
};
</script>

<style>
.index-style {
  height: 100%;
}
</style>
