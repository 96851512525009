<template>
  <div class="commonPadding">
    <div class="addDetailsContainer" v-if="!isForEditData">
      <div class="addDetailsHeader">
        <div class="addDetailsImage">
          <b-icon icon="file-earmark" class="h3" aria-hidden="true"></b-icon>
        </div>
        <div class="addDetailItem">
          <div class="addDetailItemTitle">Add Project Details</div>
          <div class="addDetailItemSubtitle">
            Projects that you have worked on before
          </div>
        </div>
      </div>
      <div></div>
      <button
        v-b-modal.add-project-modal
        type="button"
        @click="addProject"
        class="btn btn-cp ml-2"
      >
        Add
      </button>
    </div>
    <delete-modal
      :modal-id="'deleteModalProjects'"
      item="Project"
      :on-delete="removeProject"
      :item-id="projectId"
    ></delete-modal>
    <div
      v-if="
        isApplicantLoggedIn &&
        !isApplicantProfile &&
        applicantData.projectDetails.length > 0
      "
      class="addDetailItemSubtitle mb-4"
      style="color: #d4205a"
    >
      Click on the checkbox to select the projects you want to consider for the
      job application.
    </div>
    <div
      class="table-responsive"
      v-if="applicantData.projectDetails.length > 0"
    >
      <table
        class="table table-striped table-hover"
        aria-describedby="Project Details Table"
      >
        <thead>
          <tr>
            <th v-if="isApplicantLoggedIn && !isApplicantProfile"></th>
            <th>#</th>
            <th>Project Title</th>
            <th>Technologies Used</th>
            <th>Description</th>
            <th>Demo Link</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th v-if="!isForEditData">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(project, index) in applicantData.projectDetails"
            :key="index"
          >
            <td v-if="isApplicantLoggedIn && !isApplicantProfile">
              <input
                style="margin-top: 6px"
                type="checkbox"
                class="form-check-input mx-auto"
                :checked="isChecked(project.id)"
                @change="handleCheckboxChange(project.id)"
              />
            </td>
            <td>{{ index + 1 }}</td>
            <td
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="project.title.length > 12 && project.title"
            >
              {{
                project.title.length > 12
                  ? project.title.slice(0, 12) + "..."
                  : project.title
              }}
            </td>
            <td>
              <div class="skillTagsContainer" style="max-width: 200px">
                <span
                  class="cardSkillTag"
                  v-for="(tech, techIndex) in project.techUsed"
                  :key="techIndex"
                >
                  {{ tech }}
                </span>
              </div>
            </td>

            <td
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="project.description.length > 20 && project.description"
            >
              {{
                project.description.length > 20
                  ? project.description.slice(0, 20) + " ..."
                  : project.description
              }}
            </td>
            <td>
              <safe-link
                v-if="project.demoLink && isValidUrl(project.demoLink)"
                :link="project.demoLink"
              >
                <b-icon icon="link" variant="success"></b-icon>
              </safe-link>
              <span v-else> NA </span>
            </td>
            <td>{{ project.startDate }}</td>
            <td>{{ project.endDate }}</td>
            <td v-if="!isForEditData" class="d-flex">
              <button
                type="button"
                v-b-modal.add-project-modal
                class="btn btn-sm"
                title="Edit"
                @click="editProject(index)"
              >
                <b-icon icon="pencil-square" variant="primary"></b-icon>
              </button>

              <button
                class="btn btn-sm"
                id="project_job_icon"
                href="#"
                type="button"
                data-toggle="modal"
                data-target="#deleteModalProjects"
                data-backdrop="static"
                data-keyboard="false"
                @click="setProjectId(isApplicantLoggedIn ? project.id : index)"
              >
                <b-icon icon="trash" variant="primary"></b-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h5
      class="text-center"
      v-if="isForEditData && applicantData.projectDetails.length === 0"
    >
      No project details added yet.
    </h5>
    <b-modal
      id="add-project-modal"
      no-close-on-backdrop
      ref="modal"
      :title="project.headline"
      scrollable
      @ok.prevent="submitProject"
    >
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont" htmlFor="projectTitle"
          >Project Title
        </label>
        <input
          name="project_title"
          type="text"
          required
          class="form-control"
          v-validate="'required|min:3|max:50'"
          :class="{ 'is-invalid': errors.first('project_title') }"
          v-model="newProject.title"
        />
        <span
          v-if="errors.first('project_title')"
          class="valid-feedback alert-danger"
        >
          {{ errors.first("project_title") }}
        </span>
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont" htmlFor="technologyList"
          >Technologies Used
        </label>
        <multiselect
          name="techUsed"
          :searchable="false"
          :multiple="true"
          :options="skillSet"
          :close-on-select="false"
          data-vv-as="Technologies Used"
          v-model="newProject.techUsed"
          v-validate="'required'"
          :class="{ 'is-invalid': errors.first('techUsed') }"
        ></multiselect>
        <span
          v-if="errors.first('techUsed')"
          class="valid-feedback alert-danger"
        >
          {{ errors.first("techUsed") }}
        </span>
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont" htmlFor="description"
          >Description
        </label>
        <textarea
          name="project_description"
          v-validate="'required|min:20|max:200'"
          :class="{ 'is-invalid': errors.first('project_description') }"
          required
          class="form-control"
          v-model="newProject.description"
        ></textarea>
        <span
          v-if="errors.first('project_description')"
          class="valid-feedback alert-danger"
        >
          {{ errors.first("project_description") }}
        </span>
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="modalfont" htmlFor="demoLink">Demo Link</label>
        <input
          v-validate="{ max: 100, url: { require_protocol: true } }"
          type="text"
          name="demo_link"
          data-vv-as="Demo Link"
          class="form-control"
          v-model="newProject.demoLink"
        />
        <span
          v-if="errors.first('demo_link')"
          class="valid-feedback alert-danger"
          >{{ errors.first("demo_link") }}</span
        >
      </div>
      <div class="d-flex">
        <div class="col-6 col-sm-6 pr-2 mb-4">
          <label class="required modalfont" htmlFor="startDate"
            >Start Date
          </label>
          <input
            type="date"
            name="project_start_date"
            ref="project_start_date"
            placeholder="eg: Argusoft India"
            v-validate="'required'"
            :class="{ invalid: errors.first('project_start_date') }"
            class="form-control"
            data-vv-as="Start Date"
            v-model="newProject.startDate"
          />
          <span
            v-if="errors.first('project_start_date')"
            class="valid-feedback-sm alert-danger"
            >{{ errors.first("project_start_date") }}</span
          >
        </div>
        <div class="col-6 col-sm-6 pr-2 mb-4">
          <label class="required modalfont" htmlFor="endDate ">End Date </label>
          <input
            type="date"
            name="project_end_date"
            placeholder="eg: Argusoft India"
            v-validate="'required|after:project_start_date'"
            :class="{ invalid: errors.first('project_end_date') }"
            class="form-control"
            data-vv-as="End Date"
            v-model="newProject.endDate"
          />
          <div
            v-if="errors.first('project_end_date')"
            class="valid-feedback-sm alert-danger"
          >
            {{ errors.first("project_end_date") }}
          </div>
        </div>
      </div>
      <template #modal-footer="{ ok }">
        <b-button size="sm" @click="ok()" class="btn btn-cp ml-2">{{
          project.button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { Validator } from "vee-validate";
import {
  isSameAlternateNo,
  beforeDate,
} from "../../../Common/_plugin/validation";
import DeleteModal from "../delete-modal.vue";
import Multiselect from "vue-multiselect";
import { isValidUrl } from "../../../Common/_utils/sanitization";

Validator.extend("sameAlternateNo", isSameAlternateNo);
Validator.extend("beforeDate", beforeDate);
export default {
  props: {
    applicantData: {
      type: Object,
      default: null,
      required: true,
    },
    skillSet: {
      type: Array,
      required: true,
    },
    isApplicantLoggedIn: {
      type: Boolean,
      required: true,
      default: true,
    },
    isApplicantProfile: {
      type: Boolean,
      default: false,
    },
    isForEditData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newProject: {
        title: "",
        techUsed: [],
        description: "",
        demoLink: "",
        startDate: null,
        endDate: null,
        isEdit: false,
      },
      projectId: null,
      checkedProjects: [],
    };
  },
  computed: {
    project() {
      if (this.newProject.isEdit) {
        return {
          headline: "Edit Project",
          button: "Edit",
        };
      } else {
        return {
          headline: "Add Project",
          button: "Add",
        };
      }
    },
  },
  components: {
    "delete-modal": DeleteModal,
    Multiselect,
  },
  created() {
    if (this.isApplicantLoggedIn && !this.isApplicantProfile) {
      this.checkedProjects = this.applicantData.projectDetails.map(
        (project) => project.id
      );
      this.$emit("toggle-checkbox", this.checkedProjects, "project");
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.limitTags();
    window.addEventListener("resize", this.limitTags);
  },
  methods: {
    isValidUrl(url) {
      return url ? isValidUrl(url) : false;
    },
    limitTags() {
      const container = this.$el.querySelector(".skillTagsContainer");

      if (!container) return;
      const tags = container.querySelectorAll(".cardSkillTag");
      let totalWidth = 0;
      let hiddenTags = 0;

      tags.forEach((tag) => {
        totalWidth += tag.offsetWidth;
        if (totalWidth > container.offsetWidth) {
          tag.style.display = "none";
          hiddenTags++;
        } else {
          tag.style.display = "inline-block";
        }
      });

      if (hiddenTags > 0) {
        container.insertAdjacentHTML(
          "beforeend",
          `<span>...(${hiddenTags} more)</span>`
        );
      }
    },
    isChecked(projectId) {
      return this.checkedProjects.includes(projectId);
    },
    handleCheckboxChange(projectId) {
      if (this.checkedProjects.includes(projectId)) {
        const idx = this.checkedProjects.indexOf(projectId);
        this.checkedProjects.splice(idx, 1);
      } else {
        this.checkedProjects.push(projectId);
      }

      this.$emit("toggle-checkbox", this.checkedProjects, "project");
    },
    setProjectId(projectId) {
      this.projectId = projectId;
    },

    addProject() {
      this.newProject = {
        title: "",
        techUsed: [],
        description: "",
        demoLink: "",
        startDate: null,
        endDate: null,
        isEdit: false,
      };
    },
    async submitProject() {
      await this.$validator.validateAll();
      if (this.$validator.errors.items.length > 0) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Please enter valid values!",
        });
        return;
      }

      if (this.isApplicantLoggedIn) {
        const action = this.newProject.isEdit
          ? "updateUserProject"
          : "postUserProject";
        this.$store.dispatch(`$_applicant_master/${action}`, this.newProject);
      } else {
        const temp = { ...this.applicantData };
        if (this.newProject.isEdit) {
          temp.projectDetails[this.projectId] = this.newProject;
        } else {
          temp.projectDetails.push(this.newProject);
        }
        this.$emit("update:applicantData", temp);
      }

      this.$bvModal.hide("add-project-modal");
    },
    removeProject() {
      if (this.projectId !== null) {
        if (this.isApplicantLoggedIn) {
          this.$store.dispatch(
            "$_applicant_master/deleteUserProject",
            this.projectId
          );
        } else {
          const temp = { ...this.applicantData };
          temp.projectDetails.splice(this.projectId, 1);
          this.$emit("update:applicantData", temp);
          this.projectId = null;
        }
      }
    },
    editProject(index) {
      this.newProject = { ...this.applicantData.projectDetails[index] };
      this.newProject.isEdit = true;
      this.projectId = index;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.limitTags);
  },
};
</script>

<style scoped>
@import "../tabStyles.css";
.skillTagsContainer {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
}

.cardSkillTag {
  background-color: #f7f8fa;
  color: #566474;
  font-size: 1rem;
  border-radius: 20px;
  padding: 4px 12px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 5px;
}
</style>
