<template>
  <div class="content-div">
    <div style="flex-grow: 1; display: flex; flex-direction: column">
      <router-view></router-view>
    </div>
    <!-- FOOTER-BG-->
    <div v-if="!isPlainRoute" class="footer-bg">
      <div class="footer-overlay">
        <div class="container">
          <div class="d-flex flex-wrap">
            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <h5 class="subheading">
                Navigation <span class="line-sm"></span>
              </h5>
              <div class="d-flex flex-wrap">
                <div class="col no-padding">
                  <ul class="footer-navigation">
                    <li><a href="http://www.argusoft.com/#home">Home</a></li>
                    <li>
                      <a href="http://www.argusoft.com/#services">Services</a>
                    </li>
                    <li>
                      <a href="http://www.argusoft.com/#portfolio">Platforms</a>
                    </li>
                    <li>
                      <a href="http://www.argusoft.com/#team">TechStack</a>
                    </li>
                    <li>
                      <a href="http://www.argusoft.com/#approach">Approach</a>
                    </li>
                    <li>
                      <a href="http://www.argusoft.com/#collaterals"
                        >Collaterals</a
                      >
                    </li>
                  </ul>
                </div>

                <div class="col no-padding">
                  <ul class="footer-navigation">
                    <li>
                      <a href="http://www.argusoft.com/#blog">About us</a>
                    </li>
                    <li>
                      <a href="http://www.argusoft.com/#clients">Clients</a>
                    </li>
                    <li>
                      <a href="http://www.argusoft.com/#awards">Awards</a>
                    </li>
                    <li>
                      <a href="http://www.argusoft.com/#argonauts">Argonauts</a>
                    </li>
                    <li>
                      <a href="http://www.argusoft.com/#careers">Careers</a>
                    </li>
                    <li>
                      <a href="http://www.argusoft.com/#contacts-mobile"
                        >Contact us</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <h5 class="subheading">
                India Office & Development Center <span class="line-sm"></span>
              </h5>
              <ul class="footer-navigation" style="margin-left: 24px">
                <li>
                  <i
                    class="fa fa-map-marker footer-icon"
                    aria-hidden="true"
                  ></i>
                  ArguSoft India Ltd.
                </li>
                <li>A 66, GIDC Sector - 25</li>
                <li>Gandhinagar - 382016</li>
                <li>Gujarat, India</li>
                <li>
                  <i class="fa fa-phone footer-icon" aria-hidden="true"></i>
                  91-79-23287 029 &nbsp; &nbsp;
                  <i
                    class="fa fa-envelope-o"
                    aria-hidden="true"
                    style="color: #903564"
                  ></i>
                  &nbsp;
                  <a href="mailto:info@argusoft.com" style="color: #fff"
                    >info@argusoft.com</a
                  >
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-6 col-12">
              <h5 class="subheading">
                US Office <span class="line-sm"></span>
              </h5>
              <ul class="footer-navigation" style="margin-left: 24px">
                <li>
                  <i
                    class="fa fa-map-marker footer-icon"
                    aria-hidden="true"
                  ></i>
                  ArguSoft America Inc.
                </li>
                <li>35463 Dumbarton Ct.,</li>
                <li>Newark, CA 94560</li>
                <li>USA</li>
                <li>
                  <i class="fa fa-phone footer-icon" aria-hidden="true"></i>
                  +1-510-435-0567 &nbsp; &nbsp;
                  <i
                    class="fa fa-envelope-o"
                    aria-hidden="true"
                    style="color: #903564"
                  ></i>
                  &nbsp;
                  <a href="mailto:info@argusoft.com" style="color: #fff"
                    >info@argusoft.com</a
                  >
                </li>
              </ul>

              <div class="col-xs-12 no-padding top-spacer-20">
                <h5 class="subheading">
                  Follow Us <span class="line-sm"></span>
                </h5>

                <ul class="dt-sc-social-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/argusoft"
                      target="_blank"
                      rel="noopener"
                    >
                      <span class="hexagon">
                        <span class="corner1"></span>
                        <span class="corner2"></span>
                        <i class="fa fa-facebook"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/argusoft"
                      target="_blank"
                      rel="noopener"
                    >
                      <span class="hexagon">
                        <span class="corner1"></span>
                        <span class="corner2"></span>
                        <i class="fa fa-linkedin"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FOOTER-BG-END-->
  </div>
</template>

<script>
import { addApplicantMasterModule } from "../ApplicantMaster/_utils/actions";
import store from "./_store";
import axios from "axios";

/**
 * @module Home
 * @description home module
 */
export default {
  data() {
    return {
      plainRoutes: [
        "login",
        "applicantRegistration",
        "applicantForgotPasswordRequest",
        "applicantForgotPassword",
        "recruiterResetPassword",
        "resetRecruiterPassword",
      ],
    };
  },
  methods: {
    logout() {
      axios.get("/logout").then(() => {
        this.$cookie.delete("user");
        this.$cookie.delete("connect.sid");
        localStorage.clear();
        this.$router.push({ name: "home" });
        this.$router.go();
      });
    },
  },
  computed: {
    isPlainRoute() {
      return this.plainRoutes.includes(this.$route.name);
    },
  },
  created() {
    const STORE_KEY = "$_home";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }

    addApplicantMasterModule();
  },
};
</script>

<style scoped>
.content-div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
h1 {
  font-size: 48px;
  color: #2e3941;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  h1 {
    font-size: 32px;
  }
}

.text-size-22 {
  font-size: 22px;
}
@media screen and (max-width: 991px) {
  .text-size-22 {
    font-size: 18px;
  }
}

.top-spacer-50 {
  margin-top: 50px;
}
.top-spacer-40 {
  margin-top: 40px;
}
.top-spacer-30 {
  margin-top: 30px;
}
.top-spacer-20 {
  margin-top: 20px;
}
.top-spacer-10 {
  margin-top: 10px;
}
.bottom-spacer-20 {
  margin-bottom: 20px;
}
.no-padding {
  padding: 0px;
}
.bottom-spacer-0 {
  margin-bottom: 0px;
}

/*FOOTER-CSS*/
.footer-bg {
  background-image: url(../../assets/images/footer-banner.jpg);
  margin-top: 130px;
}
.footer-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 60px 30px 60px 30px;
}

.subheading {
  font-size: 18px;
  color: #903564;
  border-bottom: 1px solid #4c4c4c;
  padding-bottom: 8px;
  font-weight: bold;
  position: relative;
}
.line-sm {
  width: 20px;
  height: 1px;
  position: absolute;
  background: #903564;
  display: block;
  margin: 8px 0px -1px 0px;
}

.footer-navigation {
  margin: 0px;
  padding: 0px;
}

.footer-navigation li {
  list-style-type: none;
  line-height: 30px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  position: relative;
}
.footer-navigation a {
  color: #fff;
}
.footer-icon {
  color: #903564 !important;
  position: absolute;
  left: -26px;
  top: 7px;
}

/*FOOTER-CSS-END*/

.dt-sc-social-icons {
  float: left;
  display: block;
  margin: 0px;
  margin-bottom: 0px;
  list-style-type: none;
  padding: 0px;
  margin-top: 20px;
}

.dt-sc-social-icons li {
  float: left;
  display: block;
  margin-right: 10px;
}

.dt-sc-social-icons .hexagon {
  width: 27px;
  height: 16px;
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  margin: 10px 0px;
}

.hexagon {
  background: #903564;
  position: relative;
  float: left;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dt-sc-social-icons li:hover .hexagon,
.dt-sc-social-icons li:hover .corner1,
.dt-sc-social-icons li:hover .corner2 {
  background-color: #ffffff;
  -webkit-transition: all 300ms linear 0s;
  -moz-transition: all 300ms linear 0s;
  -o-transition: all 300ms linear 0s;
  -ms-transition: all 300ms linear 0s;
  transition: all 300ms linear 0s;
}
.hexagon .corner1,
.hexagon2 .corner1 {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  -o-transform: rotate(60deg);
  transform: rotate(60deg);
}

.hexagon .corner2,
.hexagon2 .corner2 {
  -webkit-transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -o-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.hexagon .corner1,
.hexagon .corner2,
.hexagon2 .corner1,
.hexagon2 .corner2 {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background: inherit;
  left: 0;
  top: 0;
}

.dt-sc-social-icons li .hexagon .fa {
  font: normal 16px FontAwesome, sans-serif;
  line-height: 16px;
  width: 100%;
  position: absolute;
  display: block;
  margin: 0px;
  text-align: center;
  color: #000000;
}

.dt-sc-contact-info .fa {
  font-size: 15px;
  font-family: FontAwesome, sans-serif;
  font-weight: normal;
  float: left;
  margin: 5px 10px 10px 0px;
}

/*NEW-CSS-End*/
</style>

<style scoped>
.container {
  padding: 10px;
  margin-bottom: 15px;
  display: table;
}
</style>
