/**
 * @memberof module:SharedToken.store.getters
 * @param {Object} state
 * @description get all shared tokens
 */
const getAllSharedTokens = (state) => state.sharedTokens;

/**
 * @memberof module:SharedToken.store.getters
 * @param {Object} state
 * @description get endpoint object
 */
const getEndpointObject = (state) => state.endpointObject;

/**
 * @memberof module:SharedToken
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  getAllSharedTokens,
  getEndpointObject,
};
