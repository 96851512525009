<template>
  <div>
    <div class="well section-head w-auto">
      <div class="row mx-0">
        <div class="col-md-2 col-md-offset-5">
          <h4>
            <strong>Comments</strong>
          </h4>
        </div>
      </div>
    </div>
    <div class="p-0" id="comments">
      <div class="row mx-0">
        <div class="col-md-12">
          <div class="well">
            <div class="p-1 p-md-3">
              <div class="media" style="border-top: none; padding-top: 10px">
                <a class="pull-left">
                  <img
                    class="img-responsive"
                    :src="
                      !!loggedInUser.profile_pic
                        ? loggedInUser.profile_pic
                        : avatar
                    "
                    alt="user"
                  />
                </a>
                <div class="w-100">
                  <common-rich-text-box
                    class="form-control"
                    modalId="parents-comments"
                    placeholder="Your comments"
                    @onKeyUp="onKeyUp"
                    @onBlur="onBlur"
                    :width="'100%'"
                    :height="'80'"
                    :minHeight="'auto'"
                    :haveMentions="true"
                    ref="commonRichTextBox"
                  />
                  <div class="form-group float-right m-t10">
                    <label
                      v-if="
                        hasAccess({
                          permissions: [BASE_PERMISSION['ADMIN-COMMENT']],
                        })
                      "
                    >
                      <input
                        type="checkbox"
                        class="control-label"
                        v-model="adminCheckbox"
                      />
                      <span class="ml-1">Only for Admins</span>
                    </label>
                    <button
                      type="button"
                      class="btn btn-cp ml-2"
                      @click="submitComment"
                      :disabled="!isComplete"
                    >
                      <i class="fa fa-paper-plane px-0" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <template>
              <div class="px-3" v-for="item in commentList" :key="item.id">
                <thread-comments :item="item"></thread-comments>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userProfile from "../../../assets/images/icon.png";
import { mapGetters } from "vuex";
import moment from "moment";
import commonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
import threadComments from "./thread-comments.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  components: { threadComments, commonRichTextBox },
  /**
   * @memberof module:Home.components.comments_section
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */

  data() {
    return {
      avatar: userProfile,
      commentText: "",
      adminCheckbox: false,
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      commentList: "$_applicant_profile/getCommentListByApplicantId",
      id: "$_applicant_profile/fetchID",
      loggedInUser: "user/loggedInUser",
    }),
    isComplete() {
      return this.commentText != "";
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    submitComment() {
      let commentInfo = {};
      commentInfo.comment = this.commentText;
      commentInfo.created_by = this.loggedInUser.user_id;
      commentInfo.userName = this.loggedInUser.name;
      commentInfo.profile_pic = this.loggedInUser.profile_pic;
      commentInfo.applicantId = this.id;
      commentInfo.showAdmin = this.adminCheckbox;
      this.$store.dispatch("$_applicant_profile/addComment", commentInfo);
      this.commentText = "";
      this.$refs.commonRichTextBox.resetSummerNote();
      this.adminCheckbox = false;
    },
    /**
     * set values of comments
     */
    onKeyUp(value) {
      this.commentText = value;
    },
    /**
     * set values of comments
     */
    onBlur(value) {
      this.commentText = value;
    },
    getComments(value) {
      this.$store.dispatch(
        "$_applicant_profile/getCommentListByApplicantId",
        value
      );
    },
  },

  watch: {
    id(value) {
      this.getComments(value);
    },
    commentList(value) {
      if (value) {
        value.forEach((info) => {
          info.date = moment(info.updatedAt).format(" MMMM Do YYYY, h:mm a");
        });
      }
    },
  },
};
</script>
<style scoped>
.img-responsive {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  margin-top: 2px;
}

.media {
  border-top: 1px solid #dddddd;
  padding-top: 15px;
}

.media > .pull-left {
  margin-right: 20px;
}

.padding-left-0 {
  padding-left: 0px;
}

h4 {
  padding-top: 5px;
}

.container {
  padding-bottom: 15px;
  margin-bottom: 15px;
}

@media (max-width: 576px) {
  .well {
    border-radius: 0px;
    padding: 5px;
  }
}
</style>
