<template>
  <div>
    <a v-if="params.data != undefined && params.data.hasDuplicate.length != 0"
      ><i
        data-toggle="tooltip"
        data-placement="right"
        :title="
          'Conflicts found for ' +
          params.data.first_name +
          ' ' +
          params.data.last_name +
          '.(unable to create rounds)'
        "
        class="fa fa-link"
        aria-hidden="true"
        style="color: red"
      ></i
    ></a>
    <a
      v-if="
        params.data != undefined &&
        params.data.hasDuplicate.length == 0 &&
        params.data.status == 'Duplicate'
      "
      ><i
        data-toggle="tooltip"
        data-placement="right"
        title="Duplicate applicant.(unable to create rounds)"
        class="fa fa-files-o"
        aria-hidden="true"
        style="color: red"
      ></i
    ></a>
  </div>
</template>

<script>
/**
 * @memberof module:CampusRecruitment
 * @namespace components.resolve_duplicate_link
 * @description Resolve Duplicate component
 */
export default {
  data() {
    return {};
  },
  computed: {
    resolve() {
      if (this.params.data && this.params.data.hasDuplicate) {
        const isDuplicateIDS = this.params.data.hasDuplicate.map((user) => {
          return user.isDuplicate.campusRecruitmentId;
        });
        const sessionList = this.params.context.componentParent.sessionList;
        let exist = false;

        for (let session of sessionList) {
          if (isDuplicateIDS.includes(session.id)) {
            exist = true;
            break;
          }
        }
        return exist;
      } else {
        return false;
      }
    },
  },
};
</script>
