<template>
  <div
    class="content container-fluid page-bg nopadding"
    :class="{ 'small-height': emailSent }"
  >
    <div class="forgotbox">
      <div
        class="p-2"
        style="display: flex; flex-direction: column; min-height: 100%"
      >
        <div>
          <h3 class="header pt-1 mb-0">Reset Recruiter Password</h3>
          <div class="logo-div">
            <img
              class="logoSize"
              src="../../../assets/images/newLogo.png"
              alt="Argusoft Logo"
            />
          </div>
          <form @submit.prevent="submit">
            <div class="form_page_inner">
              <div v-if="!emailSent" class="col-12 mb-2">
                <label class="control-label required">Email </label>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  v-validate="'required|email'"
                  :class="{ 'is-invalid': errors.first('email') }"
                  class="form-control"
                  v-model="data.email"
                />
                <span
                  v-if="errors.first('email')"
                  class="valid-feedback alert-danger"
                  >{{ errors.first("email") }}</span
                >
                <div class="mt-2 text-secondary">
                  The reset password link will be sent to this email.
                </div>
              </div>
              <div v-else class="col-12 mb-2">
                <p class="text-success" style="font-size: 1.12rem">
                  A password reset link has been sent to your email.
                </p>
              </div>
              <div class="col-12 text-left mb-2">
                <router-link to="/login?as=recruiter">Go to Login</router-link>
              </div>
            </div>

            <div v-if="!emailSent" class="text-center mt-4">
              <button
                style="width: 150px"
                type="submit"
                class="btn btn-cp my-2 ml-0 rounded-pill"
                :disabled="!this.isDisabled"
              >
                Send
              </button>
            </div>
          </form>
        </div>
        <div class="font-weight-bold mx-3">
          <h3 class="p-0 my-2"></h3>
          <router-link :to="'/'">
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            <span style="padding: 4px">Homepage</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        email: "",
      },
      emailSent: false,
    };
  },
  methods: {
    async submit() {
      this.$store
        .dispatch("$_recruiter/recruiterResetPasswordRequest", this.data)
        .then((response) => {
          if (response.status == 200) {
            this.emailSent = true;
          }
        })
        .catch(() => {});
    },
  },
  computed: {
    isDisabled() {
      return this.data.email && this.$validator.errors.items.length === 0;
    },
  },
};
</script>

<style scoped>
.custom-btn {
  padding: 10px 50px 10px 50px;
  border-radius: 25px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.header {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
}

.logo-div {
  padding-top: 30px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-around;
}

.forgotbox {
  margin: auto;
  max-width: 420px;
  width: 100%;
  height: 415px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 2px 8px #5b5558;
  border-radius: 25px;
}
.forgotbox h5 {
  letter-spacing: 0.5px;
  display: block;
  border-radius: 30px;
  padding: 8px 20px;
  border: 1px solid #8f1452;
}

.forgotbox h5:hover {
  background-color: #8f1452;
  color: white;
  cursor: pointer;
}

.forgotbox h5 img {
  width: 30px;
  height: 30px;
}

.forgotbox h5 a {
  padding-left: 20px;
}

.page-bg {
  /* height: 100vh; */
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .forgotbox {
    width: 90%;
    height: 440px;
  }
}

h3 {
  text-align: center;
  border-bottom: 1px solid #efe6e6;
  padding-bottom: 15px;
}

.content {
  margin: auto !important;
}
::v-deep #maincontent {
  margin-top: 0px !important;
}

.text-secondary {
  color: #6c757d;
  font-size: 14px;
  margin-top: 2px;
}
.small-height .forgotbox {
  height: fit-content;
}
</style>
