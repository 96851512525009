<template>
  <div>
    <div class="logo-div">
      <img
        class="logoSize"
        src="../../../assets/images/newLogo.png"
        alt="logo"
      />
    </div>
    <form class="content-form m-0 p-0" @submit.prevent="submit">
      <div class="">
        <div class="col-12 form-group">
          <label class="required control-label" htmlFor="email">Email </label>
          <input
            type="text"
            class="form-control"
            v-model="data.email"
            name="email"
            v-validate="'required|email'"
            :class="{ 'is-invalid': errors.first('email') }"
          />
          <span
            v-if="errors.first('email')"
            class="valid-feedback alert-danger"
            >{{ errors.first("email") }}</span
          >
        </div>
        <div class="col-12 form-group">
          <label class="required control-label" htmlFor="password"
            >Password
          </label>
          <input
            :type="passwordFieldType"
            name="password"
            class="form-control"
            v-model="data.password"
            v-validate="'required|min:8|max:20|customPassword'"
          />
          <button
            type="button"
            class="eye-button"
            @click="togglePasswordVisibility"
          >
            <i
              :class="
                passwordFieldType === 'password'
                  ? 'fa fa-eye'
                  : 'fa fa-eye-slash'
              "
            ></i>
          </button>
          <span
            v-if="errors.first('password')"
            class="valid-feedback alert-danger"
            >{{ errors.first("password") }}</span
          >
        </div>
      </div>
      <div class="px-3 mb-2 d-flex justify-content-end">
        <router-link :to="{ name: 'recruiterResetPassword' }"
          >Reset Password?</router-link
        >
      </div>

      <div class="form-footer text-center">
        <button
          style="width: 150px"
          type="submit"
          class="btn btn-cp my-2 rounded-pill"
          :disabled="!isDisabled"
        >
          Login
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import VueCookie from "vue-cookie";
import api from "../_api/index";

export default {
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
      passwordVisible: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    async submit() {
      const data = {
        email: this.data.email,
        accountPassword: this.data.password,
      };
      api
        .loginUser(data)
        .then((response) => {
          if (response.data.token) {
            VueCookie.set("RECRUITER_ACCESS_TOKEN", response.data.token);
            localStorage.setItem("RECRUITER_ACCESS_TOKEN", response.data.token);
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "success",
                message: "Logged in successfully!",
              },
              { root: true }
            );
            this.$router.push("/recruiter/dashboard");
          } else {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: response.data.message,
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: error.response.data.message,
            },
            { root: true }
          );
        });
    },
    passwordValidator(value) {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~\-\\|`]).{8,}$/.test(
        value
      );
    },
  },

  computed: {
    passwordFieldType() {
      return this.passwordVisible ? "text" : "password";
    },
    isDisabled() {
      return (
        this.data.email &&
        this.data.password &&
        this.$validator.errors.items.length === 0
      );
    },
  },
  created() {
    this.$validator.extend("customPassword", {
      getMessage: () =>
        `It requires an uppercase, lowercase, number and special character.`,
      validate: (value) => this.passwordValidator(value),
    });
  },
};
</script>

<style scoped>
.logo-div {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
}

h3 {
  text-align: center;
  border-bottom: 1px solid #efe6e6;
  padding-bottom: 15px;
}

.custom-btn {
  padding: 10px 50px 10px 50px;
  border-radius: 25px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.eye-button {
  position: absolute;
  right: 20px;
  top: 76%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  z-index: 9999;
}

.eye-button i {
  font-size: 1.2rem;
  color: #6c757d;
}

.eye-button:hover i {
  color: #495057;
}

.eye-button:focus {
  outline: none;
}
</style>
