<template>
  <BaseModal
    id="addEventRoundScore"
    :size="modal.size == 'large' ? 'lg' : 'md'"
    :title="
      modal.title +
      (modal.type == 'rounds' ? (passRoundModal ? ' - Pass' : ' - Fail') : '')
    "
    @onClose="resetValidations($v)"
    :closeButton="true"
  >
    <div v-if="modal.type == 'rounds'">
      <h4>
        <strong>{{ data.round_name }}</strong>
      </h4>
      <div v-if="data && data.round_name == 'Interview'">
        <template v-if="data.interviewers && data.interviewers.length !== 0">
          <h5>
            <strong>Reviews</strong>
          </h5>
          <table
            aria-describedby="interviewerReview"
            class="table table-condensed"
          >
            <thead>
              <th id="Interviewer">Interviewer</th>
              <th id="Review">Review</th>
            </thead>
            <tbody
              v-for="(interviewer, index2) in data.interviewers"
              :key="index2"
            >
              <tr>
                <td class="col-md-4">
                  {{ interviewer.interviewer_name }}
                  <br />
                  <button
                    :disabled="data.round_result !== 'Pending'"
                    class="btn btn-default btn-sm"
                    style="margin-top: 6px"
                    v-if="loggedInUser.user_id == interviewer.interviewer_id"
                    @click="
                      addReview(
                        interviewer.id,
                        interviewer.interviewer_remarks,
                        data.id,
                        interviewer.interviewer_id,
                        data.round_name,
                        interviewer.formMasterId,
                        interviewer.formVersionId
                      )
                    "
                  >
                    <i class="fa fa-edit fa-lg" aria-hidden="true"></i>Review
                  </button>
                </td>
                <td
                  class="col-md-9"
                  v-if="
                    interviewer.formMasterId && interviewer.interviewer_remarks
                  "
                >
                  <table class="w-100">
                    <caption style="display: none">
                      this table shows the interviewer and his remarks.
                    </caption>
                    <tbody>
                      <template
                        v-if="
                          JSON.parse(interviewer.interviewer_remarks)['model']
                        "
                      >
                        <tr
                          v-for="(value, property) in JSON.parse(
                            interviewer.interviewer_remarks
                          )['label']"
                          :key="property"
                        >
                          <th class="border-0 p-1 m-0">{{ property }}</th>
                          <td class="border-0 p-1 m-0">{{ value }}</td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr
                          v-for="(value, property) in JSON.parse(
                            interviewer.interviewer_remarks
                          )"
                          :key="property"
                        >
                          <th class="border-0 p-1 m-0">{{ property }}</th>
                          <td class="border-0 p-1 m-0">{{ value }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </td>
                <safe-content
                  v-else
                  className="col-md-9"
                  :content="interviewer.interviewer_remarks"
                  tag="td"
                ></safe-content>
              </tr>
            </tbody>
          </table>
        </template>
      </div>
      <form class="content-form">
        <div class="form-group">
          <label class="col-sm-3 control-label" htmlFor="roundType"
            >Round Type</label
          >
          <div class="col-sm-9">
            <input
              type="text"
              class="form-control"
              v-model="data.round_name"
              readonly
            />
          </div>
        </div>
        <div v-if="data.round_name == 'Written Test'">
          <div class="form-group">
            <label class="col-sm-3 control-label" htmlFor="writtenScore"
              >Score</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="data.score"
                @blur="$v.data.score.$touch()"
              />
              <p
                class="alert alert-danger"
                v-if="!$v.data.score.required && $v.data.score.$error"
              >
                This field is required
              </p>
              <p
                class="alert alert-danger"
                v-if="
                  !$v.data.score.numeric &&
                  $v.data.score.$error &&
                  $v.data.score.required
                "
              >
                Only Digits
              </p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-3 control-label" htmlFor="fullMarks"
              >Out Of</label
            >
            <div class="col-sm-9">
              <input
                type="text"
                class="form-control"
                v-model="data.outOf"
                @blur="$v.data.outOf.$touch()"
              />
              <p
                class="alert alert-danger"
                v-if="!$v.data.outOf.required && $v.data.outOf.$error"
              >
                This field is required
              </p>
              <p
                class="alert alert-danger"
                v-if="
                  !$v.data.outOf.numeric &&
                  $v.data.outOf.$error &&
                  $v.data.outOf.required
                "
              >
                Only Digits
              </p>
            </div>
          </div>
        </div>
        <div class="form-group" v-else>
          <label class="col-sm-3 control-label" htmlFor="grade">Grade</label>
          <div class="col-sm-9">
            <select
              class="form-control"
              v-model="data.grade"
              @blur="$v.data.grade.$touch()"
            >
              <option>Excellent</option>
              <option>Very Good</option>
              <option>Good</option>
              <option>Moderate</option>
              <option>Lacking</option>
              <option>Weak</option>
            </select>
            <p
              class="alert alert-danger"
              v-if="!$v.data.grade.required && $v.data.grade.$error"
            >
              This field is required
            </p>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-3 control-label" htmlFor="comments"
            >Comments</label
          >
          <div class="w-100">
            <common-rich-text-box
              :modalId="'add-score-modal-summernoteSlot'"
              :updateCode="data.comments"
              @onKeyUp="onKeyUp()"
              @onBlur="onBlur()"
            />
            <p
              class="alert alert-danger"
              v-if="checkCommentLengthNotValid(data.comments)"
            >
              Comments must not exceed 5000 characters
            </p>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-9">
            <div class="d-flex justify-content-between align-items-center">
              <label class="control-label" htmlFor="mailTemplate"
                >Select Mail Template</label
              >
            </div>
            <select
              class="custom-select main college mr-2"
              v-model="mail_template"
            >
              <option disabled value="">Select Mail Template</option>
              <option
                v-for="(mailTemplate, index) in filteredMailTemplates"
                :value="mailTemplate.id"
                :key="index"
              >
                {{ mailTemplate.name }}
              </option>
            </select>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-cp btn-sm m-1"
          :disabled="data.isMailed"
          @click="sendMail()"
        >
          <em class="fa fa-envelope"></em>&nbsp;
          <template v-if="!data.isMailed"> Send Mail </template>
          <template v-if="data.isMailed"> Mail Sent </template>
        </button>
        <button
          class="btn btn-cp btn-sm m-1"
          :disabled="submitDisable"
          @click="interviewResult(data, passRoundModal ? 'Passed' : 'Failed')"
        >
          <i class="fa fa-book fa-lg" aria-hidden="true"></i>Submit
        </button>
      </div>
    </div>
    <div v-if="modal.type == 'formRender'">
      <form-render
        :applicantView="false"
        :interviewData="interviewData"
        :callback="backToRound"
        :showFooter="true"
        :handleClose="handleFormClose"
      ></form-render>
    </div>
    <div v-if="modal.type == 'addReview'">
      <add-interview-review
        :applicantView="false"
        :interviewData="interviewData"
        :callback="backToRound"
        :showFooter="true"
        :handleClose="handleFormClose"
      >
      </add-interview-review>
    </div>
  </BaseModal>
</template>

<script>
import api from "../../CampusRecruitment/_api/index";
import { required, numeric } from "vuelidate/lib/validators";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
import { mapGetters } from "vuex";
import AddInterviewReview from "../../ApplicantProfile/_components/add-interview-review.vue";
import FormRender from "../../ApplicantProfile/_components/form-render.vue";
import BaseModal from "../../Common/_components/base-modal.vue";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_score_modal
 * @description Add score modal component
 */
export default {
  components: {
    CommonRichTextBox,
    AddInterviewReview,
    FormRender,
    BaseModal,
  },
  props: [
    "roundData",
    "roundDataAction",
    "setIterviewData",
    "interviewData",
    "refreshPage",
  ],
  data() {
    return {
      passRoundModal: true,
      mail_template: null,
      modalTypes: {
        rounds: {
          size: "medium",
          type: "rounds",
          title: "Round Result",
        },
        formRender: {
          size: "large",
          type: "formRender",
          title: "Add Review",
        },
        addReview: {
          size: "large",
          type: "addReview",
          title: "Add Review",
        },
      },
      modal: {
        size: "medium",
        type: "rounds",
        title: "Round Result",
      },
      data: {
        round_name: null,
        round_schedule: null,
        isMailed: false,
        round_result: null,
        interviewers_id: [],
        applicantIterationMasterId: null,
        score: null,
        grade: null,
        comments: null,
        outOf: null,
      },
      isAptitude: false,
      isReview: false,
      isCommentLengthNotValid: false,
    };
  },
  validations: {
    data: {
      score: {
        required,
        numeric,
      },
      outOf: {
        required,
        numeric,
      },
      grade: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      mailTemplates: "$_mailbox/getTemplates",
      loggedInUser: "user/loggedInUser",
    }),
    selectedSlotId() {
      if (this.roundData) {
        return this.roundData.slotsMasterId;
      }
      return null;
    },
    filteredMailTemplates() {
      if (this.mailTemplates) {
        return this.mailTemplates.filter((mail) => {
          return mail.key == "Application Status";
        });
      } else {
        return [];
      }
    },
    submitDisable() {
      if (!this.roundData || !this.mail_template) return true;
      if (this.roundData.round_name === "Written Test")
        return !this.data.score || !this.data.outOf;
      return !this.data.grade;
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantProfile.components.add_score_modal
     * @param {Object} value
     * @description Marks if the round is aptitude
     */
    roundData(value) {
      this.data = {
        ...value,
        interviewers: value.interviewers
          ? value.interviewers.map((element) => ({
              ...element,
              interviewer_id: element.user_id,
              interviewer_email: element.email_address,
              interviewer_name: element.first_name + " " + element.last_name,
            }))
          : value.interviewers,
      };
      if (this.data.round_name == "Aptitude") {
        this.isAptitude = true;
      }
      if (this.data.round_name == "Review") {
        this.isReview = true;
      }
      $("#add-score-modal-summernote").summernote("code", this.data.comments);
    },
  },
  methods: {
    handleFormClose() {
      this.changeModal("rounds");
    },
    setPassRoundModal(value) {
      this.passRoundModal = value;
    },
    getAllMailTemplates() {
      this.$store.dispatch("$_mailbox/getTemplates");
    },
    backToRound() {
      this.changeModal("rounds");
      this.refreshPage(() => {
        this.changeModal("rounds");
      });
    },
    changeModal(type) {
      if (this.modalTypes[type]) {
        this.modal = this.modalTypes[type];
      }
    },
    addReview(
      id,
      remarks,
      round_id,
      interviewer_id,
      round_name,
      formMasterId,
      formVersionId
    ) {
      this.setIterviewData({
        id: id,
        interviewer_remarks: remarks,
        round_id: round_id,
        interviewer_id: interviewer_id,
        round_name: round_name,
        formMasterId: formMasterId,
        formVersionId: formVersionId,
      });
      if (formMasterId) {
        this.changeModal("formRender");
      } else {
        this.changeModal("addReview");
      }
    },
    /**
     * @memberOf module:ApplicantProfile.components.round_info
     * @param {number} index
     * @description Pass and fail  round of applicant
     */
    interviewResult(round, action) {
      let isRemark = false;
      if (round.round_name == "Written Test") {
        if (round.round_result == "Pending") {
          this.addScore(
            { ...round, mail_template: this.mail_template },
            action
          );
        }
      } else if (round.round_name == "Interview") {
        if (round.round_result == "Pending") {
          if (round.interviewers) {
            isRemark = round.interviewers.some((data) => {
              return data.interviewer_remarks;
            });
            if (!isRemark && round.round_name === "Interview") {
              this.$alert("Atleast 1 review is required");
              return;
            } else {
              this.addScore(
                { ...round, mail_template: this.mail_template },
                action
              );
            }
          }
        }
      }
    },

    checkCommentLengthNotValid(comments) {
      return comments ? comments.length > 500 : false;
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @param {Object} v
     * @description Resets validations on the page
     */
    resetValidations(v) {
      v.$reset();
      $("#add-score-modal-summernote").summernote("code", "");
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description set the tempData
     */
    setTempData(round, action) {
      return {
        mail_template: round.mail_template,
        id: round.id,
        round_result: action,
        round_name: round.round_name,
        grade: round.grade,
        score: round.score,
        outOf: round.outOf,
        comments: round.comments,
        applicantIterationMasterId: round.applicantIterationMasterId,
      };
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description Add score for a round
     */
    addScore(round, action) {
      let tempData = this.setTempData(round, action);
      if (
        ["Passed", "Failed", "Cancelled", "Processed", "Differed"].includes(
          action
        )
      ) {
        api
          .updateRoundFromSlot(tempData)
          .then(() => {
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "success",
                message: "Round status has been succesfully changed.",
              },
              {
                root: true,
              }
            );
            $("#addEventRoundScore").modal("hide");
            this.refreshPage();
          })
          .catch(() => {
            this.refreshPage();
            $("#addEventRoundScore").modal("hide");
            this.$store.dispatch(
              "openSnackbar",
              {
                type: "danger",
                message: "Problem passing round! Try again later",
              },
              {
                root: true,
              }
            );
          });
      }
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description set values of comments
     */
    onKeyUp() {
      this.data.comments = $("#add-score-modal-summernoteSlot").summernote(
        "code"
      );
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description set values of comments
     */
    onBlur() {
      this.data.comments = $("#add-score-modal-summernoteSlot").summernote(
        "code"
      );
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * checks length for the comments
     */
    isCommentLengthValid(comments) {
      this.isCommentLengthNotValid = comments.length > 5000;
    },
  },
  mounted() {
    this.getAllMailTemplates();
    $("#addEventRoundScore").on("shown.bs.modal", () => {
      this.changeModal("rounds");
    });
    $("#addEventRoundScore").on("hidden.bs.modal", () => {
      this.changeModal("rounds");
    });
  },
};
</script>
