<template>
  <div class="main-app">
    <applicant-header-section
      v-if="loggedInApplicant"
    ></applicant-header-section>
    <applicant-sidebar-section
      v-if="loggedInApplicant"
    ></applicant-sidebar-section>
    <div id="maincontent">
      <MandatoryDetails v-if="loggedInApplicant" />
      <router-view></router-view>
    </div>
    <footer-section></footer-section>
  </div>
</template>

<script>
/**
 * @module ApplicantMaster
 */

/**
 * @memberOf module:ApplicantMaster
 * @namespace components
 * @description components of Applicant Master
 */

import applicantHeaderSection from "./_components/applicant-header-section.vue";
import applicantSidebarSection from "./_components/applicant-sidebar-section.vue";
import FooterSection from "../Common/_components/footer-section";
import MandatoryDetails from "./_components/mandatory-details.vue";

import store from "./_store";
import homeStore from "../Home/_store";
import { mapGetters } from "vuex";

export default {
  components: {
    applicantHeaderSection,
    applicantSidebarSection,
    FooterSection,
    MandatoryDetails,
  },
  computed: {
    ...mapGetters({
      loggedInApplicant: "$_applicant_master/getLoggedInApplicant",
    }),
  },
  created() {
    const STORE_KEY = "$_applicant_master";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }

    const HOME_KEY = "$_home";
    if (!(HOME_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(HOME_KEY, homeStore);
    }
  },
};
</script>

<style scoped>
#maincontent {
  transition: all 0.4s ease 0s;
  white-space: nowrap;
  flex-grow: 1;
}
</style>
