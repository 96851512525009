import store from "../../../store/index";
import applicantMasterStore from "../_store/index";
import homeStore from "../../Home/_store/index";
import api from "../_api/index";

export const addApplicantMasterModule = () => {
  const STORE_KEY = "$_applicant_master";
  if (!(STORE_KEY in store._modules.root._children)) {
    store.registerModule(STORE_KEY, applicantMasterStore);
  }

  const HOME_KEY = "$_home";
  if (!(HOME_KEY in store._modules.root._children)) {
    store.registerModule(HOME_KEY, homeStore);
  }
};

export const setLoggedInApplicant = async () => {
  try {
    const applicantData = await api.getApplicantProfile();
    store.dispatch(
      "$_applicant_master/setLoggedInApplicant",
      applicantData.data
    );
  } catch (error) {
    store.dispatch("$_applicant_master/setLoggedInApplicant", null);
    throw error;
  }
};
