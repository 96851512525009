import axios from "axios";

const fetchCategoriesWithJobs = () => {
  return axios.get("/jobs/jobopening");
};
const getReportByJobs = (data) => {
  return axios.post("/report/applicant/job-application-report", data);
};
const getRoundStats = (data) => {
  return axios.post("/report/applicant/by-jobs-stats", data);
};
const getDetailedReport = (data) => {
  return axios.post("/report/applicant/by-jobs-data", data);
};
export default {
  fetchCategoriesWithJobs,
  getReportByJobs,
  getDetailedReport,
  getRoundStats,
};
