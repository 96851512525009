/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of Job Categories and Jobs to be get
 * @description commits data of Job categories and Jobs
 */
const fetchCategoriesWithJobs = (state, data) => {
  state.categoriesWithJobs = data;
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of open positions
 * @description commits data of open positions
 */
const fetchPositionsWithJob = (state, data) => {
  state.positionsWithJob = data;
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} job data of Job to be post
 * @description commits data of Job
 */
const addJob = (state, job) => {
  const categoryIndex = state.categoriesWithJobs.findIndex(
    (c) => c.id === job.categoryId
  );
  state.categoriesWithJobs[categoryIndex].jobs.push(job);
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} category data of Job Category to be post
 * @description commits data of Job category
 */
const addCategory = (state, category) => {
  category.jobs = [];
  state.categoriesWithJobs.push(category);
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} categoryData data of Job Category to be get for add Job
 * @description commits data of Job
 */
const ADD_JOB_MODAL_OPENED = (state, categoryData) => {
  state.categoryDataForAddJobModal = categoryData;
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} itemDeleteData data of Job Category and Jobs to be deleted
 * @description commits data of Job category and Jobs
 */
const DELETE_ITEM_MODAL = (state, itemDeleteData) => {
  state.itemDeleteData = itemDeleteData;
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} categoryId data of Job Category to be deleted
 * @description commits data of Job category
 */
const DELETE_CATEGORY = (state, categoryId) => {
  const index = state.categoriesWithJobs.findIndex(
    (category) => category.id === categoryId
  );
  state.categoriesWithJobs.splice(index, 1);
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} deleteJobData data of Job Job to be deleted
 * @description commits data of Job
 */
const DELETE_JOB = (state, deleteJobData) => {
  let categoryId = deleteJobData.categoryId;
  let jobId = deleteJobData.jobId;

  const categoryIndex = state.categoriesWithJobs.findIndex(
    (category) => category.id === categoryId
  );

  const jobIndex = state.categoriesWithJobs[categoryIndex].jobs.findIndex(
    (job) => job.id === jobId
  );

  state.categoriesWithJobs[categoryIndex].jobs.splice(jobIndex, 1);
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editJobData data of Job to edit
 * @description commits data of Job to be set in edit Job Modal
 */
const EDIT_JOB_MODAL = (state, editJobData) => {
  state.jobDataForEditJobModal = editJobData;
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} job data of Job
 * @description commits job id to the open positions modal
 */
const OPEN_POSITIONS_MODAL = (state, jobData) => {
  state.jobDataForEditJobModal = jobData;
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editJobData data of Job to be edited
 * @description commits data of Job to be edited
 */
const EDIT_JOB = (state, { prevCatId, objectInfo }) => {
  const categoryIndex = state.categoriesWithJobs.findIndex(
    (category) => category.id === objectInfo.categoryId
  );
  const jobIndex = state.categoriesWithJobs[categoryIndex].jobs.findIndex(
    (job) => job.id === objectInfo.id
  );
  if (jobIndex > -1) {
    state.categoriesWithJobs[categoryIndex].jobs[jobIndex].job_name =
      objectInfo.job_name;
    state.categoriesWithJobs[categoryIndex].jobs[jobIndex].job_description =
      objectInfo.job_description;
    state.categoriesWithJobs[categoryIndex].jobs[jobIndex].required_skills =
      objectInfo.required_skills;
    state.categoriesWithJobs[categoryIndex].jobs[
      jobIndex
    ].required_qualifications = objectInfo.required_qualifications;
    state.categoriesWithJobs[categoryIndex].jobs[
      jobIndex
    ].required_experience_min = objectInfo.required_experience_min;
    state.categoriesWithJobs[categoryIndex].jobs[
      jobIndex
    ].required_experience_max = objectInfo.required_experience_max;
    state.categoriesWithJobs[categoryIndex].jobs[jobIndex].status =
      objectInfo.status;
  } else {
    const prevCategoryIndex = state.categoriesWithJobs.findIndex(
      (category) => category.id === prevCatId
    );
    const prevJobIndex = state.categoriesWithJobs[
      prevCategoryIndex
    ].jobs.findIndex((job) => job.id === objectInfo.id);
    state.categoriesWithJobs[prevCategoryIndex].jobs.splice(prevJobIndex, 1);
    state.categoriesWithJobs[categoryIndex].jobs.push(objectInfo);
  }
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editCategoryData data of Job Category
 * @description commits data of Job category to be set in edit Job category modal
 */
const EDIT_CATEGORY_MODAL = (state, editCategoryData) => {
  state.categoryDataForEditCategoryModal = editCategoryData;
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editCategoryData data of Job Category
 * @description commits data of Job category to be edited
 */
const EDIT_CATEGORY = (state, editCategoryData) => {
  const categoryIndex = state.categoriesWithJobs.findIndex(
    (category) => category.id === editCategoryData.id
  );

  state.categoriesWithJobs[categoryIndex].category_name =
    editCategoryData.category_name;
  state.categoriesWithJobs[categoryIndex].category_description =
    editCategoryData.category_description;
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editCategoryData data of Job Category
 * @description commits data of Job category to be edited
 */
const UPDATE_CATEGORY_WITH_JOBS_STATUS = (state, editedCategory) => {
  const categoryIndex = state.categoriesWithJobs.findIndex(
    (category) => category.id === editedCategory.id
  );

  state.categoriesWithJobs[categoryIndex].status = editedCategory.status;
  state.categoriesWithJobs[categoryIndex].jobs.forEach((job) => {
    job.status = state.categoriesWithJobs[categoryIndex].status;
  });
};

/**
 * @memberOf module:ManageJob.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editCategoryData data of Job Category
 * @description commits data of Job category to be edited
 */
const UPDATE_JOB_STATUS = (state, editedJob) => {
  const categoryIndex = state.categoriesWithJobs.findIndex(
    (category) => category.id === editedJob.categoryId
  );
  const jobIndex = state.categoriesWithJobs[categoryIndex].jobs.findIndex(
    (job) => job.id === editedJob.id
  );

  let statusCount = 0;
  state.categoriesWithJobs[categoryIndex].jobs[jobIndex].status =
    editedJob.status;
  state.categoriesWithJobs[categoryIndex].jobs.forEach((job) => {
    if (job.status === editedJob.status) {
      ++statusCount;
    }
  });
  if (statusCount === state.categoriesWithJobs[categoryIndex].jobs.length) {
    state.categoriesWithJobs[categoryIndex].status = editedJob.status;
  } else {
    state.categoriesWithJobs[categoryIndex].status = "active";
  }
};

/**
 * @memberOf module:ManageJob
 * @namespace store.mutations
 * @description mutations for manage job module
 */
export default {
  fetchCategoriesWithJobs,
  fetchPositionsWithJob,
  addJob,
  addCategory,
  ADD_JOB_MODAL_OPENED,
  DELETE_ITEM_MODAL,
  DELETE_CATEGORY,
  DELETE_JOB,
  EDIT_JOB_MODAL,
  OPEN_POSITIONS_MODAL,
  EDIT_JOB,
  EDIT_CATEGORY_MODAL,
  EDIT_CATEGORY,
  UPDATE_CATEGORY_WITH_JOBS_STATUS,
  UPDATE_JOB_STATUS,
};
