<template>
  <div class="content">
    <div class="container-fluid" style="height: inherit">
      <div class="row flex-row-reverse p-0 m-0 pb-3">
        <div class="col-md-6 col-xs-6 py-0 px-0">
          <div class="entity-name-div" v-if="entityListDetails">
            <p class="h5 border m-0 mb-2 py-2 rounded pl-2">
              <b>{{ entityListDetails.entity_type + " - " }}</b>
              {{ entityListDetails.entity_name }}
            </p>
          </div>
        </div>
        <div class="col-md-6 col-xs-6 px-0 pr-md-3">
          <applicant-search-bar
            class="px-0"
            :apiPromise="searchApiPromise"
            :routerLink="searchRouterLink"
          />
          <div class="mt-2"></div>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-md-7 col-xs-7 p-0 pr-md-3">
          <campus-event-modal
            ref="campusModal"
            :selectSessionEvents="selectSessionEvents"
            :selectedEventModal="selectedEventModal"
          />
        </div>
        <div class="col-md-5 col-xs-5 border-left p-0">
          <div class="d-flex justify-content-end mt-4 mt-md-0 flex-wrap">
            <button class="btn btn-cp my-1" @click="refreshData()">
              <i class="fa fa-repeat" aria-hidden="true"></i>
            </button>
            <div
              class="entity-dropdown btn-group dropdown my-1"
              v-if="
                entityListDetails &&
                entityListDetails.entity_type === 'University'
              "
            >
              <button
                class="w-100 btn dropdown-toggle dropdown-toggle-entity btn-cp ml-2"
                type="button"
                data-toggle="dropdown"
                id="dropdownMenuButton2"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="dropdown-toggle-entity-label">{{
                  "College - " + selectedCollege
                    ? selectedCollege.entity_name
                    : "Select College"
                }}</span>
              </button>
              <div>
                <ul
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton2"
                >
                  <li
                    class="dropdown-item"
                    v-for="(entity, index) in entityListDetails.childEntities"
                    v-on:click="selectEntity(entity)"
                    :key="index"
                    :class="{
                      'dropdown-selected':
                        selectedCollege && entity.id === selectedCollege.id,
                    }"
                  >
                    {{ entity.entity_name }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="entity-dropdown btn-group dropdown my-1">
              <button
                class="w-100 btn dropdown-toggle dropdown-toggle-entity btn-cp ml-2"
                type="button"
                data-toggle="dropdown"
                id="dropdownMenuButton"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="dropdown-toggle-entity-label">{{
                  selectedSession
                    ? "Session - " + selectedSession.recruitment_session_name
                    : "Select Session"
                }}</span>
              </button>
              <div>
                <ul
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li
                    class="dropdown-item"
                    :class="{
                      'dropdown-selected': selectedSession.id === session.id,
                      'active-session':
                        session.status === 'inProgress' &&
                        !checkLastDateOver(session),
                      'inactive-session':
                        session.status !== 'inProgress' ||
                        checkLastDateOver(session),
                    }"
                    v-for="(
                      session, index
                    ) in selectedCollege.campusRecruitments"
                    v-on:click="selectSession(session)"
                    :key="index"
                  >
                    {{ session.recruitment_session_name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="my-1">
              <button class="btn btn-cp btn-filter mx-1" @click="filterCards">
                <em class="fa fa-filter px-0"></em>
                <span
                  v-if="!isFilterOptionsEmpty()"
                  class="filter-indicator"
                ></span>
              </button>
            </div>
          </div>
          <common-chart
            chartId="status-chart"
            chartTitle="Status-wise"
            :colorIdx="0"
            :dataSlices="statusCountSlices"
            v-on:filter-data="filterApplicationStatus($event)"
          >
            <div class="filter-text" v-if="applicationStatus">
              <span v-if="applicationStatus"
                ><em class="fa fa-filter"></em> Application Status :
                {{ applicationStatus }}</span
              ><br />
            </div>
          </common-chart>
        </div>
      </div>
      <div
        class="d-flex pb-2 pt-3 justify-content-between"
        style="flex-wrap: wrap"
      >
        <div class="d-flex" style="flex-wrap: wrap">
          <div class="btn-group dropup my-1">
            <button
              class="btn dropdown-toggle btn-cp mr-1"
              type="button"
              data-toggle="dropdown"
              id="dropdownMenuButtonli"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-tasks" aria-hidden="true"></i>
            </button>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonli">
              <li
                class="dropdown-header"
                v-if="
                  selectedSession.status === 'inProgress' && !_isLastDateOver
                "
              >
                Add Applicants
              </li>
              <li
                class="dropdown-item"
                v-if="
                  selectedSession.status === 'inProgress' && !_isLastDateOver
                "
                @click="copyURL"
              >
                Copy URL
              </li>
              <li
                class="divider"
                v-if="
                  selectedSession.status === 'inProgress' && !_isLastDateOver
                "
              ></li>
              <!-- <li class="dropdown-header">Download</li> -->
              <li class="dropdown-item" @click="getFormatFile">
                Download Format
              </li>
              <li class="dropdown-item" @click="downloadData">
                Download Student Data
              </li>
            </ul>
            <upload-applicants
              ref="uploadapplicants"
              @refreshData="refreshData"
              :selectedSession="selectedSession"
              v-if="selectedSession.status === 'inProgress'"
            >
            </upload-applicants>
          </div>
          <button class="btn btn-cp mx-1 my-1" @click="openUploadApplicant">
            Upload Applicants
          </button>

          <div v-if="selectedSession" class="my-1">
            <div class="entity-dropdown">
              <select
                id="eventPicker"
                class="select selectpicker bt-select"
                data-live-search="true"
                data-width="100%"
                v-model="selectedEvent"
                :data-dropdown-align-left="true"
              >
                <option :value="null">All Event</option>
                <option
                  :value="event.id"
                  v-for="(event, index) in events"
                  :key="index"
                >
                  {{ `${event.round_title}` }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          class="d-flex ml-auto"
          style="flex-wrap: wrap; justify-content: flex-end"
          v-if="selectedSession"
        >
          <p
            class="alert alert-info alert-sm mx-1 my-1"
            v-if="selectedApplicants.length > 0"
          >
            You have selected {{ selectedApplicants.length }} records.
          </p>
          <button
            class="btn btn-cp mx-1 my-1"
            @click="openDeleteModal"
            v-if="
              totalVisibleRows > 0 &&
              selectedApplicants.length > 0 &&
              selectedSession.status == 'inProgress' &&
              !_isLastDateOver
            "
          >
            Delete
          </button>
          <button
            class="btn btn-cp mx-1 my-1"
            v-if="totalVisibleRows > 0 && selectedApplicants.length == 0"
            @click="selectAllOnCurrentPage"
          >
            <i class="fa fa-square" aria-hidden="true"></i> Select all
          </button>
          <button
            class="btn btn-cp mx-1 my-1"
            v-else-if="
              totalVisibleRows > 0 &&
              selectedApplicants.length > 0 &&
              selectedApplicants.length < maxApplicantCountToCurrentPage &&
              selectedApplicants.length < totalVisibleRows
            "
            @click="selectAllOnCurrentPage"
          >
            <i class="fa fa-minus-square" aria-hidden="true"></i>Select all
          </button>
          <button
            class="btn btn-cp mx-1 my-1"
            v-else-if="totalVisibleRows > 0"
            @click="deselectAllOnCurrentPage"
          >
            <i class="fa fa-check-square" aria-hidden="true"></i> Deselect all
          </button>
          <div class="ml-1 my-1">
            <select
              @change="onPageSizeChanged($event)"
              class="form-control select-cp"
            >
              <option class="pagesizeoption" value="50">50</option>
              <option class="pagesizeoption" value="100" selected="">
                100
              </option>
              <option class="pagesizeoption" value="200">200</option>
              <option class="pagesizeoption" value="500">500</option>
              <option class="pagesizeoption" value="1000">1000</option>
              <option class="pagesizeoption" value="2000">2000</option>
            </select>
          </div>
        </div>
      </div>
      <ag-grid-vue
        v-if="selectedSession"
        class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
        style="height: 70vh"
        :enableBrowserTooltips="true"
        :gridOptions="gridOptions"
        @selection-changed="onSelectionChanged"
        rowSelection="multiple"
        @filter-changed="onFilterChanged"
        @sort-changed="onSortChanged"
        @gridReady="onGridReady"
        :key="paginationSize"
      >
      </ag-grid-vue>
      <div v-else class="m-3">
        <div class="jumbotron text-center">
          <p class="lead">No session is availabe or not selected.</p>
          <hr class="my-4" />
        </div>
      </div>
    </div>
    <b-modal
      id="confirm-delete-modal"
      ref="confirmDeleteModal"
      title="Confirm Delete"
      @ok.prevent="confirmDelete"
    >
      <div class="col-12 pr-sm-2 mb-3">
        <p>Are you sure you want to delete the selected items?</p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()"
          >Cancel</b-button
        >
        <b-button size="sm" @click="ok()" class="btn btn-cp ml-2"
          >Delete</b-button
        >
      </template>
    </b-modal>
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
    <filter-data-modal-rec
      :filterOptions="filterOptions"
      :updateFilterOptions="updateFilterOptions"
    ></filter-data-modal-rec>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import ResolveDuplicateLink from "./resolve-duplicate-link.vue";
import CommonChart from "../../Common/_components/common-highchart.vue";
import ApplicantSearchBar from "../../Common/_components/applicant-search-bar.vue";
import api from "../_api/index";
import { mapGetters } from "vuex";
import { APPLICANTS_DATA_COL_DEFS } from "../_utils/applicant-data-col-def";
import config from "../../../config";
import UploadApplicants from "./upload-applicants.vue";
import moment from "moment";
import CampusEventModal from "./campus-event-modal.vue";
import CommonModal from "../../Common/_components/modal.vue";
import { getFilterColumns } from "@/modules/Common/_plugin/validation";
import FilterDataModalRec from "@/modules/CampusRecruitment/_components/filter-data-modal-rec.vue";
export default {
  data() {
    return {
      totalVisibleRows: null,
      selectedEvent: null,
      events: [],
      config,
      currentDate: moment(),
      columnDefs: APPLICANTS_DATA_COL_DEFS,
      selectedApplicants: [],
      gridOptions: null,
      includeAll: true,
      applicationStatus: null,
      employmentStatus: "P",
      isApplicantView: true,
      selectSessionEvents: null,
      selectedEventModal: null,
      showRowStyles: true,
      maxApplicantCountToCurrentPage: null,
      paginationSize: 100,
      filterOptions: {
        applicationDate: {
          filterType: "",
          fromDate: null,
          toDate: null,
        },
        moreFilters: {
          duplicateType: "",
          campusType: "",
        },
      },
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    "resolve-link": ResolveDuplicateLink,
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data" target='_blank' :to="'/recruiter/applicants/'+params.data.slug"><i title="View Profile" class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "edit-profile-data": {
      template: `<router-link v-if="params.data && params.context.componentParent.canRecruiterEdit(params.data)" target='_blank' :to="'/recruiter/applicants/'+params.data.slug+'/update'"><i title="Edit Applicant Profile" class="fa fa-edit" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },

    ApplicantSearchBar,
    CommonChart,
    UploadApplicants,
    CampusEventModal,
    FilterDataModalRec,
    CommonModal,
  },
  updated() {
    $("#eventPicker").selectpicker("refresh");
  },
  computed: {
    selectedEventDetails() {
      return this.events.find((event) => {
        return event.id == this.selectedEvent;
      });
    },
    _isLastDateOver() {
      if (this.selectedSession) {
        return moment(this.selectedSession.last_date).isBefore(
          this.currentDate
        );
      }
      return true;
    },
    ...mapGetters({
      statusCount: "$_recruiter/applicationStatusCount",
      selectedSession: "$_recruiter/getSelectedSession",
      selectedCollege: "$_recruiter/getSelectedCollege",
      sessionList: "$_recruiter/getSessionList",
      entityListDetails: "$_recruiter/getEntityListDetails",
    }),
    statusCountSlices() {
      return this.statusCount.map((status) => {
        if (parseInt(status.status_count) !== 0) {
          return {
            name: status.status_name,
            y: parseInt(status.status_count),
          };
        }
      });
    },
  },
  watch: {
    async selectedEvent() {
      let instance = await this.gridOptions.api.getColumnFilterInstance(
        "title"
      );
      await instance.setModel({
        filterType: "text",
        type: "equals",
        filter: this.selectedEvent ? this.selectedEventDetails.round_title : "",
      });
      this.gridOptions.api.onFilterChanged();
    },
    selectedSession(selectedSession) {
      if (selectedSession) {
        this.getCampusEvents();
        this.refreshData();
        const filter = "?campusId=" + selectedSession.id;
        api
          .getCampusEvents(filter)
          .then((res) => {
            this.selectSessionEvents = res.data;
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    },
    filterOptions() {
      this.refreshData();
      this.applyDateFilter();
    },
  },
  methods: {
    openDeleteModal() {
      this.$refs.confirmDeleteModal.show();
    },
    confirmDelete() {
      this.deleteSelected();
    },
    getFormatFile() {
      api
        .getFormatFile()
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.headers["content-type"]
              ? res.headers["content-type"]
              : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "freshersStudentSheetFormat";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    canRecruiterEdit(applicantData) {
      return (
        applicantData.belongsToCampusRecruitement.status === "inProgress" &&
        applicantData.application_status == "Received" &&
        applicantData.status == "new" &&
        !this.checkLastDateOver(applicantData.belongsToCampusRecruitement)
      );
    },

    getCampusEvents() {
      const filterURL = "?&campusId=" + this.selectedSession.id;
      api
        .getCampusEvents(filterURL)
        .then((response) => {
          this.events = response.data;
        })
        .catch(() => {});
    },
    campusValid() {
      const currentDate = moment();
      if (moment(this.selectedSession.last_date).isBefore(currentDate)) {
        this.$store.dispatch(
          "openSnackbar",
          { type: "danger", message: "Last Date has been expired!" },
          { root: true }
        );
        return;
      }
      if (this.selectedSession.status != "inProgress") {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "danger",
            message: "Campus status is " + this.selectedSession.status,
          },
          { root: true }
        );
        return;
      }
      return true;
    },

    downloadData() {
      if (this.isAtleastOneSelected()) {
        let columns = this.gridOptions.api.getAllDisplayedColumns();
        columns = columns.filter(function (col) {
          return !col.colDef.display_only;
        });
        let excel_param = {
          columnKeys: columns,
          onlySelected: this.selectedApplicants,
          fileName: "Applicant's Data.csv",
        };
        this.gridOptions.api.exportDataAsCsv(excel_param);
      }
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to set entity/college
     */
    selectEntity(entity) {
      this.$store.dispatch("$_recruiter/setSelectedCollege", entity);
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to run on upload applicant page load
     */
    openUploadApplicant() {
      if (this.campusValid()) {
        this.$refs.uploadapplicants.clearFile();
        this.$refs.uploadapplicants.createdUploadPage();
        $("#upload-applicant-model").modal("show");
      }
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to check if session is valid
     */
    checkLastDateOver(session) {
      const currentDate = moment();
      return moment(session.last_date).isBefore(currentDate);
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to check if event is valid
     */
    checkEventValid(event) {
      const currentDate = moment();
      return (
        moment(event.round_schedule).isBefore(currentDate) &&
        !moment(event.round_schedule_end).isBefore(currentDate)
      );
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to copy selected active session URL
     */
    copyURL() {
      if (this.campusValid()) {
        const url = `${config.BASE_URL}campus-recruitment-form/${this.selectedSession.unique_id}`;
        navigator.clipboard.writeText(url).then(() => {
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Successfully Copied URL !" },
            { root: true }
          );
        });
      }
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to sdelete selected applicants
     */
    deleteSelected() {
      const ids = this.selectedApplicants.map((applicant) => {
        return applicant.id;
      });
      api
        .deleteMultipleApplicants(ids, this.selectedSession.id)
        .then(() => {
          this.$refs.confirmDeleteModal.hide();
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Selected applicants has been deleted!",
            },
            {
              root: true,
            }
          );
          this.refreshData();
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to refresh page data
     */
    refreshData() {
      this.currentDate = moment();
      if (this.selectedSession.id) {
        const entityId = this.selectedCollege.id;
        let filterOption = `?entityId=${entityId}&campusId=${this.selectedSession.id}&includeAll=${this.includeAll}&employmentStatus=G`;
        if (this.filterOptions.applicationDate.fromDate) {
          filterOption += `&fromDate=${this.filterOptions.applicationDate.fromDate}`;
        }
        if (this.filterOptions.applicationDate.toDate) {
          filterOption += `&toDate=${this.filterOptions.applicationDate.toDate}`;
        }
        if (this.filterOptions.moreFilters.duplicateType) {
          filterOption += `&duplicateType=${this.filterOptions.moreFilters.duplicateType}`;
        }
        this.$store.dispatch(
          "$_recruiter/fetchApplicationStatusCount",
          filterOption
        );
      }
      this.gridOptions.api.setFilterModel(null);
      this.$refs.campusModal.clickToday();
      this.refreshGrid();
    },
    async applyDateFilter() {
      if (this.filterOptions.applicationDate.filterType) {
        let fromDate = "";
        let toDate = "";
        if (
          typeof this.filterOptions.applicationDate.fromDate === "object" &&
          this.filterOptions.applicationDate.fromDate != null
        ) {
          fromDate = this.filterOptions.applicationDate.fromDate.toJSON();
          toDate =
            this.filterOptions.applicationDate.toDate || new Date().toJSON();
        } else {
          fromDate = this.filterOptions.applicationDate.fromDate;
          toDate =
            this.filterOptions.applicationDate.toDate || new Date().toJSON();
        }
        fromDate = this.dateFormatter(fromDate);
        toDate = this.dateFormatter(toDate);

        const instance = await this.gridOptions.api.getColumnFilterInstance(
          "createdAt"
        );
        if (instance) {
          const data = await instance.getModel();
          if (data) {
            data.filterType = "date";
            data.type = "inRange";
            data.dateFrom = fromDate;
            data.dateTo = toDate;
            await instance.setModel(data);
          }
        }
      } else {
        const instance = await this.gridOptions.api.getColumnFilterInstance(
          "createdAt"
        );
        if (instance) {
          await instance.setModel(null);
        }
      }
      this.gridOptions.api.onFilterChanged();
    },
    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to set session
     */
    selectSession(session) {
      this.$store.dispatch("$_recruiter/setSelectedSession", session);
    },
    dateFormatter(date) {
      let tempDate = new Date(date);
      tempDate = new Date(
        tempDate.getTime() - tempDate.getTimezoneOffset() * 60 * 1000
      );
      tempDate = JSON.stringify(tempDate);
      tempDate = tempDate.slice(1, -1);
      return tempDate;
    },
    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to set applicant list in data table
     */
    setDataSource() {
      this.selectedApplicants = [];
      let includeAll = this.includeAll;
      if (this.selectedSession.id) {
        const entityId = this.selectedCollege.id;
        let queryParams = {
          entityId: entityId,
          includeAll: includeAll,
          campusId: this.selectedSession.id,
        };
        this._self.filterOptions.moreFilters.duplicateType &&
          (queryParams["duplicateType"] =
            this._self.filterOptions.moreFilters.duplicateType);
        let dataParams = {};
        if (
          this._self.filterOptions.applicationDate.toDate &&
          this._self.filterOptions.applicationDate.fromDate
        ) {
          dataParams["createdAt"] = {
            op: "BETWEEN",
            val: {
              from: this._self.filterOptions.applicationDate.fromDate,
              to: this._self.filterOptions.applicationDate.toDate,
            },
          };
        }
        api.applicantsDataList(dataParams, queryParams).then(
          (data) => {
            this.totalVisibleRows = data.data.length;
            const newData = data.data.map((row) => {
              return { ...row, parentSession: this.selectedSession };
            });
            if (newData.length > 0) {
              this.gridOptions.api.hideOverlay();
            } else {
              this.gridOptions.api.showNoRowsOverlay();
            }
            // this.gridOptions.api.setRowData(data.data);   old method of setting
            this.gridOptions.api.setGridOption("rowData", data.data);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to filter data in grid
     */
    async filterApplicationStatus(event) {
      const applicationStatus = event.point.name;
      const selected = !event.point.selected;
      const instance = await this.gridOptions.api.getColumnFilterInstance(
        "application_status"
      );
      if (instance) {
        if (selected) {
          await instance.setModel({
            filterType: "text",
            type: "equals",
            filter: applicationStatus,
          });
          this.applicationStatus = applicationStatus;
        } else {
          await instance.setModel({
            filterType: "text",
            type: "equals",
            filter: "",
          });
          this.applicationStatus = "";
        }
        this.gridOptions.api.onFilterChanged();
      }
    },
    /**
     * @memberof module:Recruiter.applicants-list
     * @description handle on change in pag size
     */
    onPageSizeChanged(event) {
      const rowCount = Number(event.target.value);
      this.gridOptions.paginationPageSize = rowCount;
      this.gridOptions.cacheBlockSize = rowCount;
      this.paginationSize = rowCount;
      this.setDataSource();
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description handles on refresh grid
     */
    refreshGrid() {
      this.gridOptions.api.paginationGoToFirstPage();
      this.setDataSource();
      this.applicationStatus = null;
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description select all applicant on current page
     */
    selectAllOnCurrentPage() {
      const maxNumbers =
        this.gridOptions.api.paginationGetPageSize() *
        (this.gridOptions.api.paginationGetCurrentPage() + 1);
      this.gridOptions.api.forEachNodeAfterFilter((node, index) => {
        node.setSelected(index < maxNumbers);
      });
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description deselect all selected applicant on current page
     */
    deselectAllOnCurrentPage() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @returns {Boolean}
     * @description Check whether there is atleast one applicant selected to perform any action
     */
    isAtleastOneSelected() {
      if (this.selectedApplicants.length > 0) {
        return true;
      } else {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select atleast one applicant",
          false,
          "danger",
          "modal-m",
          true
        )
          .then(() => {})
          .catch(() => {});
        return false;
      }
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description Called everytime the selected rows get changed in the grid
     */
    onSelectionChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description Called everytime the filter applied in the grid
     */
    onFilterChanged() {
      const rowData = [];
      let totalVisibleRows = 0;
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
      this.gridOptions.api.forEachNodeAfterFilter(() => {
        totalVisibleRows++;
      });
      this.totalVisibleRows = totalVisibleRows;
      this.selectedApplicants = rowData;
      if (this.gridOptions.api.paginationGetRowCount() > 0) {
        this.gridOptions.api.hideOverlay();
      } else {
        this.gridOptions.api.showNoRowsOverlay();
      }
    },

    /**
     * @memberof module:Common.components.registered_applicant_list
     * @description Called everytime the column sorted in the grid
     */
    onSortChanged() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
    },

    /**
     * @memberof module:Recruiter.applicants-list
     * @description method to get data table list on ag grid ready
     */
    onGridReady(params) {
      this.gridOptions.api = params.api;
      this.setDataSource();
    },
    /**
     * @memberof module:Recruiter.applicants-list
     * @description set row style of applicant list table
     */
    getRowStyle(params) {
      if (params && params.data && this.showRowStyles) {
        if (params.data.hasDuplicate && params.data.hasDuplicate.length > 0) {
          return { background: "#fff396" };
        } else if (params.data.status == "Duplicate") {
          return { background: "#fff396" };
        } else if (/Passed/.test(params.data.application_status)) {
          return { background: "#b2ff8f" };
        } else if (/Failed|Differ/.test(params.data.application_status)) {
          return { background: "#f58f82" };
        } else if (/Shortlisted/.test(params.data.application_status)) {
          return { background: "#3788d8" };
        }
      }
    },
    onPaginationChanged() {
      if (this.gridOptions && this.gridOptions.api) {
        this.maxApplicantCountToCurrentPage =
          this.gridOptions.api.paginationGetPageSize() *
          (this.gridOptions.api.paginationGetCurrentPage() + 1);
      }
    },
    searchApiPromise(searchQuery) {
      return api.searchApplicants({ searchQuery });
    },
    searchRouterLink(slug) {
      return "/recruiter/applicants/" + slug;
    },
    filterCards() {
      $("#filter-data-modal-rec").modal("show");
    },
    isFilterOptionsEmpty() {
      // Compare filterOptions with its initialized value
      return (
        JSON.stringify(this.filterOptions) ===
        JSON.stringify({
          applicationDate: {
            filterType: "",
            fromDate: null,
            toDate: null,
          },
          moreFilters: {
            duplicateType: "",
            campusType: "",
          },
        })
      );
    },
    updateFilterOptions(filterOptions) {
      this.filterOptions = {
        applicationDate: { ...filterOptions.applicationDate },
        moreFilters: { ...filterOptions.moreFilters },
      };
    },
  },

  beforeMount() {
    this.gridOptions = {
      rowHeight: 30,
      suppressPropertyNamesCheck: true,
      enableServerSideFilter: true,
      enableCellTextSelection: true,
      rowMultiSelectWithClick: true,
      suppressRowClickSelection: true,
      enableColResize: true,
      rowSelection: "multiple",
      getRowStyle: (event) => this.getRowStyle(event),
      onPaginationChanged: (event) => this.onPaginationChanged(event),
      defaultColDef: {
        flex: 1,
        resizable: true,
        floatingFilter: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        filter: true,
      },
      columnDefs: getFilterColumns(this.columnDefs, 1),
      infiniteInitialRowCount: 0,
      animateRows: true,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      pagination: true,
      paginationPageSize: 100,
      paginationPageSizeSelector: false,
      context: {
        componentParent: this,
      },
    };
  },

  mounted() {
    this.title = "Campus Recruitment Applications";
    this.$store.dispatch("$_recruiter/entityListWithSessions");
  },
};
</script>

<style scoped>
.ag-theme-balham
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
}

.ag-grid-style {
  width: 100%;
  height: 86%;
}

.dashboard-box-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.application-container {
  margin: 20px 40px 20px 20px;
}

.dropdown-menu {
  top: 50px;
}

.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 3px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #803059;
  color: white;
}

.dropdown-menu .dropdown-header {
  padding: 3px 20px;
  color: #823e5b;
  font-size: 16px;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

li.dropdown > a {
  color: white;
}

.active-session {
  font-weight: 700;
}

.inactive-session {
  color: grey;
}

.inactive-session:hover {
  color: white;
}

.entity-dropdown {
  min-width: 200px;
  max-width: 200px;
  display: flex !important;
}

.dropdown-toggle-entity {
  max-width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

.dropdown-toggle-entity-label {
  display: block;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}
.session-dropdown {
  width: 250px;
}

@media screen and (max-width: 418px) {
  .session-dropdown {
    width: 170px;
  }
}
</style>
