export function requiredError(input, validator) {
  if (
    input == "" ||
    input == null ||
    input == undefined ||
    (typeof input === "object" && Array.isArray(input) && input.length === 0)
  ) {
    if (validator.message) {
      return validator.message;
    }
    return "This field is required";
  }
  return null;
}

export function formatError(input, validator) {
  if (input == "" || input == null || input == undefined) {
    return null;
  }
  let valid = true;
  let error = "";
  let pattern;
  switch (validator.format) {
    case "email":
      valid = String(input)
        .toLowerCase()
        .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      error = "Enter a valid email";
      break;
    case "phone":
      valid = String(input)
        .toLowerCase()
        .match(/^\d{10}$/);

      error = "Enter a valid phone (10 digit no space)";
      break;
    case "url":
      pattern = new RegExp(
        "^(https?://)?" +
          "(?:[a-z\\d](?:[a-z\\d-]*[a-z\\d])?\\.)+[a-z]{2,}|" +
          "(?:\\d{1,3}\\.){3}\\d{1,3}" +
          "(?:\\:\\d{1,5})?" +
          "(?:\\/[\\w\\d%_.~+-]*)*" +
          "(?:\\?[;&\\w\\d%_.~+=-]*)?" +
          "(?:#[-\\w\\d_]*)?$",
        "i"
      );
      valid = !!pattern.test(input);
      error = "Enter a valid URL";
      break;
    case "number-float":
      valid = isNaN(input);
      error = "Enter a valid number";
      break;
    case "number-int":
      valid = isNaN(input) && input.toString().indexOf(".") == -1;
      error = "Enter a valid integer";
      break;
    case "alpha":
      valid = String(input)
        .toLowerCase()
        .match(/^[a-zA-Z]*$/);
      error = "Only alphabets allowed";
      break;
    case "alpha-num":
      valid = String(input)
        .toLowerCase()
        .match(/^[a-zA-Z0-9]*$/);
      error = "Only alphabets & digits allowed";
      break;
    case "alpha-space":
      valid = String(input)
        .toLowerCase()
        .match(/^[a-zA-Z\s]*$/);
      error = "Only alphabets & spaces allowed";
      break;
    case "alpha-num-space":
      valid = String(input)
        .toLowerCase()
        .match(/^[a-zA-Z0-9\s]*$/);
      error = "Only alphabets, digits & spaces allowed";
      break;
    case "regex":
      pattern = new RegExp(validator.regex);
      valid = !!pattern.test(input);
      error = "Invalid input";
      break;
  }
  if (valid) {
    return null;
  } else if (validator.message) {
    return validator.message;
  }
  return error;
}

export function lengthError(input, validator) {
  if (input == "" || input == null || input == undefined) {
    return null;
  }
  if (validator.min <= input.length && input.length <= validator.max) {
    return null;
  }
  if (validator.message) {
    return validator.message;
  }
  return `This field should be ${validator.min} to ${validator.max} characters`;
}

// This and the length validator are so similar I dont know what to do
export function tickError(input, validator) {
  if (
    input == "" ||
    input == null ||
    input == undefined ||
    (typeof input === "object" && Array.isArray(input) && input.length === 0)
  ) {
    return null;
  }
  if (validator.min <= input.length && input.length <= validator.max) {
    return null;
  }
  if (validator.message) {
    return validator.message;
  }
  if (validator.min === validator.max) {
    return `Must select exactly ${validator.min} options`;
  }
  return `Must select ${validator.min} to ${validator.max} options`;
}

export default {
  required: requiredError,
  format: formatError,
  length: lengthError,
  tick: tickError,
};
