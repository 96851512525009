<template>
  <router-view></router-view>
</template>

<script>
import store from "./_store";

/**
 * @module FormTemplate
 * @description manage form templates
 */
export default {
  /**
   * @memberof module:FormTemplate
   * @namespace components
   * @description components of form template module
   */
  // components: {},
  created() {
    const STORE_KEY = "$_form_template";

    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>
