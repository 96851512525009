<template>
  <div>
    <loading
      :show="isVisible"
      overlay-class="custom-overlay"
      loader-class="custom-loader-wrapper user-select-none"
    ></loading>
    <loading
      :show="isLoading"
      overlay-class="white-overlay2"
      loader-class="custom-loader-wrapper user-select-none"
    >
      <template v-slot:loading-container>
        <div class="loadingContainer">
          <div style="text-align: center">
            <b-spinner
              style="width: 50px; height: 50px"
              label="Large Spinner"
              type="grow"
            ></b-spinner>
          </div>
          <div>
            <p style="font-weight: 600; text-align: center; padding: 10px">
              {{ loadingMessage }}
            </p>
          </div>
        </div>
      </template>
    </loading>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "vue-full-loading";
export default {
  components: {
    Loading,
  },
  computed: {
    ...mapGetters({
      isVisible: "loading_overlay/visibility",
      isLoading: "loading_overlay/isLoading",
      loadingMessage: "loading_overlay/loadingMessage",
    }),
  },
};
</script>
<style scoped>
.white-overlay2 {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  margin: 0;
}
.custom-overlay {
  background-color: rgba(255, 255, 255, 0);
  z-index: 99999;
  bottom: 0;
  right: 0;
  height: 100px;
  width: 300px;
  position: fixed;
  margin: 0;
}
::v-deep .custom-loader-wrapper {
  position: absolute;
  right: 10px;
  display: inline-block;
  bottom: 20px;
  padding: 8px 50px;
  color: #0c5460;
  background-color: #d1ecf1;
  box-shadow: 5px 5px 14px #888888;
}
.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: auto;
  min-height: 170px;
  background-color: white;
  border: 1px solid #c1c6c791;
  border-radius: 5px;
}
@media (min-width: 576px) {
  .loadingContainer {
    width: 400px;
  }
}
</style>
