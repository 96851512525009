<template>
  <div class="modal fade" id="editJobModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">
            {{ this.isEdit ? "Edit Job" : "Create Job" }}
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ws-normal">
          <form class="content-form" id="editJobForm">
            <div class="form-group position-relative">
              <label class="control-label"
                >Job Name<span class="text-danger"> *</span></label
              >
              <input
                type="text"
                name="jobName"
                class="form-control"
                v-validate="'required|regex:^[^-\s][a-zA-Z ]*$'"
                data-vv-as="Job Name"
                data-vv-validate-on="blur|input"
                v-model="data.job_name"
                maxlength="30"
                :class="{ invalid: errors.first('jobName') }"
              />
              <span
                v-if="errors.first('jobName')"
                class="valid-feedback alert-danger"
                >{{ errors.first("jobName") }}</span
              >
            </div>

            <div class="form-group position-relative">
              <label class="control-label"
                >Job Description<span class="text-danger"> *</span></label
              >
              <common-rich-text-box
                modalId="edit-job-summernote"
                :width="'100%'"
                @onKeyUp="onKeyUp"
                @onBlur="onBlur"
              />
              <span
                v-if="isJobDescTouched && !isJobDescValid"
                class="valid-feedback alert-danger"
                >Job Description is Required</span
              >
            </div>

            <div class="form-group">
              <label class="control-label"
                >Status<span class="text-danger"> *</span></label
              >
              <select class="form-control" v-model="data.status">
                <option v-for="(status, index) in statusTypes" :key="index">
                  {{ status }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label class="control-label"
                >Category<span class="text-danger"> *</span></label
              >
              <select class="form-control" v-model="data.categoryId">
                <option
                  v-for="category in categoriesWithJobs"
                  :key="category.id"
                  :value="category.id"
                  :selected="data.categoryId == category.id"
                >
                  {{ category.category_name }}
                </option>
              </select>
            </div>

            <tags-input
              id="skills"
              label="Required Skills"
              @valuesUpdated="updateSkillsValue($event)"
              :setValues="data.required_skills"
              :finished="isEditJobDone"
            />

            <tags-input
              id="qualifications"
              label="Required Qualification"
              @valuesUpdated="updateQualificationValue($event)"
              :setValues="data.required_qualifications"
              :finished="isEditJobDone"
            />

            <div class="form-group position-relative">
              <label class="control-label"
                >Req Exp Min<span class="text-danger"> *</span></label
              >
              <input
                type="text"
                name="reqExpMin"
                maxlength="2"
                v-validate="'required|numeric'"
                data-vv-as="Required Min Experience"
                data-vv-validate-on="blur|input"
                class="form-control"
                v-model="data.required_experience_min"
                :class="{ invalid: errors.first('reqExpMin') }"
              />
              <span
                v-if="errors.first('reqExpMin')"
                class="valid-feedback alert-danger"
                >{{ errors.first("reqExpMin") }}</span
              >
            </div>

            <div class="form-group position-relative">
              <label class="control-label"
                >Req Exp Max<span class="text-danger"> *</span></label
              >
              <input
                type="text"
                name="reqExpMax"
                maxlength="2"
                v-validate="'required|numeric'"
                data-vv-as="Required Max Experience"
                data-vv-validate-on="blur|input"
                class="form-control"
                v-model="data.required_experience_max"
                :class="{ invalid: errors.first('reqExpMax') }"
              />
              <span
                v-if="errors.first('reqExpMax')"
                class="valid-feedback alert-danger"
                >{{ errors.first("reqExpMax") }}</span
              >
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="$v.data.$invalid"
            type="button"
            class="btn btn-cp ml-2"
            @click="updateJob"
            data-dismiss="modal"
          >
            {{ this.isEdit ? "Update" : "Create" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TagsInput from "../../Common/_components/tags-input-v2";
import { job_status_type } from "../../Common/commonData";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";
import {
  required,
  // maxLength,
  // maxValue,
  // numeric,
} from "vuelidate/lib/validators";

/**
 * @memberof module:ManageJob
 * @namespace components.edit_job_modal
 */
export default {
  components: {
    TagsInput,
    CommonRichTextBox,
  },
  /**
   * @memberof module:ManageJob.components.edit_job_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      postData: {},
      data: {
        id: null,
        job_name: "",
        job_description: "",
        required_skills: [],
        required_experience_min: null,
        required_experience_max: null,
        categoryId: null,
        job_posting_image: null,
        status: "",
        required_qualifications: [],
      },
      isEditJobDone: false,
      isJobDescTouched: false,
      isEdit: false,
      statusTypes: "",
      previousCatId: "",
    };
  },

  /**
   * @memberof module:ManageJob.components.edit_job_modal
   * @returns {Object} validation object
   * @description data - returns validation object for current vue component
   */
  validations() {
    const data = {
      job_name: {
        required,
        jobNameValidator: this.jobNameValidator,
      },
      required_experience_min: {
        required,
        experienceValidator: this.experienceValidator,
      },
      required_experience_max: {
        required,
        experienceValidator: this.experienceValidator,
      },
    };
    return {
      data,
    };
  },
  computed: {
    ...mapGetters({
      jobData: "$_manage_jobs/editJobModal",
      categoriesWithJobs: "$_manage_jobs/categoriesWithJobs",
    }),

    /**
     * @memberof module:ManageJob.components.edit_job_modal
     * @returns {Boolean} validated result
     * @description computed - checks whether Job Description is valid or not
     */
    isJobDescValid() {
      return (
        this.data.job_description !== "<p><br></p>" &&
        this.data.job_description !== ""
      );
    },
  },

  /**
   * @memberof module:ManageJob.components.edit_job_modal
   * @description lifecycle method - handle data when modal closed and about to open
   */
  mounted() {
    $("#editJobModal").on("show.bs.modal", () => {
      this.isEditJobDone = false;
      this.statusTypes = job_status_type;
    });
    $("#editJobModal").on("hidden.bs.modal", () => {
      this.isEditJobDone = true;
      this.$store.dispatch("$_manage_jobs/editJobModal", {});
    });
  },
  watch: {
    /**
     * @memberof module:ManageJob.components.edit_job_modal
     * @param {Object} value new value
     * @description watch - handle Job data
     */
    jobData(value) {
      if (value.jobInfo) {
        this.isEdit = value.isEdit;
        this.data = this.getClone(value.jobInfo);
        this.previousCatId = this.data.categoryId;
        $("#edit-job-summernote").summernote("code", this.data.job_description);
      }
    },
  },
  methods: {
    onKeyUp(value) {
      this.data.job_description = value;
    },

    onBlur(value) {
      this.data.job_description = value;
      this.isJobDescTouched = true;
    },

    /**
     * @memberof module:ManageJob.components.edit_job_modal
     * @description method - set qualifications data
     */
    updateQualificationValue(event) {
      this.data.required_qualifications = event.values;
    },

    /**
     * @memberof module:ManageJob.components.edit_job_modal
     * @description method - set skills data
     */
    updateSkillsValue(event) {
      this.data.required_skills = event.values;
    },

    /**
     * @memberof module:ManageJob.components.edit_job_modal
     * @returns {Object} clone data object
     * @description method - clone data to off reactivity of vue.js
     */
    getClone(value) {
      const to = {};
      if (Object.keys(value).length !== 0) {
        to.job_name = value.job_name;
        to.job_description = value.job_description;
        to.required_skills = JSON.parse(JSON.stringify(value.required_skills));
        to.required_qualifications = JSON.parse(
          JSON.stringify(value.required_qualifications)
        );
        to.required_experience_min = value.required_experience_min;
        to.required_experience_max = value.required_experience_max;
        to.categoryId = value.categoryId;
        to.job_posting_image = value.job_posting_image;
        to.status = value.status;
        if (this.isEdit === true) {
          to.id = value.id;
        }
      }
      return to;
    },

    /**
     * @memberof module:ManageJob.components.edit_job_modal
     * @description method - dispatch request to edit job
     */
    updateJob() {
      this.data.job_description = $("#edit-job-summernote").summernote("code");
      this.isEdit
        ? this.$store.dispatch("$_manage_jobs/editJob", {
            prevCatId: this.previousCatId,
            objectInfo: this.getClone(this.data),
          })
        : this.$store.dispatch(
            "$_manage_jobs/addJob",
            this.getClone(this.data)
          );
    },

    /**
     * @memberof module:ManageJob.components.edit_job_modal
     * @param value new value
     * @returns {Boolean} validated result
     * @description method - checks whether job name is valid or not
     */
    jobNameValidator(value) {
      return /^[^-\s][a-zA-Z ]*$/.test(value);
    },

    /**
     * @memberof module:ManageJob.components.edit_job_modal
     * @param value new value
     * @returns {Boolean} validated result
     * @description method - checks whether experience is valid or not
     */
    experienceValidator(value) {
      return /^\d+(?:\.\d{1,4})?$/.test(value);
    },
  },
  beforeDestroy() {
    $("#editJobModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.ws-normal {
  white-space: normal;
}
</style>
