import api from "../api";
import formCleaner from "../_utils/cleaner";

function fetchEditorFormMaster(context, masterIdOrSlug) {
  api
    .fetchFormById(masterIdOrSlug)
    .then((result) => context.commit("setEditorActiveFormMaster", result.data))
    .catch((err) => {
      console.error(err);
      context.commit("setEditorFormFetchError", true);
    });
}

function fetchEditorFormVersion(context, versionId) {
  api
    .fetchFormVersionById(versionId)
    .then((result) => context.commit("setEditorActiveFormVersion", result.data))
    .catch((err) => {
      console.error(err);
      context.commit("setEditorFormFetchError", true);
    });
}

function fetchEditorFormVersionBySlug(context, slug) {
  api
    .fetchFormVersionByHash(slug)
    .then((result) => {
      context.commit("setEditorActiveFormMaster", result.data.form_master);
      context.commit("setEditorActiveFormVersion", result.data);
    })
    .catch((err) => {
      console.error(err);
      context.commit("setEditorFormFetchError", true);
    });
}

function getAllFormMasters(context) {
  api
    .fetchAllForms()
    .then((result) => context.commit("setAllFormMasters", result.data))
    .catch((err) => console.error(err));
}

function getAllFormVersions(context, masterId) {
  api
    .fetchFormVersionsByFormId(masterId)
    .then((result) => context.commit("setAllFormVersions", result.data))
    .catch((err) => console.error(err));
}

function saveFormMaster(context, data) {
  api
    .editFormTemplate({ data })
    .then(() => context.commit("setFormMaster", data))
    .catch((err) => console.error(err));
}

function deleteFormMaster(context, id) {
  api
    .deleteFormTemplate(id)
    .then(() => context.commit("removeFormMaster", id))
    .catch((err) => console.error(err));
}

function addFormMaster(context, data) {
  api
    .addFormTemplate({ data })
    .then((result) => context.commit("addFormMaster", result.data))
    .catch((err) => console.error(err));
}

function saveFormVersion(context, data) {
  formCleaner(data.form_json);
  api
    .saveFormVersion({ data })
    .then(() => {
      context.commit("setFormSaved", "saved");
    })
    .catch((err) => {
      console.error(err);
      context.commit("setFormSaved", "error");
    });
}

const discardFormVersion = (context, payload) => {
  api.discardFormVersion(payload).then(() => {
    context.commit("removeFormVersion", payload);
  });
};

const copyFormMaster = (context, payload) => {
  api.copyFormMaster(payload.formMasterId, payload.title).then((result) => {
    context.commit("addFormMaster", result.data.formMaster);
  });
};

export default {
  fetchEditorFormMaster,
  fetchEditorFormVersion,
  getAllFormMasters,
  getAllFormVersions,
  saveFormMaster,
  deleteFormMaster,
  addFormMaster,
  saveFormVersion,
  fetchEditorFormVersionBySlug,
  discardFormVersion,
  copyFormMaster,
};
