<template>
  <div
    class="modal fade"
    id="interview-round-fomr-data-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Form Data</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            v-if="
              round && round.interviewers && round.interviewers.length !== 0
            "
          >
            <h5>
              <strong>Reviews</strong>
            </h5>
            <table
              aria-describedby="interviewerReview"
              class="table table-condensed"
            >
              <thead>
                <th id="Interviewer">Interviewer</th>
                <th id="Review">Review</th>
              </thead>
              <tbody
                v-for="(interviewer, index) in round.interviewers"
                :key="index"
              >
                <tr>
                  <td class="col-md-4">
                    <p class="p-1 m-0">
                      {{ interviewer.first_name + " " + interviewer.last_name }}
                    </p>
                  </td>
                  <td
                    class="col-md-9"
                    v-if="
                      interviewer.formMasterId &&
                      interviewer.interviewer_remarks
                    "
                  >
                    <table class="w-100" aria-describedby="Remarks">
                      <tbody>
                        <template
                          v-if="
                            JSON.parse(interviewer.interviewer_remarks)['model']
                          "
                        >
                          <tr
                            v-for="(value, property) in JSON.parse(
                              interviewer.interviewer_remarks
                            )['label']"
                            :key="property"
                          >
                            <th class="border-0 p-1 m-0">{{ property }}</th>
                            <td class="border-0 p-1 m-0">{{ value }}</td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr
                            v-for="(value, property) in JSON.parse(
                              interviewer.interviewer_remarks
                            )"
                            :key="property"
                          >
                            <th class="border-0 p-1 m-0">{{ property }}</th>
                            <td class="border-0 p-1 m-0">{{ value }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </td>

                  <safe-content
                    v-else
                    className="col-md-9"
                    :content="interviewer.interviewer_remarks"
                    tag="td"
                  ></safe-content>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["round"],
};
</script>
