<template>
  <header>
    <div class="container-fluid py-0">
      <div
        class="position-relative d-flex align-items-center justify-content-between"
      >
        <div class="d-flex align-items-center">
          <div class="sidebar">
            <button id="menu-toggle" class="btn btn-basic mr-2">
              <img
                alt="argusoft-menu"
                src="../../../assets/images/argus-menu.png"
              />
            </button>
          </div>
          <div class="logo logo-with-menu">
            <router-link :to="'/recruiter/dashboard'"
              ><img src="../../../assets/images/newLogo.png" alt="logo"
            /></router-link>
          </div>
        </div>
        <div
          v-if="loggedInUser"
          class="header_btn d-flex align-items-center"
          id="header_btn"
        >
          <img class="profile_icon" :src="avatar" alt="" />
          <button
            class="sign-out-btn float-right btn btn-basic p-1"
            @click="recruiterLogout()"
            title="Log out"
          >
            <i class="fa fa-2x fa-sign-out" aria-hidden="true"></i>
          </button>
        </div>
        <div v-if="!loggedInUser" class="header_btn" id="header_btn">
          <button
            class="sign-in-btn float-right btn btn-basic p-1"
            @click="goToLogin()"
            title="Recruiter Login"
          >
            <i class="fa fa-2x fa-sign-in" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import userProfile from "../../../assets/images/avatar.png";
import VueCookie from "vue-cookie";

/**
 * @memberof module:Recruiter
 * @namespace recruitment-header-section
 */
export default {
  data() {
    return {
      avatar: userProfile,
    };
  },
  computed: {
    ...mapGetters({
      recruiterNotification: "$_recruiter/fetchRecruiterNotifications",
      loggedInUser: "$_recruiter/getLoggedInUser",
    }),
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "login", query: { as: "recruiter" } });
    },
    recruiterLogout() {
      VueCookie.delete("RECRUITER_ACCESS_TOKEN");
      localStorage.clear();
      this.$router.push({ name: "home" });
    },
  },
  mounted() {
    $("#menu-toggle").click((e) => {
      e.preventDefault();
      $("#wrapper").toggleClass("active");
      $("#maincontent").toggleClass("sidebar-margin");
    });
    $(".submenu").click((e) => {
      e.stopPropagation();
    });
  },
};
</script>

<style scoped>
.img-responsive {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  margin-top: 2px;
}

.media img {
  max-width: 100px;
}

.sub-text {
  position: absolute;
  margin-top: -15px;
  text-align: right;
  color: "#666666";
  font-size: 14px;
}

.pull-left {
  margin-right: 20px;
}

.sign-in-btn {
  color: #8f1452;
  cursor: pointer;
  margin-left: 50px;
  vertical-align: middle;
  margin-top: 5px;
}

.sign-in-btn:hover {
  color: #6d083b;
}

@media screen and (max-width: 767px) {
  .sign-in-btn {
    margin-top: 20px;
    margin-right: 120px;
  }
}

.sign-out-btn {
  color: #8f1452;
  cursor: pointer;
  margin-left: 20px;
  vertical-align: middle;
}

.sign-out-btn:hover {
  color: #6d083b;
}

.btn__login {
  background: #8f1452;
  color: #fff !important;
  padding: 8px 25px;
  border: 1px solid #903564;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.btn__login i {
  padding-right: 5px;
}

.btn__login:hover {
  background: #6d083b;
}

/*header-css*/
header {
  position: fixed;
  top: 0px;
  background: #fff;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 500;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.2);
}

header .logo {
  width: 170px;
  overflow: hidden;
}

header .logo img {
  width: 90%;
}

header .logo-with-menu {
  width: 120px;
}

@media screen and (max-width: 400px) {
  .logo-with-menu {
    display: none;
  }
}

header .header_btn {
  position: absolute;
  right: 0px;
}

header .profile_icon {
  position: absolute;
  right: 50px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-size: cover;
}

header .notification_icon {
  position: absolute;
  right: 90px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  cursor: pointer;
}

header .notification_icon img {
  height: 25px;
  width: 25px;
}

header .notification_icon .red-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  position: absolute;
  background-color: #9b3f6f;
  font-size: 10px;
  border-radius: 50%;
  color: #fff;
  top: -7px;
  left: 18px;
  text-align: center;
  line-height: 17px;
  cursor: pointer;
}

.notification-list-base {
  margin-top: 60px;
  overflow-y: scroll;
  width: fit-content;
  min-width: 450px;
  position: fixed;
  right: 0px;
}

header .logout {
  cursor: pointer;
}

.dropdown-menu {
  top: 50px;
}

.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 3px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #803059;
  color: white;
}

.dropdown-menu > li > a:hover {
  color: white;
}

/*gpw-logo-css*/
.gpw-logo {
  position: fixed;
  z-index: 9999;
  right: 10px;
  top: 86px;
  width: 86px;
}

@media (max-width: 599px) {
  .gpw-logo {
    top: 86px;
  }
}

.gpw-logo img {
  width: 86px;
}

/*gpw-logo-css*/
</style>
