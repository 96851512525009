<template>
  <div class="content">
    <div class="form_page_wrapper" v-if="applicantData">
      <div class="centered-div form-title">
        <div class="title-top-strip"></div>
        <div>
          <h1>Edit Applicant Form</h1>
          <div class="px-5">
            <h5
              class="text-center"
              :class="{
                'text-danger':
                  isLastDateOver ||
                  isCompleted ||
                  applicantData.status == 'Duplicate' ||
                  applicantData.application_status != 'Received',
              }"
            >
              <div v-if="!isCompleted">
                Last Date to update :
                {{
                  applicantData.belongsToCampusRecruitement.last_date
                    | dateFormatFilter
                }}
              </div>
              <div v-if="applicantData.status == 'Duplicate'">
                Duplicate application!
              </div>
              <div v-if="applicantData.application_status != 'Received'">
                Application has been processed!
              </div>
              <div v-if="isLastDateOver || isCompleted" style="margin-top: 5px">
                Updation is closed!
              </div>
            </h5>
          </div>
        </div>
      </div>
      <div>
        <div>
          <form @submit.prevent="updateApplicantForm" id="rec_form" class="m-4">
            <fieldset
              :disabled="
                isLastDateOver ||
                isCompleted ||
                applicantData.status == 'Duplicate' ||
                applicantData.application_status != 'Received'
              "
            >
              <legend>Applicant Information</legend>
              <div class="card-view">
                <div class="field-container row">
                  <p class="m-0 col-6">
                    <b class="control-label">College Name - </b>
                    {{ applicantData.college_name }}
                  </p>
                  <p class="m-0 col-6">
                    <b class="control-label">Recruitment Session Name - </b>
                    {{
                      applicantData.belongsToCampusRecruitement
                        .recruitment_session_name
                    }}
                  </p>
                </div>
              </div>
              <div class="card-view">
                <div class="field-container">
                  <BaseInput
                    label="Email"
                    v-model="recruitmentData.email"
                    type="text"
                    class="field"
                    placeholder="Enter email"
                    :required="true"
                    :class="{ error: $v.recruitmentData.email.$error }"
                    @blur="$v.recruitmentData.email.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.email.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.email.required"
                        class="validation-err"
                      >
                        Email is required
                      </p>
                      <p
                        v-if="!$v.recruitmentData.email.email"
                        class="validation-err"
                      >
                        Invalid Email
                      </p>
                    </template>
                  </BaseInput>
                </div>
                <div class="field-container">
                  <BaseInput
                    label="Mobile No."
                    v-model="recruitmentData.mobile_no"
                    type="text"
                    class="field"
                    placeholder="Enter Mobile No."
                    :required="true"
                    :class="{ error: $v.recruitmentData.mobile_no.$error }"
                    @blur="$v.recruitmentData.mobile_no.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.mobile_no.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.mobile_no.required"
                        class="validation-err"
                      >
                        Mobile no. is required
                      </p>
                      <p
                        v-if="!$v.recruitmentData.mobile_no.numeric"
                        class="validation-err"
                      >
                        Only digits are allowed
                      </p>
                      <p
                        v-if="
                          $v.recruitmentData.mobile_no.numeric &&
                          (!$v.recruitmentData.mobile_no.minLength ||
                            !$v.recruitmentData.mobile_no.maxLength)
                        "
                        class="validation-err"
                      >
                        Invalid mobile no.
                      </p>
                    </template>
                  </BaseInput>
                </div>
              </div>
              <!-- first.last name -->
              <div class="card-view">
                <div class="row">
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="First Name"
                      v-model="recruitmentData.first_name"
                      type="text"
                      :required="true"
                      class="field"
                      placeholder="Enter First Name"
                      :class="{ error: $v.recruitmentData.first_name.$error }"
                      @blur="$v.recruitmentData.first_name.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.first_name.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.first_name.required"
                          class="validation-err"
                        >
                          First name is required
                        </p>
                        <p
                          v-if="!$v.recruitmentData.first_name.minLength"
                          class="validation-err"
                        >
                          Min 2 char. allowed
                        </p>
                        <p
                          v-if="!$v.recruitmentData.first_name.maxLength"
                          class="validation-err"
                        >
                          Max. 50 char. allowed
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Middle Name"
                      v-model="recruitmentData.middle_name"
                      type="text"
                      class="field"
                      placeholder="Enter Middle Name"
                      :class="{ error: $v.recruitmentData.middle_name.$error }"
                      @blur="$v.recruitmentData.middle_name.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.middle_name.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.middle_name.minLength"
                          class="validation-err"
                        >
                          Min 2 char. allowed
                        </p>
                        <p
                          v-if="!$v.recruitmentData.middle_name.maxLength"
                          class="validation-err"
                        >
                          Max. 50 char. allowed
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Last Name"
                      v-model="recruitmentData.last_name"
                      type="text"
                      class="field"
                      placeholder="Enter Last Name"
                      :required="true"
                      :class="{ error: $v.recruitmentData.last_name.$error }"
                      @blur="$v.recruitmentData.last_name.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.last_name.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.last_name.required"
                          class="validation-err"
                        >
                          Last name is required
                        </p>
                        <p
                          v-if="!$v.recruitmentData.last_name.minLength"
                          class="validation-err"
                        >
                          Min 2 char. allowed
                        </p>
                        <p
                          v-if="!$v.recruitmentData.last_name.maxLength"
                          class="validation-err"
                        >
                          Max. 50 char. allowed
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 field-container">
                    <label class="control-label" htmlFor="gender"
                      >Gender
                      <span class="text-danger">*</span>
                    </label>
                    <div class="radio-options">
                      <div class="radio">
                        <label>
                          <input
                            class="mr-1"
                            type="radio"
                            id="Male"
                            value="Male"
                            v-model="recruitmentData.gender"
                          />Male</label
                        >
                      </div>
                      <div class="radio">
                        <label>
                          <input
                            class="mr-1"
                            type="radio"
                            id="Female"
                            value="Female"
                            v-model="recruitmentData.gender"
                          />Female</label
                        >
                      </div>
                      <div class="radio">
                        <label>
                          <input
                            class="mr-1"
                            type="radio"
                            id="Other"
                            value="Other"
                            v-model="recruitmentData.gender"
                          />Other</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 field-container">
                    <BaseDateInput
                      label="DOB"
                      v-model="recruitmentData.dob"
                      id="dobDatePicker"
                      :minDate="minDob"
                      :maxDate="maxDob"
                      :required="true"
                      class="field"
                      datePickerType="DATE"
                      placeholder="Select Date Of Birth"
                      :class="{ error: $v.recruitmentData.dob.$error }"
                      @dp-hide="$v.recruitmentData.dob.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.dob.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.dob.required"
                          class="validation-err"
                        >
                          Date of birth is required
                        </p>
                      </template>
                    </BaseDateInput>
                  </div>
                </div>
              </div>
              <!-- ssc/hsc -->
              <div class="card-view">
                <div class="row">
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="SSC (%)"
                      v-model="recruitmentData.ssc_percentage"
                      type="number"
                      class="field"
                      step="any"
                      placeholder="Enter SSC Percentage"
                      :required="true"
                      :class="{
                        error: $v.recruitmentData.ssc_percentage.$error,
                      }"
                      @blur="$v.recruitmentData.ssc_percentage.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.ssc_percentage.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.ssc_percentage.required"
                          class="validation-err"
                        >
                          SSC percentage is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.ssc_percentage.minValue ||
                            !$v.recruitmentData.ssc_percentage.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 100
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="HSC (%)"
                      v-model="recruitmentData.hsc_percentage"
                      type="number"
                      class="field"
                      step="any"
                      placeholder="Enter HSC Percentage"
                      :required="true"
                      :class="{
                        error: $v.recruitmentData.hsc_percentage.$error,
                      }"
                      @blur="$v.recruitmentData.hsc_percentage.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.hsc_percentage.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.hsc_percentage.required"
                          class="validation-err"
                        >
                          HSC percentage is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.hsc_percentage.minValue ||
                            !$v.recruitmentData.hsc_percentage.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 100
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Graduation (%)"
                      v-model="recruitmentData.grad_percentage"
                      type="number"
                      class="field"
                      step="any"
                      placeholder="Enter Graduation Percentage"
                      :class="{
                        error: $v.recruitmentData.grad_percentage.$error,
                      }"
                      @blur="$v.recruitmentData.grad_percentage.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.grad_percentage.$error"
                      >
                        <p
                          v-if="
                            !$v.recruitmentData.grad_percentage.minValue ||
                            !$v.recruitmentData.grad_percentage.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 100
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Aggregate (SSC/HSC/Graduation) (%)"
                      v-model="recruitmentData.aggregate_percentage"
                      type="number"
                      class="field"
                      step="any"
                      :required="true"
                      placeholder="Enter Aggregate percentage of SSC/HSC/Graduation"
                      :class="{
                        error: $v.recruitmentData.aggregate_percentage.$error,
                      }"
                      @blur="$v.recruitmentData.aggregate_percentage.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.aggregate_percentage.$error"
                      >
                        <p
                          v-if="
                            !$v.recruitmentData.aggregate_percentage.required
                          "
                          class="validation-err"
                        >
                          aggregate percentage is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.aggregate_percentage.minValue ||
                            !$v.recruitmentData.aggregate_percentage.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 100
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 field-container">
                    <label class="control-label" htmlFor="degree"
                      >Degree
                      <span class="text-danger">*</span>
                    </label>
                    <div class="radio-options">
                      <div class="radio">
                        <label>
                          <input
                            class="mr-1"
                            type="radio"
                            id="active"
                            value="BE"
                            v-model="recruitmentData.degree"
                          />B.E.</label
                        >
                      </div>
                      <div class="radio">
                        <label>
                          <input
                            class="mr-1"
                            type="radio"
                            id="block"
                            value="BTECH"
                            v-model="recruitmentData.degree"
                          />B. Tech</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 field-container">
                    <label class="control-label" htmlFor="Stream"
                      >Stream
                      <span class="text-danger">*</span>
                    </label>
                    <div class="radio-options">
                      <div class="radio">
                        <label>
                          <input
                            class="mr-1"
                            type="radio"
                            id="computer_sci"
                            value="COMPUTER_SCIENCE"
                            v-model="recruitmentData.stream"
                          />Computer Science</label
                        >
                      </div>
                      <div class="radio">
                        <label>
                          <input
                            class="mr-1"
                            type="radio"
                            id="information_tech"
                            value="INFORMATION_TECHNOLOGY"
                            v-model="recruitmentData.stream"
                          />Information Technology</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- semesters -->
              <div class="card-view mb-4">
                <div class="row">
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Semester 1 SPI (Out of 10)"
                      v-model="recruitmentData.semester1"
                      type="number"
                      step="any"
                      class="field"
                      placeholder="Enter Semester 1 SPI"
                      @blur="$v.recruitmentData.semester1.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.semester1.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.semester1.required"
                          class="validation-err"
                        >
                          semester1 is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.semester1.minValue ||
                            !$v.recruitmentData.semester1.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 10
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Semester 2 SPI (Out of 10)"
                      v-model="recruitmentData.semester2"
                      type="number"
                      step="any"
                      class="field"
                      placeholder="Enter Semester 2 SPI"
                      @blur="$v.recruitmentData.semester2.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.semester2.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.semester2.required"
                          class="validation-err"
                        >
                          semester2 is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.semester2.minValue ||
                            !$v.recruitmentData.semester2.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 10
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Semester 3 SPI (Out of 10)"
                      v-model="recruitmentData.semester3"
                      type="number"
                      step="any"
                      class="field"
                      placeholder="Enter Semester 3 SPI"
                      @blur="$v.recruitmentData.semester3.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.semester3.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.semester3.required"
                          class="validation-err"
                        >
                          semester3 is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.semester3.minValue ||
                            !$v.recruitmentData.semester3.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 10
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Semester 4 SPI (Out of 10)"
                      v-model="recruitmentData.semester4"
                      type="number"
                      step="any"
                      class="field"
                      placeholder="Enter Semester 4 SPI"
                      @blur="$v.recruitmentData.semester4.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.semester4.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.semester4.required"
                          class="validation-err"
                        >
                          semester4 is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.semester4.minValue ||
                            !$v.recruitmentData.semester4.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 10
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Semester 5 SPI (Out of 10)"
                      v-model="recruitmentData.semester5"
                      type="number"
                      class="field"
                      step="any"
                      placeholder="Enter Semester 5 SPI"
                      @blur="$v.recruitmentData.semester5.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.semester5.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.semester5.required"
                          class="validation-err"
                        >
                          semester5 is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.semester5.minValue ||
                            !$v.recruitmentData.semester5.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 10
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Semester 6 SPI (Out of 10)"
                      v-model="recruitmentData.semester6"
                      type="number"
                      class="field"
                      step="any"
                      placeholder="Enter Semester 6 SPI"
                      @blur="$v.recruitmentData.semester6.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.semester6.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.semester6.required"
                          class="validation-err"
                        >
                          semester6 is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.semester6.minValue ||
                            !$v.recruitmentData.semester6.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 10
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Semester 7 SPI (Out of 10)"
                      v-model="recruitmentData.semester7"
                      type="number"
                      class="field"
                      step="any"
                      placeholder="Enter Semester 7 SPI"
                      @blur="$v.recruitmentData.semester7.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.semester7.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.semester7.required"
                          class="validation-err"
                        >
                          semester7 is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.semester7.minValue ||
                            !$v.recruitmentData.semester7.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 10
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                  <div class="col-sm-6 field-container">
                    <BaseInput
                      label="Semester 8 SPI (Out of 10)"
                      v-model="recruitmentData.semester8"
                      type="number"
                      class="field"
                      step="any"
                      placeholder="Enter Semester 8 SPI"
                      @blur="$v.recruitmentData.semester8.$touch()"
                    >
                      <template
                        slot="error"
                        v-if="$v.recruitmentData.semester8.$error"
                      >
                        <p
                          v-if="!$v.recruitmentData.semester8.required"
                          class="validation-err"
                        >
                          semester8 is required
                        </p>
                        <p
                          v-if="
                            !$v.recruitmentData.semester8.minValue ||
                            !$v.recruitmentData.semester8.maxValue
                          "
                          class="validation-err"
                        >
                          Value should be between 0 and 10
                        </p>
                      </template>
                    </BaseInput>
                  </div>
                </div>
              </div>
              <div
                class="submit-btn-container"
                v-if="
                  !isLastDateOver &&
                  !isCompleted &&
                  applicantData.status == 'new' &&
                  applicantData.application_status == 'Received'
                "
              >
                <div class="w-100 d-flex justify-content-between">
                  <div>
                    <div v-if="submitError" class="text-danger">
                      <p>{{ errorMessage }}</p>
                    </div>
                  </div>
                  <button
                    :disabled="$v.recruitmentData.$invalid"
                    type="submit"
                    class="btn btn-cp submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
    <div v-else-if="apiCallCompleted">
      <p class="text-danger text-center mt-5">No applicant found!</p>
    </div>
  </div>
</template>

<script>
import BaseInput from "../../Common/_components/base-input";
import BaseDateInput from "../../Common/_components/base-date-input";
import api from "../_api/index";
import "vue-cute-timeline/dist/index.css";

import {
  defaultRecruitmentFormData,
  RECRUITMENT_FORM_DATA_VALIDATIONS,
  DateOfBirthValidations,
} from "../_utils/recruitment-form-const";
import moment from "moment";
export default {
  data() {
    return {
      slugId: null,
      formData: null,
      currentDate: moment(),
      maxDob: DateOfBirthValidations.maxDob,
      minDob: DateOfBirthValidations.minDob,
      recruitmentData: this.getDefaultRecruitmentFormData(),
      applicantData: null,
      submitError: false,
      errorMessage: "",
      apiCallCompleted: false,
    };
  },
  computed: {
    applicantRounds() {
      return this.applicantData.roundData;
    },
    isLastDateOver() {
      return this._isLastDateOver();
    },
    isCompleted() {
      return (
        this.applicantData.belongsToCampusRecruitement.status == "Completed"
      );
    },
  },
  components: {
    BaseInput,
    BaseDateInput,
  },
  created() {
    this.slugId = this.$route.params.slug;
    api
      .getApplicantDetailsBySlug(this.$route.params.slug)
      .then((res) => {
        this.apiCallCompleted = true;
        this.applicantData = res.data;
        const data = {
          email: res.data.email,
          mobile_no: res.data.mobile_no,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          gender: res.data.gender,
          dob: res.data.dob,
          middle_name: res.data.education_details.middle_name,
          ssc_percentage: res.data.education_details.ssc_percentage,
          hsc_percentage: res.data.education_details.hsc_percentage,
          grad_percentage: res.data.education_details.grad_percentage,
          aggregate_percentage: res.data.education_details.aggregate_percentage,
          degree: res.data.education_details.degree,
          stream: res.data.education_details.stream,
          semester1: res.data.education_details.semester1,
          semester2: res.data.education_details.semester2,
          semester3: res.data.education_details.semester3,
          semester4: res.data.education_details.semester4,
          semester5: res.data.education_details.semester5,
          semester6: res.data.education_details.semester6,
          semester7: res.data.education_details.semester7,
          semester8: res.data.education_details.semester8,
        };
        this.recruitmentData = data;
      })
      .catch(() => {
        this.apiCallCompleted = true;
      });
  },
  validations() {
    // eslint-disable-next-line no-unused-vars
    const { entity_id, ...restRequired } = RECRUITMENT_FORM_DATA_VALIDATIONS;
    return {
      recruitmentData: restRequired,
    };
  },
  methods: {
    _isLastDateOver() {
      return moment(
        this.applicantData.belongsToCampusRecruitement.last_date
      ).isBefore(this.currentDate);
    },
    /**
     * @memberof module:Recruiter.edit-recruitment-form
     * @description format date
     */
    formatDate(date) {
      return moment(date).format("MMM Do YYYY, h:mm a");
    },

    getDefaultRecruitmentFormData() {
      // eslint-disable-next-line no-unused-vars
      const { entity_id, ...restRequired } = defaultRecruitmentFormData();
      return restRequired;
    },
    updateApplicantForm() {
      this.submitError = false;
      this.errorMessage = null;
      const data = {
        ...this.recruitmentData,
        id: this.applicantData.id,
        entity_id: this.applicantData.entity_id,
        dob: new Date(this.recruitmentData.dob),
      };
      api
        .updateApplicantData(data)
        .then(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Applicant Updated Successfully!",
            },
            {
              root: true,
            }
          );
        })
        .catch((err) => {
          this.submitError = true;
          this.$alert(
            err.response.data && err.response.data.message
              ? err.response.data.message
              : "Something went wrong"
          );
          this.showSnackbar(
            err.response.data && err.response.data.message
              ? err.response.data.message
              : "Something went wrong"
          );
        });
    },
    /**
     * @memberof module:Recruiter.components.edit-recruitment-form
     * @description Shows snackbar toast
     */
    showSnackbar(type, message) {
      this.$store.dispatch("openSnackbar", {
        type: type,
        message: message,
      });
    },
  },
  filters: {
    dateFormatFilter(date) {
      return moment(date).format(" MMMM Do YYYY, h:mm a");
    },
  },
};
</script>

<style lang="css" scoped>
.form_page_wrapper {
  width: 1140px;
  padding-bottom: 0px;
  margin: 0 auto;
  margin-top: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1366px) {
  .form_page_wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  .form_page_wrapper {
    width: 90%;
  }
}

.form_page_wrapper .form_header {
  font-size: 26px;
  text-align: center;
  font-weight: 600;
  padding-top: 15px;
}

.form_page_wrapper .form_page_inner {
  padding: 10px 40px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
}

.form_page_wrapper .form_page_inner .from_subheading {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  position: relative;
  margin-bottom: 24px;
}

.form_page_wrapper .form_page_inner .from_subheading:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 14px;
  width: calc(100% - 180px);
  height: 1px;
  background: #dbdbdb;
}

.form_page_wrapper .form_page_inner .from_subheading-2:after {
  content: "";
  width: calc(100% - 147px);
}

.form_page_wrapper .file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.form_page_wrapper .file-input__label {
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  height: 38px;
  width: 140px;
  text-align: center;
  background-color: #686868;
}

.form_page_wrapper .file-input__label i {
  font-size: 22px;
  padding-right: 6px;
  position: relative;
  top: 3px;
}

.form_page_wrapper .star {
  color: red;
}

.form_page_wrapper label {
  font-weight: 600;
  font-size: 14px;
}

.form_page_wrapper input:focus {
  box-shadow: none;
}

.form_page_wrapper select:focus {
  box-shadow: none;
}

.form_page_wrapper textarea:focus {
  box-shadow: none;
}

.form_page_wrapper .references_section {
  background: #f5f5f5;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}

.form_page_wrapper .references_section h4 {
  font-size: 20px;
  margin-bottom: 0px;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bottomslide {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes rightslide {
  0% {
    height: 0;
  }

  100% {
    height: 100%;
  }
}

@keyframes spin {
  10% {
    width: 0;
    height: 0;
    border-width: 6px;
  }

  30% {
    width: 0;
    height: 0;
    border-radius: 50%;
    border-width: 1em;
    transform: rotate(0deg);
    border-color: #c7daf5;
  }

  50% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }

  70% {
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }

  90% {
    border-width: 4px;
  }

  100% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    transform: rotate(720deg);
    border-color: transparent;
  }
}

.edit-h1 {
  color: #4d4d4d;
  border-bottom: 1px solid #ebebeb;
  font-size: 26px;
  text-align: center;
  font-weight: 600;
  padding: 20px 30px;
}

.centered-div {
  margin: auto;
}

.form-title {
  position: relative;
  margin-bottom: 12px !important;
  margin-top: 12px !important;
  padding-top: 22px !important;
}

.title-top-strip {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 10px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: calc(100% + 2px);
  background-color: #903564;
  color: rgba(255, 255, 255, 1);
}

.card-view {
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px 24px 14px;
  page-break-inside: avoid;
  word-wrap: break-word;
}

.validation-err {
  color: #a94442;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 0px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.submit-btn-container {
  padding: 15px 0px 15px 0px;
  text-align: right;
}

.field-container {
  margin-bottom: 18px;
}

.radio-options {
  display: flex;
  flex-direction: row;
}

.radio {
  margin: 10px 5px !important;
}

.format-date {
  font-size: 14px;
}
</style>
