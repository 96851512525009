var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('CollapseCard',{staticClass:"theme-default",attrs:{"title":"Hiring Pipeline","midDivider":false,"header-class":"px-0 py-0","collapse":true,"title-class":"h5 m-0"},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center flex-wrap"},[_c('div',{staticClass:"ml-auto"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterOptions.employmentStatus),expression:"filterOptions.employmentStatus"}],staticClass:"form-control form-control-sm",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filterOptions, "employmentStatus", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.onChangeEmploymentStatus]}},[_c('option',{attrs:{"selected":"","value":"P"}},[_vm._v("Professionals")]),_c('option',{attrs:{"selected":"","value":"G"}},[_vm._v("Freshers")])])]),_c('div',{staticClass:"d-flex align-items-center ml-auto pl-2"},[_vm._v(" "+_vm._s(_vm.statusByIndex[_vm.activeTabIndex].type)+" "),_c('div',[_c('span',{staticClass:"bg-secondary px-2 rounded ml-2 text-white"},[_vm._v(_vm._s(_vm.currentCount))])])])])]},proxy:true}])},[_c('div',{staticClass:"px-2 p-2"},[_c('b-tabs',{attrs:{"active-nav-item-class":"font-weight-bold text-uppercase","content-class":"mt-3","justified":""},model:{value:(_vm.activeTabIndex),callback:function ($$v) {_vm.activeTabIndex=$$v},expression:"activeTabIndex"}},[_c('b-tab',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Processed "),_c('span',{class:[
                'px-2 ml-2 rounded text-white',
                _vm.activeTabIndex === 0 ? 'theme-bg-clr' : 'bg-secondary',
              ],staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.hiringPipelineData?.processed)+" ")])]},proxy:true}])}),_c('b-tab',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Ongoing "),_c('span',{class:[
                'px-2 ml-2 rounded text-white',
                _vm.activeTabIndex === 1 ? 'theme-bg-clr' : 'bg-secondary',
              ],staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.hiringPipelineData?.ongoing)+" ")])]},proxy:true}])}),_c('b-tab',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Hold "),_c('span',{class:[
                'px-2 ml-2 rounded text-white',
                _vm.activeTabIndex === 2 ? 'theme-bg-clr' : 'bg-secondary',
              ],staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.hiringPipelineData?.held)+" ")])]},proxy:true}])}),_c('b-tab',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Shortlisted "),_c('span',{class:[
                'px-2 ml-2 rounded text-white',
                _vm.activeTabIndex === 3 ? 'theme-bg-clr' : 'bg-secondary',
              ],staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.hiringPipelineData?.shortlisted)+" ")])]},proxy:true}])}),_c('b-tab',{attrs:{"lazy":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Joined "),_c('span',{class:[
                'px-2 ml-2 rounded text-white',
                _vm.activeTabIndex === 4 ? 'theme-bg-clr' : 'bg-secondary',
              ],staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.hiringPipelineData?.joined)+" ")])]},proxy:true}])})],1),_c('div',[(_vm.activeTabIndex == 4)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterOptions.duration),expression:"filterOptions.duration"}],staticClass:"form-control my-1 width-auto",staticStyle:{"width":"auto"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.filterOptions, "duration", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.onChangeDuration]}},[_c('option',{attrs:{"selected":"","value":"l7d"}},[_vm._v("In last 7 days")]),_c('option',{attrs:{"selected":"","value":"l15d"}},[_vm._v("In last 15 days")]),_c('option',{attrs:{"selected":"","value":"l1m"}},[_vm._v("In last 1 month")]),_c('option',{attrs:{"selected":"","value":"l3m"}},[_vm._v("In last 3 months")]),_c('option',{attrs:{"selected":"","value":"l6m"}},[_vm._v("In last 6 months")]),_c('option',{attrs:{"selected":"","value":"l1y"}},[_vm._v("In last 1 year")])])]):_vm._e()]),_c('div',[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"w-100 d-flex justify-content-between"}),_c('div',{staticStyle:{"width":"100%"}},[_c('ag-grid-vue',{staticClass:"ag-theme-alpine ag-grid-style ag-grid-footer-style",staticStyle:{"height":"75vh"},attrs:{"enableBrowserTooltips":true,"gridOptions":_vm.gridOptions,"getRowStyle":_vm.getRowStyle,"rowData":_vm.rowData,"columnDefs":_vm.columnDefs}})],1)])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }