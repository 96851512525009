import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  categoriesWithJobs: [],
  categoryDataForAddJobModal: null,
  itemDeleteData: null,
  jobDataForEditJobModal: null,
  positionsWithJob: [],
  categoryDataForEditCategoryModal: null,
  isEdit: false,
};

/**
 * @memberOf module:ManageJob
 * @namespace store
 * @description central store for manage job module
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
