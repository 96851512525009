<template>
  <header>
    <div class="container-fluid">
      <div
        class="position-relative d-flex align-items-center justify-content-between"
      >
        <div class="d-flex align-items-center">
          <div class="sidebar">
            <button id="menu-toggle" class="btn btn-basic">
              <img
                alt="argusoft-menu"
                src="../../../assets/images/argus-menu.png"
              />
            </button>
          </div>
          <div class="logo logo-with-menu ml-2">
            <router-link :to="{ name: 'applicantApplications' }"
              ><img src="../../../assets/images/newLogo.png" alt=""
            /></router-link>
          </div>
        </div>
        <div class="d-flex align-items-center" id="header_btn">
          <router-link
            :title="loggedInUser.first_name + ' ' + loggedInUser.last_name"
            :to="{
              name: 'applicantProfile',
            }"
          >
            <img
              class="mx-auto d-block rounded-circle profile_icon"
              v-if="loggedInUser.profile_pic"
              :src="'data:image/jpeg;base64,' + profilePic"
              alt="Profile"
            />
            <img
              v-else
              class="mx-auto d-block rounded-circle profile_icon"
              :src="avatar"
              alt="Profile"
            />
          </router-link>
          <button
            class="btn btn-basic sign-out-btn p-1"
            @click="applicantLogout()"
            title="Log out"
          >
            <i class="fa fa-2x fa-sign-out" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import userProfile from "../../../assets/images/avatar.png";
import VueCookie from "vue-cookie";
import { mapGetters } from "vuex";

/**
 * @memberof module:ApplicantMaster
 * @namespace applicant-header-section
 */
export default {
  data() {
    return {
      avatar: userProfile,
    };
  },
  computed: {
    ...mapGetters({
      loggedInUser: "$_applicant_master/getLoggedInApplicant",
      profilePic: "$_applicant_master/getProfilePic",
    }),
  },

  methods: {
    applicantLogout() {
      VueCookie.delete("APPLICANT_ACCESS_TOKEN");
      this.$store.dispatch("$_applicant_master/logoutApplicant");
      localStorage.clear();
      this.$router.push({ name: "home" });
    },
  },
  mounted() {
    $("#menu-toggle").click((e) => {
      e.preventDefault();
      $("#wrapper").toggleClass("active");
      $("#maincontent").toggleClass("sidebar-margin");
    });
    $(".submenu").click((e) => {
      e.stopPropagation();
    });
  },
  created() {
    this.$store.dispatch("$_applicant_master/getApplicantProfilePic");
  },
};
</script>

<style scoped>
.img-responsive {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-size: cover;
  margin-top: 2px;
}

.media img {
  max-width: 100px;
}

.sub-text {
  position: absolute;
  margin-top: -15px;
  text-align: right;
  color: "#666666";
  font-size: 14px;
}

.pull-left {
  margin-right: 20px;
}

.sign-out-btn {
  color: #8f1452;
  cursor: pointer;
  margin-left: 20px;
  vertical-align: middle;
}

.sign-out-btn:hover {
  color: #6d083b;
}

.btn__login {
  background: #8f1452;
  color: #fff !important;
  padding: 8px 25px;
  border: 1px solid #903564;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.btn__login i {
  padding-right: 5px;
}

.btn__login:hover {
  background: #6d083b;
}

/*header-css*/
header {
  position: fixed;
  top: 0px;
  background: #fff;
  left: 0px;
  right: 0px;
  width: 100%;
  z-index: 500;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.2);
}

header .logo {
  width: 170px;
  overflow: hidden;
}

header .logo img {
  width: 90%;
}

header .logo-with-menu {
  width: 120px;
}

@media screen and (max-width: 400px) {
  .logo-with-menu {
    display: none;
  }
}

header .header_btn {
  position: absolute;
  right: 0px;
}

header .profile_icon {
  right: 50px;
  height: 30px;
  width: 30px;
  background-size: cover;
}

header .logout {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  header .header_btn {
    top: 4px;
  }

  header .header_btn .btn__login {
    width: 180px;
    padding: 4px 15px;
    margin-bottom: 4px;
  }
}

@media (max-width: 599px) {
  .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 700px) {
  .custom-btn {
    margin-bottom: 2px;
  }
}
</style>
