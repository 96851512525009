<template>
  <div v-if="childDataLoaded">
    <applicant-form
      :userData="userData"
      :states="states"
      :employment_status="employment_status"
      :isJobIdValid="isJobIdValid"
      :isForEditData="isForEditData"
      :postUserData="postUserData"
    >
    </applicant-form>
  </div>
</template>

<script>
/* eslint-disable no-undef*/
import { mapGetters } from "vuex";
import ApplicantForm from "../../Common/_components/applicant-form.vue";
import { states, employment_status } from "../../Common/commonData";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  components: {
    ApplicantForm,
  },
  data() {
    return {
      userData: {},
      isForEditData: true,
      states: [],
      employment_status: [],
      isJobIdValid: false,
      childDataLoaded: false,
      isResumeChanged: false,
      newResume: null,
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      ApplicantData: "$_edit_applicant_data/getApplicantData",
      FormEditedStatus: "$_edit_applicant_data/getFormEditedStatus",
      getNewUploadedResume: "$_edit_applicant_data/getNewUploadedResume",
    }),
  },
  watch: {
    /***
     * @memberof module:EditApplicantData.components.edit-applicant-form
     * @param {Object} value object of userdata
     * @description watch - loads to get user data and load it in form
     */
    ApplicantData(value) {
      this.userData = value;
      this.childDataLoaded = true;
    },

    /***
     * @memberof module:EditApplicantData.components.edit-applicant-form
     * @param {Object} data status when form is successfully edited
     * @description watch -handles response when applicant form is edited
     */
    FormEditedStatus(data) {
      if (data.status === "SUCCESS") {
        const handler = () => {
          this.$router.push({ name: "applicants" });
        };
        this.$store
          .dispatch("openSnackbar", {
            type: "success",
            message: "Data Updated successfully.",
          })
          .then(handler)
          .catch(handler);
      } else if (value.status === "FAILURE") {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message:
            value.error_data.response && value.error_data.response.data.message
              ? value.error_data.response.data.message
              : "There is problem uploading your data. Please, try again later.",
        });
      }
    },

    getNewUploadedResume(resume) {
      this.newResume = resume;
      this.userData.resume = this.newResume;
      this.$store.dispatch("$_edit_applicant_data/postUserdata", this.userData);
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    getApplicantData() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.$store.dispatch(
        "$_edit_applicant_data/getApplicantData",
        this.$route.params.slug
      );
    },
    /**
     * @memberof module:EditApplicantData.components.edit-applicant-form
     *@description method - handles applicant form data when edited
     */
    postUserData(applicantData) {
      delete applicantData.linkedWithRecord;
      delete applicantData.applicantMasterId;
      delete applicantData.jobDetails;
      delete applicantData.projectDetails;
      delete applicantData.courseDetails;
      delete applicantData.profile_picture;

      if (applicantData.alternate_mobile_no === "")
        applicantData.alternate_mobile_no = null;

      this.$store.dispatch("$_edit_applicant_data/postUserdata", applicantData);
    },
  },
  /**
   * @memberof module:EditApplicantData.components.edit-applicant-form
   * @description lifecycle method - dispatch request of fetching data
   */
  created() {
    this.getApplicantData();
    this.states = states;
    this.employment_status = employment_status;
  },
};
</script>
