<template>
  <div
    id="formRender"
    class="modal fade bd-example-modal-lg"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Add Review</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form-render
            ref="formRender"
            modalId="formRender"
            :interviewData="interviewData"
            :isModal="true"
          ></form-render>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="btn btn-cp"
            @click="$refs.formRender.saveResponse()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formRender from "./form-render.vue";

export default {
  props: ["interviewData"],
  components: {
    formRender,
  },
  beforeDestroy() {
    $("#formRender").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
