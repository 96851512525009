<template>
  <div>
    <div class="d-flex">
      <small class="font-weight-bold" v-if="notUploaded">Not Uploaded</small>
      <div class="p-0 m-0" v-else-if="actionVisible">
        <button
          v-if="moodleInfoVisible"
          :id="popover_target"
          class="btn p-0 mx-1 text-success"
        >
          <i class="fa fa-info-circle" aria-hidden="true"></i>
        </button>
        <b-popover
          v-if="moodleInfoVisible"
          :target="popover_target"
          triggers="hover"
          placement="top"
        >
          <ul class="list-group m-0 p-0">
            <li class="list-group-item align-items-center custom-li">
              <b>Username</b>:
              {{ moodleUserMaster ? moodleUserMaster.moodle_username : "" }}
            </li>
            <li class="list-group-item align-items-center custom-li">
              <b>Password</b>:
              {{ moodleUserMaster ? moodleUserMaster.moodle_password : "" }}
            </li>
          </ul>
        </b-popover>
        <template
          v-if="
            hasAccess({ permissions: [BASE_PERMISSION['SCHEDULE-UPDATE']] })
          "
        >
          <button
            v-if="this.params.data.roundData[0]['round_result'] === 'Pending'"
            @click="passApplicantRound"
            title="pass round"
            class="btn p-0 mx-1 text-success"
          >
            <i class="fa fa-check" aria-hidden="true"></i>
          </button>
          <button
            v-if="this.params.data.roundData[0]['round_result'] === 'Pending'"
            @click="failApplicantRound"
            title="fail round"
            class="btn p-0 mx-1 text-danger"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </template>
        <button
          v-if="
            hasAccess({ permissions: [BASE_PERMISSION['SCHEDULE-CREATE']] })
          "
          @click="openCancelRoundModal"
          title="cancel round"
          class="btn p-0 mx-1 text-warning"
        >
          <i class="fa fa-ban" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <!-- Cancel Round Confirmation Modal -->
    <confirmation-modal
      ref="confirmCancelRoundModal"
      modal-id="confirm-cancel-round-modal"
      modal-title="Confirm Cancel Round"
      modal-message="Are you sure you want to cancel this round for the applicant?"
      :action="cancelApplicantRound"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import confirmationModal from "../../Common/_components/confirmation-modal.vue";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

export default {
  components: {
    confirmationModal,
  },
  watch: {},
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].SLOT;
    },
    notUploaded() {
      return this.params.data.not_uploaded;
    },
    actionVisible() {
      return true;
    },
    moodleInfoVisible() {
      return this.params.data.roundData[0].round_name == "Written Test";
    },
    moodleUserMaster() {
      return this.params.data.roundData[0]?.hasMoodle?.moodle_user_master;
    },
    newParams() {
      return this.params;
    },
    ...mapGetters({
      eventData: "$_campus_recruitment/getEventData",
    }),
    popover_target() {
      return `popover-target-${this.params.data.id}`;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    openCancelRoundModal() {
      this.$refs.confirmCancelRoundModal.$refs.confirmationModal.show();
    },
    passApplicantRound() {
      if (this.params && this.params.context) {
        this.params.context.componentParent.passApplicantRound(
          this.params.data
        );
      }
    },
    failApplicantRound() {
      if (this.params && this.params.context) {
        this.params.context.componentParent.failApplicantRound(
          this.params.data
        );
      }
    },
    cancelApplicantRound() {
      if (this.params && this.params.context) {
        this.params.context.componentParent.cancelApplicantRoundSelectMailTemplate(
          this.params.data
        );
      }
    },
  },
  beforeMount() {
    this.value = this.params.value;
  },
};
</script>

<style scoped>
.btn:focus {
  outline: none;
  box-shadow: none;
  color: white;
}
.custom-li {
  margin: 0;
  padding: 0;
  border: 0;
}
</style>
