<template>
  <div class="mb-3">
    <CollapseCard
      class="theme-default"
      title="Recruitment"
      :midDivider="false"
      header-class="px-0 py-0"
      :collapse="true"
      title-class="h5 m-0"
    >
      <template v-slot:right>
        <select class="form-control form-control-sm" v-model="selectedReport">
          <option
            v-for="(report, index) in sessionReportList"
            :value="report.id"
            :key="index"
          >
            {{ report.title }}
          </option>
        </select>
      </template>
      <div
        class="entity-details table-responsive mb-0"
        v-if="selectedReport && selectedReportData"
      >
        <table class="table" aria-hidden="true">
          <caption style="display: none">
            This table displays the summary report of the campus visited of the
            selected recruitment session.
          </caption>
          <tbody>
            <tr>
              <td class="cell">
                <strong style="font-weight: 600">Campuses</strong>
              </td>
              <td class="cell">
                {{ selectedReportData.campusCount }}
              </td>
              <td class="cell">
                <strong style="font-weight: 600">Entities</strong>
              </td>
              <td class="cell">
                {{
                  selectedReportData.collegeCount.length +
                  selectedReportData.universityCount.length +
                  selectedReportData.recruiterCount.length
                }}
              </td>
            </tr>
            <tr>
              <td class="cell">
                <strong style="font-weight: 600">Applicants</strong>
              </td>
              <td class="cell">{{ selectedReportData.applicantCount }}</td>
              <td class="cell">
                <strong style="font-weight: 600">Shortlisted</strong>
              </td>
              <td class="cell">
                {{
                  selectedReportData.shortlistedApplicantCount +
                  selectedReportData.joinedApplicantCount
                }}
              </td>
            </tr>
            <tr>
              <td class="cell">
                <strong style="font-weight: 600">Joined</strong>
              </td>
              <td class="cell">
                {{ selectedReportData.joinedApplicantCount }}
              </td>
              <td class="cell">
                <strong style="font-weight: 600">Failed</strong>
              </td>
              <td class="cell">
                {{ selectedReportData.failedApplicantCount }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="campus-stats border-top pt-2">
        <p
          class="h6 px-2 mb-0"
          style="font-weight: 600; color: rgb(143, 20, 82)"
        >
          Filter campus and entity wise
        </p>
        <div class="row mx-0 py-2">
          <div class="col d-flex justify-content-start">
            <select
              class="form-control form-control-sm"
              v-model="selectedCampus"
              @change="updateTableData"
            >
              <option :value="null">All Campuses</option>
              <option
                v-for="campus in applicationCampusCount"
                :key="campus.id"
                :value="campus.id"
              >
                {{ campus.campus_name }}
              </option>
            </select>
          </div>
          <div class="col d-flex justify-content-end">
            <select
              class="form-control form-control-sm"
              v-model="selectedEntity"
              @change="updateTableData"
            >
              <option :value="null">All Entities</option>
              <option
                v-for="entity in applicationEntityCount"
                :key="entity.id"
                :value="entity.entity_name"
              >
                {{ entity.entity_name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="entity-details table-responsive mb-0"
        v-if="selectedCampus || selectedEntity"
      >
        <table class="table" aria-hidden="true">
          <caption style="display: none">
            This table shows the total applicants' report.
          </caption>
          <tbody>
            <tr>
              <td class="cell">
                <strong style="font-weight: 600">Applicants</strong>
              </td>
              <td class="cell">{{ entityDetails.entity_count }}</td>
              <td class="cell">
                <strong style="font-weight: 600">Shortlisted</strong>
              </td>
              <td class="cell">{{ entityDetails.shortlisted }}</td>
            </tr>
            <tr>
              <td class="cell">
                <strong style="font-weight: 600">Failed</strong>
              </td>
              <td class="cell">{{ entityDetails.failed }}</td>
              <td class="cell">
                <strong style="font-weight: 600">Joined</strong>
              </td>
              <td class="cell">{{ entityDetails.joined }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card_footer text-right py-2 border-top">
        <router-link class="m-2" :to="{ name: 'sessionReport' }">
          View Report
          <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
        </router-link>
      </div>
    </CollapseCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../../Report/_api";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";

export default {
  components: {
    CollapseCard,
  },
  data() {
    return {
      selectedEntity: null,
      selectedCampus: null,
      campusId: null,
      entityDetails: {
        entity_count: 0,
        shortlisted: 0,
        joined: 0,
        failed: 0,
      },
      campusDetails: {
        campus_count: 0,
        shortlisted: 0,
        joined: 0,
        failed: 0,
      },
      selectedReport: null,
      selectedReportData: null,
      status: "",
      startDate: null,
      endDate: null,
    };
  },
  methods: {
    async fetchApplicationEntityCount() {
      await this.$store.dispatch("$_report/sessionApplicationEntityCount", {
        queryOptions: {},
        filterOptions: {
          campusId: this.selectedCampus,
          sessionReportId: this.selectedReport,
          start_date: this.selectedReportData?.start_date,
          end_date: this.selectedReportData?.end_date,
        },
      });
      this.updateTableData();
    },
    async fetchApplicationCampusCount() {
      await this.$store.dispatch("$_report/sessionApplicationCampusCount", {
        queryOptions: {},
        filterOptions: {
          sessionReportId: this.selectedReport,
          start_date: this.selectedReportData?.start_date,
          end_date: this.selectedReportData?.end_date,
        },
      });
      this.updateTableData();
    },
    fetchReports() {
      this.$store.dispatch("$_report/fetchSessionReportList", {});
    },
    updateTableData() {
      if (!this.selectedEntity) {
        this.entityDetails = {
          entity_count: 0,
          shortlisted: 0,
          joined: 0,
          failed: 0,
        };

        this.applicationEntityCount.forEach((entity) => {
          this.entityDetails.entity_count += Number(entity.entity_count) || 0;
          this.entityDetails.shortlisted += Number(entity.shortlisted) || 0;
          this.entityDetails.joined += Number(entity.joined) || 0;
          this.entityDetails.failed += Number(entity.failed) || 0;
        });
      } else {
        const selectedEntityDetails = this.applicationEntityCount.find(
          (entity) => entity.entity_name === this.selectedEntity
        );
        if (selectedEntityDetails) {
          this.entityDetails = {
            entity_count: Number(selectedEntityDetails.entity_count) || 0,
            shortlisted: Number(selectedEntityDetails.shortlisted) || 0,
            joined: Number(selectedEntityDetails.joined) || 0,
            failed: Number(selectedEntityDetails.failed) || 0,
          };
        }
      }
      if (!this.selectedCampus) {
        this.campusDetails = {
          campus_count: 0,
          shortlisted: 0,
          joined: 0,
          failed: 0,
        };

        this.applicationCampusCount.forEach((campus) => {
          this.campusDetails.entity_count += Number(campus.campus_count) || 0;
          this.campusDetails.shortlisted += Number(campus.shortlisted) || 0;
          this.campusDetails.joined += Number(campus.joined) || 0;
          this.campusDetails.failed += Number(campus.failed) || 0;
        });
      } else {
        const selectedCampusDetails = this.applicationCampusCount.find(
          (campus) => campus.campus_name === this.selectedCampus
        );
        if (selectedCampusDetails) {
          this.campusDetails = {
            entity_count: Number(selectedCampusDetails.campus_count) || 0,
            shortlisted: Number(selectedCampusDetails.shortlisted) || 0,
            joined: Number(selectedCampusDetails.joined) || 0,
            failed: Number(selectedCampusDetails.failed) || 0,
          };
        }
      }
    },

    getReportData() {
      api
        .getSessionReportData(this.selectedReport)
        .then((res) => {
          const campusCount = res.data.campusData.length;
          const collegeCount = [];
          const collegeList = [];
          const universityCount = [];
          const uiversityList = [];
          const recruiterCount = [];
          const recruiterList = [];
          for (const campus of res.data.campusData) {
            for (const entity of campus.entitiesInvolved) {
              if (entity.entityDetail.entity_type == "University") {
                if (!universityCount.includes(entity.entityDetail.id)) {
                  universityCount.push(entity.entityDetail.id);
                  uiversityList.push(entity);
                }
              } else if (entity.entityDetail.entity_type == "College") {
                if (!collegeCount.includes(entity.entityDetail.id)) {
                  collegeCount.push(entity.entityDetail.id);
                  collegeList.push(entity);
                }
              } else if (entity.entityDetail.entity_type == "Recruiter") {
                if (!recruiterCount.includes(entity.entityDetail.id)) {
                  recruiterCount.push(entity.entityDetail.id);
                  recruiterList.push(entity);
                }
              }
            }
          }
          this.selectedReportData = {
            ...res.data,
            campusCount,
            collegeCount,
            collegeList,
            universityCount,
            recruiterCount,
            recruiterList,
          };
        })
        .catch(() => (this.selectedReportData = null));
    },
  },
  created() {
    this.fetchReports();
  },
  watch: {
    selectedEntity: {
      handler: "updateTableData",
      immediate: true,
    },
    selectedCampus() {
      this.selectedEntity = null;
      this.fetchApplicationEntityCount();
    },

    applicationEntityCount() {
      this.updateTableData();
    },
    sessionReportList() {
      if (this.sessionReportList.length > 0) {
        this.selectedReport = this.sessionReportList[0].id;
      } else {
        this.selectedReport = null;
      }
    },
    selectedReport(value) {
      this.selectedCampus = null;
      if (value) {
        this.getReportData();
      }
    },
    selectedReportData() {
      this.fetchApplicationCampusCount();
      this.fetchApplicationEntityCount();
    },
  },

  computed: {
    ...mapGetters({
      sessionReportList: "$_report/sessionReportList",
      applicationCampusCount: "$_report/sessionApplicationCampusCount",
      applicationEntityCount: "$_report/sessionApplicationEntityCount",
    }),
  },
};
</script>

<style scoped>
.card_footer a {
  color: #903564;
  /* text-transform: uppercase; */
  font-weight: 600;
  text-decoration: none;
}
.cell {
  padding: 10px;
}
</style>
