<template>
  <div class="content">
    <common-header :data="['home', 'applications', 'schedule']" />
    <div class="container-fluid">
      <div
        v-if="isSmallScreen"
        class="d-flex w-100 justify-content-between mb-3"
      >
        <button
          class="btn btn-primary d-md-none"
          type="button"
          data-toggle="collapse"
          data-target="#collapseFilters"
          aria-expanded="false"
          aria-controls="collapseFilters"
          style="background-color: #8f1452; color: white; border: #8f1452"
        >
          Filters
        </button>
        <button
          class="btn btn-primary"
          type="button"
          data-toggle="collapse"
          data-target="#collapsePendingEvents"
          aria-expanded="false"
          aria-controls="collapsePendingEvents"
          style="background-color: #8f1452; color: white; border: #8f1452"
        >
          Pending Events
        </button>
      </div>
      <div class="collapse d-md-none w-100 mb-2" id="collapseFilters">
        <div class="card card-body">
          <div class="applicant-schedule-sidebar-section row pl-4">
            <div class="col-12 col-sm-6">
              <input
                id="includeWeekends"
                type="checkbox"
                class="form-check-input"
                :checked="calendarOptions.weekends"
                @change="handleWeekendsToggle"
              />
              <label for="includeWeekends"> Include Weekends</label>
            </div>
            <div class="col-12 col-sm-6">
              <input
                id="showAll"
                type="checkbox"
                class="form-check-input"
                :checked="!calendarOptions.dayMaxEvents"
                @change="handleDayMaxEventsToggle"
              />
              <label for="showAll"> Show All Events</label>
            </div>
            <div
              class="col-12 col-sm-6"
              v-if="
                hasAccess({
                  permissions: [
                    BASE_PERMISSION.READ,
                    PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                  ],
                  checkAll: false,
                })
              "
            >
              <input
                id="mySchedule"
                type="checkbox"
                class="form-check-input"
                @change="toggleMyRounds"
              />
              <label for="mySchedule">My Schedules</label>
            </div>
            <div
              class="col-12 col-sm-6"
              v-if="hasAccess({ permissions: [PERMISSIONS.APPLICANT.READ] })"
            >
              <input
                id="claimedByMe"
                type="checkbox"
                class="form-check-input"
                @change="toggleClaimedByMe"
              />
              <label for="claimedByMe">Claimed By Me</label>
            </div>
          </div>
          <div class="applicant-schedule-sidebar-section">
            <h5>Filters</h5>
            <div
              class="col-12 p-0 mb-2"
              v-if="
                hasAccess({
                  permissions: [
                    PERMISSIONS.APPLICANT.READ,
                    PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                  ],
                  checkAll: false,
                })
              "
            >
              <label htmlFor="interviewers">Interviewers/Reviewers</label>
              <multiselect
                v-model="selectedUsers"
                :multiple="true"
                :options="users"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Pick some"
                label="name"
                track-by="name"
                :preselect-first="true"
                selectLabel=""
                deselectLabel=""
                selectedLabel="*"
                @input="filterEvents"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars -->
                <template slot="selection" slot-scope="{ values, isOpen }"
                  ><span
                    class="multiselect__single"
                    v-if="values.length && !isOpen"
                    >{{ values.length }} options selected</span
                  ></template
                >
              </multiselect>
            </div>
            <div class="col-12 p-0">
              <label htmlFor="events">Include Events</label><br />
              <select
                v-model="selectedType"
                class="form-control"
                @change="filterEvents"
              >
                <option value="">All Events</option>
                <option value="Aptitude">Aptitude Only</option>
                <option value="Written Test">Written Test Only</option>
                <option value="Interview">Interviews Only</option>
                <option value="Review">Reviews Only</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="collapse d-md-none w-100 mb-2" id="collapsePendingEvents">
        <div class="card card-body p-2">
          <div
            class="applicant-schedule-sidebar-section"
            v-if="pendingEvents.length > 0 || pendingCampusEvents.length > 0"
          >
            <div class="pending-events-section m-0">
              <h5>Pending A. Events - ({{ pendingEvents.length }})</h5>
              <div class="mb-2" v-for="event in pendingEvents" :key="event.id">
                <span
                  style="margin-top: 3px"
                  class="event-circle"
                  :title="event.roundName"
                >
                  {{ event.round }}
                </span>
                <p class="mb-0 font-weight-bold">
                  {{ event.start | formatDateTime }}
                </p>
                <div style="margin-left: 30px">
                  <i :title="event.withUsers">
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'applicantprofile',
                        params: { slug: event.slug },
                      }"
                      class="link-class"
                      >{{ event.title | truncate(30, "..") }}</router-link
                    >
                  </i>
                </div>
              </div>
              <h5 class="mt-4">
                Pending C. Events - ({{ pendingCampusEvents.length }})
              </h5>
              <div
                class="schedule-app-list"
                v-for="(event, index) in pendingCampusEvents"
                :key="index"
              >
                <span
                  style="margin-top: 3px"
                  class="event-circle"
                  :title="event.round_name"
                  >{{ event.round }}</span
                >
                <b>
                  {{ formatCampusDate(event.round_schedule) }} -
                  {{ formatCampusDate(event.round_schedule_end) }}
                </b>
                <div
                  v-if="
                    hasAccess({
                      permissions: [PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU],
                      checkAll: false,
                    })
                  "
                >
                  <i>
                    <span
                      style="margin-top: 3px"
                      class="event-circle event-type-circle"
                      :title="'Campus Event'"
                      >C</span
                    >
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'scheduleEventSlot',
                        params: { eventId: event.id },
                      }"
                      class="link-class"
                    >
                      {{ event.title | truncate(30, "..") }}</router-link
                    >
                  </i>
                </div>
                <div v-else>
                  <i>
                    <span
                      style="margin-top: 3px"
                      class="event-circle event-type-circle"
                      :title="'Campus Event'"
                      >C</span
                    >{{ event.title | truncate(30, "..") }}</i
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center">
            <h5 class="m-0">No Pending Events</h5>
          </div>
        </div>
      </div>
      <div class="applicant-schedule parent-class d-flex">
        <div v-if="!isSmallScreen" class="schedule-sidebar-parent p-0">
          <div
            class="applicant-schedule-sidebar mr-3 rounded d-flex flex-column"
          >
            <div class="applicant-schedule-sidebar-section">
              <div class="col-12">
                <input
                  id="includeWeekends"
                  type="checkbox"
                  class="form-check-input"
                  :checked="calendarOptions.weekends"
                  @change="handleWeekendsToggle"
                />
                <label for="includeWeekends"> Include Weekends</label>
              </div>
              <div class="col-12">
                <input
                  id="showAll"
                  type="checkbox"
                  class="form-check-input"
                  :checked="!calendarOptions.dayMaxEvents"
                  @change="handleDayMaxEventsToggle"
                />
                <label for="showAll"> Show All Events</label>
              </div>
              <div
                class="col-12"
                v-if="
                  hasAccess({
                    permissions: [
                      BASE_PERMISSION.READ,
                      PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                    ],
                    checkAll: false,
                  })
                "
              >
                <input
                  id="mySchedule"
                  type="checkbox"
                  class="form-check-input"
                  @change="toggleMyRounds"
                />
                <label for="mySchedule">My Schedules</label>
              </div>
              <div
                class="col-12"
                v-if="hasAccess({ permissions: [PERMISSIONS.APPLICANT.READ] })"
              >
                <input
                  id="claimedByMe"
                  type="checkbox"
                  class="form-check-input"
                  @change="toggleClaimedByMe"
                />
                <label for="claimedByMe">Claimed By Me</label>
              </div>
            </div>
            <div class="applicant-schedule-sidebar-section">
              <h5>Filters</h5>
              <div
                class="col-12 p-0 mb-2"
                v-if="
                  hasAccess({
                    permissions: [
                      PERMISSIONS.APPLICANT.READ,
                      PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                    ],
                    checkAll: false,
                  })
                "
              >
                <label htmlFor="interviewers">Interviewers/Reviewers</label>
                <multiselect
                  v-model="selectedUsers"
                  :multiple="true"
                  :options="users"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Pick some"
                  label="name"
                  track-by="name"
                  :preselect-first="true"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="*"
                  @input="filterEvents"
                >
                  <!-- eslint-disable-next-line vue/no-unused-vars -->
                  <template slot="selection" slot-scope="{ values, isOpen }"
                    ><span
                      class="multiselect__single"
                      v-if="values.length && !isOpen"
                      >{{ values.length }} options selected</span
                    ></template
                  >
                </multiselect>
              </div>
              <div class="col-12 p-0">
                <label htmlFor="events">Include Events</label><br />
                <select
                  v-model="selectedType"
                  class="form-control"
                  @change="filterEvents"
                >
                  <option value="">All Events</option>
                  <option value="Aptitude">Aptitude Only</option>
                  <option value="Written Test">Written Test Only</option>
                  <option value="Interview">Interviews Only</option>
                  <option value="Review">Reviews Only</option>
                </select>
              </div>
            </div>
            <div
              class="applicant-schedule-sidebar-section"
              style="min-height: 0"
              v-if="pendingEvents.length > 0 || pendingCampusEvents.length > 0"
            >
              <div class="pending-events-section">
                <h5>Pending A. Events - ({{ pendingEvents.length }})</h5>
                <div
                  class="mb-2"
                  v-for="event in pendingEvents"
                  :key="event.id"
                >
                  <span
                    style="margin-top: 3px"
                    class="event-circle"
                    :title="event.roundName"
                  >
                    {{ event.round }}
                  </span>
                  <p class="mb-0 font-weight-bold">
                    {{ event.start | formatDateTime }}
                  </p>
                  <div style="margin-left: 30px">
                    <i :title="event.withUsers">
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'applicantprofile',
                          params: { slug: event.slug },
                        }"
                        class="link-class"
                        >{{ event.title | truncate(30, "..") }}</router-link
                      >
                    </i>
                  </div>
                </div>
                <h5 class="mt-4">
                  Pending C. Events - ({{ pendingCampusEvents.length }})
                </h5>
                <div
                  class="schedule-app-list"
                  v-for="(event, index) in pendingCampusEvents"
                  :key="index"
                >
                  <span
                    style="margin-top: 3px"
                    class="event-circle"
                    :title="event.round_name"
                    >{{ event.round }}</span
                  >
                  <b>
                    {{ formatCampusDate(event.round_schedule) }} -
                    {{ formatCampusDate(event.round_schedule_end) }}
                  </b>
                  <div
                    v-if="
                      hasAccess({
                        permissions: [
                          PERMISSIONS['CAMPUS-DRIVE'].SCHEDULE.MENU,
                        ],
                        checkAll: false,
                      })
                    "
                  >
                    <i>
                      <span
                        style="margin-top: 3px"
                        class="event-circle event-type-circle"
                        :title="'Campus Event'"
                        >C</span
                      >
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'scheduleEventSlot',
                          params: { eventId: event.id },
                        }"
                        class="link-class"
                      >
                        {{ event.title | truncate(30, "..") }}</router-link
                      >
                    </i>
                  </div>
                  <div v-else>
                    <i>
                      <span
                        style="margin-top: 3px"
                        class="event-circle event-type-circle"
                        :title="'Campus Event'"
                        >C</span
                      >{{ event.title | truncate(30, "..") }}</i
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="applicant-schedule-app p-2 border rounded"
          style="flex-grow: 1"
          ref="calendarParent"
        >
          <FullCalendar
            v-show="isCalendarView"
            id="calendar"
            name="calendar"
            ref="calendar"
            class="fullcalendar schedule-app-calendar fullcalendar-header-style-md"
            :options="calendarOptions"
            :style="{ fontSize: toolbarFontSize }"
          >
            <template v-slot:eventContent="arg">
              <div style="width: 100%">
                <div
                  style="width: 100%"
                  :class="{
                    'event-title-inactive':
                      arg.event.extendedProps.status != 'Pending',
                  }"
                  @click="handleEventClick(arg)"
                  v-if="arg.event.extendedProps.eventType === 'applicantEvent'"
                >
                  <div>
                    <b class="event-time">{{ arg.timeText }}</b>
                    <span
                      class="event-circle"
                      :title="arg.event.extendedProps.roundName"
                      :class="{
                        'event-circle-inactive':
                          arg.event.extendedProps.status != 'Pending',
                      }"
                    >
                      {{ arg.event.extendedProps.round }}
                    </span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <i
                      class="title-overflow"
                      v-if="arg.event.extendedProps.status !== 'Pending'"
                      :title="arg.event.extendedProps.withUsers"
                    >
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'applicantprofile',
                          params: { slug: arg.event.extendedProps.slug },
                        }"
                        class="link-class"
                      >
                        {{ arg.event.title }}
                      </router-link>
                    </i>
                    <i
                      class="title-overflow"
                      v-else
                      :title="arg.event.extendedProps.withUsers"
                    >
                      {{ arg.event.title }}
                    </i>
                    <span v-if="arg.view.type == 'listMonth'">
                      {{ arg.event.extendedProps.interviewers }}
                    </span>
                  </div>
                </div>
                <div
                  class="w-100"
                  style="overflow: hidden"
                  @click="handleCampusEventClick(arg)"
                  v-else
                >
                  <div
                    style="width: 100%"
                    class="d-flex justify-content-between"
                    :class="{
                      'event-title-inactive':
                        arg.event.extendedProps.status != 'Pending' ||
                        !scheduleDateValid(
                          arg.event.extendedProps.round_schedule_end
                        ),
                    }"
                  >
                    <div class="left-side-div">
                      <span
                        class="event-circle span-circle"
                        :title="arg.event.extendedProps.round_name"
                        :class="{
                          'event-circle-inactive':
                            arg.event.extendedProps.status != 'Pending' ||
                            !scheduleDateValid(
                              arg.event.extendedProps.round_schedule_end
                            ),
                        }"
                      >
                        {{ arg.event.extendedProps.round }}
                      </span>
                      <span
                        :title="arg.event.extendedProps.withUsers"
                        class="font-weight-bold"
                        style="cursor: pointer"
                      >
                        {{ arg.event.title }}
                      </span>
                    </div>

                    <div class="d-flex">
                      <div v-if="arg.view.type == 'listMonth'">
                        {{ arg.event.extendedProps.interviewers }}
                      </div>
                      <div v-if="arg.view.type != 'listMonth'">
                        {{
                          extractTime(arg.event.extendedProps.round_schedule)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </FullCalendar>
        </div>
      </div>

      <b-modal
        ref="eventModal"
        :title="eventModalData.modalTitle"
        :size="eventModalData.modalSize"
        :hide-footer="eventModalData.hideFooter"
      >
        <EventModal
          :isEdit="eventModalData.isEdit"
          v-on:generateRound="generateRound($event)"
          v-on:updateEvent="updateEvent($event)"
          :editEventApplicant="editEventApplicantData"
        />
      </b-modal>
      <b-modal
        ref="campusEventModal"
        :title="eventModalData.modalTitle"
        :size="eventModalData.modalSize"
        :hide-footer="eventModalData.hideFooter"
      >
        <CampusEventModal
          :campusSchedule="false"
          v-on:closeEventModal="closeEventModal"
          :isEdit="eventModalData.isEdit"
          :selectedDate="selectedDate"
          :editEvent="editEvent"
          v-on:generateRound="generateRound($event)"
          v-on:updateEvent="updateEvent($event)"
        />
      </b-modal>
      <edit-round-modal
        v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.UPDATE] })"
        :showApplicantName="true"
        :applicantData="this.applicantData"
      />
      <common-modal
        :id="commonModalData.id"
        :modal-title="commonModalData.title"
        :modal-body="commonModalData.body"
        :show-cancel="commonModalData.showCancel"
        :modal-color="commonModalData.color"
        :modal-size-class="commonModalData.sizeClass"
        @actionPerformed="emitOnCurrent"
      />
      <schedule-round-modal
        v-if="hasAccess({ permissions: [BASE_PERMISSION.ROUND.CREATE] })"
        :applicant="applicantData"
        :calendarDateTime="selectedDate"
        :inPlaceAction="true"
      />
    </div>
  </div>
</template>
<script>
import api from "../_api";
import campusRecruitmentApi from "../../CampusRecruitment/_api/index";
import apiApplicantProfile from "../../ApplicantProfile/_api";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import Multiselect from "vue-multiselect";
import EventModal from "./event-modal.vue";
import CommonModal from "../../Common/_components/modal.vue";
import scheduleRoundModal from "./schedule-round-modal.vue";
import EditRoundModal from "../../ApplicantProfile/_components/edit-round-modal.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import CampusEventModal from "../../CampusRecruitment/_components/event-modal.vue";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  components: {
    FullCalendar,
    Multiselect,
    EventModal,
    CommonModal,
    scheduleRoundModal,
    EditRoundModal,
    // AgGridVue,
    CampusEventModal,
  },
  data: function () {
    return {
      isSmallScreen: false,
      myRounds: false,
      ovserver: null,
      users: [],
      selectedUsers: [],
      campusEvents: [],
      editEvent: null,
      selectedType: "",
      calendarOptions: {
        height: "100%",
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
        },
        buttonText: {
          list: "table",
        },
        initialView: "dayGridMonth",
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        select: this.handleDateSelect,
        // eventClick: this.handleEventSelect,
        // dateClick: this.handleDateSelect,
        // //Dynamic Event Source
        eventSources: [
          {
            events: this.fetchEvents,
          },
        ],
        lazyFetching: false,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      claimedByMe: false,
      pendingEvents: [],
      pendingCampusEvents: [],
      allEvents: [],
      eventModalData: {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      },
      applicantData: null,
      selectedDate: null,
      isCalendarView: true,
      editEventApplicantData: null,
      currentDate: new Date(),
      rowSelection: null,
      columnDefs: [
        {
          headerName: "Round",
          field: "roundName",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Candidate",
          field: "title",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Status",
          field: "status",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Position",
          field: "position",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Time",
          field: "start",
          valueFormatter: this.formatDate,
          filter: "agDateColumnFilter",
          filterParams: {
            filterOptions: ["equals"],
            comparator: function (filterDate, cellValue) {
              const formattedDate = moment(cellValue).format("DD/MM/YY");
              const filterText = moment(filterDate).format("DD/MM/YY");
              return formattedDate.includes(filterText) ? 0 : 1;
            },
          },
        },
        {
          headerName: "Interviewer/Reviewer",
          field: "interviewers",
          filter: "agTextColumnFilter",
        },
      ],
    };
  },
  created() {
    this.rowSelection = "single";
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      applicant_Profile_Data: "$_applicant_profile/fetchApplicantData",
      applicantRoundsData: "$_applicant_profile/fetchRoundDetails",
      confirmRoundScheduleStatus:
        "$_applicants_data/fetchConfirmRoundScheduleStatus",
      loggedInUser: "user/loggedInUser",
    }),
    toolbarFontSize() {
      return window.innerWidth <= 650 ? "60%" : "";
    },
  },
  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth < 768; // Example breakpoint for small screens
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    closeEventModal() {
      this.$refs["campusEventModal"].hide();
    },
    checkLastDateOver(last_date) {
      const currentDate = moment();
      return moment(last_date).isBefore(currentDate);
    },
    extractTime(value) {
      return moment(value).format("h:mm A");
    },
    scheduleDateValid(date) {
      return moment(date).isAfter(new Date());
    },
    getStatusAbbreviation(status) {
      let abbreviation = "";
      if (status === "Pending") {
        abbreviation = "PND";
      } else if (status === "Completed") {
        abbreviation = "CPD";
      } else if (status === "Cancelled") {
        abbreviation = "CND";
      } else {
        abbreviation = "PND";
      }
      return abbreviation;
    },
    /**
     * @memberof module:CampusRecruitment.components.campus_schedule
     * @description handle click event and open edit event modal
     */
    handleCampusEventClick(clickInfo) {
      this.editEvent = null;
      const foundEvent = this.campusEvents.find((event) => {
        return event.id == clickInfo.event.id;
      });
      this.editEvent = foundEvent;
      this.$store.dispatch("$_campus_recruitment/resetSelectedCampus");
      const filter = "?campusId=" + clickInfo.event.extendedProps.campusId;
      campusRecruitmentApi
        .fetchCampuses(filter)
        .then((res) => {
          this.$store.dispatch(
            "$_campus_recruitment/setSelectedCampus",
            res.data[0]
          );
          this.eventModalData = {
            modalTitle: "Edit Event",
            modalSize: "xl",
            hideFooter: true,
            isEdit: true,
          };
          this.$refs["campusEventModal"].show();
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem retrieving data!",
            },
            { root: true }
          );
        });
    },
    toggleView() {
      this.isCalendarView = !this.isCalendarView;
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDayMaxEventsToggle() {
      this.calendarOptions.dayMaxEvents = !this.calendarOptions.dayMaxEvents; // update a property
    },
    toggleClaimedByMe() {
      this.claimedByMe = !this.claimedByMe;
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },
    toggleMyRounds() {
      this.myRounds = !this.myRounds;
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },
    handleDateSelect(selectInfo) {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.eventModalData = {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      };
      const selectDate = new Date(selectInfo.startStr).setHours(0, 0, 0, 0);
      const currentDate = new Date().setHours(0, 0, 0, 0);
      if (selectDate < currentDate) {
        // show invalid modal
        this.openDialog(
          "invalidEventModal",
          "Invalid Input",
          "Please select a future date & time for the event!",
          false,
          "danger",
          "modal-md"
        );
        return;
      }
      this.selectedDate = selectInfo;
      this.$refs["eventModal"].show();
    },

    tabularCreateEvent() {
      this.eventModalData = {
        modalTitle: "Add Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: false,
      };
      this.$refs["eventModal"].show();
    },
    tabularEventClick(data) {
      if (data.node.data.status !== "Pending") {
        return;
      }

      this.eventModalData = {
        modalTitle: "Edit Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };

      apiApplicantProfile
        .fetchApplicantData(data.node.data.slug)
        .then((response) => {
          this.editEventApplicantData = response.data;
        });
      this.$refs["eventModal"].show();
    },
    handleEventClick(clickInfo) {
      if (clickInfo.event.extendedProps.status !== "Pending") {
        return;
      }

      this.eventModalData = {
        modalTitle: "Edit Event",
        modalSize: "xl",
        hideFooter: true,
        isEdit: true,
      };

      apiApplicantProfile
        .fetchApplicantData(clickInfo.event.extendedProps.slug)
        .then((response) => {
          this.editEventApplicantData = response.data;
        });
      this.$refs["eventModal"].show();
    },
    generateRound(applicant) {
      this.$refs["eventModal"].hide();
      this.applicantData = applicant;
      this.selectedDate = $("#addRound").modal("show");
    },
    updateEvent(applicant) {
      this.$refs["eventModal"].hide();
      this.applicantData = applicant;
      this.selectedDate = $("#editRound").modal("show");
    },
    formatDate(date) {
      if (date.value === null) return null;
      else if (date.value) return moment(date.value).format("llll");
      else return moment(date).format("llll");
    },
    formatCampusDate(dateString) {
      return moment(dateString).format("DD/MMM/YY");
    },
    fetchEvents(config, successCallback, failureCallback) {
      let filters = {
        startDate: config.start,
        endDate: config.end,
        round: this.selectedType,
        myRounds: this.myRounds,
        ...(this.claimedByMe ? { claimedBy: this.loggedInUser.user_id } : {}),
      };

      this.allEvents = [];
      this.pendingEvents = [];
      this.users = [];

      api
        .fetchAllSchedule(filters)
        .then((response) => {
          let events = response.data.applicantRounds;
          let interviewerId = [];
          events.forEach((event) => {
            let userIds = [];
            let userNames = [];
            event.interviewers.forEach((user) => {
              if (user.interviewer_name) {
                const userExists = interviewerId.some(
                  (userId) => userId === user.interviewer_id
                );
                if (!userExists) {
                  this.users.push({
                    id: user.interviewer_id,
                    name: user.interviewer_name,
                  });
                  interviewerId.push(user.interviewer_id);
                }
                userIds.push(user.interviewer_id);
                userNames.push(user.interviewer_name);
              }
            });
            event.reviewers.forEach((user) => {
              if (user.reviewer_name) {
                const userExists = interviewerId.some(
                  (userId) => userId === user.reviewer_id
                );
                if (!userExists) {
                  this.users.push({
                    id: user.reviewer_id,
                    name: user.reviewer_name,
                  });
                  interviewerId.push(user.reviewer_id);
                }
                userIds.push(user.reviewer_id);
                userNames.push(user.reviewer_name);
              }
            });

            let calEvent = {
              eventType: "applicantEvent",
              id: event.id,
              slug: event.applicantIterationMaster.slug,
              title:
                event.applicantIterationMaster.first_name +
                " " +
                event.applicantIterationMaster.last_name,
              withUsers: this.getWithUsers(
                event.round_name,
                event.round_result,
                userNames.toString(),
                event.applicantIterationMaster.position
              ),
              interviewers: userNames.toString(),
              start: event.round_schedule,
              status: event.round_result,
              position: event.applicantIterationMaster.position,
              users: userIds,
              round: this.getTitleAbbreviation(event.round_name),
              roundName: event.round_name,
              allDay: false,
              backgroundColor: this.getEventColor(event.round_result),
              borderColor: this.getEventColor(event.round_result),
            };

            if (event.round_result === "Pending") {
              this.pendingEvents.push(calEvent);
            }

            this.transformUsers();

            if (this.selectedUsers && this.selectedUsers.length > 0) {
              if (calEvent.users.length > 0) {
                this.selectedUsers.every((selectedUser) => {
                  let result = calEvent.users.find(
                    (id) => id === selectedUser.id
                  );
                  if (result) {
                    this.allEvents.push(calEvent);
                    return false;
                  } else {
                    return true;
                  }
                });
              }
            } else {
              this.allEvents.push(calEvent);
            }
          });

          // campus event
          let calendarApi = this.$refs.calendar.getApi();
          let campusEvents = [];
          response.data.campusRounds.forEach((event) => {
            let userNames = [];
            event.interviewers.forEach((user) => {
              if (user.interviewer_name) {
                const userExists = interviewerId.some(
                  (userId) => userId == user.interviewer_id
                );
                if (!userExists) {
                  this.users.push({
                    id: user.interviewer_id,
                    name: user.interviewer_name,
                  });
                  interviewerId.push(user.interviewer_id);
                }
                userNames.push(user.interviewer_name);
              }
            });
            let eventData = {
              ...event,
              eventType: "campusEvent",
              title:
                event.campusDetail.recruitment_session_name +
                " - " +
                event.round_title,
              start: event.round_schedule,
              end: event.round_schedule_end,
              round: this.getTitleAbbreviation(event.round_name),
              interviewers: userNames.join(", "),
              withUsers: this.getCampusWithUsers(
                event.round_title,
                event.round_name,
                event.status,
                event.campusDetail.recruitment_session_name,
                userNames.join(", ")
              ),
              roundName: event.round_name,
              backgroundColor: this.getCampusEventColor(
                event.status,
                event.round_schedule_end
              ),
              borderColor: this.getCampusEventColor(
                event.status,
                event.round_schedule_end
              ),
              allDay: false,
            };
            const interviewIds = event.slotsInvolved.flatMap(
              (event) => event.interviewerId
            );
            if (
              !this.selectedUsers ||
              !this.selectedUsers.length ||
              this.hasCommonElement(
                interviewIds,
                this.selectedUsers.map((user) => user.id)
              )
            ) {
              if (calendarApi && calendarApi.view.type != "dayGridMonth") {
                campusEvents.push({
                  ...eventData,
                  startRecur: moment(new Date(event.round_schedule)).format(
                    "YYYY-MM-DD"
                  ),
                  endRecur: moment(new Date(event.round_schedule_end))
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                  startTime: moment(new Date(event.round_schedule)).format(
                    "HH:mm"
                  ),
                  endTime: moment(new Date(event.round_schedule_end)).format(
                    "HH:mm"
                  ),
                  backgroundColor: this.getCampusEventColor(
                    event.status,
                    event.round_schedule_end
                  ),
                  borderColor: this.getCampusEventColor(
                    event.status,
                    event.round_schedule_end
                  ),
                });
              } else {
                campusEvents.push({
                  ...eventData,
                  allDay: moment(event.round_schedule).isSame(
                    event.round_schedule_end,
                    "day"
                  ),
                });
              }
            }
          });
          this.campusEvents = campusEvents;
          this.pendingCampusEvents = campusEvents.filter((event) => {
            return (
              event.status == "Pending" &&
              !this.checkLastDateOver(event.round_schedule_end)
            );
          });
          this.allEvents = [...this.allEvents, ...campusEvents];
          successCallback(this.allEvents);
        })
        .catch(() => {
          failureCallback([]);
        });
    },

    hasCommonElement(arrayA, arrayB) {
      return arrayA.some((element) => arrayB.includes(element));
    },

    filterEvents() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.refetchEvents();
    },
    getTitleAbbreviation(roundName) {
      let abbreviation = "I";
      if (roundName === "Review") {
        abbreviation = "R";
      } else if (roundName === "Aptitude") {
        abbreviation = "A";
      } else if (roundName === "Written Test") {
        abbreviation = "W";
      }
      return abbreviation;
    },
    getWithUsers(roundName, roundStatus, withUsers, position) {
      let message = "";
      if (roundName === "Review") {
        message =
          "Reviewer/s: " +
          withUsers.toString() +
          " \nStatus: " +
          roundStatus +
          " \nPosition: " +
          position;
      } else if (roundName === "Interview") {
        message =
          "Interviewer/s: " +
          withUsers.toString() +
          " \nStatus: " +
          roundStatus +
          " \nPosition: " +
          position;
      } else {
        message = "Status: " + roundStatus + "\nPosition: " + position;
      }

      return message;
    },
    getCampusWithUsers(
      eventTitle,
      eventName,
      eventStatus,
      recruitmentName,
      interviewers
    ) {
      let message = "";
      if (eventName === "Written Test") {
        message =
          "Title: " +
          eventTitle +
          " \nType: " +
          eventName +
          " \nRecruitmentName: " +
          recruitmentName +
          "\nScheduler/s: " +
          interviewers.toString() +
          " \nStatus: " +
          eventStatus;
      } else if (eventName === "Interview") {
        message =
          "Title: " +
          eventTitle +
          " \nType: " +
          eventName +
          " \nRecruitmentName: " +
          recruitmentName +
          "\nInterviewer/s: " +
          interviewers.toString() +
          " \nStatus: " +
          eventStatus;
      } else {
        message = "Status: " + eventStatus;
      }

      return message;
    },
    getCampusEventColor(roundStatus, end_time) {
      let color = "line";
      if (roundStatus === "Cancelled") {
        color = "#FEE6AF";
      } else if (
        roundStatus === "Pending" &&
        end_time &&
        !this.scheduleDateValid(end_time)
      ) {
        color = "grey";
      } else if (roundStatus === "Pending") {
        color = "green";
      }
      return color;
    },
    getEventColor(roundStatus) {
      let color = "green";
      if (roundStatus === "Failed" || roundStatus === "Differed") {
        color = "orange";
      } else if (roundStatus === "Cancelled") {
        color = "grey";
      } else if (roundStatus === "Pending") {
        color = "lime";
      }
      return color;
    },
    transformUsers() {
      this.users = this.users.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.id === value.id)
      );
      this.users.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
    onResize() {
      let calendarApi = this.$refs.calendar.getApi();
      calendarApi.updateSize();
    },
    navigateMonth(traverseTo) {
      function getNextMonthLastDate(timestamp) {
        const date = new Date(timestamp);
        date.setMonth(date.getMonth() + 2);
        date.setDate(0);
        return date;
      }
      function getLastDateOfPreviousMonth(timestamp) {
        const currentDate = new Date(timestamp);
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const firstDayOfCurrentMonth = new Date(year, month, 1);
        const lastDayOfPreviousMonth = new Date(
          firstDayOfCurrentMonth.getTime() - 1
        );
        return lastDayOfPreviousMonth;
      }
      if (traverseTo === "next") {
        const config = {
          start: new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth() + 1,
            1
          ),
          end: getNextMonthLastDate(this.currentDate),
        };
        this.fetchEvents(
          config,
          () => {},
          () => {}
        );
        this.currentDate = config.start;
      }
      if (traverseTo === "previous") {
        const config = {
          start: new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth() - 1,
            1
          ),
          end: getLastDateOfPreviousMonth(this.currentDate),
        };
        this.fetchEvents(
          config,
          () => {},
          () => {}
        );
        this.currentDate = config.end;
      }
    },
    getMoodleInstances() {
      if (
        !this.hasAccess({
          permissions: [this.PERMISSIONS["CAMPUS-DRIVE"]["MOODLE"].MENU],
        })
      )
        return;
      this.$store.dispatch("$_applicant_profile/getMoodleInstanceList");
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantsData.components.applicant_schedule
     * @param {String} value
     * @description Checks whether round has been added successfully or not then call filter event
     */
    confirmRoundScheduleStatus(value) {
      if (value === "SUCCESS") {
        this.filterEvents(null, null);
      }
    },
  },
  mounted() {
    this.ovserver = new ResizeObserver(this.onResize);
    this.ovserver.observe(this.$refs.calendarParent);
    this.getMoodleInstances();
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
  },

  beforeDestroy() {
    this.ovserver.unobserve(this.$refs.calendarParent);
    window.removeEventListener("resize", this.checkScreenSize);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.title-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.schedule-app-list {
  margin: 1em 0;
  padding: 0;
}

.schedule-app {
  display: flex;
  min-height: 35rem;
}

.schedule-sidebar-parent {
  width: 350px;
  height: 100%;
  padding: 10px;
}
.schedule-app-sidebar {
  height: 100%;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 5px;
}

.btn-margin {
  margin-top: 12px;
}

.fa-table,
.fa-calendar {
  background-color: #8f1452;
  padding: 8px;
  color: #fff;
  border-radius: 3px;
  margin-right: 3px;
}

.fa-angle-left {
  background-color: #2c3e50;
  font-size: 22px;
  padding: 0.8vw 1.5vw;
  color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.fa-angle-left:hover {
  background-color: #2e3941;
}

.fa-angle-right {
  background-color: #2c3e50;
  font-size: 22px;
  padding: 0.8vw 1.5vw;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-left: -4px;
}

.fa-angle-right:hover {
  background-color: #2e3941;
}
.schedule-app-main {
  margin-left: 310px;
  flex-grow: 1;
  padding: 10px 0px 0px 20px;
  height: 100%;
}

.fc {
  /* the calendar root */
  max-width: 100%;
  margin: 0 auto;
  text-transform: capitalize !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}
::v-deep .fc-event {
  margin-top: 4px;
}

.event-time {
  position: relative;
  float: right;
}

.event-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: #9b3f6f;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  /* position: relative; */
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-type-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  background-color: gray;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 18px;
  font-size: 12px;
}

.event-circle-inactive {
  background-color: #2e3941;
}

.event-title-inactive {
  color: #2e3941;
}

.link-class {
  font-size: 1em;
}

.right-div-title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.left-side-div {
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}
.applicant-schedule {
  width: 100%;
  padding: 0px;
}
.applicant-schedule-sidebar {
  height: 100%;
  margin-bottom: 10px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.applicant-schedule-sidebar-section {
  padding: 5px;
}
.pending-events-section {
  overflow: auto;
  height: 100%;
  margin: 10px 0px;
}

.parent-class {
  min-height: 500px;
  height: calc(100vh - 135px);
}

/* MEDIA QUERY FOR MEDIUM(MD) SCREEN */
@media screen and (max-width: 767px) {
  .schedule-app-calendar .fc-toolbar .fc-header-toolbar {
    font-size: 60% !important;
  }
  .pending-events-section {
    height: 17vh;
  }
  .parent-class {
    min-height: 500px;
    height: calc(100vh - 187px);
  }
}

/* MEDIA QUERY FOR SMALL(SM) SCREEN */
@media screen and (max-width: 576px) {
  .parent-class {
    min-height: 360px;
    /* height: calc(100vh - 187px); */
  }
}

/* MEDIA QUERY FOR SMALLER SCREEN */
@media screen and (max-width: 250px) {
  .parent-class {
    min-height: 200px;
    height: calc(100vh - 235px);
  }
}
</style>
