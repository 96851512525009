<template>
  <div class="content">
    <common-header :data="['home', 'applications', 'followUps']">
      <template>
        <div class="input-group">
          <multiselect
            v-model="selectedEmployee"
            :options="employees"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select Employee"
            label="name"
            track-by="name"
            :preselect-first="true"
            selectLabel=""
            deselectLabel=""
            class="small-dense-multiselect"
          >
          </multiselect>

          <button class="btn btn-secondary" type="button" @click="reset">
            <strong>Reset</strong>
          </button>
        </div>
      </template>
    </common-header>
    <div class="container-fluid">
      <div class="row mx-0">
        <div class="col-lg-8 col-sm-12 px-0 pr-lg-3 mb-3 mb-lg-0">
          <div class="row mx-0 mb-2 align-items-center justify-content-between">
            <div class="my-1">
              <div class="summary-box form-inline">
                <div
                  v-for="monthWidget in monthWidgets()"
                  class="summary-box-item my-0"
                  :key="monthWidget.month"
                  :class="{ selected: monthWidget.selected }"
                  :style="generateRandomBackgroundColor()"
                  @click="filterFollowUps(monthWidget.month)"
                >
                  <div class="summary-box-title">{{ monthWidget.month }}</div>
                  <div class="summary-box-body">{{ monthWidget.count }}</div>
                </div>
              </div>
            </div>
            <div class="ml-auto my-1">
              <div class="d-flex align-items-center">
                <div>
                  <button
                    title="Create new followup"
                    v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
                    class="btn btn-cp mb-0"
                    type="button"
                    data-toggle="modal"
                    @click="$refs.eventModal.show()"
                  >
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
                <select
                  class="ml-1 form-control select-cp"
                  v-model="pageSize"
                  @change="onPageSizeChanged($event)"
                >
                  <option
                    v-for="size in pageSizes"
                    :key="size"
                    :value="size"
                    :selected="pageSize === size"
                  >
                    {{ size }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <ag-grid-vue
            ref="agGrid"
            :gridOptions="gridOptions"
            :rowData="followUps"
            class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
            :key="pageSize"
            @gridReady="onGridReady"
          ></ag-grid-vue>
        </div>
        <div class="col-lg-4 px-0 card-height">
          <div v-if="getPendingFollowUps().length">
            <div
              class="mb-2 border rounded p-2 followups"
              v-for="(followUp, index) in getPendingFollowUps()"
              :key="index"
            >
              <div v-if="followUp.applicantData" class="row px-1">
                <div class="col-6">
                  <h6 class="card-title mb-0 mt-1">
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'applicantprofile',
                        params: { slug: followUp.applicantData.slug },
                      }"
                    >
                      <i
                        title="View Profile"
                        class="fa fa-user-circle-o"
                        aria-hidden="true"
                        style="color: #8f1452"
                      ></i>
                    </router-link>
                    <strong>
                      {{ followUp.applicantData.first_name }}
                      {{ followUp.applicantData.last_name }}
                    </strong>
                  </h6>
                  <div class="">
                    <i
                      :title="`Created By ${followUp.userDetails.first_name} ${followUp.userDetails.last_name}`"
                      class="fa fa-info-circle"
                      aria-hidden="true"
                      style="color: #8f1452"
                    ></i>
                    <span class="mb-0 text-muted pt-1" style="font-size: 14px">
                      {{ formatDate(followUp.schedule) }}
                    </span>
                  </div>
                </div>
                <div class="col-6 text-right text-break">
                  <span
                    class="badge mb-2"
                    :class="{
                      'badge-outline-success': followUp.active_status === 1,
                      'badge-outline-danger': followUp.active_status === 0,
                    }"
                  >
                    {{ followUp.status }}
                  </span>
                  <h6
                    class="card-subtitle mb-0 text-muted"
                    style="font-size: 14px"
                  >
                    {{ followUp.remarks }}
                  </h6>
                </div>
              </div>
              <template v-else>
                <div
                  :class="getFollowUpStatus(followUp)"
                  class="followups pa-2"
                >
                  <p>
                    {{ followUp.status }} - {{ formatDate(followUp.schedule) }}
                  </p>
                </div>
              </template>
            </div>
          </div>
          <div v-else class="followups pa-2 text-center">
            <p>No pending followups found</p>
          </div>
        </div>
      </div>
      <b-modal
        ref="eventModal"
        title="Add Follow Up"
        size="xl"
        :hide-footer="true"
      >
        <event-modal
          :isEdit="false"
          v-on:generateFollowUp="generateFollowUp($event)"
          title="FollowUp"
        />
      </b-modal>
      <add-follow-up-modal
        ref="followUpModal"
        :applicantData="applicantInfoData"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import EventModal from "./event-modal.vue";
import AddFollowUpModal from "../../ApplicantProfile/_components/add-follow-up-modal.vue";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import Multiselect from "vue-multiselect";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  data() {
    return {
      followUps: [],
      applicantInfoData: {},
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      selectedEmployee: "",
      employees: [],
      gridOptions: null,
      selectedFilter: null,
      columnDefs: [
        {
          cellRenderer: "profile-link",
          width: 35,
          minWidth: 35,
          maxWidth: 35,
          suppressHeaderMenuButton: true,
          valueGetter: "node.id",
          headerTooltip: "Profile Info",
          tooltipValueGetter: function (params) {
            if (params.data.applicantData != undefined) {
              return "Profile of " + params.data.applicantData.first_name;
            }
            return "";
          },
          cellStyle: { textAlign: "center", padding: 0 },
        },
        {
          headerName: "Candidate Name",
          field: "applicantData",
          width: 200,
          filter: "agTextColumnFilter",
          valueGetter: function (params) {
            const firstName = params.data.applicantData.first_name;
            const lastName = params.data.applicantData.last_name;
            return firstName + " " + lastName;
          },
        },
        {
          headerName: "Followup Status",
          field: "status",
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Remarks",
          field: "remarks",
          width: 250,
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Next Scheduled FollowUp",
          field: "schedule",
          valueFormatter: this.formatDate,
          width: 200,
          filter: "agDateColumnFilter",
          filterParams: {
            inRangeInclusive: true,
            comparator: (filterDate, cellValue) => {
              const scheduleFilterObj =
                this.gridOptions.api.getFilterModel().schedule;
              const filterType = scheduleFilterObj.type;
              const formattedDate = new Date(cellValue).getTime();
              if (filterType === "equals") {
                const formattedCellValue = moment(cellValue).format("DD/MM/YY");
                const formattedFilterDate =
                  moment(filterDate).format("DD/MM/YY");
                return formattedCellValue.includes(formattedFilterDate) ? 0 : 1;
              } else if (filterType === "inRange") {
                const dateFrom = new Date(scheduleFilterObj.dateFrom).getTime();
                const dateTo = new Date(scheduleFilterObj.dateTo).getTime();
                return formattedDate > dateFrom && formattedDate < dateTo
                  ? 0
                  : 1;
              }
            },
          },
        },
        {
          headerName: "Status",
          field: "active_status",
          width: 150,
          filter: "agTextColumnFilter",
          valueGetter: (params) => {
            return params.data.active_status === 0 ? "Pending" : "Completed";
          },
        },
        {
          headerName: "Created At",
          field: "createdAt",
          valueFormatter: this.formatDate,
          width: 200,
          filter: "agDateColumnFilter",
          filterParams: {
            inRangeInclusive: true,
            comparator: (filterDate, cellValue) => {
              const scheduleFilterObj =
                this.gridOptions.api.getFilterModel().createdAt;
              const filterType = scheduleFilterObj.type;
              const formattedDate = new Date(cellValue).getTime();

              if (filterType === "equals") {
                const formattedCellValue = moment(cellValue).format("DD/MM/YY");
                const formattedFilterDate =
                  moment(filterDate).format("DD/MM/YY");
                return formattedCellValue.includes(formattedFilterDate) ? 0 : 1;
              } else if (filterType === "inRange") {
                const dateFrom = new Date(scheduleFilterObj.dateFrom).getTime();
                const dateTo = new Date(scheduleFilterObj.dateTo).getTime();
                return formattedDate > dateFrom && formattedDate < dateTo
                  ? 0
                  : 1;
              } else if (filterType === "greaterThan") {
                const dateFrom = new Date(scheduleFilterObj.dateFrom).getTime();
                return formattedDate > dateFrom ? 1 : 0;
              }
              // else if(filterType === 'lessThan')
              // {
              //   const dateFrom = new Date(scheduleFilterObj.dateFrom).getTime();
              //   return formattedDate < dateFrom ? 1 : 0;
              // }
            },
          },
        },
        {
          headerName: "Created By",
          field: "userDetails",
          width: 150,
          valueGetter: this.formatCreatedBy,
          filter: "agTextColumnFilter",
        },
      ],
    };
  },
  beforeMount() {
    this.configGridOptions();
  },
  mounted() {
    this.getFollowups({});
    this.getAllEmployees();
  },
  components: {
    EventModal,
    AddFollowUpModal,
    AgGridVue,
    Multiselect,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data.applicantData" target='_blank' :to="{ name: 'applicantprofile', params: { slug: params.data.applicantData.slug } }"><i class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT.FOLLOWUP;
    },
    ...mapGetters({
      followUpList: "$_applicants_data/fetchAllFollowUps",
      changedFollowUp: "$_applicant_profile/getFollowUpDetails",
    }),
  },
  watch: {
    followUpList(value) {
      this.followUps = value;
    },
    changedFollowUp() {
      this.getFollowups({});
    },
    selectedEmployee(value) {
      this.getFollowups({
        userId: value.id,
      });
    },
  },
  methods: {
    getFollowups(filter) {
      this.$store.dispatch("$_applicants_data/fetchAllFollowUps", filter);
    },
    configGridOptions() {
      this.gridOptions = {
        colResizeDefault: true,
        enableCellTextSelection: true,
        defaultColDef: {
          resizable: true,
          floatingFilter: true,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
          sortable: true,
        },
        enableBrowserTooltips: true,
        columnDefs: this.columnDefs,
        pagination: true,
        paginationPageSize: this.pageSize,
        paginationPageSizeSelector: false,
        context: {
          componentParent: this,
        },
      };
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    formatDate(date) {
      if (date.value === null) return null;
      else if (date.value) return moment(date.value).format("llll");
      else return moment(date).format("llll");
    },
    formatCreatedBy(params) {
      const user = params.data.userDetails;
      return `${user.first_name} ${user.last_name}`;
    },
    generateRandomBackgroundColor() {
      const minBrightness = 100;
      const r = Math.floor(
        minBrightness + Math.random() * (255 - minBrightness)
      );
      const g = Math.floor(
        minBrightness + Math.random() * (255 - minBrightness)
      );
      const b = Math.floor(
        minBrightness + Math.random() * (255 - minBrightness)
      );
      return `background-color: rgba(${r},${g},${b},.6)`;
      //random color will be freshly served
    },
    monthWidgets() {
      const today = moment().startOf("day");
      const lastWeekStart = moment().startOf("week").subtract(1, "week");
      const lastWeekEnd = moment().startOf("week");
      const lastMonthStart = moment().startOf("month").subtract(1, "month");
      const lastMonthEnd = moment().startOf("month");
      const lastYearStart = moment().startOf("year").subtract(1, "year");
      const lastYearEnd = moment().startOf("year");

      const countByCondition = (startDate, endDate) => {
        return this.followUps.filter((followUp) =>
          moment(followUp.createdAt).isBetween(startDate, endDate, null, "[]")
        ).length;
      };

      const todayCount = countByCondition(today, moment(today).endOf("day"));
      const lastWeekCount = countByCondition(lastWeekStart, lastWeekEnd);
      const lastMonthCount = countByCondition(lastMonthStart, lastMonthEnd);
      const lastYearCount = countByCondition(lastYearStart, lastYearEnd);

      return [
        {
          month: "Today",
          count: todayCount,
          selected: this.selectedFilter === "Today",
        },
        {
          month: "Last Week",
          count: lastWeekCount,
          selected: this.selectedFilter === "Last Week",
        },
        {
          month: "Last Month",
          count: lastMonthCount,
          selected: this.selectedFilter === "Last Month",
        },
        {
          month: "Last Year",
          count: lastYearCount,
          selected: this.selectedFilter === "Last Year",
        },
      ];
    },
    getPendingFollowUps() {
      let followUps = this.followUps.filter(
        (followUp) => followUp.active_status === 0
      );
      followUps.sort((a, b) => new Date(a.schedule) - new Date(b.schedule));
      return followUps;
    },
    getFollowUpStatus(followUp) {
      const currentDate = moment().startOf("day");
      const scheduledDate = moment(followUp.schedule).startOf("day");
      if (scheduledDate.isBefore(currentDate)) return "past-schedule";
      else if (scheduledDate.isSame(currentDate)) return "today-schedule";
      else return "future-schedule";
    },
    generateFollowUp(applicant) {
      this.applicantInfoData = applicant;
      this.$store.dispatch("$_applicant_profile/setIDInState", applicant.id);
      this.$store.dispatch(
        "$_applicant_profile/getFollowUpDetails",
        applicant.id
      );
      this.$refs["eventModal"].hide();
      $("#addFollowUp").modal("show");
    },
    onPageSizeChanged() {
      const rowCount = this.pageSize;
      this.gridOptions.paginationPageSize = rowCount;
    },
    getAllEmployees() {
      axios
        .get("/users")
        .then((response) => {
          const users = response.data;
          this.employees = users.map((user) => ({
            id: user.user_id,
            name: user.first_name + " " + user.last_name,
          }));
        })
        .catch((error) => {
          console.error("Error fetching employees:", error);
        });
    },
    filterFollowUps(month) {
      if (month === "Today") {
        this.gridOptions.api.setFilterModel({
          createdAt: {
            filterType: "date",
            type: "equals",
            dateFrom: moment().format("YYYY-MM-DD HH:mm:ss"),
            dateTo: null,
          },
        });
      } else if (month === "Last Week") {
        const lastWeekStart = moment().startOf("week").subtract(1, "week");
        const lastWeekEnd = moment().startOf("week");
        this.gridOptions.api.setFilterModel({
          createdAt: {
            filterType: "date",
            type: "inRange",
            dateFrom: moment(lastWeekStart).format("YYYY-MM-DD HH:mm:ss"),
            dateTo: moment(lastWeekEnd).format("YYYY-MM-DD HH:mm:ss"),
          },
        });
      } else if (month === "Last Month") {
        const lastMonthStart = moment().startOf("month").subtract(1, "month");
        const lastMonthEnd = moment().startOf("month");
        this.gridOptions.api.setFilterModel({
          createdAt: {
            filterType: "date",
            type: "inRange",
            dateFrom: moment(lastMonthStart.toDate()).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateTo: moment(lastMonthEnd.toDate()).format("YYYY-MM-DD HH:mm:ss"),
          },
        });
      } else if (month === "Last Year") {
        const lastYearStart = moment().startOf("year").subtract(1, "year");
        const lastYearEnd = moment().startOf("year");
        this.gridOptions.api.setFilterModel({
          createdAt: {
            filterType: "date",
            type: "inRange",
            dateFrom: moment(lastYearStart.toDate()).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            dateTo: moment(lastYearEnd.toDate()).format("YYYY-MM-DD HH:mm:ss"),
          },
        });
      }
      this.selectedFilter = month;
    },
    reset() {
      this.selectedEmployee = "";
      this.gridOptions.api.setFilterModel(null);
    },
    onGridReady(params) {
      if (params.api) {
        this.gridOptions.api = params.api;
      }
    },
  },
};
</script>
<style scoped>
.followups {
  border: 1px solid #dee2e6;
  /* box-shadow: 0px 0px 5px #dedcdc; */
  padding: 5px;
  color: black;
  border-radius: 5px;
}

.past-schedule {
  background-color: #ee4b2b;
}

.today-schedule {
  background-color: #32cd32;
}

.future-schedule {
  background-color: rgb(207, 204, 204);
}

.followups:hover {
  box-shadow: 5px 5px 10px #dedcdc;
}

.summary-box {
  display: flex;
  flex-direction: row;
  flex-basis: 10in;
  justify-content: right;
}

.summary-box > * {
  flex: 0 0 10%;
}

.summary-box-item {
  color: black;
  display: inline-block;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.summary-box-title {
  background-color: #e9cfcf99;
  font-size: 14px;
  /* font-weight: bold; */
  width: auto;
  white-space: nowrap;
  line-height: 1.1;
  text-transform: uppercase;
  padding: 1px 5px;
}

.summary-box-body {
  vertical-align: middle;
  font-size: 18px;
  /* font-weight: bold; */
  height: 20px;
  line-height: 20px;
  justify-content: center;
}

.selected {
  background-color: grey;
}

.small-dense-multiselect {
  width: 260px;
  line-height: 1.2;
  margin-right: 5px;
}

.ag-grid-style {
  min-height: 500px;
  height: calc(100vh - 190px);
}
.card-height {
  height: calc(100vh - 133px);
  overflow-y: auto;
  overflow-x: hidden;
}
.badge-outline-success {
  color: #28a745;
  border: 1px solid #28a745;
  background-color: transparent;
}

.badge-outline-danger {
  color: #dc3545;
  border: 1px solid #dc3545;
  background-color: transparent;
}
</style>
