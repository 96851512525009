<template>
  <div class="content">
    <common-header :data="['home', 'campusDrive', 'moodleConfigurations']">
      <div v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })">
        <button
          type="button"
          class="btn btn-cp"
          @click="openMoodleInstanceModal"
        >
          <i class="fa fa-plus" aria-hidden="true"></i> Add Instance
        </button>
      </div>
    </common-header>
    <div class="container-fluid d-flex flex-column">
      <div
        class="card"
        style="min-width: 100%"
        v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
      >
        <div class="table-responsive mb-0">
          <table class="table" aria-describedby="moodleTable">
            <thead>
              <tr>
                <th>Label</th>
                <th>Url</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(instance, index) in moodleInstances" :key="index">
                <td
                  class="font-weight-bold"
                  :class="{ 'border-top-0': index == 0 }"
                >
                  {{ instance.label }}
                </td>
                <td :class="{ 'border-top-0': index == 0 }" class="custom-url">
                  {{ instance.url }}
                </td>
                <td :class="{ 'border-top-0': index == 0 }">
                  <span class="d-none d-md-inline">
                    <button
                      v-if="
                        hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })
                      "
                      class="btn"
                      @click="openUpdateMoodleModal(instance)"
                    >
                      <b-icon icon="pencil-fill" variant="primary"></b-icon>
                    </button>
                    <button
                      v-if="
                        hasAccess({ permissions: [BASE_PERMISSION.DELETE] })
                      "
                      class="btn"
                      @click="openConfirmDeleteModal(instance.id)"
                    >
                      <b-icon icon="trash" variant="danger"></b-icon>
                    </button>
                  </span>
                  <span class="d-md-none">
                    <button
                      v-if="
                        hasAccess({ permissions: [BASE_PERMISSION.DELETE] }) ||
                        hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })
                      "
                      class="btn dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <b-icon
                        icon="three-dots-vertical"
                        style="color: #8f1452"
                      ></b-icon>
                    </button>
                    <div class="dropdown-menu">
                      <button
                        v-if="
                          hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })
                        "
                        class="btn mr-1 dropdown-item"
                        @click="openUpdateMoodleModal(instance)"
                      >
                        <b-icon icon="pencil-fill" variant="primary"></b-icon>
                        Edit
                      </button>
                      <button
                        v-if="
                          hasAccess({ permissions: [BASE_PERMISSION.DELETE] })
                        "
                        class="btn mr-1 dropdown-item"
                        @click="openConfirmDeleteModal(instance.id)"
                      >
                        <b-icon icon="trash" variant="danger"></b-icon> Delete
                      </button>
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Add Moodle Instance Modal -->
    <add-moodle-instance-modal
      @submitted="moodleInstanceChanged"
    ></add-moodle-instance-modal>

    <!-- Update Moodle Instance Modal -->
    <update-moodle-instance-modal
      @submitted="moodleInstanceChanged"
      :data="{ ...selectedInstance }"
    ></update-moodle-instance-modal>

    <!-- Confirm Delete Modal -->
    <b-modal
      id="confirm-delete-modal"
      ref="confirmDeleteModal"
      title="Confirm Delete"
      @ok.prevent="confirmDelete"
    >
      <div class="col-12 pr-sm-2 mb-3">
        <p>Are you sure you want to delete this instance?</p>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()"
          >Cancel</b-button
        >
        <b-button size="sm" @click="ok()" class="btn btn-cp ml-2"
          >Delete</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import api from "../_api/index";
import AddMoodleInstanceModal from "./add-moodle-instance-modal.vue";
import UpdateMoodleInstanceModal from "./update-moodle-instance-modal.vue";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

/**
 * @memberof module:campusRecruitment
 * @namespace components.campus_recruitments
 */
export default {
  /**
   * @memberof module:campusRecruitment.components.campus_recruitments
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      moodleInstances: [],
      selectedInstance: {
        label: "",
        url: "",
        ws_token: "",
      },
      instanceToDelete: null,
    };
  },
  components: {
    AddMoodleInstanceModal,
    UpdateMoodleInstanceModal,
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"]["MOODLE"];
    },
  },
  watch: {},
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    openMoodleInstanceModal() {
      $("#addMoodleInstance").modal("show");
    },
    openUpdateMoodleModal(data) {
      this.selectedInstance = data;
      $("#updateMoodleInstance").modal("show");
    },
    openConfirmDeleteModal(id) {
      this.instanceToDelete = id;
      this.$refs.confirmDeleteModal.show();
    },
    confirmDelete() {
      if (this.instanceToDelete) {
        api.deleteMoodleInstance(this.instanceToDelete).then(() => {
          this.$refs.confirmDeleteModal.hide();
          this.moodleInstanceChanged();
          this.instanceToDelete = null;
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Instance has been deleted!",
            },
            {
              root: true,
            }
          );
        });
      }
    },
    moodleInstanceChanged() {
      this.selectedInstance = {
        label: "",
        url: "",
        ws_token: "",
      };
      api.getAllMoodleInstances().then((res) => {
        this.moodleInstances = res.data;
      });
    },
    getMoodleInstances() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      api.getAllMoodleInstances().then((res) => {
        this.moodleInstances = res.data;
      });
    },
  },
  beforeMount() {
    this.getMoodleInstances();
  },
};
</script>
<style scoped>
td {
  vertical-align: middle !important;
}

.dropdown-toggle::after {
  display: none;
}
</style>
