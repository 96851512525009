<template>
  <div v-if="data?.options">
    <b-modal
      v-model="modalOpen"
      :title="eventModalData.modalTitle"
      :size="eventModalData.modalSize"
      hide-footer
      hide-header
    >
      <div class="d-flex active-purple active-purple-2">
        <div class="d-inline-block" style="width: 100%">
          <input
            ref="textInput"
            class="form-control"
            v-model="searchQuery"
            tabindex="-1"
            type="search"
            placeholder="search"
            aria-label="Search"
            autocomplete="off"
            @input="updateList"
            @keydown="handleKeyPress"
          />
        </div>
        <div class="d-inline-block">
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>
      </div>
      <div
        class="list-group"
        style="width: 100%"
        v-if="searchResults.length > 0"
      >
        <div
          v-for="(result, index) in searchResults"
          :key="result.key"
          :class="{
            'list-group-item': true,
            'selected-result': index === selectedIndex,
          }"
          @click="closeModal"
        >
          <router-link
            v-if="result.type == 'page'"
            class="link"
            :to="{ name: result.name }"
          >
            {{ result.key }}
            <span class="pl-2 parent" v-if="result.parent"
              >[{{ result.parent }}]</span
            >
          </router-link>
          <div v-else>
            {{ result.key }}
            <span v-if="result.parent">{{ result.parent }}</span>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  data() {
    return {
      modalOpen: false,
      searchQuery: "",
      selectedIndex: -1,
      searchResults: [],
      eventModalData: {
        modalTitle: "Search",
        modalSize: "md",
        isEdit: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      data: "user/commonHeader",
    }),
    searchableData() {
      const options = this.data.options.home.options;
      const filterCriteria = (key, value) => {
        return value?.permission && value?.showInSearch;
      };

      const filtered = Object.keys(options)
        .filter((key) => filterCriteria(key, options[key]))
        .reduce((result, key) => {
          const innerOptions = options[key].options;

          const filteredInnerOptions = innerOptions
            ? Object.keys(innerOptions)
                .filter((innerKey) =>
                  filterCriteria(innerKey, innerOptions[innerKey])
                )
                .reduce((innerResult, innerKey) => {
                  innerResult[innerKey] = innerOptions[innerKey];
                  return innerResult;
                }, {})
            : {};

          result[key] = {
            ...options[key],
            options: filteredInnerOptions,
          };
          return result;
        }, {});

      return filtered;
    },
  },
  watch: {
    searchResults(newResults) {
      if (newResults.length > 0) {
        this.selectedIndex = 0;
      } else {
        this.selectedIndex = -1;
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
    Vue.prototype.$openSearchbar = this.openModal;
  },
  methods: {
    openModal() {
      this.modalOpen = true;
      setTimeout(() => {
        this.$refs.textInput.focus();
      }, 0);
    },
    closeModal() {
      this.modalOpen = false;
      this.clear();
    },
    searchResults2() {
      if (!this.searchQuery) return [];

      const searchQuery = this.searchQuery.toLowerCase();
      const searchResults = [];

      const isMatchFound = (aliases) => {
        return aliases.some((alias) =>
          alias.toLowerCase().includes(searchQuery)
        );
      };

      const processOption = (option, parentKey) => {
        const filteredObj = { ...option, parent: parentKey };
        searchResults.push(filteredObj);
      };

      for (const key in this.searchableData) {
        const dataItem = this.searchableData[key];
        const parentFound = isMatchFound(dataItem.alias || []);

        for (const key2 in dataItem.options) {
          const option = dataItem.options[key2];

          if (parentFound || isMatchFound(option.alias || [])) {
            processOption(option, dataItem.key);
          }
        }
      }
      return searchResults;
    },
    handleKeyDown(event) {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        this.openModal();
      }
    },
    handleKeyPress(event) {
      if (event.key === "Enter") {
        this.handleEnterKey();
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        this.handleArrowDown();
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        this.handleArrowUp();
      }
    },

    handleArrowDown() {
      if (this.selectedIndex < this.searchResults.length - 1) {
        this.selectedIndex += 1;
        const selectedResult = this.searchResults[this.selectedIndex];
        this.searchQuery = selectedResult.key;
      } else {
        this.selectedIndex = 0;
      }
    },

    handleArrowUp() {
      if (this.selectedIndex > 0) {
        this.selectedIndex -= 1;
        const selectedResult = this.searchResults[this.selectedIndex];
        this.searchQuery = selectedResult.key;
      } else {
        this.selectedIndex = this.searchResults.length - 1;
      }
    },

    handleEnterKey() {
      if (this.selectedIndex >= 0 && this.searchResults.length > 0) {
        const selectedResult = this.searchResults[this.selectedIndex];
        if (selectedResult.type === "page") {
          this.$router.push({ name: selectedResult.name });
        }
        this.closeModal();
      }
    },
    updateList() {
      this.searchResults = this.searchResults2();
    },
    clear() {
      this.searchQuery = "";
      this.searchResults = [];
      this.selectedIndex = -1;
    },
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
};
</script>

<style scoped>
.active-purple-2 input.form-control[type="search"]:focus:not([readonly]) {
  border-bottom: 1px solid #8f1452;
  box-shadow: 0 1px 0 0 #8f1452;
}
.active-purple input.form-control[type="search"] {
  border: none;
  border-bottom: 1px solid #ced4da;
  box-shadow: 0 1px 0 0 #ced4da;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}

.form-control {
  border-radius: 0;
}

.fa-search {
  margin-top: 10px;
}

.active-purple .fa,
.active-purple-2 .fa {
  color: #8f1452;
}

.list-group {
  margin-top: 5px;
  position: absolute;
  background: white;
  box-shadow: 0 1px 0 0 #ced4da;
  z-index: 2;
  overflow-x: auto;
  max-height: 500px;
  left: 0;
}

.fa-user-circle-o {
  color: white;
}
.parent {
  color: #8f1452;
}
.list-group-item:hover {
  background-color: #803059;
}

.list-group-item:hover .parent,
.list-group-item:hover .link,
.selected-result.list-group-item .parent,
.selected-result.list-group-item .link {
  color: white;
}

.link {
  display: block;
  height: 100%;
  width: 100%;
}
.selected-result {
  background-color: #b2628b;
}
</style>
