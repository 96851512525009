const SET_ERROR_LOGS = (state, data) => {
  state.errorLogs = data;
};
const SET_ERROR_FILE = (state, data) => {
  state.errorFile = data;
};
export default {
  SET_ERROR_LOGS,
  SET_ERROR_FILE,
};
