<template>
  <div v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })">
    <router-link
      v-if="params.data"
      target="_blank"
      :to="{ name: 'editApplicantData', params: { slug: params.data.slug } }"
      ><i
        title="Edit Applicant Profile"
        class="fa fa-edit"
        aria-hidden="true"
        style="color: #832e5b"
      ></i
    ></router-link>
  </div>
</template>

<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  data() {
    return {};
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
  },
};
</script>
