import PERMISSIONS from "../Common/permissions";
import _managejob from "./index";

export default {
  path: "jobs",
  component: _managejob,
  name: "managejobs",
  meta: {
    title: "Manage Jobs",
    user: "employee",
    permissions: [PERMISSIONS.JOB.MENU],
    checkAll: true,
  },
};
