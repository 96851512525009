<template>
  <div class="main-app">
    <home-header-section v-if="!isPlainRoute"></home-header-section>
    <div id="maincontent">
      <home-container></home-container>
    </div>
    <footer-section v-if="!isPlainRoute"></footer-section>
    <application-status-modal></application-status-modal>
  </div>
</template>

<script>
import HomeContainer from "../modules/Home/index.vue";
import ApplicationStatusModal from "../modules/Common/_components/application-status-modal.vue";
import HomeHeaderSection from "../modules/Common/_components/home-header.vue";
import FooterSection from "../modules/Common/_components/footer-section.vue";
import store from "../modules/Home/_store";
export default {
  name: "Home",
  data() {
    return {
      plainRoutes: [
        "login",
        "applicantRegistration",
        "applicantForgotPasswordRequest",
        "applicantForgotPassword",
        "recruiterResetPassword",
        "resetRecruiterPassword",
      ],
    };
  },
  computed: {
    isPlainRoute() {
      return this.plainRoutes.includes(this.$route.name);
    },
  },
  components: {
    HomeContainer,
    ApplicationStatusModal,
    HomeHeaderSection,
    FooterSection,
  },
  created() {
    const STORE_KEY = "$_home";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>

<style scoped>
#maincontent {
  margin-top: 0px;
  transition: all 0.4s ease 0s;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>
