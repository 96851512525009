/**
 * @memberof module:Report.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches session report list from store
 */
const sessionReportList = (state) => state.sessionReportList;

/**
 * @memberof module:Report.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches campus application count for report
 */
const sessionApplicationCampusCount = (state) =>
  state.sessionApplicationCampusCount;

/**
 * @memberof module:Report.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches entitty application count for report
 */
const sessionApplicationEntityCount = (state) =>
  state.sessionApplicationEntityCount;

/**
 * @memberof module:Report
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  sessionReportList,
  sessionApplicationCampusCount,
  sessionApplicationEntityCount,
};
