<template>
  <div class="modal fade" tabindex="-1" id="copy-url-modal" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Recruitment Drive added successfully</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <h4>Kindly copy down the URL to register!</h4>
            <div style="display: flex">
              <label
                style="
                  border: 1px solid #ccc;
                  border-radius: 4px;
                  padding: 5px 12px;
                  font-size: 14px;
                "
                >{{ url }}</label
              >
              <button
                type="button"
                class="btn btn-info refres`h-btn btn-cp ml-2 copy-url-btn"
                @click="emitEvent('copyUrl')"
              >
                <i
                  title="Copy Url"
                  class="fa fa-copy"
                  style="padding-left: 10px; font-size: 18px"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    emitEvent(event) {
      this.$emit("actionPerformed", event);
    },
  },
  mounted() {
    $("#copy-url-modal").on("hidden.bs.modal", () => {
      this.$emit("close", "Copy Url Modal");
    });
  },
  beforeDestroy() {
    $("#copy-url-modal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
