<template>
  <div class="commonPadding">
    <div class="addDetailsContainer" v-if="!isForEditData">
      <div class="addDetailsHeader">
        <div class="addDetailsImage">
          <b-icon icon="file-earmark" class="h3" aria-hidden="true"></b-icon>
        </div>
        <div class="addDetailItem">
          <div class="addDetailItemTitle">Add work Experience</div>
          <div class="addDetailItemSubtitle">
            Your previous internship / full time experiences
          </div>
        </div>
      </div>
      <button
        v-b-modal.add-job-modal
        type="button"
        @click="addJob"
        class="btn btn-cp ml-2"
      >
        Add
      </button>
    </div>
    <delete-modal
      :modal-id="'deleteModalJobs'"
      item="Work Experience"
      :on-delete="removeJob"
      :item-id="jobId"
    ></delete-modal>
    <div
      v-if="
        isApplicantLoggedIn &&
        !isApplicantProfile &&
        applicantData.jobDetails.length > 0
      "
      class="addDetailItemSubtitle mb-4"
      style="color: #d4205a"
    >
      Click on the checkbox to select the work experiences you want to consider
      for the job application.
    </div>
    <div class="table-responsive" v-if="applicantData.jobDetails.length > 0">
      <table
        class="table table-striped table-hover"
        aria-describedby="Job Details Table"
      >
        <thead>
          <tr>
            <th v-if="isApplicantLoggedIn && !isApplicantProfile"></th>
            <th>#</th>
            <th>Title</th>
            <th>Description</th>
            <th>Company Name</th>
            <th>Company Location</th>
            <th>Company Website</th>
            <th>Type</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th v-if="!isForEditData">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(job, index) in applicantData.jobDetails" :key="index">
            <td v-if="isApplicantLoggedIn && !isApplicantProfile">
              <input
                style="margin-top: 6px"
                type="checkbox"
                class="form-check-input mx-auto"
                :checked="isChecked(job.id)"
                @change="handleCheckboxChange(job.id)"
              />
            </td>
            <td>{{ index + 1 }}</td>
            <td
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="job.title.length > 10 && job.title"
            >
              {{
                job.title.length > 10
                  ? job.title.slice(0, 10) + "..."
                  : job.title
              }}
            </td>
            <td
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="job.description.length > 10 && job.description"
            >
              {{
                job.description.length > 10
                  ? job.description.slice(0, 10) + " ..."
                  : job.description
              }}
            </td>
            <td
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="job.companyName.length > 10 && job.companyName"
            >
              {{
                job.companyName.length > 10
                  ? job.companyName.slice(0, 10) + "..."
                  : job.companyName
              }}
            </td>
            <td
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :title="job.companyLocation.length > 10 && job.companyLocation"
            >
              {{
                job.companyLocation.length > 10
                  ? job.companyLocation.slice(0, 10) + "..."
                  : job.companyLocation
              }}
            </td>
            <td>
              <safe-link
                v-if="job.companyWebsite && isValidUrl(job.companyWebsite)"
                :link="job.companyWebsite"
              >
                <b-icon icon="link" variant="success"></b-icon>
              </safe-link>

              <span v-else>NA</span>
            </td>
            <td>{{ job.type === "job" ? "Full-Time" : "Internship" }}</td>
            <td>{{ job.startDate }}</td>
            <td>{{ job.endDate }}</td>
            <td v-if="!isForEditData" class="d-flex">
              <button
                type="button"
                v-b-modal.add-job-modal
                class="btn btn-sm"
                title="Edit"
                @click="editJob(index)"
              >
                <b-icon icon="pencil-square" variant="primary"></b-icon>
              </button>

              <button
                class="btn btn-sm"
                id="delete_job_icon"
                href="#"
                type="button"
                data-toggle="modal"
                data-target="#deleteModalJobs"
                data-backdrop="static"
                data-keyboard="false"
                @click="setjobId(isApplicantLoggedIn ? job.id : index)"
              >
                <b-icon icon="trash" variant="primary"></b-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h5
      class="text-center"
      v-if="isForEditData && applicantData.jobDetails.length === 0"
    >
      No work experience added yet.
    </h5>
    <b-modal
      id="add-job-modal"
      no-close-on-backdrop
      ref="modal"
      :title="job.headline"
      size="lg"
      scrollable
      @ok.prevent="submitJob"
    >
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont" htmlFor="jobTitle">Job Title </label>
        <input
          name="job_title"
          v-validate="'required|min:3|max:50'"
          :class="{ invalid: errors.first('job_title') }"
          type="text"
          placeholder="Enter Job Title"
          class="form-control"
          v-model="newJob.title"
        />
        <span
          v-if="errors.first('job_title')"
          class="valid-feedback alert-danger"
          >{{ errors.first("job_title") }}</span
        >
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont" htmlFor="contribution"
          >Describe your Contribution / Learning
        </label>
        <textarea
          name="job_description"
          v-validate="'required|min:20|max:200'"
          :class="{ invalid: errors.first('job_description') }"
          placeholder="What was the project assigned? What was your contribution to the project?"
          required
          class="form-control"
          v-model="newJob.description"
        ></textarea>
        <span
          v-if="errors.first('job_description')"
          class="valid-feedback alert-danger"
          >{{ errors.first("job_description") }}</span
        >
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont" htmlFor="companyName"
          >Company Name
        </label>
        <input
          name="company_name"
          v-validate="'required|min:3|max:50'"
          :class="{ invalid: errors.first('company_name') }"
          type="text"
          placeholder="Enter Company Name"
          required
          class="form-control"
          v-model="newJob.companyName"
        />
        <span
          v-if="errors.first('company_name')"
          class="valid-feedback alert-danger"
          >{{ errors.first("company_name") }}</span
        >
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont" htmlFor="location">Location </label>
        <input
          name="company_location"
          v-validate="'required|min:3|max:50'"
          :class="{ invalid: errors.first('company_location') }"
          type="text"
          placeholder="Enter Location Place / Remote"
          class="form-control"
          v-model="newJob.companyLocation"
        />
        <span
          v-if="errors.first('company_location')"
          class="valid-feedback alert-danger"
          >{{ errors.first("company_location") }}</span
        >
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="modalfont" htmlFor="companyContact"
          >Company Website / LinkedIn
        </label>
        <input
          name="company_website"
          v-validate="{ max: 100, url: { require_protocol: true } }"
          type="text"
          placeholder="Enter Company website"
          class="form-control"
          v-model="newJob.companyWebsite"
        />
        <span
          v-if="errors.first('company_website')"
          class="valid-feedback alert-danger"
          >{{ errors.first("company_website") }}</span
        >
      </div>
      <div class="col-12 col-sm-6 pr-2 mb-4">
        <label class="required" htmlFor="jobType"> Job Type </label>
        <select
          name="job_type"
          v-validate="'required|min:3|max:100'"
          :class="{ invalid: errors.first('job_type') }"
          class="form-control"
          v-model="newJob.type"
        >
          <option value="job">Full-Time</option>
          <option value="internship">Internship</option>
        </select>

        <span
          v-if="errors.first('job_type')"
          class="valid-feedback alert-danger"
          >{{ errors.first("job_type") }}</span
        >
      </div>
      <div class="d-flex">
        <div class="col-6 col-sm-6 pr-2 mb-4">
          <label class="required modalfont" htmlFor="startDate"
            >Start Date
          </label>
          <input
            type="date"
            name="job_start_date"
            ref="job_start_date"
            placeholder="eg: Argusoft India"
            v-validate="'required'"
            :class="{ invalid: errors.first('job_start_date') }"
            class="form-control"
            data-vv-as="Start Date"
            v-model="newJob.startDate"
          />
          <span
            v-if="errors.first('job_start_date')"
            class="valid-feedback-sm alert-danger"
            >{{ errors.first("job_start_date") }}</span
          >
        </div>
        <div class="col-6 col-sm-6 pr-2 mb-4">
          <label class="required modalfont" htmlFor="endDate">End Date </label>
          <input
            type="date"
            name="job_end_date"
            placeholder="eg: Argusoft India"
            v-validate="'required|after:job_start_date'"
            :class="{ invalid: errors.first('job_end_date') }"
            class="form-control"
            data-vv-as="End Date"
            v-model="newJob.endDate"
          />
          <span
            v-if="errors.first('job_end_date')"
            class="valid-feedback-sm alert-danger"
            >{{ errors.first("job_end_date") }}</span
          >
        </div>
      </div>
      <template #modal-footer="{ ok }">
        <b-button size="sm" @click="ok()" class="btn btn-cp ml-2">
          {{ job.button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { Validator } from "vee-validate";
import {
  isSameAlternateNo,
  beforeDate,
} from "../../../Common/_plugin/validation";
import DeleteModal from "../delete-modal.vue";
import { isValidUrl } from "../../../Common/_utils/sanitization";

Validator.extend("sameAlternateNo", isSameAlternateNo);
Validator.extend("beforeDate", beforeDate);
export default {
  props: {
    applicantData: {
      type: Object,
      default: null,
      required: true,
    },
    skillSet: {
      type: Array,
      required: true,
    },
    isApplicantLoggedIn: {
      type: Boolean,
      required: true,
      default: true,
    },
    isApplicantProfile: {
      type: Boolean,
      default: false,
    },
    isForEditData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newJob: {
        companyName: "",
        companyWebsite: "",
        title: "",
        companyLocation: "",
        type: "",
        description: "",
        startDate: null,
        endDate: null,
        isEdit: false,
      },
      jobId: null,
      checkedJobs: [],
    };
  },
  computed: {
    job() {
      if (this.newJob.isEdit) {
        return {
          headline: "Edit Work Experience",
          button: "Edit",
        };
      } else {
        return {
          headline: "Add Work Experience",
          button: "Add",
        };
      }
    },
  },
  components: {
    "delete-modal": DeleteModal,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    if (this.isApplicantLoggedIn && !this.isApplicantProfile) {
      this.checkedJobs = this.applicantData.jobDetails.map((job) => job.id);
      this.$emit("toggle-checkbox", this.checkedJobs, "job");
    }
  },
  methods: {
    isValidUrl(url) {
      return url ? isValidUrl(url) : false;
    },
    isChecked(jobId) {
      return this.checkedJobs.includes(jobId);
    },
    handleCheckboxChange(jobId) {
      if (this.checkedJobs.includes(jobId)) {
        const idx = this.checkedJobs.indexOf(jobId);
        this.checkedJobs.splice(idx, 1);
      } else {
        this.checkedJobs.push(jobId);
      }

      this.$emit("toggle-checkbox", this.checkedJobs, "job");
    },
    setjobId(jobId) {
      this.jobId = jobId;
    },

    addJob() {
      this.newJob = {
        companyName: "",
        companyWebsite: "",
        title: "",
        companyLocation: "",
        type: "job",
        description: "",
        startDate: null,
        endDate: null,
        isEdit: false,
      };
    },
    async submitJob() {
      await this.$validator.validateAll();
      if (this.$validator.errors.items.length > 0) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Please enter valid values!",
        });
        return;
      }

      if (this.isApplicantLoggedIn) {
        const action = this.newJob.isEdit ? "updateUserJob" : "addUserJob";
        this.$store.dispatch(`$_applicant_master/${action}`, this.newJob);
      } else {
        const temp = { ...this.applicantData };
        if (this.newJob.isEdit) {
          temp.jobDetails[this.jobId] = this.newJob;
        } else {
          temp.jobDetails.push(this.newJob);
        }
        this.$emit("update:applicantData", temp);
      }

      this.$bvModal.hide("add-job-modal");
    },
    removeJob() {
      if (this.jobId !== null) {
        if (this.isApplicantLoggedIn) {
          this.$store.dispatch("$_applicant_master/deleteUserJob", this.jobId);
        } else {
          const temp = { ...this.applicantData };
          temp.jobDetails.splice(this.jobId, 1);
          this.$emit("update:applicantData", temp);
          this.jobId = null;
        }
      }
    },
    editJob(index) {
      this.newJob = { ...this.applicantData.jobDetails[index] };
      this.newJob.isEdit = true;
      this.jobId = index;
    },
  },
};
</script>

<style scoped>
@import "../tabStyles.css";
</style>
