<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("$_applicant_master/fetchApplicantApplications");
    this.$store.dispatch("$_applicant_master/fetchApplicantLinkRequests");
    this.$store.dispatch("$_home/fetchActiveCategoriesWithJobs");
  },
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
    };
  },
};
</script>
