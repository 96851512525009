<template>
  <tbody>
    <tr>
      <td class="review-icon">
        <div class="dropdown">
          <a class="btn dropdown-toggle"
            ><i class="fa fa-wrench" aria-hidden="true"></i
          ></a>
          <div class="dropdown-content">
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
              class=""
              id="edit_review_icon"
              href="#"
              role="button"
              data-toggle="modal"
              data-target="#editReviewModal"
              data-backdrop="static"
              data-keyboard="false"
              @click="editReview"
            >
              <i class="fa fa-pencil edit-icon" aria-hidden="true"></i> Edit
            </a>
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.DELETE] })"
              class=""
              id="delete_review_icon"
              href="#"
              role="button"
              data-toggle="modal"
              data-target="#deleteModal"
              data-backdrop="static"
              data-keyboard="false"
              @click="deleteReview"
            >
              <i class="fa fa-trash-o delete-icon" aria-hidden="true"></i>
              Delete
            </a>
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
              class="status-icon"
              id="update_review_status_icon"
              href="#"
              role="button"
              @click="updateReviewStatus"
            >
              <i
                class="fa fa-circle"
                :style="[
                  review.status === 'active'
                    ? { color: 'green' }
                    : { color: 'red' },
                ]"
                aria-hidden="true"
              ></i>
              {{ review.status === "active" ? "Deactivate" : "Activate" }}
              Review
            </a>
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
              class=""
              id="copy_review_icon"
              href="#"
              role="button"
              data-toggle="modal"
              data-target="#editReviewModal"
              data-backdrop="static"
              data-keyboard="false"
              @click="createDuplicateReview"
            >
              <i class="fa fa-clone copy-icon" aria-hidden="true"></i> Create
              Duplicate
            </a>
          </div>
        </div>
      </td>
      <td
        v-if="review.review_name.length > 15"
        v-tooltip.top="review.review_name"
      >
        {{ review.review_name }}
      </td>
      <td v-else>
        {{ review.review_name }}
      </td>
      <td>{{ review.status }}</td>
    </tr>
  </tbody>
</template>

<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

/**
 * @memberof module:ManageReview
 * @namespace components.review_list_element
 */
export default {
  props: ["review", "reviewCategoryId"],
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.REVIEW;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:ManageReview.components.review_list_element
     * @description method - dispatch request to delete review
     */
    deleteReview() {
      this.$store.dispatch("$_manage_reviews/deleteItem", {
        reviewCategoryId: this.reviewCategoryId,
        reviewId: this.review.id,
        type: "review",
      });
    },
    /**
     * @memberof module:ManageReview.components.review_list_element
     * @description method - dispatch request to delete review
     */
    editReview() {
      this.$store.dispatch("$_manage_reviews/editReviewModal", {
        reviewInfo: this.review,
        isEdit: true,
      });
    },
    /**
     * @memberof module:ManageReview.components.review_list_element
     * @description method - dispatch request to update review status
     */
    updateReviewStatus() {
      let updatedReviewInfo = { ...this.review };
      if (this.review.status === "active") {
        updatedReviewInfo.status = "inactive";
      } else {
        updatedReviewInfo.status = "active";
      }
      delete updatedReviewInfo.createdAt;
      delete updatedReviewInfo.updatedAt;
      delete updatedReviewInfo.slug;
      this.$store.dispatch(
        "$_manage_reviews/editReviewStatus",
        updatedReviewInfo
      );
    },
    /**
     * @memberof module:ManageReview.components.review_list_element
     * @description method - dispatch request to create duplicate review
     */
    createDuplicateReview() {
      this.$store.dispatch("$_manage_reviews/editReviewModal", {
        reviewInfo: this.review,
        isEdit: false,
      });
    },
  },
};
</script>

<style scoped>
td {
  text-align: center;
  /* text-align: center; */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.copy-icon {
  color: #337ab7;
}

/* Dropdown Button */
.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  position: relative;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: inherit;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: left;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: gainsboro;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
</style>
