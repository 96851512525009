<template>
  <div class="chart-class">
    <div :id="chartId"></div>
    <slot></slot>
  </div>
</template>

<script>
import Highcharts from "highcharts";
require("highcharts/modules/accessibility")(Highcharts);

export default {
  props: {
    chartId: {
      type: String,
      required: true,
    },
    chartTitle: {
      type: String,
      required: true,
    },
    dataSlices: {
      type: Array,
      required: true,
    },
    colorIdx: {
      type: Number,
      required: true,
      default: 2,
    },
  },
  methods: {
    init() {
      // Make monochrome colors
      let pieColors = function () {
        let colors = [],
          base = Highcharts.getOptions().colors[this.colorIdx],
          i;

        for (i = 0; i < 10; i += 1) {
          // Start out with a darkened base color (negative brighten), and end
          // up with a much brighter color
          colors.push(
            Highcharts.color(base)
              .brighten((i - 3) / 7)
              .get()
          );
        }
        return colors;
      }.bind(this)();

      Highcharts.chart(this.chartId, {
        chart: {
          type: "pie",
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat: "<b>{point.y}</b>",
        },
        title: {
          text: `<b>${this.chartTitle}</b>`,
        },
        subtitle: {
          text: "Click the slices to filter the below datatable.",
        },
        plotOptions: {
          turboThreshold: 2000,
          pie: {
            size: "60%",
            allowPointSelect: true,
            cursor: "pointer",
            colors: pieColors,
          },
          series: {
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b> ({point.y})",
              connectorColor: "silver",
            },
            events: {
              click: (event) => {
                this.$emit("filter-data", event);
              },
            },
          },
        },
        series: [
          {
            showInLegend: false,
            data: this.dataSlices,
          },
        ],
      });
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    dataSlices() {
      this.init();
    },
  },
};
</script>

<style scoped>
.chart-class {
  max-width: 800px;
  min-width: 100%;
  margin: 1em auto;
}
.filter-text {
  color: #666666;
  fill: #666666;
  text-align: center;
  font-size: 14px;
}
</style>
