<template>
  <div>
    <div class="content">
      <common-header :data="['home', 'manage', 'configurations']">
        <div>
          <input
            v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
            v-model="searchForm"
            class="form-control"
            placeholder="Search"
            style="max-width: 235px"
          />
        </div>
      </common-header>
      <div class="container-fluid">
        <div v-if="Object.keys(filteredSystemConfigs).length > 0 && fetched">
          <CollapseCard
            class="theme-default mb-3"
            :midDivider="false"
            header-class="px-0"
            :collapse="true"
            title="Google drive folder ids"
            title-class="h5 m-0 font-monospace lh-base py-2"
            :open="false"
          >
            <div v-for="config in filteredSystemConfigs" :key="config.key">
              <systemConfigItem
                v-if="config.isChild"
                :config="config"
                :onEdit="onEdit"
                class="mb-2"
              />
            </div>
          </CollapseCard>
          <div v-for="config in filteredSystemConfigs" :key="config.key">
            <systemConfigItem
              v-if="!config.isChild"
              :config="config"
              :onEdit="onEdit"
              class="mb-2"
            />
          </div>
        </div>
        <div v-else>
          <h1>No config to show!</h1>
        </div>
      </div>
    </div>
    <new-system-config-modal
      ref="systemConfigModal"
      @updatedConfig="getSystemConfigs"
    />
  </div>
</template>

<script>
import systemConfigItem from "./config-master-item.vue";
import newSystemConfigModal from "./new-system-config-modal.vue";
import configurations from "../../../../config/configurations";
import { getConfigByKey } from "@/modules/Common/_utils/common-methods";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";

export default {
  data() {
    return {
      searchForm: "",
      fetched: false,
      selectedSystemConfig: {},
    };
  },

  components: {
    systemConfigItem,
    newSystemConfigModal,
    CollapseCard,
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },

    onEdit(key) {
      $("#systemConfigModal").modal("show");
      const filteredConfig = this.filteredSystemConfigs[key];
      const fetchedConfig = getConfigByKey(key);
      const config = fetchedConfig
        ? { ...filteredConfig, ...fetchedConfig }
        : { ...filteredConfig };
      this.$refs.systemConfigModal.prepareEditData(config);
    },
    async getSystemConfigs() {
      this.fetched = false;
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;

      await this.$store.dispatch("system_config/fetchSystemConfigsObject");
      this.fetched = true;
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CONFIGURATION"];
    },
    configurations() {
      return configurations;
    },
    filteredSystemConfigs() {
      const configurations = this.configurations || {};
      const filteredConfigs = Object.keys(configurations).reduce(
        (filtered, key) => {
          const config = configurations[key];
          if (
            config.key.toLowerCase().includes(this.searchForm.toLowerCase())
          ) {
            filtered[key] = config;
          }
          return filtered;
        },
        {}
      );

      return filteredConfigs;
    },
  },

  mounted() {
    this.getSystemConfigs();
  },
};
</script>

<style scoped>
.hover:hover {
  background: rgba(138, 233, 250, 0.2);
  transition: all ease-out 200ms;
}
.event-circle {
  font-weight: bolder;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  float: left;
  line-height: 18px;
  font-size: 12px;
}
.val-data {
  font-weight: normal !important;
  font-size: 0.9rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  display: block;
  margin-bottom: 20px;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.container {
  max-width: 100%;
  padding: 10px 20px;
}

@media (max-width: 576px) {
  .container {
    padding: 0 10px;
  }
}
</style>
