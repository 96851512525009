<template>
  <div class="mb-3">
    <CollapseCard
      class="theme-default"
      title="Hiring Target"
      :midDivider="false"
      header-class="px-0 py-0"
      :collapse="true"
      title-class="h5 m-0"
    >
      <div class="hiring-targets-container">
        <div v-if="filteredOpenJobPositions.length">
          <div
            v-for="jobPosition in filteredOpenJobPositions"
            :key="jobPosition.id"
            :title="jobPosition.job.title"
            class=""
          >
            <div class="d-flex flex-row align-items-center ml-3 my-2">
              <div class="icon-div">
                <div class="numeric-icon">
                  {{ jobPosition.open | formatNumber("00") }}
                </div>
              </div>
              <div class="mx-2" style="line-height: 1; font-weight: 500">
                <span> {{ jobPosition.job.title }}</span>
              </div>
            </div>
          </div>

          <div class="border-top">
            <div class="d-flex flex-row align-items-center ml-3 my-2">
              <div class="icon-div">
                <div class="numeric-icon">
                  {{ totalOpenPositions | formatNumber("00") }}
                </div>
              </div>
              <div class="mx-2" style="line-height: 1">
                <span style="font-size: 20px; font-weight: 600">Total</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="pt-2 text-center">
          <p>Currently, there aren't any open positions.</p>
        </div>
      </div>
    </CollapseCard>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";

export default {
  components: {
    CollapseCard,
  },
  methods: {
    getOpenPositions() {
      this.$store.dispatch("$_applicants_data/fetchOpenJobPositions");
    },
  },
  computed: {
    totalOpenPositions() {
      let total = 0;
      this.openJobPositions &&
        this.openJobPositions?.forEach(function (jobPosition) {
          total += jobPosition.open * 1;
        });
      return total;
    },
    ...mapGetters({
      openJobPositions: "$_applicants_data/fetchOpenJobPositions",
    }),
    filteredOpenJobPositions() {
      return this.openJobPositions.filter(
        (jobPosition) => jobPosition.open > 0
      );
    },
  },
  mounted() {
    this.getOpenPositions();
  },
};
</script>
<style scoped>
.numeric-icon {
  width: 30px;
  height: 30px;
  background-color: #ce8dad85;
  border-color: #ce8dad85;
  color: #8f1452;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  user-select: none;
  font-weight: 600;
}
.hiring-targets-container {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
