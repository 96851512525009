import api from "../api";

function getAllTemplateMasters(context) {
  return api
    .fetchAllTemplates()
    .then((result) => {
      context.commit("setAllTemplateMasters", result.data);
    })

    .catch((err) => console.error(err));
}

function updateTemplateMaster(context, data) {
  const formData = new FormData();

  // Append file to formData separately
  const { file, ...rest } = data;
  formData.append("file", file);

  formData.append("data", JSON.stringify(rest));
  return api
    .updateTemplate(formData)
    .then(() => context.commit("setTemplateMaster", data))
    .catch((err) => console.error(err));
}

function deleteTemplateMaster(context, id) {
  return api
    .deleteTemplate(id)
    .then(() => context.commit("removeTemplateMaster", id))
    .catch((err) => console.error(err));
}

function createTemplateMaster(context, data) {
  const formData = new FormData();

  const { file, ...rest } = data;
  formData.append("file", file);

  formData.append("data", JSON.stringify(rest));
  return api
    .createTemplate(formData)
    .then((result) => context.commit("addTemplateMaster", result.data))
    .catch((err) => console.error(err));
}

export default {
  getAllTemplateMasters,
  updateTemplateMaster,
  deleteTemplateMaster,
  createTemplateMaster,
};
