import axios from "axios";

const getPermissions = () => {
  return axios.get("my-permissions", { meta: { abortRequests: false } });
};
const allPermissions = () => {
  return axios.get("all-permissions", { meta: { abortRequests: false } });
};

export default {
  getPermissions,
  allPermissions,
};
