<template>
  <div>
    <div class="text-center mt-5" v-if="isDisplayDataAvailable">
      <h1>Reviews</h1>
    </div>
    <div class="container-fluid" v-if="isDisplayDataAvailable">
      <div class="row">
        <div class="col-md-11">
          <button
            class="btn dropdown-toggle btn-cp ml-2"
            type="button"
            data-toggle="dropdown"
            id="reviewListButton"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ categories[0][1] }}
          </button>
          <ul
            id="reviewList"
            class="dropdown-menu"
            aria-labelledby="reviewListButton"
          >
            <button
              class="dropdown-item"
              v-for="(category, index) in categories"
              @click="getReview(category)"
              :key="index"
            >
              {{ category[1] }}
            </button>
          </ul>
        </div>
      </div>
    </div>
    <div class="container mt-4">
      <div
        class="review_card_wrapper"
        :key="categoryId"
        v-if="isDisplayDataAvailable"
      >
        <div
          class="review_card"
          v-for="(review, index) in displayData"
          :key="index"
        >
          <!-- Add JavaScript Source file for Reviews -->
          <script
            type="application/javascript"
            src="https://www.glassdoor.co.in/static/js/api/widget/v1.js"
          ></script>

          <div v-bind:id="review['id']"></div>
          <script type="application/javascript">
            document.getElementById('{{review["id"]}}').innerHTML =
              '{{review["review_description"]}}';
          </script>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  methods: {
    getReview(category) {
      this.categoryId = category[0];
      this.displayData = this.categoriesToReview[category[0]];
      document.getElementById("reviewListButton").innerHTML = category[1];
    },
  },

  data() {
    return {
      isDisplayDataAvailable: false,
      displayData: [],
      categories: [],
      categoriesToReview: {},
      categoryId: 0,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapGetters({
      currentReviews: "$_home/reviewCategoriesWithReviews",
    }),
  },
  watch: {
    currentReviews(value) {
      for (let x in value) {
        if (x != "reviews" && value[x]["status"] == "active") {
          for (let y in value[x]["reviews"]) {
            if (value[x]["reviews"][y]["status"] == "active") {
              let id = value[x]["reviews"][y]["reviewCategoryId"];
              let name = value[x]["review_category_name"];
              if (!(id in this.categoriesToReview)) {
                this.categoriesToReview[id] = [];
                this.categories.push([id, name]);
              }
              this.categoriesToReview[id].push(value[x]["reviews"][y]);
            }
          }
        }
      }
      if (this.categories.length > 0) {
        this.isDisplayDataAvailable = true;
        this.categoryId = this.categories[0][0];
        this.displayData = this.categoriesToReview[this.categories[0][0]];
      }
    },
  },

  created() {
    this.$store.dispatch("$_home/fetchReviewCategoriesWithReviews");
  },
};
</script>

<style scoped>
#reviewListButton {
  float: right;
  width: 150px;
}
#reviewList {
  width: 150px;
}

.review_card_wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.review_card {
  margin: 10px;
  margin-bottom: 30px;
  position: relative;
  height: 250px;
  width: 350px;
  font-size: 15px;
  line-height: 22px;
}

.review_card .review_card_header {
  margin: 30px 0px;
  text-align: center;
}
.review_card .review_card_header h2 {
  font-size: 22px;
  font-weight: 700;
  margin: 15px 0px;
  color: #2e3941;
  line-height: 24px;
}
.review_card span {
  font-weight: 600;
  display: block;
  color: #75828a;
  margin-bottom: 5px;
  font-size: 14px;
}
.review_card .review_card_footer {
  border-top: 1px solid #cccccc;
  padding-top: 30px;
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 30px;
  width: calc(100% - 40px);
  text-align: right;
}
.review_card .review_card_footer a {
  color: #903564;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}
.review_card .review_card_footer a i {
  padding-left: 5px;
}
.review_card .review_card_footer a:hover {
  color: #903564;
}

h1 {
  font-size: 48px;
  color: #2e3941;
  font-weight: 700;
}
@media screen and (max-width: 991px) {
  h1 {
    font-size: 32px;
  }
}

.text-size-22 {
  font-size: 22px;
}
@media screen and (max-width: 991px) {
  .text-size-22 {
    font-size: 18px;
  }
}
</style>
