<template>
  <div>
    <div class="row mx-0 job-category nopadding">
      <h3
        data-toggle="collapse"
        :data-target="'#panel' + category.id"
        :aria-controls="'panel' + category.id"
        class="flex-grow-1 pl-2 pointer"
        :id="'flip' + category.id"
      >
        {{ category.category_name }}
      </h3>
      <div class="text-right d-flex align-items-center">
        <div class="d-none d-md-inline">
          <a
            v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
            class="add-icon mr-3"
            href="#"
            role="button"
            data-toggle="modal"
            data-target="#addJobModal"
            data-backdrop="static"
            data-keyboard="false"
            @click="setModalData(category.id, category.category_name)"
            id="add_cat_icon"
            v-tooltip.top="'Add Job'"
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
          </a>
          <a
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            class="edit-icon mr-3"
            href="#"
            role="button"
            data-toggle="modal"
            data-target="#editCategoryModal"
            data-backdrop="static"
            data-keyboard="false"
            @click="editCategory"
            id="edit_cat_icon"
            v-tooltip.top="'Edit Category'"
          >
            <i class="fa fa-pencil p-0" aria-hidden="true"></i>
          </a>
          <a
            v-if="hasAccess({ permissions: [BASE_PERMISSION.DELETE] })"
            class="delete-icon mr-3"
            id="delete_cat_icon"
            v-tooltip.top="'Delete Category'"
            href="#"
            role="button"
            data-toggle="modal"
            data-target="#deleteModal"
            data-backdrop="static"
            data-keyboard="false"
            @click="deleteCategory(category.id, 'category')"
          >
            <i class="fa fa-trash p-0" aria-hidden="true"></i>
          </a>
          <a
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            class="status-icon mr-3"
            id="update_cat_status_icon"
            v-tooltip.top="
              category.status === 'active'
                ? 'Deactivate Category'
                : 'Activate Category'
            "
            href="#"
            role="button"
            :style="[
              category.status === 'active'
                ? { color: 'green' }
                : { color: 'red' },
            ]"
            @click="updateCategoryWithJobStatus()"
          >
            <i class="fa fa-circle" aria-hidden="true"></i>
          </a>
        </div>
        <button
          type="button"
          class="btn btn-custom dropdown-toggle d-md-none"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <b-icon icon="three-dots-vertical" style="color: #8f1452"></b-icon>
        </button>
        <div class="dropdown-menu">
          <a
            class="dropdown-item"
            href="#"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            data-toggle="modal"
            data-target="#editCategoryModal"
            @click="editCategory"
          >
            <b-icon class="mr-2" icon="pencil-square" variant="primary"></b-icon
            >Edit
          </a>
          <a
            class="dropdown-item"
            href="#"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.DELETE] })"
            data-toggle="modal"
            data-target="#deleteModal"
            @click="deleteCategory(category.id, 'category')"
          >
            <b-icon class="mr-2" icon="trash" variant="danger"></b-icon>Delete
          </a>
          <a
            class="dropdown-item"
            href="#"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            @click="updateCategoryWithJobStatus"
          >
            <b-icon
              class="mr-2"
              icon="circle-fill"
              :variant="category.status === 'active' ? 'success' : 'danger'"
            ></b-icon>
            {{ category.status === "active" ? "Deactivate" : "Activate" }}
            Category
          </a>
          <a
            class="dropdown-item"
            href="#"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
            data-toggle="modal"
            data-target="#addJobModal"
            @click="setModalData(category.id, category.category_name)"
          >
            <b-icon class="mr-2" icon="plus-circle" variant="secondary"></b-icon
            >Add New Job
          </a>
        </div>
      </div>
    </div>
    <div
      class="collapse table-responsive nopadding m-t10"
      :id="'panel' + category.id"
    >
      <table
        aria-describedby="ReqCategories"
        class="table"
        v-if="category.jobs.length"
      >
        <tr class="tableheader">
          <th id="Actions" class="col6">Actions</th>
          <th id="Position" class="col6">Position</th>
          <th id="Skills Reqd" class="col6">Skills Reqd</th>
          <th id="Qualifications Reqd" class="col6">Qualifications Reqd</th>
          <th id="Min Exp Reqd" class="col6">Min Exp Reqd</th>
          <th id="Max Exp Reqd" class="col6">Max Exp Reqd</th>
          <th id="Status" class="col6">Status</th>
        </tr>
        <job-list-element
          v-for="job in category.jobs"
          :key="job.id"
          :job="job"
          :categoryId="category.id"
        ></job-list-element>
      </table>
      <p class="p-l10" v-else>No jobs to show</p>
    </div>
  </div>
</template>

<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import JobListElement from "./job-list-element.vue";

/**
 * @memberof module:ManageJob
 * @namespace components.category_list_element
 */
export default {
  props: ["category", "isOpened"],

  components: {
    JobListElement,
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.JOB;
    },
  },

  /**
   * @memberof module:ManageJob.components.category_list_element
   * @description lifecycle method - handles accordian css
   */
  mounted() {
    if (this.isOpened) {
      const panelId = "#panel" + this.category.id;
      $(panelId).addClass("in");
    }
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:ManageJob.components.category_list_element
     * @description method - dipatch request to set category data to add job
     */
    setModalData(categoryId, categoryName) {
      this.$store.dispatch("$_manage_jobs/setAddJobModalData", {
        id: categoryId,
        name: categoryName,
      });
    },

    /**
     * @memberof module:ManageJob.components.category_list_element
     * @description method - dispatch request to delete cateory
     */
    deleteCategory(categoryId, itemType) {
      this.$store.dispatch("$_manage_jobs/deleteItem", {
        id: categoryId,
        type: itemType,
      });
    },

    /**
     * @memberof module:ManageJob.components.category_list_element
     * @description method - dispatch request to edit category
     */
    editCategory() {
      this.$store.dispatch("$_manage_jobs/editCategoryModal", this.category);
    },

    /**
     * @memberof module:ManageJob.components.category_list_element
     * @description method - dispatch request to change category status
     */
    updateCategoryWithJobStatus() {
      const info = {};
      if (this.category.status === "active") {
        info.status = "inactive";
      } else {
        info.status = "active";
      }
      info.id = this.category.id;
      info.category_name = this.category.category_name;
      info.category_description = this.category.category_description;
      info.created_by = this.category.created_by;
      info.updated_by = this.category.updated_by;
      this.$store.dispatch("$_manage_jobs/updateCategoryWithJobStatus", info);
    },
  },
};
</script>

<style scoped>
th {
  text-align: center;
}

table {
  table-layout: fixed;
  word-wrap: break-word;
  width: 100%;
  overflow: scroll;
}

.p-l10 {
  padding-left: 10px;
}

.add-new-job-btn {
  margin-top: 4px;
}

.add-new-job-btn:hover {
  background-color: #903564;
  border-radius: 5px;
  color: white !important;
}

.status-icon {
  margin-top: 4px;
}
.pointer {
  cursor: pointer;
}
.job-category {
  border: 1px solid #d8cbda;
  background-color: #f4f4f4;
  padding: 5px;
  margin-bottom: 5px;
}

.job-category h3 {
  font-size: 17px;
  margin-top: 8px;
  font-weight: 600;
}

.btn-custom.dropdown-toggle::after {
  display: none;
}

.add-icon {
  color: #8f1452;
}
</style>
