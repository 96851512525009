<template>
  <div>
    <label :for="'input-line-' + _uid">
      {{ label }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <input
      :id="'input-line-' + _uid"
      class="form-control form-control-sm mb-1"
      :value="value"
      :placeholder="properties.placeholder"
      @input="handleInput"
    />
    <p class="text-danger" v-for="(error, idx) in errorList" :key="idx">
      <b-icon-exclamation-circle></b-icon-exclamation-circle>
      <span class="ms-2">{{ error }}</span>
    </p>
  </div>
</template>

<script>
import validators from "../../_utils/validators";

export default {
  name: "line-input-builder",
  props: {
    value: String,
    label: String,
    properties: Object,
    useValidation: Boolean,
    validators: Array,
  },
  data() {
    return {
      errorList: [],
      previewInputClass: "",
      previewError: "",
    };
  },
  computed: {
    isRequired() {
      return (
        this.useValidation &&
        this.validators.some((e) => e.validator === "required")
      );
    },
  },
  methods: {
    checkError(input) {
      this.errorList = [];
      for (const validator of this.validators) {
        const err = validators[validator.validator](input, validator);
        if (err) {
          this.errorList.push(err);
        }
      }
      if (this.errorList.length !== 0) {
        this.$emit("error", {
          key: this.key,
          errors: this.errorList,
        });
        return true;
      }
      return false;
    },
    handleInput(e) {
      this.$emit("input", e.target.value);
      this.useValidation && this.checkError(e.target.value);
    },
  },
};
</script>
