<template>
  <div class="base-card">
    <b-card>
      <slot name="img-element"></slot>
      <b-card-text>
        <slot name="text-element"> </slot>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.card {
  width: 100%;
}

.base-card {
  width: 100%;
  height: auto;
  cursor: pointer;
}
</style>
