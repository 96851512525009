/**
 * @memberOf module:Home.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of Job Categories and Jobs to be get
 * @description commits data of Job categories and Jobs
 */
const FETCH_CATEGORIES_WITH_JOBS = (state, data) => {
  state.categoriesWithJobs = data;
};

/**
 * @memberOf module:Home.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of Active Job Categories and Active Jobs to be get
 * @description commits data of Active Job categories and  Active Jobs
 */
const FETCH_ACTIVE_CATEGORIES_WITH_JOBS = (state, data) => {
  state.activeCategoriesWithJobs = data;
};

/**
 * @memberOf module:Home.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of Active Job Categories and Active Jobs to be get
 * @description commits data of job by id
 *  */
const FETCH_JOB_BY_ID = (state, data) => {
  state.jobData = data;
};

/**
 * @memberOf module:Home.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of Review categories and Reviews to be get
 * @description commits data of Review categories and Reviews
 */
const FETCH_REVIEW_CATEGORIES_WITH_REVIEWS = (state, data) => {
  state.reviewCategoriesWithReviews = data;
};

/**
 * @memberOf module:Home.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} formSubmitStatus Job Application data to be submitted
 * @description commits data of Job application to get Application Id
 */
const APPLICANT_DATA_SUBMITTED = (state, formSubmitStatus) => {
  state.formSubmitStatus = formSubmitStatus;
};

/**
 * @memberOf module:Home.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} applicationStatus applicant's details or application Id to be submitted
 * @description commits data of applicant's details or application Id to get current status of applicantion
 */
const APPLICATION_STATUS = (state, applicationStatus) => {
  state.applicationStatus = applicationStatus;
};

/**
 * @memberOf module:Home.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} jobOpeningStatus job opening key details
 * @description commits data of job opening keys details to get current status of job opening.
 */

const Job_Opening_Fresher = (state, data) => {
  state.fresherOpeningEnabled = data ? data.value[0] == "true" : false;
};
const Job_Opening_Professional = (state, data) => {
  state.professionalOpeningEnabled = data ? data.value[0] == "true" : false;
};
const Job_Opening_Skills = (state, data) => {
  if (data) {
    state.skillSet = data.value;
  }
};
/**
 * @memberOf module:Home
 * @namespace store.mutations
 * @description mutations for home module
 */
export default {
  FETCH_CATEGORIES_WITH_JOBS,
  APPLICANT_DATA_SUBMITTED,
  APPLICATION_STATUS,
  FETCH_REVIEW_CATEGORIES_WITH_REVIEWS,
  Job_Opening_Fresher,
  Job_Opening_Professional,
  Job_Opening_Skills,
  FETCH_ACTIVE_CATEGORIES_WITH_JOBS,
  FETCH_JOB_BY_ID,
};
