<template>
  <div class="content">
    <common-header :data="['home', 'report', 'errorLogs']">
      <div class="d-flex justify-content-betweem">
        <button
          type="button"
          class="btn btn-cp mr-2"
          @click="openModal"
          :disabled="selectedFiles.length < 1"
        >
          Mail
        </button>
        <select
          v-model="selectedPageSize"
          v-on:change="onPageSizeChanged()"
          id="page-size"
          class="select-cp form-control"
        >
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="300">300</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
          <option value="2000">2000</option>
        </select>
      </div>
    </common-header>
    <base-modal
      id="email-user-modal"
      :size="'md'"
      title="Email Error Files"
      :headerHeight="12"
      :footerHeight="10"
      :toggle="true"
    >
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont">Users</label>
        <multiselect
          name="emailUser"
          :searchable="true"
          :multiple="true"
          :options="userOptions"
          :close-on-select="false"
          data-vv-as="Users"
          v-model="emailUsers"
          v-validate="'required'"
          :class="{ 'is-invalid': errors.first('emailUser') }"
        >
        </multiselect>
        <span
          v-if="errors.first('emailUser')"
          class="valid-feedback alert-danger"
        >
          {{ errors.first("emailUser") }}
        </span>
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont">Selected files</label>
        <input
          disabled
          name="selectedFiles"
          type="text"
          required
          class="form-control"
          v-model="selectedFiles"
        />
      </div>
      <template #footer>
        <b-button
          size="md"
          @click="sendErrorEmail"
          :disabled="isAPICalling"
          class="btn btn-cp ml-2"
          >Mail</b-button
        >
      </template>
    </base-modal>
    <div class="container-fluid">
      <ag-grid-vue
        class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
        :gridOptions="gridOptions"
        @filter-changed="onFilterChanged"
        @sort-changed="onSortChanged"
        :paginationAutoPageSize="false"
        :paginationPageSize="selectedPageSize"
        :paginationPageSizeOptions="pageSizeOptions"
        @gridReady="onGridReady"
        @selectionChanged="onSelectionChanged"
        rowSelection="multiple"
        :key="paginationSize"
      ></ag-grid-vue>
    </div>
  </div>
</template>

<script>
import BaseModal from "@/modules/Common/_components/base-modal.vue";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";
import axios from "axios";
import getFilterByType from "../../Common/_utils/get-filter-by-type";

export default {
  data() {
    return {
      selectedFiles: [],
      users: [],
      emailUsers: [],
      paginationSize: 100,
      errorList: [],
      selectedErrors: [],
      isAPICalling: false,
      noRowsTemplate: "No Error Log found",
      columnDefs: [
        {
          headerName: "Error Type",
          field: "error_type",
          filter: true,
          floatingFilter: true,
          checkboxSelection: true,
          suppressHeaderMenuButton: true,
          minWidth: 150,
        },
        {
          headerName: "Error Message",
          field: "error_message",
          filter: true,
          floatingFilter: true,
          suppressHeaderMenuButton: true,
          minWidth: 150,
        },
        {
          headerName: "Handle Type",
          field: "handle_type",
          filter: true,
          floatingFilter: true,
          suppressHeaderMenuButton: true,
          minWidth: 150,
        },
        {
          headerName: "Date",
          field: "createdAt",
          filter: "agDateColumnFilter",
          floatingFilter: true,
          minWidth: 150,
          resizable: false,
          valueFormatter: (params) => {
            return moment(params.value).format("DD/MM/YYYY hh:mm:ss a");
          },
          filterParams: {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              const cellDate = new Date(cellValue);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Download",
          field: "download",
          cellRenderer: "download-file",
          pinned: "right",
          sortable: false,
          width: 100,
          maxWidth: 100,
          minWidth: 100,
          resizable: false,
        },
      ],
      gridOptions: null,
      selectedPageSize: 100,
      pageSizeOptions: [50, 100, 200, 300, 400, 500, 1000, 2000], // Custom page size options
    };
  },

  components: {
    "ag-grid-vue": AgGridVue,
    Multiselect,
    BaseModal,
    // eslint-disable-next-line vue/no-unused-components
    "download-file": {
      template: `<div>
        <b-icon role="button" icon="download" variant="danger" @click="downloadError(params.data.file_name)"></b-icon>
        </div>`,
      computed: {
        ...mapGetters({
          rowData: "$_error_logs/errorLogs",
          errorFile: "$_error_logs/errorFile",
        }),
      },
      methods: {
        async downloadError(filename) {
          await this.$store.dispatch(
            "$_error_logs/downloadErrorFile",
            filename
          );
          if (this.errorFile) {
            const blob = new Blob([this.errorFile], { type: "text/plain" });
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.download = filename;
            downloadLink.click();
          }
        },
      },
    },
  },
  methods: {
    async sendErrorEmail() {
      await this.$validator.validateAll();
      if (this.$validator.errors.items.length > 0) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Please enter valid values!",
        });
        return;
      }
      this.isAPICalling = true;
      const data = {
        emails: this.mappedEmails,
        filenames: this.selectedFiles,
      };
      await this.$store.dispatch("$_error_logs/emailErrorFile", data);
      this.gridOptions.api.deselectAll();
      this.emailUsers = [];
      this.isAPICalling = false;
      $("#email-user-modal").modal("hide");
    },
    onPageSizeChanged() {
      const rowCount = Number(this.selectedPageSize);
      this.gridOptions.paginationPageSize = rowCount;
      this.gridOptions.cacheBlockSize = rowCount;
      this.paginationSize = rowCount;
    },
    onSelectionChanged() {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      const filenames = selectedRows.map((row) => row.file_name);
      this.selectedFiles = filenames;
    },
    setDataSource() {
      let dataSource = {
        rowCount: null,
        rowCountPagination: 0,
        rowCountFiltering: 0,
        _self: this,
        getRows: function (params) {
          let queryParams = {
            startRow: params.startRow,
            size: params.endRow - params.startRow,
          };
          let queryObj = {};
          if (params.sortModel) {
            queryObj.sort = params.sortModel[0];
          }
          for (const key in params.filterModel) {
            if (Object.hasOwn(params.filterModel, key)) {
              const queryData = getFilterByType(params.filterModel[key]);
              queryObj[key] = queryData;
            }
          }
          if (
            Object.keys(params.filterModel).length != 0 ||
            Object.keys(params.sortModel).length != 0
          ) {
            this.rowCountPagination = 0;
            if (params.startRow == 0) {
              this.rowCountFiltering = 0;
            }
            axios.post("error-logs", queryObj, { params: queryParams }).then(
              (data) => {
                this.rowCountFiltering += data.data.length;
                let lastRow = this._self.getLastRowValue(
                  this.rowCountFiltering,
                  params.endRow,
                  -1
                );
                params.successCallback(data.data, lastRow);
              },
              (err) => {
                console.log(err);
              }
            );
          } else {
            this.rowCountFiltering = 0;
            axios.post("error-logs", {}, { params: queryParams }).then(
              (data) => {
                if (params.startRow == 0) {
                  this.rowCountPagination = data.data.length;
                } else {
                  this.rowCountPagination += data.data.length;
                }
                let lastRow = this._self.getLastRowValue(
                  this.rowCountPagination,
                  params.endRow,
                  -1
                );
                params.successCallback(data.data, lastRow);
              },
              (err) => {
                console.log(err);
              }
            );
          }
          this._self.resetRowCountData();
        },
      };

      this.gridOptions.api.setGridOption("datasource", dataSource);
    },

    getLastRowValue(rowCount, endRow, defaultValue = -1) {
      if (rowCount < endRow) return rowCount;
      return defaultValue;
    },

    resetRowCountData() {
      this.rowCountOnCurrentPage = -1;
      this.selectedRowCount = 0;
    },

    onFilterChanged() {
      this.selectedErrors = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedErrors.length;
    },
    onSortChanged() {
      this.selectedErrors = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedErrors.length;
    },
    onGridReady(params) {
      if (params.api) {
        this.gridOptions.api = params.api;
        this.setDataSource();
      } else {
        console.error("Grid API is undefined.");
      }
    },
    configGridOptions() {
      this.gridOptions = {
        overlayNoRowsTemplate: this.noRowsTemplate,
        rowData: null,
        columnDefs: this.columnDefs,
        suppressPropertyNamesCheck: true,
        enableCellTextSelection: true,
        suppressRowClickSelection: true,
        enableServerSideFilter: true,
        enableColResize: true,
        rowModelType: "infinite",
        rowSelection: "multiple",
        defaultColDef: {
          resizable: true,
          sortable: true,
          flex: 1,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
        },
        infiniteInitialRowCount: 0,
        animateRows: true,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        pagination: true,
        paginationPageSize: 100,
        paginationPageSizeSelector: false,
        cacheBlockSize: 100,
        context: {
          componentParent: this,
        },
      };
    },
    openModal() {
      $("#email-user-modal").modal();
    },
    async fetchUsers() {
      const response = await axios.get("users");
      this.users = response.data;
    },
  },
  beforeMount() {
    this.configGridOptions();
    this.fetchUsers();
  },
  computed: {
    ...mapGetters({
      errorFile: "$_error_logs/errorFile",
    }),
    userOptions() {
      return this.users.map((user) => `${user.first_name} ${user.last_name}`);
    },
    mappedEmails() {
      let mappedEmails = [];
      this.emailUsers.forEach((fullName) => {
        let [firstName, lastName] = fullName.split(" ");
        let user = this.users.find(
          (user) => user.first_name === firstName && user.last_name === lastName
        );
        if (user) {
          mappedEmails.push(user.email_address);
        }
      });
      return mappedEmails;
    },
  },
};
</script>

<style scoped>
.ag-grid-style {
  height: calc(100vh - 135px);
  min-height: 500px;
}

@media screen and (max-width: 294px) {
  .ag-grid-style {
    min-height: 250px;
    height: calc(100vh - 195px);
  }
}
::v-deep .multiselect__content-wrapper {
  position: relative !important;
  max-height: 250px;
}
</style>
