<template>
  <div class="content" ref="tableParent">
    <div class="page-header">
      <common-header :data="['home', 'campusDrive', 'campusRecruitments']">
        <div class="d-flex">
          <button
            class="btn dropdown-toggle btn-cp"
            type="button"
            data-toggle="dropdown"
          >
            Actions
          </button>
          <ul class="dropdown-menu dropdown-menu-right">
            <li
              v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
              class="dropdown-item"
              style="padding-top: 0px"
              @click="openDriveModal"
            >
              <a>Add Campus Recruitment</a>
            </li>
            <li
              v-if="
                hasAccess({
                  permissions: [PERMISSIONS['CAMPUS-DRIVE'].ENTITY.CREATE],
                })
              "
              class="dropdown-item"
              style="padding-top: 0px"
            >
              <router-link :to="{ name: 'addEntityForm' }">
                Add Entity
              </router-link>
            </li>
            <li
              v-if="
                hasAccess({
                  permissions: [PERMISSIONS['CAMPUS-DRIVE'].ENTITY.MENU],
                })
              "
              class="dropdown-item"
              style="padding-top: 0px"
            >
              <router-link :to="{ name: 'viewEntity' }">
                View Entity
              </router-link>
            </li>
            <li
              v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
              class="dropdown-item"
              style="padding-top: 0px"
            >
              <safe-link :link="formatDownloadLink"> Download Format</safe-link>
            </li>
          </ul>
          <button
            v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
            class="btn btn-cp btn-filter float-right ml-2"
            @click="filterCards"
          >
            <em class="fa fa-filter"></em>
            <span
              v-if="!isFilterOptionsEmpty()"
              class="filter-indicator"
            ></span>
          </button>
        </div>
      </common-header>
    </div>
    <div class="container-fluid">
      <ag-grid-vue
        v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
        class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :rowData="rowData"
      >
      </ag-grid-vue>
    </div>

    <new-campus-req-modal
      @refreshData="refreshData"
      @showSuccessModel="showSuccessModel"
    ></new-campus-req-modal>
    <update-status-modal></update-status-modal>
    <copy-url-modal @actionPerformed="copyUrl" :url="url"></copy-url-modal>
    <update-recruitment-last-date-modal></update-recruitment-last-date-modal>
    <filter-recruitment-modal
      :filterOptions="filterOptions"
      :updateFilterOptions="updateFilterOptions"
    ></filter-recruitment-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import newCampusRecruitment from "./add-campus-drive-modal";
import UpdateStatus from "./update-status.vue";
import updateStatusModal from "./update-status-modal.vue";
import config from "../../../config";
import copyURLButton from "./copy-form-url-button-render";
import CopyUrlModal from "./copy-url-modal";
import updateRecruitmentLastDate from "./update-recruitment-last-date.vue";
import updateRecruitmentLastDateModal from "./update-recruitment-last-date-modal.vue";
import FilterRecruitmentModal from "./filter-recruitment-modal.vue";
import { RECRUITMENT_COLUMNS_DEFS } from "../_utils/recruitments_data_list";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

/**
 * @memberof module:campusRecruitment
 * @namespace components.campus_recruitments
 */
export default {
  /**
   * @memberof module:campusRecruitment.components.campus_recruitments
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      observer: null,
      config,
      gridOptions: null,
      columnDefs: RECRUITMENT_COLUMNS_DEFS,
      rowData: null,
      selectedModal: "",
      url: "",
      showCopyUrlModal: false,
      filterOptions: {
        createdDate: {
          filterType: "",
          fromDate: null,
          toDate: null,
        },
        moreFilters: {
          status: "inProgress",
        },
      },
      rowCountOnCurrentPage: -1,
      selectedRowCount: 0,
      formatDownloadLink:
        config.ROOT_API + "/campus-drive/recruitments/download/format/",
    };
  },
  components: {
    updateStatusModal,
    updateRecruitmentLastDateModal,
    // "error-list-modal": errorListModal,
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "campus-recruitment-name": {
      template: `<div>
        <div v-if="params && params.data">
          <router-link :to="{name:'registeredApplicantList', params:{recruitmentId: params.data.id}}">
            <div class="d-inline-block" style="color:#337ab7; font-size:13px;" :id="'campus-list-popover-target-1'+params.rowIndex">
              {{params.data.recruitment_session_name}}
              </div>
              <b-popover :target="'campus-list-popover-target-1'+params.rowIndex" triggers="hover" placement="right">
                Click here to see campus status
              </b-popover>
          </router-link>
        </div>
      </div>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "entity-list": {
      template: `<div>
                  <div v-if="params && params.data && params.data.entitiesInvolved">
                    <li v-for="entity in params.data.entitiesInvolved">
                      <router-link v-if="entity.entityDetail" style="font-size:13px;" :to="{name:'viewEntityDetails', params:{entityId:entity.entityDetail.id}}">
                        {{entity.entityDetail.entity_name}}
                      </router-link>
                    </li>
                  </div>
                </div>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "entity-city-list": {
      template: `<div>
                  <div v-if="params && params.data && params.data.entitiesInvolved">
                    <li v-for="entity in params.data.entitiesInvolved">
                        {{entity.entityDetail.city}}
                    </li>
                  </div>
                </div>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "contact-person-name": {
      template: `<div>
                   <div v-if="params && params.data && params.data.entitiesInvolved">
                    <div v-for="entity in params.data.entitiesInvolved">
                      <div v-if="entity.entityDetail" v-for="contact in (entity.entityDetail.contactPersonDetails || [])">
                      {{contact && contact["name"]}}
                      </div>
                    </div>
                   </div>
                 </div>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "contact-person-email": {
      template: `<div>
                   <div v-if="params && params.data && params.data.entitiesInvolved">
                    <div v-for="entity in params.data.entitiesInvolved">
                      <div v-if="entity.entityDetail" v-for="contact in (entity.entityDetail.contactPersonDetails || [])">
                        {{contact && contact["email"]}}
                      </div>
                    </div>
                   </div>
                 </div>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "contact-person-mobile_no": {
      template: `<div>
                   <div v-if="params && params.data && params.data.entitiesInvolved">
                    <div v-for="entity in params.data.entitiesInvolved">
                      <div v-if="entity.entityDetail" v-for="contact in (entity.entityDetail.contactPersonDetails || [])">
                      {{contact && contact["mobile_no"]}}
                      </div>
                    </div>
                  </div>
                 </div>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "applicant-registered": {
      template: `<div>
                    <div v-if="params.data">
                      {{params.data.applicantCount}}
                    </div>
                </div>`,
    },
    "new-campus-req-modal": newCampusRecruitment,
    // eslint-disable-next-line vue/no-unused-components
    "update-status": UpdateStatus,
    // eslint-disable-next-line vue/no-unused-components
    "copy-form-url": copyURLButton,
    // eslint-disable-next-line vue/no-unused-components
    "update-recruitment-last-date": updateRecruitmentLastDate,
    CopyUrlModal,
    FilterRecruitmentModal,
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT;
    },
    ...mapGetters({
      campusRecruitments: "$_campus_recruitment/campusRecruitments",
      errorMessages: "$_campus_recruitment/errorMessages",
      setCampusRecruitmentUrl: "$_campus_recruitment/setCampusRecruitmentUrl",
      getRegisteredApplicantCount:
        "$_campus_recruitment/getRegisteredApplicantCount",
    }),
  },
  watch: {
    filterOptions() {
      this.refreshData();
    },
    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     * @param {Array} value
     * @description Watch - campus recruitment data
     */
    campusRecruitments(value) {
      this.rowData = value;
    },

    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     * @param {String} value
     * @description Watch on error message
     */
    errorMessages() {
      this.selectedModal = "error-list-modal";
    },

    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     * @param {String} value
     * @description sets campus drive url into modal
     */
    setCampusRecruitmentUrl(value) {
      this.url = `${config.BASE_URL}campus-recruitment-form/${value.uniqueID}`;
      this.showCopyUrlModal = true;
      this.refreshData(false);
      $("#copy-url-modal").modal("show");
    },

    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     * @param {String} count
     * @description sets registered applicant count
     */
    getRegisteredApplicantCount(count) {
      let newRowData = [];
      newRowData = this.rowData;
      count.forEach((val) => {
        for (let newRow of newRowData) {
          if (newRow.id === val.campusID) {
            newRow.registered_applicants = val.count;
            break;
          }
        }
      });
      this.rowData = newRowData;
      this.gridOptions.api.setGridOption("rowData", this.rowData);
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     * @param {String} value
     * @description sets campus drive url into modal
     */
    showSuccessModel(value) {
      this.url = `${config.BASE_URL}campus-recruitment-form/${value.unique_id}`;
      this.showCopyUrlModal = true;
      this.refreshData();
      $("#copy-url-modal").modal("show");
    },
    filterCards() {
      $("#filter-recruitment-modal").modal("show");
    },
    updateFilterOptions(filterOptions) {
      this.filterOptions = {
        createdDate: { ...filterOptions.createdDate },
        moreFilters: { ...filterOptions.moreFilters },
      };
    },
    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     * @param {String} identifier
     * @description Open campus recruitment modal
     */
    showModal(identifier) {
      $(identifier).modal("show");
    },

    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     * @description Reset modal data
     */
    resetSelectedModal() {
      this.selectedModal = "";
    },

    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     * @description Open drive modal
     */
    openDriveModal() {
      this.selectedModal = "new-campus-req-modal";
      this.$store.dispatch("$_campus_recruitment/fetchEntities");
      $("#addRecruitment").modal("show");
    },

    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     * @param {String} status
     * @description Set the status of campus recruitment
     */

    /**
     * @memberof module:campusRecruitment.components.campus_recruitments
     *
     * @description copy url from modal
     */
    copyUrl() {
      this.copyUrlFunction(this.url);
      $("#copy-url-modal").modal("hide");
    },

    registeredApplicantList(params) {
      this.$router.push({
        name: "registeredApplicantList",
        query: { key: params.id },
      });
    },

    copyUrlFunction(url) {
      navigator.clipboard.writeText(url).then(() => {
        this.$store.dispatch(
          "openSnackbar",
          {
            type: "success",
            message: "Successfully Copied URL !",
          },
          {
            root: true,
          }
        );
      });
    },

    refreshData(includeFilter = true) {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;

      this.$store.dispatch(
        "$_campus_recruitment/fetchCampusRecruitments",
        includeFilter ? this.getFilterOptions() : null
      );
    },
    getFilterOptions() {
      const queryParams = {};
      const filterParams = {};
      if (this.filterOptions.moreFilters.status) {
        queryParams.status = this.filterOptions.moreFilters.status;
      }
      if (this.filterOptions.createdDate.filterType) {
        queryParams.fromDate = this.filterOptions.createdDate.fromDate;
        queryParams.toDate = this.filterOptions.createdDate.toDate;
      }
      return { queryParams, filterParams };
    },
    configGridOptions() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      this.gridOptions = {
        headerHeight: 30,
        rowHeight: 25,
        suppressPropertyNamesCheck: true,
        enableColResize: true,
        enableCellTextSelection: true,
        pagination: true,
        paginationPageSizeSelector: false,

        defaultColDef: {
          flex: 1,
          resizable: true,
          floatingFilter: true,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
          filter: true,
        },
        context: {
          componentParent: this,
        },
      };
    },

    isFilterOptionsEmpty() {
      return (
        JSON.stringify(this.filterOptions) ===
        JSON.stringify({
          createdDate: {
            filterType: "",
            fromDate: null,
            toDate: null,
          },
          moreFilters: {
            status: "inProgress",
          },
        })
      );
    },
  },

  /**
   * @memberof module:campusRecruitment.components.campus_recruitments
   * @description lifecycle method - set all parameter for grid like gow height, filter etc.
   */
  beforeMount() {
    this.configGridOptions();
  },

  /**
   * @memberof module:campusRecruitment.components.campus_recruitments
   * @description lifecycle method - fetch all campus recruitment data
   */
  created() {
    this.refreshData();
  },
};
</script>
<style scoped>
.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 3px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #803059;
  color: white;
}

.dropdown-menu .dropdown-header {
  padding: 3px 20px;
  color: #823e5b;
  font-size: 16px;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

li.dropdown > a {
  color: white;
}

.container {
  max-width: 100%;
  padding: 10px 20px;
}

@media (max-width: 576px) {
  .container {
    padding: 0 14px;
  }
}

.ag-grid-style {
  height: calc(100vh - 135px);
  min-height: 500px;
}

/* MEDIA QUERY FOR SMALL SCREENS */
@media screen and (max-width: 423px) {
  .ag-grid-style {
    height: calc(100vh - 175px);
    min-height: 450px;
  }
}

/* MEDIA QUERY FOR SMALLER SCREENS */
@media screen and (max-width: 285px) {
  .ag-grid-style {
    height: calc(100vh - 195px);
    min-height: 400px;
  }
}
</style>
