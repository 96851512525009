<template>
  <div>
    <div v-if="interviewData">
      <div v-if="showHeader">
        <h3>Add Review</h3>
      </div>
      <div v-if="invalidForm">
        <h3 class="text-danger text-center my-4 py-4">
          Invalid Form URL / DATA !
        </h3>
      </div>
      <div v-else>
        <form @submit.prevent="handleSubmit">
          <div class="form-container">
            <h2 class="mb-2 text-muted text-center">{{ form.title }}</h2>
            <p
              v-if="form.description"
              class="mb-2 text-muted"
              style="text-align: justify"
            >
              {{ form.description }}
            </p>
            <hr />
            <div v-for="(section, idx) in sections" :key="idx">
              <!-- Section Title & Description -->
              <b-card
                v-if="section.title || section.description"
                class="my-3 border-top border-4 border-dark"
              >
                <h4 v-if="section.title" class="mb-2">{{ section.title }}</h4>
                <p
                  v-if="section.description"
                  class="mb-2"
                  style="text-align: justify"
                >
                  {{ section.description }}
                </p>
              </b-card>
              <!-- Section Body -->
              <b-card
                class="mb-3 shadow-sm"
                v-for="question in section.body"
                :key="question.key"
              >
                <component
                  :ref="question.key"
                  :is="question.type + '-renderer'"
                  v-bind="question"
                  v-model="model[question.key]"
                >
                </component>
              </b-card>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer text-center" v-if="showFooter">
        <button type="button" class="btn btn-secondary" @click="handleClose">
          Close
        </button>
        <button type="button" class="btn btn-cp ml-2" @click="saveResponse">
          Save
        </button>
      </div>
    </div>
    <div v-else>
      <h3 class="text-danger text-center my-4 py-4">Form data is required!</h3>
    </div>
  </div>
</template>

<script>
import LineInputRenderer from "../../FormTemplate/_components/renderers/LineInputRenderer.vue";
import ParagraphInputRenderer from "../../FormTemplate/_components/renderers/ParagraphInputRenderer.vue";
import RatingInputRenderer from "../../FormTemplate/_components/renderers/RatingInputRenderer.vue";
import DropDownInputRenderer from "../../FormTemplate/_components/renderers/DropDownInputRenderer.vue";
import CheckboxInputRenderer from "../../FormTemplate/_components/renderers/CheckboxInputRenderer.vue";
import RadioInputRenderer from "../../FormTemplate/_components/renderers/RadioInputRenderer.vue";
import api from "../../FormTemplate/api";

export default {
  components: {
    "line-renderer": LineInputRenderer,
    "paragraph-renderer": ParagraphInputRenderer,
    "rating-renderer": RatingInputRenderer,
    "dropdown-renderer": DropDownInputRenderer,
    "checkbox-renderer": CheckboxInputRenderer,
    "radio-renderer": RadioInputRenderer,
  },
  props: {
    applicantView: { type: Boolean, default: true },
    modalId: { type: String, default: null },
    interviewData: { type: Object },
    showHeader: { type: Boolean, default: false },
    showFooter: { type: Boolean, default: false },
    callback: { type: Function, default: () => {} },
    handleClose: { type: Function, default: () => {} },
  },
  data() {
    return {
      invalidForm: false,
      form: {
        sections: [],
      },
      model: {},
      label: {},
      formVersionId: null,
    };
  },
  watch: {
    FormById(val) {
      this.formHash = val.hash;
    },
    interviewData: {
      handler: function (val) {
        if (!val || !val.formMasterId) {
          return;
        }
        if (val.interviewer_remarks) {
          if (JSON.parse(val.interviewer_remarks)["model"]) {
            this.model = JSON.parse(val.interviewer_remarks)["model"];
            this.label = JSON.parse(val.interviewer_remarks)["label"];
          } else {
            this.model = JSON.parse(val.interviewer_remarks);
          }
        }
        if (val.interviewer_remarks && val.formVersionId) {
          api
            .fetchFormVersionById(val.formVersionId)
            .then((result) => {
              this.formVersionId = val.formVersionId;
              this.form = result.data.form_json;
            })
            .catch((err) => {
              this.invalidForm = true;
              console.error(err);
            });
        } else {
          api
            .fetchFormVersionByHash(val.formMasterId)
            .then((result) => {
              this.formVersionId = result.data.id;
              this.form = result.data.form_json;
            })
            .catch((err) => {
              this.invalidForm = true;
              console.error(err);
            });
        }
      },
      immediate: true,
    },
  },
  computed: {
    sections() {
      if (!this.form) {
        return [];
      }
      return this.form.sections;
    },
  },
  methods: {
    saveResponse() {
      let error = false;
      for (const section of this.sections) {
        const body = section.body;
        for (const item of body) {
          if (this.$refs[item.key][0].checkError(this.model[item.key])) {
            error = true;
          }
        }
      }
      if (error) {
        return;
      }

      const responseData = {};
      responseData.round_name = this.interviewData.round_name;
      responseData.formVersionId = this.formVersionId;
      if (this.applicantView && this.modalId)
        $("#" + this.modalId).modal("hide");
      if (this.interviewData.round_name === "Interview") {
        responseData.interviewer_id = this.interviewData.interviewer_id;
        responseData.interviewer_remarks = this.model;
        if (this.interviewData?.id) {
          responseData.id = this.interviewData.id;
          responseData.round_id = this.interviewData.round_id;
          this.$store.dispatch("$_applicant_profile/addInterviewReview", {
            ...responseData,
            applicantView: this.applicantView,
            callback: this.callback,
          });
        } else {
          responseData.ids = this.interviewData.ids;
          responseData.roundIds = this.interviewData.roundIds;
          this.$store.dispatch("$_applicant_profile/addInterviewReview", {
            ...responseData,
            applicantView: this.applicantView,
            callback: this.callback,
          });
        }
      } else {
        responseData.reviewer_id = this.interviewData.interviewer_id;
        responseData.reviewer_remarks = this.model;
        if (this.interviewData?.id) {
          responseData.id = this.interviewData.id;
          responseData.round_id = this.interviewData.round_id;
          this.$store.dispatch("$_applicant_profile/addReviewRoundReview", {
            ...responseData,
            applicantView: this.applicantView,
            callback: this.callback,
          });
        } else {
          responseData.ids = this.interviewData.ids;
          responseData.roundIds = this.interviewData.roundIds;
          this.$store.dispatch("$_applicant_profile/addReviewRoundReview", {
            ...responseData,
            applicantView: this.applicantView,
            callback: this.callback,
          });
        }
      }
    },
    // Convers form into key: label pair object
    // Duplicate from round-info.vue (could be move to utils)
    extractKeyLabel(form) {
      const keyLabel = {};
      form.sections.forEach((section) => {
        section.body.forEach((question) => {
          keyLabel[question.key] = question.label;
        });
      });
      return keyLabel;
    },
  },
};
</script>
<style scoped>
.form_header {
  font-size: 26px;
  text-align: center;
  font-weight: 600;
}

.form_page_inner {
  position: relative;
  margin-bottom: 15px;
}

.form_page_inner .from_subheading {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  position: relative;
  margin-bottom: 24px;
}

.form_page_inner .from_subheading-2:after {
  content: "";
  width: calc(100% - 147px);
}
</style>
