<template>
  <div
    class="shadow-sm p-2 rounded-2 hover"
    :class="{ notCreated: !isUpdated }"
  >
    <div
      class="d-flex flex-row justify-content-between align-items-center"
      :class="{ notCreated: !isUpdated }"
    >
      <!-- Input / Title -->
      <div class="flex-grow-1 cursor-pointer">
        <h5 class="mb-0 font-monospace lh-base">
          {{ keyName }}
          <button
            v-if="!isUpdated"
            class="btn btn-sm px-1 py-0 ml-0 info mb-1"
            v-b-tooltip.hover
            title="This config key is not added in database yet. It's default value will be used until a value is provided."
          >
            <b-icon icon="info-circle-fill" variant="danger"></b-icon>
          </button>
        </h5>
      </div>

      <!-- Toggle Button or Dropdown -->
      <div
        class="position-relative"
        v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
      >
        <template v-if="updatedConfig.type === 'boolean'">
          <!-- Render toggle button -->
          <button
            class="btn btn-toggle custom-toggle"
            :class="{ active: value[0] === 'true' }"
            @click="toggleBoolean"
          ></button>
        </template>
        <template v-else-if="!updatedConfig.isInput">
          <div class="form-group my-0" :class="{ rightMargin: addRightMargin }">
            <div>
              <div class="form-group d-flex align-items-center m-0">
                <div class="flex-grow-1">
                  <multiselect
                    class="custom-multiselect"
                    :searchable="false"
                    :multiple="!updatedConfig?.isSingleValue"
                    :close-on-select="updatedConfig?.isSingleValue"
                    :show-labels="false"
                    v-model="value"
                    :options="updatedConfig.options"
                    @close="onMultiSelectClose"
                  >
                    <!-- eslint-disable vue/no-unused-vars -->
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} selected</span
                      ></template
                    ></multiselect
                  >
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <!-- Action Buttons -->
      <div
        class="d-none d-md-flex ml-4"
        v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
      >
        <div class="d-flex align-items-center">
          <template v-if="isUpdated">
            <button
              class="btn btn-sm mr-0"
              v-b-tooltip="
                updatedConfig.is_active ? 'Click to disable' : 'Click to enable'
              "
              @click="onToggleStatus"
            >
              <b-icon
                icon="circle-fill"
                class="mr-2"
                :variant="updatedConfig.is_active ? 'success' : 'danger'"
              ></b-icon>
            </button>
            <span>|</span>
          </template>
          <template>
            <button
              class="btn btn-sm"
              v-b-tooltip="'Edit'"
              @click="handleEditButton"
            >
              <b-icon icon="pencil-square" variant="primary"></b-icon>
            </button>
          </template>
        </div>
      </div>

      <!-- Triple-dot Dropdown -->
      <div
        class="ml-2"
        v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
      >
        <button
          type="button"
          class="btn btn-custom dropdown-toggle d-md-none"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <b-icon icon="three-dots-vertical" style="color: #8f1452"></b-icon>
        </button>
        <div class="dropdown-menu d-md-none">
          <a class="dropdown-item" @click="handleEditButton">
            <b-icon
              class="mr-2"
              icon="pencil-square"
              variant="primary"
            ></b-icon>
            Edit
          </a>
          <a class="dropdown-item" v-if="isUpdated" @click="onToggleStatus">
            <b-icon
              icon="circle-fill"
              class="mr-2"
              :variant="updatedConfig.is_active ? 'success' : 'danger'"
            ></b-icon>
            Toggle Status
          </a>
        </div>
      </div>
    </div>
    <div>
      {{ updatedConfig.desc }}
    </div>
  </div>
</template>

<script>
import { getConfigByKey } from "@/modules/Common/_utils/common-methods";
import Multiselect from "vue-multiselect";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      isUpdated: false,
      updatedConfigData: null,
      showDropdown: false,
      value: [],
      isValueChanged: false,
    };
  },
  watch: {
    value(newValue) {
      const newValueArray = Array.isArray(newValue) ? newValue : [newValue];
      if (!this.isSameValue(newValueArray, this.updatedConfig.value)) {
        this.isValueChanged = true;
        this.updatedConfig.value = newValueArray;
      }
    },
    updatedConfig() {
      const fetchedConfig = getConfigByKey(this.config.key);
      this.isUpdated = !!fetchedConfig;
    },
  },

  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    handleEditButton() {
      this.onEdit(this.updatedConfig.key);
    },
    onSave() {
      const fetchedConfig = getConfigByKey(this.config.key);
      const nConfig = this.updatedConfig;

      if (fetchedConfig) {
        const commonConfig = {};

        for (const key in fetchedConfig) {
          if (Object.hasOwn(nConfig, key)) {
            commonConfig[key] = nConfig[key];
          }
        }
        this.$store.dispatch("system_config/updateSystemConfig", {
          data: { ...commonConfig },
        });
      } else {
        this.$store.dispatch("system_config/createSystemConfig", {
          data: { ...this.updatedConfig },
        });
      }
    },
    onMultiSelectClose() {
      this.$nextTick(() => {
        if (this.isValueChanged) {
          this.onSave();
          this.isValueChanged = false;
        }
      });
    },
    onToggleStatus() {
      this.updatedConfig.is_active = !this.updatedConfig.is_active;
      this.onSave();
    },
    toggleBoolean() {
      this.value[0] = this.value[0] === "true" ? "false" : "true";
      this.updatedConfig.value = this.value;
      this.onSave();
    },
    isSameValue(arr1, arr2) {
      if (arr1?.length !== arr2?.length) {
        return false;
      }
      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();

      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }
      return true;
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CONFIGURATION"];
    },
    updatedConfig() {
      const fetchedConfig = getConfigByKey(this.config.key);
      return fetchedConfig
        ? { ...this.config, ...fetchedConfig }
        : { ...this.config };
    },
    addRightMargin() {
      return window.innerWidth >= 768 && !this.isUpdated;
    },
    keyName() {
      let key = this.updatedConfig?.key?.toLowerCase().replace(/_/g, " ");
      return key.length ? key.charAt(0).toUpperCase() + key.slice(1) : "";
    },
  },
  mounted() {
    if (this.updatedConfig.value) this.value = this.updatedConfig.value;
    const fetchedConfig = getConfigByKey(this.config.key);
    this.isUpdated = !!fetchedConfig;
  },
};
</script>
<style scoped>
.hover:hover {
  background: rgba(138, 233, 250, 0.2);
  transition: all ease-out 200ms;
}

.form-select {
  position: absolute;
  z-index: 999;
  top: calc(100% + 5px);
  left: 0;
}
.form-select option:checked {
  background-color: #903564;
  color: #333;
}
.notCreated {
  color: red;
}
.custom-multiselect {
  width: 130px;
}

.info {
  font-size: 0.7rem;
}
.btn-custom.dropdown-toggle::after {
  display: none;
}
.rightMargin {
  margin-right: 8vh;
}
</style>
