<template>
  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Delete Item</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 :style="'font-size:16px'">
            This action will delete the records permanently. <br />
            Are you sure?
          </h3>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-cp"
            @click="deleteItem"
            data-dismiss="modal"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      deleteItemData: "$_manage_reviews/deleteItemModal",
    }),
  },
  methods: {
    deleteItem() {
      if (this.deleteItemData.type === "reviewCategory") {
        this.$store.dispatch(
          "$_manage_reviews/deleteReviewCategory",
          this.deleteItemData.id
        );
      } else {
        this.$store.dispatch("$_manage_reviews/deleteReview", {
          reviewCategoryId: this.deleteItemData.reviewCategoryId,
          reviewId: this.deleteItemData.reviewId,
        });
      }
    },
  },
  beforeDestroy() {
    $("#deleteModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
