import {
  addSnackbarMessage,
  addSuccessSnackbarMessage,
} from "../../Common/_plugin/error-response";
import api from "../_api";

const fetchErrorLogs = (context) => {
  api
    .fetchErrorLogs()
    .then((response) => {
      context.commit("SET_ERROR_LOGS", response.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const downloadErrorFile = (context, data) => {
  return api
    .fetchErrorfile(data)
    .then((response) => {
      context.commit("SET_ERROR_FILE", response.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving download file!", true);
    });
};
const emailErrorFile = (context, data) => {
  return api
    .emailErrorFile(data)
    .then(() => {
      addSuccessSnackbarMessage("mail sent successfully");
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem Sending mail!", true);
    });
};
/**
 * @memberOf module:ErrorLogs
 * @namespace store.actions
 * @description actions for error logs module
 */
export default {
  fetchErrorLogs,
  downloadErrorFile,
  emailErrorFile,
};
