import axios from "axios";

const prefix = "/jobs";

/**
 * @memberOf module:ManageJob.api
 * @returns {Promise}
 */
const fetchCategoriesWithJobs = () => {
  return axios.get(prefix + "/jobopening");
};

/**
 * @memberOf module:ManageJob.api
 * @returns {Promise}
 */
const fetchPositionsWithJob = (jobId) => {
  return axios.get("jobs/positions-by-job/" + jobId);
};

/**
 * @memberOf module:ManageJob.api
 * @param {Object} job Job data to be sent
 * @returns {Promise}
 */
const addJob = (job) => {
  let formData = new FormData();
  formData.append("job_posting_image", job.job_posting_image);

  delete job.job_posting_image;

  let jobData = JSON.stringify(job);
  formData.append("data", jobData);
  return axios.post("jobs", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @memberOf module:ManageJob.api
 * @param {Object} category Job Category data to be sent
 * @returns {Promise}
 */
const addCategory = (category) => {
  const data = {
    data: category,
  };
  return axios.post(prefix + "/categories", data);
};

/**
 * @memberOf module:ManageJob.api
 * @param {Object} position Open Positions data to be sent
 * @returns {Promise}
 */
const addPositions = (positionData) => {
  const data = {
    data: positionData,
  };
  return axios.post(prefix + "/job-positions", data);
};

/**
 * @memberOf module:ManageJob.api
 * @param {Number} categoryId Job Category Id data to be sent
 * @returns {Promise}
 */
const deleteCategory = (categoryId) => {
  return axios.delete(prefix + "/categories/" + categoryId);
};

/**
 * @memberOf module:ManageJob.api
 * @param {Number} jobId Job Id data to be sent
 * @returns {Promise}
 */
const deleteJob = (jobId) => {
  return axios.delete("jobs/" + jobId);
};

/**
 * @memberOf module:ManageJob.api
 * @param {Number} jobPositionId Job position Id data to be sent
 * @returns {Promise}
 */
const deleteJobPositions = (jobPositionId) => {
  return axios.delete(prefix + "/job-positions/" + jobPositionId);
};

/**
 * @memberOf module:ManageJob.api
 * @param {Object} editJobData Job data to be sent
 * @returns {Promise}
 */
const editJob = (editJobData) => {
  const data = {
    data: editJobData,
  };
  return axios.put("jobs", data);
};

/**
 * @memberOf module:ManageJob.api
 * @param {Object} editCategoryData Job Category data to be sent
 * @returns {Promise}
 */
const editCategory = (editCategoryData) => {
  const data = {
    data: editCategoryData,
  };
  return axios.put(prefix + "/categories", data);
};

/**
 * @memberOf module:ManageJob.api
 * @param {Object} updateCategoryWithJobStatus Job Category data to be sent
 * @returns {Promise}
 */
const updateCategoryWithJobStatus = (editedCategoryData) => {
  return editCategory(editedCategoryData)
    .then(() => {
      axios
        .get("jobs/jobsByCategory/" + editedCategoryData.id)
        .then((resp) => {
          const promises = [];
          resp.data.forEach((element) => {
            element.status = editedCategoryData.status;
            delete element.createdAt;
            delete element.updatedAt;
            delete element.slug;

            promises.push(editJob(element));
          });
          Promise.all(promises)
            .then((result) => result)
            .catch((error) => error);
        })
        .catch((err) => err);
    })
    .catch((error) => error);
};

/**
 * @memberOf module:ManageJob.api
 * @param {Object} editJobStatus Job data to be sent
 * @returns {Promise}
 */
const editJobStatus = (editedJobData) => {
  return editJob(editedJobData)
    .then(() => {
      axios
        .get("jobs/jobsByCategory/" + editedJobData.categoryId)
        .then((resp) => {
          const length = resp.data.length;
          let statusCount = 0;
          resp.data.forEach((element) => {
            if (element.status === editedJobData.status) {
              ++statusCount;
            }
          });
          if (length === statusCount) {
            axios
              .get(prefix + "/categories/" + editedJobData.categoryId)
              .then((getCatResponse) => {
                let catInfo = { ...getCatResponse.data };
                delete catInfo.updatedAt;
                delete catInfo.createdAt;
                catInfo.status = editedJobData.status;

                editCategory(catInfo)
                  .then((result) => result)
                  .catch((error) => error);
              })
              .catch((error) => error);
          } else {
            axios
              .get(prefix + "/categories/" + editedJobData.categoryId)
              .then((getCatElseResponse) => {
                let catInfo = { ...getCatElseResponse.data };
                delete catInfo.updatedAt;
                delete catInfo.createdAt;
                catInfo.status = "active";

                editCategory(catInfo)
                  .then((result) => result)
                  .catch((error) => error);
              })
              .catch((error) => error);
          }
        })
        .catch((error) => error);
    })
    .catch((error) => error);
};

/**
 * @memberOf module:ManageJob
 * @namespace api
 * @description api for backend interactions
 */
export default {
  fetchCategoriesWithJobs,
  fetchPositionsWithJob,
  addJob,
  addCategory,
  addPositions,
  deleteCategory,
  deleteJob,
  deleteJobPositions,
  editJob,
  editCategory,
  updateCategoryWithJobStatus,
  editJobStatus,
};
