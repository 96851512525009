<template>
  <div class="container p-0 m-0">
    <div class="menubar nopadding" id="wrapper">
      <div class="profile-wrapper">
        <div class="profile-overlay">
          <div>
            <div class="profile-container" v-if="profile">
              <img
                v-if="profilePic"
                class="rounded-circle profile"
                :src="'data:image/jpeg;base64,' + profilePic"
                alt="Profile"
              />
              <img v-else class="profile" :src="avatar" alt="Profile" />
            </div>
            <h4 class="username">{{ fullName }}</h4>
          </div>
        </div>
      </div>

      <ul class="nopadding sidemenu">
        <li class="submenu">
          <router-link
            :to="{
              name: 'applicantProfile',
            }"
          >
            <i class="fa fa-user"></i> Profile</router-link
          >
        </li>
        <li class="submenu">
          <router-link
            :to="{
              name: 'applicantApplications',
            }"
          >
            <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Your
            Applications</router-link
          >
        </li>
        <li class="submenu" v-if="!canApplyJobs.belongsToCampusRecruitement">
          <router-link
            :to="{
              name: 'applicantJobOpenings',
            }"
          >
            <i class="fa fa-briefcase" aria-hidden="true"></i> Job
            Openings</router-link
          >
        </li>
        <li class="submenu" v-if="!canApplyJobs.belongsToCampusRecruitement">
          <router-link
            :to="{
              name: 'linkApplicationRequest',
            }"
          >
            <i class="fa fa-link" aria-hidden="true"></i>
            Link Application
            <div class="ml-4">Request</div></router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userProfile from "../../../assets/images/avatar.png";

/**
 * @memberof module:ApplicantMaster
 * @namespace sidebar-section
 */
export default {
  data: function () {
    return {
      menu: {
        MANAGE: false,
        MAIL: false,
        REPORT: false,
        "CAMPUS-DRIVE": false,
      },
      applicant: {
        first_name: "",
        last_name: "",
      },
      avatar: userProfile,
    };
  },
  mounted() {
    $(".submenu").click((e) => {
      e.stopPropagation();
    });
    this.applicant = this.profile;
  },
  computed: {
    ...mapGetters({
      profile: "$_applicant_master/getLoggedInApplicant",
      profilePic: "$_applicant_master/getProfilePic",
      canApplyJobs: "$_applicant_master/getCanApplyJobs",
    }),
    fullName() {
      return `${this.applicant.first_name} ${this.applicant.last_name}`;
    },
  },
  watch: {
    profile(val) {
      this.applicant = val;
    },
  },
};
</script>

<style scoped>
#wrapper {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  transition: all 0.4s ease 0s;
}

.menubar {
  top: 60px;
  bottom: 0px;
  width: 250px;
  position: fixed;
  left: -250px;
  z-index: 999;
  background: #832e5b;
  /* Old browsers */
  background: -moz-linear-gradient(top, #832e5b 1%, #b65386 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #832e5b 1%, #b65386 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #832e5b 1%, #b65386 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.menubar li {
  list-style-type: none;
  border-bottom: 1px solid #b2628b;
  transition: 0.5s;
}

.menubar li:hover {
  background-color: #803059;
  color: white !important;
}

.profile {
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 4px solid #e7e7e7;
}

.profile-wrapper {
  background-image: url(../../../assets/images/user-bg.jpg);
  background-size: cover;
  height: 190px;
  background-repeat: no-repeat;
  position: relative;
}

.profile-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.username {
  color: white;
  text-align: center;
  margin-top: 10px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 245px;
}

li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: white;
}

li > a:focus,
li > a:hover {
  background-color: #803059;
  color: white;
}

.fa {
  margin-right: 5px;
}

.submenu a {
  padding: 9px;
  font-size: 17px;
  background-color: #903564;
  color: white !important;
}

.submenu:hover a {
  background-color: #803059;
  color: white;
}

.sidemenu {
  background-color: #903564;
  width: 248px;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  margin: 0;
  padding: 0;
}

.router-link-active.router-link-exact-active {
  background: #fff !important;
  color: #903564 !important;
}
</style>
