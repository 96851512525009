<template>
  <div
    class="content"
    v-if="
      hasAccess({
        permissions: [PERMISSIONS['FILE-TEMPLATE'].MENU],
        checkAll: true,
      })
    "
  >
    <common-header :data="['home', 'manage', 'fileTemplates']">
      <div class="d-flex flex-row justify-content-between">
        <input
          v-model="searchTemplate"
          class="form-control"
          placeholder="Search"
          style="max-width: 360px"
        />

        <router-link
          v-if="
            hasAccess({
              permissions: [PERMISSIONS['FILE-TEMPLATE'].CREATE],
              checkAll: true,
            })
          "
          :to="{ name: 'create-template' }"
        >
          <button class="btn btn-cp ml-2">Add</button>
        </router-link>
      </div>
    </common-header>
    <div class="container-fluid">
      <div
        v-if="addTemplate"
        class="d-flex flex-row justify-content-between shadow-sm p-2 rounded-2 hover mb-4"
      >
        <!-- Input / Title -->
        <div class="flex-grow-1 cursor-pointer">
          <input
            id="addTemplateTitleInp"
            class="form-control"
            placeholder="Title"
            v-model="addTemplateTitle"
          />
          <small v-if="addTemplateTitle.length < 2" class="text-danger"
            >* Template title is required</small
          >
        </div>
        <!-- Action Buttons -->
        <div>
          <button class="btn" v-b-tooltip="'Save'" @click="onAddTemplate">
            <b-icon icon="check-circle" variant="success"></b-icon>
          </button>
          <span class="px-2">|</span>
          <button
            class="btn"
            v-b-tooltip="'Cancel'"
            @click="onAddTemplateCancel"
          >
            <b-icon icon="x-circle" variant="danger"></b-icon>
          </button>
        </div>
      </div>
      <div v-if="masterTemplates.length > 0">
        <templateMasterItem
          v-for="template in masterTemplates"
          :fileTemplate="template"
          :key="template.id"
          :hasAccess="hasAccess"
          :PERMISSIONS="PERMISSIONS"
          class="mb-2"
          @deleteTemplate="openConfirmDeleteModal(template)"
        />
      </div>
      <div v-else>
        <div class="jumbotron text-center">
          <p class="h5">No file template is availabe to display.</p>
          <hr class="my-4" />
          <router-link
            v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
            :to="{ name: 'create-template' }"
          >
            <button class="btn btn-cp">
              <i class="fa fa-plus fa-lg mr-1" aria-hidden="true"></i>Create
              file template
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <b-modal
      v-if="
        hasAccess({
          permissions: [PERMISSIONS['FILE-TEMPLATE'].DELETE],
          checkAll: true,
        })
      "
      ref="confirm-delete-modal"
      title="Delete Form?"
    >
      <p class="my-4">
        Are you sure you want to delete "<i
          ><strong>{{ confirmModalData.templateTitle }}</strong></i
        >" file template ?
      </p>
      <template #modal-footer>
        <button
          class="btn btn-secondary"
          @click="$refs['confirm-delete-modal'].hide()"
        >
          Close
        </button>
        <button class="btn btn-danger" @click="deleteTemplate">Delete</button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import TemplateMasterItem from "./template-master-item.vue";

export default {
  components: {
    TemplateMasterItem,
  },
  data() {
    return {
      searchTemplate: "",
      addTemplateTitle: "",
      addTemplate: false,
      formTemplateInp: "",
      confirmModalData: {
        templateTitle: "",
        id: 0,
      },
      selectedForm: -1,
    };
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    openAddTemplate() {
      this.addTemplate = true;
      this.$nextTick(() => {
        document.getElementById("addTemplateTitleInp").focus();
      });
    },
    onAddTemplate() {
      if (this.addTemplateTitle.length < 2) return;
      this.$store.dispatch("$_file_template/addTemplateMaster", {
        title: this.addTemplateTitle,
      });
      this.addTemplate = false;
      this.addTemplateTitle = "";
    },
    onAddTemplateCancel() {
      this.addTemplate = false;
      this.addTemplateTitle = "";
    },
    openConfirmDeleteModal(template) {
      this.confirmModalData.id = template.id;
      this.confirmModalData.templateTitle = template.file_name;
      this.$refs["confirm-delete-modal"].show();
    },
    deleteTemplate() {
      this.$store.dispatch(
        "$_file_template/deleteTemplateMaster",
        this.confirmModalData.id
      );
      this.$refs["confirm-delete-modal"].hide();
    },
    getTemplates() {
      this.$store.dispatch("$_file_template/getAllTemplateMasters");
    },
  },
  computed: {
    masterTemplates() {
      return this.$store.getters["$_file_template/getTemplates"].filter(
        (template) =>
          template.file_name
            .toLowerCase()
            .includes(this.searchTemplate.toLowerCase())
      );
    },
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["FILE-TEMPLATE"];
    },
  },
  beforeMount() {
    this.getTemplates();
  },
};
</script>
<style scoped>
.container {
  max-width: 100%;
  padding: 10px 20px;
}

@media (max-width: 576px) {
  .container {
    padding: 0 10px;
  }
}
</style>
