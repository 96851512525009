var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"calendarParent"},[_c('FullCalendar',{ref:"calendar",staticClass:"fullcalendar schedule-app-calendar fullcalendar-header-style-lg",staticStyle:{"height":"450px"},attrs:{"id":"calendar","name":"calendar","options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [_c('div',{staticClass:"w-100",staticStyle:{"overflow":"hidden"},on:{"click":function($event){return _vm.handleEventClick(arg)}}},[_c('div',{staticClass:"d-flex justify-content-between",class:{
              'event-title-inactive':
                arg.event.extendedProps.status != 'Pending' ||
                !_vm.scheduleDateValid(
                  arg.event.extendedProps.round_schedule_end
                ),
            },staticStyle:{"width":"100%"}},[_c('div',{staticClass:"left-side-div"},[_c('span',{staticClass:"event-circle span-circle",class:{
                  'event-circle-inactive':
                    arg.event.extendedProps.status != 'Pending' ||
                    !_vm.scheduleDateValid(
                      arg.event.extendedProps.round_schedule_end
                    ),
                },attrs:{"title":arg.event.extendedProps.round_name}},[_vm._v(" "+_vm._s(arg.event.extendedProps.round)+" ")]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"cursor":"pointer"},attrs:{"title":arg.event.extendedProps.withUsers}},[_vm._v(" "+_vm._s(arg.event.title)+" ")])]),_c('div',{staticClass:"d-flex"},[(arg.view.type == 'listMonth')?_c('div',[_vm._v(" "+_vm._s(arg.event.extendedProps.interviewers)+" ")]):_vm._e(),(arg.view.type != 'listMonth')?_c('div',[_vm._v(" "+_vm._s(_vm.extractTime(arg.event.extendedProps.round_schedule))+" ")]):_vm._e()])])])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }