var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center shadow-sm p-2 rounded-2 hover"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.editMode ? '' : String(_vm.fileTemplate.id)),expression:"editMode ? '' : String(fileTemplate.id)"}],staticClass:"flex-grow-1 cursor-pointer"},[(_vm.editMode)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editTitle),expression:"editTitle"}],staticClass:"form-control",attrs:{"placeholder":"Title"},domProps:{"value":(_vm.editTitle)},on:{"input":function($event){if($event.target.composing)return;_vm.editTitle=$event.target.value}}}):_c('h5',{staticClass:"mb-0 font-monospace lh-base"},[_vm._v(" "+_vm._s(_vm.fileTemplate.file_name)+" ")])]),_c('div',[(_vm.editMode)?[[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Save'),expression:"'Save'"}],staticClass:"btn btn-sm",on:{"click":_vm.onSave}},[_c('b-icon',{attrs:{"icon":"check-circle","variant":"success"}})],1),_c('span',[_vm._v("|")]),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Cancel'),expression:"'Cancel'"}],staticClass:"btn btn-sm",on:{"click":_vm.onCancel}},[_c('b-icon',{attrs:{"icon":"x-circle","variant":"danger"}})],1)]]:[_c('div',{staticClass:"d-none d-md-inline"},[[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('View'),expression:"'View'"}],staticClass:"btn btn-sm",on:{"click":_vm.handleTemplateView}},[_c('b-icon',{attrs:{"icon":"eye-fill","variant":"secondary"}})],1),(
                _vm.hasAccess({
                  permissions: [_vm.PERMISSIONS['FILE-TEMPLATE'].UPDATE],
                  checkAll: true,
                })
              )?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Edit name'),expression:"'Edit name'"}],staticClass:"btn btn-sm",on:{"click":_vm.onEdit}},[_c('b-icon',{attrs:{"icon":"pencil-square","variant":"primary"}})],1):_vm._e(),(
                _vm.hasAccess({
                  permissions: [_vm.PERMISSIONS['FILE-TEMPLATE'].MENU],
                  checkAll: true,
                })
              )?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Download File'),expression:"'Download File'"}],staticClass:"btn btn-sm",on:{"click":_vm.handleDownloadButton}},[_c('b-icon',{attrs:{"icon":"download","variant":"info"}})],1):_vm._e()],(
              _vm.hasAccess({
                permissions: [_vm.PERMISSIONS['FILE-TEMPLATE'].DELETE],
                checkAll: true,
              })
            )?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Delete'),expression:"'Delete'"}],staticClass:"btn btn-sm",on:{"click":_vm.onDelete}},[_c('b-icon',{attrs:{"icon":"trash","variant":"danger"}})],1):_vm._e()],2),_c('button',{staticClass:"btn btn-custom dropdown-toggle d-md-none",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('b-icon',{staticStyle:{"color":"#8f1452"},attrs:{"icon":"three-dots-vertical"}})],1),_c('div',{staticClass:"dropdown-menu d-md-none"},[_c('a',{staticClass:"dropdown-item cursor",on:{"click":_vm.handleTemplateView}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"eye-fill","variant":"secondary"}}),_vm._v(" View ")],1),(
              _vm.hasAccess({
                permissions: [_vm.PERMISSIONS['FILE-TEMPLATE'].UPDATE],
                checkAll: true,
              })
            )?_c('a',{staticClass:"dropdown-item cursor",on:{"click":_vm.onEdit}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"pencil-square","variant":"primary"}}),_vm._v(" Edit Name ")],1):_vm._e(),(
              _vm.hasAccess({
                permissions: [_vm.PERMISSIONS['FILE-TEMPLATE'].MENU],
                checkAll: true,
              })
            )?_c('a',{staticClass:"dropdown-item cursor",on:{"click":_vm.handleDownloadButton}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"download","variant":"info"}}),_vm._v(" Download File ")],1):_vm._e(),(
              _vm.hasAccess({
                permissions: [_vm.PERMISSIONS['FILE-TEMPLATE'].DELETE],
                checkAll: true,
              })
            )?_c('a',{staticClass:"dropdown-item cursor",on:{"click":_vm.onDelete}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"trash","variant":"danger"}}),_vm._v(" Delete ")],1):_vm._e()])]],2)]),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":String(_vm.fileTemplate.id),"accordion":"template-master","role":"tabpanel"}},[_c('b-card',{staticClass:"custom-card"},[_c('div',{staticClass:"d-flex flex-row justify-content-between align-items-center"},[_c('span',[_c('strong',[_vm._v("Id:")]),_vm._v(" "+_vm._s(_vm.fileTemplate.id))]),_c('div',[(
              _vm.hasAccess({
                permissions: [_vm.PERMISSIONS['FILE-TEMPLATE'].UPDATE],
                checkAll: true,
              })
            )?_c('router-link',{staticClass:"btn text-primary",attrs:{"to":{ name: 'edit-template', params: { id: _vm.fileTemplate.id } }}},[_c('b-icon',{staticClass:"small-icon",attrs:{"icon":"pencil","variant":"primary"}}),_vm._v(" Edit template ")],1):_vm._e()],1)]),_c('div',{staticClass:"mt-3"},[_c('table',{staticClass:"table table-bordered"},[_c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Variable")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Type")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Input Method")])])]),_c('tbody',_vm._l((_vm.formData),function(field,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(field.name))]),_c('td',[_vm._v(_vm._s(field.type))]),_c('td',[_vm._v(_vm._s(field.inputType))])])}),0)])])])],1),(_vm.viewTemplate)?_c('template-preview-modal',{attrs:{"formData":_vm.formData,"modalId":"preview-template","pdfData":_vm.pdfData,"title":_vm.fileTemplate.file_name,"isTemplateOnly":true},on:{"closeModal":_vm.closeModal}}):_vm._e(),(_vm.download)?_c('document-test-modal',{attrs:{"modalId":"downlaod-file","id":_vm.fileTemplate.id},on:{"closeModal":function($event){_vm.download = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }