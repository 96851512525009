/**
 * @memberof module:Common
 * @namespace loading_overlay_store
 * @description store for loading-overlay component
 */
export default {
  namespaced: true,

  state: {
    isVisible: false,
    isLoading: false,
    loadingMessage: "",
  },

  /**
   * @memberof module:Common.loading_overlay_store
   * @namespace getters
   */
  getters: {
    /**
     * @memberof module:Common.loading_overlay_store.getters
     * @param {Object} state holds store properties for current module
     * @returns {Boolean} visibility of loading-overlay
     */
    visibility(state) {
      return state.isVisible;
    },
    isLoading(state) {
      return state.isLoading;
    },
    loadingMessage(state) {
      return state.loadingMessage;
    },
  },

  /**
   * @memberof module:Common.loading_overlay_store
   * @namespace actions
   */
  actions: {
    /**
     * @memberof module:Common.loading_overlay_store.actions
     * @param {Object} context store context
     * @param {Object} visibility visibility of loading-overlay
     * @description used for displaying and hiding loading-overlay
     */
    showLoadingOverlay: {
      root: true,
      handler(context, visibility) {
        context.commit("LOADING_OVERLAY_VISIBILITY", visibility);
      },
    },
    setLoading: {
      root: true,
      handler(context, { isLoading, loadingMessage }) {
        context.commit("SET_LOADING", { isLoading, loadingMessage });
      },
    },
  },

  /**
   * @memberof module:Common.loading_overlay_store
   * @namespace mutations
   */
  mutations: {
    /**
     * @memberof module:Common.loading_overlay_store.mutations
     * @param {Object} state holds store properties for current module
     * @param {Object} snackbarData visibility of loading-overlay
     * @description handles loading-overlay opening and closing
     */
    LOADING_OVERLAY_VISIBILITY(state, visibility) {
      state.isVisible = visibility;
    },
    SET_LOADING(state, { isLoading, loadingMessage }) {
      state.isLoading = isLoading;
      state.loadingMessage = loadingMessage;
    },
  },
};
