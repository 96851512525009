<template>
  <div v-if="params.data">
    <i
      v-if="params.data.status === 'Completed'"
      class="fa fa-pencil-square-o fa-lg text-secondary"
      aria-hidden="true"
      :id="'update-last-date-completed-popover-target-1' + params.rowIndex"
    ></i>
    <i
      v-else-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
      class="fa fa-pencil-square-o fa-lg not-completed"
      aria-hidden="true"
      @click="updateRecruitmentLastDate(params.data.id)"
      data-target="#updateRecruitmentLastDateModal"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
      :id="'update-last-date-pending-popover-target-1' + params.rowIndex"
    ></i>
    <b-popover
      v-if="params.data.status === 'Completed'"
      :target="'update-last-date-completed-popover-target-1' + params.rowIndex"
      triggers="hover"
      placement="right"
    >
      Drive finished already!
    </b-popover>
    <b-popover
      v-else
      :target="'update-last-date-pending-popover-target-1' + params.rowIndex"
      triggers="hover"
      placement="right"
    >
      Update last date
    </b-popover>
  </div>
</template>

<script>
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

/**
 * @memberof module:campusRecruitment
 * @namespace components.update_status
 */
export default {
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:campusRecruitment.components.update_recruitment_last_date
     * @param {nummber} id
     * @description open last date change modal for campus recruitment
     */
    updateRecruitmentLastDate(id) {
      this.$store.dispatch("$_campus_recruitment/setCampusRecruitmentId", id);
    },
  },
};
</script>

<style scoped>
.not-completed {
  color: #337ab7;
}
</style>
