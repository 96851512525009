<template>
  <div
    v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
    class="mb-4"
    ref="rowElement"
  >
    <form
      class="content-form p-0"
      v-if="doRender"
      enctype="multipart/form-data"
    >
      <div v-if="!(isApplicantDataAvailable || isCampusDataAvailable)">
        <tags-input
          id="mail_to"
          label="To"
          :setValues="mailData.mail_to"
          validationType="email"
          @valuesUpdated="updateToValues($event)"
        />
        <div class="row mx-0">
          <div class="col-12 mb-4 px-0">
            <div
              class="form-group"
              v-if="mailTemplates && mailTemplates.length !== 0"
            >
              <label class="control-label" htmlFor="template">Template</label>
              <template-select
                for-id="#default-summernote"
                @change="fillMailDetails"
              />
            </div>
          </div>
        </div>
        <tags-input
          id="cc"
          label="CC"
          labelClasses="text-left"
          :isRequired="false"
          validationType="email"
          :setValues="mailData.mail_cc"
          @valuesUpdated="updateCcValues($event)"
        />
        <tags-input
          id="bcc"
          label="BCC"
          labelClasses="text-left"
          :isRequired="false"
          validationType="email"
          :setValues="mailData.mail_bcc"
          @valuesUpdated="updateBccValues($event)"
        />
      </div>
      <div class="row mx-0">
        <div class="col-12 mb-4 px-0">
          <div class="form-group">
            <label for="subject" class="control-label"
              >Subject <span class="text-danger">*</span></label
            >
            <input
              type="text"
              id="subject"
              class="form-control"
              v-model="mailData.mail_subject"
              @blur="isMailSubjectTouched = true"
            />
            <p
              class="alert-sm alert-danger"
              v-if="isMailSubjectTouched && !isMailSubjectValid"
            >
              This field is required
            </p>
          </div>
        </div>
      </div>
    </form>

    <div
      class=""
      v-show="!(isApplicantsMail || isCampusMail || isReviewerDataAvailable)"
    >
      <div class="form-group">
        <label class="control-label" htmlFor="body"
          >Body
          <span class="text-danger"> *</span>
        </label>
      </div>
      <common-rich-text-box
        modalId="default-summernote"
        :width="'100%'"
        height="200"
        minHeight="200"
        @onChange="onChange('default-summernote', $event, [])"
      />
    </div>
    <div
      v-if="isApplicantsMail && !isReviewerDataAvailable"
      class="mail-entity-container mx-0"
    >
      <mail-to-list
        id="applicant-mail-to-list"
        heading="applicant(s)"
        :mail-to-list="applicantMailToList"
      />
      <tags-input
        id="cc"
        label="CC"
        labelClasses="text-left"
        :isRequired="false"
        validationType="email"
        :setValues="mailData.mail_cc"
        @valuesUpdated="updateCcValues($event)"
      />
      <tags-input
        id="bcc"
        label="BCC"
        labelClasses="text-left"
        :isRequired="false"
        validationType="email"
        :setValues="mailData.mail_bcc"
        @valuesUpdated="updateBccValues($event)"
      />
      <div class="form-group">
        <label class="control-label" htmlFor="applicantBody"
          >Body for applicant
          <span class="text-danger"> *</span>
          <em
            class="fa fa-question-circle fa-lg"
            data-toggle="popover"
            data-placement="right"
            data-html="true"
            title="Available Placeholders"
            :data-content="finalPlaceholders.join('<br/>')"
          ></em>
        </label>
        <template-select
          for-id="#applicant-summernote"
          input-class="template-selector"
          @change="fillMailDetails"
        />
      </div>
      <common-rich-text-box
        modalId="applicant-summernote"
        :width="'100%'"
        height="200"
        minHeight="200"
        @onChange="onChange('applicant-summernote', $event, finalPlaceholders)"
      />
    </div>

    <div v-if="isCampusMail">
      <div
        v-if="
          receivedCampusData &&
          (receivedCampusData.type === 'coordinator' ||
            receivedCampusData.type === 'both')
        "
        class="mail-entity-container mx-0"
      >
        <mail-to-list
          id="coordinator-mail-to-list"
          heading="coordinator(s)"
          :mail-to-list="coordinatorMailToList"
        />
        <tags-input
          id="cc"
          label="CC"
          labelClasses="text-left"
          :isRequired="false"
          validationType="email"
          :setValues="mailData.mail_cc"
          @valuesUpdated="updateCcValues($event)"
        />
        <tags-input
          id="bcc"
          label="BCC"
          labelClasses="text-left"
          :isRequired="false"
          validationType="email"
          :setValues="mailData.mail_bcc"
          @valuesUpdated="updateBccValues($event)"
        />
        <div class="mail-label-container m-b15">
          <label class="control-label" htmlFor="coordinatorBody"
            >Body for coordinator
            <span class="text-danger"> *</span>
            <em
              class="fa fa-question-circle fa-lg"
              data-toggle="popover"
              data-placement="right"
              data-html="true"
              title="Available placeholders"
              :data-content="coordinatorPlaceholders.join('<br/>')"
            ></em>
          </label>
          <template-select
            for-id="#coordinator-summernote"
            input-class="template-selector"
            @change="fillMailDetails"
          />
        </div>
        <common-rich-text-box
          modalId="coordinator-summernote"
          :width="'100%'"
          height="200"
          minHeight="200"
          @onChange="onChange('coordinator-summernote', $event)"
        />
      </div>

      <div
        v-if="
          receivedCampusData &&
          (receivedCampusData.type === 'student' ||
            receivedCampusData.type === 'both')
        "
        class="mail-entity-container mx-0"
      >
        <mail-to-list
          id="student-mail-to-list"
          heading="student(s)"
          :mail-to-list="applicantMailToList"
        />
        <tags-input
          id="cc"
          label="CC"
          labelClasses="text-left"
          :isRequired="false"
          validationType="email"
          :setValues="mailData.mail_cc"
          @valuesUpdated="updateCcValues($event)"
        />
        <tags-input
          id="bcc"
          label="BCC"
          labelClasses="text-left"
          :isRequired="false"
          validationType="email"
          :setValues="mailData.mail_bcc"
          @valuesUpdated="updateBccValues($event)"
        />
        <div class="mail-label-container m-b15">
          <label class="control-label" htmlFor="studentBody"
            >Body for student
            <span class="text-danger"> *</span>
            <em
              class="fa fa-question-circle fa-lg"
              data-toggle="popover"
              data-placement="right"
              data-html="true"
              title="Available placeholders"
              :data-content="finalPlaceholders.join('<br/>')"
            ></em>
          </label>
          <template-select
            for-id="#student-summernote"
            input-class="template-selector"
            @change="fillMailDetails"
          />
        </div>
        <common-rich-text-box
          modalId="student-summernote"
          :width="'100%'"
          height="200"
          minHeight="200"
          @onChange="onChange('student-summernote', $event)"
        />
        <div class="col-sm-9" id="student-summernote"></div>
      </div>
    </div>

    <div v-if="isInterviewerDataAvailable" class="mail-entity-container">
      <mail-to-list
        id="interviewer-mail-to-list"
        heading="interviewer(s)"
        :mail-to-list="interviewerMailToList"
      />
      <tags-input
        id="cc"
        label="CC"
        labelClasses="text-left"
        :isRequired="false"
        validationType="email"
        :setValues="mailData.mail_cc"
        @valuesUpdated="updateCcValues($event)"
      />
      <tags-input
        id="bcc"
        label="BCC"
        labelClasses="text-left"
        :isRequired="false"
        validationType="email"
        :setValues="mailData.mail_bcc"
        @valuesUpdated="updateBccValues($event)"
      />
      <div class="mail-label-container m-b15">
        <label class="control-label" htmlFor="interviewerBody"
          >Body for interviewer
          <span class="text-danger"> *</span>
          <em
            class="fa fa-question-circle fa-lg"
            data-toggle="popover"
            data-placement="right"
            data-html="true"
            title="Available placeholders"
            :data-content="interviewerPlaceholders.join('<br/>')"
          ></em>
        </label>
        <template-select
          for-id="#interviewer-summernote"
          input-class="template-selector"
          @change="fillMailDetails"
        />
      </div>
      <common-rich-text-box
        modalId="interviewer-summernote"
        :width="'100%'"
        height="200"
        minHeight="200"
        @onChange="onChange('interviewer-summernote', $event)"
      />
    </div>
    <div v-if="isReviewerDataAvailable" class="mail-entity-container">
      <mail-to-list
        id="reviewer-mail-to-list"
        heading="reviewer(s)"
        :mail-to-list="reviewerMailToList"
      />
      <tags-input
        id="cc"
        label="CC"
        labelClasses="text-left"
        :isRequired="false"
        validationType="email"
        :setValues="mailData.mail_cc"
        @valuesUpdated="updateCcValues($event)"
      />
      <tags-input
        id="bcc"
        label="BCC"
        labelClasses="text-left"
        :isRequired="false"
        validationType="email"
        :setValues="mailData.mail_bcc"
        @valuesUpdated="updateBccValues($event)"
      />
      <div class="mail-label-container m-b15">
        <div class="form-group">
          <label class="control-label" htmlFor="reviewerBody"
            >Body for reviewer
            <span class="text-danger"> *</span>
            <em
              class="fa fa-question-circle fa-lg"
              data-toggle="popover"
              data-placement="right"
              data-html="true"
              title="Available placeholders"
              :data-content="reviewerPlaceholders.join('<br/>')"
            ></em>
          </label>
          <template-select
            for-id="#reviewer-summernote"
            input-class="template-selector"
            @change="fillMailDetails"
          />
        </div>
      </div>
      <common-rich-text-box
        modalId="reviewer-summernote"
        :width="'100%'"
        height="200"
        minHeight="200"
        @onChange="onChange('reviewer-summernote', $event)"
      />
    </div>

    <div class="form-group" style="margin-top: 25px">
      <file-upload-input
        id="files"
        name="mail_attachments"
        label="Attachment"
        :containerClasses="'col-lg-12 col-md-12 col-sm-12'"
        :file-size="{ size: 5120, unit: 'MB' }"
        :file-extensions="['doc', 'docx', 'pdf']"
        :max-files="5"
        @fileSelected="fileChanged($event)"
        @validityChanged="isFileValid = $event"
      />
    </div>
    <div v-if="hasAccess({ permissions: [FiLE_TEMPLATE_PERMISSION] })">
      <div
        class="toggle-section"
        v-if="fileTemplateAttachments.length"
        style="margin-top: 20px"
      >
        <h5 @click="toggleFileAttachmentsView" class="clickable">
          File Template Attachments
          <i
            class="fa fa-chevron-down fa-2xs chevron-icon"
            :class="{ down: fileAttachmentsVisible }"
            aria-hidden="true"
          ></i>
        </h5>
        <ul v-show="fileAttachmentsVisible">
          <li
            v-for="(attachment, index) in fileTemplateAttachments"
            :key="index"
          >
            {{ attachment.name }} - {{ attachment.for }}
          </li>
        </ul>
      </div>
      <div v-if="isApplicantDataAvailable" style="color: black">
        <span v-if="!fileTemplateAttachments.length">File Template</span>
        <span v-else>Select More File Template</span>

        <select
          id="file-template-select"
          class="form-control"
          v-model="selectedFileTemplate"
          @change="updateFileTemplate"
        >
          <option value="" disabled>Select a template</option>
          <option
            v-for="template in fileTemplates"
            :key="template.id"
            :value="template"
          >
            {{ template.file_name }}
          </option>
        </select>
      </div>
      <document-modal
        v-if="showModal"
        @closeModal="handleCloseModal"
        modalId="file-template-modal"
        :id="selectedFileTemplate.id"
        :users="selectedToMaildata"
      />
    </div>
    <hr />
    <div class="text-right">
      <button class="btn btn-cp" @click="resetMailData()">Discard</button>
      <button
        :disabled="!isFormValid"
        class="btn btn-cp ml-2"
        @click="sendMail()"
      >
        Send
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MailToList from "./mail-to-list";
import TemplateSelect from "./template-select";
import FileUploadInput from "../../Common/_components/file-upload-input";
import documentModal from "../../FileTemplate/_components/document-modal.vue";
import TagsInput from "../../Common/_components/tags-input-v2.vue";
import api from "../_api/index";
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";

/**
 * @memberof module:MailBox
 * @namespace components.mail_box
 * @description Consists of mail compose fields, receives data from other components for mailing purposes
 */
export default {
  /**
   * @memberof module:MailBox.components.mail_box
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      doRender: true,
      applicantsData: [],
      campusData: null,
      mailData: {
        mail_to: [],
        mail_cc: [],
        mail_bcc: [],
        mail_subject: "",
        mail_attachments: null,
      },
      defaultSummernoteBody: "",
      applicantSummenoteBody: "",
      coordinatorSummernoteBody: "",
      studentSummernoteBody: "",
      interviewerSummernoteBody: "",
      reviewerSummernoteBody: "",
      applicantMailToList: [],
      interviewerMailToList: [],
      reviewerMailToList: [],
      coordinatorMailToList: [],
      coordinatorPlaceholders: ["name", "email"],
      applicantPlaceholders: [
        "first_name",
        "last_name",
        "email",
        "mobile_no",
        "slug",
      ],
      roundPlaceholders: [
        "round_name",
        "round_schedule_time",
        "round_schedule_date",
      ],
      availableMailBodies: [],
      isApplicantsMail: false,
      isCampusMail: false,
      isMailToValid: false,
      isMailCCValid: true,
      isMailBCCValid: true,
      isMailSubjectTouched: false,
      isFileValid: false,
      templateKey: this.$route.query.key,
      redirect: this.$route.query?.redirect,
      claimedByMailIds: [],
      currentHeight: 0,
      showModal: false,
      selectedFileTemplate: {},
      selectedToMaildata: [],
      fileTemplateAttachments: [],
      toMailData: [],
      toSendTemplate: null,
      fileAttachmentsVisible: true,
    };
  },

  components: {
    FileUploadInput,
    TagsInput,
    MailToList,
    TemplateSelect,
    CommonRichTextBox,
    documentModal,
  },

  /**
   * @memberof module:MailBox.components.mail_box
   * @description lifecycle method - initializes default summernote
   */
  mounted() {
    this.updateHeight();

    this.observer = new MutationObserver(this.updateHeight);

    this.observer.observe(this.$refs.rowElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });
  },

  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.MAIL;
    },
    FiLE_TEMPLATE_PERMISSION() {
      return this.PERMISSIONS["FILE-TEMPLATE"].MENU;
    },
    ...mapGetters({
      mailTemplates: "$_mailbox/getTemplates",
      receivedApplicantsData: "$_mailbox/getApplicantsData",
      receivedCampusData: "$_mailbox/getCampusData",
      mailStatus: "$_mailbox/getMailStatus",
      fileTemplates: "$_file_template/getTemplates",
    }),

    /**
     * @memberof module:MailBox.components.mail_box
     * @returns {Boolean}
     * @description computed - checks whether applicant data is available or not
     */
    isApplicantDataAvailable: function () {
      return this.applicantsData.length !== 0;
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @returns {Boolean}
     * @description computed - checks whether campus data is available or not
     */
    isCampusDataAvailable: function () {
      return this.campusData !== null;
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @returns {Boolean}
     * @description computed - checks whether interviewer data is available or not
     */
    isInterviewerDataAvailable: function () {
      return (
        (this.isApplicantDataAvailable || this.isCampusDataAvailable) &&
        (this.templateKey === "Schedule Round" ||
          this.templateKey === "Reschedule") &&
        ((this.applicantsData[0] != undefined &&
          this.applicantsData[0].interviewers &&
          this.applicantsData[0].interviewers.length !== 0) ||
          (this.campusData !== null &&
            ((this.campusData.roundData &&
              this.campusData.roundData[0].interviewers &&
              this.campusData.roundData[0].interviewers.length !== 0) ||
              (this.campusData.applicantData[0] &&
                this.campusData.applicantData[0].interviewers &&
                this.campusData.applicantData[0].interviewers.length !== 0))))
      );
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @returns {Boolean}
     * @description computed - checks whether reviewer data is available or not
     */
    isReviewerDataAvailable: function () {
      return (
        (this.isApplicantDataAvailable || this.isCampusDataAvailable) &&
        (this.templateKey === "Schedule Round" ||
          this.templateKey === "Reschedule") &&
        ((this.applicantsData[0] != undefined &&
          this.applicantsData[0].reviewers &&
          this.applicantsData[0].reviewers.length !== 0) ||
          (this.campusData !== null &&
            ((this.campusData.roundData &&
              this.campusData.roundData[0].reviewers &&
              this.campusData.roundData[0].reviewers.length !== 0) ||
              (this.campusData.applicantData[0] &&
                this.campusData.applicantData[0].reviewers &&
                this.campusData.applicantData[0].reviewers.length !== 0))))
      );
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @returns {Boolean}
     * @description computed - checks whether mail subject is valid or not
     */
    isMailSubjectValid: function () {
      return this.mailData.mail_subject !== "";
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @returns {Boolean}
     * @description computed - checks whether form is valid or not
     */
    isFormValid: function () {
      if (this.isApplicantDataAvailable || this.isCampusDataAvailable) {
        return this.isMailSubjectValid;
      } else {
        return (
          this.isMailToValid &&
          this.isMailSubjectValid &&
          this.isMailCCValid &&
          this.isMailBCCValid
        );
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @returns {Array}
     * @description computed - placeholders for interviewer mail body
     */
    interviewerPlaceholders: function () {
      return this.coordinatorPlaceholders.concat("interviewee_list_table");
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @returns {Array}
     * @description computed - placeholders for reviewer mail body
     */
    reviewerPlaceholders: function () {
      return this.coordinatorPlaceholders.concat("reviewee_list_table");
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @returns {Array}
     * @description computed - placeholders for applicant|student mail body
     */
    finalPlaceholders: function () {
      let placeholders = this.applicantPlaceholders;
      if (
        this.templateKey === "Schedule Round" ||
        this.templateKey == "Reschedule"
      ) {
        placeholders = placeholders.concat(this.roundPlaceholders);
      } else if (this.templateKey === "Schedule Cancel") {
        placeholders.push("application_status");
      }
      return placeholders;
    },
  },

  watch: {
    "$refs.rowElement.clientHeight"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateHeight();
      }
    },
    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} value new value
     * @description watch - does required changes for sending mail to applicants
     */
    receivedApplicantsData: async function (value) {
      if (value) {
        if (this.templateKey == "RunCampaign") {
          this.isApplicantsMail = false;
        } else {
          this.isApplicantsMail = true;
          this.destroySummernote("default-summernote");
        }
        this.$nextTick(() => {
          $('[data-toggle="popover"]').popover();
          document.addEventListener("click", this.closePopovers);
          this.onChange("applicant-summernote", "", this.finalPlaceholders);
        });
        this.setApplicantsData(value);
        this.setMailToValues(false);
        this.bindRefreshAlert();
        this.$store.dispatch(
          "$_mailbox/setAvailableMailBodies",
          this.availableMailBodies
        );
        const claimedByMailIds = value
          .filter((val) => val.claimed_by)
          .map((val) => val.claimed_by);
        if (claimedByMailIds.length) {
          api
            .getClaimedMailIds(claimedByMailIds)
            .then((res) => {
              this.claimedByMailIds = res.data
                .map((applicant) => applicant.email_address)
                .filter(this.onlyUnique);
              this.mailData.mail_cc = this.claimedByMailIds;
            })
            .catch(() => {});
        }
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} value new value
     * @description watch - does required changes for sending mail to campus applicants
     */
    receivedCampusData: function (value) {
      if (value) {
        this.destroySummernote("default-summernote");
        this.isCampusMail = true;

        if (value.inititator === "fromschedules") {
          const finalApplicantData = [];

          value.applicantData.forEach((applicant) => {
            const applicantData = applicant.applicantIterationMaster;
            applicantData.interviewers = applicant.interviewers;
            applicantData.reviewers = applicant.reviewers;
            applicantData.round_id = applicant.id;
            applicantData.round_name = applicant.round_name;
            applicantData.round_schedule = applicant.round_schedule;
            finalApplicantData.push(applicantData);
          });

          this.campusData = {};
          this.campusData.applicantData = finalApplicantData;
          this.campusData.type = value.type;
          this.campusData.toBeMailed = {};
          this.campusData.toBeMailed.coordinatorEmails = value.toBeMailed;
        } else {
          if (value.roundData && value.roundData.length !== 0) {
            value.applicantData.forEach((applicant) => {
              applicant.round_id = value.roundData[0].id;
              applicant.round_name = value.roundData[0].round_name;
              applicant.round_schedule = value.roundData[0].round_schedule;
              applicant.moodleData = value.roundData[0].moodleData;
            });
          }
          this.campusData = value;
        }
        this.$nextTick(() => {
          $('[data-toggle="popover"]').popover();
          document.addEventListener("click", this.closePopovers);
          if (this.campusData.type === "coordinator") {
            this.onChange(
              "coordinator-summernote",
              "",
              this.coordinatorPlaceholders
            );
          } else if (this.campusData.type === "student") {
            this.onChange("student-summernote", "", this.finalPlaceholders);
          } else {
            this.onChange(
              "student-summernote",
              "",
              this.coordinatorPlaceholders
            );
            this.onChange("student-summernote", "", this.finalPlaceholders);
          }
        });
        this.bindRefreshAlert();
        this.setMailToValues(true);
        this.$store.dispatch(
          "$_mailbox/setAvailableMailBodies",
          this.availableMailBodies
        );
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} value new value
     * @description watch - initializes summernote for interviewer if its data is available
     */
    isInterviewerDataAvailable: function (value) {
      if (value) {
        this.$nextTick(() => {
          this.onChange(
            "interviewer-summernote",
            "",
            this.interviewerPlaceholders
          );
        });
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} value new value
     * @description watch - initializes summernote for reviewer if its data is available
     */
    isReviewerDataAvailable: function (value) {
      if (value) {
        this.$nextTick(() => {
          this.onChange("reviewer-summernote", "", this.reviewerPlaceholders);
        });
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} value new value
     * @description watch - handles response when mail is sent
     */
    mailStatus: function (value) {
      if (value === "SUCCESS") {
        if (!(this.isApplicantsMail || this.isCampusMail)) {
          this.resetMailData();
          this.doRender = false;
          this.$nextTick(() => {
            this.doRender = true;
          });
        } else {
          this.routerRedirect();
        }
      }
      this.$store.commit("$_mailbox/RESET_MAIL_STATUS");
    },
  },

  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    updateHeight() {
      this.currentHeight = this.$refs.rowElement.clientHeight;
      this.$emit("height-updated", this.currentHeight);
    },
    handleCloseModal(fileTemplateData) {
      if (fileTemplateData) {
        fileTemplateData.forEach((data) => {
          this.attachFileTemplates(data);
        });
      }
      this.showModal = false;

      this.selectedFileTemplate = {};
    },
    attachFileTemplates(data) {
      const selectedTemplate = this.fileTemplates.find(
        (template) => template.id == this.selectedFileTemplate?.id
      );
      if (selectedTemplate) {
        this.fileTemplateAttachments.push({
          name: selectedTemplate.file_name,
          for: data.user.email,
        });
        switch (data.user.type) {
          case "applicant": {
            this.applicantsData?.forEach((applicant, index) => {
              if (
                applicant?.id == data.user.id &&
                applicant?.email == data.user.email
              ) {
                if (
                  !Array.isArray(this.applicantsData[index]["file_template"])
                ) {
                  this.applicantsData[index]["file_template"] = [];
                }
                this.applicantsData[index]["file_template"].push(
                  data.fileTemplate
                );
              }
            });

            this.campusData?.applicantData?.forEach((applicant, index) => {
              if (
                applicant?.id == data.user.id &&
                applicant?.email == data.user.email
              ) {
                if (
                  !Array.isArray(
                    this.campusData.applicantData[index]["file_template"]
                  )
                ) {
                  this.campusData.applicantData[index]["file_template"] = [];
                }
                this.campusData.applicantData[index]["file_template"].push(
                  data.fileTemplate
                );
              }
            });
            break;
          }
          case "interviewer": {
            this.applicantsData?.forEach((applicant, index) => {
              applicant.interviewers.forEach((interviewer, index2) => {
                if (
                  interviewer.interviewer_id == data.user.id &&
                  interviewer.interviewer_email == data.user.email
                ) {
                  if (
                    !Array.isArray(
                      this.applicantsData[index].interviewers[index2][
                        "file_template"
                      ]
                    )
                  ) {
                    this.applicantsData[index].interviewers[index2][
                      "file_template"
                    ] = [];
                  }
                  this.applicantsData[index].interviewers[index2][
                    "file_template"
                  ].push(data.fileTemplate);
                }
              });
            });
            this.campusData?.roundData.forEach((round, index) => {
              round.interviewers.forEach((interviewer, index2) => {
                if (
                  interviewer.interviewer_id == data.user.id &&
                  interviewer.interviewer_email == data.user.email
                ) {
                  if (
                    !Array.isArray(
                      this.campusData.roundData[index].interviewers[index2][
                        "file_template"
                      ]
                    )
                  ) {
                    this.campusData.roundData[index].interviewers[index2][
                      "file_template"
                    ] = [];
                  }
                  this.campusData.roundData[index].interviewers[index2][
                    "file_template"
                  ].push(data.fileTemplate);
                }
              });
            });
            break;
          }
          case "reviewer": {
            this.applicantsData?.forEach((applicant, index) => {
              applicant.reviewers.forEach((reviewer, index2) => {
                if (
                  reviewer.reviewer_id == data.user.id &&
                  reviewer.reviewer_email == data.user.email
                ) {
                  if (
                    !Array.isArray(
                      this.applicantsData[index].reviewers[index2][
                        "file_template"
                      ]
                    )
                  ) {
                    this.applicantsData[index].reviewers[index2][
                      "file_template"
                    ] = [];
                  }
                  this.applicantsData[index].reviewers[index2][
                    "file_template"
                  ].push(data.fileTemplate);
                }
              });
            });
            this.campusData?.roundData?.forEach((round, index) => {
              round.reviewers.forEach((reviewer, index2) => {
                if (
                  reviewer.reviewer_id == data.user.id &&
                  reviewer.reviewer_email == data.user.email
                ) {
                  if (
                    !Array.isArray(
                      this.campusData.roundData[index].reviewers[index2][
                        "file_template"
                      ]
                    )
                  ) {
                    this.campusData.roundData[index].reviewers[index2][
                      "file_template"
                    ] = [];
                  }
                  this.campusData.roundData[index].reviewers[index2][
                    "file_template"
                  ].push(data.fileTemplate);
                }
              });
            });
            break;
          }
          case "coordinator": {
            this.campusData.toBeMailed.coordinatorEmails.forEach(
              (coordinator, index) => {
                if (
                  coordinator.id == data.user.id &&
                  coordinator.email == data.user.email
                ) {
                  if (
                    !Array.isArray(
                      this.campusData.toBeMailed.coordinatorEmails[index][
                        "file_template"
                      ]
                    )
                  ) {
                    this.campusData.toBeMailed.coordinatorEmails[index][
                      "file_template"
                    ] = [];
                  }
                  this.campusData.toBeMailed.coordinatorEmails[index][
                    "file_template"
                  ].push(data.fileTemplate);
                }
              }
            );
            break;
          }
        }
      }
    },
    toggleFileAttachmentsView() {
      this.fileAttachmentsVisible = !this.fileAttachmentsVisible;
    },
    updateFileTemplate() {
      this.showModal = true;
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    },
    // eslint-disable-next-line no-unused-vars
    onChange(modalId, contents, placeholders = []) {
      this.availableMailBodies.push(modalId);
      switch (modalId) {
        case "default-summernote":
          this.defaultSummernoteBody = contents;
          break;
        case "applicant-summernote":
          this.applicantSummenoteBody = contents;
          break;
        case "coordinator-summernote":
          this.coordinatorSummernoteBody = contents;
          break;
        case "student-summernote":
          this.studentSummernoteBody = contents;
          break;
        case "interviewer-summernote":
          this.interviewerSummernoteBody = contents;
          break;
        case "reviewer-summernote":
          this.reviewerSummernoteBody = contents;
          break;
      }
    },
    /**
     * @memberof module:MailBox.components.mail_box
     * @param {String} summernoteId id attribute of summernote
     * @description method - destroys summernote for given summernoteId
     */
    destroySummernote(summernoteId) {
      const summernoteIdentifier = "#" + summernoteId;
      $(summernoteIdentifier).summernote("destroy");

      const index = this.availableMailBodies.indexOf(summernoteId);
      if (index >= 0) {
        this.availableMailBodies.splice(index, 1);
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param applicant id attribute of summernote
     * @description method - push data into interviewerMailToList
     */
    pushIntoInterviewerMailToList(applicant) {
      applicant.interviewers.forEach((interviewer) => {
        if (
          !this.interviewerMailToList.includes(interviewer.interviewer_email)
        ) {
          this.interviewerMailToList.push(interviewer.interviewer_email);
          this.toMailData.push({
            email: interviewer.interviewer_email,
            type: "interviewer",
            id: interviewer.interviewer_id,
          });
        }
      });
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param applicant id attribute of summernote
     * @description method - push data into reviewerMailToList
     */
    pushIntoReviewerMailToList(applicant) {
      applicant.reviewers.forEach((reviewer) => {
        if (!this.reviewerMailToList.includes(reviewer.reviewer_email)) {
          this.reviewerMailToList.push(reviewer.reviewer_email);
          this.toMailData.push({
            email: reviewer.reviewer_email,
            type: "reviewer",
            id: reviewer.reviewer_id,
          });
        }
      });
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @description method - push data if Interviewer data available
     */
    pushIfInterviewerDataAvailable() {
      if (this.receivedCampusData.inititator === "fromschedules") {
        this.campusData.applicantData.forEach((applicant) => {
          this.pushIntoInterviewerMailToList(applicant);
        });
      } else {
        this.campusData.roundData[0].interviewers.forEach((interviewer) => {
          this.interviewerMailToList.push(interviewer.interviewer_email);
          this.toMailData.push({
            email: interviewer.interviewer_email,
            type: "interviewer",
            id: interviewer.interviewer_id,
          });
        });
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @description method - push data if Reviewer data available
     */
    pushIfReviewerDataAvailable() {
      if (this.receivedCampusData.inititator === "fromschedules") {
        this.campusData.applicantData.forEach((applicant) => {
          this.pushIntoReviewerMailToList(applicant);
        });
      } else {
        this.campusData.roundData[0].reviewers.forEach((reviewer) => {
          this.reviewerMailToList.push(reviewer.reviewer_email);
          this.toMailData.push({
            email: reviewer.reviewer_email,
            type: "reviewer",
            id: reviewer.reviewer_id,
          });
        });
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} receivedData received applicants data
     * @description method - manipulates received data for further usage
     */
    setApplicantsData(receivedData) {
      if (
        this.templateKey === "Schedule Round" ||
        this.templateKey == "Reschedule"
      ) {
        receivedData.forEach((data) => {
          const applicant = data.applicantIterationMaster;
          applicant.round_id = data.id;
          applicant.round_name = data.round_name;
          applicant.round_schedule = data.round_schedule;
          if (data.interviewers) {
            applicant.interviewers = data.interviewers;
          }
          if (data.reviewers) {
            applicant.reviewers = data.reviewers;
          }

          this.applicantsData.push(applicant);
        });
      } else if (this.templateKey === "Schedule Cancel") {
        receivedData.forEach((data) => {
          const applicant = data.applicantIterationMaster;
          applicant.application_status = data.application_status;
          applicant.round_id = data.id;
          this.applicantsData.push(applicant);
        });
      } else if (this.templateKey === "Application Status") {
        this.applicantsData = receivedData;
      } else if (this.templateKey === "RunCampaign") {
        let sendMailTo = [];
        receivedData.forEach((value) => {
          sendMailTo.push(value.email);
        });
        this.mailData.mail_bcc = sendMailTo.filter(this.onlyUnique);
      } else if (this.templateKey === "MailTo") {
        this.applicantsData = receivedData;
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Boolean} isCampus it is mail for campus applicants or not
     * @description method - sets array for mail-to-list components
     */
    setMailToValues(isCampus) {
      if (isCampus) {
        switch (this.campusData.type) {
          case "coordinator":
            this.setCoordinatorMailList();
            break;
          case "student":
            this.setApplicantMailToList();
            break;
          default:
            this.setCoordinatorMailList();
            this.setApplicantMailToList();
            break;
        }

        if (this.isInterviewerDataAvailable) {
          this.pushIfInterviewerDataAvailable();
        } else if (this.isReviewerDataAvailable) {
          this.pushIfReviewerDataAvailable();
        }
      } else {
        if (this.isInterviewerDataAvailable) {
          this.applicantsData.forEach((applicant) => {
            this.applicantMailToList.push(applicant.email);
            this.selectedToMaildata.push({
              ...applicant,
              type: "applicant",
            });

            this.pushIntoInterviewerMailToList(applicant);
          });
        } else if (this.isReviewerDataAvailable) {
          this.applicantsData.forEach((applicant) => {
            this.applicantMailToList.push(applicant.email);
            this.selectedToMaildata.push({
              ...applicant,
              type: "applicant",
            });
            this.pushIntoReviewerMailToList(applicant);
          });
        } else {
          this.applicantsData.forEach((applicant) => {
            this.applicantMailToList.push(applicant.email);
            this.selectedToMaildata.push({
              ...applicant,
              type: "applicant",
            });
          });
        }
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @description method - sets array for coordinator mail-to-list component
     */
    setCoordinatorMailList() {
      this.campusData.toBeMailed.coordinatorEmails.forEach((coordinator) => {
        this.coordinatorMailToList.push(coordinator.email);
        this.toMailData.push({
          email: coordinator.email,
          type: "coordinator",
          id: coordinator.id,
        });
      });
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @description method - sets array for applicants mail-to-list component
     */
    setApplicantMailToList() {
      this.campusData.applicantData.forEach((applicant) => {
        this.applicantMailToList.push(applicant.email);
        this.selectedToMaildata.push({
          ...applicant,
          type: "applicant",
        });
      });
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} event consists of ID of summenote and template data
     * @description method - fills mail details by received template data
     */
    fillMailDetails(event) {
      const mailBodyId = event.targetId;
      const template = event.template;

      if (template.cc && template.cc.length > 0)
        this.mailData.mail_cc = [
          ...this.claimedByMailIds,
          ...template.cc,
        ].filter(this.onlyUnique);
      if (template.bcc && template.bcc.length > 0)
        this.mailData.mail_bcc = [
          ...this.mailData.mail_bcc,
          ...template.bcc,
        ].filter(this.onlyUnique);

      this.mailData.mail_subject = template.template_subject;
      $(mailBodyId).summernote("code", template.template_body);
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} event received from tags-input component
     * @description method - updates mail_to data value when tags-input fires update event
     */
    updateToValues(event) {
      this.mailData.mail_to = event.values;
      this.isMailToValid = event.isValid;
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} event received from tags-input component
     * @description method - updates mail_cc data value when tags-input fires update event
     */
    updateCcValues(event) {
      this.mailData.mail_cc = event.values;
      this.isMailCCValid = event.isValid;
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {Object} event received from tags-input component
     * @description method - updates mail_bcc data value when tags-input fires update event
     */
    updateBccValues(event) {
      this.mailData.mail_bcc = event.values;
      this.isMailBCCValid = event.isValid;
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @param {File} file received from file-upload-input component
     * @description method - updates mail_attachments data value when file-upload-input fires file changed event
     */
    fileChanged(files) {
      this.mailData.mail_attachments = files;
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @description method - manipulates mailData and sends it
     */
    sendMail() {
      let mailData;

      if (
        !(
          this.isApplicantsMail ||
          this.isCampusMail ||
          this.isReviewerDataAvailable
        )
      ) {
        mailData = {
          mail_type: "normal",
          mail_to: this.mailData.mail_to,
          mail_cc: this.mailData.mail_cc,
          mail_bcc: this.mailData.mail_bcc,
          mail_subject: this.mailData.mail_subject,
          mail_body: this.defaultSummernoteBody,
          mail_attachment: this.mailData.mail_attachments,
        };
      } else if (this.isApplicantsMail) {
        mailData = {
          mail_type: "applicant",
          mail_cc: this.mailData.mail_cc,
          mail_bcc: this.mailData.mail_bcc,
          mail_subject: this.mailData.mail_subject,
          mail_attachment: this.mailData.mail_attachments,
          data: {
            applicant_template: this.applicantSummenoteBody,
            applicantsData: this.applicantsData,
          },
        };
        if (this.isInterviewerDataAvailable) {
          mailData.data.interviewer_template = this.interviewerSummernoteBody;
        } else if (this.isReviewerDataAvailable) {
          mailData.data.reviewer_template = this.reviewerSummernoteBody;
        }
      } else {
        mailData = {
          mail_type: "campus",
          mail_cc: this.mailData.mail_cc,
          mail_bcc: this.mailData.mail_bcc,
          mail_subject: this.mailData.mail_subject,
          mail_attachment: this.mailData.mail_attachments,
          data: {
            type: this.campusData.type,
            applicantsData: this.campusData.applicantData,
            roundData: [],
          },
        };
        if (this.receivedCampusData.inititator !== "fromschedules") {
          mailData.data.roundData = this.campusData.roundData;
        }
        if (this.isInterviewerDataAvailable) {
          mailData.data.interviewer_template = this.interviewerSummernoteBody;
        }
        if (this.isReviewerDataAvailable) {
          mailData.data.reviewer_template = this.reviewerSummernoteBody;
        }

        switch (this.campusData.type) {
          case "coordinator":
            mailData.data.coordinatorData =
              this.campusData.toBeMailed.coordinatorEmails;
            mailData.data.coordinator_template = this.coordinatorSummernoteBody;
            mailData.data.applicant_template = "";
            break;
          case "student":
            mailData.data.applicant_template = this.studentSummernoteBody;
            break;
          default:
            mailData.data.coordinatorData =
              this.campusData.toBeMailed.coordinatorEmails;
            mailData.data.coordinator_template = this.coordinatorSummernoteBody;
            mailData.data.applicant_template = this.studentSummernoteBody;
            break;
        }
      }
      this.$store.dispatch("$_mailbox/sendMail", mailData);
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @description method - resets data and compose mail fields
     */
    resetMailData() {
      this.mailData.mail_to = [];
      this.mailData.mail_cc = [];
      this.mailData.mail_bcc = [];
      this.mailData.mail_subject = "";
      this.defaultSummernoteBody = "";
      this.applicantSummenoteBody = "";
      this.studentSummernoteBody = "";
      this.coordinatorSummernoteBody = "";
      this.interviewerSummernoteBody = "";
      this.reviewerSummernoteBody = "";
      $("#default-summernote").summernote("code", "");
      $("#applicant-summernote").summernote("code", "");
      $("#student-summernote").summernote("code", "");
      $("#coordinator-summernote").summernote("code", "");
      $("#interviewer-summernote").summernote("code", "");
      $("#reviewer-summernote").summernote("code", "");

      this.isMailSubjectTouched = false;
      this.routerRedirect();
    },
    routerRedirect() {
      if (this.redirect) {
        if (this.redirect.includes("/")) {
          this.$router.push(this.redirect);
        } else {
          this.$router.push({ name: this.redirect });
        }
      } else {
        this.$router.push({ name: "applicants" });
      }
    },

    closePopovers(e) {
      if (
        typeof $(e.target).data("original-title") == "undefined" &&
        !$(e.target).parents().is(".popover.in")
      ) {
        $("[data-original-title]").popover("hide");
      }
    },

    /**
     * @memberof module:MailBox.components.mail_box
     * @description method - binds beforeunload event when applicants or campus data is passed from other components to avoid unwanted data lost locally
     */
    bindRefreshAlert() {
      $(window).on("beforeunload", function () {
        return "Please do not reload this page, else the data on this page will be lost!";
      });
    },
  },
  updated() {
    this.updateHeight();
  },
  /**
   * @memberof module:MailBox.components.mail_box
   * @description lifecycle method - unbinds beforeunload event callback
   */
  beforeDestroy() {
    this.observer.disconnect();
    $(window).off("beforeunload");
    document.removeEventListener("click", this.closePopovers);
  },
};
</script>

<style scoped>
ul.nav li {
  margin: 5px;
}

.mail-entity-container {
  padding: 3px;
  padding-bottom: 0px;
  border: 1px solid lightgray;
  border-radius: 8px;
  background: #fff;
  margin: 0px 10px 0px 10px;
}

.mail-label-container {
  margin-bottom: 1px;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 3px 0px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 10px 0px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.template-selector {
  margin-top: 0px;
  width: 25%;
  height: 35px;
  margin-left: 74%;
}

.mail-label-container label {
  margin-top: 7px;
}
.clickable {
  cursor: pointer;
  user-select: none;
}
.chevron-icon {
  transition: transform 0.3s ease;
  font-size: 12px;
  margin-left: 10px;
}
.toggle-section {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}
.down {
  transform: rotate(180deg);
}
</style>
