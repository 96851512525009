<template>
  <div>
    <home-header class="top"></home-header>
    <application-status-modal></application-status-modal>
    <div class="common-margin">
      <div class="container-fluid page-bg main-container nopadding">
        <div class="text-center mt-30">
          <img
            class="logoSize"
            id="argus-logo"
            src="../../assets/images/newLogo.png"
            alt="Argusoft Logo"
          />
        </div>
        <h1>404</h1>
        <div class="container">
          <h3>Page not found</h3>
          <hr />
          <p>Make sure you are at correct address.</p>
          <p>
            Please contact your Argusoft administrator if you think this is a
            mistake.
          </p>
          <router-link
            :to="{
              name: routerName,
            }"
            >Go to
            {{ routerName === "home" ? "Homepage" : "Dashboard" }}</router-link
          >
        </div>
      </div>
    </div>
    <index></index>
    <footer-section></footer-section>
  </div>
</template>

<script>
import FooterSection from "../Common/_components/footer-section.vue";
import Index from "../Home/index.vue";
import HomeHeader from "../Common/_components/home-header.vue";
import ApplicationStatusModal from "../Common/_components/application-status-modal.vue";
import VueCookie from "vue-cookie";
import { mapGetters } from "vuex";

export default {
  components: { FooterSection, Index, HomeHeader, ApplicationStatusModal },
  mounted() {
    $("#navmenu").hide();
    $("#footer").hide();
  },
  computed: {
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
    }),
    routerName() {
      if (VueCookie.get("RECRUITER_ACCESS_TOKEN")) {
        return "RecruiterApplicantData";
      } else if (VueCookie.get("APPLICANT_ACCESS_TOKEN")) {
        return "applicantApplications";
      } else if (this.loggedInUser) {
        return "homepage";
      } else {
        return "home";
      }
    },
  },
};
</script>

<style scoped>
.common-margin {
  margin-top: 75px;
}
.main-container {
  color: #666;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: auto;
  font-size: 14px;
}

h1 {
  font-size: 56px;
  line-height: 100px;
  font-weight: 400;
  color: #456;
}

h3 {
  color: #456;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

hr {
  max-width: 800px;
  margin: 18px auto;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid white;
}

img {
  max-width: 40vw;
  display: block;
  margin: 40px auto;
}

a {
  line-height: 100px;
  font-weight: 400;
  color: #4a8bee;
  font-size: 18px;
  text-decoration: none;
}

.top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
</style>
