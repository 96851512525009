import { render, staticRenderFns } from "./base-list.vue?vue&type=template&id=588a1e59&scoped=true"
import script from "./base-list.vue?vue&type=script&lang=js"
export * from "./base-list.vue?vue&type=script&lang=js"
import style0 from "./base-list.vue?vue&type=style&index=0&id=588a1e59&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588a1e59",
  null
  
)

export default component.exports