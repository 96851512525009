/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set applicants data in store
 */
const FETCH_APPLICANTS_DATA = (state, data) => {
  state.applicantsData = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set application status and their count in store
 */
const FETCH_APPLICATION_STATUS_COUNT = (state, data) => {
  state.applicationStatusCount = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set application position and their count in store
 */
const FETCH_APPLICATION_POSITION_COUNT = (state, data) => {
  state.applicationPositionCount = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set application employment status and their count in store
 */
const FETCH_APPLICATION_EMPLOYMENT_STATUS_COUNT = (state, data) => {
  state.applicationEmploymentStatusCount = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {String} data
 * @description Set count of fresh professional applications
 */
const FETCH_FRESH_PROFESSIONAL_APPLICATION_COUNT = (state, data) => {
  state.freshProfessionalsCount = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {String} data
 * @description Set count of fresh graduate applications
 */
const FETCH_FRESH_GRADUATE_APPLICATION_COUNT = (state, data) => {
  state.freshGraduatesCount = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Store selected applicants from grid in store
 */
const STORE_SELECTED_APPLICANTS = (state, data) => {
  state.selectedApplicants.length = 0;
  state.selectedApplicants = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Object} filterData
 * @description Store the filter data in store
 */
const STORE_FILTER_DATA = (state, filterData) => {
  state.filterData = filterData;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @description Reset the filter data in store
 */
const RESET_FILTER_DATA = (state) => {
  state.filterData = {};
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {String} data
 * @description Set marker to check whether round has been added succesfully or not
 */
const CONFIRM_ROUND_SCHEDULE_STATUS = (state, data) => {
  state.confirmRoundScheduleStatus = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @description Set marker to check whether data in grid has been changed in store
 */
const UPDATE_APPLICATION_STATUS = (state) => {
  state.applicantsDataStatus = !state.applicantsDataStatus;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} campusData
 * @description Set campus mails in store
 */
const SET_CAMPUS_MAILS = (state, campusData) => {
  state.campusData = campusData;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set registered application status and their count in store
 */
const GET_REG_APPLICANT_STATUS_COUNT = (state, data) => {
  state.registeredApplicantStatusCount = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set open job positions
 */
const FETCH_OPEN_JOB_POSITIONS = (state, data) => {
  state.openJobPositions = data;
};

/**
 * @memberof module:ApplicantsData.store.mutations
 * @param {*} state
 * @param {*} data
 * @description Set the all filtered applicants data
 */
const FETCH_FILTERED_APPLICANTS_DATA = (state, data) => {
  state.filteredApplicantData = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set shortlisted applicants data
 */
const FETCH_SHORTLISTED_APPLICANTS_DATA = (state, data) => {
  state.shortlistedApplicantsData = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} Applicants Data
 * @description Set joined applicants data
 */
const FETCH_JOINED_APPLICANTS_DATA = (state, data) => {
  state.joinedApplicantsData = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set user mail data
 */
const FETCH_USER_MAILS = (state, data) => {
  state.userMailData = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set mail attachment
 */
const FETCH_ATTACHMENT = (state, data) => {
  state.attachment = data;
};

/* @memberof:module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Object} Applicants Data
 * @description Set user specific notification
 */
const FETCH_USER_NOTIFICATIONS = (state, data) => {
  state.userNotification = data;
};

/**
 * @memberOf module:ApplicantsData.store.mutations
 * @param {Object} state
 * @param {Array} data
 * @description Set pending round schedules of applicant from current date in store
 */
const FETCH_ROUND_SCHEDULES = (state, data) => {
  state.roundSchedules = data;
};

const FETCH_FOLLOWUPS = (state, data) => {
  state.followups = data;
};

const FETCH_LINK_REQUESTS = (state, data) => {
  state.linkRequests = data;
};

const UPDATE_LINK_REQUESTS = (state, data) => {
  state.linkRequests.splice(
    state.linkRequests.findIndex((linkRequest) => {
      return linkRequest.id === data.id;
    })
  );
};

const FETCH_APPLICANT_MASTER_DATA = (state, data) => {
  state.applicantMasterData = data;
};

/**
 * @memberOf module:ApplicantsData
 * @namespace store.mutations
 * @description mutations for Applicants data
 */
export default {
  FETCH_APPLICANTS_DATA,
  FETCH_APPLICATION_STATUS_COUNT,
  FETCH_APPLICATION_POSITION_COUNT,
  FETCH_APPLICATION_EMPLOYMENT_STATUS_COUNT,
  FETCH_FRESH_GRADUATE_APPLICATION_COUNT,
  FETCH_FRESH_PROFESSIONAL_APPLICATION_COUNT,
  FETCH_OPEN_JOB_POSITIONS,
  FETCH_FILTERED_APPLICANTS_DATA,
  FETCH_SHORTLISTED_APPLICANTS_DATA,
  FETCH_JOINED_APPLICANTS_DATA,
  STORE_FILTER_DATA,
  RESET_FILTER_DATA,
  STORE_SELECTED_APPLICANTS,
  CONFIRM_ROUND_SCHEDULE_STATUS,
  UPDATE_APPLICATION_STATUS,
  SET_CAMPUS_MAILS,
  GET_REG_APPLICANT_STATUS_COUNT,
  FETCH_USER_MAILS,
  FETCH_ATTACHMENT,
  FETCH_USER_NOTIFICATIONS,
  FETCH_ROUND_SCHEDULES,
  FETCH_FOLLOWUPS,
  FETCH_LINK_REQUESTS,
  UPDATE_LINK_REQUESTS,
  FETCH_APPLICANT_MASTER_DATA,
};
