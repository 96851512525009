<template>
  <div
    class="modal fade"
    id="addFollowUp"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ModalTitle">FollowUp Details</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group">
              <label class="col-sm-3 control-label"
                >Status
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <select class="form-control" v-model="followUp.status">
                  <option>Called</option>
                  <option>Did not take the call</option>
                  <option>Spoke to the candidate</option>
                  <option>Not reachable</option>
                  <option>Not looking for a job</option>
                  <option>Call back</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label"
                >Remarks
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <textarea
                  class="form-control"
                  v-model="followUp.remarks"
                  style="height: 100px; width: 100%"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-6 control-label"
                >Schedule Next Follow Up
              </label>
              <div class="col-sm-9">
                <div class="input-group">
                  <date-picker
                    v-model="followUp.schedule"
                    :config="config"
                    id="datePickerElement"
                    :minDate="new Date()"
                    @dp-hide="datePickerRequired"
                  >
                  </date-picker>
                  <span class="input-group-addon" @click="toggleDatePicker">
                    <i class="fa fa-calendar m-2" aria-hidden="false"> </i>
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="btn btn-dark"
            data-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>

          <button
            type="button"
            class="btn btn-cp ml-1"
            data-dismiss="modal"
            @click="addFollowUp"
            :disabled="!followUp.status || !followUp.remarks"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-cp ml-1"
            data-dismiss="modal"
            @click="addFollowUpAndMail"
            :disabled="!followUp.status || !followUp.remarks"
          >
            Save And Mail
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import datePicker from "vue-bootstrap-datetimepicker";

export default {
  props: ["applicantData"],
  data() {
    return {
      followUp: {
        status: "",
        remarks: "",
        schedule: null,
        applicantId: this.applicantData.id,
        active_status: 1,
      },
      config: {
        format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        minDate: new Date(),
        sideBySide: true,
      },
      datePickerFlag: false,
    };
  },
  components: {
    datePicker,
  },
  computed: {
    ...mapGetters({
      id: "$_applicant_profile/fetchID",
    }),
  },
  watch: {},
  mounted() {
    // to reset the followup
    $("#addFollowUp").on("hidden.bs.modal", () => {
      this.resetFollowUp();
    });
  },
  methods: {
    resetFollowUp() {
      this.followUp = {
        status: "",
        remarks: "",
        schedule: null,
        applicantId: this.applicantData.id,
        active_status: 1,
      };
    },
    datePickerRequired() {
      if (!this.followUp.schedule) {
        this.datePickerFlag = true;
      } else {
        this.datePickerFlag = false;
      }
    },
    cancel() {
      let followUp = {
        status: "",
        remarks: "",
        schedule: null,
        applicantId: this.applicantData.id,
        active_status: 1,
      };
      this.followUp = followUp;
    },

    addFollowUp() {
      this.followUp.applicantId = this.id;
      if (this.followUp.schedule) {
        this.followUp.active_status = 0;
      }
      this.$store.dispatch("$_applicant_profile/addFollowUp", this.followUp);
    },
    addFollowUpAndMail() {
      this.followUp.applicantId = this.id;
      if (this.followUp.schedule) {
        this.followUp.active_status = 0;
      }
      this.$store.dispatch("$_applicant_profile/addFollowUpAndMail", {
        data: this.followUp,
        redirect: this.$route.fullPath,
      });
    },
    toggleDatePicker() {
      $("#datePickerElement").data("DateTimePicker").toggle();
    },
  },
  beforeDestroy() {
    $("#addFollowUp").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
