var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.hasAccess({
      permissions: [_vm.PERMISSIONS['SHARED-TOKEN'].MENU],
      checkAll: false,
    })
  )?_c('div',{staticClass:"content"},[_c('div',{staticClass:"page-header"},[_c('common-header',{attrs:{"data":['home', 'manage', 'sharedToken']}},[(
          _vm.hasAccess({
            permissions: [_vm.PERMISSIONS['SHARED-TOKEN'].CREATE],
            checkAll: false,
          })
        )?_c('div',{staticClass:"text-right"},[_c('a',{staticClass:"btn btn-cp",on:{"click":_vm.goToAddTokenPage}},[_c('i',{staticClass:"fa fa-plus mr-2",attrs:{"aria-hidden":"true"}}),_vm._v("Create")])]):_vm._e()])],1),_c('div',{staticClass:"container-fluid py-2"},[_c('ag-grid-vue',{staticClass:"ag-theme-alpine ag-grid-style ag-grid-footer-style",attrs:{"gridOptions":_vm.gridOptions,"rowData":_vm.sharedTokens,"columnDefs":_vm.columnDefs}})],1),_c('DeleteModal',{attrs:{"id":_vm.id}}),_c('EndpointModal',{ref:"endpointModal",attrs:{"selectedEndpoints":_vm.selectedEndpoints},on:{"update:selectedEndpoints":_vm.updateSelectedEndpoints}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }