import axios from "axios";

const prefix = "/applicants";

const fetchDashboardStats = () => {
  return axios.get("/dashboard_stats");
};

const fetchApplicantsDataByStatus = (data) => {
  return axios.post(prefix + "/by/status", data);
};

const fetchHiringPipelineStats = (filterOptions) => {
  return axios.get(prefix + "/hiring-pipeline-stats", {
    params: filterOptions,
  });
};

export default {
  fetchDashboardStats,
  fetchApplicantsDataByStatus,
  fetchHiringPipelineStats,
};
