<template>
  <div id="container" class="index-style">
    <router-view></router-view>
  </div>
</template>

<script>
import store from "./_store";
import mailboxstore from "../MailBox/_store";
import applicantProfileStore from "../ApplicantProfile/_store";
import formTemplateStore from "../FormTemplate/_store";
import campusRecruitmentStore from "../CampusRecruitment/_store";
import scheduleDashboardStore from "../ScheduleDashboard/_store";

/**
 * @module ApplicantsData
 */
export default {
  /**
   * @memberOf module:ApplicantsData
   * @namespace components
   * @description components of Applicants data
   */
  components: {},
  created() {
    const STORE_KEY = "$_applicants_data";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }

    const MAILBOX_STORE_KEY = "$_mailbox";
    if (!(MAILBOX_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(MAILBOX_STORE_KEY, mailboxstore);
    }
    const CAMPUS_RECRUITMENT_STORE_KEY = "$_campus_recruitment";
    if (
      !(CAMPUS_RECRUITMENT_STORE_KEY in this.$store._modules.root._children)
    ) {
      this.$store.registerModule(
        CAMPUS_RECRUITMENT_STORE_KEY,
        campusRecruitmentStore
      );
    }
    const FORM_TEMPLATE_STORE_KEY = "$_form_template";
    if (!(FORM_TEMPLATE_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(FORM_TEMPLATE_STORE_KEY, formTemplateStore);
    }
    const APPLICANT_PROFILE_STORE_KEY = "$_applicant_profile";
    if (!(APPLICANT_PROFILE_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(
        APPLICANT_PROFILE_STORE_KEY,
        applicantProfileStore
      );
    }
    const SCHEDULE_DASHBOARD_STORE_KEY = "$_schedule_dashboard";
    if (
      !(SCHEDULE_DASHBOARD_STORE_KEY in this.$store._modules.root._children)
    ) {
      this.$store.registerModule(
        SCHEDULE_DASHBOARD_STORE_KEY,
        scheduleDashboardStore
      );
    }
  },
};
</script>
<style>
.index-style {
  height: 100%;
}
</style>
