/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state
 * @description Fetch the count of each application status
 */
const applicationStatusCount = (state) => state.applicationStatusCount;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state
 * @description Fetch the count of each application position
 */
const applicationPositionCount = (state) => state.applicationPositionCount;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state
 * @description fetch the user specific notifications
 */
const fetchRecruiterNotifications = (state) => state.recruiterNotification;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state
 * @description get the session list
 */
const getSessionList = (state) => state.sessionList;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state
 * @description get the selected session
 */
const getSelectedSession = (state) => state.selectedSession;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state
 * @description get logged in user
 */
const getLoggedInUser = (state) => state.loggedInUser;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state
 * @description get entity details with sessions
 */
const getEntityListDetails = (state) => state.entityListDetails;

/**
 * @memberof module:campusRecruitment.store.getters
 * @param {Object} state
 * @description get selected college
 */
const getSelectedCollege = (state) => state.selectedCollege;

/**
 * @memberof module:campusRecruitment
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  applicationStatusCount,
  applicationPositionCount,
  fetchRecruiterNotifications,
  getSessionList,
  getSelectedSession,
  getLoggedInUser,
  getEntityListDetails,
  getSelectedCollege,
};
