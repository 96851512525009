var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.params.data != undefined && _vm.params.data.hasDuplicate.length != 0)?_c('a',[_c('i',{staticClass:"fa fa-link",staticStyle:{"color":"red"},attrs:{"data-toggle":"tooltip","data-placement":"right","title":'Conflicts found for ' +
        _vm.params.data.first_name +
        ' ' +
        _vm.params.data.last_name +
        '.(unable to create rounds)',"aria-hidden":"true"}})]):_vm._e(),(
      _vm.params.data != undefined &&
      _vm.params.data.hasDuplicate.length == 0 &&
      _vm.params.data.status == 'Duplicate'
    )?_c('a',[_c('i',{staticClass:"fa fa-files-o",staticStyle:{"color":"red"},attrs:{"data-toggle":"tooltip","data-placement":"right","title":"Duplicate applicant.(unable to create rounds)","aria-hidden":"true"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }