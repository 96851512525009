<template>
  <div class="mx-0 commonPadding">
    <div class="addDetailsContainer" v-if="!isForEditData">
      <div class="addDetailsHeader">
        <div class="addDetailsImage">
          <b-icon icon="file-earmark" class="h3" aria-hidden="true"></b-icon>
        </div>
        <div class="addDetailItem">
          <div class="addDetailItemTitle">Add Certificate / Course Details</div>
          <div class="addDetailItemSubtitle">
            All Certifications / Courses you have done
          </div>
        </div>
      </div>
      <button
        v-b-modal.add-course-modal
        type="button"
        @click="addCourse"
        class="btn btn-cp ml-2"
      >
        Add
      </button>
    </div>
    <delete-modal
      :modal-id="'deleteModalCourses'"
      item="Course"
      :on-delete="removeCourse"
      :item-id="courseId"
    ></delete-modal>
    <div
      v-if="
        isApplicantLoggedIn &&
        !isApplicantProfile &&
        applicantData.courseDetails.length > 0
      "
      class="addDetailItemSubtitle mb-4"
      style="color: #d4205a"
    >
      Click on the checkbox to select the courses you want to consider for the
      job application.
    </div>
    <div class="table-responsive" v-if="applicantData.courseDetails.length > 0">
      <table
        class="table table-striped table-hover"
        aria-describedby="Course Details Table"
      >
        <thead>
          <tr>
            <th v-if="isApplicantLoggedIn && !isApplicantProfile"></th>
            <th>#</th>
            <th>Course Title</th>
            <th>Description</th>
            <th>Certification Link</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th v-if="!isForEditData">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(course, index) in applicantData.courseDetails"
            :key="index"
          >
            <td v-if="isApplicantLoggedIn && !isApplicantProfile">
              <input
                style="margin-top: 6px"
                type="checkbox"
                class="form-check-input mx-auto"
                :checked="isChecked(course.id)"
                @change="handleCheckboxChange(course.id)"
              />
            </td>
            <td>{{ index + 1 }}</td>
            <td>
              {{
                course.title.length > 20
                  ? course.title.slice(0, 20) + "..."
                  : course.title
              }}
            </td>
            <td>
              {{
                course.description.length > 20
                  ? course.description.slice(0, 20) + "..."
                  : course.description
              }}
            </td>
            <td>
              <safe-link
                v-if="
                  course.certificationLink &&
                  isValidUrl(course.certificationLink)
                "
                :link="course.certifcationLink"
              >
                <b-icon icon="link" variant="success"></b-icon>
              </safe-link>
              <span v-else>NA</span>
            </td>
            <td>{{ course.startDate }}</td>
            <td>{{ course.endDate }}</td>
            <td v-if="!isForEditData" class="d-flex">
              <button
                type="button"
                v-b-modal.add-course-modal
                class="btn btn-sm"
                title="Edit"
                @click="editCourse(index)"
              >
                <b-icon icon="pencil-square" variant="primary"></b-icon>
              </button>

              <button
                class="btn btn-sm"
                id="delete_job_icon"
                href="#"
                type="button"
                data-toggle="modal"
                data-target="#deleteModalCourses"
                data-backdrop="static"
                data-keyboard="false"
                @click="setCourseId(isApplicantLoggedIn ? course.id : index)"
              >
                <b-icon icon="trash" variant="primary"></b-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <h5
      class="text-center"
      v-if="isForEditData && applicantData.courseDetails.length === 0"
    >
      No course details added yet.
    </h5>
    <b-modal
      id="add-course-modal"
      no-close-on-backdrop
      ref="modal"
      :title="course.headline"
      scrollable
      @ok.prevent="submitCourse"
    >
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont" htmlFor="title">Title </label>
        <input
          name="title"
          v-validate="'required|min:3|max:50'"
          :class="{ invalid: errors.first('title') }"
          type="text"
          placeholder="Enter name of certification"
          required
          class="form-control"
          v-model="newCourse.title"
        />
        <span
          v-if="errors.first('title')"
          class="valid-feedback alert-danger"
          >{{ errors.first("title") }}</span
        >
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="required modalfont" htmlFor="certificateDesc">
          Describe your Certification
        </label>
        <textarea
          name="description"
          v-validate="'required|min:20|max:200'"
          :class="{ invalid: errors.first('description') }"
          placeholder="What did you learn from this Certification"
          required
          class="form-control"
          v-model="newCourse.description"
        ></textarea>
        <span
          v-if="errors.first('description')"
          class="valid-feedback alert-danger"
          >{{ errors.first("description") }}</span
        >
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="modalfont" htmlFor="link">Link </label>
        <input
          type="text"
          name="certification_link"
          placeholder="Certification link"
          class="form-control"
          data-vv-as="Link"
          v-model="newCourse.certificationLink"
          v-validate="{ max: 100, url: { require_protocol: true } }"
        />
        <span
          v-if="errors.first('certification_link')"
          class="valid-feedback alert-danger"
          >{{ errors.first("certification_link") }}</span
        >
      </div>
      <div class="d-flex">
        <div class="col-6 col-sm-6 pr-2 mb-4">
          <label class="required modalfont" htmlFor="startDate"
            >Start Date
          </label>
          <input
            type="date"
            name="course_start_date"
            ref="course_start_date"
            placeholder="eg: Argusoft India"
            v-validate="'required'"
            :class="{ invalid: errors.first('course_start_date') }"
            class="form-control"
            v-model="newCourse.startDate"
            data-vv-as="Start Date"
          />
          <span
            v-if="errors.first('course_start_date')"
            class="valid-feedback-sm alert-danger"
            >{{ errors.first("course_start_date") }}</span
          >
        </div>
        <div class="col-6 col-sm-6 pr-2 mb-4">
          <label class="required modalfont" htmlFor="endDate">End Date </label>
          <input
            type="date"
            name="course_end_date"
            placeholder="eg: Argusoft India"
            v-validate="'required|after:course_start_date'"
            :class="{ invalid: errors.first('course_end_date') }"
            class="form-control"
            v-model="newCourse.endDate"
            data-vv-as="End Date"
          />
          <span
            v-if="errors.first('course_end_date')"
            class="valid-feedback-sm alert-danger"
            >{{ errors.first("course_end_date") }}</span
          >
        </div>
      </div>
      <template #modal-footer="{ ok }">
        <b-button size="sm" @click="ok()" class="btn btn-cp ml-2">{{
          course.button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { Validator } from "vee-validate";
import {
  isSameAlternateNo,
  beforeDate,
} from "../../../Common/_plugin/validation";
import DeleteModal from "../delete-modal.vue";
import { isValidUrl } from "../../../Common/_utils/sanitization";

Validator.extend("sameAlternateNo", isSameAlternateNo);
Validator.extend("beforeDate", beforeDate);
export default {
  props: {
    applicantData: {
      type: Object,
      default: null,
      required: true,
    },
    skillSet: {
      type: Array,
      required: true,
    },
    isApplicantLoggedIn: {
      type: Boolean,
      required: true,
      default: true,
    },
    isApplicantProfile: {
      type: Boolean,
      default: false,
    },
    isForEditData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newCourse: {
        title: "",
        description: "",
        certificationLink: "",
        startDate: null,
        endDate: null,
        isEdit: false,
      },
      courseId: null,
      checkedCourses: [],
    };
  },
  computed: {
    course() {
      if (this.newCourse.isEdit) {
        return {
          headline: "Edit Course",
          button: "Edit",
        };
      } else {
        return {
          headline: "Add Course",
          button: "Add",
        };
      }
    },
  },
  components: {
    "delete-modal": DeleteModal,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    if (this.isApplicantLoggedIn && !this.isApplicantProfile) {
      this.checkedCourses = this.applicantData.courseDetails.map(
        (course) => course.id
      );
      this.$emit("toggle-checkbox", this.checkedCourses, "course");
    }
  },

  methods: {
    isValidUrl(url) {
      return url ? isValidUrl(url) : false;
    },
    isChecked(courseId) {
      return this.checkedCourses.includes(courseId);
    },
    handleCheckboxChange(courseId) {
      if (this.checkedCourses.includes(courseId)) {
        const idx = this.checkedCourses.indexOf(courseId);
        this.checkedCourses.splice(idx, 1);
      } else {
        this.checkedCourses.push(courseId);
      }

      this.$emit("toggle-checkbox", this.checkedCourses, "course");
    },
    setCourseId(courseId) {
      this.courseId = courseId;
    },

    addCourse() {
      this.newCourse = {
        title: "",
        description: "",
        certificationLink: "",
        startDate: null,
        endDate: null,
        isEdit: false,
      };
    },
    async submitCourse() {
      await this.$validator.validateAll();
      if (this.$validator.errors.items.length > 0) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Please enter valid values!",
        });
        return;
      }

      if (this.isApplicantLoggedIn) {
        const action = this.newCourse.isEdit
          ? "updateUserCourse"
          : "addUserCourse";
        this.$store.dispatch(`$_applicant_master/${action}`, this.newCourse);
      } else {
        const temp = { ...this.applicantData };
        if (this.newCourse.isEdit) {
          temp.courseDetails[this.courseId] = this.newCourse;
        } else {
          temp.courseDetails.push(this.newCourse);
        }
        this.$emit("update:applicantData", temp);
      }

      this.$bvModal.hide("add-course-modal");
    },
    removeCourse() {
      if (this.courseId !== null) {
        if (this.isApplicantLoggedIn) {
          this.$store.dispatch(
            "$_applicant_master/deleteUserCourse",
            this.courseId
          );
        } else {
          const temp = { ...this.applicantData };
          temp.courseDetails.splice(this.courseId, 1);
          this.$emit("update:applicantData", temp);
          this.courseId = null;
        }
      }
    },
    editCourse(index) {
      this.newCourse = { ...this.applicantData.courseDetails[index] };
      this.newCourse.isEdit = true;
      this.courseId = index;
    },
  },
};
</script>

<style scoped>
@import "../tabStyles.css";
</style>
