<template>
  <div
    class="modal fade"
    ref="modal"
    id="auditDiffModal"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Audit Comparison</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="well" style="overflow-x: auto">
            <table
              class="table table-condensed"
              aria-label="Applicant Details"
              aria-hidden="true"
            >
              <tbody>
                <tr>
                  <td class="col-md-3">
                    <strong>Target Model:</strong>
                  </td>
                  <td class="col-md-3">{{ rowData.targetModel }}</td>
                  <td class="col-md-3">
                    <strong>Target Id: </strong>
                  </td>
                  <td class="col-md-3">{{ rowData.targetId }}</td>
                </tr>
                <tr>
                  <td class="col-md-3">
                    <strong>Created By : </strong>
                  </td>
                  <td class="col-md-3">
                    <div class="d-flex">
                      <div
                        v-if="rowData.created_by"
                        class="circle-icon"
                        style="margin: 5px"
                        v-b-tooltip.hover="
                          rowData.userType ? rowData.userType.toLowerCase() : ''
                        "
                      >
                        {{
                          rowData.userType
                            ? rowData.userType.charAt(0).toUpperCase()
                            : " "
                        }}
                      </div>
                      <span style="margin: 5px" class="created-by-name">
                        {{ rowData.created_by_displayName }}</span
                      >
                    </div>
                  </td>

                  <td class="col-md-3">
                    <strong> Toggle Entries: </strong>
                  </td>
                  <td class="col-md-3">
                    <label v-if="rowData.action == 'UPDATE'" class=""
                      >Updated Only:
                      <input type="checkbox" v-model="showChangedRows" />
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 class="text-center mt-4">Attributes</h3>
          <div class="table-responsive">
            <table class="table table-condensed" aria-label="Audit Details">
              <thead>
                <tr>
                  <th class="col-md-4"><strong>Attributes</strong></th>
                  <th class="col-md-4" v-if="rowData.oldValue">
                    <strong>Previous Version</strong>
                  </th>
                  <th class="col-md-4" v-if="rowData.newValue">
                    <strong>Latest Version</strong>
                  </th>
                </tr>
              </thead>

              <tbody v-if="rowData && Object.keys(rowData).length != 0">
                <template v-if="rowData.oldValue && rowData.newValue">
                  <tr v-for="key in Object.keys(rowData.oldValue)" :key="key">
                    <template
                      v-if="
                        !showChangedRows ||
                        !areObjectsEqual(
                          rowData.oldValue[key],
                          rowData.newValue[key]
                        )
                      "
                    >
                      <td>{{ key }}</td>
                      <td
                        :class="{
                          'text-danger': !areObjectsEqual(
                            rowData.oldValue[key],
                            rowData.newValue[key]
                          ),
                        }"
                      >
                        <div
                          v-if="rowData.oldValue[key] !== null"
                          class="long-text"
                        >
                          {{
                            (
                              JSON.stringify(rowData.oldValue[key]) || ""
                            ).replace(/[\[\]"]+/g, "")
                          }}
                        </div>
                      </td>
                      <td
                        :class="{
                          'text-success': !areObjectsEqual(
                            rowData.oldValue[key],
                            rowData.newValue[key]
                          ),
                        }"
                      >
                        <div
                          v-if="rowData.newValue[key] !== null"
                          class="long-text"
                        >
                          {{
                            (
                              JSON.stringify(rowData.newValue[key]) || ""
                            ).replace(/[\[\]"]+/g, "")
                          }}
                        </div>
                      </td>
                    </template>
                  </tr>
                </template>
                <template v-else-if="rowData.newValue">
                  <tr v-for="key in Object.keys(rowData.newValue)" :key="key">
                    <td>{{ key }}</td>
                    <td>
                      <div
                        v-if="rowData.newValue[key] !== null"
                        class="long-text"
                      >
                        {{
                          (JSON.stringify(rowData.newValue[key]) || "").replace(
                            /[\[\]"]+/g,
                            ""
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else-if="rowData.oldValue">
                  <tr v-for="key in Object.keys(rowData.oldValue)" :key="key">
                    <td>{{ key }}</td>
                    <td>
                      <div
                        v-if="rowData.oldValue[key] !== null"
                        class="long-text"
                      >
                        {{
                          (JSON.stringify(rowData.oldValue[key]) || "").replace(
                            /[\[\]"]+/g,
                            ""
                          )
                        }}
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="btn btn-cp"
            data-dismiss="modal"
            aria-label="Close"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "audit-diff-modal",
  components: {},
  data() {
    return {
      showChangedRows: false,
    };
  },
  props: {
    rowData: {
      type: [Object, Array],
      required: true,
    },
  },

  methods: {
    prepareData() {},
    areObjectsEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2) || obj1 == obj2;
    },
  },
  mounted() {
    $(this.$refs.modal).on("hidden.bs.modal", () => {
      this.showChangedRows = false;
    });
  },
  beforeDestroy() {
    $("#auditDiffModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.long-text {
  max-width: 220px;
  word-wrap: break-word;
  white-space: normal;
}

.toggle-button {
  position: absolute;
  top: 25px;
  right: 20px;
}

.card-subtitle {
  font-size: 15px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap; /* Prevent wrapping */
}

.card-subtitle span {
  margin-right: 5px;
}

.circle-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #8f1452;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.created-by-name {
  white-space: nowrap;
}
</style>
