/**
 * @memberOf module:Edit Applicant Data.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data data of user
 * @description commits data of user to uploadin form
 */
const SET_APPLICANT_DATA_TO_FORM = (state, data) => {
  state.ApplicantData = data;
};

/**
 * @memberOf module:Edit Applicant Data.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data status of applicant data edited
 * @description commits status after applicant data has been edited.
 */
const APPLICANT_DATA_EDITED = (state, data) => {
  state.FormEditedStatus = data;
};

/**
 * @memberOf module:Edit Applicant Data.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data resume path that is newly uploaded
 * @description sets the newly resume path
 */
const SET_NEW_RESUME_DATA = (state, data) => {
  state.newResume = data;
};

export default {
  SET_APPLICANT_DATA_TO_FORM,
  APPLICANT_DATA_EDITED,
  SET_NEW_RESUME_DATA,
};
