var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"justify-content":"center"}},[(
      _vm.hasAccess({
        permissions: [_vm.PERMISSIONS['SHARED-TOKEN'].UPDATE],
        checkAll: false,
      })
    )?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Edit Token'),expression:"'Edit Token'"}],staticClass:"btn btn-sm ml-2",attrs:{"disabled":_vm.isTokenExpired()},on:{"click":function($event){return _vm.editHandler()}}},[_c('b-icon',{attrs:{"icon":"pencil","variant":"info"}})],1):_vm._e(),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Copy Token'),expression:"'Copy Token'"}],staticClass:"btn btn-sm ml-2",on:{"click":function($event){return _vm.copyHandler()}}},[_c('b-icon',{attrs:{"icon":"files","variant":"success"}})],1),(
      _vm.hasAccess({
        permissions: [_vm.PERMISSIONS['SHARED-TOKEN'].DELETE],
        checkAll: false,
      })
    )?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Delete Token'),expression:"'Delete Token'"}],staticClass:"btn btn-sm ml-2",on:{"click":function($event){return _vm.deleteHandler()}}},[_c('b-icon',{attrs:{"icon":"trash","variant":"danger"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }