import api from "../_api";
import Routers from "../../../router/index.js";
import { addSnackbarMessage } from "../../Common/_plugin/error-response";

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context store context
 * @param {Object} campusRecruitment validate student data
 * @description Send campus recruitment details to server for validation and handel 
          the response or error if generated 
 */
const validateCampusRecruitment = (context, campusRecruitment) => {
  api
    .validateCampusRecruitment(campusRecruitment)
    .then((response) => {
      if (response.status == 299) {
        context.commit("ADD_ERROR_MESSAGES", response.data);
      }
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem adding Campus Recruitment data!",
        true
      );
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {Object} campusRecruitment Data of campus recruitment
 * @description Send campus recruitment data to server and handle
 * the response or error if generated
 */
const addCampusRecruitment = (context, campusRecruitment) => {
  api
    .addCampusRecruitment(campusRecruitment)
    .then((response) => {
      context.commit("SET_CAMPUS_RECRUITMENT_URL", {
        status: "SUCCESS",
        uniqueID: response.data.unique_id,
      });
      context.commit("ADD_CAMPUS_RECRUITMENT", response.data);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Successfully added Campus Recruitment data!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem adding Campus Recruitment data!",
        true
      );
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {Object} payload Data of entity
 * @description Send entity details to server and handle the response or error if generated
 */
const addEntity = (context, payload) => {
  api
    .addEntity(payload)
    .then((response) => {
      context.commit("ADD_ENTITY", response.data);
      Routers.push({ name: "viewEntity" });
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: " Entity successfully added!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      context.dispatch(
        "openSnackbar",
        {
          type: "danger",
          message: error.response.data.message,
        },
        { root: true }
      );
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {Object} payload Data of updated entity
 * @description Send updated details of entity to server and handle
 *  response or error if generated
 */
const updateEntity = (context, payload) => {
  api
    .updateEntity(payload)
    .then(() => {
      context.commit("UPDATE_ENTITY", payload);
      Routers.push({ name: "viewEntity" });
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: " Entity successfully updated!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      context.dispatch(
        "openSnackbar",
        {
          type: "danger",
          message: error.response.data.message,
        },
        { root: true }
      );
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {Object} data id of contact person
 * @description delete contact person details
 */
const deleteEntityContactPersonDetail = (context, data) => {
  api.deleteEntityContactPersonDetail(data);
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {Object} data id of course
 * @description delete course details
 */
const deleteEntityCourseDetail = (context, data) => {
  api.deleteEntityCourseDetail(data);
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @description Fretch entity details from server and send data to mutations otherwise generate error
 */
const fetchEntities = (context) => {
  api
    .fetchEntities()
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_ENTITIES", data);
    })
    .catch((error) => {
      addSnackbarMessage(error);
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @description Fretch campus recruitment details from server and send data to mutations otherwise generate error
 */
const fetchCampusRecruitments = (context, filters) => {
  api
    .fetchCampusRecruitments(filters)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_CAMPUS_RECRUITMENTS", data);
    })
    .catch((error) => {
      addSnackbarMessage(error);
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {Object} entityData Updated data of entity modal
 * @description Set entity data locally which you want to update
 */
const setUpdateEntityModalData = (context, entityData) => {
  context.commit("SET_UPADATE_ENTITY_MODAL_DATA", entityData);
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {number} deleteId id of entity
 * @description Set id of entity locally which you want to delete
 */
const setDeleteEntityId = (context, data) => {
  context.commit("SET_DELETE_ENTITY_ID", data);
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {number} statusId id of campus recruitment
 * @description Set id of campus recruitment id locally
 */
const setCampusRecruitmentId = (context, statusId) => {
  context.commit("SET_CAMPUS_RECRUITMENT_ID", statusId);
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {number} deleteId id of entity
 * @description Send entity id to server for blocking particular entity and handle response or error if generated
 */
const deleteEntity = (context, data) => {
  api
    .deleteEntity(data)
    .then(() => {
      context.commit("DELETE_ENTITY", data);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Entity successfully updated!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem updating entity!", true);
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {number} id id of campus recruitment
 * @description Send updated data of campus recruitment to server and handle response or error if generated
 */
const updateCampusRecruitmentStatus = (context, id) => {
  api
    .updateCampusRecruitmentStatus(id)
    .then(() => {
      context.commit("UPDATE_CAMPUS_STATUS", id);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Status updated",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem updating status", true);
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {Object} payload contains id, last_date of campus recruitment
 * @description Send updated last date for campus recruitment
 */
const updateCampusRecruitmentLastDate = (context, payload) => {
  api
    .updateCampusRecruitmentLastDate(payload.id, payload.last_date)
    .then(() => {
      context.commit("UPDATE_CAMPUS_RECRUITMENT_LAST_DATE", payload);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Last date changed!",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem updating last date", true);
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @param {number} data
 * @description sends the count of total registred applicants in camous drive
 */
const getRegisteredApplicantCount = (context, data) => {
  api
    .getRegisteredApplicantCount(data)
    .then((res) => {
      context.commit("SET_REGISTERED_COUNT", res.data);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem getting registered applicant count",
        true
      );
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @description get the inProgress campuses
 */
const fetchActiveCampuses = (context, filter) => {
  api
    .fetchCampuses(filter)
    .then((res) => {
      context.commit("SET_ACTIVE_CAMPUSES", res.data);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem getting registered applicant count",
        true
      );
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @description get the filter campuses
 */
const filterCampusRecruitments = (context, filter) => {
  api
    .filterCampusRecruitments(filter)
    .then((res) => {
      context.commit("SET_ACTIVE_CAMPUSES", res.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem registered applicant count", true);
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @description set selected campus
 */
const setSelectedCampus = (context, campusData) => {
  context.commit("SET_SELECTED_CAMPUS", campusData);
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @description reset the selected entity and sessions
 */
const resetSelectedCampus = (context) => {
  context.commit("SET_SELECTED_CAMPUS", null);
  context.commit("SET_SELECTED_ENTITY_SESSIONS", null);
};
const resetStatusCount = (context) => {
  context.commit("FETCH_APPLICATION_STATUS_COUNT", []);
};
/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @description fetch event data
 */
const fetchEventData = (context, id) => {
  api
    .fetchEventById(id)
    .then((res) => {
      context.commit("SET_EVENT_DATA", res.data.data);
      // context.dispatch("fetchEventSlots", res.data.data.EventDetails.id)
    })
    .catch((err) => {
      console.log("err", err);
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @description fetch slots belong to events
 */
const fetchEventSlots = (context, id) => {
  const filter = `?roundId=${id}`;
  api
    .getSlots(filter)
    .then((res) => {
      context.commit("SET_EVENT_DATA", {
        ...context.state.eventData,
        slotsList: res.data,
      });
    })
    .catch((err) => {
      console.log("err3", err);
    });
};

/**
 * @memberof module:campusRecruitment.store.actions
 * @param {Object} context Store context
 * @description selected applicants in event page
 */
const eventApplicantsSelectedRows = (context, rows) => {
  context.commit("EVENT_APPLICANTS_SELECTED_ROWS", rows);
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterOptions
 * @description get data of each application status and their count
 */
const fetchApplicationStatusCount = (context, filterOptions) => {
  api
    .fetchApplicationStatusCount(filterOptions)
    .then((response) => {
      const data = response.data;
      let sortedData = sortStatusCount(data);
      context.commit("FETCH_APPLICATION_STATUS_COUNT", sortedData);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const sortStatusCount = (data) => {
  const applicationStatusList = [
    "Received",
    "Shortlisted",
    "Joined",
    "Differed",
    "Held",
    "Aptitude Scheduled",
    "Aptitude Passed",
    "Aptitude Cancelled",
    "Aptitude Failed",
    "Written Test Scheduled",
    "Written Test Passed",
    "Written Test Cancelled",
    "Written Test Failed",
    "Interview Scheduled",
    "Interview Passed",
    "Interview Cancelled",
    "Interview Failed",
  ];
  applicationStatusList.forEach((status) => {
    const result = data.find((obj) => obj.status_name === status);
    let tempStatus = {};
    if (result == null) {
      tempStatus.status_name = status;
      tempStatus.status_count = "0";
      data.push(tempStatus);
    }
  });
  data.sort((a, b) => {
    return parseInt(b.status_count) - parseInt(a.status_count);
  });
  return data;
};

/**
 * @memberof module:campusRecruitment
 * @namespace store.actions
 * @description actions for mailbox module
 */
export default {
  validateCampusRecruitment,
  fetchEntities,
  fetchCampusRecruitments,
  addEntity,
  setUpdateEntityModalData,
  setDeleteEntityId,
  deleteEntity,
  updateEntity,
  addCampusRecruitment,
  setCampusRecruitmentId,
  updateCampusRecruitmentStatus,
  getRegisteredApplicantCount,
  deleteEntityCourseDetail,
  deleteEntityContactPersonDetail,
  fetchActiveCampuses,
  setSelectedCampus,
  resetSelectedCampus,
  resetStatusCount,
  fetchEventData,
  fetchEventSlots,
  eventApplicantsSelectedRows,
  fetchApplicationStatusCount,
  updateCampusRecruitmentLastDate,
  filterCampusRecruitments,
};
