var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container p-0 m-0"},[_c('div',{staticClass:"menubar nopadding",attrs:{"id":"wrapper"}},[_c('div',{staticClass:"profile-wrapper"},[_c('div',{staticClass:"profile-overlay"},[_c('div',[(_vm.profile)?_c('div',{staticClass:"profile-container"},[(_vm.profilePic)?_c('img',{staticClass:"rounded-circle profile",attrs:{"src":'data:image/jpeg;base64,' + _vm.profilePic,"alt":"Profile"}}):_c('img',{staticClass:"profile",attrs:{"src":_vm.avatar,"alt":"Profile"}})]):_vm._e(),_c('h4',{staticClass:"username"},[_vm._v(_vm._s(_vm.fullName))])])])]),_c('ul',{staticClass:"nopadding sidemenu"},[_c('li',{staticClass:"submenu"},[_c('router-link',{attrs:{"to":{
            name: 'applicantProfile',
          }}},[_c('i',{staticClass:"fa fa-user"}),_vm._v(" Profile")])],1),_c('li',{staticClass:"submenu"},[_c('router-link',{attrs:{"to":{
            name: 'applicantApplications',
          }}},[_c('i',{staticClass:"fa fa-pencil-square-o",attrs:{"aria-hidden":"true"}}),_vm._v(" Your Applications")])],1),(!_vm.canApplyJobs.belongsToCampusRecruitement)?_c('li',{staticClass:"submenu"},[_c('router-link',{attrs:{"to":{
            name: 'applicantJobOpenings',
          }}},[_c('i',{staticClass:"fa fa-briefcase",attrs:{"aria-hidden":"true"}}),_vm._v(" Job Openings")])],1):_vm._e(),(!_vm.canApplyJobs.belongsToCampusRecruitement)?_c('li',{staticClass:"submenu"},[_c('router-link',{attrs:{"to":{
            name: 'linkApplicationRequest',
          }}},[_c('i',{staticClass:"fa fa-link",attrs:{"aria-hidden":"true"}}),_vm._v(" Link Application "),_c('div',{staticClass:"ml-4"},[_vm._v("Request")])])],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }