<template>
  <applicant-profile></applicant-profile>
</template>

<script>
import store from "./_store";
import mailboxstore from "../MailBox/_store";
import applicantDataStore from "../Applications/_store";
import ApplicantProfile from "./_components/applicant-profile.vue";
import formTemplateStore from "../FormTemplate/_store";

/**
 * @module ApplicantProfile
 */
export default {
  /**
   * @memberOf module:ApplicantProfile
   * @namespace components
   * @description components of Applicant Profile
   */
  components: {
    ApplicantProfile,
  },
  created() {
    const STORE_KEY = "$_applicant_profile";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }

    const MAILBOX_STORE_KEY = "$_mailbox";
    if (!(MAILBOX_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(MAILBOX_STORE_KEY, mailboxstore);
    }
    const FORM_TEMPLATE_STORE_KEY = "$_form_template";
    if (!(FORM_TEMPLATE_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(FORM_TEMPLATE_STORE_KEY, formTemplateStore);
    }
    const APPLICANTDATA_STORE_KEY = "$_applicants_data";
    if (!(APPLICANTDATA_STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(APPLICANTDATA_STORE_KEY, applicantDataStore);
    }
  },
};
</script>
