import {
  required,
  minLength,
  maxLength,
  email,
  minValue,
  maxValue,
  numeric,
} from "vuelidate/lib/validators";
import moment from "moment";

export const defaultRecruitmentFormData = () => {
  return {
    first_name: null,
    middle_name: null,
    last_name: null,
    gender: null,
    dob: null,
    email: null,
    mobile_no: null,
    degree: null,
    stream: null,
    ssc_percentage: null,
    entity_id: null,
    hsc_percentage: null,
    grad_percentage: null,
    aggregate_percentage: null,
    semester1: null,
    semester2: null,
    semester3: null,
    semester4: null,
    semester5: null,
    semester6: null,
    semester7: null,
    semester8: null,
  };
};

export const DateOfBirthValidations = {
  minDob: moment().subtract(50, "years"),
  maxDob: moment().subtract(18, "years"),
};

export const RECRUITMENT_FORM_DATA_VALIDATIONS = {
  email: {
    required,
    email,
  },
  first_name: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(50),
  },
  middle_name: {
    minLength: minLength(2),
    maxLength: maxLength(50),
  },
  last_name: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(50),
  },
  dob: {
    required,
  },
  mobile_no: {
    required,
    numeric,
    minLength: minLength(10),
    maxLength: maxLength(10),
  },
  gender: {
    required,
  },
  entity_id: {
    required,
  },
  ssc_percentage: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(100),
  },
  hsc_percentage: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(100),
  },
  degree: {
    required,
  },
  stream: {
    required,
  },
  grad_percentage: {
    minValue: minValue(0),
    maxValue: maxValue(100),
  },
  aggregate_percentage: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(100),
  },
  semester1: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },
  semester2: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },
  semester3: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(10),
  },

  semester4: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(10),
  },

  semester5: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(10),
  },

  semester6: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(10),
  },

  semester7: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },

  semester8: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },
};
