import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  filterData: localStorage.getItem("filterParameters")
    ? JSON.parse(localStorage.getItem("filterParameters"))
    : {},
  campusRecruitmentData: [],
  campusRecruitmentURL: {
    status: "INITIAL",
    uniqueID: null,
  },
  entities: null,
  entityModalData: {},
  deletedEntityData: {},
  errorMessages: {},
  campusRecruitmentId: null,
  registeredApplicantCount: {},
  selectedEntity: null,
  selectedEntitySessions: null,
  activeCampuses: null,
  selectedCampus: null,
  eventData: null,
  eventApplicantsSelectedRows: [],
  campusStatusCount: [],
  campusPositionCount: [],
  applicationStatusCount: [],
  applicationPositionCount: [],
  entitySchedules: [],
};

/**
 * @memberof module:campusRecruitment
 * @namespace store
 * @description central store for campusRecruitment module
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
