const systemConfigs = (state) => state.systemConfigs;

const publicSystemConfigsObject = (state) => state.publicSystemConfigsObject;

const systemConfigsObject = (state) => state.systemConfigsObject;

const jobOpeningFresher = (state) => state.fresherOpeningEnabled;

const jobOpeningProfessional = (state) => state.professionalOpeningEnabled;

const jobOpeningSkills = (state) => state.skillSet;

export default {
  systemConfigs,
  systemConfigsObject,
  publicSystemConfigsObject,
  jobOpeningFresher,
  jobOpeningProfessional,
  jobOpeningSkills,
};
