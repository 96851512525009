<template>
  <div
    class="modal fade"
    id="editTemplateModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Edit Template</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6">
              <form class="content-form" id="editForm">
                <div class="form-group">
                  <label class="control-label" htmlFor="name"
                    >Name <span class="text-danger"> *</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="template.name"
                    @blur="$v.template.name.$touch()"
                  />
                  <p
                    class="alert alert-danger"
                    v-if="!$v.template.name.required && $v.template.name.$error"
                  >
                    This field is required
                  </p>
                </div>
                <div class="form-group">
                  <label class="control-label" htmlFor="subject"
                    >Subject <span class="text-danger"> *</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    v-model="template.template_subject"
                    @blur="$v.template.template_subject.$touch()"
                  />
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.template.template_subject.required &&
                      $v.template.template_subject.$error
                    "
                  >
                    This field is required
                  </p>
                </div>
                <tags-input
                  :id="'edit-template-cc'"
                  :label="'CC'"
                  :isRequired="false"
                  :setValues="template.cc"
                  @valuesUpdated="updateCcValue($event)"
                />
                <tags-input
                  :id="'edit-template-bcc'"
                  :label="'BCC'"
                  :isRequired="false"
                  :setValues="template.bcc"
                  @valuesUpdated="updateBccValue($event)"
                />
              </form>
            </div>
            <div class="col-md-6">
              <label htmlFor="body"
                >Body <span class="text-danger"> *</span></label
              >
              <div class="row" style="margin: auto">
                <common-rich-text-box
                  modalId="edit-template-summernote"
                  width="418"
                  @onKeyDown="onKeyDown"
                  @onBlur="onBlur"
                  @onFocus="onFocus"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button
            id="btn1"
            type="button"
            class="btn btn-cp"
            @click="updateTemplate()"
            data-dismiss="modal"
            :disabled="$v.template.$invalid || !isMailBodyValid"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import TagsInput from "../../Common/_components/tags-input-v2";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";

/**
 * @memberof module:MailBox
 * @namespace components.edit_template_modal
 * @description Modal for editing template
 */
export default {
  props: ["editTemplate"],
  components: {
    TagsInput,
    CommonRichTextBox,
  },

  /**
   * @memberof module:MailBox.components.edit_template_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      template: {
        id: null,
        name: "",
        template_subject: "",
        template_body: "",
        bcc: [],
        cc: [],
        key: "",
      },
    };
  },

  /**
   * @memberof module:MailBox.components.edit_template_modal
   * @description lifecycle method - initializes edit-template-modal on modal event callbacks
   */
  mounted() {
    $("#editTemplateModal").on("show.bs.modal", () => {});
    $("#editTemplateModal").on("hidden.bs.modal", () => {
      $("#edit-template-summernote").summernote("destroy");
    });
  },

  computed: {
    /**
     * @memberof module:MailBox.components.edit_template_modal
     * @returns {Boolean}
     * @description computed - validates mail body for template
     */
    isMailBodyValid: function () {
      return this.template.template_body !== "";
    },
  },

  watch: {
    /**
     * @memberof module:MailBox.components.edit_template_modal
     * @param {Object} value new value
     * @description watch - loads template data to be edited
     */
    editTemplate(value) {
      this.template.id = value.id;

      this.template.name = value.name;
      this.template.key = value.key;
      this.template.template_subject = value.template_subject;
      this.template.cc = value.cc;
      this.template.bcc = value.bcc;

      this.template.template_body = value.template_body;
      $("#edit-template-summernote").summernote("code", value.template_body);
    },
  },
  validations: {
    template: {
      template_subject: {
        required,
      },
      name: {
        required,
      },
    },
  },
  methods: {
    onKeyDown(value) {
      this.template.template_body = value;
    },
    onBlur(value) {
      this.template.template_body = value;
    },
    onFocus() {
      setTimeout(() => {
        $(document.body).addClass("modal-open");
      }, 500);
    },

    /**
     * @memberof module:MailBox.components.edit_template_modal
     * @description method - updates template
     */
    updateTemplate() {
      this.template.template_body = $("#edit-template-summernote").summernote(
        "code"
      );
      this.$store.dispatch("$_mailbox/updateTemplate", this.template);
    },

    /**
     * @memberof module:MailBox.components.edit_template_modal
     * @param {Object} event received from tags-input component
     * @description method - updates bcc data value when tags-input fires update event
     */
    updateBccValue(event) {
      this.template.bcc = event.values;
    },

    /**
     * @memberof module:MailBox.components.edit_template_modal
     * @param {Object} event received from tags-input component
     * @description method - updates cc data value when tags-input fires update event
     */
    updateCcValue(event) {
      this.template.cc = event.values;
    },
  },
  beforeDestroy() {
    $("#editTemplateModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
