<template>
  <div>
    <div class="applicant-stats">
      <CollapseCard
        class="theme-default mb-3"
        :midDivider="false"
        header-class="px-0 py-0"
        :collapse="true"
        title="Applicant Stats"
        title-class="h5 m-0"
      >
        <template>
          <div class="applicant-search">
            <div class="px-3 my-2">
              <applicant-search-bar
                searchPlaceholder="Search Applicants"
                :from-dashboard="true"
                :apiPromise="searchApiPromise"
                :routerLink="searchRouterLink"
              />
            </div>
          </div>
          <div
            @click="goToFreshers()"
            role="button"
            class="widgets_div d-flex justify-content-between align-items-start px-3 pt-2 pb-1"
          >
            <div class="text-left d-flex">
              <div class="icon_div mr-2">
                <span
                  ><i class="fa fa-graduation-cap" aria-hidden="true"></i
                ></span>
              </div>
              <div class="text_div mt-1">
                <span class="text-secondary mb-0 mt-1" style="font-size: 16px">
                  Freshers
                </span>
              </div>
            </div>
            <div class="text-right mt-1 count_div p-1">
              <strong style="font-size: 14px">{{
                dashboardStatsData.freshers
              }}</strong
              ><br />
            </div>
          </div>
          <div
            @click="goToProfessionals()"
            role="button"
            class="widgets_div d-flex justify-content-between align-items-start px-3 py-1"
          >
            <div class="text-left d-flex">
              <div class="icon_div mr-2">
                <span><i class="fa fa-briefcase" aria-hidden="true"></i></span>
              </div>
              <div class="text_div mt-1">
                <span class="text-secondary mb-0 mt-1" style="font-size: 16px">
                  Professionals
                </span>
              </div>
            </div>

            <div class="text-right mt-1 count_div p-2">
              <strong style="font-size: 14px">{{
                dashboardStatsData.professionals
              }}</strong
              ><br />
            </div>
          </div>
          <router-link :to="{ name: 'applicantMaster' }">
            <div
              class="widgets_div d-flex justify-content-between align-items-start px-3 py-1"
            >
              <div class="text-left d-flex">
                <div class="icon_div mr-2">
                  <span><i class="fa fa-users" aria-hidden="true"></i></span>
                </div>
                <div class="text_div mt-1">
                  <span
                    class="text-secondary mb-0 mt-1"
                    style="font-size: 16px"
                  >
                    Applicants
                  </span>
                </div>
              </div>
              <div class="text-right mt-1 count_div p-2">
                <strong style="font-size: 14px">{{
                  dashboardStatsData.applicants
                }}</strong
                ><br />
              </div>
            </div>
          </router-link>

          <div
            @click="setConflicts()"
            role="button"
            class="widgets_div d-flex justify-content-between align-items-start px-3 pt-1 pb-2"
          >
            <div class="text-left d-flex">
              <div class="icon_div mr-2">
                <span><i class="fa fa-link" aria-hidden="true"></i></span>
              </div>
              <div class="text_div mt-1">
                <span class="text-secondary mb-0 mt-1" style="font-size: 16px">
                  Conflicts
                </span>
              </div>
            </div>
            <div class="text-right mt-1 count_div p-2">
              <strong style="font-size: 14px">{{
                dashboardStatsData.conflicts
              }}</strong
              ><br />
            </div>
          </div>
        </template>
      </CollapseCard>
    </div>
  </div>
</template>
<script>
import ApplicantSearchBar from "../../Common/_components/applicant-search-bar.vue";
import axios from "axios";

import { mapGetters } from "vuex";
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";

export default {
  components: {
    ApplicantSearchBar,
    CollapseCard,
  },
  computed: {
    ...mapGetters({
      dashboardStatsData: "$_dashboard/fetchDashboardStatsData",
    }),
  },
  mounted() {
    this.fetchDashboardStats();
  },
  methods: {
    setConflicts() {
      let filterParameters = localStorage.getItem("filterParameters");
      if (filterParameters) {
        filterParameters = JSON.parse(filterParameters);
        if (filterParameters && filterParameters.moreFilters) {
          filterParameters.moreFilters.duplicateType = "duplicate";
          this.$store.dispatch(
            "$_applicants_data/storeFilterData",
            filterParameters
          );
        }
      } else {
        const filterParameters = {
          applicationDate: {
            filterType: "",
            fromDate: null,
            toDate: null,
          },
          moreFilters: {
            duplicateType: "duplicate",
            campusType: "",
          },
        };
        this.$store.dispatch(
          "$_applicants_data/storeFilterData",
          filterParameters
        );
      }
      this.$router.push({ name: "applicants", query: { status: "A" } });
    },
    goToFreshers() {
      this.$store.dispatch("$_applicants_data/resetFilterData");

      this.$router.push({ name: "applicants", query: { status: "G" } });
    },
    goToProfessionals() {
      this.$store.dispatch("$_applicants_data/resetFilterData");
      this.$router.push({ name: "applicants", query: { status: "P" } });
    },
    searchApiPromise(searchQuery) {
      return axios.post("applicants/search?searchQuery=" + searchQuery);
    },
    searchRouterLink(slug) {
      return { name: "applicantprofile", params: { slug } };
    },
    fetchDashboardStats() {
      this.$store.dispatch("$_dashboard/fetchDashboardStats");
    },
  },
};
</script>
<style scoped>
.icon_div {
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #ce8dad85;
  border-color: #ce8dad85;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  user-select: none;
}

.icon_div span i {
  font-size: 16px;
  color: #8f1452;
}

.count_div {
  align-items: center;
  width: 45px;
  height: 20px;
  background-color: #6c757d;
  color: white;
  display: flex;
  justify-content: center;
  border-radius: 10%;
}
</style>
