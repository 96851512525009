import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  loggedInUser: null,
  applicationStatusCount: [],
  applicationPositionCount: [],
  entityListDetails: {},
  sessionList: [],
  selectedSession: {},
  selectedCollege: {},
};

/**
 * @memberof module:Recruiter
 * @namespace store
 * @description central store for Recruiter module
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
