<template>
  <div
    id="addRecruitment"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Add Recruitment Drive</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group position-relative">
              <label class="control-label" htmlFor="recruitmentSessionName"
                >Recruitment Drive Name
                <span class="text-danger">*</span>
              </label>
              <input
                id="recruitmentSessionName"
                name="recruitmentSessionName"
                type="text"
                class="form-control"
                v-model="data.recruitment_session_name"
                v-validate="'required'"
                data-vv-as="Session Name"
                data-vv-validate-on="blur|input"
                :class="{ invalid: errors.first('recruitmentSessionName') }"
                @blur="$v.data.recruitment_session_name.$touch()"
              />
              <span
                v-if="errors.first('recruitmentSessionName')"
                class="valid-feedback alert-danger"
                >{{ errors.first("recruitmentSessionName") }}</span
              >
            </div>
            <div class="form-group position-relative">
              <label class="control-label" htmlFor="entitypicker"
                >Select Entities
                <span class="text-danger">*</span>
              </label>
              <select
                id="entitypicker"
                name="entitypicker"
                v-model="data.selected_entities"
                v-validate="'required'"
                data-vv-as="Entity"
                data-vv-validate-on="blur|select"
                class="selectpicker"
                data-live-search="true"
                title="select the entity participating in the drive"
                multiple
                :class="{ invalid: errors.first('entitypicker') }"
                data-width="100%"
              >
                <option
                  :value="entity.id"
                  v-for="(entity, index) in activeEntities"
                  :key="index"
                >
                  {{ entity.entity_name }} ({{ entity.entity_type }})
                </option>
              </select>
              <br />
              <span
                v-if="errors.first('entitypicker')"
                class="valid-feedback alert-danger"
                >{{ errors.first("entitypicker") }}</span
              >
            </div>
            <BaseDateInput
              label="Last Date Of Registration"
              v-model="data.last_date"
              id="lastDatePicker"
              :minDate="getMoment()"
              :required="true"
              uiStyle="ROW"
              datePickerType="DATE"
              placeholder="select last date of registration"
              @dp-hide="$v.data.last_date.$touch()"
              class="position-relative"
            >
              <template slot="error" v-if="$v.data.last_date.$error">
                <span
                  v-if="!$v.data.last_date.required"
                  class="valid-feedback alert-danger validation-err"
                >
                  Last date for registration is required
                </span>
              </template>
            </BaseDateInput>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-cp ml-2"
            @click="submitData"
            data-dismiss="modal"
            :disabled="$v.data.$invalid"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import BaseDateInput from "../../Common/_components/base-date-input.vue";
import moment from "moment";
import api from "../_api/index";

/**
 * @memberof module:campusRecruitment
 * @namespace components.add_campus_drive_modal
 */
export default {
  /**
   * @memberof module:campusRecruitment.components.add_campus_drive_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      data: {
        recruitment_session_name: null,
        selected_entities: [],
        status: "inProgress",
        last_date: null,
      },
      checkerEntityPicker: false,
      activeEntities: [],
      isSelectedEntitiesTouched: false,
      isFileValid: false,
    };
  },
  validations() {
    const data = {
      recruitment_session_name: {
        required,
      },
      selected_entities: {
        required,
      },
      last_date: {
        required,
      },
    };
    return { data };
  },
  computed: {
    ...mapGetters({
      entities: "$_campus_recruitment/entities",
    }),
  },
  components: {
    BaseDateInput,
  },

  /**
   * @memberof module:campusRecruitment.components.add_campus_drive_modal
   * @description lifecycle method - initializes entityPicker contactPicker
   */
  mounted() {
    $("#entitypicker").selectpicker("render");
    $("#entitypicker").on("hidden.bs.select", () => {
      this.isSelectedEntitiesTouched = true;
    });
  },

  methods: {
    /**
     * @memberof module:campusRecruitment.components.add_campus_drive_modal
     * @description Submit campus recruitment data
     */
    submitData() {
      const parsedDate = moment(this.data.last_date, "DD MMM YY");
      const formattedDate = parsedDate.format("YYYY-MM-DD");

      const campusData = {
        ...this.data,
        last_date: formattedDate,
      };
      api
        .addCampusRecruitment(campusData)
        .then((res) => {
          this.data.recruitment_session_name = "";
          this.data.selected_entities = [];
          this.data.last_date = null;
          this.$v.data.$reset();
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Successfully added Campus Recruitment data!",
            },
            { root: true }
          );
          this.$emit("showSuccessModel", res.data);
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem adding Campus Recruitment data!",
            },
            { root: true }
          );
        });
    },

    /**
     * @memberof module:campusRecruitment.components.add_campus_drive_modal
     * @param {Object} file
     * @description Set choosen file to student_list_file
     */
    fileChanged(file) {
      this.data.student_list_file = file;
    },

    /**
     * @memberof module:campusRecruitment.components.add_campus_drive_modal
     * @param {Array} entities
     * @description Display only active entities
     */
    setActiveEntity(entities) {
      const activeEntities = [];
      entities.forEach((element) => {
        if (element.status == "active") {
          activeEntities.push(element);
        }
      });
      this.activeEntities = activeEntities;
    },

    /**
     * @memberof module:campusRecruitment.components.add_campus_drive_modal
     * @param {String} value
     * @returns {boolean}
     * @description Test your session name by defining regex
     */
    sessionNameValidator(value) {
      return /^[^-\s][a-zA-Z0-9. ]*$/.test(value);
    },
    getMoment() {
      return moment();
    },
  },
  watch: {
    /**
     * @memberof module:campusRecruitment.components.add_campus_drive_modal
     * @param {Array} value
     * @description Watch - entites value
     */
    entities: function (value) {
      this.data.selected_entities = [];
      this.setActiveEntity(value);
    },

    /**
     * @memberof module:campusRecruitment.components.add_campus_drive_modal
     * @description Watch - Check entity active or not
     */
    activeEntities() {
      this.checkerEntityPicker = true;
    },
  },
  updated() {
    if (this.checkerEntityPicker) {
      let entitypicker = document.getElementById("entitypicker");
      if (entitypicker != null) {
        $("#entitypicker")
          .selectpicker("destroy")
          .attr("title", "select the entity participating in the drive")
          .selectpicker("render");
      }
      this.checkerEntityPicker = false;
    }
  },
  beforeDestroy() {
    $("#addRecruitment").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.m-15 {
  margin: 15px;
}
.bld {
  font-weight: bold;
}

#contanerFileUpload {
  margin: -15px;
}
</style>
