<template>
  <div
    class="modal fade"
    id="applicationStatusModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t50">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel">Application Status</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="onClose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="!isFormSubmitted">
            <form class="content-form" id="modalForm">
              <div class="form-group m-b6">
                <label class="col-sm-4 control-label">Application ID</label>
                <div class="col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    v-model="withApplicationID.application_id"
                    @blur="$v.withApplicationID.application_id.$touch()"
                    :disabled="
                      withUserData.email != '' || withUserData.mobile_no != ''
                    "
                  />
                </div>
                <div class="col-sm-4"></div>
                <div
                  class="col-sm-12"
                  v-if="withApplicationID.application_id != ''"
                >
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.withApplicationID.application_id.required &&
                      $v.withApplicationID.application_id.$error
                    "
                  >
                    This field is required
                  </p>
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.withApplicationID.application_id.minLength &&
                      $v.withApplicationID.application_id.$error
                    "
                  >
                    Application ID must be of minimum 7 characters
                  </p>
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.withApplicationID.application_id.maxLength &&
                      $v.withApplicationID.application_id.$error
                    "
                  >
                    Application ID must be of maximum 20 characters
                  </p>
                </div>
              </div>
              <div class="m-10">
                <label
                  class="control-label"
                  style="display: block; text-align: center"
                >
                  OR
                </label>
              </div>

              <div class="form-group m-b6">
                <label class="col-sm-4 control-label">Email</label>
                <div class="col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    v-model="withUserData.email"
                    @blur="$v.withUserData.email.$touch()"
                    :disabled="withApplicationID.application_id != ''"
                  />
                </div>
                <div class="col-sm-4"></div>
                <div class="col-sm-12" v-if="withUserData.email != ''">
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.withUserData.email.required &&
                      $v.withUserData.email.$error
                    "
                  >
                    This field is required
                  </p>
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.withUserData.email.emailValidator &&
                      $v.withUserData.email.$error &&
                      $v.withUserData.email.required
                    "
                  >
                    Enter valid email address
                  </p>
                </div>
              </div>
              <div class="form-group m-b6">
                <label class="col-sm-4 control-label">Phone Number</label>
                <div class="col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    v-model="withUserData.mobile_no"
                    @blur="$v.withUserData.mobile_no.$touch()"
                    :disabled="withApplicationID.application_id != ''"
                  />
                </div>
                <div class="col-sm-3"></div>
                <div class="col-sm-12" v-if="withUserData.mobile_no != ''">
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.withUserData.mobile_no.required &&
                      $v.withUserData.mobile_no.$error
                    "
                  >
                    This field is required
                  </p>
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.withUserData.mobile_no.numeric &&
                      $v.withUserData.mobile_no.$error
                    "
                  >
                    Only digits are allowed
                  </p>
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.withUserData.mobile_no.minLength &&
                      $v.withUserData.mobile_no.numeric &&
                      $v.withUserData.mobile_no.$error
                    "
                  >
                    Number must be of 10 digits
                  </p>
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.withUserData.mobile_no.maxLength &&
                      $v.withUserData.mobile_no.numeric &&
                      $v.withUserData.mobile_no.$error
                    "
                  >
                    Number must be of 10 digits
                  </p>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-sm-2"></div>
              <div class="col-sm-6">
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="sitekey"
                  @verify="onVerify"
                  @expired="onExpired"
                >
                </vue-recaptcha>
              </div>
              <div class="col-sm-3"></div>
            </div>
          </div>
          <div v-if="isFormSubmitted">
            <form
              class="content-form"
              id="modalForm"
              v-if="applicationStatus.name"
            >
              <table class="table" aria-label="Your latest Application details">
                <thead
                  v-if="
                    !$v.withUserData.$invalid ||
                    withApplicationID.application_id
                  "
                >
                  <tr>
                    <th
                      id="Last Round Details"
                      colspan="2"
                      class="col-sm-12 text-info"
                    >
                      Your latest Application details...
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr id="Name">
                    <td class="col-sm-5"><strong>Name :</strong></td>
                    <td class="col-sm-7">{{ applicationStatus.name }}</td>
                  </tr>
                  <tr>
                    <td class="col-sm-5">
                      <strong>Application Status :</strong>
                    </td>
                    <td class="col-sm-7">
                      {{ applicationStatus.application_status }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="table"
                v-if="applicationStatus.round_name"
                aria-label="Your Last Round Details"
              >
                <thead>
                  <tr>
                    <th
                      id="Last Round Details"
                      colspan="2"
                      class="col-sm-12 text-info"
                    >
                      Your Last Round Details...
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-sm-5"><strong>Round Name :</strong></td>
                    <td class="col-sm-7">{{ applicationStatus.round_name }}</td>
                  </tr>
                  <tr>
                    <td class="col-sm-5">
                      <strong>Round Scheduled At :</strong>
                    </td>
                    <td class="col-sm-7">
                      {{ applicationStatus.schedule | dateTimeFormat }}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-sm-5"><strong>Round Result :</strong></td>
                    <td class="col-sm-7">
                      {{ applicationStatus.round_result }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <form v-else>
              <div class="row text-danger" style="margin-left: 10px">
                No record found or you might have submited wrong information!
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button
            v-if="!isFormSubmitted"
            class="btn btn-cp ml-2"
            :disabled="
              ($v.withUserData.$invalid && $v.withApplicationID.$invalid) ||
              isOtherValid
            "
            @click="onFormSubmit"
          >
            Submit
          </button>
          <button class="btn btn-cp ml-2" data-dismiss="modal" @click="onClose">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { mapGetters } from "vuex";
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";

/**
 * @memberof module:Common
 * @namespace application-status-modal
 */
export default {
  /**
   * @memberof module:Common.components.application_status_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      sitekey: "6Lc1jlkUAAAAAFR2xokl-fUNU9lgg3BV__2T2IbN",
      withApplicationID: {
        application_id: "",
      },
      withUserData: {
        email: "",
        mobile_no: "",
      },
      isCaptchaVerified: false,
      isFormSubmitted: false,
    };
  },

  /**
   * @memberof module:Common.components.application_status_modal
   * @returns {Object} validation object
   * @description data - returns validation object for current vue component
   */
  validations() {
    const withApplicationID = {
      application_id: {
        required,
        minLength: minLength(7),
        maxLength: maxLength(20),
      },
    };
    const withUserData = {
      email: {
        required,
        emailValidator: this.emailValidator,
      },
      mobile_no: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    };
    return {
      withApplicationID,
      withUserData,
    };
  },

  components: {
    VueRecaptcha,
  },

  computed: {
    ...mapGetters({
      applicationStatus: "$_home/applicationStatus",
    }),

    /**
     * @memberof module:Common.components.application_status_modal
     * @returns {Boolean}
     * @description computed - checks whether Other data is valid or not
     */
    isOtherValid() {
      return !this.isCaptchaVerified;
    },
  },
  methods: {
    /**
     * @memberof module:Common.components.application_status_modal
     * @param {String} value value of email input fields
     * @description method - checks whether email is valid or not
     */
    emailValidator(value) {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    },

    /**
     * @memberof module:Common.components.application_status_modal
     * @param {Object} response response of google recaptcha v2 when it is varified
     * @description method - handles validation of google recaptcha v2 when it is varified
     */
    onVerify() {
      this.isCaptchaVerified = true;
    },

    /**
     * @memberof module:Common.components.application_status_modal
     * @description method - handles validation of google recaptcha v2 when it is expired
     */
    onExpired() {
      this.isCaptchaVerified = false;
    },

    /**
     * @memberof module:Common.components.application_status_modal
     * @description method - handles to reset varified google recaptcha v2
     */
    resetRecaptcha() {
      this.$refs.recaptcha.reset();
    },

    /**
     * @memberof module:Common.components.application_status_modal
     * @description method - handles to reset varified google recaptcha v2
     */
    onFormSubmit() {
      if (
        !(
          this.$v.withUserData.$invalid && this.$v.withApplicationID.$invalid
        ) &&
        !this.isOtherValid
      ) {
        if (this.withApplicationID.application_id != "") {
          this.isFormSubmitted = true;
          this.$store.dispatch(
            "$_home/checkStatus",
            this.withApplicationID.application_id
          );
        } else {
          this.isFormSubmitted = true;
          this.$store.dispatch("$_home/checkStatus", this.withUserData);
        }
      }
    },

    /**
     * @memberof module:Common.components.application_status_modal
     * @description method - handles to reset input fields and validation when user submit Go Back
     */
    onGoBackSubmit() {
      this.isFormSubmitted = false;
      this.withApplicationID.application_id = "";
      this.withUserData.email = "";
      this.withUserData.mobile_no = "";
      this.isCaptchaVerified = false;
    },

    /**
     * @memberof module:Common.components.application_status_modal
     * @description method - handles to reset input fields and validation when user submit Close
     */
    onClose() {
      if (!this.isFormSubmitted) {
        $("#applicationStatusModal").on("hidden.bs.modal", () => {
          this.withApplicationID.application_id = "";
          this.withUserData.email = "";
          this.withUserData.mobile_no = "";
          this.isCaptchaVerified = false;
          this.resetRecaptcha();
          this.isFormSubmitted = false;
        });
      } else {
        $("#applicationStatusModal").on("hidden.bs.modal", () => {
          this.withApplicationID.application_id = "";
          this.withUserData.email = "";
          this.withUserData.mobile_no = "";
          this.isCaptchaVerified = false;
          this.isFormSubmitted = false;
        });
      }
    },
  },
  beforeDestroy() {
    $("#applicationStatusModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.alert {
  margin: 3px 0 0 0;
  padding: 6px;
}

.m-b6 {
  margin-bottom: 6px;
}

.m-b0 {
  margin-bottom: 0px;
}

.m-10 {
  margin: 10px;
}
</style>
