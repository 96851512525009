<template>
  <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" v-if="deleteEntityData.status === 'active'">
            Block entity
          </h3>
          <h3 class="modal-title" v-else>Active entity</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5>Are you sure?</h5>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-cp ml-2"
            data-dismiss="modal"
            @click="deleteEntity(deleteEntityData)"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
/**
 * @memberof module:campusRecruitment
 * @namespace components.delete_entity_modal
 */
export default {
  /**
   * @memberof module:campusRecruitment.components.delete_entity_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      entityId: null,
    };
  },
  computed: {
    ...mapGetters({
      deleteEntityData: "$_campus_recruitment/fetchDeletedEntityData",
    }),
  },
  watch: {
    /**
     * @memberof module:campusRecruitment.components.delete_entity_modal
     * @param {number} value
     * @description Watch - the block entity id and set to the entityId
     */
    deleteEntityData(value) {
      this.entityId = value.id;
    },
  },
  methods: {
    /**
     * @memberof module:campusRecruitment.components.delete_entity_modal
     * @param {number} id
     * @description Dispatch the id of block entity
     */
    deleteEntity(data) {
      this.$store.dispatch("$_campus_recruitment/deleteEntity", {
        ...data,
        status: data.action,
      });
    },
  },
  beforeDestroy() {
    $("#deleteModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
