import axios from "axios";

/**
 * @memberOf module:ResolveDuplicate.api
 * @param {Number} id Id to be sent to fetch matched applicants
 * @returns {Promise}
 */
const fetchMatchedApplicants = (id) => {
  return axios.get("applicants/duplicates/matched_applicants/" + id);
};

/**
 * @memberOf module:ResolveDuplicate.api
 * @param {Number} id Id to be sent to fetch new applicant
 * @returns {Promise}
 */
const fetchNewApplicantData = (id) => {
  return axios.get("applicants/applicant/" + id);
};

/**
 * @memberOf module:ResolveDuplicate.api
 * @param {Object} data Data to be sent to confirm duplicate
 * @returns {Promise}
 */
const confirmDuplicate = (data) => {
  return axios.put("applicants/duplicates/set_duplicate", data);
};

/**
 * @memberOf module:ResolveDuplicate.api
 * @param {Object} data Data to be sent to differ all duplicates
 * @returns {Promise}
 */
const rejectDuplicate = (data) => {
  return axios.delete("applicants/duplicates/matched_applicants/" + data);
};

/**
 * @memberOf module:ResolveDuplicate
 * @namespace api
 * @description api for backend interactions
 */
export default {
  fetchMatchedApplicants,
  fetchNewApplicantData,
  confirmDuplicate,
  rejectDuplicate,
};
