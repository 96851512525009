<template>
  <div>
    <nav
      class="navbar navbar-expand-md navbar-light"
      v-if="loggedInUser"
      aria-label="loggedInUserNav"
    >
      <div class="logo">
        <router-link :to="{ name: 'homepage' }"
          ><img src="../../../assets/images/newLogo.png" alt=""
        /></router-link>
      </div>
      <!-- <a class="navbar-brand logo pt-0" href="#">
        <router-link :to="{ name: 'homepage' }">
          <img src="../../../assets/images/logo.png" alt="" />
        </router-link>
      </a> -->

      <div class="gpw-logo">
        <img
          src="https://www.argusoft.com/images/Certification%20Badge.png"
          alt=""
        />
      </div>
      <div class="header_btn d-flex align-items-center">
        <div
          :title="loggedInUser.name"
          class="profile_icon"
          :style="{
            backgroundImage: 'url(' + loggedInUser.profile_pic + ')',
          }"
          v-if="loggedInUser"
        ></div>

        <button
          class="btn btn-basic sign-out-btn p-1"
          @click="logout()"
          title="Log out"
          v-if="loggedInUser"
        >
          <i class="fa fa-2x fa-sign-out" aria-hidden="true"></i>
        </button>
      </div>
    </nav>

    <nav
      aria-label="navbar toggler RecruiterNavbar"
      class="navbar navbar-expand-md navbar-light"
      v-else-if="recruiter_token"
    >
      <!-- <a class="navbar-brand logo pt-0" href="#">
        <router-link :to="{ name: 'RecruiterApplicantData' }">
          <img src="../../../assets/images/logo.png" alt="" />
        </router-link>
      </a> -->
      <div class="logo">
        <router-link :to="{ name: 'RecruiterApplicantData' }"
          ><img src="../../../assets/images/newLogo.png" alt=""
        /></router-link>
      </div>
      <div class="gpw-logo">
        <img
          src="https://www.argusoft.com/images/Certification%20Badge.png"
          alt=""
        />
      </div>
      <div class="header_btn d-flex align-items-center" id="header_btn">
        <div
          class="profile_icon2"
          :style="{
            backgroundImage: `url(${avatar})`,
          }"
        ></div>
        <button
          class="sign-out-btn float-right btn btn-basic p-1"
          @click="recruiterLogout()"
          title="Log out"
        >
          <i class="fa fa-2x fa-sign-out" aria-hidden="true"></i>
        </button>
      </div>
    </nav>

    <nav
      aria-label="navbar toggler ApplicantNavbar"
      class="navbar navbar-expand-md navbar-light"
      v-else-if="applicant_token"
    >
      <!-- <a class="navbar-brand logo pt-0" href="#">
        <router-link :to="{ name: 'applicantApplications' }">
          <img src="../../../assets/images/logo.png" alt="" />
        </router-link>
      </a> -->
      <div class="logo">
        <router-link :to="{ name: 'applicantApplications' }"
          ><img src="../../../assets/images/newLogo.png" alt=""
        /></router-link>
      </div>

      <div class="gpw-logo">
        <img
          src="https://www.argusoft.com/images/Certification%20Badge.png"
          alt=""
        />
      </div>
      <div class="header_btn d-flex align-items-center" id="header_btn">
        <div
          class="profile_icon2"
          :style="{
            backgroundImage: `url(${avatar})`,
          }"
        ></div>
        <button
          class="sign-out-btn float-right btn btn-basic p-1"
          @click="applicantLogout()"
          title="Log out"
        >
          <i class="fa fa-2x fa-sign-out" aria-hidden="true"></i>
        </button>
      </div>
    </nav>

    <nav
      class="navbar navbar-expand-md navbar-light"
      v-else
      aria-label="otherNavbar"
    >
      <div class="dropdown d-flex">
        <button
          class="btn btn-basic d-block d-md-none mr-2"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            alt="argusoft-menu"
            src="../../../assets/images/argus-menu.png"
          />
        </button>
        <div
          class="dropdown-menu dropdown-menu-adv"
          aria-labelledby="dropdownMenuButton"
        >
          <router-link
            id="routerLink1"
            :to="{ name: 'applicationForm', query: { isFresher: 'true' } }"
            v-if="fresherOpeningEnabled"
          >
            <button class="btn btn-cp ml-2 p-2 nav-item menu-item">
              Freshers
            </button>
          </router-link>
          <router-link
            :to="{ name: 'jobOpenings' }"
            id="routerLink2"
            v-if="professionalOpeningEnabled"
          >
            <button class="btn btn-cp ml-2 p-2 nav-item menu-item">
              Experienced Professionals
            </button>
          </router-link>
          <button
            class="btn btn-cp ml-2 p-2 nav-item"
            data-toggle="modal"
            data-target="#applicationStatusModal"
            data-backdrop="static"
            data-keyboard="false"
          >
            Application Status
          </button>
        </div>
        <div class="logo">
          <router-link :to="{ name: 'home' }"
            ><img src="../../../assets/images/newLogo.png" alt=""
          /></router-link>
        </div>
      </div>

      <!-- <a class="navbar-brand logo pt-0" href="#">
        <router-link :to="{ name: 'home' }"
          ><img src="../../../assets/images/logo.png" alt=""
        /></router-link>
      </a> -->
      <!-- <div class="logo">
        <router-link :to="{ name: 'home' }"
          ><img src="../../../assets/images/newLogo.png" alt=""
        /></router-link>
      </div> -->
      <div class="gpw-logo">
        <img
          src="https://www.argusoft.com/images/Certification%20Badge.png"
          alt=""
        />
      </div>

      <div class="collapse navbar-collapse bg-white" id="navbarToggler">
        <ul class="navbar-nav center-align mt-lg-0">
          <div class="d-flex dropdown hide-in-menu">
            <button
              v-if="fresherOpeningEnabled || professionalOpeningEnabled"
              class="nav-item btn dropdown-toggle btn-cp ml-2 p-2"
              type="button"
              data-toggle="dropdown"
              id="dropdownMenuButton"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <!-- <i class="fa fa-hand-o-right" aria-hidden="true"></i> -->
              Join Us
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li class="dropdown-item" v-if="fresherOpeningEnabled">
                <router-link
                  id="routerLink1"
                  :to="{
                    name: 'applicationForm',
                    query: { isFresher: 'true' },
                  }"
                >
                  Freshers
                </router-link>
              </li>
              <li class="dropdown-item" v-if="professionalOpeningEnabled">
                <router-link :to="{ name: 'jobOpenings' }" id="routerLink2">
                  Experienced Professionals
                </router-link>
              </li>
            </ul>
            <button
              class="btn btn-cp ml-2 p-2 nav-item"
              data-toggle="modal"
              data-target="#applicationStatusModal"
              data-backdrop="static"
              data-keyboard="false"
            >
              Application Status
            </button>
          </div>
        </ul>
      </div>
      <button
        class="sign-in-btn btn btn-basic float-right p-1"
        @click="goToLogin()"
        title="Employee Login"
      >
        <i class="fa fa-2x fa-sign-in" aria-hidden="true"></i>
      </button>
      <!-- <button
        class="sign-in-btn btn btn-basic float-right p-1"
        @click="goToLogin()"
        title="Employee Login"
      >
        <i class="fa fa-2x fa-sign-in" aria-hidden="true"></i>
      </button> -->
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import VueCookie from "vue-cookie";
import store from "../../../store/index";
import userProfile from "../../../assets/images/avatar.png";

export default {
  data: () => {
    return {
      recruiter_token: null,
      applicant_token: null,
      avatar: userProfile,
    };
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    logout() {
      axios
        .get("/logout")
        .then(() => {
          this.$cookie.delete("user");
          this.$cookie.delete("connect.sid");
          localStorage.clear();
          this.$store.dispatch("user/logoutUser");
          this.$router.push({ name: "home" });
        })
        .catch(() => {
          this.$cookie.delete("user");
          this.$cookie.delete("connect.sid");
          localStorage.clear();
          this.$store.dispatch("user/logoutUser");
          this.$router.push({ name: "home" });
        });
    },
    recruiterLogout() {
      VueCookie.delete("RECRUITER_ACCESS_TOKEN");
      localStorage.clear();
      this.$router.push({ name: "home" });
    },
    applicantLogout() {
      VueCookie.delete("APPLICANT_ACCESS_TOKEN");
      this.$store.dispatch("$_applicant_master/logoutApplicant");
      localStorage.clear();
      this.$router.push({ name: "home" });
    },
  },
  computed: {
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
      fresherOpeningEnabled: "system_config/jobOpeningFresher",
      professionalOpeningEnabled: "system_config/jobOpeningProfessional",
    }),
  },
  mounted() {
    const user = VueCookie.get("user");
    if (user) {
      const userData = JSON.parse(user.substring(2));
      store.dispatch("user/setLoggedInUser", userData);
    }

    this.recruiter_token = VueCookie.get("RECRUITER_ACCESS_TOKEN");
    this.applicant_token = VueCookie.get("APPLICANT_ACCESS_TOKEN");
  },
};
</script>

<style scoped>
.profile_icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-size: cover;
}

/* .logo {
  width: 120px;
  height: 55px;
  position: fixed;
} */
.logo {
  width: 120px;
  overflow: hidden;
}
.logo img {
  width: 90%;
}
.navbar {
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 500;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.2);
}

.sign-in-btn {
  color: #8f1452;
  cursor: pointer;
  vertical-align: middle;
}

.sign-in-btn:hover {
  color: #6d083b;
}

.sign-out-btn {
  color: #8f1452;
  cursor: pointer;
  margin-left: 20px;
  vertical-align: middle;
}

.sign-out-btn:hover {
  color: #6d083b;
}

.profile_icon2 {
  position: absolute;
  right: 50px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-size: cover;
}

.header_btn {
  position: absolute;
  right: 0px;
  margin-right: 1%;
}

.gpw-logo {
  position: fixed;
  z-index: 9999;
  right: 10px;
  top: 60px;
  width: 86px;
}

@media (max-width: 599px) {
  .gpw-logo {
    top: 60px;
  }
}

@media (max-width: 550px) {
  .gpw-logo {
    display: none;
  }
}

.gpw-logo img {
  width: 86px;
}

.navbar-collapse {
  margin-right: 5px;
}
.menu-item {
  display: none;
}

.navbar-nav {
  margin-left: auto;
}

@media (max-width: 767px) {
  .hide-in-menu {
    display: none;
  }
  .menu-item {
    display: block;
  }
  /* .logo {
    position: fixed;
    left: 80px;
  } */
  .nav-item {
    margin: 5px 50px;
    width: 250px;
  }
  .navbar-collapse {
    z-index: 100000;
    margin-top: 13px;
    padding-bottom: 10px;
    margin-right: 0px;
  }

  .navbar-nav {
    margin: 0 auto;
  }

  .center-align {
    align-items: center;
  }
  .navbar-toggler {
    border: 0px;
  }
}

.navbar-toggler[aria-expanded="true"] {
  margin-top: 12px;
}

.dropdown-menu-adv {
  z-index: 100000;
  margin-top: 10px;
  padding-left: 35px;
}
</style>
