<template>
  <div
    class="modal fade"
    id="upload-applicant-model"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Upload Applicants</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="" v-if="entityListDetails">
              <p class="m-0 mb-2">
                <b>
                  {{ entityListDetails.entity_type + " - " }}
                </b>
                {{ entityListDetails.entity_name }}
              </p>
            </div>
            <div class="mb-2">
              <select
                class="custom-select main college mr-2"
                v-model="uploadSelectedCollege"
                v-if="entityListDetails.entity_type === 'University'"
              >
                <option disabled value="">Select College</option>
                <option
                  v-for="(college, index) in entityListDetails.childEntities"
                  :value="college"
                  :key="index"
                >
                  {{ college.entity_name + "- (College)" }}
                </option>
              </select>
              <select
                class="custom-select main college mr-2"
                v-model="uploadSelectedSession"
              >
                <option disabled value="">Select Session</option>
                <option
                  v-for="(session, index) in computedSessionList"
                  :value="session"
                  :key="index"
                >
                  {{ session.recruitment_session_name + "- (Session)" }}
                </option>
              </select>
            </div>
            <div class="input-group mb-2">
              <div class="custom-file">
                <input
                  ref="fileInput"
                  type="file"
                  @click="clearFile"
                  @change="uploadFile"
                  class="custom-file-input"
                  id="my_file_input"
                />
                <label class="custom-file-label" for="my_file_input">{{
                  filename
                }}</label>
              </div>
            </div>

            <div>
              <div
                class="d-flex justify-content-between"
                style="flex-wrap: wrap"
              >
                <div>
                  <button
                    v-if="file"
                    class="btn btn-cp btn-sm my-1"
                    @click="clearFile"
                  >
                    Clear file
                  </button>
                </div>
                <div class="d-flex ml-auto" v-if="applicantList.length > 0">
                  <div
                    class="d-flex"
                    style="flex-wrap: wrap; justify-content: flex-end"
                  >
                    <span
                      class="alert alert-info alert-xs m-1"
                      v-if="selectedRowCount > 0"
                    >
                      Selected {{ selectedRowCount }}.
                    </span>
                    <button
                      class="btn btn-cp btn-sm m-1"
                      @click="deleteApplicants"
                      v-if="selectedRowCount > 0"
                    >
                      Delete
                    </button>
                    <button
                      v-if="selectedApplicants.length !== applicantList.length"
                      class="btn btn-cp btn-sm m-1"
                      @click="selectAllOnCurrentPage"
                    >
                      <i class="fa fa-square" aria-hidden="true"></i>Select all
                    </button>
                    <button
                      v-else
                      class="btn btn-cp btn-sm m-1"
                      @click="deselectAllOnCurrentPage"
                    >
                      <i class="fa fa-check-square" aria-hidden="true"></i>
                      Deselect all
                    </button>
                    <button
                      class="btn btn-cp btn-sm my-1 ml-1"
                      @click="refreshData()"
                    >
                      <i class="fa fa-repeat" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ag-grid-vue
              class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
              v-if="gridOptions"
              style="height: 50vh"
              :overlayLoadingTemplate="loadingTemplate"
              :overlayNoRowsTemplate="noRowsTemplate"
              :enableBrowserTooltips="true"
              :gridOptions="gridOptions"
              @gridReady="onGridReady"
            >
            </ag-grid-vue>
            <div>
              <p class="text-danger mb-0" v-if="errorMessage">
                {{ errorMessage }}
              </p>
            </div>
            <div></div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            ref="close"
            class="btn btn-secondary btn-sm"
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            @click="applicantUpload"
            :disabled="
              !canUpload ||
              !uploadSelectedSession ||
              gridOptions.api.getDisplayedRowCount() === 0
            "
            type="button"
            class="btn btn-cp"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberOf module:ApplicantsData
 * @namespace components.filter_data_modal
 * @description Filter data modal component
 */
import * as XLSX from "xlsx";
import { AgGridVue } from "ag-grid-vue";
import { COMMON_DATA_COL_DEFS } from "../_utils/applicant_data_list";
import { mapGetters } from "vuex";
import api from "../_api/index";
import moment from "moment";
import {
  RECRUITMENT_FORM_DATA_VALIDATIONS,
  VALIDATIONS_MESSAGES,
} from "../../CampusRecruitment/_utils/recruitment-form-const";
import BulkResolveDuplicateLink from "./bulk-resolve-duplicate-link.vue";
import store from "../../../store/index";

export default {
  components: {
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "applicant-resolve-link": BulkResolveDuplicateLink,
  },
  data() {
    return {
      loadingTemplate: "Select File",
      noRowsTemplate: "No Students Found",
      gridOptions: null,
      file: null,
      applicantList: [],
      deletedApplicants: [],
      canUpload: false,
      columnDefs: COMMON_DATA_COL_DEFS,
      api: null,
      columnApi: null,
      selectedApplicants: [],
      selectedRowCount: null,
      uploadSelectedSession: "",
      uploadSelectedCollege: "",
      sendingData: [],
      errorMessage: null,
    };
  },
  validations() {
    return {
      sendingData: {
        $each: RECRUITMENT_FORM_DATA_VALIDATIONS,
      },
    };
  },
  watch: {
    uploadSelectedCollege() {
      this.uploadSelectedSession = "";
    },
  },
  computed: {
    computedSessionList() {
      if (this.uploadSelectedCollege)
        return this.uploadSelectedCollege.campusRecruitments.filter(
          (entity) => {
            return (
              entity.status === "inProgress" && !this.checkLastDateOver(entity)
            );
          }
        );
      else {
        return [];
      }
    },
    ...mapGetters({
      loggedInUser: "$_recruiter/getLoggedInUser",
      entityListDetails: "$_recruiter/getEntityListDetails",
    }),
    filename() {
      return this.file ? this.file.name : "Choose file";
    },
  },
  methods: {
    checkLastDateOver(session) {
      const currentDate = moment();
      return moment(session.last_date).isBefore(currentDate);
    },
    checkDisabled() {
      if (this.gridOptions && this.gridOptions.api) {
        const duplicatesList = [];
        this.gridOptions.api.forEachNode((node, index) => {
          if (
            (node.data.hasDuplicate && node.data.hasDuplicate.length > 0) ||
            (node.data.hasServerDuplicates &&
              node.data.hasServerDuplicates.length > 0)
          ) {
            duplicatesList.push({ index: index, id: node.data.id });
          }
        });
        this.canUpload = duplicatesList.length <= 0;
        if (duplicatesList.length) {
          this.errorMessage = `Duplicate Applicants with email/phone no. at IDs ${duplicatesList
            .map((e) => e.index)
            .join(",")}`;
        } else this.errorMessage = null;
      } else {
        return false;
      }
    },
    createdUploadPage() {
      this.uploadSelectedSession = "";
      this.uploadSelectedCollege = "";
      if (this.entityListDetails.entity_type != "University") {
        this.uploadSelectedCollege = this.entityListDetails;
      }
    },
    clearFile() {
      this.$refs.fileInput.value = null;
      this.file = null;
      this.applicantList = [];
      this.selectedApplicants = [];
      this.selectedRowCount = null;
      this.deletedApplicants = [];
      this.gridOptions.api.setGridOption("rowData", []);
      this.canUpload = false;
      this.errorMessage = null;
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to convert xlsx file to applicants object to upload on server
     */
    uploadFile(event) {
      const numberFields = [
        "mobile_no",
        "ssc_percentage",
        "hsc_percentage",
        "aggregate_percentage",
        "grad_percentage",
        "semester1",
        "semester2",
        "semester3",
        "semester4",
        "semester5",
        "semester6",
        "semester7",
        "semester8",
      ];
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {
            type: "binary",
            row: true,
            cellDates: false,
          });
          let data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
            defval: null,
            raw: false,
          });
          data = data.map((applicant, index) => {
            for (const field in applicant) {
              const value =
                typeof applicant[field] === "string"
                  ? applicant[field].trim()
                  : applicant[field];
              applicant[field] =
                numberFields.includes(field) && value
                  ? parseInt(value) || value
                  : value;
            }
            return { ...applicant, id: index };
          });
          this.applicantList = this.resolveDuplicate(data);
          this.selectedApplicants = [];
          this.selectedRowCount = null;
          this.deletedApplicants = [];
          this.gridOptions.api.setGridOption("rowData", this.applicantList);
          this.checkDisabled();
        };
        reader.readAsBinaryString(this.file);
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to set gridOptions apis
     */
    onGridReady(params) {
      this.gridOptions.api = params.api;
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to call function on ag grid selection change
     */
    onSelectionChanged() {
      this.selectedApplicants = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to select all applicant in current page
     */
    selectAllOnCurrentPage() {
      this.selectedRowCount = 0;
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(true);
        this.selectedRowCount++;
      });
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to deselect all applicant in current page
     */
    deselectAllOnCurrentPage() {
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(false);
      });
      this.selectedRowCount = 0;
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to refresh data in ag grid data table
     */
    refreshData() {
      this.gridOptions.api.setGridOption("rowData", this.applicantList);
      this.deletedApplicants = [];
      this.selectedApplicants = [];
      this.selectedRowCount = null;
      this.checkDisabled();
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to return an array of all id from array of objects
     */
    getIdFromObject(obj) {
      return obj.map((ob) => {
        return ob.id;
      });
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to delete selected applicants
     */
    deleteApplicants() {
      const ids = [
        ...this.getIdFromObject(this.selectedApplicants),
        ...this.deletedApplicants,
      ];
      const afterDeleted = this.applicantList.filter((applicant) => {
        return !ids.includes(applicant.id);
      });
      this.gridOptions.api.setGridOption(
        "rowData",
        this.resolveDuplicate(afterDeleted)
      );
      this.deletedApplicants = ids;
      this.selectedApplicants = [];
      this.selectedRowCount = null;
      this.checkDisabled();
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to add local duplicat key in each applicant if exist
     */
    resolveDuplicate(list) {
      const checked = list.map((applicant, index) => {
        if (index === 0) {
          return { ...applicant, hasDuplicate: [] };
        } else {
          const hasDuplicate = [];
          for (let j = index - 1; j >= 0; j--) {
            if (applicant.email == list[j].email) {
              hasDuplicate.push({
                to_applicant_id: list[j].id,
                key: "email",
                email: applicant.email,
              });
            }
            if (applicant.mobile_no == list[j].mobile_no) {
              hasDuplicate.push({
                to_applicant_id: list[j].id,
                key: "mobile_no",
                mobile_no: applicant.mobile_no,
              });
            }
          }
          return { ...applicant, hasDuplicate: hasDuplicate };
        }
      });
      return checked;
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to add server duplicates
     */
    addServerDuplicates(errorObject) {
      const newList = [];
      this.gridOptions.api.forEachNode((node, index) => {
        const hasServerDuplicates = errorObject[index].map((duplicate) => {
          return {
            mobile_no: duplicate.mobile_no,
            email: duplicate.email,
          };
        });
        const nodeData = {
          ...node.data,
          hasServerDuplicates: hasServerDuplicates,
        };
        newList.push(nodeData);
      });
      return newList;
    },

    /**
     * @memberof module:CampusRecruitment.components.upload-applicants
     * @description method to upload applicants to selected active session
     */
    async applicantUpload() {
      const applicants = [];
      this.gridOptions.api.forEachNodeAfterFilterAndSort((node) => {
        const data = {
          ...node.data,
          mobile_no: node.data.mobile_no.toString(),
          entity_id: this.uploadSelectedCollege.id,
          campus_unique_id: this.uploadSelectedSession.unique_id,
          dob: moment(node.data.dob),
        };
        delete data.id;
        delete data.hasDuplicate;
        delete data.hasServerDuplicates;
        applicants.push(data);
      });
      this.sendingData = applicants;

      await this.$v.$touch();
      if (this.$v.sendingData.$error)
        for (const index in this.$v.sendingData.$each) {
          if (index[0] !== "$" && this.$v.sendingData.$each[index].$error) {
            for (const field in this.$v.sendingData.$each[index]) {
              if (
                field[0] !== "$" &&
                this.$v.sendingData.$each[index][field].$error
              ) {
                if (this.$v.sendingData.$each[index][field].$error) {
                  this.$alert(
                    `At row ${parseInt(index) + 1} - ${field} - ${
                      VALIDATIONS_MESSAGES[field]
                    }`
                  );
                  return;
                }
              }
            }
          }
        }
      store.dispatch("setLoading", {
        isLoading: true,
        loadingMessage: "Please wait while applicants are uploaded",
      });
      await api
        .sendBulkApplicants(applicants)
        .then(() => {
          this.$store.dispatch("setLoading", {
            isLoading: false,
            loadingMessage: "",
          });
          this.$store.dispatch(
            "$_recruiter/setSelectedCollege",
            this.uploadSelectedCollege
          );
          this.$store.dispatch(
            "$_recruiter/setSelectedSession",
            this.uploadSelectedSession
          );
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Student list has been uploaded" },
            { root: true }
          );
          this.$refs.close.click();
          this.$emit("refreshData");
        })
        .catch((err) => {
          this.$store.dispatch("setLoading", {
            isLoading: false,
            loadingMessage: "",
          });
          this.$alert(
            err.response.data && err.response.data.message
              ? err.response.data.message
              : "Something went wrong"
          );
          if (err.response.data.data) {
            const newList = this.addServerDuplicates(err.response.data.data);
            this.gridOptions.api.setGridOption(
              "rowData",
              this.resolveDuplicate(newList)
            );
            this.checkDisabled();
          }
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message:
                err.response.data && err.response.data.message
                  ? err.response.data.message
                  : "Something went wrong",
            },
            { root: true }
          );
        });
    },
  },
  beforeMount() {
    const gridOptions = {
      rowData: null,
      columnDefs: this.columnDefs,
      onSelectionChanged: () => {
        this.onSelectionChanged();
      },
      rowHeight: 30,
      headerHeight: 30,
      suppressPropertyNamesCheck: true,
      suppressRowClickSelection: true,
      enableCellTextSelection: true,
      enableServerSideFilter: true,
      enableColResize: true,
      rowSelection: "multiple",
      paginationPageSizeSelector: false,
      defaultColDef: {
        flex: 1,
        floatingFilter: true,
        resizable: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        filter: true,
      },
      infiniteInitialRowCount: 0,
      animateRows: true,
      context: {
        componentParent: this,
      },
    };
    this.gridOptions = gridOptions;
  },
};
</script>
<style scoped>
.ag-grid-style {
  margin-top: 10px;
}

.ag-theme-alpine .ag-header-group-cell-label {
  flex-direction: row-reverse;
}

.dropdown-menu {
  top: 50px;
}

.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 3px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #803059;
  color: white;
}

.dropdown-menu .dropdown-header {
  padding: 3px 20px;
  color: #823e5b;
  font-size: 16px;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

li.dropdown > a {
  color: white;
}

.custom-select.college {
  width: 250px;
  max-width: 100%;
}
</style>
