<template>
  <b-modal
    style="margin-top: 0px"
    v-model="modalVisible"
    scrollable
    :title="title"
    @hidden="$emit('closeModal')"
    size="lg"
  >
    <div class="modal-content-wrapper">
      <b-tabs
        v-if="!isTemplateOnly"
        active-nav-item-class="font-weight-bold text-uppercase"
        content-class="mt-3"
        justified
      >
        <b-tab active @click="showHtml">
          <template #title> HTML Content </template>
        </b-tab>
        <b-tab @click="showFormData">
          <template #title> Form Data </template>
        </b-tab>
      </b-tabs>

      <div v-if="viewType === 'pdf'">
        <div>
          <embed
            class="pdf-embd"
            v-if="pdfData"
            :src="pdfData + '#toolbar=0&navpanes=0&scrollbar=0&view=FitH'"
            type="application/pdf"
          />
        </div>
      </div>

      <div v-else-if="!isTemplateOnly">
        <div v-if="formData.length > 0">
          <table class="table table-striped table-hover">
            <caption style="display: none">
              This table lists variable name, type, input type.
            </caption>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Type</th>
                <th>Input Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in formData" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ data.name }}</td>
                <td>{{ data.type }}</td>
                <td>{{ data.inputType }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <h4 class="mt-4 text-center">No Form data to show!</h4>
        </div>
      </div>
    </div>
    <template #modal-footer="{}">
      <b-button @click="$emit('closeModal')" class="btn btn-secondary"
        >Cancel</b-button
      >
      <b-button
        v-if="!isTemplateOnly"
        type="submit"
        @click="$emit('submit')"
        class="btn btn-cp"
        >Save</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    formData: {
      type: Array,
    },
    title: {
      type: String,
      required: true,
    },
    pdfData: {
      type: String,
      required: true,
    },
    isTemplateOnly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      modalVisible: true,
      viewType: "pdf",
    };
  },
  methods: {
    showHtml() {
      this.viewType = "pdf";
    },
    showFormData() {
      this.viewType = "formData";
    },
  },
};
</script>

<style scoped>
.full-width-button {
  width: 100%;
  background: #7498b4 !important;
}

.html-content {
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
}

.activeTab {
  background: #8f1452 !important;
}
.pdf-embd {
  height: calc(100vh - 235px);
  width: 100%;
  margin: auto;
  border-radius: 10px;
  border: 5px solid rgb(240, 240, 240);
}
/* MEDIA QUERY FOR MEDIUM SCREEN */
@media screen and (max-width: 768px) {
  .pdf-embd {
    height: calc(100vh - 195px);
  }
}
</style>
