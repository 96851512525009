<template>
  <div class="content container-fluid page-bg nopadding">
    <div class="loginbox" v-if="selectedUserType">
      <div
        class="p-2"
        style="display: flex; flex-direction: column; min-height: 100%"
      >
        <b-tabs justified v-model="activeTab" style="flex-grow: 1">
          <b-tab
            title="Employee"
            v-model="selectedUserType"
            @click="changeUserType('employee')"
          >
            <div v-if="selectedUserType === 'employee'">
              <div style="margin-top: 40px" class="p-2 p-md-5">
                <div class="logo-div">
                  <img
                    class="logoSize"
                    src="../../assets/images/newLogo.png"
                    alt="logo"
                  />
                </div>
                <div class="text-center">
                  <h5 class="text-center font-weight-bold" @click="goToLogin">
                    Sign In With
                    <a
                      ><img src="../../assets/images/mail.png" alt="mailLogo"
                    /></a>
                  </h5>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Recruiter" @click="changeUserType('recruiter')">
            <div
              style="margin-top: 20px"
              v-if="selectedUserType === 'recruiter'"
            >
              <recruiter-login />
            </div>
          </b-tab>
          <b-tab
            title="Applicant"
            @click="changeUserType('applicant')"
            class="tab-content"
          >
            <div v-if="selectedUserType === 'applicant'">
              <applicant-login />
            </div>
          </b-tab>
        </b-tabs>
        <div class="font-weight-bold mx-3">
          <h3 class="p-0 my-2"></h3>
          <router-link :to="'/'">
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            <span style="padding: 4px">Homepage</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config";
import applicantMasterStore from "../ApplicantMaster/_store";
import recruiterLogin from "../Recruiter/_components/recruiter-login.vue";
import applicantLogin from "../ApplicantMaster/_components/applicant-login.vue";

const userTypes = ["employee", "recruiter", "applicant"];
const tabIndex = {
  employee: 0,
  recruiter: 1,
  applicant: 2,
};
export default {
  components: { recruiterLogin, applicantLogin },
  data() {
    return {
      config,
      slotId: this.$route.query.slot,
      userTypes: ["employee", "recruiter", "applicant"],
      selectedUserType: userTypes.includes(this.$route.query.as)
        ? this.$route.query.as
        : "employee",
      activeTab: userTypes.includes(this.$route.query.as)
        ? tabIndex[this.$route.query.as]
        : tabIndex["employee"],
    };
  },
  watch: {
    selectedUserType: {
      handler: function (value) {
        if (value && value !== this.$route.query.as) {
          this.$router.replace({
            name: "login",
            query: { as: value },
          });
        }
      },
      immediate: true,
    },
  },

  methods: {
    goToLogin() {
      window.location = this.config.ROOT_API + "/auth/google";
    },
    changeUserType(user) {
      this.selectedUserType = user;
      this.activeTab = tabIndex[user];
    },
  },
  created() {
    const STORE_KEY = "$_applicant_master";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, applicantMasterStore);
    }
  },
};
</script>

<style scoped>
.add-cursor {
  cursor: pointer;
}

.mt-30 {
  margin-top: 30px;
}
.mb-30 {
  margin-bottom: 30px;
}
.logo-div {
  padding: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
}

.loginbox {
  margin: auto;
  max-width: 420px;
  width: 100%;
  height: 450px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 2px 8px #5b5558;
  border-radius: 25px;
}
.loginbox h5 {
  letter-spacing: 0.5px;
  display: block;
  border-radius: 30px;
  padding: 8px 20px;
  border: 1px solid #8f1452;
  max-width: 220px;
  margin: 20px auto;
}

.loginbox h5:hover {
  background-color: #8f1452;
  color: white;
  cursor: pointer;
}

.loginbox h5 img {
  width: 30px;
  height: 30px;
}

.loginbox h5 a {
  padding-left: 20px;
}

.page-bg {
  /* height: 100vh; */
  background-repeat: no-repeat;
  background-size: cover;
}
.home {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 15px;
}

@media (max-width: 768px) {
  .loginbox {
    width: 90%;
    height: 474px;
  }

  .home {
    position: fixed;
    width: 100%;
    text-align: center;
    bottom: 10px;
  }
}
h3 {
  text-align: center;
  border-bottom: 1px solid #efe6e6;
  padding-bottom: 15px;
}
.content {
  margin: auto !important;
}
::v-deep #maincontent {
  margin-top: 0px !important;
}
</style>
