<template>
  <div class="folder-item">
    <CollapseCard
      :title="item.name"
      class="theme-default my-2"
      :midDivider="false"
      header-class="px-0 py-0"
      :open="false"
      title-class="h5 m-0"
      :collapse="item.type === 'folder'"
    >
      <template v-slot:right v-if="!isFolder">
        <i
          @click.prevent="downloadFile(item.path)"
          class="fa fa-download"
          aria-hidden="true"
          style="color: grey; cursor: pointer"
        ></i>
      </template>
      <template v-slot:left>
        <i
          class="mr-2"
          :title="item.type === 'folder' ? 'folder' : 'file'"
          :class="item.type === 'folder' ? 'fa fa-folder' : 'fa fa-file'"
          aria-hidden="true"
          style="color: grey; cursor: pointer"
        ></i>
      </template>
      <div class="p-2 pl-4" v-if="isFolder">
        <FolderItem
          :item="child"
          v-for="(child, index) in item.children"
          :key="index"
          :downloadFile="downloadFile"
        />
        <div v-if="!item.children.length" class="text-center">
          No items to display
        </div>
      </div>
    </CollapseCard>
  </div>
</template>

<script>
import CollapseCard from "@/modules/Common/_components/collapse-card.vue";

export default {
  name: "FolderItem", // Name the component for recursion
  props: {
    item: {
      type: Object,
      required: true,
    },
    downloadFile: {
      type: Function,
    },
  },
  computed: {
    isFolder() {
      return this.item.type === "folder";
    },
    hasFiles() {
      return this.item.children.length;
    },
  },
  components: {
    CollapseCard,
  },
};
</script>
