<template>
  <div class="content">
    <common-header :data="['home', 'linkRequest']" type="applicant">
      <div class="text-right">
        <button class="btn btn-cp" type="button" @click="openLinkRequestModal">
          Link New Application
        </button>
      </div>
    </common-header>
    <div class="container-fluid">
      <div class="table-responsive">
        <table
          class="table table-striped mt-2"
          v-if="linkRequests.length > 0"
          aria-describedby="Link Requests Table"
        >
          <thead class="border-none">
            <tr>
              <th style="width: 20%">Sr. No.</th>
              <th style="width: 30%">Application Id</th>
              <th style="width: 30%">Date</th>
              <th style="width: 40%">Status</th>
              <th style="width: 40%">Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(request, index) in linkRequests" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ request.applicationId }}</td>
              <td>{{ createdAt(request.createdAt) }}</td>
              <td>
                <span
                  class="val-data badge px-2 mx-1"
                  :class="{
                    'badge-success': request.status === 'accepted',
                    'badge-danger': request.status === 'rejected',
                    'badge-secondary': request.status === 'pending',
                  }"
                >
                  {{ request.status }}
                </span>
              </td>
              <td>
                <div v-if="request.comments">
                  {{ request.comments }}
                </div>
                <div v-else>-</div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="center-container" v-else>
          <h4>No Link Requests Found</h4>
        </div>
      </div>
    </div>
    <link-request-modal></link-request-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import linkRequestModal from "./link-request-modal.vue";

export default {
  computed: {
    ...mapGetters({
      linkRequests: "$_applicant_master/getApplicantLinkRequests",
    }),
    createdAt() {
      return (request) => moment(request).format("MMMM Do YYYY");
    },
  },
  components: {
    linkRequestModal,
  },
  methods: {
    openLinkRequestModal() {
      $("#addLinkRequest").modal("show");
    },
  },
};
</script>

<style scoped>
.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh; /* Adjust if needed to fit your layout */
}
.mtbtn {
  margin-top: 20px;
}
table {
  width: 100%;
  border-collapse: collapse;
  display: block;
  margin-bottom: 20px;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.val-data {
  font-weight: normal !important;
  font-size: 0.9rem;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bottomslide {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes rightslide {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@keyframes spin {
  10% {
    width: 0;
    height: 0;
    border-width: 6px;
  }
  30% {
    width: 0;
    height: 0;
    border-radius: 50%;
    border-width: 1em;
    transform: rotate(0deg);
    border-color: #c7daf5;
  }
  50% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }
  70% {
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }
  90% {
    border-width: 4px;
  }
  100% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    transform: rotate(720deg);
    border-color: transparent;
  }
}
</style>
