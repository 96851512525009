var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modal",staticClass:"modal fade",attrs:{"id":"auditDiffModal","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content m-t100"},[_vm._m(0),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"well",staticStyle:{"overflow-x":"auto"}},[_c('table',{staticClass:"table table-condensed",attrs:{"aria-label":"Applicant Details","aria-hidden":"true"}},[_c('tbody',[_c('tr',[_vm._m(1),_c('td',{staticClass:"col-md-3"},[_vm._v(_vm._s(_vm.rowData.targetModel))]),_vm._m(2),_c('td',{staticClass:"col-md-3"},[_vm._v(_vm._s(_vm.rowData.targetId))])]),_c('tr',[_vm._m(3),_c('td',{staticClass:"col-md-3"},[_c('div',{staticClass:"d-flex"},[(_vm.rowData.created_by)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                        _vm.rowData.userType ? _vm.rowData.userType.toLowerCase() : ''
                      ),expression:"\n                        rowData.userType ? rowData.userType.toLowerCase() : ''\n                      ",modifiers:{"hover":true}}],staticClass:"circle-icon",staticStyle:{"margin":"5px"}},[_vm._v(" "+_vm._s(_vm.rowData.userType ? _vm.rowData.userType.charAt(0).toUpperCase() : " ")+" ")]):_vm._e(),_c('span',{staticClass:"created-by-name",staticStyle:{"margin":"5px"}},[_vm._v(" "+_vm._s(_vm.rowData.created_by_displayName))])])]),_vm._m(4),_c('td',{staticClass:"col-md-3"},[(_vm.rowData.action == 'UPDATE')?_c('label',{},[_vm._v("Updated Only: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.showChangedRows),expression:"showChangedRows"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.showChangedRows)?_vm._i(_vm.showChangedRows,null)>-1:(_vm.showChangedRows)},on:{"change":function($event){var $$a=_vm.showChangedRows,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.showChangedRows=$$a.concat([$$v]))}else{$$i>-1&&(_vm.showChangedRows=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.showChangedRows=$$c}}}})]):_vm._e()])])])])]),_c('h3',{staticClass:"text-center mt-4"},[_vm._v("Attributes")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-condensed",attrs:{"aria-label":"Audit Details"}},[_c('thead',[_c('tr',[_vm._m(5),(_vm.rowData.oldValue)?_c('th',{staticClass:"col-md-4"},[_c('strong',[_vm._v("Previous Version")])]):_vm._e(),(_vm.rowData.newValue)?_c('th',{staticClass:"col-md-4"},[_c('strong',[_vm._v("Latest Version")])]):_vm._e()])]),(_vm.rowData && Object.keys(_vm.rowData).length != 0)?_c('tbody',[(_vm.rowData.oldValue && _vm.rowData.newValue)?_vm._l((Object.keys(_vm.rowData.oldValue)),function(key){return _c('tr',{key:key},[(
                      !_vm.showChangedRows ||
                      !_vm.areObjectsEqual(
                        _vm.rowData.oldValue[key],
                        _vm.rowData.newValue[key]
                      )
                    )?[_c('td',[_vm._v(_vm._s(key))]),_c('td',{class:{
                        'text-danger': !_vm.areObjectsEqual(
                          _vm.rowData.oldValue[key],
                          _vm.rowData.newValue[key]
                        ),
                      }},[(_vm.rowData.oldValue[key] !== null)?_c('div',{staticClass:"long-text"},[_vm._v(" "+_vm._s(( JSON.stringify(_vm.rowData.oldValue[key]) || "" ).replace(/[\[\]"]+/g, ""))+" ")]):_vm._e()]),_c('td',{class:{
                        'text-success': !_vm.areObjectsEqual(
                          _vm.rowData.oldValue[key],
                          _vm.rowData.newValue[key]
                        ),
                      }},[(_vm.rowData.newValue[key] !== null)?_c('div',{staticClass:"long-text"},[_vm._v(" "+_vm._s(( JSON.stringify(_vm.rowData.newValue[key]) || "" ).replace(/[\[\]"]+/g, ""))+" ")]):_vm._e()])]:_vm._e()],2)}):(_vm.rowData.newValue)?_vm._l((Object.keys(_vm.rowData.newValue)),function(key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[(_vm.rowData.newValue[key] !== null)?_c('div',{staticClass:"long-text"},[_vm._v(" "+_vm._s((JSON.stringify(_vm.rowData.newValue[key]) || "").replace( /[\[\]"]+/g, "" ))+" ")]):_vm._e()])])}):(_vm.rowData.oldValue)?_vm._l((Object.keys(_vm.rowData.oldValue)),function(key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[(_vm.rowData.oldValue[key] !== null)?_c('div',{staticClass:"long-text"},[_vm._v(" "+_vm._s((JSON.stringify(_vm.rowData.oldValue[key]) || "").replace( /[\[\]"]+/g, "" ))+" ")]):_vm._e()])])}):_vm._e()],2):_vm._e()])])]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h3',{staticClass:"modal-title"},[_vm._v("Audit Comparison")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-3"},[_c('strong',[_vm._v("Target Model:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-3"},[_c('strong',[_vm._v("Target Id: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-3"},[_c('strong',[_vm._v("Created By : ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-3"},[_c('strong',[_vm._v(" Toggle Entries: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"col-md-4"},[_c('strong',[_vm._v("Attributes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-footer text-center"},[_c('button',{staticClass:"btn btn-cp",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_vm._v(" Close ")])])
}]

export { render, staticRenderFns }