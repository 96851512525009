import Vue from "vue";

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set applicant data in store
 */
const FETCH_APPLICANT_DATA = (state, data) => {
  state.applicantData = data;
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set applicant pic in store
 */
const FETCH_APPLICANT_PIC = (state, data) => {
  state.applicantPic = data;
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set round data in store
 */
const FETCH_ROUND_DETAILS = (state, data) => {
  state.roundDetails = data;
  if (state.roundDetails.length > 0) {
    state.lastRoundResult = data[data.length - 1].round_result;
  }
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Push new round to roundData in store
 */
const ADD_ROUND = (state, data) => {
  if (Array.isArray(data)) {
    data.forEach((round) => {
      state.roundDetails.push(round);
      state.lastRoundResult = round.round_result;
      state.applicantData.application_status = round.round_name + " Scheduled";
    });
  } else {
    state.roundDetails.push(data);
    state.lastRoundResult = data.round_result;
    state.applicantData.application_status = data.round_name + " Scheduled";
  }
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Push new comment to list in store
 */
const ADD_COMMENT = (state, data) => {
  //  Check if newly added comment is child comment
  if (data.parentCommentId) {
    const commentindex = state.commentList.findIndex((parentComment) => {
      return parentComment.id === data.parentCommentId;
    });
    state.commentList[commentindex].childComments.push(data);
  } else {
    if (!data.childComments) {
      data.childComments = [];
    }
    state.commentList = [...state.commentList, data];
  }
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description set comments in store
 */
const FETCH_COMMENTS_BY_APPLICANT_ID = (state, data) => {
  state.commentList = data;
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} roundData
 * @description Set modal round data in store for editing
 */
const SET_MODAL_ROUND_DATA = (state, roundData) => {
  state.modalRoundData = roundData;
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} roundData
 * @description Edit particular round in store
 */
const EDIT_ROUND = (state, roundData) => {
  const roundIndex = state.roundDetails.findIndex(
    (round) => round.id === roundData.id
  );
  state.roundDetails[roundIndex].round_schedule = roundData.round_schedule;
  if (
    state.roundDetails[roundIndex].interviewers &&
    state.roundDetails[roundIndex].interviewers.length != 0
  ) {
    state.roundDetails[roundIndex].interviewers = [];
    roundData.interviewers.forEach((interviewer) => {
      state.usersDetails.forEach((element) => {
        if (element.user_id == interviewer.interviewer_id) {
          state.roundDetails[roundIndex].interviewers.push({
            interviewer_id: parseInt(element.user_id),
            interviewer_email: element.email_address,
            interviewer_name: element.first_name + " " + element.last_name,
            formMasterId: roundData.formMasterId[0],
            id: interviewer.id,
          });
        }
      });
    });
  }
  if (
    state.roundDetails[roundIndex].reviewers &&
    state.roundDetails[roundIndex].reviewers.length != 0
  ) {
    state.roundDetails[roundIndex].reviewers = [];
    roundData.reviewers.forEach((reviewer) => {
      state.usersDetails.forEach((element) => {
        if (element.user_id == reviewer.reviewer_id) {
          state.roundDetails[roundIndex].reviewers.push({
            reviewer_id: element.user_id,
            reviewer_email: element.email_address,
            reviewer_name: element.first_name + " " + element.last_name,
            formMasterId: roundData.formMasterId[0],
            id: reviewer.id,
          });
        }
      });
    });
  }
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} roundData
 * @description Pass round in store
 */
const PASS_ROUND = (state, roundData) => {
  const roundIndex = state.roundDetails.findIndex(
    (round) => round.id === roundData.id
  );
  state.roundDetails[roundIndex].round_result = roundData.round_result;
  state.roundDetails[roundIndex].score = roundData.score;
  state.roundDetails[roundIndex].grade = roundData.grade;
  state.roundDetails[roundIndex].outOf = roundData.outOf;
  state.roundDetails[roundIndex].comments = roundData.comments;
  state.lastRoundResult = roundData.round_result;
  state.applicantData.application_status = roundData.round_name + " Passed";
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} roundData
 * @description Fail round in store
 */
const FAIL_ROUND = (state, roundData) => {
  const roundIndex = state.roundDetails.findIndex(
    (round) => round.id === roundData.id
  );
  state.roundDetails[roundIndex].round_result = roundData.round_result;
  state.lastRoundResult = roundData.round_result;
  state.roundDetails[roundIndex].score = roundData.score;
  state.roundDetails[roundIndex].grade = roundData.grade;
  state.roundDetails[roundIndex].outOf = roundData.outOf;
  state.roundDetails[roundIndex].comments = roundData.comments;
  state.applicantData.application_status = roundData.round_name + " Failed";
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} roundData
 * @description Scheduled Review in store
 */
const SCHEDULED_REVIEW = (state, roundData) => {
  const roundIndex = state.roundDetails.findIndex(
    (round) => round.id === roundData.id
  );
  state.roundDetails[roundIndex].round_result = roundData.round_result;
  state.lastRoundResult = roundData.round_result;
  state.roundDetails[roundIndex].score = roundData.score;
  state.roundDetails[roundIndex].grade = roundData.grade;
  state.roundDetails[roundIndex].outOf = roundData.outOf;
  state.roundDetails[roundIndex].comments = roundData.comments;
  state.applicantData.application_status = roundData.round_name + " Scheduled";
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} roundData
 * @description Differed Review in store
 */
const REJECTED_REVIEW = (state, roundData) => {
  const roundIndex = state.roundDetails.findIndex(
    (round) => round.id === roundData.id
  );
  state.roundDetails[roundIndex].round_result = roundData.round_result;
  state.lastRoundResult = roundData.round_result;
  state.roundDetails[roundIndex].score = roundData.score;
  state.roundDetails[roundIndex].grade = roundData.grade;
  state.roundDetails[roundIndex].outOf = roundData.outOf;
  state.roundDetails[roundIndex].comments = roundData.comments;
  state.applicantData.application_status = roundData.round_name + " Differed";
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} roundData
 * @description Revert round in store
 */
const REVERT_ROUND = (state, roundData) => {
  const roundIndex = state.roundDetails.findIndex(
    (round) => round.id === roundData.id
  );
  state.roundDetails[roundIndex].round_result = roundData.round_result;
  state.roundDetails[roundIndex].isMailed = roundData.isMailed;
  state.lastRoundResult = roundData.round_result;
  state.applicantData.application_status = roundData.round_name + " Scheduled";
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} roundData
 * @description Cancel round in store
 */
const CANCEL_ROUND = (state, roundData) => {
  const roundIndex = state.roundDetails.findIndex(
    (round) => round.id === roundData.id
  );
  state.roundDetails[roundIndex].round_result = roundData.round_result;
  state.lastRoundResult = "Cancelled";
  state.applicantData.application_status = roundData.round_name + " Cancelled";
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} applicationStatus
 * @description Edit main application status in store
 */
const EDIT_APPLICATION_STATUS = (state, applicationStatus) => {
  state.applicantData.application_status = applicationStatus;
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} interviewReview
 * @description Add interview review for round in store
 */
const ADD_INTERVIEW_REVIEW = (state, interviewReview) => {
  const roundIndex = state.roundDetails.findIndex(
    (round) => round.id === interviewReview.round_id
  );

  const interviewerIndex = state.roundDetails[
    roundIndex
  ].interviewers.findIndex(
    (interviwer) => interviwer.interviewer_id === interviewReview.interviewer_id
  );

  const newElement =
    state.roundDetails[roundIndex].interviewers[interviewerIndex];

  newElement.interviewer_remarks = interviewReview.interviewer_remarks;
  newElement.formVersionId = interviewReview.formVersionId;

  Vue.set(
    state.roundDetails[roundIndex].interviewers,
    interviewerIndex,
    newElement
  );
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} reviewRoundReview
 * @description Add review for Review round in store
 */
const ADD_REVIEW_ROUND_REVIEW = (state, reviewRoundReview) => {
  const roundIndex = state.roundDetails.findIndex(
    (round) => round.id === reviewRoundReview.round_id
  );
  const reviewerIndex = state.roundDetails[roundIndex].reviewers.findIndex(
    (reviwer) => reviwer.reviewer_id === reviewRoundReview.reviewer_id
  );
  const newElement = state.roundDetails[roundIndex].reviewers[reviewerIndex];
  newElement.reviewer_remarks = reviewRoundReview.reviewer_remarks;
  newElement.formVersionId = reviewRoundReview.formVersionId;

  Vue.set(state.roundDetails[roundIndex].reviewers, reviewerIndex, newElement);
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} usersDetails
 * @description Set interviewers and reviewers data in store
 */
const USERS_DETAILS = (state, usersDetails) => {
  state.usersDetails = usersDetails;
};

const SET_ID_IN_STATE = (state, id) => {
  state.applicantID = id;
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set job profiles in store
 */
const FETCH_JOB_PROFILES = (state, data) => {
  state.jobProfiles = data;
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set claimed_by detail of
 */
const CLAIMED_BY_DETAILS = (state, data) => {
  state.claimedByDetails = data;
};

/**
 * @memberOf module:ApplicantProfile.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set forms in store
 */
const ADD_FORM = (state, data) => {
  state.forms = { ...state.forms, [data.id]: data.form };
};

const SET_MOODLE_INSTANCE_LIST = (state, data) => {
  state.moodleInstanceList = data;
};

const UPDATE_FOLLOWUP = (state) => {
  state.followUpDetails[0].active_status = 1;
};

const ADD_FOLLOWUP = (state, data) => {
  if (state.followUpDetails.length) state.followUpDetails.unshift(data);
  else state.followUpDetails = [data];

  state.applicantData.followup_status = data.status;
};

const FOLLOW_UP_DETAILS = (state, data) => {
  state.followUpDetails = data;
};

/**
 * @memberOf module:ApplicantProfile
 * @namespace store.mutations
 * @description mutations for Applicant Profile
 */
export default {
  FETCH_APPLICANT_DATA,
  FETCH_ROUND_DETAILS,
  ADD_ROUND,
  SET_MODAL_ROUND_DATA,
  EDIT_ROUND,
  PASS_ROUND,
  FAIL_ROUND,
  SCHEDULED_REVIEW,
  REJECTED_REVIEW,
  REVERT_ROUND,
  CANCEL_ROUND,
  EDIT_APPLICATION_STATUS,
  ADD_INTERVIEW_REVIEW,
  ADD_REVIEW_ROUND_REVIEW,
  USERS_DETAILS,
  SET_ID_IN_STATE,
  ADD_COMMENT,
  FETCH_COMMENTS_BY_APPLICANT_ID,
  FETCH_JOB_PROFILES,
  CLAIMED_BY_DETAILS,
  SET_MOODLE_INSTANCE_LIST,
  ADD_FOLLOWUP,
  FOLLOW_UP_DETAILS,
  UPDATE_FOLLOWUP,
  ADD_FORM,
  FETCH_APPLICANT_PIC,
};
