<template>
  <div v-if="params.data">
    <i
      class="fa fa-thumbs-up fa-xl fa-flip-horizontal text-success"
      aria-hidden="true"
      v-if="params.data.status === 'Completed'"
      :id="'update-status-completed-popover-target-1' + params.rowIndex"
    >
    </i>
    <i
      v-else-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
      class="fa fa-thumbs-up fa-xl fa-flip-horizontal not-completed"
      role="button"
      aria-hidden="true"
      @click="openModal(params.data.id)"
      data-toggle="modal"
      data-target="#updateStatusModal"
      data-backdrop="static"
      data-keyboard="false"
      :id="'update-status-pending-popover-target-1' + params.rowIndex"
    >
    </i>
    <b-popover
      v-if="params.data.status === 'Completed'"
      :target="'update-status-completed-popover-target-1' + params.rowIndex"
      triggers="hover"
      placement="right"
    >
      Drive finished already!
    </b-popover>
    <b-popover
      v-else
      :target="'update-status-pending-popover-target-1' + params.rowIndex"
      triggers="hover"
      placement="right"
    >
      Mark completed
    </b-popover>
  </div>
</template>

<script>
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

/**
 * @memberof module:campusRecruitment
 * @namespace components.update_status
 */
export default {
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].RECRUITMENT;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:campusRecruitment.components.update_status
     * @param {nummber} id
     * @description Open confirmation model for updating status
     */
    openModal(id) {
      this.$store.dispatch("$_campus_recruitment/setCampusRecruitmentId", id);
    },
  },
};
</script>
<style scoped>
.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em;
}

.not-completed {
  color: #337ab7;
}
</style>
