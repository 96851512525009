import { COMMON_DATA_COL_DEFS } from "../../Common/_utils/application_data_columns_common";

export const APPLICANTS_DATA_COL_DEFS = [
  ...COMMON_DATA_COL_DEFS,
  {
    headerName: "Campus Recruitment",
    field: "recruitment_session_name",
    minWidth: 200,
    sortable: true,
    valueGetter: function dateFormat(params) {
      if (params.data != undefined) {
        if (params.data.belongsToCampusRecruitement != null) {
          return params.data.belongsToCampusRecruitement
            .recruitment_session_name;
        }
      }
    },
  },
];
