<template>
  <div class="content">
    <common-header :data="['home', 'mail', 'status']">
      <div class="d-flex">
        <div
          v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
          class="toggle-div action-item"
          title="Toggle mail feature"
        >
          <label class="switch mb-0 mr-1" htmlFor="mailFeatureEnable">
            <input
              type="checkbox"
              v-model="mailFeatureEnabled"
              @change="changeMailFeatureStatus"
            />
            <span class="slider round"></span>
          </label>
        </div>
        <div
          v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
          class="refresh-div action-item"
        >
          <button class="btn btn-cp ml-2" @click="refreshGrid()">
            <i class="fa fa-repeat p-0" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </common-header>
    <div class="container-fluid">
      <div class="row mx-0">
        <div class="col-auto mr-auto p-0">
          <div
            v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            class="dropdown action-item"
          >
            <button
              class="btn dropdown-toggle btn-cp ml-0"
              type="button"
              data-toggle="dropdown"
            >
              Actions
            </button>
            <ul class="dropdown-menu dropdown-menu-left">
              <li class="dropdown-item" style="padding: 0px">
                <a @click="markMailAsPending">Mark Selected As Pending</a>
              </li>
              <li class="dropdown-item" style="padding: 0px">
                <a @click="markMailAsMailed">Mark Selected As Mailed</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-auto d-flex p-0 ml-auto">
          <div
            class="refresh-div"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
          >
            <p
              class="alert alert-info alert-sm mx-2"
              v-if="selectedRowCount > 0"
            >
              <span class="hide-on-mobile"
                >{{ selectedRowCount }} selected</span
              >
              <span class="hide-on-desktop px-2">{{ selectedRowCount }}</span>
            </p>
            <button
              class="btn btn-cp hide-on-mobile mr-2"
              v-if="
                selectedRowCount > 0 &&
                (rowCountOnCurrentPage === -1 ||
                  selectedRowCount < rowCountOnCurrentPage)
              "
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-minus-square" aria-hidden="true"></i> Select all
            </button>
            <button
              class="btn btn-cp hide-on-mobile mr-2"
              v-else-if="selectedRowCount && rowCountOnCurrentPage !== -1"
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-check-square" aria-hidden="true"></i> Deselect all
            </button>
            <button
              class="btn btn-cp hide-on-mobile mr-2"
              v-else
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-square" aria-hidden="true"></i> Select all
            </button>
            <button
              class="btn btn-cp hide-on-desktop mr-2"
              @click="selectAllOnCurrentPage"
            >
              <i
                class="fa"
                :class="{
                  'fa-check-square':
                    selectedRowCount && rowCountOnCurrentPage !== -1,
                  'fa-square':
                    selectedRowCount === 0 || rowCountOnCurrentPage === -1,
                  'fa-minus-square':
                    selectedRowCount > 0 &&
                    (rowCountOnCurrentPage === -1 ||
                      selectedRowCount < rowCountOnCurrentPage),
                }"
                aria-hidden="true"
              ></i>
            </button>
          </div>
          <div
            v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
            class="pagesize"
          >
            <select
              @change="onPageSizeChanged($event)"
              class="form-control px-2 select-cp"
            >
              <option class="pagesizeoption" value="50">50</option>
              <option class="pagesizeoption" value="100" selected>100</option>
              <option class="pagesizeoption" value="200">200</option>
              <option class="pagesizeoption" value="500">500</option>
              <option class="pagesizeoption" value="1000">1000</option>
              <option class="pagesizeoption" value="2000">2000</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mx-0">
        <div class="col-12 p-0 pt-2">
          <ag-grid-vue
            v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
            class="ag-theme-alpine ag-grid-style ag-grid-footer-style grid-container"
            :gridOptions="gridOptions"
            @gridReady="onGridReady"
            @selection-changed="onSelectionChanged"
            rowSelection="multiple"
            :key="paginationSize"
          >
          </ag-grid-vue>
        </div>
      </div>
    </div>
    <view-mail-modal :mailData="mailModalData"></view-mail-modal>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { mapGetters } from "vuex";
import axios from "axios";
import ViewMailButton from "./view-mail-button";
import ViewMailModal from "./view-mail-modal";
import { ARRAY_FILTER_PARAMS } from "../../Common/_utils/filter-params-constants";
import getFilterByType from "../../Common/_utils/get-filter-by-type";
import configurations from "../../../../config/configurations";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

/**
 * @memberOf module:ApplicantsData
 * @namespace components.mail_dashboard
 * @description Applicant data component
 */
export default {
  data() {
    return {
      paginationSize: 100,
      gridOptions: null,
      columnDefs: null,
      selectedMails: [],
      flag: null,
      rowCountOnCurrentPage: -1,
      selectedRowCount: 0,
      mailModalData: null,
      mailFeatureEnabled: false,
      mailFeatureData: false,
    };
  },
  components: {
    "ag-grid-vue": AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "view-mail-button": ViewMailButton,
    "view-mail-modal": ViewMailModal,
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    onGridReady(params) {
      if (params.api) {
        this.gridOptions.api = params.api;
        this.setDataSource();
      }
    },
    setDataSource() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      let dataSource = {
        rowCount: null,
        rowCountPagination: 0,
        rowCountFiltering: 0,
        _self: this,
        getRows: function (params) {
          //Set Query Params and filter body to fetch data
          let queryParams = `?startRow=${params.startRow}&size=${
            params.endRow - params.startRow
          }`;
          let queryObj = {};
          if (params.sortModel) {
            queryObj.sort = params.sortModel[0];
          }
          for (const key in params.filterModel) {
            if (Object.prototype.hasOwnProperty.call(params.filterModel, key)) {
              queryObj[key] = getFilterByType(params.filterModel[key]);
            }
          }

          if (
            Object.keys(params.filterModel).length != 0 ||
            Object.keys(params.sortModel).length != 0
          ) {
            this.rowCountPagination = 0;
            if (params.startRow == 0) {
              this.rowCountFiltering = 0;
            }
            axios.post("mails/get_mails" + queryParams, queryObj).then(
              (data) => {
                this.rowCountFiltering += data.data.length;
                let lastRow = -1;
                if (this.rowCountFiltering < params.endRow) {
                  lastRow = this.rowCountFiltering;
                }
                params.successCallback(data.data, lastRow);
              },
              (err) => {
                console.log(err);
              }
            );
          } else {
            this.rowCountFiltering = 0;
            axios.post("mails/get_mails" + queryParams).then(
              (data) => {
                this.rowCountPagination += data.data.length;
                let lastRow = -1;
                if (this.rowCountPagination < params.endRow) {
                  lastRow = this.rowCountPagination;
                }
                params.successCallback(data.data, lastRow);
              },
              (err) => {
                console.log(err);
              }
            );
          }
          this._self.resetRowCountData();
        },
      };

      this.gridOptions.api.setGridOption("datasource", dataSource);
    },
    setViewMailModalData(modalData) {
      this.mailModalData = modalData;
      $("#viewMailModal").modal("show");
    },
    createColumnDefs() {
      this.columnDefs = [
        {
          headerName: "Mail Date",
          field: "createdAt",
          sortable: true,
          width: 200,
          minWidth: 150,
          checkboxSelection: true,
          valueGetter: function dateFormat(params) {
            if (params.data != undefined) {
              let dt = new Date(params.data.createdAt);
              return dt.toLocaleDateString().toString();
            }
          },
          filter: "agDateColumnFilter",
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "To",
          field: "mail_to",
          sortable: true,
          width: 200,
          minWidth: 150,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "CC",
          field: "mail_cc",
          sortable: true,
          filterParams: {
            filterOptions: ARRAY_FILTER_PARAMS,
          },
          width: 200,
          minWidth: 120,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "BCC",
          field: "mail_bcc",
          sortable: true,
          filterParams: {
            filterOptions: ARRAY_FILTER_PARAMS,
          },
          width: 200,
          minWidth: 120,
          suppressHeaderMenuButton: true,
        },

        {
          headerName: "Subject",
          field: "mail_subject",
          sortable: true,
          width: 150,
          minWidth: 150,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Status",
          field: "mail_status",
          sortable: true,
          width: 150,
          minWidth: 100,
          suppressHeaderMenuButton: true,
        },
        {
          headerName: "Error",
          field: "error",
          sortable: true,
          width: 200,
          minWidth: 100,
          suppressHeaderMenuButton: true,
        },

        {
          headerName: "Action",
          cellRenderer: "view-mail-button",
          valueGetter: "node.id",
          width: 100,
          minWidth: 100,
          suppressHeaderMenuButton: true,
          suppressFilter: true,
          filter: false,
          pinned: "right",
        },
      ];
    },
    onPageSizeChanged(event) {
      const rowCount = Number(event.target.value);
      this.gridOptions.paginationPageSize = rowCount;
      this.gridOptions.cacheBlockSize = rowCount;
      this.paginationSize = rowCount;
    },
    refreshGrid() {
      this.setDataSource();
      this.resetRowCountData();
    },
    selectAllOnCurrentPage() {
      const result = this.rowCountOnCurrentPage !== this.selectedRowCount;
      this.selectedRowCount = 0;
      this.gridOptions.api.forEachNode((node) => {
        node.setSelected(result);
        this.selectedRowCount++;
      });
      this.rowCountOnCurrentPage = this.selectedRowCount;
    },
    resetRowCountData() {
      this.rowCountOnCurrentPage = -1;
      this.selectedRowCount = 0;
    },
    changeMailFeatureStatus(event) {
      let params = {
        value: event.target.checked ? "true" : "false",
      };
      if (!this.mailFeatureData) {
        params.create = "true";
      }
      axios.get("mails/mailFeatureStateChange", { params }).then(
        () => {
          this.$store.dispatch("openSnackbar", {
            type: "success",
            message: "Mail feature status successfully changed!",
          });
        },
        () => {
          this.mailFeatureEnabled = params.value != "true";
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Error changing Mail feature status!",
          });
        }
      );
    },

    /**
     * @memberOf module:ApplicantsData.components.applicant_data
     * @description Called everytime the selected rows get changed in the grid
     */
    onSelectionChanged() {
      this.selectedMails = this.gridOptions.api.getSelectedRows();
      this.selectedRowCount = this.selectedMails.length;
    },

    markMailAsPending() {
      if (this.isRecordSelected()) {
        let updatedMailData = [];
        this.selectedMails.forEach((mail) => {
          updatedMailData.push({
            id: mail.id,
            status: "pending",
          });
        });
        this.$store.dispatch("$_mailbox/updateMailData", updatedMailData);
        this.resetRowCountData();
      } else console.log("No");
    },
    markMailAsMailed() {
      if (this.isRecordSelected()) {
        let updatedMailData = [];
        this.selectedMails.forEach((mail) => {
          updatedMailData.push({
            id: mail.id,
            status: "mailed",
          });
        });
        this.$store.dispatch("$_mailbox/updateMailData", updatedMailData);
        this.resetRowCountData();
      } else console.log("No");
    },
    isRecordSelected() {
      return this.selectedMails != 0;
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.MAIL;
    },
    ...mapGetters({
      mailData: "$_mailbox/getMailUpdateStatus",
    }),
  },
  watch: {
    mailData() {
      this.setDataSource();
    },
  },
  beforeMount() {
    this.createColumnDefs();
    this.gridOptions = {
      floatingFilter: true,
      suppressPropertyNamesCheck: true,
      enableCellTextSelection: true,
      enableServerSideFilter: true,
      enableColResize: true,
      rowSelection: "multiple",
      rowDeselection: true,
      rowModelType: "infinite",
      defaultColDef: {
        flex: 1,
        resizable: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        floatingFilter: true,
        // set filtering on for all columns
        filter: true,
      },
      columnDefs: this.columnDefs,
      infiniteInitialRowCount: 0,
      animateRows: true,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 2,
      pagination: true,
      paginationPageSize: 100,
      paginationPageSizeSelector: false,
      cacheBlockSize: 100,
      context: {
        componentParent: this,
      },
    };
  },
  mounted() {},
  created() {
    axios
      .get("system-configs/by/key", {
        params: { key: configurations.MAILING_FACILITY_PROCESS.key },
      })
      .then(
        (res) => {
          this.mailFeatureData = res.data;
          this.mailFeatureEnabled = res.data
            ? res.data.value[0] == "true"
            : false;
          if (!res.data) {
            this.$store.dispatch("openSnackbar", {
              type: "danger",
              message: "Mail feature key does not exist!",
            });
          }
        },
        () => {
          this.mailFeatureData = null;
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Error retrieving Mail feature status!",
          });
        }
      );
  },
};
</script>

<style scoped>
.content h1 {
  margin-bottom: 0;
  border: 0px;
}

.action-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 3px;
}

.dropdown-menu li {
  border-bottom: 1px solid transparent !important;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-item:hover {
  background-color: #8f1452;
  color: white;
}

#page-size {
  background: #8f1452;
  color: #fff !important;
  padding: 8px 25px;
  border: 1px solid #903564;
  font-size: 15px;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
}

#page-size:focus-visible,
#page-size:focus {
  outline: none;
}

.pagesizeoption {
  background-color: white;
  color: black;
}

.refresh-div {
  display: flex;
  align-items: center;
}

.toggle-div {
  display: flex;
  align-items: center;
}

.ag-grid-style {
  width: 100%;
  min-height: 400px;
  height: calc(100vh - 180px);
}

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 33px;
  padding-right: 10px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 6px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #903564;
}

input:focus + .slider {
  box-shadow: 0 0 1px #903564;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.container {
  max-width: 100%;
  padding: 10px 15px;
}

/* MEDIA QUERY FOR LARGE SCREEN */
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
  .grid-container {
    height: calc(100vh - 185px);
  }
}

@media (min-width: 769px) {
  .hide-on-desktop {
    display: none !important;
  }
}

/* MEDIA QUERY FOR MEDIUM SCREEN */
@media (max-width: 576px) {
  .container {
    padding: 10px 6px;
  }
  .grid-container {
    height: calc(100vh - 190px);
  }
}

@media (max-width: 575px) {
  .action-items {
    justify-content: center;
  }
}

/* MEDIA QUERY FOR SMALL SCREEN */
@media screen and (max-width: 315px) {
  .grid-container {
    height: calc(100vh - 230px);
  }
}

/* MEDIA QUERY FOR SMALLER SCREEN */
@media screen and (max-width: 230px) {
  .grid-container {
    height: calc(100vh - 295px);
  }
}
</style>
