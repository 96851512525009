var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-header",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"container-fluid py-1"},[_c('div',{staticClass:"breadcrumb-parent d-flex align-items-center justify-content-between p-0",staticStyle:{"flex-wrap":"wrap"}},[_c('b-breadcrumb',{staticClass:"d-flex align-items-center m-0 p-0 py-2"},_vm._l((_vm.dataOptions),function(option,index){return _c('b-breadcrumb-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html.bottom",value:(option.tooltip),expression:"option.tooltip",modifiers:{"hover":true,"html":true,"bottom":true}}],key:index,staticClass:"d-flex align-items-center",attrs:{"active":option.active}},[(option.type == 'page')?_c('router-link',{attrs:{"to":{
              name: option.name,
              ...(option.params ? option.params : {}),
            }}},[(option.icon)?_c('i',{class:option.icon,attrs:{"aria-hidden":"true"}}):_vm._e(),_vm._v(_vm._s(option.value ? option.value : option.key)+" ")]):_c('div',[_c('a',{staticClass:"px-0",attrs:{"data-toggle":"dropdown"}},[_vm._v(" "+_vm._s(option.key)+" ")]),_c('div',{staticClass:"dropdown-menu z-3"},_vm._l((option.options),function(suboption,index){return _c('div',{key:index},[(suboption.permission)?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{
                    name: suboption.name,
                    ...(suboption.params ? suboption.params : {}),
                  }}},[_vm._v(" "+_vm._s(suboption.key)+" ")]):_vm._e()],1)}),0)])],1)}),1),_c('div',{staticClass:"align-right ml-auto"},[_vm._t("default")],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }