<template>
  <div id="positionTracker">
    <div class="header-title">Open Positions</div>
    <div class="tracker-box">
      <div
        v-for="jobPosition in filteredOpenJobPositions"
        :key="jobPosition.id"
        :title="jobPosition.job.title"
        class="tracker-box-item"
      >
        <div class="number-section" :style="generateRandomBackgroundColor()">
          {{ jobPosition.open | formatNumber("00") }}
        </div>
        <div class="title-section" :style="generateRandomBackgroundColor()">
          {{ jobPosition.job.title }}
        </div>
      </div>
    </div>
    <div class="tracker-box total-row">
      <div class="tracker-box-item" title="Total">
        <div class="number-section" :style="generateRandomBackgroundColor()">
          {{ totalOpenPositions | formatNumber("00") }}
        </div>
        <div class="title-section" :style="generateRandomBackgroundColor()">
          Total
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  methods: {
    generateRandomBackgroundColor() {
      const minBrightness = 100;
      const r = Math.floor(
        minBrightness + Math.random() * (255 - minBrightness)
      );
      const g = Math.floor(
        minBrightness + Math.random() * (255 - minBrightness)
      );
      const b = Math.floor(
        minBrightness + Math.random() * (255 - minBrightness)
      );
      return `background-color: rgba(${r},${g},${b},.6)`;
    },
    getOpenPositions() {
      this.$store.dispatch("$_applicants_data/fetchOpenJobPositions");
    },
  },
  computed: {
    filteredOpenJobPositions() {
      return this.openJobPositions.filter((position) => position.open > 0);
    },
    totalOpenPositions() {
      let total = 0;
      this.openJobPositions &&
        this.openJobPositions?.forEach(function (jobPosition) {
          total += jobPosition.open * 1;
        });
      return total;
    },
    ...mapGetters({
      openJobPositions: "$_applicants_data/fetchOpenJobPositions",
    }),
  },
  mounted() {
    this.getOpenPositions();
  },
};
</script>
<style scoped>
.header-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.tracker-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.tracker-box-item {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.number-section,
.title-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
  /* font-weight: bold; */
  height: 50px;
}

.number-section {
  flex: 25%;
  font-size: 24px;
}

.title-section {
  flex: 75%;
  font-size: 16px;
  text-transform: uppercase;
}

.total-row {
  width: 100%;
}
</style>
