import Vue from "vue";

/**
 * @memberof module:SharedToken.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set all shared tokens
 */
const SET_ALL_SHARED_TOKENS = (state, data) => {
  state.sharedTokens = data;
};

/**
 * @memberof module:SharedToken.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set endpoint object
 */
const SET_ENDPOINT_OBJECT = (state, data) => {
  state.endpointObject = data;
};

/**
 * @memberof module:SharedToken.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Add shared token to the list
 */
const APPEND_SHARED_TOKEN = (state, data) => {
  state.sharedTokens.push(data);
};

/**
 * @memberof module:SharedToken.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Update shared token in the list
 */
const UPDATE_SHARED_TOKEN = (state, data) => {
  const tokenIdx = state.sharedTokens.findIndex(
    (token) => token.id === data.id
  );
  Vue.set(state.sharedTokens, tokenIdx, data);
};

/**
 * @memberof module:SharedToken.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Delete shared token in the list
 */
const DELETE_SHARED_TOKEN = (state, id) => {
  const tokenIdx = state.sharedTokens.findIndex((token) => token.id === id);
  Vue.delete(state.sharedTokens, tokenIdx);
};

export default {
  SET_ALL_SHARED_TOKENS,
  APPEND_SHARED_TOKEN,
  UPDATE_SHARED_TOKEN,
  DELETE_SHARED_TOKEN,
  SET_ENDPOINT_OBJECT,
};
