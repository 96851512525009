/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of Review Categories and Reviews to be get
 * @description commits data of Review categories and Reviews
 */
const fetchReviewCategoriesWithReviews = (state, data) => {
  state.reviewCategoriesWithReviews = data;
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} review data of Review to be post
 * @description commits data of Review
 */
const addReview = (state, review) => {
  const reviewCategoryIndex = state.reviewCategoriesWithReviews.findIndex(
    (c) => c.id === review.reviewCategoryId
  );
  state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews.push(review);
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} reviewCategory data of Review Category to be post
 * @description commits data of Review category
 */
const addReviewCategory = (state, reviewCategory) => {
  reviewCategory.reviews = [];
  state.reviewCategoriesWithReviews.push(reviewCategory);
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} reviewCategoryData data of Review Category to be get for add Review
 * @description commits data of Review
 */
const ADD_REVIEW_MODAL_OPENED = (state, reviewCategoryData) => {
  state.reviewCategoryDataForAddReviewModal = reviewCategoryData;
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} itemDeleteData data of Review Category and Reviews to be deleted
 * @description commits data of Review category and Reviews
 */
const DELETE_ITEM_MODAL = (state, itemDeleteData) => {
  state.itemDeleteData = itemDeleteData;
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} reviewCategoryId data of Review Category to be deleted
 * @description commits data of Review category
 */
const DELETE_REVIEW_CATEGORY = (state, reviewCategoryId) => {
  const index = state.reviewCategoriesWithReviews.findIndex(
    (reviewCategory) => reviewCategory.id === reviewCategoryId
  );
  state.reviewCategoriesWithReviews.splice(index, 1);
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} deleteReviewData data of Review Review to be deleted
 * @description commits data of Review
 */
const DELETE_REVIEW = (state, deleteReviewData) => {
  let reviewCategoryId = deleteReviewData.reviewCategoryId;
  let reviewId = deleteReviewData.reviewId;

  const reviewCategoryIndex = state.reviewCategoriesWithReviews.findIndex(
    (reviewCategory) => reviewCategory.id === reviewCategoryId
  );

  const reviewIndex = state.reviewCategoriesWithReviews[
    reviewCategoryIndex
  ].reviews.findIndex((review) => review.id === reviewId);

  state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews.splice(
    reviewIndex,
    1
  );
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editReviewData data of Review to edit
 * @description commits data of Review to be set in edit Review Modal
 */
const EDIT_REVIEW_MODAL = (state, editReviewData) => {
  state.reviewDataForEditReviewModal = editReviewData;
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editReviewData data of Review to be edited
 * @description commits data of Review to be edited
 */
const EDIT_REVIEW = (state, { prevCatId, objectInfo }) => {
  const reviewCategoryIndex = state.reviewCategoriesWithReviews.findIndex(
    (reviewCategory) => reviewCategory.id === objectInfo.reviewCategoryId
  );
  const reviewIndex = state.reviewCategoriesWithReviews[
    reviewCategoryIndex
  ].reviews.findIndex((review) => review.id === objectInfo.id);
  if (reviewIndex > -1) {
    state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews[
      reviewIndex
    ].review_name = objectInfo.review_name;
    state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews[
      reviewIndex
    ].review_description = objectInfo.review_description;
    state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews[
      reviewIndex
    ].status = objectInfo.status;
  } else {
    const prevReviewCategoryIndex = state.reviewCategoriesWithReviews.findIndex(
      (reviewCategory) => reviewCategory.id === prevCatId
    );
    const prevReviewIndex = state.reviewCategoriesWithReviews[
      prevReviewCategoryIndex
    ].reviews.findIndex((review) => review.id === objectInfo.id);
    state.reviewCategoriesWithReviews[prevReviewCategoryIndex].reviews.splice(
      prevReviewIndex,
      1
    );
    state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews.push(
      objectInfo
    );
  }
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editReviewCategoryData data of Review Category
 * @description commits data of Review category to be set in edit Review category modal
 */
const EDIT_REVIEW_CATEGORY_MODAL = (state, editReviewCategoryData) => {
  state.reviewCategoryDataForEditReviewCategoryModal = editReviewCategoryData;
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editReviewCategoryData data of Review Category
 * @description commits data of Review category to be edited
 */
const EDIT_REVIEW_CATEGORY = (state, editReviewCategoryData) => {
  const reviewCategoryIndex = state.reviewCategoriesWithReviews.findIndex(
    (reviewCategory) => reviewCategory.id === editReviewCategoryData.id
  );

  state.reviewCategoriesWithReviews[reviewCategoryIndex].review_category_name =
    editReviewCategoryData.review_category_name;
  state.reviewCategoriesWithReviews[
    reviewCategoryIndex
  ].review_category_description =
    editReviewCategoryData.review_category_description;
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editReviewCategoryData data of Review Category
 * @description commits data of Review category to be edited
 */
const UPDATE_REVIEW_CATEGORY_WITH_REVIEWS_STATUS = (
  state,
  editedReviewCategory
) => {
  const reviewCategoryIndex = state.reviewCategoriesWithReviews.findIndex(
    (reviewCategory) => reviewCategory.id === editedReviewCategory.id
  );

  state.reviewCategoriesWithReviews[reviewCategoryIndex].status =
    editedReviewCategory.status;
  state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews.forEach(
    (review) => {
      review.status =
        state.reviewCategoriesWithReviews[reviewCategoryIndex].status;
    }
  );
};

/**
 * @memberOf module:ManageReview.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} editReviewCategoryData data of Review Category
 * @description commits data of Review category to be edited
 */
const UPDATE_REVIEW_STATUS = (state, editedReview) => {
  const reviewCategoryIndex = state.reviewCategoriesWithReviews.findIndex(
    (reviewCategory) => reviewCategory.id === editedReview.reviewCategoryId
  );
  const reviewIndex = state.reviewCategoriesWithReviews[
    reviewCategoryIndex
  ].reviews.findIndex((review) => review.id === editedReview.id);

  let statusCount = 0;
  state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews[
    reviewIndex
  ].status = editedReview.status;
  state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews.forEach(
    (review) => {
      if (review.status === editedReview.status) {
        ++statusCount;
      }
    }
  );
  if (
    statusCount ===
    state.reviewCategoriesWithReviews[reviewCategoryIndex].reviews.length
  ) {
    state.reviewCategoriesWithReviews[reviewCategoryIndex].status =
      editedReview.status;
  } else {
    state.reviewCategoriesWithReviews[reviewCategoryIndex].status = "active";
  }
};

/**
 * @memberOf module:ManageReview
 * @namespace store.mutations
 * @description mutations for manage review module
 */
export default {
  fetchReviewCategoriesWithReviews,
  addReview,
  addReviewCategory,
  ADD_REVIEW_MODAL_OPENED,
  DELETE_ITEM_MODAL,
  DELETE_REVIEW_CATEGORY,
  DELETE_REVIEW,
  EDIT_REVIEW_MODAL,
  EDIT_REVIEW,
  EDIT_REVIEW_CATEGORY_MODAL,
  EDIT_REVIEW_CATEGORY,
  UPDATE_REVIEW_CATEGORY_WITH_REVIEWS_STATUS,
  UPDATE_REVIEW_STATUS,
};
