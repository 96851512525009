<template>
  <div class="content">
    <common-header
      v-if="applicantData"
      :data="['home', 'applications', 'singleApplication']"
      :componentName="applicantData.first_name + ' ' + applicantData.last_name"
      type="applicant"
    />

    <div class="container-fluid mb-3">
      <div v-if="applicantData">
        <div>
          <div class="well container section-head mx-auto">
            <div class="row mx-0">
              <div class="col-md-5">
                Applied {{ appliedAt }} ({{
                  applicantData.createdAt | dateFormat
                }})
              </div>
              <div class="col-md-7 text-right">
                <span>
                  {{ applicantData.application_status }}
                </span>
              </div>
            </div>
          </div>
          <div class="container p-0 mt-3">
            <div class="row mx-0">
              <div class="col-md-12 px-0">
                <div class="well container">
                  <table
                    class="table table-condensed table-compressed"
                    aria-label="Applicant Details"
                  >
                    <tbody v-if="applicantData">
                      <thead>
                        <th colspan="10">
                          <h4>
                            <strong>Application Details</strong>
                          </h4>
                        </th>
                      </thead>
                      <tr>
                        <td class="col-md-4">
                          <strong>Last Updated On</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.updatedAt | dateTimeFormat }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Created On</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.createdAt | dateTimeFormat }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Applied for Position</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.position }}</td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Application Status</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.application_status }}
                        </td>
                      </tr>
                      <tr v-if="applicantData.feedback">
                        <td class="col-md-2">
                          <strong>Message to Hiring Team</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.feedback }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="col-md-12 px-0"
                v-if="applicantData.designation_offered"
              >
                <div class="well container well2">
                  <table
                    class="table table-condensed table-compressed"
                    aria-label="Offer Details"
                  >
                    <thead>
                      <th colspan="10">
                        <h4>
                          <strong>Offer Details</strong>
                        </h4>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="col-md-4">
                          <strong>Designation</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.designation_offered }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Package</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.package_offered }} LPA
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Expected Joining</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.expected_joining | dateFormat }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12 px-0 pb-3">
                <div class="well container">
                  <h4 class="border-bottom pb-2">
                    <strong>Status Timeline</strong>
                  </h4>
                  <div class="py-2">
                    <timeline class="m-0">
                      <timeline-title>Applicant Status Timeline</timeline-title>
                      <timeline-item
                        bg-color="#9dd8e0"
                        v-for="(event, index) in applicantRounds"
                        :key="index"
                      >
                        <div class="d-flex justify-content-between flex-wrap">
                          <div
                            class="d-flex align-items-md-center justify-content-stretch my-1 flex-column flex-md-row"
                          >
                            <div
                              v-if="
                                event.eventSlot &&
                                event.eventSlot.belongToCampusEvents
                              "
                              class="mx-2"
                            >
                              {{
                                event.eventSlot.belongToCampusEvents
                                  .round_title
                              }}&nbsp; &nbsp;-
                            </div>
                            <div class="d-flex align-items-center ml-auto">
                              <div class="mx-2 text-secondary format-date">
                                {{ event.round_name }}
                              </div>
                              -
                              <div>
                                <p
                                  :class="[getColor(event.round_result)]"
                                  class="mx-2 px-2 rounded mb-0"
                                >
                                  {{ event.round_result }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            class="d-flex text-secondary my-1"
                            v-if="event.eventSlot"
                          >
                            <div class="mx-2 format-date">
                              {{ formatDate(event.eventSlot.from_time) }}
                            </div>
                            -
                            <div class="mx-2 format-date">
                              {{ formatDate(event.eventSlot.to_time) }}
                            </div>
                          </div>
                          <div v-else>
                            <div class="mx-2 format-date">
                              {{ formatDate(event.round_schedule) }}
                            </div>
                          </div>
                        </div>
                      </timeline-item>
                      <timeline-item :hollow="true">
                        <div class="d-flex justify-content-between">
                          <div>Applied At</div>
                          <div class="mx-2 format-date text-secondary">
                            {{ formatDate(applicantData.createdAt) }}
                          </div>
                        </div>
                      </timeline-item>
                    </timeline>
                  </div>
                </div>
              </div>
              <div class="col-md-12 px-0">
                <div class="well container">
                  <table
                    class="table table-condensed table-compressed"
                    aria-label="Personal Details"
                  >
                    <thead>
                      <th colspan="10">
                        <h4>
                          <strong>Personal Details</strong>
                        </h4>
                      </th>
                    </thead>
                    <tbody v-if="applicantData">
                      <tr v-if="applicantData.college_name">
                        <td class="col-md-4">
                          <strong>College Name</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.college_name }}
                        </td>
                      </tr>
                      <tr v-if="applicantData.profile_picture">
                        <td class="col-md-4">
                          <strong>Profile Pic</strong>
                        </td>
                        <td class="col-md-4">
                          <img
                            :src="
                              'data:image/jpeg;base64,' +
                              applicantData.profile_picture
                            "
                            alt="Profile"
                            class="d-block rounded-circle"
                            style="width: 60px; height: 60px; object-fit: cover"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>First Name</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.first_name }}</td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Last Name</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.last_name }}</td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Email</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.email }}</td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Mobile No.</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.mobile_no }}</td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Date of Birth</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.dob | dateFormat }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Gender</strong>
                        </td>
                        <td class="col-md-4">{{ applicantData.gender }}</td>
                      </tr>
                      <tr v-if="applicantData.address_line_1">
                        <td class="col-md-4">
                          <strong>Permanent Address</strong>
                        </td>
                        <td>
                          {{ applicantData.address_line_1 }},
                          {{ applicantData.address_line_2 }},
                          {{ applicantData.city }},
                          {{ applicantData.state }}
                        </td>
                      </tr>
                      <tr v-if="applicantData.current_address_line_1">
                        <td class="col-md-4">
                          <strong>Current Address</strong>
                        </td>
                        <td>
                          {{ applicantData.current_address_line_1 }},
                          {{ applicantData.current_address_line_2 }},
                          {{ applicantData.current_city }},
                          {{ applicantData.current_state }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-12 px-0">
                <div class="well container">
                  <table
                    class="table table-condensed table-compressed"
                    aria-label="Professional Details"
                  >
                    <thead>
                      <th colspan="10">
                        <h4>
                          <strong>Professional Details</strong>
                          <p
                            style="
                              float: right;
                              font-size: medium;
                              cursor: pointer;
                            "
                            v-if="applicantData.resume !== 'none'"
                            @click="getResume"
                          >
                            &nbsp;&nbsp;
                            <em title="Resume" class="fa fa-book"></em
                            >&nbsp;Resume
                          </p>
                        </h4>
                      </th>
                    </thead>
                    <tbody v-if="applicantData">
                      <tr v-if="applicantData.employment_status">
                        <td class="col-md-4">
                          <strong>Employment Status </strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.employment_status }}
                        </td>
                      </tr>
                      <tr v-if="applicantData.experience">
                        <td class="col-md-4">
                          <strong>Experience</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.experience }} years
                        </td>
                      </tr>
                      <tr v-if="applicantData.relevant_experience">
                        <td class="col-md-4">
                          <strong>Relevant Experience</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.relevant_experience }} years
                        </td>
                      </tr>
                      <tr v-if="applicantData.skills">
                        <td class="col-md-4">
                          <strong>Skills</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.skills.join(", ") }}
                        </td>
                      </tr>
                      <tr v-if="applicantData.current_ctc">
                        <td class="col-md-4">
                          <strong>Current CTC</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.current_ctc }} LPA
                        </td>
                      </tr>
                      <tr v-if="applicantData.previous_ctc">
                        <td class="col-md-4">
                          <strong>Previous CTC</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.previous_ctc }} LPA
                        </td>
                      </tr>
                      <tr v-if="applicantData.expected_ctc">
                        <td class="col-md-4">
                          <strong>Expected CTC</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.expected_ctc }} LPA
                        </td>
                      </tr>
                      <tr v-if="applicantData.current_role">
                        <td class="col-md-4">
                          <strong>Current Role</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.current_role }}
                        </td>
                      </tr>
                      <tr v-if="applicantData.previous_role">
                        <td class="col-md-4">
                          <strong>Previous Role</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.previous_role }}
                        </td>
                      </tr>
                      <tr v-if="applicantData.notice_period">
                        <td class="col-md-4">
                          <strong>Notice Period</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.notice_period }} days
                        </td>
                      </tr>
                      <tr v-if="applicantData.reason_for_leaving">
                        <td class="col-md-4">
                          <strong>Reasons to look for New Opportunity</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantData.reason_for_leaving }}
                        </td>
                      </tr>
                      <tr
                        v-if="
                          applicantData.socialProfiles &&
                          applicantData.socialProfiles.linkedin
                        "
                      >
                        <td class="col-md-4">
                          <strong>LinkedIn</strong>
                        </td>
                        <td class="col-md-4">
                          <safe-link
                            v-if="
                              applicantData.socialProfiles.linkedin &&
                              isValidUrl(applicantData.socialProfiles.linkedin)
                            "
                            :link="applicantData.socialProfiles.linkedin"
                            aria-label="Open LinkedIn"
                          >
                            <i
                              class="fa fa-external-link"
                              aria-hidden="true"
                            ></i>
                          </safe-link>
                        </td>
                      </tr>
                      <tr
                        v-if="
                          applicantData.socialProfiles &&
                          applicantData.socialProfiles.github
                        "
                      >
                        <td class="col-md-4">
                          <strong>GitHub</strong>
                        </td>
                        <td class="col-md-4">
                          <safe-link
                            aria-label="Open GitHub"
                            v-if="
                              applicantData.socialProfiles.github &&
                              isValidUrl(applicantData.socialProfiles.github)
                            "
                            :link="applicantData.socialProfiles.github"
                          >
                            <i
                              class="fa fa-external-link"
                              aria-hidden="true"
                            ></i>
                          </safe-link>
                        </td>
                      </tr>
                      <template v-if="applicantData.project_links">
                        <tr v-if="applicantData.project_links.length">
                          <td class="col-md-4">
                            <strong>Project links</strong>
                          </td>
                          <td class="col-md-4">
                            {{ applicantData.project_links.join(", ") }}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <template v-if="applicantData">
                    <template v-if="applicantData.references">
                      <template
                        v-if="
                          applicantData.references.ref1.name ||
                          applicantData.references.ref2.name
                        "
                      >
                        <h5>
                          <strong>References</strong>
                        </h5>
                        <div class="table-responsive">
                          <table
                            class="table table-condensed"
                            aria-label="References"
                          >
                            <thead>
                              <th id="refName">Name</th>
                              <th id="refDesignation">Designation</th>
                              <th id="refContactNo">Contact No</th>
                            </thead>
                            <tbody>
                              <tr v-if="applicantData.references.ref1.name">
                                <td>
                                  {{ applicantData.references.ref1.name }}
                                </td>
                                <td>
                                  {{
                                    applicantData.references.ref1.designation
                                  }}
                                </td>
                                <td>
                                  {{ applicantData.references.ref1.contact_no }}
                                </td>
                              </tr>
                              <tr v-if="applicantData.references.ref2.name">
                                <td>
                                  {{ applicantData.references.ref2.name }}
                                </td>
                                <td>
                                  {{
                                    applicantData.references.ref2.designation
                                  }}
                                </td>
                                <td>
                                  {{ applicantData.references.ref2.contact_no }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </template>
                    </template>
                  </template>
                </div>
              </div>
              <div
                class="col-12 col-md-12 px-0"
                v-if="
                  applicantData.education_details &&
                  applicantData.education_details.degree
                "
              >
                <div class="well container">
                  <h4>
                    <strong>Education Details</strong>
                  </h4>
                  <div class="table-responsive">
                    <table
                      class="table table-condensed table-compressed"
                      aria-label="Education Details"
                      aria-hidden="true"
                    >
                      <tbody>
                        <tr>
                          <td class="col-md-2">
                            <strong>Institute Name</strong>
                          </td>
                          <td class="col-md-4">
                            {{
                              applicantData.education_details.institute_name ||
                              "-"
                            }}
                          </td>
                          <td class="col-md-2">
                            <strong>Overall CGPA</strong>
                          </td>
                          <td class="col-md-4">
                            {{
                              applicantData.education_details.grade_points ||
                              "-"
                            }}
                          </td>
                        </tr>

                        <tr>
                          <td class="col-md-2">
                            <strong>Degree</strong>
                          </td>
                          <td class="col-md-4">
                            {{ applicantData.education_details.degree }}
                          </td>
                          <td class="col-md-2">
                            <strong>Stream</strong>
                          </td>
                          <td class="col-md-4">
                            {{ applicantData.education_details.stream }}
                          </td>
                        </tr>

                        <tr>
                          <td class="col-md-2">
                            <strong>Start Date</strong>
                          </td>
                          <td class="col-md-4">
                            {{
                              applicantData.education_details.start_date || "-"
                            }}
                          </td>
                          <td class="col-md-2">
                            <strong>Expected End Date</strong>
                          </td>
                          <td class="col-md-4">
                            {{
                              applicantData.education_details.end_date || "-"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="col-md-2">
                            <strong>SSC Percentage</strong>
                          </td>
                          <td class="col-md-4">
                            {{ applicantData.education_details.ssc_percentage }}
                            %
                          </td>

                          <td class="col-md-2">
                            <strong>HSC Percentage</strong>
                          </td>
                          <td class="col-md-4">
                            {{ applicantData.education_details.hsc_percentage }}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td class="col-md-2">
                            <strong>Graduation Percentage</strong>
                          </td>
                          <td class="col-md-4">
                            <span
                              v-if="
                                applicantData.education_details.grad_percentage
                              "
                            >
                              {{
                                applicantData.education_details.grad_percentage
                              }}
                              %
                            </span>
                            <span v-else>N/A</span>
                          </td>
                          <td class="col-md-2">
                            <strong>Aggregate Percentage</strong>
                          </td>
                          <td class="col-md-4">
                            {{
                              applicantData.education_details
                                .aggregate_percentage
                            }}
                            %
                          </td>
                        </tr>
                        <tr>
                          <td class="col-md-2">
                            <strong>Semester 1 SPI</strong>
                          </td>
                          <td class="col-md-4">
                            {{
                              applicantData.education_details.semester1 || "N/A"
                            }}
                          </td>
                          <td class="col-md-2">
                            <strong>Semester 2 SPI</strong>
                          </td>
                          <td class="col-md-4">
                            {{
                              applicantData.education_details.semester2 || "N/A"
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="col-md-2">
                            <strong>Semester 3 SPI</strong>
                          </td>
                          <td class="col-md-4">
                            {{ applicantData.education_details.semester3 }}
                          </td>
                          <td class="col-md-2">
                            <strong>Semester 4 SPI</strong>
                          </td>
                          <td class="col-md-4">
                            {{ applicantData.education_details.semester4 }}
                          </td>
                        </tr>
                        <tr>
                          <td class="col-md-2">
                            <strong>Semester 5 SPI</strong>
                          </td>
                          <td class="col-md-4">
                            {{ applicantData.education_details.semester5 }}
                          </td>
                          <td class="col-md-2">
                            <strong>Semester 6 SPI</strong>
                          </td>
                          <td class="col-md-4">
                            {{ applicantData.education_details.semester6 }}
                          </td>
                        </tr>
                        <tr>
                          <td class="col-md-2">
                            <strong>Semester 7 SPI</strong>
                          </td>
                          <td class="col-md-4">
                            {{
                              applicantData.education_details.semester7 || "N/A"
                            }}
                          </td>
                          <td class="col-md-2">
                            <strong>Semester 8 SPI</strong>
                          </td>
                          <td class="col-md-4">
                            {{
                              applicantData.education_details.semester8 || "N/A"
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 px-0"
                v-if="
                  applicantData.jobDetails &&
                  applicantData.jobDetails.length > 0
                "
              >
                <div class="well container">
                  <h4>
                    <strong>Job Details</strong>
                  </h4>
                  <div class="accordion pb-3" id="jobAccordion">
                    <div
                      class="card"
                      v-for="(job, index) in applicantData.jobDetails"
                      :key="job.id"
                    >
                      <div class="card-header">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left"
                            type="button"
                            :aria-controls="'jobAccordioncollapse-' + index"
                            data-toggle="collapse"
                            :data-target="'#jobAccordioncollapse-' + index"
                            aria-expanded="false"
                          >
                            {{ job.title }}
                          </button>
                        </h2>
                      </div>

                      <div
                        :id="'jobAccordioncollapse-' + index"
                        class="collapse"
                        aria-labelledby="jobAccordionheading"
                        data-parent="#jobAccordion"
                      >
                        <div class="card-body">
                          <table
                            class="table table-condensed"
                            aria-label="Job Details"
                          >
                            <thead>
                              <tr>
                                <th class="col-md-4" scope="col">
                                  <strong>Title</strong>
                                </th>
                                <td>{{ job.title }}</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Description</strong>
                                </td>
                                <td>{{ job.description }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Type</strong>
                                </td>
                                <td>{{ job.type }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Company Name</strong>
                                </td>
                                <td>{{ job.companyName }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Company Website</strong>
                                </td>
                                <td>{{ job.companyWebsite }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Company Location</strong>
                                </td>
                                <td>{{ job.companyLocation }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Start Date</strong>
                                </td>
                                <td>{{ job.startDate }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>End Date</strong>
                                </td>
                                <td>{{ job.endDate }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 px-0 mt-3"
                v-if="
                  applicantData.courseDetails &&
                  applicantData.courseDetails.length > 0
                "
              >
                <div class="well container">
                  <h4>
                    <strong>Course Details</strong>
                  </h4>
                  <div class="accordion pb-3" id="courseAccordion">
                    <div
                      class="card"
                      v-for="(course, index) in applicantData.courseDetails"
                      :key="course.id"
                    >
                      <div class="card-header">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left"
                            type="button"
                            :aria-controls="'courseAccordioncollapse-' + index"
                            data-toggle="collapse"
                            :data-target="'#courseAccordioncollapse-' + index"
                            aria-expanded="false"
                          >
                            {{ course.title }}
                          </button>
                        </h2>
                      </div>

                      <div
                        :id="'courseAccordioncollapse-' + index"
                        class="collapse"
                        aria-labelledby="courseAccordionheading"
                        data-parent="#courseAccordion"
                      >
                        <div class="card-body">
                          <table
                            class="table table-condensed"
                            aria-label="Course Details"
                          >
                            <thead>
                              <tr>
                                <th class="col-md-4" scope="col">
                                  <strong>Title</strong>
                                </th>
                                <td>{{ course.title }}</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Description</strong>
                                </td>
                                <td>{{ course.description }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Certification Link</strong>
                                </td>
                                <td>
                                  <safe-link
                                    aria-label="Open certificate"
                                    v-if="
                                      course.certificationLink &&
                                      isValidUrl(course.certificationLink)
                                    "
                                    :link="course.certificationLink"
                                  >
                                    <i
                                      class="fa fa-external-link"
                                      aria-hidden="true"
                                    ></i>
                                  </safe-link>
                                  <span v-else>NA</span>
                                </td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Start Date</strong>
                                </td>
                                <td>{{ course.startDate }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>End Date</strong>
                                </td>
                                <td>{{ course.endDate }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 px-0 my-3"
                v-if="
                  applicantData.projectDetails &&
                  applicantData.projectDetails.length > 0
                "
              >
                <div class="well container">
                  <h4>
                    <strong>Project Details</strong>
                  </h4>
                  <div class="accordion pb-3" id="projectAccordion">
                    <div
                      class="card"
                      v-for="(project, index) in applicantData.projectDetails"
                      :key="project.id"
                    >
                      <div class="card-header">
                        <h2 class="mb-0">
                          <button
                            class="btn btn-link btn-block text-left"
                            type="button"
                            :aria-controls="'projectAccordioncollapse-' + index"
                            data-toggle="collapse"
                            :data-target="'#projectAccordioncollapse-' + index"
                            aria-expanded="false"
                          >
                            {{ project.title }}
                          </button>
                        </h2>
                      </div>

                      <div
                        :id="'projectAccordioncollapse-' + index"
                        class="collapse"
                        aria-labelledby="projectAccordionheading"
                        data-parent="#projectAccordion"
                      >
                        <div class="card-body">
                          <table
                            class="table table-condensed"
                            aria-label="Job Details"
                          >
                            <thead>
                              <tr>
                                <th class="col-md-4" scope="col">
                                  <strong>Title</strong>
                                </th>
                                <td>{{ project.title }}</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Description</strong>
                                </td>
                                <td>{{ project.description }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Tech Used</strong>
                                </td>
                                <td>
                                  <span
                                    class="cardSkillTag"
                                    v-for="(
                                      tech, techIndex
                                    ) in project.techUsed"
                                    :key="techIndex"
                                  >
                                    {{ tech }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Demo Link</strong>
                                </td>
                                <td>
                                  <safe-link
                                    aria-label="Open demo link"
                                    v-if="
                                      project.demoLink &&
                                      isValidUrl(project.demoLink)
                                    "
                                    :link="project.demoLink"
                                  >
                                    <i
                                      class="fa fa-external-link"
                                      aria-hidden="true"
                                    ></i>
                                  </safe-link>
                                  <span v-else>NA</span>
                                </td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>Start Date</strong>
                                </td>
                                <td>{{ project.startDate }}</td>
                              </tr>
                              <tr>
                                <td class="col-md-4">
                                  <strong>End Date</strong>
                                </td>
                                <td>{{ project.endDate }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <pdf-modal
          :key="resumeUrl"
          v-if="resumeUrl"
          modalId="view-resume"
          :url="resumeUrl"
          :title="resumeModalTitle"
          :axios="applicantAxios"
        ></pdf-modal>
      </div>
      <div v-else>
        <p class="text-danger text-center">No applicant found!</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Timeline, TimelineItem, TimelineTitle } from "vue-cute-timeline";
import "vue-cute-timeline/dist/index.css";
import moment from "moment";
import { mapGetters } from "vuex";
import commonHeader from "../../Common/_components/common-header.vue";
import PdfModal from "../../Common/_components/pdf-modal.vue";
import config from "../../../config";
import applicantAxios from "../_utils/axios";
import { isValidUrl } from "../../Common/_utils/sanitization";

export default {
  data() {
    return {
      config,
      applicantAxios,
      resumeUrl: null,
      resumeModalTitle: null,
    };
  },
  computed: {
    applicantRounds() {
      return this.applicantData.roundData;
    },
    ...mapGetters({
      applicantData: "$_applicant_master/getApplication",
    }),

    appliedAt() {
      let applicationDate = new Date(this.applicantData.createdAt);
      return moment([
        applicationDate.getFullYear(),
        applicationDate.getMonth(),
        applicationDate.getDate(),
        applicationDate.getHours(),
        applicationDate.getMinutes(),
        applicationDate.getSeconds(),
      ]).fromNow();
    },
  },
  components: {
    Timeline,
    TimelineItem,
    TimelineTitle,
    commonHeader,
    PdfModal,
  },
  watch: {
    applicantData(value) {
      this.resumeUrl = value.resume
        ? this.config.ROOT_API + "/applicant-master/get-resume/" + value.slug
        : null;
      this.resumeModalTitle = `${value.first_name} ${value.last_name}-Resume`;
    },
  },
  created() {
    const slug = this.$route.params.slug;
    this.$store.dispatch("$_applicant_master/fetchApplicationBySlug", slug);
  },
  methods: {
    getResume() {
      $("#view-resume").modal("show");
    },
    isValidUrl(url) {
      return url ? isValidUrl(url) : false;
    },

    getColor(status) {
      const dangerRegex = /Cancelled|Failed|Differed/i;
      const warningRegex = /Pending/i;
      const successRegex = /Passed/i;
      if (dangerRegex.test(status)) {
        return "bg-danger";
      } else if (warningRegex.test(status)) {
        return "bg-warning";
      } else if (successRegex.test(status)) {
        return "bg-success";
      } else {
        return "bg-secondary";
      }
    },

    formatDate(date) {
      return moment(date).format("MMM Do YYYY, h:mm a");
    },
  },
};
</script>

<style lang="css" scoped>
h4 {
  padding-top: 5px;
}

.format-date {
  font-size: 14px;
}

.well2 {
  box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 330px) {
  /* For screens narrower than 300px */
  tr > td {
    width: 100%;
    display: block;
  }
}
</style>
