import { render, staticRenderFns } from "./thread-comments.vue?vue&type=template&id=0f742d8c&scoped=true"
import script from "./thread-comments.vue?vue&type=script&lang=js"
export * from "./thread-comments.vue?vue&type=script&lang=js"
import style0 from "./thread-comments.vue?vue&type=style&index=0&id=0f742d8c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f742d8c",
  null
  
)

export default component.exports