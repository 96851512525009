import api from "../_api";
import { addSnackbarMessage } from "../../Common/_plugin/error-response";

/**
 * @memberOf module:ResolveDuplicate.store.actions
 * @param {Object} context store context
 * @param {Number} id Id to be sent to fetch matched applicants
 * @description get data of Job categories and Jobs
 */
const fetchMatchedApplicants = (context, id) => {
  api
    .fetchMatchedApplicants(id)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_MATCHED_APPLICANTS", data);
    })
    .catch((error) => {
      console.log(error);
      context.dispatch(
        "openSnackbar",
        {
          type: "danger",
          message: "Problem retrieving Matched Applicants  Data!",
        },
        { root: true }
      );
      addSnackbarMessage(error, "Problem retrieving Matched Applicants  Data!");
    });
};

/**
 * @memberOf module:ResolveDuplicate.store.actions
 * @param {Object} context store context
 * @param {Number} id Id to be sent to fetch new applicant
 * @description get data of Job categories and Jobs
 */
const fetchNewApplicantData = (context, id) => {
  api
    .fetchNewApplicantData(id)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_NEW_APPLICANT_DATA", data);
    })
    .catch((error) => {
      console.log(error);
      context.dispatch(
        "openSnackbar",
        {
          type: "danger",
          message: "Problem retrieving Applicants Data!",
        },
        { root: true }
      );
      addSnackbarMessage(error, "Problem retrieving Applicants Data!");
    });
};

/**
 * @memberOf module:ResolveDuplicate.store.actions
 * @param {Object} context store context
 * @param {Object} data Data to be sent to confirm duplicate
 * @description get data of Job categories and Jobs
 */
const confirmDuplicate = (context, data) => {
  api
    .confirmDuplicate(data)
    .then(() => {
      context.commit("CONFIRM_DUPLICATE_STATUS", "SUCCESS");
    })
    .catch((error) => {
      console.log(error);
      context.commit("CONFIRM_DUPLICATE_STATUS", "FAILURE");
      context.dispatch(
        "openSnackbar",
        {
          type: "danger",
          message: "Problem retrieving Applicants Data!",
        },
        { root: true }
      );
      addSnackbarMessage(error, "Problem retrieving Applicants Data!");
    });
};

/**
 * @memberOf module:ResolveDuplicate.store.actions
 * @param {Object} context store context
 * @param {Object} data Data to be sent to reject all duplicates
 * @description get data of Job categories and Jobs
 */
const rejectAllDuplicate = (context, data) => {
  api
    .rejectDuplicate(data)
    .then(() => {
      context.commit("CONFIRM_DUPLICATE_STATUS", "SUCCESS");
    })
    .catch((error) => {
      console.log(error);
      context.commit("CONFIRM_DUPLICATE_STATUS", "FAILURE");
      context.dispatch(
        "openSnackbar",
        {
          type: "danger",
          message: "Problem retrieving Applicants Data!",
        },
        { root: true }
      );
      addSnackbarMessage(error, "Problem retrieving Applicants Data!");
    });
};

/**
 * @memberOf module:ResolveDuplicate
 * @namespace store.actions
 * @description actions for resolve_duplicate module
 */
export default {
  fetchMatchedApplicants,
  fetchNewApplicantData,
  confirmDuplicate,
  rejectAllDuplicate,
};
