import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  loggedInApplicant: null,
  applications: [],
  linkRequests: [],
  canApplyJobs: {},
  resumeLink: null,
  application: null,
  sscMarksheetLink: null,
  hsMarksheetLink: null,
  profilePic: null,
  formSubmitStatus: {
    status: "INITIAL",
    uid: null,
  },
};

/**
 * @memberOf module:ApplicantMaster
 * @namespace store
 * @description central store for ApplicantMaster module
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
