/**
 * @memberof module:Report.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set session report list data in store
 */
const SET_SESSION_REPORT_LIST = (state, data) => {
  state.sessionReportList = data;
};

/**
 * @memberof module:Report.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set entity application count in store
 */
const SET_SESSION_APPLICATION_ENTITY_COUNT = (state, data) => {
  state.sessionApplicationEntityCount = data;
};

/**
 * @memberof module:Report.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set campus application count in store
 */
const SET_SESSION_APPLICATION_CAMPUS_COUNT = (state, data) => {
  state.sessionApplicationCampusCount = data;
};

/**
 * @memberof module:Report
 * @namespace store.mutations
 * @description mutations for mailbox module
 */
export default {
  SET_SESSION_REPORT_LIST,
  SET_SESSION_APPLICATION_ENTITY_COUNT,
  SET_SESSION_APPLICATION_CAMPUS_COUNT,
};
