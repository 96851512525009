<template>
  <div
    class="modal fade"
    id="createReportModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">
            {{ !isEdit ? "Create report" : "Edit Report" }}
          </h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group position-relative">
              <label class="col-sm-3 control-label">Title</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="roundEntity"
                  name="title"
                  v-validate="'required'"
                  data-vv-as="Title"
                  data-vv-validate-on="blur|input"
                  :class="{ invalid: errors.first('title') }"
                  v-model="data.title"
                  @blur="$v.data.title.$touch()"
                />
                <span
                  v-if="errors.first('title')"
                  class="valid-feedback alert-danger"
                  >{{ errors.first("title") }}</span
                >
              </div>
            </div>
            <div class="form-group position-relative">
              <label class="col-sm-3 control-label">Description</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="roundEntity"
                  name="description"
                  v-validate="'required'"
                  data-vv-as="Description"
                  data-vv-validate-on="blur|input"
                  :class="{ invalid: errors.first('description') }"
                  v-model="data.description"
                  @blur="$v.data.description.$touch()"
                />
                <span
                  v-if="errors.first('description')"
                  class="valid-feedback alert-danger"
                  >{{ errors.first("description") }}</span
                >
              </div>
            </div>
            <div class="form-group position-relative">
              <label class="col-sm-9 control-label">Start Date</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="data.start_date"
                  id="startDatePicker"
                  @dp-hide="datePickerStartRequired"
                  :config="startDateConfig"
                  :class="{ invalid: datePickerStartFlag || !checkStartDate }"
                ></date-picker>
                <span
                  v-if="datePickerStartFlag"
                  class="valid-feedback alert-danger"
                >
                  Start date is required
                </span>
                <span
                  class="valid-feedback alert-danger"
                  v-if="!checkStartDate"
                >
                  Start Date must be lesser than End Date
                </span>
              </div>
            </div>
            <div class="form-group position-relative">
              <label class="col-sm-9 control-label">End Date</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="data.end_date"
                  id="endDatePicker"
                  @dp-hide="datePickerEndRequired"
                  :config="endDateConfig"
                  :class="{ invalid: datePickerEndFlag }"
                ></date-picker>
                <span
                  v-if="datePickerEndFlag"
                  class="valid-feedback alert-danger"
                >
                  End date is required
                </span>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark btn-sm"
            data-dismiss="modal"
          >
            <strong>Cancel</strong>
          </button>
          <button
            :disabled="$v.data.$invalid || !checkStartDate"
            v-if="!isEdit"
            type="button"
            class="btn btn-cp ml-2"
            @click="submit"
          >
            <i class="fa fa-book fa-lg" aria-hidden="true"></i>
            Create
          </button>
          <button
            :disabled="$v.data.$invalid || !checkStartDate"
            v-else
            type="button"
            class="btn btn-cp ml-2"
            @click="update"
          >
            <i class="fa fa-book fa-lg" aria-hidden="true"></i>
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberof module:Report
 * @namespace components.create-report-modal
 * @description Add report modal component
 */
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import { required } from "vuelidate/lib/validators";
import api from "../../_api/session-report-api";
export default {
  props: {
    editReport: Object,
    updatedReport: Function,
    createdReport: Function,
    isEdit: {
      type: Boolean,
      default: false,
    },
    selectedReport: Number,
  },
  components: {
    datePicker,
  },
  data() {
    return {
      data: {
        title: null,
        description: null,
        start_date: null,
        end_date: null,
      },
      datePickerStartFlag: false,
      datePickerEndFlag: false,
      config: {
        format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
      },
    };
  },
  validations() {
    const data = {
      data: {
        title: {
          required,
        },
        description: {
          required,
        },
        start_date: {
          required,
        },
        end_date: {
          required,
        },
      },
    };
    return data;
  },
  computed: {
    startDateConfig() {
      if (!this.data.end_date) {
        return this.config;
      } else {
        return {
          ...this.config,
        };
      }
    },
    endDateConfig() {
      if (!this.data.start_date) {
        return this.config;
      } else {
        return {
          ...this.config,
        };
      }
    },
    checkStartDate() {
      if (this.data.start_date && this.data.end_date) {
        return new Date(this.data.start_date) < new Date(this.data.end_date);
      } else {
        return true;
      }
    },
  },
  methods: {
    submit() {
      api
        .createSessionReport(this.data)
        .then(() => {
          this.createdReport();
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Report has been created!" },
            { root: true }
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            { type: "danger", message: "Something went wrong!" },
            { root: true }
          );
        });
    },
    getUpdateData() {
      return {
        id: this.data.id,
        title: this.data.title,
        description: this.data.description,
        start_date: this.data.start_date,
        end_date: this.data.end_date,
      };
    },

    update() {
      api
        .updateSessionReport(this.getUpdateData())
        .then(() => {
          this.updatedReport();
          this.$store.dispatch(
            "openSnackbar",
            { type: "success", message: "Report has been updated!" },
            { root: true }
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            { type: "danger", message: "Something went wrong!" },
            { root: true }
          );
        });
    },
    datePickerStartRequired() {
      if (!this.data.start_date) {
        this.datePickerStartFlag = true;
      } else {
        this.datePickerStartFlag = false;
      }
    },
    datePickerEndRequired() {
      if (!this.data.end_date) {
        this.datePickerEndFlag = true;
      } else {
        this.datePickerEndFlag = false;
      }
    },
    resetValidations() {
      this.$v.$reset();
    },
    updateData(value) {
      this.resetValidations();
      if (value) {
        this.data = {
          ...this.editReport,
          start_date: moment(this.editReport.start_date),
          end_date: moment(this.editReport.end_date),
        };
      } else {
        this.data = {
          title: null,
          description: null,
          start_date: null,
          end_date: null,
        };
        this.datePickerStartFlag = false;
        this.datePickerEndFlag = false;
      }
    },
  },
  beforeDestroy() {
    $("#createReportModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
<style scoped>
.valid-feedback {
  top: -15px !important;
}
</style>
