/**
 * @memberOf module:EditApplicantData.store.getters
 * @description return data of aplicant
 */
const getApplicantData = (state) => state.ApplicantData;

/**
 * @memberOf module:EditApplicantData.store.getters
 * @param {Object} state holds store properties for current module
 * @description return status of applicant data edited
 */
const getFormEditedStatus = (state) => state.FormEditedStatus;

/**
 * @memberOf module:EditApplicantData.store.getters
 * @param {Object} state holds store properties for current module
 * @description returns new uploaded resume path
 */
const getNewUploadedResume = (state) => state.newResume;

export default {
  getApplicantData,
  getFormEditedStatus,
  getNewUploadedResume,
};
