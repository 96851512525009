// can edit
export const COMMON_DATA_COL_DEFS = [
  {
    headerName: "",
    children: [
      {
        headerName: "ID.",
        field: "id",
        sortable: true,
        resizable: false,
        width: 80,
        minWidth: 80,
        suppressHeaderMenuButton: true,
        pinned: "left",
        display_only: true,
        cellStyle: { textAlign: "center", padding: 0 },
      },
      {
        cellRenderer: "applicant-resolve-link",
        width: 35,
        minWidth: 35,
        maxWidth: 35,
        suppressFilter: true,
        filter: false,
        headerTooltip: "Profile Info",
        valueGetter: "node.id",
        pinned: "left",
        display_only: true,
        cellStyle: { textAlign: "center", padding: 0 },
      },
      {
        checkboxSelection: true,
        headerName: "Email",
        field: "email",
        sortable: true,
        comparator: (valueA, valueB) => {
          if (valueA && valueB) {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          }
          return 0;
        },
        width: 200,
        minWidth: 200,
        pinned: "left",
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "Mobile",
        field: "mobile_no",
        sortable: true,
        width: 200,
        minWidth: 200,
        pinned: "left",
        suppressHeaderMenuButton: true,
      },
    ],
  },
  {
    headerName: "Personal details",
    children: [
      {
        headerName: "Name",
        field: "name",
        valueGetter: function mergeLastNameFirstName(params) {
          if (params.data != undefined) {
            return params.data.first_name + " " + params.data.last_name;
          }
        },
        sortable: true,
        comparator: (valueA, valueB) => {
          if (valueA && valueB) {
            return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
          }
          return 0;
        },
        width: 200,
        minWidth: 200,
        suppressHeaderMenuButton: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      {
        headerName: "Gender",
        field: "gender",
        sortable: true,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "DOB",
        field: "dob",
        sortable: true,
        width: 150,
        minWidth: 150,
        suppressHeaderMenuButton: true,
        valueGetter: function dateFormat(params) {
          if (params.data != undefined) {
            let dt = new Date(params.data.dob);
            return dt.toLocaleDateString().toString();
          }
        },
        filter: "agDateColumnFilter",
      },
    ],
  },
  {
    headerName: "Education Details",
    children: [
      {
        headerName: "SSC %",
        field: "ssc_percentage",
        sortable: true,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "HSC %",
        field: "hsc_percentage",
        sortable: true,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "Grad %",
        field: "grad_percentage",
        sortable: true,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "aggregate %",
        field: "aggregate_percentage",
        sortable: true,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "Degree",
        field: "degree",
        sortable: true,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "Stream",
        field: "stream",
        sortable: true,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
    ],
  },
  {
    headerName: "Semester Grades",
    children: [
      {
        headerName: "SEM 1",
        field: "semester1",
        sortable: true,
        resizable: false,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "SEM 2",
        field: "semester2",
        sortable: true,
        resizable: false,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "SEM 3",
        field: "semester3",
        sortable: true,
        resizable: false,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "SEM 4",
        field: "semester4",
        sortable: true,
        resizable: false,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "SEM 5",
        field: "semester5",
        sortable: true,
        resizable: false,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "SEM 6",
        field: "semester6",
        sortable: true,
        resizable: false,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "SEM 7",
        field: "semester7",
        sortable: true,
        resizable: false,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
      {
        headerName: "SEM 8",
        field: "semester8",
        sortable: true,
        resizable: false,
        width: 100,
        minWidth: 100,
        suppressHeaderMenuButton: true,
      },
    ],
  },
];
