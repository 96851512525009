import PERMISSIONS from "../Common/permissions";
import _manageHtmlTemplate from "./index.vue";

export default {
  path: "html-templates",
  component: _manageHtmlTemplate,
  name: "htmltemplates",
  meta: {
    title: "Manage Html Templates",
    user: "employee",
    permissions: [PERMISSIONS["HTML-TEMPLATE"].MENU],
  },
};
