import axios from "axios";

/**
 * @memberof module:Report.api
 * @description - create session report data
 * @param {Object} data - session report data
 * @returns {Promise} - success or failure message
 */
const createSessionReport = (data) => {
  return axios.post("/report/session/create_report", { data });
};

/**
 * @memberof module:Report.api
 * @description - update session report data
 * @param {Object} data - report session data with id
 * @returns {Promise} - success or failure message
 */
const updateSessionReport = (data) => {
  return axios.put("/report/session/update_report", { data });
};

/**
 * @memberof module:Report.api
 * @description - update session report data
 * @param {Object} filterOptions - session report filters
 * @returns {Promise} - session report list
 */
const sessionReportList = (filterOptions) => {
  return axios.get("/report/session/report_list", {
    params: filterOptions,
  });
};

/**
 * @memberof module:Report.api
 * @description - fetches the session report applicant list
 * @param {Object} data - filters object to set the data source
 * @param {Object} filterOptions - session report filters
 * @returns {Promise} - session report list
 */
const sessionReportApplicantList = (data, params) => {
  return axios.post("/report/session/report-applicant-list", data, { params });
};

/**
 * @memberof module:Report.api
 * @description - application count belong to campus
 * @param {Object} queryOptions - session report filters query
 * @param {Object} filterOptions - session report filters data
 * @returns {Promise} - campus applicant count
 */
const sessionApplicationCampusCount = (queryOptions, filterOptions) => {
  return axios.post("/report/session/application_campus_count", filterOptions, {
    params: queryOptions,
  });
};

/**
 * @memberof module:Report.api
 * @description - application count belong to entity
 * @param {Object} queryOptions - session report filters query
 * @param {Object} filterOptions - session report filters data
 * @returns {Promise} - entity applicant count
 */
const sessionApplicationEntityCount = (queryOptions, filterOptions) => {
  return axios.post("/report/session/application_entity_count", filterOptions, {
    params: queryOptions,
  });
};

/**
 * @memberof module:Report.api
 * @description - campus recruitment list
 * @param {Object} filter - campus recruitment filters query
 * @returns {Promise} - campus list
 */
const filterCampusRecruitments = (filter) => {
  return axios.post(
    "campus-drive/recruitments/filter-data-list",
    {},
    { params: filter }
  );
};

/**
 * @memberof module:Report.api
 * @description - application count between campus createdAt dates
 * @param {Object} filter - filters query
 * @returns {Promise} - applicant data list
 */
const sessionApplicationCount = (filter) => {
  return axios.post("report/application_count", {}, { params: filter });
};

/**
 * @memberof module:Report.api
 * @description - delete session report by id
 * @param {Number} id - report id
 * @returns {Promise} - success or failure message
 */
const deleteSessionReport = (id) => {
  return axios.post("/report/session/delete_report", { id });
};

const getSessionReportData = (id) => {
  return axios.get("/report/session/report-data/" + id);
};

/**
 * @memberof module:Report.api
 * @namespace api
 * @description api for backend interactions
 */
export default {
  createSessionReport,
  updateSessionReport,
  sessionReportList,
  sessionApplicationCampusCount,
  sessionApplicationEntityCount,
  filterCampusRecruitments,
  sessionApplicationCount,
  deleteSessionReport,
  sessionReportApplicantList,
  getSessionReportData,
};
