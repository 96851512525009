var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',[_c('div',{staticClass:"container-fluid py-0"},[(_vm.loggedInUser)?_c('div',{staticClass:"position-relative d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[(_vm.$route.meta.user === 'employee')?_c('div',{staticClass:"sidebar"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"logo logo-with-menu"},[_c('router-link',{attrs:{"to":{ name: 'homepage' }}},[_c('img',{attrs:{"src":require("../../../assets/images/newLogo.png"),"alt":""}})])],1)]),_c('div',{staticClass:"d-flex align-items-center",attrs:{"id":"header_btn"}},[_c('div',{staticClass:"position-relative"},[_c('button',{staticClass:"notification_icon btn btn-basic",on:{"click":function($event){_vm.notificationModelVisibility = !_vm.notificationModelVisibility}}},[_c('img',{attrs:{"src":require("../../../assets/images/bell.png"),"alt":"notification"}}),_c('span',{staticClass:"red-circle"},[_vm._v(_vm._s(_vm.userNotification && _vm.userNotification.length > 0 ? _vm.userNotification.length : 0))])]),(_vm.userNotification && _vm.userNotification.length !== 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.notificationModelVisibility),expression:"notificationModelVisibility"}],staticClass:"notification-list-base"},_vm._l((_vm.userNotification),function(item){return _c('base-list',{key:item.id,staticClass:"media",attrs:{"slug":item.commentsDetails.applicant.slug},nativeOn:{"click":function($event){return _vm.navigateToComment(
                  item.commentsDetails.applicant.slug,
                  item.commentId
                )}},scopedSlots:_vm._u([{key:"img-element",fn:function(){return [(item.commentsDetails.profile_pic)?_c('div',{staticClass:"pull-left"},[_c('img',{staticClass:"img-responsive",attrs:{"src":!!item.commentsDetails.profile_pic
                        ? item.commentsDetails.profile_pic
                        : _vm.avatar,"alt":"image"}})]):_vm._e()]},proxy:true},{key:"text-element",fn:function(){return [_c('p',[_c('strong',[_vm._v(_vm._s(item.commentsDetails.userName))]),_vm._v(" has mentioned you in a comment. ")]),_c('span',{staticClass:"sub-text"},[_vm._v(" "+_vm._s(_vm._f("dateTimeFormat")(item.updatedAt))+" ")])]},proxy:true}],null,true)})}),1):_vm._e()]),_c('img',{staticClass:"profile_icon",attrs:{"src":_vm.profileImage,"alt":""}}),_c('button',{staticClass:"btn btn-basic sign-out-btn p-1",attrs:{"title":"Log out"},on:{"click":function($event){return _vm.logout()}}},[_c('i',{staticClass:"fa fa-2x fa-sign-out",attrs:{"aria-hidden":"true"}})])])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-basic mr-2",attrs:{"id":"menu-toggle"}},[_c('img',{attrs:{"alt":"argusoft-menu","src":require("../../../assets/images/argus-menu.png")}})])
}]

export { render, staticRenderFns }