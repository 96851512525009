import { FILTER_TYPES_MAPPING } from "./filter-types-mapping";

const getDateFilter = (filterModel) => {
  let filterObj = {
    op: FILTER_TYPES_MAPPING[filterModel.type],
  };
  // Check if inRange filter is selected
  if (filterModel.type != "inRange") {
    filterObj.val = filterModel.dateFrom;
  } else {
    filterObj.val = {
      from: filterModel.dateFrom,
      to: filterModel.dateTo,
    };
  }

  return filterObj;
};

const getNumberFilter = (filterModel) => {
  let filterObj = {
    op: FILTER_TYPES_MAPPING[filterModel.type],
  };
  // Check if inRange filter is selected
  if (filterModel.type != "inRange") {
    filterObj.val = filterModel.filter;
  } else {
    filterObj.val = {
      from: filterModel.filter,
      to: filterModel.filterTo,
    };
  }

  return filterObj;
};

/**
 *
 * @param {Object} filterModel - Filter Model Object of particular column from AgGrid Params
 * @returns {Object} Filter Object
 * @description getFilterByType - returns Formatted Filter Object for passing filter to server
 */
const getFilterByType = (filterModel) => {
  if (filterModel.filterType == "date") {
    return getDateFilter(filterModel);
  } else if (filterModel.filterType == "number") {
    return getNumberFilter(filterModel);
  }
  const filterValue = filterModel.filter ? filterModel.filter.trim() : "";
  return {
    op: FILTER_TYPES_MAPPING[filterModel.type],
    val: filterValue,
  };
};

export default getFilterByType;
