import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  applicantsDataStatus: true,
  applicationStatusCount: [],
  applicationPositionCount: [],
  applicationEmploymentStatusCount: [],
  selectedApplicants: [],
  confirmRoundScheduleStatus: "FALSE",
  campusData: [],
  filterData: localStorage.getItem("filterParameters")
    ? JSON.parse(localStorage.getItem("filterParameters"))
    : {},
  registeredApplicantStatusCount: null,
  freshProfessionalsCount: "0",
  freshGraduatesCount: "0",
  openJobPositions: [],
  shortlistedApplicantsData: [],
  filteredApplicantData: [],
  joinedApplicantsData: [],
  userMailData: {},
  attachment: "",
  userNotification: {},
  roundSchedules: [],
  followups: [],
  linkRequests: [],
  applicantMasterData: [],
};

/**
 * @memberOf module:ApplicantsData
 * @namespace store
 * @description store for Applicants data
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
