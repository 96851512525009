<template>
  <div>
    <home-header class="navbar p-0"></home-header>
    <div v-if="formData && !formSubmittedSuccess" class="form-container">
      <div class="centered-div form-title card-view">
        <div class="title-top-strip"></div>
        <div>
          <h1>{{ formData.recruitment_session_name }}</h1>
          <h4 :class="{ 'text-danger': isLastDateOver || isCompleted }">
            <div v-if="!isCompleted">
              Last Date of submission :
              {{ formData.last_date | dateFormatFilter }}
            </div>
            <div v-if="isLastDateOver || isCompleted" style="margin-top: 5px">
              Registration is closed
            </div>
          </h4>
        </div>
      </div>
      <div class="centered-div" v-if="!isLastDateOver">
        <form @submit.prevent="openConfirmationModal" id="rec_form">
          <fieldset :disabled="isLastDateOver || isCompleted">
            <legend style="display: none"></legend>
            <div class="card-view">
              <div class="">
                <label class="control-label" htmlFor="college"
                  >Select College / University
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="d-flex selection" style="margin-top: 6px">
                <select
                  class="custom-select main"
                  v-model="recruitmentData.entity_id"
                >
                  <option
                    v-for="option in formData.entitiesInvolved"
                    :value="option.entityDetail.id"
                    :key="option.entityDetail.id"
                  >
                    {{
                      option.entityDetail.entity_name +
                      " - (" +
                      option.entityDetail.entity_type +
                      ")"
                    }}
                  </option>
                </select>
                <div v-if="showSubSelection" class="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right mx-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                    />
                  </svg>
                  &thinsp;
                  <select class="custom-select" v-model="subCollege_id">
                    <option
                      v-for="option in subColleges"
                      :value="option.entityDetail.id"
                      :key="option.entityDetail.id"
                    >
                      {{
                        option.entityDetail.entity_name +
                        " - (" +
                        option.entityDetail.entity_type +
                        ")"
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="card-view">
              <div class="field-container">
                <BaseInput
                  label="Email"
                  v-model="recruitmentData.email"
                  type="text"
                  class="field"
                  placeholder="Enter email"
                  :required="true"
                  :class="{ error: $v.recruitmentData.email.$error }"
                  @blur="$v.recruitmentData.email.$touch()"
                >
                  <template slot="error" v-if="$v.recruitmentData.email.$error">
                    <p
                      v-if="!$v.recruitmentData.email.required"
                      class="validation-err"
                    >
                      Email is required
                    </p>
                    <p
                      v-if="!$v.recruitmentData.email.email"
                      class="validation-err"
                    >
                      Invalid Email
                    </p>
                  </template>
                </BaseInput>
              </div>
              <div class="field-container">
                <BaseInput
                  label="Mobile No."
                  v-model="recruitmentData.mobile_no"
                  type="text"
                  class="field"
                  placeholder="Enter Mobile No."
                  :required="true"
                  :class="{ error: $v.recruitmentData.mobile_no.$error }"
                  @blur="$v.recruitmentData.mobile_no.$touch()"
                >
                  <template
                    slot="error"
                    v-if="$v.recruitmentData.mobile_no.$error"
                  >
                    <p
                      v-if="!$v.recruitmentData.mobile_no.required"
                      class="validation-err"
                    >
                      Mobile no. is required
                    </p>
                    <p
                      v-if="!$v.recruitmentData.mobile_no.numeric"
                      class="validation-err"
                    >
                      Only digits are allowed
                    </p>
                    <p
                      v-if="
                        $v.recruitmentData.mobile_no.numeric &&
                        (!$v.recruitmentData.mobile_no.minLength ||
                          !$v.recruitmentData.mobile_no.maxLength)
                      "
                      class="validation-err"
                    >
                      Invalid mobile no.
                    </p>
                  </template>
                </BaseInput>
              </div>
            </div>
            <!-- first.last name -->
            <div class="card-view">
              <div class="row">
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="First Name"
                    v-model="recruitmentData.first_name"
                    type="text"
                    :required="true"
                    class="field"
                    placeholder="Enter First Name"
                    :class="{ error: $v.recruitmentData.first_name.$error }"
                    @blur="$v.recruitmentData.first_name.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.first_name.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.first_name.required"
                        class="validation-err"
                      >
                        First name is required
                      </p>
                      <p
                        v-if="!$v.recruitmentData.first_name.minLength"
                        class="validation-err"
                      >
                        Min 2 char. allowed
                      </p>
                      <p
                        v-if="!$v.recruitmentData.first_name.maxLength"
                        class="validation-err"
                      >
                        Max. 50 char. allowed
                      </p>
                    </template>
                  </BaseInput>
                </div>
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Middle Name"
                    v-model="recruitmentData.middle_name"
                    type="text"
                    class="field"
                    placeholder="Enter Middle Name"
                    :class="{ error: $v.recruitmentData.middle_name.$error }"
                    @blur="$v.recruitmentData.middle_name.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.middle_name.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.middle_name.minLength"
                        class="validation-err"
                      >
                        Min 2 char. allowed
                      </p>
                      <p
                        v-if="!$v.recruitmentData.middle_name.maxLength"
                        class="validation-err"
                      >
                        Max. 50 char. allowed
                      </p>
                    </template>
                  </BaseInput>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Last Name"
                    v-model="recruitmentData.last_name"
                    type="text"
                    class="field"
                    placeholder="Enter Last Name"
                    :required="true"
                    :class="{ error: $v.recruitmentData.last_name.$error }"
                    @blur="$v.recruitmentData.last_name.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.last_name.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.last_name.required"
                        class="validation-err"
                      >
                        Last name is required
                      </p>
                      <p
                        v-if="!$v.recruitmentData.last_name.minLength"
                        class="validation-err"
                      >
                        Min 2 char. allowed
                      </p>
                      <p
                        v-if="!$v.recruitmentData.last_name.maxLength"
                        class="validation-err"
                      >
                        Max. 50 char. allowed
                      </p>
                    </template>
                  </BaseInput>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 field-container">
                  <label class="control-label" htmlFor="gender"
                    >Gender
                    <span class="text-danger">*</span>
                  </label>
                  <div class="radio-options">
                    <div class="radio">
                      <label class="mr-1">
                        <input
                          class="mr-1"
                          type="radio"
                          id="Male"
                          value="Male"
                          v-model="recruitmentData.gender"
                        />Male</label
                      >
                    </div>
                    <div class="radio">
                      <label class="mr-1">
                        <input
                          class="mr-1"
                          type="radio"
                          id="Female"
                          value="Female"
                          v-model="recruitmentData.gender"
                        />Female</label
                      >
                    </div>
                    <div class="radio">
                      <label class="mr-1">
                        <input
                          class="mr-1"
                          type="radio"
                          id="Other"
                          value="Other"
                          v-model="recruitmentData.gender"
                        />Other</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 field-container">
                  <BaseDateInput
                    label="DOB"
                    v-model="recruitmentData.dob"
                    id="dobDatePicker"
                    :minDate="minDob"
                    :maxDate="maxDob"
                    :required="true"
                    class="field"
                    datePickerType="DATE"
                    placeholder="Select Date Of Birth"
                    :class="{ error: $v.recruitmentData.dob.$error }"
                    @dp-hide="$v.recruitmentData.dob.$touch()"
                  >
                    <template slot="error" v-if="$v.recruitmentData.dob.$error">
                      <p
                        v-if="!$v.recruitmentData.dob.required"
                        class="validation-err"
                      >
                        Date of birth is required
                      </p>
                    </template>
                  </BaseDateInput>
                </div>
              </div>
            </div>
            <!-- ssc/hsc -->
            <div class="card-view">
              <div class="row">
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="SSC (%)"
                    v-model="recruitmentData.ssc_percentage"
                    type="number"
                    class="field"
                    step="any"
                    placeholder="Enter SSC Percentage"
                    :required="true"
                    :class="{ error: $v.recruitmentData.ssc_percentage.$error }"
                    @blur="$v.recruitmentData.ssc_percentage.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.ssc_percentage.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.ssc_percentage.required"
                        class="validation-err"
                      >
                        SSC percentage is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.ssc_percentage.minValue ||
                          !$v.recruitmentData.ssc_percentage.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 100
                      </p>
                    </template>
                  </BaseInput>
                </div>
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="HSC (%)"
                    v-model="recruitmentData.hsc_percentage"
                    type="number"
                    class="field"
                    step="any"
                    placeholder="Enter HSC Percentage"
                    :required="true"
                    :class="{ error: $v.recruitmentData.hsc_percentage.$error }"
                    @blur="$v.recruitmentData.hsc_percentage.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.hsc_percentage.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.hsc_percentage.required"
                        class="validation-err"
                      >
                        HSC percentage is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.hsc_percentage.minValue ||
                          !$v.recruitmentData.hsc_percentage.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 100
                      </p>
                    </template>
                  </BaseInput>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Graduation (%)"
                    v-model="recruitmentData.grad_percentage"
                    type="number"
                    class="field"
                    step="any"
                    placeholder="Enter Graduation Percentage"
                    :class="{
                      error: $v.recruitmentData.grad_percentage.$error,
                    }"
                    @blur="$v.recruitmentData.grad_percentage.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.grad_percentage.$error"
                    >
                      <p
                        v-if="
                          !$v.recruitmentData.grad_percentage.minValue ||
                          !$v.recruitmentData.grad_percentage.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 100
                      </p>
                    </template>
                  </BaseInput>
                </div>
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Aggregate (SSC/HSC/Graduation) (%)"
                    v-model="recruitmentData.aggregate_percentage"
                    type="number"
                    class="field"
                    step="any"
                    :required="true"
                    placeholder="Enter Aggregate percentage of SSC/HSC/Graduation"
                    :class="{
                      error: $v.recruitmentData.aggregate_percentage.$error,
                    }"
                    @blur="$v.recruitmentData.aggregate_percentage.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.aggregate_percentage.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.aggregate_percentage.required"
                        class="validation-err"
                      >
                        aggregate percentage is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.aggregate_percentage.minValue ||
                          !$v.recruitmentData.aggregate_percentage.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 100
                      </p>
                    </template>
                  </BaseInput>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 field-container">
                  <label class="control-label" htmlFor="gender"
                    >Degree
                    <span class="text-danger">*</span>
                  </label>
                  <div class="radio-options">
                    <div class="radio">
                      <label class="mr-1">
                        <input
                          class="mr-1"
                          type="radio"
                          id="active"
                          value="BE"
                          v-model="recruitmentData.degree"
                        />B.E.</label
                      >
                    </div>
                    <div class="radio">
                      <label>
                        <input
                          class="mr-1"
                          type="radio"
                          id="block"
                          value="BTECH"
                          v-model="recruitmentData.degree"
                        />B. Tech</label
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 field-container">
                  <label class="control-label" htmlFor="stream"
                    >Stream
                    <span class="text-danger">*</span>
                  </label>
                  <div class="radio-options">
                    <div class="radio">
                      <label class="mr-1">
                        <input
                          class="mr-1"
                          type="radio"
                          id="computer_sci"
                          value="COMPUTER_SCIENCE"
                          v-model="recruitmentData.stream"
                        />Computer Science</label
                      >
                    </div>
                    <div class="radio">
                      <label>
                        <input
                          class="mr-1"
                          type="radio"
                          id="information_tech"
                          value="INFORMATION_TECHNOLOGY"
                          v-model="recruitmentData.stream"
                        />Information Technology</label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- semesters -->
            <div class="card-view">
              <div class="row">
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Semester 1 SPI (Out of 10)"
                    v-model="recruitmentData.semester1"
                    type="number"
                    step="any"
                    class="field"
                    placeholder="Enter Semester 1 SPI"
                    @blur="$v.recruitmentData.semester1.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.semester1.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.semester1.required"
                        class="validation-err"
                      >
                        semester1 is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.semester1.minValue ||
                          !$v.recruitmentData.semester1.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 10
                      </p>
                    </template>
                  </BaseInput>
                </div>
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Semester 2 SPI (Out of 10)"
                    v-model="recruitmentData.semester2"
                    type="number"
                    step="any"
                    class="field"
                    placeholder="Enter Semester 2 SPI"
                    @blur="$v.recruitmentData.semester2.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.semester2.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.semester2.required"
                        class="validation-err"
                      >
                        semester2 is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.semester2.minValue ||
                          !$v.recruitmentData.semester2.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 10
                      </p>
                    </template>
                  </BaseInput>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Semester 3 SPI (Out of 10)"
                    v-model="recruitmentData.semester3"
                    type="number"
                    step="any"
                    class="field"
                    placeholder="Enter Semester 3 SPI"
                    @blur="$v.recruitmentData.semester3.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.semester3.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.semester3.required"
                        class="validation-err"
                      >
                        semester3 is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.semester3.minValue ||
                          !$v.recruitmentData.semester3.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 10
                      </p>
                    </template>
                  </BaseInput>
                </div>
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Semester 4 SPI (Out of 10)"
                    v-model="recruitmentData.semester4"
                    type="number"
                    step="any"
                    class="field"
                    placeholder="Enter Semester 4 SPI"
                    @blur="$v.recruitmentData.semester4.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.semester4.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.semester4.required"
                        class="validation-err"
                      >
                        semester4 is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.semester4.minValue ||
                          !$v.recruitmentData.semester4.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 10
                      </p>
                    </template>
                  </BaseInput>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Semester 5 SPI (Out of 10)"
                    v-model="recruitmentData.semester5"
                    type="number"
                    class="field"
                    step="any"
                    placeholder="Enter Semester 5 SPI"
                    @blur="$v.recruitmentData.semester5.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.semester5.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.semester5.required"
                        class="validation-err"
                      >
                        semester5 is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.semester5.minValue ||
                          !$v.recruitmentData.semester5.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 10
                      </p>
                    </template>
                  </BaseInput>
                </div>
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Semester 6 SPI (Out of 10)"
                    v-model="recruitmentData.semester6"
                    type="number"
                    class="field"
                    step="any"
                    placeholder="Enter Semester 6 SPI"
                    @blur="$v.recruitmentData.semester6.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.semester6.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.semester6.required"
                        class="validation-err"
                      >
                        semester6 is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.semester6.minValue ||
                          !$v.recruitmentData.semester6.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 10
                      </p>
                    </template>
                  </BaseInput>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Semester 7 SPI (Out of 10)"
                    v-model="recruitmentData.semester7"
                    type="number"
                    class="field"
                    step="any"
                    placeholder="Enter Semester 7 SPI"
                    @blur="$v.recruitmentData.semester7.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.semester7.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.semester7.required"
                        class="validation-err"
                      >
                        semester7 is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.semester7.minValue ||
                          !$v.recruitmentData.semester7.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 10
                      </p>
                    </template>
                  </BaseInput>
                </div>
                <div class="col-sm-6 field-container">
                  <BaseInput
                    label="Semester 8 SPI (Out of 10)"
                    v-model="recruitmentData.semester8"
                    type="number"
                    class="field"
                    step="any"
                    placeholder="Enter Semester 8 SPI"
                    @blur="$v.recruitmentData.semester8.$touch()"
                  >
                    <template
                      slot="error"
                      v-if="$v.recruitmentData.semester8.$error"
                    >
                      <p
                        v-if="!$v.recruitmentData.semester8.required"
                        class="validation-err"
                      >
                        semester8 is required
                      </p>
                      <p
                        v-if="
                          !$v.recruitmentData.semester8.minValue ||
                          !$v.recruitmentData.semester8.maxValue
                        "
                        class="validation-err"
                      >
                        Value should be between 0 and 10
                      </p>
                    </template>
                  </BaseInput>
                </div>
              </div>
            </div>
            <div class="submit-btn-container">
              <button
                :disabled="
                  $v.recruitmentData.$invalid || isLastDateOver || isCompleted
                "
                type="submit"
                class="btn btn-cp ml-2 submit"
              >
                Submit
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
    <div v-if="formData && formSubmittedSuccess" class="pt-3">
      <div>
        <div class="centered-div form-error-title card-view my-0">
          <div class="title-top-strip"></div>
          <div>
            <h1>Form submitted Successfully!</h1>
            <button @click="closeSuccessModal" class="btn btn-cp ml-2">
              Submit another
            </button>
          </div>
        </div>
      </div>
    </div>
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
    <footer-section></footer-section>
  </div>
</template>

<script>
import BaseInput from "../../Common/_components/base-input";
import BaseDateInput from "../../Common/_components/base-date-input";
import api from "../../CampusRecruitment/_api/index";
import CommonModal from "../../Common/_components/modal";
import {
  defaultRecruitmentFormData,
  RECRUITMENT_FORM_DATA_VALIDATIONS,
  DateOfBirthValidations,
} from "../_utils/recruitment-form-const";
import moment from "moment";
import FooterSection from "../../Common/_components/footer-section.vue";
import HomeHeader from "../../Common/_components/home-header.vue";

export default {
  data() {
    return {
      formId: null,
      formData: null,
      isLastDateOver: null,
      isCompleted: null,
      currentDate: moment(),
      maxDob: DateOfBirthValidations.maxDob,
      minDob: DateOfBirthValidations.minDob,
      recruitmentData: defaultRecruitmentFormData(),
      subColleges: null,
      subCollege_id: null,
      formSubmittedSuccess: false,
    };
  },
  components: {
    BaseInput,
    BaseDateInput,
    CommonModal,
    FooterSection,
    HomeHeader,
  },
  computed: {
    entity_id() {
      return this.recruitmentData.entity_id;
    },
    showSubSelection() {
      const selectedEntity = this.formData.entitiesInvolved.find((entity) => {
        return entity.entityDetail.id === this.recruitmentData.entity_id;
      });
      return (
        selectedEntity &&
        selectedEntity.entityDetail.entity_type === "University"
      );
    },
  },
  watch: {
    entity_id: {
      handler(entity_id) {
        const entity = this.formData.entitiesInvolved.find((ent) => {
          return ent.entityDetail.id == entity_id;
        });
        if (entity && entity.subColleges) {
          this.subColleges = entity.subColleges;
          this.subCollege_id = entity.subColleges[0].entityDetail.id;
        } else {
          this.subColleges = null;
          this.subCollege_id = null;
        }
      },
      deep: true,
    },
  },
  created() {
    this.formId = this.$route.params.id;
    api
      .fetchCampusRecruitmentDetailsByUniqueId(this.formId, true)
      .then((response) => {
        this.formData = response.data;

        if (this._isLastDateOver()) {
          this.isLastDateOver = true;
        } else if (this.formData.status === "Completed") {
          this.isCompleted = true;
        } else {
          this.setDefaultSelectedEntity();
        }
      })
      .catch(() => {
        this.showSnackbar("danger", "Unable to fetch form data!");
      });
  },
  methods: {
    /**
     * @memberof module:campusRecruitment.components.campus-recruitment-form
     * @description Make an API call for registering student for campus recruitment
     */
    submitRecruitmentData() {
      this.$v.$touch();
      if (this.$v.recruitmentData.$invalid) {
        this.showSnackbar("danger", "Invalid Data!");
        return;
      }
      const check_entity_id = this.formData.entitiesInvolved.find((entity) => {
        return entity.entityDetail.id == this.recruitmentData.entity_id;
      });
      const studentData = {
        ...this.recruitmentData,
        entity_id:
          check_entity_id.entityDetail.entity_type === "University"
            ? this.subCollege_id
            : this.recruitmentData.entity_id,
        campus_unique_id: this.formId,
        dob: new Date(this.recruitmentData.dob),
      };
      api
        .registerStudentForCampusRecruitment(studentData)
        .then(() => {
          this.showSnackbar("success", "Successfully registerd!");
          this.resetData();
          this.formSubmittedSuccess = true;
        })
        .catch((err) => {
          this.$alert(
            err.response.data && err.response.data.message
              ? err.response.data.message
              : "Something went wrong"
          );
          this.showSnackbar(
            err.response.data && err.response.data.message
              ? err.response.data.message
              : "Something went wrong"
          );
        });
    },
    _isLastDateOver() {
      return moment(this.formData.last_date).isBefore(this.currentDate);
    },
    /**
     * @memberof module:campusRecruitment.components.campus-recruitment-form
     * @description Open confirmation modal for submitting data
     */
    openConfirmationModal() {
      this.openDialog(
        "commonModal",
        "Are you sure?",
        "Please confirm the details, you will not be able to change it after submission.",
        true,
        "",
        "",
        true
      )
        .then(() => {
          this.submitRecruitmentData();
        })
        .catch(() => {
          console.log("Confirmation cancelled!");
        });
    },

    /**
     * @memberof module:campusRecruitment.components.campus-recruitment-form
     * @description Set default selected entity as a first of list in enitity list
     */
    setDefaultSelectedEntity() {
      if (this.formData.entitiesInvolved.length == 0) return;
      this.recruitmentData.entity_id =
        this.formData.entitiesInvolved[0].entityDetail.id;
    },

    /**
     * @memberof module:campusRecruitment.components.campus-recruitment-form
     * @description Shows snackbar toast
     */
    showSnackbar(type, message) {
      this.$store.dispatch("openSnackbar", {
        type: type,
        message: message,
      });
    },

    /**
     * @memberof module:campusRecruitment.components.campus-recruitment-form
     * @description resets form values and validations
     */
    resetData() {
      this.$v.$reset();
      this.recruitmentData = defaultRecruitmentFormData();
      this.setDefaultSelectedEntity();
    },
    closeSuccessModal() {
      this.formSubmittedSuccess = false;
    },
  },
  validations() {
    return {
      recruitmentData: RECRUITMENT_FORM_DATA_VALIDATIONS,
    };
  },
  filters: {
    dateFormatFilter(date) {
      return moment(date).format(" MMMM Do YYYY, h:mm a");
    },
  },
};
</script>

<style lang="css" scoped>
.form-container {
  display: flex;
  background-color: #f4f4f4;
  flex-direction: column;
  color: #202124;
  margin-top: 60px;
}

.navbar {
  background-color: #fff;
  height: 60px;
  display: flex;
  position: fixed;
  box-shadow: 0px 3px 6px -2px rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.centered-div {
  max-width: 90vw;
  width: 780px;
  margin: auto;
}

.form-title {
  position: relative;
  margin-bottom: 12px !important;
  margin-top: 12px !important;
  padding-top: 22px !important;
}

.form-error-title {
  position: relative;
  padding-top: 22px !important;
}

.title-top-strip {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 10px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: calc(100% + 2px);
  background-color: #903564;
  color: rgba(255, 255, 255, 1);
}

.card-view {
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px 24px 14px;
  page-break-inside: avoid;
  word-wrap: break-word;
}

.validation-err {
  color: #a94442;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 0px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.submit-btn-container {
  padding: 15px 0px 30px 0px;
  text-align: right;
}

.field-container {
  margin-bottom: 18px;
}

.radio-options {
  display: flex;
  flex-direction: row;
}

.radio {
  margin: 10px 5px !important;
}

.custom-select {
  width: 250px;
}

@media screen and (max-width: 650px) {
  .selection {
    flex-wrap: wrap;
  }
  .custom-select {
    margin: 2px;
  }
}
</style>
