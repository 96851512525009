<template>
  <div class="modal fade" id="endpointModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h5 class="modal-title">Selected Endpoints</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            v-for="(group, index) in selectedEndpoints"
            :key="index"
            class="mb-3"
            style="margin-left: 10px"
          >
            <h5 class="mb-3">
              <strong>{{ group.name }}</strong>
            </h5>
            <div
              v-for="(endpoint, endpointIndex) in group.endpoints"
              :key="endpointIndex"
              class="card mb-3"
            >
              <div class="card-body d-flex justify-content-between">
                <div class="">
                  <p class="mb-2">{{ endpoint.VALUE }}</p>
                  <p class="mb-2" style="font-style: italic">
                    {{ endpoint.PATH }}
                  </p>
                </div>
                <div>
                  <span
                    style="font-size: small"
                    class="bg-secondary px-2 py-1 rounded text-white"
                  >
                    {{ endpoint.METHOD }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      endpointGroups: "$_shared_token/getEndpointObject",
    }),
  },
  props: {
    selectedEndpoints: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    showModal(endpointIds) {
      const selectedEndpoints = [];
      for (const endpointId of endpointIds) {
        for (const [groupName, group] of Object.entries(this.endpointGroups)) {
          const endpoint = Object.values(group).find(
            (e) => e.ID === endpointId
          );
          if (endpoint) {
            const existingGroupIndex = selectedEndpoints.findIndex(
              (item) => item.name === groupName
            );
            if (existingGroupIndex === -1) {
              selectedEndpoints.push({
                name: groupName,
                endpoints: [endpoint],
              });
            } else {
              selectedEndpoints[existingGroupIndex].endpoints.push(endpoint);
            }
            break;
          }
        }
      }
      this.$emit("update:selectedEndpoints", selectedEndpoints);
      $("#endpointModal").modal("show");
    },
  },
  beforeDestroy() {
    $("#endpointModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
/* Add custom styling here */

.card {
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px rgba(19, 17, 17, 0.1);
}
</style>
