<template>
  <div
    class="base-modal"
    :class="modalClasses"
    :id="id"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog"
      :class="[modalSizeClass, modalPositionClass, modalScrollable]"
      :aria-labelledby="modalIdLabel"
      :aria-hidden="true"
    >
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center"
          :class="headerClass"
          :style="{ padding: `${headerHeight}px 15px` }"
        >
          <h5 class="modal-title">{{ title }}</h5>
          <div
            class="button-container"
            style="display: flex; align-items: center"
          >
            <slot name="headerSlot"></slot>
            <button
              style="display: flex; align-items: center"
              type="button"
              v-if="toggle"
              class="close"
              @click="toggleFullScreen"
            >
              <i
                v-if="currentType != 'fullscreen'"
                class="fa fa-expand cst-size"
                aria-hidden="true"
              ></i>
              <i
                v-if="currentType === 'fullscreen'"
                class="fa fa-compress cst-size"
                aria-hidden="true"
              ></i>
            </button>
            <button
              style="display: flex; align-items: center"
              type="button"
              class="close cst-style"
              data-dismiss="modal"
              aria-label="Close"
              @click="onClose()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body" :class="modalBodyClass">
          <slot></slot>
        </div>
        <div
          v-if="$slots['footer'] || closeButton"
          class="modal-footer px-2"
          :style="{ padding: `${footerHeight}px` }"
        >
          <slot name="footer" v-if="$slots['footer']"> </slot>
          <button
            v-else-if="closeButton"
            type="button"
            class="btn btn-sm btn-secondary"
            data-dismiss="modal"
            @click="onClose()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentType: "",
    };
  },
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
      validator: (value) => ["sm", "md", "lg", "xl"].includes(value),
    },
    title: {
      type: String,
      default: "",
    },
    headerHeight: {
      type: Number,
      default: 12,
    },
    headerClass: {
      type: String,
      default: "",
    },
    modalBodyClass: {
      type: String,
      default: "",
    },
    footerHeight: {
      type: Number,
      default: 0,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "default",
      options: [
        "default",
        "center",
        "fullscreen",
        "left",
        "right",
        "fullheight",
      ],
    },
    toggle: {
      type: Boolean,
      default: false,
    },
    closeButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalSizeClass() {
      return `modal-${this.size}`;
    },
    modalIdLabel() {
      return `${this.id}-label`;
    },
    modalClasses() {
      return {
        modal: true,
        fade: true,
        full: true,
      };
    },
    modalPositionClass() {
      return [`modal-${this.currentType}`];
    },
    modalScrollable() {
      if (this.scrollable) {
        return ["modal-dialog-scrollable"];
      }
      return [""];
    },
  },
  mounted() {
    this.currentType = this.type;
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    },
    toggleFullScreen() {
      if (this.currentType != "fullscreen") {
        this.currentType = "fullscreen";
      } else {
        this.currentType = this.type;
      }
    },
  },
  beforeDestroy() {
    $(`#${this.id}`).modal("hide");
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped lang="scss">
.base-modal {
  padding-right: 0px !important;
}
.modal-center {
  height: 80vh;
  width: 80vw;
  max-width: 100vw;
  max-height: 100vh;
  margin: 10vh 10vw !important;
  .modal-content {
    height: 100%;
  }
}

.modal-right {
  height: 100vh;
  width: 500px;
  max-width: 100%;
  max-height: 100% !important;
  margin: 0px 0px 0px auto !important;
  .modal-content {
    height: 100%;
    max-height: 100% !important;
  }
}
.modal-left {
  height: 100vh;
  width: 500px;
  max-width: 100%;
  max-height: 100% !important;
  margin: 0px auto 0px 0px !important;
  .modal-content {
    height: 100%;
    max-height: 100% !important;
  }
}
.modal-fullheight {
  height: calc(100vh - 1rem);
  max-height: calc(100vh - 1rem);
  margin: 0.5rem;
  .modal-content {
    height: 100%;
    max-height: 100%;
  }
}
@media screen and (min-width: 576px) {
  .modal-fullheight {
    height: calc(100vh - 2rem);
    max-height: calc(100vh - 2rem);
    margin: 1rem auto;
  }
}

.modal-fullscreen {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0px !important;
  .modal-content {
    height: 100%;
    max-height: 100% !important;
  }
}

.cst-size {
  font-size: 14px;
}
</style>
