import { render, staticRenderFns } from "./update-moodle-instance-modal.vue?vue&type=template&id=fe138438&scoped=true"
import script from "./update-moodle-instance-modal.vue?vue&type=script&lang=js"
export * from "./update-moodle-instance-modal.vue?vue&type=script&lang=js"
import style0 from "./update-moodle-instance-modal.vue?vue&type=style&index=0&id=fe138438&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe138438",
  null
  
)

export default component.exports