import axios from "axios";
const prefix = "/file-template";

/**
 * @memberOf module:FormTemplate.api
 * @returns {Promise}
 * @description Fetch All FormTemplates
 */
const fetchAllTemplates = () => {
  return axios.get(prefix);
};

/**
 * @memberOf module:FormTemplate.api
 * @returns {Promise}
 * @description Fetch All FormTemplates
 */
const fetchTemplateById = (fileId) => {
  return axios.get(prefix + "/" + fileId);
};

const getFields = (formData) => {
  return axios.post("/file-template/getFields", formData);
};

/**
 * @memberOf module:FormTemplate.api
 * @param {Object} data
 * @returns {Promise}
 * @description inserts new FormTemplates
 */
const createTemplate = (data) => {
  return axios.post(prefix + "/create", data);
};

const updateTemplate = (data) => {
  return axios.post(prefix + "/update", data);
};

const deleteTemplate = (id) => {
  return axios.delete(prefix + `/delete/${id}`);
};
const createTestDoc = (id, data) => {
  return axios.post(
    `/create-test-file/${id}`,
    { data: { ...data } },
    { responseType: "arraybuffer" }
  );
};

export default {
  fetchAllTemplates,
  fetchTemplateById,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  createTestDoc,
  getFields,
};
