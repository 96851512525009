function setEditorMode(state, payload) {
  state.editorMode = payload;
}

function setAllFormMasters(state, payload) {
  state.forms.splice(0, state.forms.length, ...payload);
}

function setAllFormVersions(state, payload) {
  state.formVersions.splice(0, state.formVersions.length, ...payload);
}

function setFormMaster(state, payload) {
  const idx = state.forms.findIndex((f) => f.id === payload.id);
  if (idx == -1) {
    return;
  }
  state.forms.splice(idx, 1, { ...state.forms[idx], ...payload });
}

function removeFormMaster(state, id) {
  const idx = state.forms.findIndex((f) => f.id === id);
  if (idx == -1) {
    return;
  }
  state.forms.splice(idx, 1);
}

function removeFormVersion(state, id) {
  const idx = state.formVersions.findIndex((f) => f.id === id);
  if (idx == -1) {
    return;
  }
  state.formVersions.splice(idx, 1);
}

function addFormMaster(state, payload) {
  state.forms.unshift(payload);
}

function setEditorActiveFormMaster(state, payload) {
  state.editorActiveFormMaster = {
    ...state.editorActiveFormMaster,
    ...payload,
  };
  if (state.editorMode === "N") {
    state.editorActiveFormVersion = {
      masterId: payload.id,
      version: null,
      status: "N",
      date: Date(),
      form_json: {
        title: payload.title,
        description: "",
        sections: [
          {
            title: "Untitled Section",
            description: "",
            key: "5d236a22-0f49-43fb-a349-06824741d57f",
            body: [
              {
                key: "bb1d0e86-5c9f-4bbf-a24e-841187a30435",
                label: "First Name",
                type: "line",
                useValidation: false,
                properties: {
                  placeholder: "",
                },
                validators: [],
              },
            ],
          },
        ],
      },
    };
  }
}

function setEditorActiveFormVersion(state, payload) {
  state.editorActiveFormVersion = payload;
}

function setEditorFormFetchError(state, payload) {
  state.editorFormFetchError = payload;
}

function setFormSaved(state, payload) {
  state.formSaved = payload;
}

export default {
  setEditorMode,
  setAllFormMasters,
  setAllFormVersions,
  setFormMaster,
  removeFormMaster,
  removeFormVersion,
  addFormMaster,
  setEditorActiveFormMaster,
  setEditorActiveFormVersion,
  setEditorFormFetchError,
  setFormSaved,
};
