<template>
  <div
    class="text-center"
    v-if="
      params.data.hasDuplicate.length > 0 ||
      (params.data.hasServerDuplicates &&
        params.data.hasServerDuplicates.length > 0)
    "
  >
    <div class="b-button" :id="'popover-target-ag-grid' + params.data.id">
      <i
        class="fa fa-link"
        aria-hidden="true"
        style="color: red; cursor: pointer"
      ></i>
    </div>
    <b-popover
      :target="'popover-target-ag-grid' + params.data.id"
      triggers="hover"
      placement="right"
      class="popover"
    >
      <template #title>Match Duplicate Found</template>
      <div
        v-if="
          params.data.hasServerDuplicates &&
          params.data.hasServerDuplicates.length > 0
        "
      >
        <p class="m-0">Server duplicates</p>
        <ul>
          <li class="has-duplicate-ls">
            <span class="server-li-key m-1">Mobile_no </span>
            <span class="server-li-key m-1">Email </span>
          </li>
        </ul>
        <ul>
          <li
            class="has-duplicate-ls"
            v-for="(item, index) in params.data.hasServerDuplicates"
            :key="index"
          >
            <span class="bulk-li-id m-1">{{ item.mobile_no }} -</span>
            <span class="bulk-li-key m-1">{{ item.email }}</span>
          </li>
        </ul>
      </div>
      <div
        v-if="params.data.hasDuplicate && params.data.hasDuplicate.length > 0"
      >
        <p class="m-0">Local duplicates</p>
        <ul>
          <li class="has-duplicate-ls">
            <span class="bulk-li-id m-1">id </span>
            <span class="bulk-li-key m-1">key </span>
            <span class="bulk-li-type m-1">value</span>
          </li>
        </ul>
        <ul>
          <li
            class="has-duplicate-ls"
            v-for="(item, index) in params.data.hasDuplicate"
            :key="index"
          >
            <span class="bulk-li-id m-1">{{ item.to_applicant_id }} -</span>
            <span class="bulk-li-key m-1">{{ item.key }} -</span>
            <span class="bulk-li-type m-1">{{ item[item.key] }}</span>
          </li>
        </ul>
      </div>
    </b-popover>
  </div>
</template>

<script>
/**
 * @memberof module:CampusRecruitment
 * @namespace components.bulk_resolve_duplicate_link
 * @description Resolve Duplicate component
 */
import router from "../../../router/index";
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    /**
     * @memberOf module:ApplicantsData.components.resolve_duplicate_link
     * @description Resolve duplicates when matched for applicants
     */
    resolveDuplicate(id) {
      router.push({ name: "recruiterResolveDuplicate", params: { id: id } });
    },
  },
};
</script>

<style scoped>
.popover {
  min-width: min-content;
  max-width: min-content;
}
.b-button {
  background-color: transparent;
  border: none;
  margin: 0px;
  padding: 0px;
}

.b-button:hover,
.b-button:focus {
  outline: none;
}

.bulk-li-id {
  display: inline-block;
  min-width: 90px;
}

.bulk-li-key {
  display: inline-block;
  min-width: 90px;
  white-space: nowrap;
}

.server-li-key {
  display: inline-block;
  min-width: 120px;
}

.has-duplicate-ls {
  display: flex;
  align-items: center;
}
</style>
