<template>
  <div
    id="updateMoodleInstance"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    data-keyboard="false"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Edit Moodle Instance</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="reFetch"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group position-relative">
              <label class="control-label" htmlFor="Label"
                >label
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                name="label"
                class="form-control"
                v-model="data.label"
                v-validate="'required'"
                data-vv-as="Label"
                data-vv-validate-on="blur|input"
                :class="{ invalid: errors.first('label') }"
              />
              <span
                v-if="errors.first('label')"
                class="valid-feedback alert-danger"
                >{{ errors.first("label") }}</span
              >
            </div>
            <div class="form-group position-relative">
              <label class="control-label" htmlFor="url"
                >url
                <span class="text-danger">*</span>
              </label>
              <input
                id="url"
                name="url"
                type="text"
                class="form-control"
                v-model="data.url"
                v-validate="'required'"
                data-vv-as="URL"
                data-vv-validate-on="blur|input"
                :class="{ invalid: errors.first('url') }"
              />
              <span
                v-if="errors.first('url')"
                class="valid-feedback alert-danger"
                >{{ errors.first("url") }}</span
              >
            </div>
            <div class="form-group position-relative">
              <label class="control-label" htmlFor="ws_token"
                >ws_token
                <span class="text-danger">*</span>
              </label>
              <input
                id="token"
                name="token"
                type="text"
                class="form-control"
                v-model="data.ws_token"
                v-validate="'required'"
                data-vv-as="WS Token"
                data-vv-validate-on="blur|input"
                :class="{ invalid: errors.first('token') }"
              />
              <span
                v-if="errors.first('token')"
                class="valid-feedback alert-danger"
                >{{ errors.first("token") }}</span
              >
            </div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-success"
                @click="testConnection"
                :disabled="!data.label || !data.url || !data.ws_token"
              >
                Test Connection
              </button>
              <button
                type="button"
                class="btn btn-cp ml-2"
                @click="submitData"
                :disabled="!connectionOk"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="btn btn-dark"
            @click="reFetch"
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../_api/index";

export default {
  props: ["data"],
  data() {
    return {
      connectionOk: false,
      oldData: {},
    };
  },
  computed: {},
  components: {},
  mounted() {
    $("#updateMoodleInstance").on("hidden.bs.modal", () => {
      this.connectionOk = false;
    });
  },
  methods: {
    submitData() {
      api
        .updateMoodleInstance(this.data)
        .then(() => {
          this.connectionOk = false;
          $("#updateMoodleInstance").modal("hide");
          this.$emit("submitted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    testConnection() {
      api
        .testConnection(this.data)
        .then(() => {
          this.connectionOk = true;
        })
        .catch(() => {
          this.connectionOk = false;
          this.$store.dispatch(
            "openSnackbar",
            { type: "danger", message: "Connection can not be established" },
            { root: true }
          );
        });
    },
    reFetch() {
      api.getAllMoodleInstances().then((res) => {
        this.oldData = res.data;
        // Reset `data` with the fetched values from `oldData`
        for (let i of this.oldData) {
          if (i.id == this.data.id) {
            this.data.label = i.label;
            this.data.url = i.url;
            this.data.ws_token = i.ws_token;
          }
        }
      });
    },
  },
  watch: {
    "data.label"() {
      this.connectionOk = false;
    },
    "data.url"() {
      this.connectionOk = false;
    },
    "data.ws_token"() {
      this.connectionOk = false;
    },
  },
  beforeDestroy() {
    $("#updateMoodleInstance").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.m-15 {
  margin: 15px;
}
.bld {
  font-weight: bold;
}

#contanerFileUpload {
  margin: -15px;
}
</style>
