<template>
  <b-modal
    v-model="modalVisible"
    scrollable
    :title="template[0].file_name"
    @hidden="$emit('closeModal')"
    size="lg"
  >
    <div class="modal-content-wrapper">
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase"
        content-class="mt-3"
        justified
      >
        <b-tab active @click="showSingleFields">
          <template #title> Single Input fields </template>
        </b-tab>
        <b-tab @click="showMultiFields">
          <template #title> Multi input Fields </template>
        </b-tab>
      </b-tabs>
      <form>
        <div v-if="viewType === 'singleFields'">
          <div v-show="!singleInputFields.length" class="text-center">
            <h5>No input fields for this template!</h5>
          </div>
          <div v-for="(field, index) in singleInputFields" :key="index">
            <div class="form-group">
              <label :for="'inputField' + index">
                {{ field.name }}
                <span class="text-danger">*</span>
              </label>
              <select
                v-if="field.type === 'select'"
                :name="field.name"
                :id="'inputField' + index"
                class="form-control"
                v-model="data[field.name]"
              >
                <option
                  v-for="(option, optIndex) in field.options"
                  :key="optIndex"
                >
                  {{ option }}
                </option>
              </select>
              <input
                v-else
                placeholder="please enter variable value"
                :type="field.type"
                :name="field.name"
                :id="'inputField' + index"
                class="form-control"
                v-model="data[field.name]"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-show="!multiInputFields.length" class="text-center">
            <h5>No input fields for this template!</h5>
          </div>
          <div
            v-show="multiInputFields.length"
            v-for="(field, index) in multiInputFields"
            :key="index"
          >
            <div class="form-group">
              <label :for="'inputField' + index">
                {{ field.name }}
                <span class="text-danger">*</span>
              </label>
              <select
                v-if="field.type === 'select'"
                :name="field.name"
                :id="'inputField' + index"
                class="form-control"
                v-model="currentUser[field.name]"
              >
                <option
                  v-for="(option, optIndex) in field.options"
                  :key="optIndex"
                >
                  {{ option }}
                </option>
              </select>
              <input
                v-else
                placeholder="please enter variable value"
                :type="field.type"
                :name="field.name"
                :id="'inputField' + index"
                class="form-control"
                v-model="currentUser[field.name]"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
    <template #modal-footer="{}">
      <b-button size="sm" @click="$emit('closeModal')" class="btn btn-cp ml-2">
        Cancel
      </b-button>
      <b-button
        type="submit"
        size="sm"
        @click="onSubmit"
        class="btn btn-cp ml-2"
        :disabled="isDisabled()"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import api from "../api";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      modalVisible: true,
      data: {
        multiValues: [],
      },
      currentUser: {},
      viewType: "singleFields",
      editingIndex: -1,
    };
  },
  methods: {
    showSingleFields() {
      this.viewType = "singleFields";
    },
    showMultiFields() {
      this.viewType = "multiFields";
    },
    async onSubmit() {
      this.data.multiValues.push(this.currentUser);
      api.createTestDoc(this.id, this.data).then((response) => {
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
      this.$emit("closeModal");
    },
    isDisabled() {
      let singleInputFieldResult = true,
        multiInputFieldResult = true;
      singleInputFieldResult = this.singleInputFields.every(
        (field) => singleInputFieldResult && this.data[field.name]
      );
      multiInputFieldResult = this.multiInputFields.every(
        (field) => multiInputFieldResult && this.currentUser[field.name]
      );
      return !(singleInputFieldResult && multiInputFieldResult);
    },
  },

  computed: {
    template() {
      return this.$store.getters["$_file_template/getTemplates"].filter(
        (template) => {
          return template.id === this.id;
        }
      );
    },
    singleInputFields() {
      return this.template[0].required_fields.single_input_fields || [];
    },
    multiInputFields() {
      return this.template[0].required_fields.multi_input_fields || [];
    },
  },
};
</script>

<style scoped>
.btn:disabled {
  cursor: not-allowed;
}
.full-width-button {
  width: 100%;
}
.inactive {
  background: #7498b4 !important;
}

.html-content {
  width: 210mm;
  max-width: 100%;
  margin: 0 auto;
  padding: 20mm;
  box-sizing: border-box;
  overflow: hidden;
}

.active {
  background: #8f1452 !important;
}
</style>
