<template>
  <div
    class="modal fade"
    id="addScore"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Round Result</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="resetValidations($v)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group">
              <label class="col-sm-3 control-label">Round Type</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  v-model="data.round_name"
                  readonly
                />
              </div>
            </div>
            <div v-if="isAptitude">
              <div class="form-group">
                <label class="col-sm-3 control-label">Score</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.score"
                    @blur="$v.data.score.$touch()"
                  />
                  <p
                    class="alert alert-danger"
                    v-if="!$v.data.score.required && $v.data.score.$error"
                  >
                    This field is required
                  </p>
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.data.score.numeric &&
                      $v.data.score.$error &&
                      $v.data.score.required
                    "
                  >
                    Only Digits
                  </p>
                </div>
              </div>
              <div class="form-group">
                <label class="col-sm-3 control-label">Out Of</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.outOf"
                    @blur="$v.data.outOf.$touch()"
                  />
                  <p
                    class="alert alert-danger"
                    v-if="!$v.data.outOf.required && $v.data.outOf.$error"
                  >
                    This field is required
                  </p>
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.data.outOf.numeric &&
                      $v.data.outOf.$error &&
                      $v.data.outOf.required
                    "
                  >
                    Only Digits
                  </p>
                </div>
              </div>
            </div>
            <div v-else-if="isReview"></div>
            <div class="form-group" v-else>
              <label class="col-sm-3 control-label">Grade</label>
              <div class="col-sm-9">
                <select
                  class="form-control"
                  v-model="data.grade"
                  @blur="$v.data.grade.$touch()"
                >
                  <option>Excellent</option>
                  <option>Very Good</option>
                  <option>Good</option>
                  <option>Moderate</option>
                  <option>Lacking</option>
                  <option>Weak</option>
                </select>

                <p
                  class="alert alert-danger"
                  v-if="!$v.data.grade.required && $v.data.grade.$error"
                >
                  This field is required
                </p>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">Comments</label>
              <div class="col-sm-9">
                <common-rich-text-box
                  modalId="add-score-modal-summernote"
                  @onKeyUp="onKeyUp"
                  @onBlur="onBlur"
                />
                <p class="alert alert-danger" v-if="isCommentLengthNotValid">
                  Comments must not exceed 5000 characters
                </p>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="btn btn-cp"
            @click="addScore"
            data-dismiss="modal"
          >
            Generate
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import CommonRichTextBox from "../../Common/_components/common-rich-text-box.vue";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_score_modal
 * @description Add score modal component
 */
export default {
  components: {
    CommonRichTextBox,
  },
  props: ["roundData"],
  data() {
    return {
      data: {
        round_name: null,
        round_schedule: null,
        isMailed: false,
        round_result: null,
        interviewers_id: [],
        applicantIterationMasterId: this.$route.params.id,
        score: null,
        grade: null,
        comments: null,
        outOf: null,
      },
      isAptitude: false,
      isReview: false,
      isCommentLengthNotValid: false,
    };
  },
  validations: {
    data: {
      score: {
        required,
        numeric,
      },
      outOf: {
        required,
        numeric,
      },
      grade: {
        required,
      },
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantProfile.components.add_score_modal
     * @param {Object} value
     * @description Marks if the round is aptitude
     */
    roundData(value) {
      this.data = value;
      if (this.data.round_name == "Aptitude") {
        this.isAptitude = true;
      }
      if (this.data.round_name == "Review") {
        this.isReview = true;
      }
      $("#add-score-modal-summernote").summernote("code", this.data.comments);
    },
  },
  methods: {
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @param {Object} v
     * @description Resets validations on the page
     */
    resetValidations(v) {
      v.$reset();
      $("#add-score-modal-summernote").summernote("code", "");
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description set the tempData
     */
    setTempData() {
      return {
        id: this.data.id,
        round_result: this.data.round_result,
        round_name: this.data.round_name,
        grade: this.data.grade,
        score: this.data.score,
        outOf: this.data.outOf,
        comments: this.data.comments,
        applicantIterationMasterId: this.data.applicantIterationMasterId,
      };
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description Add score for a round
     */
    addScore() {
      let tempData = this.setTempData();
      switch (this.data.round_result) {
        case "Passed":
          this.$store.dispatch("$_applicant_profile/passRound", tempData);
          break;
        case "Failed":
          this.$store.dispatch("$_applicant_profile/failRound", tempData);
          break;
        case "Processed":
          this.$store.dispatch("$_applicant_profile/acceptedReview", tempData);
          break;
        case "Differed":
          this.$store.dispatch("$_applicant_profile/rejectedReview", tempData);
          break;
        default:
          break;
      }
      this.isAptitude = false;
      this.isReview = false;
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description set values of comments
     */
    onKeyUp(value) {
      this.data.comments = value;
      this.isCommentLengthValid(value);
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description set values of comments
     */
    onBlur(value) {
      this.data.comments = value;
      this.isCommentLengthValid(value);
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * checks length for the comments
     */
    isCommentLengthValid(comments) {
      this.isCommentLengthNotValid = comments.length > 5000;
    },
  },
  beforeDestroy() {
    $("#addScore").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
