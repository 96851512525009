<template>
  <router-view></router-view>
</template>

<script>
import store from "./_store";

/**
 * @module FileTemplate
 * @description manage file templates
 */
export default {
  /**
   * @memberof module:FileTemplate
   * @namespace components
   * @description components of file template module
   */
  created() {
    const STORE_KEY = "$_file_template";
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>
