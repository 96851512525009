<template>
  <div class="content">
    <common-header
      v-if="isForEditData"
      :data="[
        'home',
        'applications',
        'applicants',
        {
          key: 'singleApplicant',
          value: valueForCommonHeader,
        },
        'editApplicant',
      ]"
      :componentName="'Update'"
    />

    <div v-if="!isApplicant && !isForEditData" class="job__banner mb-4"></div>
    <div class="container container-wrapper">
      <h1 class="form_header mb-5" v-if="!isForEditData">Application Form</h1>
      <h1 class="form_header mb-5" v-if="isForEditData">Edit Applicant Form</h1>
      <div
        class="text-muted text-right pr-5 pb-3"
        v-if="activeTab === 'section4' && !isForEditData"
      >
        Note: This section is completely optional.
      </div>

      <form @submit.prevent="handleSubmit">
        <div v-if="activeTab === 'Personal'">
          <div class="col-12">
            <div class="form_page_inner">
              <h4 class="from_subheading from_subheading-2">
                Personal Details
              </h4>
              <div class="row">
                <div class="col-sm-6 col-12 mb-4 custom-padding">
                  <label class="required" htmlFor="position">Position </label>
                  <input
                    type="text"
                    key="position"
                    name="position"
                    placeholder="Enter Position"
                    v-validate="'required|alpha_spaces|min:2|max:100'"
                    :class="{ invalid: errors.first('position') }"
                    class="form-control"
                    v-model="applicantData.position"
                    :disabled="applicantData.position != null && isJobIdValid"
                  />
                  <span
                    v-if="errors.first('position')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("position") }}</span
                  >
                </div>
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="employmentStatus">
                    Current Employment Status
                  </label>
                  <div v-if="isApplicant">
                    <input
                      key="employment_status_isApplicant"
                      disabled
                      class="form-control"
                      :value="applicantData.employment_status"
                    />
                  </div>
                  <div v-else>
                    <input
                      key="employment_status"
                      v-if="checkEmploymentAccess('Fresher') && !isForEditData"
                      disabled
                      class="form-control"
                      value="Fresher"
                    />
                    <select
                      key="employment_status_select"
                      v-if="!checkEmploymentAccess('Fresher') || isForEditData"
                      class="form-control"
                      v-model="applicantData.employment_status"
                    >
                      <option
                        v-for="(emp_status, index) in employment_status"
                        :key="index"
                        @mouseover="onMouseOver()"
                      >
                        {{ emp_status }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="required" htmlFor="firstName"
                    >First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    key="first_name"
                    placeholder="Enter First Name"
                    v-validate="'required|alpha_spaces|min:2|max:50'"
                    :class="{ invalid: errors.first('first_name') }"
                    class="form-control"
                    v-model="applicantData.first_name"
                  />
                  <span
                    v-if="errors.first('first_name')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("first_name") }}</span
                  >
                </div>
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="required" htmlFor="lastName">Last Name </label>
                  <input
                    type="text"
                    name="last_name"
                    key="last_name"
                    placeholder="Enter Last Name"
                    v-validate="'required|alpha_spaces|min:2|max:50'"
                    :class="{ invalid: errors.first('last_name') }"
                    class="form-control"
                    v-model="applicantData.last_name"
                  />
                  <span
                    v-if="errors.first('last_name')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("last_name") }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="required" htmlFor="email">Email </label>
                  <input
                    type="text"
                    name="email"
                    key="email"
                    placeholder="Enter Email"
                    v-validate="'required|email|max:100'"
                    :class="{ invalid: errors.first('email') }"
                    class="form-control"
                    v-model="applicantData.email"
                  />
                  <span
                    v-if="errors.first('email')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("email") }}</span
                  >
                </div>
                <div
                  v-if="!isForEditData"
                  class="col-sm-6 col-12 custom-padding mb-4"
                >
                  <label>Profile Picture </label>
                  <div class="custom-file">
                    <input
                      id="profile_picture"
                      type="file"
                      name="profile_picture"
                      :class="{ invalid: errors.first('profile_picture') }"
                      class="form-control custom-file-input"
                      accept=".jpg, .jpeg, .png"
                      @change="handleProfilePic"
                    />
                    <label class="custom-file-label" for="profile_picture">{{
                      fileName
                    }}</label>
                  </div>
                </div>
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="required" htmlFor="mobileNumber"
                    >Mobile Number
                  </label>
                  <input
                    type="text"
                    name="mobile_number"
                    key="mobile_number"
                    placeholder="Enter Mobile Number"
                    v-validate="'required|numeric|length:10'"
                    :class="{ invalid: errors.first('mobile_number') }"
                    class="form-control"
                    v-model="applicantData.mobile_no"
                  />
                  <span
                    v-if="errors.first('mobile_number')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("mobile_number") }}</span
                  >
                </div>
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label htmlFor="alternateNumber"
                    >Alternative Mobile Number
                  </label>
                  <input
                    type="text"
                    name="alternative_mobile_number"
                    key="alternative_mobile_number"
                    placeholder="Enter Alternative Mobile Number"
                    v-validate="
                      `numeric|length:10|sameAlternateNo:${applicantData.mobile_no}`
                    "
                    :class="{
                      invalid: errors.first('alternative_mobile_number'),
                    }"
                    class="form-control"
                    v-model="applicantData.alternate_mobile_no"
                  />
                  <span
                    v-if="errors.first('alternative_mobile_number')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("alternative_mobile_number") }}</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="required" htmlFor="dob"> Date of Birth </label>
                  <div class="input-group mb-3">
                    <date-picker
                      id="dob"
                      name="date_of_birth"
                      v-model="applicantData.dob"
                      placeholder="Select Date"
                      :config="config"
                      :minDate="minDate"
                      :maxDate="maxDate"
                      :class="{
                        'invalid-border': errors.first('date_of_birth'),
                      }"
                      @dp-hide="validateDateOfBirth"
                      @onChange="validateDateOfBirth"
                    >
                    </date-picker>
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        id="basic-addon2"
                        @click="toggleDatePicker"
                      >
                        <i class="fa fa-calendar" aria-hidden="false"> </i>
                      </span>
                      <span
                        v-if="errors.first('date_of_birth')"
                        class="valid-feedback alert-danger"
                        style="top: -14px; margin-right: 35px"
                        >{{ errors.first("date_of_birth") }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="d-block required" htmlFor="gender"
                    >Gender
                  </label>
                  <div class="form-control">
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input
                          type="radio"
                          key="male"
                          class="form-check-input"
                          value="Male"
                          v-model="applicantData.gender"
                          v-validate="'required'"
                          name="gender"
                        />
                        Male
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input
                          type="radio"
                          key="female"
                          class="form-check-input"
                          value="Female"
                          v-model="applicantData.gender"
                          v-validate="'required'"
                          name="gender"
                        />
                        Female
                      </label>
                    </div>
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input
                          type="radio"
                          key="other"
                          class="form-check-input"
                          value="Other"
                          v-model="applicantData.gender"
                          v-validate="'required'"
                          name="gender"
                        />
                        Other
                      </label>
                    </div>
                  </div>
                  <span
                    v-if="errors.first('gender')"
                    class="valid-feedback no-lable alert-danger"
                    >{{ errors.first("gender") }}</span
                  >
                </div> -->
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="d-block required" for="gender">Gender</label>
                  <select
                    class="form-control"
                    v-model="applicantData.gender"
                    v-validate="'required'"
                    name="gender"
                  >
                    <option disabled value="null">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                  <span
                    v-if="errors.first('gender')"
                    class="valid-feedback alert-danger"
                  >
                    {{ errors.first("gender") }}
                  </span>
                </div>
              </div>
              <div class="required row" htmlFor="permanentAddr">
                Permanent Address
              </div>
              <div class="row">
                <div class="col-sm-6 col-12 custom-padding mb-3">
                  <label class="required" style="display: block"
                    >Address Line 1
                  </label>
                  <input
                    type="text"
                    name="address_line_1"
                    key="address_line_1"
                    placeholder="Address Line 1"
                    v-validate="'required|max:500'"
                    :class="{ invalid: errors.first('address_line_1') }"
                    class="form-control"
                    v-model="applicantData.address_line_1"
                  />
                  <span
                    v-if="errors.first('address_line_1')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("address_line_1") }}</span
                  >
                </div>
                <div class="col-sm-6 col-12 custom-padding mb-3">
                  <label>Address Line 2 </label>
                  <input
                    type="text"
                    name="address_line_2"
                    key="address_line_2"
                    placeholder="Address Line 2"
                    v-validate="'max:500'"
                    :class="{ invalid: errors.first('address_line_2') }"
                    class="form-control"
                    v-model="applicantData.address_line_2"
                  />
                  <span
                    v-if="errors.first('address_line_2')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("address_line_2") }}</span
                  >
                </div>
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="required">City </label>
                  <input
                    type="text"
                    name="city"
                    key="city"
                    placeholder="City"
                    v-validate="'required|max:50'"
                    :class="{ invalid: errors.first('city') }"
                    class="form-control"
                    v-model="applicantData.city"
                  />
                  <span
                    v-if="errors.first('city')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("city") }}</span
                  >
                </div>
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="required">State </label>
                  <div :class="{ 'invalid-border': errors.first('state') }">
                    <select
                      id="statePicker"
                      name="state"
                      key="state"
                      class="form-control selectPicker"
                      v-validate="'required'"
                      v-model="applicantData.state"
                      data-live-search="true"
                      title="Select state"
                    >
                      <option v-for="(state, index) in states" :key="index">
                        {{ state }}
                      </option>
                    </select>
                    <span
                      v-if="errors.first('state')"
                      class="valid-feedback alert-danger"
                      >{{ errors.first("state") }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="row" htmlFor="permanentAddr">
                <label class="required">Current Address</label>
                <label class="form-check-label cst-margin">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="asAbove"
                  />As Above</label
                >
              </div>
              <div class="row">
                <div class="col-sm-6 col-12 custom-padding mb-3">
                  <label class="required">Address Line 1</label>
                  <input
                    type="text"
                    name="current_address_line_1"
                    :disabled="asAbove"
                    key="current_address_line_1"
                    placeholder="Address Line 1"
                    v-validate="'required|max:500'"
                    :class="{
                      invalid: errors.first('current_address_line_1'),
                    }"
                    class="form-control"
                    v-model="applicantData.current_address_line_1"
                  />
                  <span
                    v-if="errors.first('current_address_line_1')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("current_address_line_1") }}</span
                  >
                </div>
                <div class="col-sm-6 col-12 custom-padding mb-3">
                  <label>Address Line 2</label>
                  <input
                    type="text"
                    :disabled="asAbove"
                    name="current_address_line_2"
                    key="current_address_line_2"
                    placeholder="Address Line 2"
                    v-validate="'max:500'"
                    :class="{
                      invalid: errors.first('current_address_line_2'),
                    }"
                    class="form-control"
                    v-model="applicantData.current_address_line_2"
                  />
                  <span
                    v-if="errors.first('current_address_line_2')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("current_address_line_2") }}</span
                  >
                </div>
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="required">City</label>
                  <input
                    type="text"
                    :disabled="asAbove"
                    name="current_city"
                    key="current_city"
                    placeholder="City"
                    v-validate="'required|max:50'"
                    :class="{ invalid: errors.first('current_city') }"
                    class="form-control"
                    v-model="applicantData.current_city"
                  />
                  <span
                    v-if="errors.first('current_city')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("current_city") }}</span
                  >
                </div>
                <div class="col-sm-6 col-12 custom-padding mb-4">
                  <label class="required">State</label>
                  <div
                    :class="{
                      'invalid-border': errors.first('current_state'),
                    }"
                  >
                    <select
                      id="currentStatePicker"
                      name="current_state"
                      key="current_state"
                      class="form-control selectPicker"
                      v-model="applicantData.current_state"
                      title="Select state"
                      data-live-search="true"
                      :disabled="asAbove"
                      v-validate="'required'"
                    >
                      <option v-for="(state, index) in states" :key="index">
                        {{ state }}
                      </option>
                    </select>
                    <span
                      v-if="errors.first('current_state')"
                      class="valid-feedback alert-danger"
                      >{{ errors.first("current_state") }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              class="btn btn-cp"
              type="button"
              @click="toggleForm(true), validateDateOfBirth()"
            >
              Next
            </button>
          </div>
        </div>
        <div v-if="activeTab === 'Education'">
          <div class="col-12">
            <div class="form_page_inner">
              <h4
                v-if="checkEmploymentAccess('Fresher')"
                class="from_subheading"
              >
                Education Details
              </h4>
              <div v-if="checkEmploymentAccess('Fresher')" class="row">
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="college"
                    >College or University
                  </label>
                  <input
                    type="text"
                    name="institute_name"
                    key="institute_name"
                    placeholder="ABC college"
                    v-validate="'required|min:3|max:100'"
                    :class="{ invalid: errors.first('institute_name') }"
                    class="form-control"
                    data-vv-as="Institue Name"
                    v-model="applicantData.education_details.institute_name"
                  />
                  <span
                    v-if="errors.first('institute_name')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("institute_name") }}</span
                  >
                </div>
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="degree">Degree </label>
                  <input
                    type="text"
                    name="degree"
                    key="degree"
                    placeholder="BTECH"
                    v-validate="'required|min:2|max:100'"
                    data-vv-as="Degree"
                    :class="{
                      invalid: errors.first('degree'),
                    }"
                    class="form-control"
                    v-model="applicantData.education_details.degree"
                  />
                  <span
                    v-if="errors.first('degree')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("degree") }}</span
                  >
                </div>
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="branch"
                    >Specialization / Branch
                  </label>
                  <input
                    type="text"
                    name="stream"
                    key="stream"
                    placeholder="CSE,IT,ME"
                    data-vv-as="Branch"
                    v-validate="'required|min:2|max:100'"
                    :class="{
                      invalid: errors.first('stream'),
                    }"
                    class="form-control"
                    v-model="applicantData.education_details.stream"
                  />
                  <span
                    v-if="errors.first('stream')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("stream") }}</span
                  >
                </div>
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="startDate"
                    >Start Date
                  </label>
                  <input
                    type="date"
                    name="start_date"
                    key="start_date"
                    ref="start_date"
                    v-validate="'required'"
                    :class="{
                      invalid: errors.first('start_date'),
                    }"
                    data-vv-as="Start Date"
                    class="form-control"
                    v-model="applicantData.education_details.start_date"
                  />
                  <span
                    v-if="errors.first('start_date')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("start_date") }}</span
                  >
                </div>
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="endDate">
                    Expected End Date
                  </label>
                  <input
                    type="date"
                    name="end_date"
                    key="end_date"
                    v-validate="
                      'required|after_one_years:' +
                      applicantData.education_details.start_date
                    "
                    :class="{
                      invalid: errors.first('end_date'),
                    }"
                    data-vv-as="End Date"
                    class="form-control"
                    v-model="applicantData.education_details.end_date"
                  />
                  <span
                    v-if="errors.first('end_date')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("end_date") }}</span
                  >
                </div>
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="cgpa">Overall CGPA </label>
                  <input
                    type=""
                    name="grade_points"
                    key="grade_points"
                    data-vv-as="Overall CGPA"
                    v-validate="'required|decimal:2|between:0,10'"
                    :class="{
                      invalid: errors.first('grade_points'),
                    }"
                    class="form-control"
                    v-model="applicantData.education_details.grade_points"
                  />
                  <span
                    v-if="errors.first('grade_points')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("grade_points") }}</span
                  >
                </div>
                <div class="col-12 col-sm-3 custom-padding mb-4">
                  <label class="required" htmlFor="ssc">SSC (%) </label>
                  <input
                    name="ssc_percentage"
                    key="ssc_percentage"
                    data-vv-as="SSC Percentage"
                    v-validate="'required|decimal:2|between:0,100'"
                    :class="{
                      invalid: errors.first('ssc_percentage'),
                    }"
                    class="form-control"
                    v-model="applicantData.education_details.ssc_percentage"
                  />
                  <span
                    v-if="errors.first('ssc_percentage')"
                    class="valid-feedback-sm alert-danger"
                    >{{ errors.first("ssc_percentage") }}</span
                  >
                </div>
                <div class="col-12 col-sm-3 custom-padding mb-4">
                  <label class="required" htmlFor="hsc">HSC (%) </label>
                  <input
                    data-vv-as="HSC Percentage"
                    name="hsc_percentage"
                    key="hsc_percentage"
                    v-validate="'required|decimal:2|between:0,100'"
                    :class="{
                      invalid: errors.first('hsc_percentage'),
                    }"
                    class="form-control"
                    v-model="applicantData.education_details.hsc_percentage"
                  />
                  <span
                    v-if="errors.first('hsc_percentage')"
                    class="valid-feedback-sm alert-danger"
                    >{{ errors.first("hsc_percentage") }}</span
                  >
                </div>
                <div class="col-12 col-sm-3 custom-padding mb-4">
                  <label class="required" htmlFor="graduation"
                    >Graduation (%)
                  </label>
                  <input
                    data-vv-as="Graduation Percentage"
                    name="grad_percentage"
                    key="grad_percentage"
                    v-validate="'required|decimal:2|between:0,100'"
                    :class="{
                      invalid: errors.first('grad_percentage'),
                    }"
                    class="form-control"
                    v-model="applicantData.education_details.grad_percentage"
                  />
                  <span
                    v-if="errors.first('grad_percentage')"
                    class="valid-feedback-sm alert-danger"
                    >{{ errors.first("grad_percentage") }}</span
                  >
                </div>
                <div class="col-12 col-sm-3 custom-padding mb-4">
                  <label class="required" htmlFor="aggregatePerc"
                    >Aggregate (SSC/HSC/Graduation) (%)
                  </label>
                  <input
                    type=""
                    name="aggregate_percentage"
                    key="aggregate_percentage"
                    data-vv-as="Aggregate Grades"
                    v-validate="'required|decimal:2|between:0,100'"
                    :class="{
                      invalid: errors.first('aggregate_percentage'),
                    }"
                    class="form-control"
                    v-model="
                      applicantData.education_details.aggregate_percentage
                    "
                  />
                  <span
                    v-if="errors.first('aggregate_percentage')"
                    class="valid-feedback-sm alert-danger"
                    >{{ errors.first("aggregate_percentage") }}</span
                  >
                </div>
                <div
                  class="col-12 col-sm-3 custom-padding mb-4"
                  v-for="index in 4"
                  :key="index"
                >
                  <label class="required" htmlFor="sem"
                    >Semester {{ index }} SGPA
                  </label>

                  <input
                    :name="'semester' + index"
                    :key="'semester' + index"
                    v-validate="'required|decimal:2|between:0,10'"
                    :class="{
                      invalid: errors.first('semester' + index),
                    }"
                    class="form-control"
                    data-vv-as="SGPA"
                    v-model="
                      applicantData.education_details['semester' + index]
                    "
                  />
                  <span
                    v-if="errors.first('semester' + index)"
                    class="valid-feedback-sm alert-danger"
                  >
                    {{ errors.first("semester" + index) }}
                  </span>
                </div>
                <div
                  class="col-12 col-sm-3 custom-padding mb-4"
                  v-for="index in 4"
                  :key="index + 4"
                >
                  <label htmlFor="sem4">Semester {{ index + 4 }} SGPA </label>

                  <input
                    :name="'semester' + (index + 4)"
                    :key="'semester' + (index + 4)"
                    v-validate="'decimal:2|between:0,10'"
                    :class="{
                      invalid: errors.first('semester' + (index + 4)),
                    }"
                    class="form-control"
                    data-vv-as="SGPA"
                    v-model="
                      applicantData.education_details['semester' + (index + 4)]
                    "
                  />
                  <span
                    v-if="errors.first('semester' + (index + 4))"
                    class="valid-feedback-sm alert-danger"
                  >
                    {{ errors.first("semester" + (index + 4)) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <button class="btn btn-cp" type="button" @click="toggleForm()">
              Previous
            </button>
            <button
              class="btn btn-cp"
              type="button"
              @click="toggleForm(true), validateEndDate()"
            >
              Next
            </button>
          </div>
        </div>
        <div v-if="activeTab === 'section3'">
          <div class="col-12">
            <div class="form_page_inner">
              <h4
                class="from_subheading from_subheading-5"
                v-if="!checkEmploymentAccess('Fresher')"
              >
                Current Professional Details
              </h4>
              <div class="row" v-if="!checkEmploymentAccess('Fresher')">
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="experience"
                    >Experience (yrs)
                  </label>
                  <input
                    type="text"
                    name="experience"
                    key="experience"
                    placeholder="E.g. 3"
                    v-validate="'required|min_value:1|max_value:30'"
                    :class="{ invalid: errors.first('experience') }"
                    class="form-control"
                    data-vv-as="Experience"
                    v-model="applicantData.experience"
                  />
                  <span
                    v-if="errors.first('experience')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("experience") }}</span
                  >
                </div>
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="relExperience"
                    >Relevant Experience (yrs)
                  </label>
                  <input
                    type="text"
                    name="relevant_experience"
                    key="relevant_experience"
                    placeholder="E.g. 3"
                    v-validate="'required|min_value:1|max_value:30'"
                    :class="{ invalid: errors.first('relevant_experience') }"
                    class="form-control"
                    data-vv-as="Relevant Experience"
                    v-model="applicantData.relevant_experience"
                  />
                  <span
                    v-if="errors.first('relevant_experience')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("relevant_experience") }}</span
                  >
                </div>
                <div
                  class="col-12 col-sm-6 custom-padding mb-4"
                  v-if="!checkEmploymentAccess('Other')"
                >
                  <label class="required" htmlFor="currRole"
                    >Current Role
                  </label>
                  <input
                    type="text"
                    name="current_role"
                    key="current_role"
                    placeholder="E.g. Software Engineer"
                    v-validate="'required|min:3|max:100'"
                    :class="{ invalid: errors.first('current_role') }"
                    class="form-control"
                    data-vv-as="Current Role"
                    v-model="applicantData.current_role"
                  />
                  <span
                    v-if="errors.first('current_role')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("current_role") }}</span
                  >
                </div>
                <div
                  class="col-12 col-sm-6 custom-padding mb-4"
                  v-if="
                    (!checkEmploymentAccess('Other') &&
                      this.hasAccess({
                        permissions: [this.BASE_PERMISSION.CTC],
                      }) &&
                      isForEditData) ||
                    (!checkEmploymentAccess('Other') && !isForEditData)
                  "
                >
                  <label class="required" htmlFor="currCtc"
                    >Current CTC (LPA)
                  </label>
                  <input
                    type="text"
                    name="current_ctc"
                    key="current_ctc"
                    placeholder="E.g. 10"
                    v-validate="'required|min_value:0|max_value:100|decimal:2'"
                    :class="{ invalid: errors.first('current_ctc') }"
                    class="form-control"
                    data-vv-as="Current CTC"
                    v-model="applicantData.current_ctc"
                  />
                  <span
                    v-if="errors.first('current_ctc')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("current_ctc") }}</span
                  >
                </div>
                <div
                  class="col-12 col-sm-6 custom-padding mb-4"
                  v-if="checkEmploymentAccess('Other')"
                >
                  <label class="required" htmlFor="prevRole"
                    >Previous Role
                  </label>
                  <input
                    type="text"
                    name="previous_role"
                    key="previous_role"
                    placeholder="E.g. Software Engineer"
                    v-validate="'required|min:3|max:100'"
                    :class="{ invalid: errors.first('previous_role') }"
                    class="form-control"
                    data-vv-as="Previous Role"
                    v-model="applicantData.previous_role"
                  />
                  <span
                    v-if="errors.first('previous_role')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("previous_role") }}</span
                  >
                </div>
                <div
                  class="col-12 col-sm-6 custom-padding mb-4"
                  v-if="
                    (this.hasAccess({
                      permissions: [this.BASE_PERMISSION.CTC],
                    }) &&
                      isForEditData &&
                      checkEmploymentAccess('Other')) ||
                    (!isForEditData && checkEmploymentAccess('Other'))
                  "
                >
                  <label class="required" htmlFor="prevCtc"
                    >Previous CTC (LPA)
                  </label>
                  <input
                    type="text"
                    name="previous_ctc"
                    key="previous_ctc"
                    placeholder="E.g. 10"
                    v-validate="'required|min_value:0|max_value:100'"
                    :class="{ invalid: errors.first('previous_ctc') }"
                    class="form-control"
                    data-vv-as="Previous CTC"
                    v-model="applicantData.previous_ctc"
                  />
                  <span
                    v-if="errors.first('previous_ctc')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("previous_ctc") }}</span
                  >
                </div>
                <div
                  class="col-12 col-sm-6 custom-padding mb-4"
                  v-if="
                    (this.hasAccess({
                      permissions: [this.BASE_PERMISSION.CTC],
                    }) &&
                      isForEditData) ||
                    !isForEditData
                  "
                >
                  <label class="required" htmlFor="expectedCtc"
                    >Expected CTC (LPA)
                  </label>
                  <input
                    type="text"
                    name="expected_ctc"
                    key="expected_ctc"
                    placeholder="E.g. 5"
                    v-validate="'required|min_value:2|max_value:100|decimal:2'"
                    data-vv-as="Expected CTC"
                    :class="{
                      invalid: errors.first('expected_ctc'),
                    }"
                    class="form-control"
                    v-model="applicantData.expected_ctc"
                  />
                  <span
                    v-if="errors.first('expected_ctc')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("expected_ctc") }}</span
                  >
                </div>
                <div class="col-12 col-sm-6 custom-padding mb-4">
                  <label class="required" htmlFor="leavingReason"
                    >Reason For Leaving
                  </label>
                  <input
                    type="text"
                    name="reason_for_leaving"
                    key="reason_for_leaving"
                    placeholder="E.g. better opportunities"
                    v-validate="'required|min:3|max:100'"
                    :class="{ invalid: errors.first('reason_for_leaving') }"
                    class="form-control"
                    data-vv-as="Reason For Leaving"
                    v-model="applicantData.reason_for_leaving"
                  />
                  <span
                    v-if="errors.first('reason_for_leaving')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("reason_for_leaving") }}</span
                  >
                </div>
                <div
                  class="col-12 col-sm-6 custom-padding mb-4"
                  v-if="!checkEmploymentAccess('Other')"
                >
                  <label class="required" htmlFor="noticePeriod"
                    >Notice Period (days)
                  </label>
                  <input
                    type="text"
                    name="notice_period"
                    key="notice_period"
                    placeholder="E.g. 5"
                    v-validate="'required|min_value:1|max_value:365|integer'"
                    data-vv-as="Notice Period"
                    :class="{
                      invalid: errors.first('notice_period'),
                    }"
                    class="form-control"
                    v-model="applicantData.notice_period"
                  />
                  <span
                    v-if="errors.first('notice_period')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("notice_period") }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <button class="btn btn-cp" type="button" @click="toggleForm()">
              Previous
            </button>
            <button class="btn btn-cp" type="button" @click="toggleForm(true)">
              Next
            </button>
          </div>
        </div>
        <div v-if="activeTab === 'section4'">
          <div class="form_page_inner">
            <div v-if="!checkEmploymentAccess('Working')">
              <h4 class="from_subheading from_subheading-4">Project Details</h4>
              <project-tab
                :applicantData.sync="applicantData"
                :isApplicantLoggedIn="isApplicant"
                :skillSet="skillSet"
                @toggle-checkbox="receiveEmit"
                :isForEditData="isForEditData"
              ></project-tab>
            </div>
            <div v-if="!checkEmploymentAccess('Fresher')">
              <h4 class="from_subheading from_subheading-4">Job Details</h4>
              <job-tab
                :applicantData.sync="applicantData"
                :isApplicantLoggedIn="isApplicant"
                :skillSet="skillSet"
                @toggle-checkbox="receiveEmit"
                :isForEditData="isForEditData"
              ></job-tab>
            </div>
            <div v-if="!checkEmploymentAccess('Working')">
              <h4 class="from_subheading from_subheading-4">Course Details</h4>
              <course-tab
                :applicantData.sync="applicantData"
                :isApplicantLoggedIn="isApplicant"
                :skillSet="skillSet"
                @toggle-checkbox="receiveEmit"
                :isForEditData="isForEditData"
              ></course-tab>
            </div>
            <div class="d-flex justify-content-between">
              <button class="btn btn-cp" type="button" @click="toggleForm()">
                Previous
              </button>
              <button
                class="btn btn-cp"
                type="button"
                @click="toggleForm(true)"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'section5'" class="d-flex flex-column">
          <div class="form_page_inner">
            <h4 class="from_subheading from_subheading">Resume & Skills</h4>
            <div class="row mx-0" v-if="isForEditData">
              <div class="col-12 custom-padding mb-4">
                <label class="required" htmlFor="resume">Resume </label>
                <safe-link
                  v-if="
                    applicantData.resume !== 'none' && !isChangeResumeTouched
                  "
                  :link="resumeDownloadLink"
                  >&nbsp;&nbsp;&nbsp;
                  <i aria-hidden="true" title="Resume" class="fa fa-file" />
                  Download
                </safe-link>
                <label
                  class="form-check-label ml-4"
                  v-if="applicantData.resume !== 'none'"
                >
                  <input
                    style="margin-top: 2px"
                    type="checkbox"
                    v-model="changeFile"
                  />
                  Change file
                </label>
                <span v-if="applicantData.resume == 'none'"
                  >No Resume uploaded</span
                >

                <file-upload-input
                  v-if="isChangeResumeTouched"
                  id="file"
                  name="file"
                  :containerClasses="'col-12 custom-padding mb-4'"
                  :file-size="{ size: 5120, unit: 'MB' }"
                  :file-extensions="['pdf']"
                  :required="true"
                  @fileSelected="fileChanged({ file: $event, key: 'resume' })"
                />
              </div>
            </div>

            <div class="row mx-0" v-if="isApplicant">
              <div v-if="profileResume" class="ml-3 mb-3">
                <label class="form-check-label">
                  <input
                    style="margin-top: 2px"
                    type="checkbox"
                    v-model="useProfileResumeCheckbox"
                    @change="useProfileResume($event)"
                  />
                  Use profile resume
                </label>
              </div>

              <div
                class="col-12 custom-padding mb-4"
                v-if="!useProfileResumeCheckbox"
              >
                <label class="required" htmlFor="resume">Resume </label>

                <file-upload-input
                  id="file"
                  name="file"
                  :containerClasses="'col-12 custom-padding mb-4'"
                  :file-size="{ size: 5120, unit: 'MB' }"
                  :file-extensions="['pdf']"
                  :required="true"
                  @fileSelected="fileChanged({ file: $event, key: 'resume' })"
                />
              </div>
            </div>

            <file-upload-input
              v-if="!isForEditData && !isApplicant"
              id="file"
              name="file"
              label="Resume "
              :containerClasses="'col-lg-12 col-md-12 col-sm-12'"
              :file-size="{ size: 5120, unit: 'MB' }"
              :file-extensions="['pdf']"
              :required="true"
              @fileSelected="fileChanged({ file: $event, key: 'resume' })"
            />
            <div class="row mx-0">
              <div class="col-12 custom-padding mb-4">
                <label class="required" for="skills">Skills </label>
                <multiselect
                  name="skills"
                  :searchable="false"
                  :multiple="true"
                  v-model="applicantData.skills"
                  :options="skillSet"
                  :close-on-select="false"
                  v-validate="'required'"
                  class="multi-border"
                  :class="{
                    invalid: errors.first('skills'),
                  }"
                ></multiselect>
                <span
                  v-if="errors.first('skills')"
                  class="valid-feedback alert-danger"
                >
                  {{ errors.first("skills") }}
                </span>
              </div>
              <div class="col-12 col-sm-6 custom-padding mb-4">
                <label htmlFor="linkedinLink">LinkedIn</label>
                <input
                  type="text"
                  name="linkedin"
                  key="linkedin"
                  v-validate="{ url: { require_protocol: true } }"
                  placeholder="Your LinkedIn Account Url"
                  :class="{
                    invalid: errors.first('linkedin'),
                  }"
                  class="form-control"
                  v-model="applicantData.socialProfiles.linkedin"
                />
                <span
                  v-if="errors.first('linkedin')"
                  class="valid-feedback alert-danger"
                >
                  {{ errors.first("linkedin") }}
                </span>
              </div>
              <div class="col-12 col-sm-6 custom-padding mb-4">
                <label htmlFor="githubLink">Github</label>
                <input
                  type="text"
                  name="github"
                  key="github"
                  placeholder="Your Github Account Url"
                  v-validate="{ url: { require_protocol: true } }"
                  class="form-control"
                  v-model="applicantData.socialProfiles.github"
                />
                <span
                  v-if="errors.first('github')"
                  class="valid-feedback alert-danger"
                >
                  {{ errors.first("github") }}
                </span>
              </div>
              <div class="col-12 custom-padding mb-4">
                <label htmlFor="message">Message to Hiring Team</label>
                <div>
                  <textarea
                    type="text"
                    name="feedback"
                    key="feedback"
                    v-validate="'max:250'"
                    placeholder="Please write your message here"
                    :class="{
                      invalid: errors.first('feedback'),
                    }"
                    class="form-control"
                    data-vv-as="Message"
                    v-model="applicantData.feedback"
                  ></textarea>
                  <span
                    v-if="errors.first('feedback')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("feedback") }}</span
                  >
                </div>
              </div>
            </div>
            <div
              class="references_section"
              v-if="!applicantData.campusRecruitmentId"
            >
              <div class="row mx-0">
                <div class="col-12 custom-padding mb-2">
                  <h4>References</h4>
                </div>
              </div>
              <div class="row mx-0">
                <div class="col-sm-4 col-12 custom-padding mb-3">
                  <label htmlFor="Name">Name</label>
                  <input
                    type="text"
                    name="refName"
                    class="form-control"
                    data-vv-as="name"
                    placeholder="Enter name"
                    v-validate="'max:75|alpha'"
                    v-model="applicantData.references.ref1.name"
                    :class="{
                      invalid: errors.first('refName'),
                    }"
                  />
                  <span
                    v-if="errors.first('refName')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("refName") }}</span
                  >
                </div>
                <div class="col-sm-4 col-12 custom-padding mb-3">
                  <label htmlFor="refDesignation">Designation</label>
                  <input
                    type="text"
                    name="refDesignation"
                    v-validate="'max:50|alpha'"
                    placeholder="Enter Designation"
                    class="form-control"
                    data-vv-as="designation"
                    v-model="applicantData.references.ref1.designation"
                    :disabled="!applicantData.references.ref1.name"
                    :class="{
                      invalid: errors.first('refDesignation'),
                    }"
                  />
                  <span
                    v-if="errors.first('refDesignation')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("refDesignation") }}</span
                  >
                </div>
                <div class="col-sm-4 col-12 custom-padding mb-3">
                  <label htmlFor="refContactNumber">Contact No</label>
                  <input
                    type="text"
                    name="ref1_contact_no"
                    key="ref1_contact_no"
                    placeholder="Enter Contact No."
                    v-validate="'numeric|length:10'"
                    :class="{
                      invalid: errors.first('ref1_contact_no'),
                    }"
                    class="form-control"
                    v-model="applicantData.references.ref1.contact_no"
                    :disabled="!applicantData.references.ref1.name"
                  />
                  <span
                    v-if="errors.first('ref1_contact_no')"
                    class="valid-feedback alert-danger"
                    >{{ errors.first("ref1_contact_no") }}</span
                  >
                </div>
              </div>
              <div v-show="applicantData.references.ref1.name" class="row mx-0">
                <div class="col-sm-4 col-12 custom-padding mb-4">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter name"
                    v-model="applicantData.references.ref2.name"
                  />
                </div>

                <div class="col-sm-4 col-12 custom-padding mb-4">
                  <input
                    type="text"
                    placeholder="Enter Designation"
                    class="form-control"
                    v-model="applicantData.references.ref2.designation"
                    :disabled="!applicantData.references.ref2.name"
                  />
                </div>
                <div class="col-sm-4 col-12 custom-padding mb-4">
                  <input
                    type="text"
                    name="ref2_contact_no"
                    key="ref2_contact_no"
                    placeholder="Enter Contact No."
                    v-validate="'numeric|length:10'"
                    :class="{
                      invalid: errors.first('ref2_contact_no'),
                    }"
                    class="form-control"
                    v-model="applicantData.references.ref2.contact_no"
                    :disabled="!applicantData.references.ref2.name"
                  />
                  <span
                    v-if="errors.first('ref2_contact_no')"
                    class="valid-feedback no-lable alert-danger"
                    >{{ errors.first("ref2_contact_no") }}</span
                  >
                </div>
              </div>
            </div>
            <br />
            <div
              class="row mx-0 col-12 mb-4 justify-content-center ml-0"
              v-if="!isForEditData"
            >
              <vue-recaptcha
                ref="recaptcha"
                :sitekey="sitekey"
                @verify="onVerify"
                @expired="onExpired"
              >
              </vue-recaptcha>
            </div>

            <div class="d-flex justify-content-between">
              <button class="btn btn-cp" type="button" @click="toggleForm()">
                Previous
              </button>
              <button type="submit" class="btn btn-cp">Submit</button>
            </div>
          </div>

          <router-link
            class="btn btn-outline-danger mx-auto"
            v-if="!isForEditData"
            :to="{
              name: isApplicant ? 'applicantJobProfile' : 'jobProfile',
              params: { jobId: this.$route.query.jobId },
            }"
          >
            Cancel
          </router-link>
          <router-link
            class="btn btn-outline-danger mx-auto"
            v-else
            :to="{ name: 'applicantprofile' }"
            >Cancel
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import FileUploadInput from "./file-upload-input";
import datePicker from "vue-bootstrap-datetimepicker";
import VueRecaptcha from "vue-recaptcha";
import { Validator } from "vee-validate";
import { isSameAlternateNo, beforeDate } from "../_plugin/validation";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
Validator.extend("sameAlternateNo", isSameAlternateNo);
Validator.extend("beforeDate", beforeDate);
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../permissions";
import ProjectTab from "../../ApplicantMaster/_components/tabs/project-tab.vue";
import CourseTab from "../../ApplicantMaster/_components/tabs/course-tab.vue";
import JobTab from "../../ApplicantMaster/_components/tabs/job-tab.vue";
import moment from "moment";
import Config from "../../../config";

Validator.extend("after_one_years", {
  getMessage: (field) =>
    `${field} must be at least 1 years after the Start Date.`,
  validate: (value, [startDate]) => {
    const start = moment(startDate, "YYYY-MM-DD").toDate();
    const end = moment(value, "YYYY-MM-DD").toDate();
    const minEndDate = moment(start).add(1, "years").toDate();
    return end >= minEndDate;
  },
  params: ["startDate"],
});

export default {
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isApplicant: {
      type: Boolean,
      default: false,
    },
    states: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    employment_status: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
    },
    isJobIdValid: {
      type: Boolean,
      required: true,
    },
    isForEditData: {
      type: Boolean,
      required: true,
    },
    postUserData: {
      type: Function,
    },
    profileResume: {
      type: String,
    },
    useProfileResume: {
      type: Function,
    },
  },
  data() {
    return {
      fresherTabs: ["Personal", "Education", "section4", "section5"],
      experiencedTabs: ["Personal", "section3", "section4", "section5"],
      fileName: "Choose file",
      currIdx: 0,
      activeTab: "Personal",
      applicantData: {},
      disabled: {
        from: new Date(
          new Date().getFullYear() - 18,
          new Date().getMonth(),
          new Date().getDate()
        ),
      },
      sitekey: "6Lc1jlkUAAAAAFR2xokl-fUNU9lgg3BV__2T2IbN",
      isCaptchaVerified: false,
      asAbove: false,
      isCurrentStateSelected: false,
      isStateSelected: false,
      changeFile: false,
      isChangeResumeTouched: false,
      useProfileResumeCheckbox: false,
      checkedData: {
        project: [],
        course: [],
        job: [],
      },
      config: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
      },
      datePickerFlag: false,
      resumeDownloadLink: "",
      Config,
    };
  },
  components: {
    FileUploadInput,
    // TagsInput,
    datePicker,
    VueRecaptcha,
    // CommonModal,
    Multiselect,
    "project-tab": ProjectTab,
    "course-tab": CourseTab,
    "job-tab": JobTab,
  },
  watch: {
    userData: {
      immediate: true,
      handler(newData) {
        this.applicantData = newData;
        this.asAbove = this.checkAddress();
      },
    },

    applicantData: {
      deep: true,
      handler(value) {
        if (this.asAbove) {
          if (value.address_line_1 !== value.current_address_line_1) {
            value.current_address_line_1 = value.address_line_1;
          }
          if (value.address_line_2 !== value.current_address_line_2) {
            value.current_address_line_2 = value.address_line_2;
          }
          if (value.city !== value.current_city) {
            value.current_city = value.city;
          }
          if (value.state !== value.current_state) {
            value.current_state = value.state;
            $("#currentStatePicker").selectpicker("val", value.current_state);
          }
        }
        this.resumeDownloadLink =
          this.Config.ROOT_API + "/applicants/resume/" + value.slug;
      },
    },
    "applicantData.employment_status"() {
      this.$validator.reset();
    },

    /**
     * @memberof module:Common.components.application_form
     * @param {Object} value new value
     * @description watch - validate values of Referenece One fields
     */
    "applicantData.references.ref1.name"(value) {
      if (value === "") {
        if (this.userData.references.ref2.name !== "") {
          this.userData.references.ref1.name =
            this.userData.references.ref2.name;
          this.userData.references.ref1.designation =
            this.userData.references.ref2.designation;
          this.userData.references.ref1.contact_no =
            this.userData.references.ref2.contact_no;

          this.userData.references.ref2.name = "";
          this.userData.references.ref2.designation = "";
          this.userData.references.ref2.contact_no = "";
        } else {
          this.userData.references.ref1.designation = "";
          this.userData.references.ref1.contact_no = "";
        }
      }
    },

    /**
     * @memberof module:Common.components.application_form
     * @param {Object} value new value
     * @description watch - validate values of Referenece Two fields
     */
    "applicantData.references.ref2.name"(value) {
      if (value === "") {
        this.userData.references.ref2.designation = "";
        this.userData.references.ref2.contact_no = "";
      }
    },
    /**
     *
     * @param {Object} value new value
     * @description watch - changes values of Current Address fields when asAbove is selected
     */
    asAbove(value) {
      if (value) {
        this.applicantData.current_address_line_1 =
          this.applicantData.address_line_1;
        this.applicantData.current_address_line_2 =
          this.applicantData.address_line_2;
        this.applicantData.current_city = this.applicantData.city;
        this.applicantData.current_state = this.applicantData.state;
        $("#currentStatePicker").selectpicker("val", this.applicantData.state);
      }
      $("#currentStatePicker").prop("disabled", value);
      $("#currentStatePicker").selectpicker("refresh");
    },

    changeFile(value) {
      if (value) {
        this.isChangeResumeTouched = true;
      } else {
        this.isChangeResumeTouched = false;
      }
    },

    profileResume(value) {
      if (value) {
        this.useProfileResumeCheckbox = true;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    $("#statePicker").selectpicker("render");
    $("#currentStatePicker").selectpicker("render");

    $("#statePicker").on("hidden.bs.select", () => {
      this.isStateSelected = true;
    });

    $("#currentStatePicker").on("hidden.bs.select", () => {
      this.isCurrentStateSelected = true;
    });

    if (this.isForEditData) {
      $("#statePicker").selectpicker("val", this.applicantData.state);
      $("#currentStatePicker").selectpicker(
        "val",
        this.applicantData.current_state
      );
    }

    // this.$store.dispatch("$_home/fetchJobOpeningKeys");
  },
  created() {
    this.applicantData = this.userData;
    if (this.applicantData.resume == "none") this.isChangeResumeTouched = true;
    this.useProfileResumeCheckbox = this.profileResume ? true : false;
  },
  computed: {
    ...mapGetters({
      skillSet: "system_config/jobOpeningSkills",
    }),
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    minDate() {
      return moment().subtract(80, "years").toDate();
    },
    maxDate() {
      return moment().subtract(18, "years").toDate();
    },
    valueForCommonHeader() {
      const firstName = this.userData.first_name || "";
      const lastName = this.userData.last_name || "";
      const fullName = `${firstName} ${lastName}`.trim();
      return fullName || "NA";
    },
  },
  methods: {
    async toggleForm(isNext = false) {
      const proceed = isNext ? await this.validate() : 1;
      if (proceed) {
        const newIdx = isNext ? this.currIdx + 1 : this.currIdx - 1;
        this.activeTab = this.checkEmploymentAccess("Fresher")
          ? this.fresherTabs[newIdx]
          : this.experiencedTabs[newIdx];
        this.currIdx = newIdx;
        this.scrollWindowToForm();
      }
    },

    checkEmploymentAccess(status) {
      return this.applicantData.employment_status === status;
    },

    fileChanged({ key, file }) {
      this.applicantData[key] = file;
    },

    receiveEmit(event, type) {
      this.checkedData[type] = event;
    },

    scrollWindowToForm() {
      window.scrollTo(0, 0);
      if (!this.isApplicant) {
        window.scrollBy({
          top: 500,
          behavior: "smooth",
        });
      }
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    async validate() {
      await this.$validator.validateAll();
      if (this.$validator.errors.items.length > 0) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Please enter valid values!",
        });
        return 0;
      } else return 1;
    },

    handleSubmit() {
      // Check for edit permissions
      if (this.isEditNotAllowed()) {
        return;
      }

      // Validate form data
      this.$validator.validateAll().then(() => {
        // Remove errors based on employment status
        this.removeErrorsBasedOnEmploymentStatus();

        // Check for validation errors
        if (this.$validator.errors.items.length === 0) {
          // Update details for applicants
          if (this.isApplicant) {
            this.applicantData.projectDetails = this.checkedData.project;
            this.applicantData.courseDetails = this.checkedData.course;
            this.applicantData.jobDetails = this.checkedData.job;
          }

          // Post user data if not editing
          if (!this.isForEditData) {
            if (this.isCaptchaVerified) {
              this.postUserData(this.applicantData);
            } else {
              this.$store.dispatch("openSnackbar", {
                type: "danger",
                message: "Please verify captcha!",
              });
            }
          } else {
            this.postUserData(this.applicantData);
          }
        } else {
          // Display error message for invalid values
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Please enter valid values!",
          });
        }
      });
    },

    isEditNotAllowed() {
      return (
        this.isForEditData &&
        !this.hasAccess({ permissions: [this.BASE_PERMISSION.UPDATE] })
      );
    },

    removeErrorsBasedOnEmploymentStatus() {
      const status = this.applicantData.employment_status;
      if (["Freelancer", "Working"].includes(status)) {
        this.removeErrors(["previous_CTC", "previous_role"]);
      } else if (status === "Other") {
        this.removeErrors(["current_CTC", "notice_period", "current_role"]);
      }
    },

    removeErrors(fields) {
      fields.forEach((field) => this.$validator.errors.remove(field));
    },

    /**
     * @memberof module:Common.components.application_form
     * @param {Object} response response of google recaptcha v2 when it is varified
     * @description method - handles validation of google recaptcha v2 when it is varified
     */
    onVerify() {
      this.isCaptchaVerified = true;
    },

    /**
     *
     * @description method - handles validation of google recaptcha v2 when it is expired
     */
    onExpired() {
      this.isCaptchaVerified = false;
    },

    handleProfilePic(event) {
      const file = event.target.files[0];
      this.fileName = file.name;
      const validExtensions = ["image/jpeg", "image/jpg", "image/png"];
      if (file && !validExtensions.includes(file.type)) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Please select valid profile picture!",
        });
        event.target.value = "";
        this.fileName = "Choose file";
        return;
      }
      this.fileChanged({ file, key: "profile_picture" });
    },

    // to verify the address on load (specifically for edit option) to check the checkbox
    checkAddress() {
      return (
        this.applicantData.current_address_line_1 ===
          this.applicantData.address_line_1 &&
        this.applicantData.current_address_line_2 ===
          this.applicantData.address_line_2 &&
        this.applicantData.current_city === this.applicantData.city &&
        this.applicantData.current_state === this.applicantData.state &&
        this.applicantData.current_address_line_1 != null
      );
    },
    toggleDatePicker() {
      $("#dob").data("DateTimePicker").toggle();
    },
    validateDateOfBirth() {
      const applicantDob = moment(this.applicantData.dob, "YYYY-MM-DD", true);
      const minDate = moment().subtract(18, "years");
      const maxDate = moment().subtract(80, "years");

      this.$validator.errors.remove("date_of_birth");

      if (!this.applicantData.dob) {
        this.$validator.errors.add({
          field: "date_of_birth",
          msg: "Date of Birth is required.",
        });
      } else if (!applicantDob.isValid()) {
        this.$validator.errors.add({
          field: "date_of_birth",
          msg: "Invalid date format. Please use YYYY-MM-DD.",
        });
      } else if (applicantDob.isAfter(minDate)) {
        this.$validator.errors.add({
          field: "date_of_birth",
          msg: "Date of birth must be at least 18 years ago.",
        });
      } else if (applicantDob.isBefore(maxDate)) {
        this.$validator.errors.add({
          field: "date_of_birth",
          msg: "Date of birth must be within the past 80 years.",
        });
      }
    },

    async validateEndDate() {
      await this.$validator.validate(
        `end_date`,
        this.applicantData.education_details.end_date,
        {
          startDate: this.applicantData.education_details.start_date,
        }
      );
    },
  },
};
</script>

<style scoped>
.cardSkillTag {
  background-color: #f7f8fa;
  color: #566474;
  font-size: 1rem;
  border-radius: 20px;
  padding: 5px 12px;
  margin-right: 10px;
  white-space: nowrap;
  margin-bottom: 10px;
}
.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.3s;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.job__banner {
  background-image: url(../../../assets/images/home_banner.jpg);
  height: 400px;
  margin-top: 70px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}
.form_page_wrapper {
  width: 1140px;
  padding-bottom: 40px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 25px rgba(0, 0, 0, 0.1);
}

.mt-50 {
  margin-top: -50px;
}

.applicant {
  top: 25px;
  position: relative;
  margin-bottom: 50px;
}

@media screen and (max-width: 1366px) {
  .form_page_wrapper {
    width: 80%;
  }
}
@media screen and (max-width: 767px) {
  .form_page_wrapper {
    width: 90%;
  }
}
.form_page_wrapper .form_header {
  font-size: 26px;
  text-align: center;
  font-weight: 600;
  padding-top: 15px;
}
.form_page_wrapper .form_page_inner {
  padding: 10px 40px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
}

@media (max-width: 576px) {
  .form_page_wrapper .form_page_inner {
    padding: 10px 20px;
  }
}
.form_page_wrapper .form_page_inner .from_subheading {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  position: relative;
  margin-bottom: 24px;
}
.form_page_wrapper .form_page_inner .from_subheading:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 14px;
  width: calc(100% - 180px);
  height: 1px;
  background: #dbdbdb;
}
.form_page_wrapper .form_page_inner .from_subheading-2:after {
  content: "";
  width: calc(100% - 147px);
}
.from_subheading-3:after {
  content: "";
  width: calc(100% - 210px) !important;
}
.from_subheading-4:after {
  content: "";
  width: calc(100% - 135px) !important;
}
.from_subheading-5:after {
  content: "";
  width: calc(100% - 250px) !important;
}
.form_page_wrapper .file-input__input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.form_page_wrapper .file-input__label {
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  height: 38px;
  width: 140px;
  text-align: center;
  background-color: #686868;
}
.form_page_wrapper .file-input__label i {
  font-size: 22px;
  padding-right: 6px;
  position: relative;
  top: 3px;
}
.form_page_wrapper .star {
  color: red;
}
.form_page_wrapper label {
  font-weight: 600;
  font-size: 14px;
}
.form_page_wrapper input:focus {
  box-shadow: none;
}
.form_page_wrapper select:focus {
  box-shadow: none;
}
.form_page_wrapper textarea:focus {
  box-shadow: none;
}
.form_page_wrapper .references_section {
  background: #f5f5f5;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}
.form_page_wrapper .references_section h4 {
  font-size: 20px;
  margin-bottom: 0px;
}
.modalfont {
  font-weight: 600;
}
.nextbtnmargin {
  margin-right: 55px;
}

.prevbtnmargin {
  margin-left: 55px;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bottomslide {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes rightslide {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@media (max-width: 575.98px) {
  .custom-padding {
    padding: 0 !important; /* p-0 equivalent */
  }
}

@media (min-width: 576px) {
  .custom-padding {
    padding: 0.5rem !important; /* custom-padding equivalent */
  }
}
@keyframes spin {
  10% {
    width: 0;
    height: 0;
    border-width: 6px;
  }
  30% {
    width: 0;
    height: 0;
    border-radius: 50%;
    border-width: 1em;
    transform: rotate(0deg);
    border-color: #c7daf5;
  }
  50% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }
  70% {
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }
  90% {
    border-width: 4px;
  }
  100% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    transform: rotate(720deg);
    border-color: transparent;
  }
}
.cst-margin {
  margin-left: 1.8rem;
}
</style>
