<template>
  <div id="container" class="index-style">
    <router-view></router-view>
  </div>
</template>

<script>
import store from "./_store";
import applicantDataStore from "../Applications/_store";
import mailboxstore from "../MailBox/_store";
import applicantProfileStore from "../ApplicantProfile/_store";
import formTemplateStore from "../FormTemplate/_store";

/**
 * @module campusRecruitment
 */
export default {
  /**
   * @memberof module:campusRecruitment
   * @namespace components
   * @description components of campusRecruitment module
   */
  components: {
    // 'campus-recruitments': CampusRecruitments,
    // 'view-entity': ViewEntity
  },
  methods: {
    registerStore(storeKey, store) {
      if (!(storeKey in this.$store._modules.root._children))
        this.$store.registerModule(storeKey, store);
    },
  },
  created() {
    const STORE_KEY = "$_campus_recruitment";
    this.registerStore(STORE_KEY, store);

    const APPLICANTDATA_STORE_KEY = "$_applicants_data";
    this.registerStore(APPLICANTDATA_STORE_KEY, applicantDataStore);

    const MAILBOX_STORE_KEY = "$_mailbox";
    this.registerStore(MAILBOX_STORE_KEY, mailboxstore);

    const FORM_TEMPLATE_STORE_KEY = "$_form_template";
    this.registerStore(FORM_TEMPLATE_STORE_KEY, formTemplateStore);

    const APPLICANT_PROFILE_STORE_KEY = "$_applicant_profile";
    this.registerStore(APPLICANT_PROFILE_STORE_KEY, applicantProfileStore);
  },
};
</script>

<style>
.index-style {
  height: 100%;
}
</style>
