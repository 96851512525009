<template>
  <div
    class="modal fade scroll"
    id="addEvent"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">
            {{ editEvent ? "Update Event Details" : "Event Details" }}
          </h3>
          <button
            ref="closeButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="resetForm"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group" v-if="selectedCampus">
              <label class="col-sm-3 control-label">Title</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="roundEntity"
                  v-model="round.event_title"
                />
              </div>
            </div>
            <div class="form-group" v-if="selectedCampus">
              <label class="col-sm-3 control-label">Campus</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  id="roundEntity"
                  :value="selectedCampus.recruitment_session_name"
                  disabled
                />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-9 control-label">Type</label>
              <div class="col-sm-9">
                <select
                  class="form-control"
                  id="roundTypeSelect"
                  v-model="round.round_name"
                >
                  <option value="Written Test">Written Test</option>
                  <option value="Interview">Interview</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-9 control-label">Start time</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="round.round_schedule"
                  id="datePicker"
                  :config="config"
                  :minDate="new Date()"
                  @dp-hide="datePickerRequired"
                ></date-picker>
                <p v-if="datePickerFlag" class="alert alert-danger">
                  This field is required
                </p>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-9 control-label">End time</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="round.round_schedule_end"
                  id="datePicker"
                  :config="config"
                  :minDate="new Date()"
                  @dp-hide="datePickerToRequired"
                ></date-picker>
                <p v-if="datePickerToFlag" class="alert alert-danger">
                  This field is required
                </p>
                <p
                  v-if="round.round_schedule_end && scheduleEndInvalid"
                  class="alert alert-danger m-0 p-0"
                >
                  End date time should be greater than start date time.
                </p>
              </div>
            </div>
            <div class="form-group" v-if="selectedCampus">
              <label class="col-sm-9 control-label">Dependent Event</label>
              <div class="col-sm-9">
                <select
                  id="dependency-picker"
                  v-model="round.dependency"
                  class="form-control"
                  data-live-search="false"
                  data-width="100%"
                >
                  <option :value="null" class="text-muted">No dependecy</option>
                  <option
                    v-for="(event, index) in dependentEvents"
                    :key="index"
                    :value="event.id"
                  >
                    {{ event.round_title }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div v-if="editEvent" class="modal-footer text-center">
          <button class="btn btn-dark" @click="cancelEvent">
            <i class="fa fa-ban fa-lg" aria-hidden="true"></i>
            <strong>Cancel</strong>
          </button>
          <button class="btn btn-dark" @click="markCompleted">
            <i class="fa fa-check-square-o fa-lg" aria-hidden="true"></i>
            <strong>Mark completed</strong>
          </button>
          <button
            :disabled="!scheduleDateValid(editEvent.round_schedule)"
            type="button"
            class="btn btn-cp ml-2"
            @click="updateRound"
          >
            Update
          </button>
        </div>
        <div v-else class="modal-footer text-center">
          <button
            :disabled="$v.round.$invalid"
            type="button"
            class="btn btn-cp ml-2"
            @click="addEvent"
          >
            Generate
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import api from "../_api/index";
import moment from "moment";

/**
 * @memberof module:CampusRecruitment
 * @namespace components.schedule_event_modal
 * @description Schedule round modal component
 */
export default {
  props: {
    editEvent: Object,
    calendarDateTime: Object,
    inPlaceAction: Boolean,
  },
  data() {
    return {
      isCoordinator: true,
      isStudent: false,
      isInterviewerTouched: false,
      isReviewerTouched: false,
      dependentRounds: [
        { title: "Received", value: "Received" },
        { title: "Written Test Passed", value: "Written Test Passed" },
        { title: "HR Round Passed", value: "HR Round Passed" },
        { title: "VP Round Passed", value: "VP Round Passed" },
        // { title: "CEO Round Passed", value: "CEO Round Passed" },
      ],
      round: {
        dependency: null,
        round_name: "Written Test",
        event_title: "",
        round_schedule: null,
        time_range: true,
        round_schedule_end: null,
        is_mailed: false,
        round_result: "Pending",
        interviewers_id: [],
        reviewers_id: [],
      },
      interviewerChecker: false,
      reviewerChecker: false,
      datePickerFlag: false,
      datePickerToFlag: false,
      config: {
        format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
      },
    };
  },
  validations() {
    const round = {
      round_schedule: {
        required,
      },
      event_title: {
        required,
      },
      round_schedule_end: {
        required,
      },
    };
    return {
      round,
    };
  },
  computed: {
    ...mapGetters({
      activeCampuses: "$_campus_recruitment/getActiveCampuses",
      selectedCampus: "$_campus_recruitment/getSelectedCampus",
      usersDetails: "$_applicant_profile/fetchUsersDetails",
    }),
    scheduleEndInvalid() {
      const startDate = this.round.round_schedule;
      const endDate = this.round.round_schedule_end;
      const startDateHour = moment(new Date(startDate)).hour();
      const startDateMinute = moment(new Date(startDate)).minute();
      const changedEndDate = moment(new Date(this.round.round_schedule_end));
      changedEndDate.set({ h: startDateHour, m: startDateMinute });
      return !moment(new Date(endDate).toISOString()).isAfter(
        new Date(changedEndDate).toISOString(),
        "minute"
      );
    },
    submitDisabled() {
      return (
        this.scheduleEndInvalid &&
        !this.round.round_schedule &&
        !this.round_schedule_end
      );
    },
    dependentEvents() {
      if (this.selectedCampus) {
        if (this.editEvent) {
          return this.selectedCampus.linkedEventDetails.filter((event) => {
            return event.status != "Cancelled" && event.id != this.editEvent.id;
          });
        } else {
          return this.selectedCampus.linkedEventDetails.filter((event) => {
            return event.status != "Cancelled";
          });
        }
      }
      return [];
    },
  },
  components: {
    datePicker,
  },
  methods: {
    getUsers() {
      this.$store.dispatch("$_applicant_profile/usersDetails");
    },
    scheduleDateValid(date) {
      return moment(date).isAfter(new Date());
    },
    /**
     * @memberof module:CampusRecruitment.components.schedule_event_modal
     * @description cancel selected event
     */
    cancelEvent() {
      let mydata = {
        id: this.editEvent.id,
        round_name: this.editEvent.round_name,
        round_schedule: this.editEvent.round_schedule,
        dependency: this.editEvent.dependency,
        round_schedule_end: new Date(this.round.round_schedule_end),
        is_mailed: this.editEvent.is_mailed,
        campusId: this.editEvent.campusId,
        status: "Cancelled",
      };
      api
        .putRoundSchedules(mydata)
        .then(() => {
          this.$refs.closeButton.click();
          this.$emit("refreshEvents");
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Event Cancelled successfully!",
            },
            { root: true }
          );
          this.$emit("closeEventModal");
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem updating data!",
            },
            { root: true }
          );
        });
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule_event_modal
     * @description complete selected event
     */

    markCompleted() {
      let mydata = {
        id: this.editEvent.id,
        round_name: this.editEvent.round_name,
        round_schedule: this.editEvent.round_schedule,
        dependency: this.editEvent.dependency,
        round_schedule_end: new Date(this.round.round_schedule_end),
        is_mailed: this.editEvent.is_mailed,
        campusId: this.editEvent.campusId,
        status: "Completed",
      };
      api
        .putRoundSchedules(mydata)
        .then(() => {
          this.$refs.closeButton.click();
          this.$emit("refreshEvents");
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Event marked as completed!",
            },
            { root: true }
          );
          this.$emit("closeEventModal");
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem updating data!",
            },
            { root: true }
          );
        });
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule_event_modal
     * @description Validation for date picker
     */
    datePickerRequired() {
      if (!this.round.round_schedule) {
        this.datePickerFlag = true;
      } else {
        this.datePickerFlag = false;
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule_event_modal
     * @description Validation for date picker
     */
    datePickerToRequired() {
      if (!this.round.round_schedule_end) {
        this.datePickerToFlag = true;
      } else {
        this.datePickerToFlag = false;
      }
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule_event_modal
     * @description Builds an object which is used in adding round for campus
     */
    generateUpdateRound() {
      let mydata = {
        id: this.round.id,
        round_title: this.round.event_title,
        dependency: this.round.dependency ? [this.round.dependency] : [],
        round_name: this.round.round_name,
        round_schedule: new Date(this.round.round_schedule),
        round_schedule_end: new Date(this.round.round_schedule_end),
        time_range: this.round.time_range,
        is_mailed: this.round.is_mailed,
        campusId: this.selectedCampus.id,
        status: this.round.status,
      };
      return mydata;
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule_event_modal
     * @description Builds an object which is used in adding round for entity
     */
    generateRound(is_mailed) {
      let mydata = {
        dependency: this.round.dependency ? [this.round.dependency] : [],
        round_title: this.round.event_title,
        round_name: this.round.round_name,
        round_schedule: new Date(this.round.round_schedule),
        round_schedule_end: new Date(this.round.round_schedule_end),
        time_range: this.round.time_range,
        is_mailed: is_mailed ? is_mailed : false,
        campusId: this.selectedCampus.id,
        status: "Pending",
      };
      return mydata;
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule_event_modal
     * @description update selected event
     */
    updateRound() {
      const roundData = this.generateUpdateRound();
      api
        .putRoundSchedules(roundData)
        .then(() => {
          this.$refs.closeButton.click();
          this.$emit("refreshEvents");
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Event updated successfully!",
            },
            { root: true }
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem updating data!",
            },
            { root: true }
          );
        });
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule_event_modal
     * @description Add round for entity
     */
    addEvent() {
      const roundData = this.generateRound();
      api
        .postRoundSchedules(roundData)
        .then(() => {
          this.$refs.closeButton.click();
          this.$emit("refreshEvents");
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "success",
              message: "Event added successfully!",
            },
            { root: true }
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: "Problem sending data!",
            },
            { root: true }
          );
        });
    },
    resetForm() {
      this.round = {
        dependency: null,
        round_name: "Written Test",
        round_title: "",
        event_title: "",
        is_mailed: false,
        round_result: "Pending",
        interviewers_id: [],
        reviewers_id: [],
      };
      // Optionally, reset form validation if using Vuelidate
      this.$v.$reset();
    },

    /**
     * @memberof module:CampusRecruitment.components.schedule_event_modal
     * @description get all coordinator contacts
     */
    selectedCampusContacts() {
      let contactList = [];
      this.selectedCampus.entitiesInvolved.forEach((campus) => {
        campus.entityDetail.contactPersonDetails.forEach((contact) => {
          contactList.push({
            ...contact,
            entity_name: campus.entityDetail.entity_name,
            entity_type: campus.entityDetail.entity_type,
          });
        });
      });
      return contactList;
    },
  },
  watch: {
    calendarDateTime(value) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      if (value.start) {
        let theDate = new Date(value.start);
        let formatedDate = "";
        formatedDate += theDate.getDate() + " ";
        formatedDate += months[theDate.getMonth()] + " ";
        formatedDate += theDate.getFullYear() + " ";
        if (theDate.getHours() == 0 && theDate.getMinutes() == 0) {
          formatedDate += "10:00";
        } else {
          formatedDate += `${theDate.getHours()}:${theDate.getMinutes()}`;
        }
        this.round.round_schedule = formatedDate;
      }
      if (value.end) {
        let currentDateObj = new Date(value.end);
        let numberOfMlSeconds = currentDateObj.getTime();
        let addMlSeconds = 86400000;
        let theDate = new Date(numberOfMlSeconds - addMlSeconds);
        let formatedDate = "";
        formatedDate += theDate.getDate() + " ";
        formatedDate += months[theDate.getMonth()] + " ";
        formatedDate += theDate.getFullYear() + " ";
        if (theDate.getHours() == 0 && theDate.getMinutes() == 0) {
          formatedDate += "18:00";
        } else {
          formatedDate += `${theDate.getHours()}:${theDate.getMinutes()}`;
        }
        this.round.round_schedule_end = formatedDate;
      }
    },

    editEvent(value) {
      if (value) {
        this.round = {
          dependency: value.dependency[0] ? value.dependency[0] : null,
          id: value.id,
          event_title: value.round_title,
          round_name: value.round_name,
          round_schedule: moment(value.round_schedule).format(
            "DD MMMM YYYY HH:mm"
          ),
          round_schedule_end: moment(value.round_schedule_end).format(
            "DD MMMM YYYY HH:mm"
          ),
          is_mailed: value.is_mailed,
          round_result: value.round_name,
          status: value.status,
          campusId: value.campusId,
        };
      } else {
        this.round = {
          event_title: "",
          dependency: null,
          round_name: "Written Test",
          round_schedule: this.round.round_schedule,
          round_schedule_end: this.round.round_schedule_end,
          is_mailed: false,
          round_result: "Pending",
        };
      }
    },
  },

  created() {
    this.getUsers();
  },

  mounted() {
    $("#datePicker").on("dp.show", function (e) {
      $("#datePicker").data("DateTimePicker").minDate(e.date);
    });
  },
  beforeDestroy() {
    $("#addEvent").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
