import sessionApi from "./session-report-api";
import candidateApi from "./candidate-report-api";
import applicantApi from "./applicant-report-api";
/**
 * @memberof module:Report.api
 * @namespace api
 * @description api for backend interactions
 */
export default {
  ...sessionApi,
  ...candidateApi,
  ...applicantApi,
};
