<template>
  <div class="content container-fluid page-bg nopadding">
    <div class="registerbox">
      <div
        class="p-2"
        style="display: flex; flex-direction: column; min-height: 100%"
      >
        <div>
          <h3 class="header pt-1 mb-0">Applicant Registration</h3>
          <div class="logo-div">
            <img
              class="logoSize"
              src="../../../assets/images/newLogo.png"
              alt="Argusoft Logo"
            />
          </div>
          <form @submit.prevent="handleSubmit">
            <div class="form_page_inner">
              <div class="col-12 mb-2">
                <label class="control-label required">Email </label>
                <input
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  v-validate="'required|email'"
                  :class="{ 'is-invalid': errors.first('email') }"
                  class="form-control"
                  v-model="applicantData.email"
                />
                <span
                  v-if="errors.first('email')"
                  class="valid-feedback alert-danger"
                  >{{ errors.first("email") }}</span
                >
              </div>
              <div class="col-12 mb-2">
                <label class="control-label required">Password </label>
                <input
                  :type="passwordFieldType"
                  name="password"
                  placeholder="Enter Password"
                  v-validate="'required|min:8|max:20|customPassword'"
                  class="form-control"
                  v-model="applicantData.password"
                  ref="password"
                />
                <button
                  type="button"
                  class="eye-button"
                  @click="togglePasswordVisibility"
                >
                  <i
                    :class="
                      passwordFieldType === 'password'
                        ? 'fa fa-eye'
                        : 'fa fa-eye-slash'
                    "
                  ></i>
                </button>
                <span
                  v-if="errors.first('password')"
                  class="valid-feedback alert-danger"
                  >{{ errors.first("password") }}</span
                >
              </div>
              <div class="col-12 mb-2">
                <label class="control-label required">Confirm Password </label>
                <input
                  :type="confirmPassType"
                  name="confirmPassword"
                  placeholder="Enter Confirm Password"
                  v-validate="
                    'required|min:8|max:20|customPassword|confirmed:password'
                  "
                  v-model="applicantData.confirmPassword"
                  data-vv-as="Confirm Password"
                  class="form-control"
                />
                <button
                  type="button"
                  class="eye-button"
                  @click="toggleConfirmPasswordVisibility"
                >
                  <i
                    :class="
                      confirmPassType === 'password'
                        ? 'fa fa-eye'
                        : 'fa fa-eye-slash'
                    "
                  ></i>
                </button>
                <span
                  v-if="errors.first('confirmPassword')"
                  class="valid-feedback alert-danger"
                >
                  {{ errors.first("confirmPassword") }}
                </span>
              </div>
              <div class="col-12 text-left mb-2">
                <router-link to="/login?as=applicant"
                  >Already Registered ? Login Here</router-link
                >
              </div>
            </div>

            <div class="text-center">
              <button
                style="width: 150px"
                type="submit"
                class="btn btn-cp my-2 ml-0 rounded-pill"
                :disabled="!isDisabled"
              >
                Register
              </button>
            </div>
          </form>
          <div class="font-weight-bold mx-3">
            <h3 class="p-0 my-2"></h3>
            <router-link :to="'/'">
              <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
              <span style="padding: 4px">Homepage</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { isSameAlternateNo, beforeDate } from "../../Common/_plugin/validation";

Validator.extend("sameAlternateNo", isSameAlternateNo);
Validator.extend("beforeDate", beforeDate);

export default {
  data() {
    return {
      applicantData: {
        email: "",
        password: "",
        confirmPassword: "",
      },
      passwordVisible: false,
      confirmPasswordVisible: false,
      passwordValidator(value) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~\-\\|`]).{8,}$/.test(
          value
        );
      },
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    },
    handleSubmit() {
      // eslint-disable-next-line no-unused-vars
      this.$validator.validateAll().then((valid) => {
        if (this.$validator.errors.items.length == 0) {
          this.$store.dispatch(
            "$_applicant_master/applicantRegister",
            this.applicantData
          );
        } else {
          this.$store.dispatch("openSnackbar", {
            type: "danger",
            message: "Please enter valid values!",
          });
        }
      });
    },
  },
  computed: {
    passwordFieldType() {
      return this.passwordVisible ? "text" : "password";
    },
    confirmPassType() {
      return this.confirmPasswordVisible ? "text" : "password";
    },
    isDisabled() {
      return (
        this.applicantData.email &&
        this.applicantData.password &&
        this.applicantData.confirmPassword &&
        this.$validator.errors.items.length === 0
      );
    },
  },
  created() {
    this.$validator.extend("customPassword", {
      getMessage: () =>
        `It requires an uppercase, lowercase, number and special character.`,
      validate: (value) => this.passwordValidator(value),
    });
  },
};
</script>

<style scoped>
.custom-btn {
  padding: 10px 50px 10px 50px;
  border-radius: 25px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.header {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
}

.logo-div {
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}

.registerbox {
  margin: auto;
  max-width: 420px;
  width: 100%;
  height: 540px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 2px 8px #5b5558;
  border-radius: 25px;
}
.registerbox h5 {
  letter-spacing: 0.5px;
  display: block;
  border-radius: 30px;
  padding: 8px 20px;
  border: 1px solid #8f1452;
}

.registerbox h5:hover {
  background-color: #8f1452;
  color: white;
  cursor: pointer;
}

.registerbox h5 img {
  width: 30px;
  height: 30px;
}

.registerbox h5 a {
  padding-left: 20px;
}

.page-bg {
  /* height: 100vh; */
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .registerbox {
    width: 90%;
  }
}

h3 {
  text-align: center;
  border-bottom: 1px solid #efe6e6;
  padding-bottom: 15px;
}

.content {
  margin: auto !important;
}
::v-deep #maincontent {
  margin-top: 0px !important;
}
.eye-button {
  position: absolute;
  right: 20px;
  top: 76%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  z-index: 9999;
}

.eye-button i {
  font-size: 1.2rem;
  color: #6c757d;
}

.eye-button:hover i {
  color: #495057;
}

.eye-button:focus {
  outline: none;
}
</style>
