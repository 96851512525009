<template>
  <div
    class="modal fade"
    id="passQualified"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Pass Qualified</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="input-group mb-3">
              <input
                type="number"
                class="form-control"
                v-model="min_qual_percentage"
              />
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">%</span>
              </div>
            </div>
            <div class="form-group">
              <button
                class="btn btn-cp ml-2 ml-0 btn-block"
                type="button"
                @click="passQualified"
              >
                Check Pass Qualified
              </button>
            </div>
          </form>
          <div class="form-group">
            <p v-if="isEventView" class="ml-3">
              <b>Scheduled applicants in selected Event: </b>
              {{ pendingApplicantIds.length }}
            </p>
            <p v-else class="ml-3">
              <b>Scheduled applicants in selected slot: </b>
              {{ pendingApplicantIds.length }}
            </p>
            <p class="ml-3"><b>Passed: </b> {{ passedApplicants.length }}</p>
            <p class="ml-3"><b>Failed: </b> {{ failedApplicants.length }}</p>
          </div>
          <div>
            <div class="form-group">
              <div class="col-sm-9">
                <div class="d-flex justify-content-between align-items-center">
                  <label class="control-label"
                    >Select Mail Template for Passed Applicants</label
                  >
                </div>
                <select
                  class="custom-select main college mr-2"
                  v-model="pass_mail_template"
                >
                  <option disabled value="">Select Mail Template</option>
                  <option
                    v-for="(mailTemplate, index) in applicationStatusTemplates"
                    :value="mailTemplate.id"
                    :key="index"
                  >
                    {{ mailTemplate.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-9">
                <div class="d-flex justify-content-between align-items-center">
                  <label class="control-label"
                    >Select Mail Template for Failed Applicants</label
                  >
                </div>
                <select
                  class="custom-select main college mr-2"
                  v-model="fail_mail_template"
                >
                  <option disabled value="">Select Mail Template</option>
                  <option
                    v-for="(mailTemplate, index) in applicationStatusTemplates"
                    :value="mailTemplate.id"
                    :key="index"
                  >
                    {{ mailTemplate.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark btn-sm"
            data-dismiss="modal"
            @click="$emit('canceled')"
          >
            <strong>Cancel</strong>
          </button>
          <button
            :disabled="
              (passedApplicants.length == 0 && failedApplicants.length == 0) ||
              !pass_mail_template ||
              !fail_mail_template
            "
            type="button"
            class="btn btn-cp ml-2"
            @click="submit"
          >
            <i class="fa fa-book fa-lg" aria-hidden="true"></i>
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../_api/index";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  props: [
    "eventId",
    "selectedSlotData",
    "pendingApplicantIds",
    "selectedCampusEventData",
    "isEventView",
  ],
  data() {
    return {
      passedApplicants: [],
      failedApplicants: [],
      min_qual_percentage: 75,
      pass_mail_template: null,
      fail_mail_template: null,
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      mailTemplates: "$_mailbox/getTemplates",
    }),
    applicationStatusTemplates() {
      if (this.mailTemplates) {
        return this.mailTemplates.filter((mail) => {
          return mail.key == "Application Status";
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    getAllMailTemplates() {
      this.$store.dispatch("$_mailbox/getTemplates");
    },
    passQualified() {
      api
        .passQualified(
          this.pendingApplicantIds,
          this.min_qual_percentage,
          this.eventId
        )
        .then((res) => {
          this.passedApplicants = res.data.passedApplicant;
          this.failedApplicants = res.data.failedApplicant;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    submit() {
      const data = {
        minQualPercent: this.min_qual_percentage,
        applicantIds: this.pendingApplicantIds,
        passedApplicant: this.passedApplicants,
        failedApplicant: this.failedApplicants,
        eventId: this.eventId,
        pass_mail_template: this.pass_mail_template,
        fail_mail_template: this.fail_mail_template,
      };
      if (this.selectedSlotData) {
        data.slotsMasterId = this.selectedSlotData.id;
        api
          .updateApplicantStatusSlot(data)
          .then(() => {
            this.passedApplicants = [];
            this.failedApplicants = [];
            this.min_qual_percentage = 75;
            this.$emit("submitted");
          })
          .catch((err) => {
            console.error(err);
          });
      } else if (this.selectedCampusEventData) {
        api
          .updateApplicantStatusEvent(data)
          .then(() => {
            this.passedApplicants = [];
            this.failedApplicants = [];
            this.min_qual_percentage = 75;
            this.$emit("submitted");
          })
          .catch((err) => {
            console.error(err);
          });
      }
      $("#passQualified").modal("hide");
    },
  },
  beforeMount() {},
  mounted() {
    $("#passQualified").on("shown.bs.modal", () => {
      this.getAllMailTemplates();
    });
    $("#passQualified").on("hidden.bs.modal", () => {
      this.passedApplicants = [];
      this.failedApplicants = [];
      this.min_qual_percentage = 75;
    });
  },
  beforeDestroy() {
    $("#passQualified").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.custom-btn-gray {
  height: 35px;
  display: flex;
  align-items: center;
}

.custom-input {
  height: 35px;
  max-width: 60px;
  padding-right: 10px;
  padding-left: 10px;
  border: 0;
  color: #000;
  background-color: #ccc;
  border-radius: 4px;
}
</style>
