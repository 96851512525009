<template>
  <div class="row" v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })">
    <div class="col-6 pr-0">
      <router-link
        class="fa fa-pencil action-icon edit-icon"
        :to="{
          name: 'editEntityForm',
          params: { entityId: params.data.id, currentMode: 'editEntity' },
        }"
      >
      </router-link>
    </div>
    <div class="col-6 pl-0">
      <div v-if="params.data.status === 'active'">
        <em
          class="fa fa-ban delete-icon action-icon"
          data-toggle="modal"
          data-target="#deleteModal"
          data-backdrop="static"
          data-keyboard="false"
          @click="setDeleteEntityId(params.data, 'block')"
        ></em>
      </div>

      <div v-if="params.data.status === 'block'">
        <em
          class="fa fa-repeat success-icon action-icon"
          data-toggle="modal"
          data-target="#deleteModal"
          data-backdrop="static"
          data-keyboard="false"
          @click="setDeleteEntityId(params.data, 'active')"
        ></em>
      </div>
    </div>
  </div>
</template>

<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

/**
 * @memberof module:campusRecruitment
 * @namespace components.edit_entity_button
 */
export default {
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS["CAMPUS-DRIVE"].ENTITY;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    /**
     * @memberof module:campusRecruitment.components.edit_entity_button
     * @param {number} value
     * @description Set delete entity id by dispatching value to action
     */
    setDeleteEntityId(value, action) {
      let data = {
        id: value.id,
        status: value.status,
        action,
      };
      this.$store.dispatch("$_campus_recruitment/setDeleteEntityId", data);
    },

    /**
     * @memberof module:campusRecruitment.components.edit_entity_button
     * @param {Object} value
     * @description Dispatch edit entity data to action
     */
    editEntity(value) {
      this.$store.dispatch(
        "$_campus_recruitment/setUpdateEntityModalData",
        value
      );
    },
  },
};
</script>
<style scoped>
.action-icon {
  font-size: 1.33em !important;
  cursor: pointer;
  width: 50%;
}
</style>
