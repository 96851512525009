import _managefiles from "./index";

export default {
  path: "manage-file",
  component: _managefiles,
  name: "manageFiles",
  meta: {
    title: "Manage Files",
    user: "employee",
  },
};
