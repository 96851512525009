const states = [
  "Andaman and Nicobar Islands",
  "Andhra Pradesh",
  "Arunachal Pradesh ",
  "Assam",
  "Bihar ",
  "Chandigarh ",
  "Chhattisgarh ",
  "Dadra and Nagar Haveli ",
  "Daman and Diu ",
  "Delhi ",
  "Goa ",
  "Gujarat ",
  "Haryana ",
  "Himachal Pradesh ",
  "Jammu and Kashmir ",
  "Jharkhand ",
  "Karnataka ",
  "Kerala ",
  "Lakshadweep ",
  "Madhya Pradesh ",
  "Maharashtra ",
  "Manipur ",
  "Meghalaya ",
  "Mizoram ",
  "Nagaland ",
  "Odisha ",
  "Pondicherry ",
  "Punjab ",
  "Rajasthan ",
  "Sikkim ",
  "Tamil Nadu ",
  "Telangana",
  "Tripura ",
  "Uttar Pradesh ",
  "Uttarakhand ",
  "West Bengal",
];

const position = [
  "Business Analyst",
  "Programmer Analyst",
  "Software Quality Analyst",
  "Web Designer",
  "UI/UX Designer",
  "Business Development",
  "Network Manager",
  "Business Development",
];

const employment_status = ["Fresher", "Working", "Freelancer", "Other"];

const entity_type = ["College", "University", "Recruiter"];

const job_status_type = ["active", "inactive"];

const review_status_type = ["active", "inactive"];

export {
  states,
  position,
  employment_status,
  entity_type,
  job_status_type,
  review_status_type,
};
