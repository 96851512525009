export const COURSE_LIST = [
  "Computer Science and Engineering",
  "Biotechnology Engineering",
  "Mechanical Engineering",
  "Civil Engineering",
  "Aeronautical Engineering",
  "Automobile Engineering",
  "Electronics and Communication Engineering",
  "Electrical and Electronics Engineering",
  "Data Science",
  "Power Electronics",
  "MCA",
  "BCA",
  "MSc",
  "BSc",
];
