import axios from "axios";
import recruiterAxios from "../_utils/axios";
const prefix = "/recruiter";

const loginUser = (email) => {
  const data = { data: email };
  return axios.post(prefix + "/login", data);
};

const fetchRecruiterData = () => {
  return recruiterAxios.get(prefix + "/my-profile");
};

/**
 * @memberof module:Recruiter.api
 * @returns {Promise}
 * @description Fetch application status and their count
 */
const fetchApplicationStatusCount = (filterOption) => {
  return recruiterAxios.get(
    prefix + "/applicants/count/status" + (filterOption ? filterOption : "")
  );
};

/**
 * @memberof module:Recruiter.api
 * @returns {Promise}
 * @description Fetch application position and their count
 */

const fetchApplicationPositionCount = (filterOption) => {
  return recruiterAxios.get(
    prefix + "/applicants/position-count" + (filterOption ? filterOption : "")
  );
};

/**
 * @memberof module:Recruiter.api
 * @returns {Promise}
 * @description send bulk applicants
 */
const sendBulkApplicants = (applicants) => {
  return recruiterAxios.post(prefix + "/applicants/register/bulk", {
    data: applicants,
  });
};

/**
 * @memberof module:Recruiter.api
 * @returns {Promise}
 * @description delete multiple applicants
 */
const deleteMultipleApplicants = (ids, campusId) => {
  return recruiterAxios.post(prefix + "/applicants/delete/bulk", {
    id: ids,
    campusId: campusId,
  });
};

/**
 * @memberof module:Recruiter.api
 * @returns {Promise}
 * @description get applicant details by slug
 */
const getApplicantDetailsBySlug = (slug) => {
  return recruiterAxios.get(prefix + "/applicants/by/slug/" + slug);
};

/**
 * @memberof module:Recruiter.api
 * @returns {Promise}
 * @description update applicant data
 */
const updateApplicantData = (data) => {
  return recruiterAxios.put(prefix + "/applicants/update/" + data.id, {
    data: data,
  });
};

/**
 * @memberof module:Recruiter.api
 * @returns {Promise}
 * @description confirm duplicate applicant
 */
const entityListWithSessions = () => {
  return recruiterAxios.get(prefix + "/entities/data-list");
};

/**
 * @memberof module:Recruiter.api
 * @returns {Promise}
 * @description get campus events
 */
const getCampusEvents = (filter) => {
  return recruiterAxios.get(prefix + "/schedules" + filter);
};

const checkExpiry = (value) => {
  return axios.get(prefix + "/password/check-status/" + value);
};

const resetPassword = (accountPassword) => {
  const data = { data: accountPassword };
  return axios.post(prefix + "/password/reset", data);
};

const recruiterResetPasswordRequest = (data) => {
  return axios.post(prefix + "/forgot-password-request", data);
};

/**
 * @memberof module:Recruiter.api
 * @returns {Promise}
 * @description get file format
 */
const getFormatFile = () => {
  return recruiterAxios.get(prefix + "/download/freshers-format", {
    responseType: "blob",
  });
};

const searchApplicants = (filter) => {
  return recruiterAxios.post(
    prefix + "/applicants/search",
    {},
    { params: filter }
  );
};

const applicantsDataList = (dataParams, filter) => {
  return recruiterAxios.post(prefix + "/applicants/data-list", dataParams, {
    params: filter,
  });
};

/**
 * @memberof module:Recruiter.api
 * @memberof module:Recruiter
 * @namespace api
 * @description api for backend interactions
 */
export default {
  loginUser,
  fetchRecruiterData,
  fetchApplicationStatusCount,
  fetchApplicationPositionCount,
  sendBulkApplicants,
  deleteMultipleApplicants,
  getApplicantDetailsBySlug,
  updateApplicantData,
  entityListWithSessions,
  getCampusEvents,
  checkExpiry,
  resetPassword,
  getFormatFile,
  searchApplicants,
  applicantsDataList,
  recruiterResetPasswordRequest,
};
