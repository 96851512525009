var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{class:_vm.containerClasses,attrs:{"id":_vm.containerId}},[_c('div',{staticClass:"form-group",class:_vm.inputClasses},[(_vm.label)?_c('label',{staticClass:"control-label",class:{ required: _vm.required },attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label)+" (Max "+_vm._s(_vm.maxFiles)+" file/s allowed)")]):_vm._e(),_c('div',{staticClass:"file-upload-box",class:{
          invalid: _vm.errors.first(_vm.id),
        }},[(_vm.selectedFiles && _vm.selectedFiles.length > 0)?_vm._l((_vm.selectedFiles),function(selectedFile,index){return _c('span',{key:index,staticStyle:{"width":"100%"}},[_c('div',{staticClass:"file-tag"},[_vm._v(" "+_vm._s(selectedFile.name)+" "),_c('span',{staticClass:"close-btn",on:{"click":function($event){return _vm.removeFile(index)}}},[_c('em',{staticClass:"fa fa-close"})])])])}):_vm._e(),(!_vm.selectedFiles || _vm.selectedFiles.length === 0)?_c('span',{staticClass:"upload fa fa-upload",staticStyle:{"width":"100%","text-align":"center","padding-top":"10px","cursor":"pointer"},on:{"click":_vm.openFileInput}},[_c('span',{staticStyle:{"margin":"0px 8px"}},[_vm._v(" Choose file/s or Drag and Drop ")])]):_vm._e(),(_vm.maxFiles <= 1)?_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(
            `${_vm.required ? 'required|' : ''}extationValidation:${
              this.fileExtensions
            }|size:${this.fileSize.size}|`
          ),expression:"\n            `${required ? 'required|' : ''}extationValidation:${\n              this.fileExtensions\n            }|size:${this.fileSize.size}|`\n          "}],ref:"fileInputSingle",attrs:{"id":_vm.id,"type":"file","name":_vm.id,"data-vv-as":"file"},on:{"change":_vm.fileSelected}}):_vm._e(),(_vm.maxFiles > 1)?_c('input',{directives:[{name:"validate",rawName:"v-validate",value:(
            `${_vm.required ? 'required|' : ''}extationValidation:${
              this.fileExtensions
            }|size:${this.fileSize.size}|`
          ),expression:"\n            `${required ? 'required|' : ''}extationValidation:${\n              this.fileExtensions\n            }|size:${this.fileSize.size}|`\n          "}],ref:"fileInputMultiple",attrs:{"id":_vm.id,"type":"file","name":_vm.id,"multiple":""},on:{"change":_vm.filesSelected}}):_vm._e()],2),(_vm.errors.first(_vm.id))?_c('span',{staticClass:"valid-feedback alert-danger",staticStyle:{"top":"20px"}},[_vm._v(_vm._s(_vm.errors.first(_vm.id)))]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }