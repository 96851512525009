export default {
  time: "fa fa-address-book",
  date: "fa fa-calendar",
  up: "fa fa-arrow-up",
  down: "fa fa-arrow-down",
  previous: "fa fa-chevron-left",
  next: "fa fa-chevron-right",
  today: "fa fa-calendar-check",
  clear: "fa fa-trash",
  close: "fa fa-times-circle",
};
