var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mx-0 p-0"},[_c('div',{class:{
          'col-12 col-lg-3 px-0 pr-md-2': _vm.rightSideComponents.length,
          'col-12 px-0': !_vm.rightSideComponents.length,
        }},_vm._l((_vm.leftSideComponents),function(comp,index){return _c(comp.name,{key:`left-${index}`,tag:"component",attrs:{"leftLength":_vm.leftSideComponents.length,"rightLength":_vm.rightSideComponents.length}})}),1),_c('div',{class:{
          'col-12 col-lg-9 px-0 pl-md-2': _vm.leftSideComponents.length,
          'col-12 px-0': !_vm.leftSideComponents.length,
        }},_vm._l((_vm.rightSideComponents),function(comp,index){return _c(comp.name,{key:`right-${index}`,tag:"component",attrs:{"leftLength":_vm.leftSideComponents.length,"rightLength":_vm.rightSideComponents.length}})}),1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }