<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    role="dialog"
    aria-labelledby="commonModalLabel"
  >
    <div class="modal-dialog" :class="modalSizeClass" role="document">
      <div class="modal-content m-t100" id="commonModalContent">
        <div class="modal-header" :class="modalColor">
          <h4 class="modal-title" id="commonModalLabel">{{ modalTitle }}</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="emitEvent('cancel')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <safe-content
            v-if="modalBody"
            :content="modalBody"
            tag="p"
          ></safe-content>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark"
            v-if="showCancel"
            @click="emitEvent('cancel')"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-cp ml-2"
            @click="emitEvent('ok')"
            data-dismiss="modal"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberof module:Common
 * @namespace common_modal_component
 */
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    modalColor: {
      type: String,
      required: false,
    },
    modalSizeClass: {
      type: String,
      required: false,
    },
    modalTitle: {
      type: String,
      required: true,
    },
    modalBody: {
      type: String,
      required: true,
    },
    showCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    /**
     * @memberof module:Common.common_modal_component
     * @param {String} eventType 'ok' or 'cancel'
     * @description emits event on click of ok or cancel button
     */
    emitEvent(eventType) {
      this.$emit("actionPerformed", eventType);
    },
  },
  beforeDestroy() {
    if (this.id) {
      $(`#${this.id}`).modal("hide");
      $(".modal-backdrop").remove();
      $("body").removeClass("modal-open");
    }
  },
};
</script>

<style scoped>
.warn {
  background-color: #eea236;
}

.danger {
  background-color: #d9534f;
}

#commonModalContent {
  white-space: normal;
}
</style>
