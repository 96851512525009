import { addSnackbarMessage } from "../../Common/_plugin/error-response";
import api from "../_api";

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description get data of Review categories and Reviews
 */
const fetchReviewCategoriesWithReviews = (context) => {
  api
    .fetchReviewCategoriesWithReviews()
    .then((response) => {
      const data = response.data.reviewCategories;

      data.forEach((reviewCategory) => {
        reviewCategory.reviews = [];
      });

      response.data.reviews.forEach((review) => {
        const reviewCategoryIndex = data.findIndex(
          (reviewCategory) => reviewCategory.id === review.reviewCategoryId
        );
        data[reviewCategoryIndex].reviews.push(review);
      });
      context.commit("fetchReviewCategoriesWithReviews", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description add data of Review
 */
const addReview = (context, review) => {
  api
    .addReview(review)
    .then((response) => {
      context.commit("addReview", response.data);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Review successfully added!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem adding review!", true);
    });
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description add data of Review category
 */
const addReviewCategory = (context, reviewCategory) => {
  api
    .addReviewCategory(reviewCategory)
    .then((response) => {
      context.commit("addReviewCategory", response.data);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Category successfully added!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem adding Category!", true);
    });
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description get data of Review category to add Review
 */
const setAddReviewModalData = (context, reviewCategoryData) => {
  context.commit("ADD_REVIEW_MODAL_OPENED", reviewCategoryData);
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description delete data of Review category and Reviews
 */
const deleteItem = (context, itemDeleteData) => {
  context.commit("DELETE_ITEM_MODAL", itemDeleteData);
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description delete data of Review category
 */
const deleteReviewCategory = (context, reviewCategoryId) => {
  api
    .deleteReviewCategory(reviewCategoryId)
    .then(() => {
      context.commit("DELETE_REVIEW_CATEGORY", reviewCategoryId);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Category successfully deleted!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem deleting Category!", true);
    });
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description delete data of Review
 */
const deleteReview = (context, deleteReviewData) => {
  api
    .deleteReview(deleteReviewData.reviewId)
    .then(() => {
      context.commit("DELETE_REVIEW", deleteReviewData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Review successfully deleted!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem deleting review!", true);
    });
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description get id of Review
 */
const editReviewModal = (context, editReviewData) => {
  context.commit("EDIT_REVIEW_MODAL", editReviewData);
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description edit data of Review
 */
const editReview = (context, editReviewData) => {
  api
    .editReview(editReviewData.objectInfo)
    .then(() => {
      context.commit("EDIT_REVIEW", editReviewData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Review successfully updated!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem editing Review!", true);
    });
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description get data of Review category to edit
 */
const editReviewCategoryModal = (context, editReviewCategoryData) => {
  context.commit("EDIT_REVIEW_CATEGORY_MODAL", editReviewCategoryData);
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description get data of Review category to edit with status
 */
const updateReviewCategoryWithReviewStatus = (context, editedData) => {
  api
    .updateReviewCategoryWithReviewStatus(editedData)
    .then(() => {
      context.commit("UPDATE_REVIEW_CATEGORY_WITH_REVIEWS_STATUS", editedData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Category Status updated",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem updating Category status", true);
    });
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description get data of Review category to edit with status
 */
const editReviewStatus = (context, editedReviewData) => {
  api
    .editReviewStatus(editedReviewData)
    .then(() => {
      context.commit("UPDATE_REVIEW_STATUS", editedReviewData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Review Status updated",
        },
        {
          root: true,
        }
      );
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem updating Review status", true);
    });
};

/**
 * @memberOf module:ManageReview.store.actions
 * @param {Object} context store context
 * @description edit data of Review category
 */
const editReviewCategory = (context, editReviewCategoryData) => {
  api
    .editReviewCategory(editReviewCategoryData)
    .then(() => {
      context.commit("EDIT_REVIEW_CATEGORY", editReviewCategoryData);
      context.dispatch(
        "openSnackbar",
        {
          type: "success",
          message: "Category successfully updated!",
        },
        { root: true }
      );
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem editing Category!", true);
    });
};

/**
 * @memberOf module:ManageReview
 * @namespace store.actions
 * @description actions for manage review module
 */
export default {
  fetchReviewCategoriesWithReviews,
  addReview,
  addReviewCategory,
  setAddReviewModalData,
  deleteItem,
  deleteReviewCategory,
  deleteReview,
  editReviewModal,
  editReview,
  editReviewCategoryModal,
  editReviewCategory,
  updateReviewCategoryWithReviewStatus,
  editReviewStatus,
};
