import axios from "axios";

/**
 * Calling api directly, better to call from store
 */

/**
 * @memberOf module:FormTemplate.api
 * @returns {Promise}
 * @description Fetch All FormTemplates
 */
const fetchAllForms = () => {
  return axios.get("form-templates");
};

/**
 * @memberOf module:FormTemplate.api
 * @returns {Promise}
 * @description Fetch All FormTemplates
 */
const fetchFormById = (formId) => {
  return axios.get("form-templates/" + formId);
};

const fetchFormVersionsByFormId = (formId) => {
  return axios.get(`form-templates/${formId}/versions`);
};

const fetchFormVersionById = (formVersionId) => {
  return axios.get("form-templates/version/" + formVersionId);
};

// Works with both ID & Hash
const fetchFormVersionByHash = (formHash) => {
  return axios.get("form-templates/current/" + formHash);
};

/**
 * @memberOf module:FormTemplate.api
 * @param {Object} data
 * @returns {Promise}
 * @description inserts new FormTemplates
 */
const addFormTemplate = (data) => {
  return axios.post("form-templates", data);
};

const editFormTemplate = (data) => {
  return axios.put(`form-templates`, data);
};

const deleteFormTemplate = (id) => {
  return axios.delete(`form-templates/${id}`);
};

/**
 * @memberOf module:FormTemplate.api
 * @param {Object} data
 * @returns {Promise}
 * @description inserts new Form Version
 */
const saveFormVersion = (data) => {
  return axios.put("form-templates/version", data);
};

const discardFormVersion = (formVersionId) => {
  return axios.delete("form-templates/version/" + formVersionId);
};

const copyFormMaster = (formMasterId, title) => {
  const data = {
    data: {
      formMasterId,
      title,
    },
  };
  return axios.post("form-templates/copy", data);
};

export default {
  fetchAllForms,
  fetchFormById,
  fetchFormVersionByHash,
  fetchFormVersionById,
  fetchFormVersionsByFormId,
  addFormTemplate,
  saveFormVersion,
  editFormTemplate,
  deleteFormTemplate,
  discardFormVersion,
  copyFormMaster,
};
