var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"p-0",attrs:{"id":"comments"}},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"well"},[_c('div',{staticClass:"p-1 p-md-3"},[_c('div',{staticClass:"media",staticStyle:{"border-top":"none","padding-top":"10px"}},[_c('a',{staticClass:"pull-left"},[_c('img',{staticClass:"img-responsive",attrs:{"src":!!_vm.loggedInUser.profile_pic
                      ? _vm.loggedInUser.profile_pic
                      : _vm.avatar,"alt":"user"}})]),_c('div',{staticClass:"w-100"},[_c('common-rich-text-box',{ref:"commonRichTextBox",staticClass:"form-control",attrs:{"modalId":"parents-comments","placeholder":"Your comments","width":'100%',"height":'80',"minHeight":'auto',"haveMentions":true},on:{"onKeyUp":_vm.onKeyUp,"onBlur":_vm.onBlur}}),_c('div',{staticClass:"form-group float-right m-t10"},[(
                      _vm.hasAccess({
                        permissions: [_vm.BASE_PERMISSION['ADMIN-COMMENT']],
                      })
                    )?_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.adminCheckbox),expression:"adminCheckbox"}],staticClass:"control-label",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.adminCheckbox)?_vm._i(_vm.adminCheckbox,null)>-1:(_vm.adminCheckbox)},on:{"change":function($event){var $$a=_vm.adminCheckbox,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.adminCheckbox=$$a.concat([$$v]))}else{$$i>-1&&(_vm.adminCheckbox=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.adminCheckbox=$$c}}}}),_c('span',{staticClass:"ml-1"},[_vm._v("Only for Admins")])]):_vm._e(),_c('button',{staticClass:"btn btn-cp ml-2",attrs:{"type":"button","disabled":!_vm.isComplete},on:{"click":_vm.submitComment}},[_c('i',{staticClass:"fa fa-paper-plane px-0",attrs:{"aria-hidden":"true"}})])])],1)])]),_vm._l((_vm.commentList),function(item){return _c('div',{key:item.id,staticClass:"px-3"},[_c('thread-comments',{attrs:{"item":item}})],1)})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"well section-head w-auto"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-md-2 col-md-offset-5"},[_c('h4',[_c('strong',[_vm._v("Comments")])])])])])
}]

export { render, staticRenderFns }