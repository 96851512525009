<template>
  <div class="modal fade" id="editCategoryModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Category Details</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group position-relative">
              <label class="control-label"
                >Category<span class="text-danger"> *</span></label
              >
              <input
                type="text"
                class="form-control"
                name="category-name"
                v-validate="'required|regex:^[^-\s][a-zA-Z ]*$'"
                data-vv-as="Category Name"
                data-vv-validate-on="blur|input"
                v-model="data.category_name"
                :class="{ invalid: errors.first('category-name') }"
                maxlength="30"
              />
              <span
                v-if="errors.first('category-name')"
                class="valid-feedback alert-danger"
                >{{ errors.first("category-name") }}</span
              >
            </div>
            <div class="form-group position-relative">
              <label class="control-label"
                >Category Description<span class="text-danger"> *</span></label
              >
              <input
                type="text"
                class="form-control"
                name="category-description"
                v-validate="'required'"
                data-vv-as="Category Description"
                data-vv-validate-on="blur|input"
                v-model="data.category_description"
                :class="{ invalid: errors.first('category-description') }"
              />
              <span
                v-if="errors.first('category-description')"
                class="valid-feedback alert-danger"
                >{{ errors.first("category-description") }}</span
              >
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="$v.data.$invalid"
            type="button"
            class="btn btn-cp"
            @click="updateCategory"
            data-dismiss="modal"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, maxLength } from "vuelidate/lib/validators";

/**
 * @memberof module:ManageJob
 * @namespace components.edit_category_modal
 */
export default {
  /**
   * @memberof module:ManageJob.components.edit_category_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      data: {
        id: null,
        category_name: "",
        category_description: "",
        status: "active",
      },
    };
  },

  /**
   * @memberof module:ManageJob.components.edit_category_modal
   * @returns {Object} validation object
   * @description data - returns validation object for current vue component
   */
  validations() {
    const data = {
      category_name: {
        required,
        maxLength: maxLength(30),
        categoryNameValidator: this.categoryNameValidator,
      },
      category_description: {
        required,
      },
    };
    return {
      data,
    };
  },
  computed: {
    ...mapGetters({
      categoryData: "$_manage_jobs/editCategoryModal",
    }),
  },
  watch: {
    /**
     * @memberof module:ManageJob.components.edit_category_modal
     * @param {Object} value new value
     * @description watch - handle Category data
     */
    categoryData(value) {
      this.data.id = value.id;
      this.data.category_name = value.category_name;
      this.data.category_description = value.category_description;
    },
  },
  methods: {
    /**
     * @memberof module:ManageJob.components.edit_category_modal
     * @description method - dispatch request to edit category
     */
    updateCategory() {
      this.$store.dispatch("$_manage_jobs/editCategory", this.data);
    },

    /**
     * @memberof module:ManageJob.components.edit_category_modal
     * @param {String} value new value
     * @returns {Boolean} validation result
     * @description method - checks whether category name is valid or not
     */
    categoryNameValidator(value) {
      return /^[^-\s][a-zA-Z ]*$/.test(value);
    },
  },
  beforeDestroy() {
    $("#editCategoryModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
<style scoped></style>
