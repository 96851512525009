const BASE_URLS = {
  APPLICANT: ["APPLICANT"],
  ROUND: ["ROUND"],
  MAIL: ["MAIL"],
  "CAMPUS-DRIVE": ["CAMPUS-DRIVE"],
  FORM: ["FORM"],
  CONFIGURATION: ["CONFIGURATION"],
  REPORT: ["REPORT"],
  JOB: ["JOB"],
  "AUDIT-LOG": ["AUDIT-LOG"],
  "ERROR-LOG": ["ERROR-LOG"],
  "APPLICANT-REPORT": ["APPLICANT-REPORT"],
  REVIEW: ["REVIEW"],
  "SHARED-TOKEN": ["SHARED-TOKEN"],
  "FILE-TEMPLATE": ["FILE-TEMPLATE"],
  "CRON-JOB": ["CRON-JOB"],
  "HTML-TEMPLATE": ["HTML-TEMPLATE"],
};

const PERMISSIONS = {
  "ALL-PERMISSION": { title: ["ALL-PERMISSION"] },

  MAIL: {
    CREATE: {
      title: [...BASE_URLS.MAIL, "CREATE"],
    },
    MENU: {
      title: ["MENU", "MAIL"],
    },
    UPDATE: {
      title: [...BASE_URLS.MAIL, "UPDATE"],
    },
    DELETE: {
      title: [...BASE_URLS.MAIL, "DELETE"],
    },
    TEMPLATE: {
      CREATE: {
        title: [...BASE_URLS.MAIL, "TEMPLATE", "CREATE"],
      },
      READ: {
        title: [...BASE_URLS.MAIL, "TEMPLATE", "READ"],
      },
      UPDATE: {
        title: [...BASE_URLS.MAIL, "TEMPLATE", "UPDATE"],
      },
      DELETE: {
        title: [...BASE_URLS.MAIL, "TEMPLATE", "DELETE"],
      },
    },
  },
  APPLICANT: {
    // CREATE: {
    //   title: [...BASE_URLS.APPLICANT, 'CREATE']
    // },
    READ: {
      title: [...BASE_URLS.APPLICANT, "READ"],
    },
    UPDATE: {
      title: [...BASE_URLS.APPLICANT, "UPDATE"],
    },
    "ADMIN-COMMENT": {
      title: [...BASE_URLS.APPLICANT, "ADMIN-COMMENT"],
    },
    // DELETE: {
    //   title: [...BASE_URLS.APPLICANT, 'DELETE']
    // },
    CTC: {
      title: [...BASE_URLS.APPLICANT, "CTC"],
    },
    DUPLICATE: {
      READ: {
        title: [...BASE_URLS.APPLICANT, "DUPLICATE", "READ"],
      },
      UPDATE: {
        title: [...BASE_URLS.APPLICANT, "DUPLICATE", "UPDATE"],
      },
    },
    FOLLOWUP: {
      CREATE: {
        title: [...BASE_URLS.APPLICANT, "FOLLOWUP", "CREATE"],
      },
      // READ: {
      //   title: [...BASE_URLS.APPLICANT, 'FOLLOWUP', 'READ']
      // },
      UPDATE: {
        title: [...BASE_URLS.APPLICANT, "FOLLOWUP", "UPDATE"],
      },
    },
    ROUND: {
      CREATE: {
        title: [...BASE_URLS.APPLICANT, "ROUND", "CREATE"],
      },
      UPDATE: {
        title: [...BASE_URLS.APPLICANT, "ROUND", "UPDATE"],
      },
    },
    "APPLICANT-SEARCH": {
      title: ["MENU", "APPLICANT", "APPLICANT-SEARCH"],
    },
    "FOLLOW-UP": {
      title: ["MENU", "APPLICANT", "FOLLOW-UP"],
    },
    "APPLICANT-TRACKER": {
      title: ["MENU", "APPLICANT", "APPLICANT-TRACKER"],
    },
    // 'APPLICANT-SCHEDULE': {
    //   title: ['MENU', 'APPLICANT', 'APPLICANT-SCHEDULE']
    // },
    "CAMPUS-SESSION": {
      title: ["MENU", "APPLICANT", "CAMPUS-SESSION"],
    },
    "LINK-REQUESTS": {
      MENU: {
        title: ["MENU", "APPLICANT", "LINK-REQUESTS"],
      },
      UPDATE: {
        title: [...BASE_URLS["APPLICANT"], "LINK-REQUESTS", "UPDATE"],
      },
    },
  },
  "CAMPUS-DRIVE": {
    ENTITY: {
      CREATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "ENTITY", "CREATE"],
      },
      MENU: {
        title: ["MENU", "CAMPUS-DRIVE", "ENTITY"],
      },
      UPDATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "ENTITY", "UPDATE"],
      },
    },
    RECRUITMENT: {
      CREATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "RECRUITMENT", "CREATE"],
      },
      MENU: {
        title: ["MENU", "CAMPUS-DRIVE", "RECRUITMENT"],
      },
      UPDATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "RECRUITMENT", "UPDATE"],
      },
    },
    SCHEDULE: {
      CREATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "SCHEDULE", "CREATE"],
      },
      MENU: {
        title: ["MENU", "CAMPUS-DRIVE", "SCHEDULE"],
      },
      UPDATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "SCHEDULE", "UPDATE"],
      },
    },
    MOODLE: {
      CREATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "MOODLE", "CREATE"],
      },
      MENU: {
        title: ["MENU", "CAMPUS-DRIVE", "MOODLE"],
      },
      UPDATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "MOODLE", "UPDATE"],
      },
      DELETE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "MOODLE", "DELETE"],
      },
    },
    SLOT: {
      CREATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "SLOT", "CREATE"],
      },
      READ: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "SLOT", "READ"],
      },
      UPDATE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "SLOT", "UPDATE"],
      },
      DELETE: {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "SLOT", "DELETE"],
      },
      "SCHEDULE-READ": {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "SLOT", "SCHEDULE-READ"],
      },
      "SCHEDULE-CREATE": {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "SLOT", "SCHEDULE-CREATE"],
      },
      "SCHEDULE-UPDATE": {
        title: [...BASE_URLS["CAMPUS-DRIVE"], "SLOT", "SCHEDULE-UPDATE"],
      },
    },
  },
  JOB: {
    CREATE: {
      title: [...BASE_URLS.JOB, "CREATE"],
    },
    MENU: {
      title: ["MENU", "JOB"],
    },
    UPDATE: {
      title: [...BASE_URLS.JOB, "UPDATE"],
    },
    DELETE: {
      title: [...BASE_URLS.JOB, "DELETE"],
    },
    SEARCH: {
      title: [...BASE_URLS.JOB, "SEARCH"],
    },
  },
  FORM: {
    CREATE: {
      title: [...BASE_URLS.FORM, "CREATE"],
    },
    MENU: {
      title: ["MENU", "FORM"],
    },
    UPDATE: {
      title: [...BASE_URLS.FORM, "UPDATE"],
    },
    DELETE: {
      title: [...BASE_URLS.FORM, "DELETE"],
    },
  },
  "AUDIT-LOG": {
    MENU: {
      title: ["MENU", "AUDIT-LOG"],
    },
  },
  "ERROR-LOG": {
    MENU: {
      title: ["MENU", "ERROR-LOG"],
    },
  },
  "APPLICANT-REPORT": {
    MENU: {
      title: ["MENU", "APPLICANT-REPORT"],
    },
  },
  CONFIGURATION: {
    CREATE: {
      title: [...BASE_URLS["CONFIGURATION"], "CREATE"],
    },
    MENU: {
      title: ["MENU", "CONFIGURATION"],
    },
    UPDATE: {
      title: [...BASE_URLS["CONFIGURATION"], "UPDATE"],
    },
    DELETE: {
      title: [...BASE_URLS["CONFIGURATION"], "DELETE"],
    },
  },
  REPORT: {
    SESSION: {
      CREATE: {
        title: [...BASE_URLS.REPORT, "SESSION", "CREATE"],
      },
      MENU: {
        title: ["MENU", "REPORT", "SESSION"],
      },
      UPDATE: {
        title: [...BASE_URLS.REPORT, "SESSION", "UPDATE"],
      },
      DELETE: {
        title: [...BASE_URLS.REPORT, "SESSION", "DELETE"],
      },
    },
    CANDIDATE: {
      MENU: {
        title: ["MENU", "REPORT", "CANDIDATE"],
      },
    },
    APPLICANT: {
      MENU: {
        title: ["MENU", "REPORT", "APPLICANT"],
      },
    },
  },
  // ROUND: {
  //   CREATE: {
  //     title: [...BASE_URLS.ROUND, 'CREATE']
  //   },
  //   READ: {
  //     title: [...BASE_URLS.ROUND, 'READ']
  //   },
  //   UPDATE: {
  //     title: [...BASE_URLS.ROUND, 'UPDATE']
  //   },
  //   DELETE: {
  //     title: [...BASE_URLS.ROUND, 'DELETE']
  //   }
  // },
  REVIEW: {
    CREATE: {
      title: [...BASE_URLS.REVIEW, "CREATE"],
    },
    MENU: {
      title: ["MENU", "REVIEW"],
    },
    UPDATE: {
      title: [...BASE_URLS.REVIEW, "UPDATE"],
    },
    DELETE: {
      title: [...BASE_URLS.REVIEW, "DELETE"],
    },
  },
  "SHARED-TOKEN": {
    CREATE: {
      title: [...BASE_URLS["SHARED-TOKEN"], "CREATE"],
    },
    MENU: {
      title: ["MENU", "SHARED-TOKEN"],
    },
    UPDATE: {
      title: [...BASE_URLS["SHARED-TOKEN"], "UPDATE"],
    },
    DELETE: {
      title: [...BASE_URLS["SHARED-TOKEN"], "DELETE"],
    },
  },
  "FILE-TEMPLATE": {
    CREATE: {
      title: [...BASE_URLS["FILE-TEMPLATE"], "CREATE"],
    },
    MENU: {
      title: ["MENU", "FILE-TEMPLATE"],
    },
    UPDATE: {
      title: [...BASE_URLS["FILE-TEMPLATE"], "UPDATE"],
    },
    DELETE: {
      title: [...BASE_URLS["FILE-TEMPLATE"], "DELETE"],
    },
  },
  "CRON-JOB": {
    MENU: {
      title: ["MENU", "CRON-JOB"],
    },
    UPDATE: {
      title: [...BASE_URLS["CRON-JOB"], "UPDATE"],
    },
  },
  "HTML-TEMPLATE": {
    MENU: {
      title: ["MENU", "HTML-TEMPLATE"],
    },
  },
  "UPLOADED-FILES": {
    MENU: {
      title: ["MENU", "UPLOADED-FILES"],
    },
  },
};
export default PERMISSIONS;
