<template>
  <div class="content container-fluid page-bg nopadding">
    <div class="forgotbox">
      <div
        class="p-2"
        style="display: flex; flex-direction: column; min-height: 100%"
      >
        <h3 v-if="res && res.passwordExist" class="header pt-1 mb-0">
          Reset Recruiter Password
        </h3>
        <h3 v-else class="header pt-1 mb-0">Create Recruiter Password</h3>
        <div class="logo-div">
          <img
            class="logoSize"
            src="../../../assets/images/newLogo.png"
            alt="Argusoft Logo"
          />
        </div>
        <div v-if="initError">
          <p class="text-danger form-control" style="text-align: center">
            {{ initError.message }}
          </p>
        </div>
        <div v-else>
          <div v-if="!resetDone">
            <form class="form_page_inner">
              <div class="col-12">
                <label class="control-label required" htmlFor="password"
                  >Password
                </label>
                <div style="position: relative">
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control"
                    v-model="data.password"
                    @blur="$v.data.password.$touch()"
                  />
                  <button
                    type="button"
                    class="eye-button"
                    @click="togglePasswordVisibility"
                  >
                    <i
                      :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                    ></i>
                  </button>
                </div>
                <p
                  style="font-size: small; margin-bottom: 0px"
                  class="text-danger"
                  v-if="!$v.data.password.required && $v.data.password.$error"
                >
                  This field is required
                </p>
                <p
                  style="font-size: small; margin-bottom: 0px"
                  class="text-danger"
                  v-if="!$v.data.password.minLength && $v.data.password.$error"
                >
                  Password must have atleast 8 characters.
                </p>
                <p
                  style="font-size: small; margin-bottom: 0px"
                  class="text-danger"
                  v-if="
                    $v.data.password.required &&
                    !$v.data.password.passwordValidator &&
                    $v.data.password.$error
                  "
                >
                  Invalid Password. The password requires an uppercase,
                  lowercase, number and special character.
                </p>
              </div>
              <div class="col-12">
                <label class="control-label required" htmlFor="confirmPassword"
                  >Confirm Password
                </label>
                <div style="position: relative">
                  <input
                    :type="showConfirmPassword ? 'text' : 'password'"
                    class="form-control"
                    v-model="data.confirmPassword"
                    @blur="$v.data.confirmPassword.$touch()"
                  />
                  <button
                    type="button"
                    class="eye-button"
                    @click="toggleConfirmPasswordVisibility"
                  >
                    <i
                      :class="
                        showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'
                      "
                    ></i>
                  </button>
                </div>
                <p
                  style="font-size: small; margin-bottom: 0px"
                  class="text-danger"
                  v-if="
                    !$v.data.confirmPassword.required &&
                    $v.data.confirmPassword.$error
                  "
                >
                  This field is required
                </p>
                <p
                  style="font-size: small; margin-bottom: 0px"
                  class="text-danger"
                  v-if="
                    data.confirmPassword &&
                    !$v.data.confirmPassword.sameAsPassword
                  "
                >
                  Password and Confirm Password should match
                </p>
              </div>

              <div
                style="display: flex; justify-content: center; margin-top: 10px"
              >
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="sitekey"
                  @verify="onVerify"
                  @expired="onExpired"
                >
                </vue-recaptcha>
              </div>

              <div class="text-center">
                <button
                  type="button"
                  class="btn btn-cp ml-2 my-2 ml-0 rounded-pill"
                  style="width: 150px"
                  @click="submit()"
                  :disabled="this.isDisabled"
                >
                  Reset
                </button>
              </div>
            </form>
          </div>
          <div v-else class="col-12 mb-2">
            <p class="text-success form-control" style="text-align: center">
              Password Reset Sucessful
            </p>
          </div>
        </div>
        <div class="col-12 text-left mb-2">
          <router-link to="/login?as=applicant">Go to Login</router-link>
        </div>
        <div class="font-weight-bold mx-3">
          <h3 class="p-0 my-2"></h3>
          <router-link :to="'/'">
            <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
            <span style="padding: 4px">Homepage</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../_api/index";
import VueRecaptcha from "vue-recaptcha";

import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      resetPasswordId: this.$route.params.resetPasswordId,
      data: {
        password: "",
        confirmPassword: "",
      },
      sitekey: "6Lc1jlkUAAAAAFR2xokl-fUNU9lgg3BV__2T2IbN",
      isCaptchaVerified: false,
      linkExpired: true,
      email: "",
      setPassword: false,
      initError: null,
      res: null,
      showPassword: false, // For toggling password visibility
      showConfirmPassword: false,
      resetDone: false,
    };
  },
  methods: {
    async submit() {
      const data = {
        accountPassword: this.data.password,
        resetPasswordId: this.resetPasswordId,
      };
      api
        .resetPassword(data)
        .then((response) => {
          if (response.status == 200) {
            this.resetDone = true;
            this.$store.dispatch(
              "openSnackbar",
              { type: "success", message: "Password updated successfully!" },
              { root: true }
            );
          }
        })
        .catch((err) => {
          this.$store.dispatch(
            "openSnackbar",
            {
              type: "danger",
              message: err.response.message
                ? err.response.message
                : "Something went wrong!",
            },
            { root: true }
          );
        });
    },

    onVerify() {
      this.isCaptchaVerified = true;
    },
    onExpired() {
      this.isCaptchaVerified = false;
    },
    passwordValidator(value) {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~\-\\|`]).{8,}$/.test(
        value
      );
    },
    entityNameValidator(value) {
      return /^[^-\s][a-zA-Z. ]*$/.test(value);
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
  },
  components: {
    VueRecaptcha,
  },
  validations() {
    const data = {
      password: {
        required,
        minLength: minLength(8),
        passwordValidator: this.passwordValidator,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    };
    return { data };
  },
  computed: {
    isDisabled() {
      return this.$v.$invalid || !this.isCaptchaVerified;
    },
  },
  beforeMount() {
    this.initError = null;
    api
      .checkExpiry(this.$route.params.resetPasswordId)
      .then((data) => {
        this.res = data.data;
        this.linkExpired = data.data.isExpired;
        this.email = data.data.email;
      })
      .catch((err) => {
        this.initError = err.response.data;
      });
  },
};
</script>

<style scoped>
.custom-btn {
  padding: 10px 50px 10px 50px;
  border-radius: 25px;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.header {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
}

.logo-div {
  padding-top: 20px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-around;
}
.forgotbox {
  margin: auto;
  max-width: 420px;
  width: 100%;
  max-height: fit-content;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 2px 8px #5b5558;
  border-radius: 25px;
}

.page-bg {
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 768px) {
  .forgotbox {
    width: 90%;
    height: fit-content;
  }
}
.small-height .forgotbox {
  height: fit-content;
}
h3 {
  text-align: center;
  border-bottom: 1px solid #efe6e6;
  padding-bottom: 15px;
}

.content {
  margin: auto !important;
}
::v-deep #maincontent {
  margin-top: 0px !important;
}
input[type="password"] {
  padding-right: 40px;
}

.eye-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  z-index: 9999;
}
.eye-button i {
  font-size: 1.2rem;
  color: #6c757d;
}

.eye-button:hover i {
  color: #495057;
}

.eye-button:focus {
  outline: none;
}
</style>
