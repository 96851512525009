import _applicantProfile from "./index.vue";

export default {
  path: "applicants/:slug",
  component: _applicantProfile,
  name: "applicantprofile",
  meta: {
    title: "Applicant's Profile",
    user: "employee",
  },
};
