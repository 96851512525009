function getForms(state) {
  return state.forms;
}
function getFormVersions(state) {
  return state.formVersions;
}
function getFormFetchError(state) {
  return state.editorFormFetchError;
}
function getEditorActiveFormMaster(state) {
  return state.editorActiveFormMaster;
}
function getEditorActiveFormVersion(state) {
  return state.editorActiveFormVersion;
}
function getIsFormSaved(state) {
  return state.formSaved;
}

export default {
  getForms,
  getFormVersions,
  getFormFetchError,
  getEditorActiveFormMaster,
  getEditorActiveFormVersion,
  getIsFormSaved,
};
