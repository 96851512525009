<template>
  <div
    class="modal fade"
    id="updateRecruitmentLastDateModal"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Change last date for Applications.</h3>
          <button
            type="but</div>ton"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="form-group position-relative p-4">
          <div class="d-flex col">
            <div class="mr-2 control-label m-0 p-0">Recruitment Name:</div>
            <div class="">{{ selectedTitle }}</div>
          </div>
          <label class="col-sm-3 control-label"
            >Last Date
            <span class="text-danger">*</span>
          </label>
          <div class="col-sm-9">
            <div class="input-group">
              <date-picker
                v-model="lastDate"
                :config="config"
                id="datePicker"
                :minDate="new Date()"
                @dp-hide="datePickerRequired"
              >
              </date-picker>
              <span v-if="datePickerFlag" class="valid-feedback alert-danger">
                Last date is required
              </span>
              <span class="input-group-addon" @click="toggleDatePicker">
                <i class="fa fa-calendar m-2" aria-hidden="false"> </i>
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Cancel
          </button>
          <button
            :disabled="!lastDate"
            type="button"
            class="btn btn-cp ml-2"
            data-dismiss="modal"
            @click="updateLastDate(campusId, lastDate)"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import datePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";

/**
 * @memberof module:campusRecruitment
 * @namespace components.update_recruitment_last_date
 */
export default {
  /**
   * @memberof module:campusRecruitment.components.update_recruitment_last_date
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      lastDate: null,
      campusId: null,
      selectedTitle: "",
      config: {
        format: "DD-MM-YYYY",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
      },
      datePickerFlag: false,
    };
  },
  components: {
    datePicker,
  },
  computed: {
    ...mapGetters({
      campusrecruitmentId: "$_campus_recruitment/fetchCampusRecruitmentId",
      campusRecruitments: "$_campus_recruitment/campusRecruitments",
    }),
  },
  watch: {
    /**
     * @memberof module:campusRecruitment.components.update_status_modal
     * @param {Object} data
     * @description Watch - campus recruitment id
     */
    campusrecruitmentId(value) {
      this.campusId = value;
      this.setLastDateAndTitle();
    },
  },
  methods: {
    setLastDateAndTitle() {
      const selectedCampus = this.campusRecruitments.find(
        (campus) => campus.id === this.campusId
      );
      this.lastDate = moment(selectedCampus.last_date);
      this.selectedTitle = selectedCampus.recruitment_session_name;
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description Required validation for date picker
     */
    datePickerRequired() {
      if (!this.lastDate) {
        this.datePickerFlag = true;
      } else {
        this.datePickerFlag = false;
      }
    },
    /**
     * @memberof module:campusRecruitment.components.update_recruitment_last_date_modal
     * @param {Object} data
     * @description Send id to action for updating last date of campus recruitment
     */
    updateLastDate(id, lastDate) {
      let formattedDate = moment(lastDate, "DD-MM-YYYY").format("YYYY-MM-DD");
      let data = { id, last_date: formattedDate };
      this.$store.dispatch(
        "$_campus_recruitment/updateCampusRecruitmentLastDate",
        data
      );
    },
    toggleDatePicker() {
      $("#datePicker").data("DateTimePicker").toggle();
    },
  },
  beforeDestroy() {
    $("#updateRecruitmentLastDateModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
<style scoped>
.valid-feedback {
  top: -15px !important;
  right: 40px !important;
}
</style>
