import reportHome from "./index";
import sessionReport from "./_components/session-report/session-report.vue";
import candidateReport from "./_components/candidate-report/candidate-report.vue";
import PERMISSIONS from "../Common/permissions";
import errorLogs from "../ErrorLogs/index.vue";
import applicantReport from "./_components/applicant-report/applicant-report.vue";

export default {
  path: "report",
  component: reportHome,
  children: [
    {
      path: "sessions",
      component: sessionReport,
      name: "sessionReport",
      meta: {
        title: "Session Reports",
        user: "employee",
        permissions: [PERMISSIONS.REPORT.SESSION.MENU],
        checkAll: true,
      },
    },
    {
      path: "candidates",
      component: candidateReport,
      name: "candidateReport",
      meta: {
        title: "Candidate Reports",
        user: "employee",
        permissions: [PERMISSIONS.REPORT.CANDIDATE.MENU],
        checkAll: true,
      },
    },
    {
      path: "error-logs",
      component: errorLogs,
      name: "errorLogs",
      meta: {
        title: "Error Logs",
        user: "employee",
        permissions: [PERMISSIONS["ERROR-LOG"].MENU],
        checkAll: true,
      },
    },
    {
      path: "applicant-reports",
      component: applicantReport,
      name: "applicantReport",
      meta: {
        title: "applicant Report",
        user: "employee",
        permissions: [PERMISSIONS.REPORT.APPLICANT.MENU],
        checkAll: true,
      },
    },
  ],
};
