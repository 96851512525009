<template>
  <div id="container" class="index-style">
    <router-view></router-view>
  </div>
</template>

<script>
/**
 * @module Cron
 * @description manage cron module
 */
export default {
  /**
   * @memberof module:Cron
   * @namespace components
   * @description components of manage cron module
   */
  created() {},
};
</script>
