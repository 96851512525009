import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  matchedApplicantsData: {},
  newApplicantData: {},
  confirmDuplicateStatus: "FALSE",
};

/**
 * @memberOf module:ResolveDuplicate
 * @namespace store
 * @description central store for resolve_duplicate module
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
