/**
 * @memberOf module:ManageJob.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Job Catrgories and Jobs
 */
const categoriesWithJobs = (state) => state.categoriesWithJobs;

/**
 * @memberOf module:ManageJob.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Job Category
 */
const categoryDataForAddJobModal = (state) => state.categoryDataForAddJobModal;

/**
 * @memberOf module:ManageJob.store.getters
 * @param {Object} state holds store properties for current module
 * @description return updated data with deleted Job Category and Jobs
 */
const deleteItemModal = (state) => state.itemDeleteData;

/**
 * @memberOf module:ManageJob.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Job
 */
const editJobModal = (state) => state.jobDataForEditJobModal;

/**
 * @memberOf module:ManageJob.store.getters
 * @param {Object} state holds store properties for current module
 * @description return id of Job
 */
const openPositionsModal = (state) => state.jobDataForEditJobModal;

/**
 * @memberOf module:ManageJob.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of job positions
 * */
const positionsWithJob = (state) => state.positionsWithJob;

/**
 * @memberOf module:ManageJob.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Job Category
 */
const editCategoryModal = (state) => state.categoryDataForEditCategoryModal;

/**
 * @memberOf module:ManageJob
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  categoriesWithJobs,
  positionsWithJob,
  categoryDataForAddJobModal,
  deleteItemModal,
  editJobModal,
  openPositionsModal,
  editCategoryModal,
};
