import axios from "axios";

/**
 * @memberOf module:ManageReview.api
 * @returns {Promise}
 */
const fetchReviewCategoriesWithReviews = () => {
  return axios.get("reviews");
};

/**
 * @memberOf module:ManageReview.api
 * @param {Object} review Review data to be sent
 * @returns {Promise}
 */
const addReview = (review) => {
  let formData = new FormData();
  let reviewData = JSON.stringify(review);
  formData.append("data", reviewData);
  return axios.post("reviews", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**
 * @memberOf module:ManageReview.api
 * @param {Object} reviewCategory Review Category data to be sent
 * @returns {Promise}
 */
const addReviewCategory = (reviewCategory) => {
  const data = {
    data: reviewCategory,
  };
  return axios.post("reviews/categories", data);
};

/**
 * @memberOf module:ManageReview.api
 * @param {Number} reviewCategoryId Review Category Id data to be sent
 * @returns {Promise}
 */
const deleteReviewCategory = (reviewCategoryId) => {
  return axios.delete("reviews/categories/" + reviewCategoryId);
};

/**
 * @memberOf module:ManageReview.api
 * @param {Number} reviewId Review Id data to be sent
 * @returns {Promise}
 */
const deleteReview = (reviewId) => {
  return axios.delete("reviews/" + reviewId);
};

/**
 * @memberOf module:ManageReview.api
 * @param {Object} editReviewData Review data to be sent
 * @returns {Promise}
 */
const editReview = (editReviewData) => {
  const data = {
    data: editReviewData,
  };
  return axios.put("reviews", data);
};

/**
 * @memberOf module:ManageReview.api
 * @param {Object} editReviewCategoryData Review Category data to be sent
 * @returns {Promise}
 */
const editReviewCategory = (editReviewCategoryData) => {
  const data = {
    data: editReviewCategoryData,
  };
  return axios.put("reviews/categories", data);
};

/**
 * @memberOf module:ManageReview.api
 * @param {Object} updateCategoryWithReviewStatus Review Category data to be sent
 * @returns {Promise}
 */
const updateReviewCategoryWithReviewStatus = (editedReviewCategoryData) => {
  return editReviewCategory(editedReviewCategoryData)
    .then(() => {
      axios
        .get("reviews/by/category/" + editedReviewCategoryData.id)
        .then((resp) => {
          const promises = [];
          resp.data.forEach((element) => {
            element.status = editedReviewCategoryData.status;
            delete element.createdAt;
            delete element.updatedAt;
            delete element.slug;

            promises.push(editReview(element));
          });
          Promise.all(promises)
            .then((result) => result)
            .catch((error) => error);
        })
        .catch((err) => err);
    })
    .catch((error) => error);
};

/**
 * @memberOf module:ManageReview.api
 * @param {Object} editReviewStatus Review data to be sent
 * @returns {Promise}
 */
const editReviewStatus = (editedReviewData) => {
  return editReview(editedReviewData)
    .then(() => {
      axios
        .get("reviews/by/category/" + editedReviewData.reviewCategoryId)
        .then((resp) => {
          const length = resp.data.length;
          let statusCount = 0;
          resp.data.forEach((element) => {
            if (element.status === editedReviewData.status) {
              ++statusCount;
            }
          });
          if (length === statusCount) {
            axios
              .get("reviews/categories/" + editedReviewData.reviewCategoryId)
              .then((getCatResponse) => {
                let catInfo = { ...getCatResponse.data };
                delete catInfo.updatedAt;
                delete catInfo.createdAt;
                catInfo.status = editedReviewData.status;

                editReviewCategory(catInfo)
                  .then((result) => result)
                  .catch((error) => error);
              })
              .catch((error) => error);
          } else {
            axios
              .get("reviews/categories/" + editedReviewData.reviewCategoryId)
              .then((getCatElseResponse) => {
                let catInfo = { ...getCatElseResponse.data };
                delete catInfo.updatedAt;
                delete catInfo.createdAt;
                catInfo.status = "active";

                editReviewCategory(catInfo)
                  .then((result) => result)
                  .catch((error) => error);
              })
              .catch((error) => error);
          }
        })
        .catch((error) => error);
    })
    .catch((error) => error);
};

/**
 * @memberOf module:ManageReview
 * @namespace api
 * @description api for backend interactions
 */
export default {
  fetchReviewCategoriesWithReviews,
  addReview,
  addReviewCategory,
  deleteReviewCategory,
  deleteReview,
  editReview,
  editReviewCategory,
  updateReviewCategoryWithReviewStatus,
  editReviewStatus,
};
