<template>
  <div
    class="modal fade"
    id="filter-data-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Filter Data</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group col-lg-12 col-md-12 col-sm-12">
              <label class="control-label m-r15">Applied On</label>
              <div class="col-lg-12 col-md-12 col-sm-12 nopadding text-wrap">
                <label for="all" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="all"
                    value=""
                    v-model="filterData.filterType"
                  />
                  All
                </label>
                <label for="lastWeek" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="lastWeek"
                    value="Last One Week"
                    v-model="filterData.filterType"
                  />
                  Last One Week
                </label>
                <label for="lastMonth" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="lastMonth"
                    value="Last One Month"
                    v-model="filterData.filterType"
                  />
                  Last One Month
                </label>
                <label for="lastThreeMonth" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="lastThreeMonth"
                    value="Last Three Months"
                    v-model="filterData.filterType"
                  />
                  Last Three Months
                </label>
                <label for="lastSixMonths" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="lastSixMonths"
                    value="Last Six Months"
                    v-model="filterData.filterType"
                  />
                  Last Six Months
                </label>
                <label for="lastYear" class="radio-inline m-r15">
                  <input
                    type="radio"
                    id="lastYear"
                    value="Last One Year"
                    v-model="filterData.filterType"
                  />
                  Last One Year
                </label>
                <label for="custom" class="radio-inline m-r10">
                  <input
                    type="radio"
                    id="Custom"
                    value="Custom"
                    v-model="filterData.filterType"
                  />
                  Custom
                </label>
              </div>
            </div>
            <div v-if="filterData.filterType === 'Custom'">
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="control-label">From Date</label>
                <date-picker
                  v-model="filterData.fromDate"
                  id="fromDate"
                  :config="config"
                  @dp-hide="dateRequired('fromDate')"
                ></date-picker>
                <p v-if="fromDateFlag" class="alert alert-danger">
                  This field is required
                </p>
              </div>
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="control-label">To Date</label>
                <date-picker
                  v-model="filterData.toDate"
                  id="toDate"
                  :config="config"
                ></date-picker>
              </div>
              <p v-if="!isDateValid" class="alert alert-danger">
                From date should not be greater than To date
              </p>
            </div>
            <div>
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="control-label m-r15">Duplicates</label>
                <div class="col-lg-12 col-md-12 col-sm-12 nopadding text-wrap">
                  <label class="radio-inline m-r10">
                    <input
                      type="radio"
                      value=""
                      v-model="moreFilters.duplicateType"
                    />
                    All
                  </label>
                  <label class="radio-inline m-r10">
                    <input
                      type="radio"
                      value="resolved"
                      v-model="moreFilters.duplicateType"
                    />
                    Resolved
                  </label>
                  <label class="radio-inline m-r10">
                    <input
                      type="radio"
                      value="duplicate"
                      v-model="moreFilters.duplicateType"
                    />
                    Duplicates
                  </label>
                </div>
              </div>
              <div class="form-group col-lg-12 col-md-12 col-sm-12">
                <label class="control-label m-r15">Campus</label>
                <div class="col-lg-12 col-md-12 col-sm-12 nopadding text-wrap">
                  <label class="radio-inline m-r10">
                    <input
                      type="radio"
                      value=""
                      v-model="moreFilters.campusType"
                    />
                    ALl
                  </label>
                  <label class="radio-inline m-r10">
                    <input
                      type="radio"
                      value="offcampus"
                      v-model="moreFilters.campusType"
                    />
                    Off-campus
                  </label>
                  <label class="radio-inline m-r10">
                    <input
                      type="radio"
                      value="oncampus"
                      v-model="moreFilters.campusType"
                    />
                    On-campus
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="disableButton"
            type="button"
            class="btn btn-cp ml-2"
            @click="onApplyFilter"
            data-dismiss="modal"
          >
            Apply
          </button>
          <button
            type="button"
            class="btn btn-cp ml-2"
            data-dismiss="modal"
            @click="onResetFilter"
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import datePickerIcons from "../../Common/_utils/date-picker-icons";

/**
 * @memberOf module:ApplicantsData
 * @namespace components.filter_data_modal
 * @description Filter data modal component
 */
export default {
  data() {
    return {
      filterData: {
        filterType: "",
        fromDate: null,
        toDate: null,
      },
      moreFilters: {
        duplicateType: "",
        campusType: "",
      },
      fromDateFlag: false,
      toDateFlag: false,
      config: {
        format: "DD MMMM YYYY",
        maxDate: new Date(),
        showClear: true,
        showClose: true,
        icons: datePickerIcons,
      },
    };
  },
  components: {
    datePicker,
  },
  validations() {
    const filterData = {
      filterType: {
        required,
      },
    };
    if (this.filterData.filterType === "Custom") {
      filterData.fromDate = {
        required,
      };
    }
    return {
      filterData,
    };
  },
  computed: {
    disableButton() {
      if (
        this.filterData.filterType ||
        this.moreFilters.duplicateType ||
        this.moreFilters.campusType
      ) {
        if (
          this.filterData.filterType &&
          this.filterData.filterType == "Custom"
        ) {
          if (this.filterData.fromDate) {
            return !this.isDateValid;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
      return true;
    },
    isDateValid() {
      if (this.filterData.fromDate && this.filterData.toDate) {
        return new Date(this.filterData.fromDate) >
          new Date(this.filterData.toDate)
          ? false
          : true;
      }
      return true;
    },
    ...mapGetters({
      filterOptions: "$_applicants_data/fetchFilterData",
    }),
  },
  methods: {
    /**
     * @memberOf module:ApplicantsData.components.filter_data_modal
     * @description Validation for from date and to date
     */
    dateRequired(dateType) {
      if (dateType === "fromDate") {
        this.fromDateFlag = !this.filterData.fromDate ? true : false;
      } else {
        this.toDateFlag = !this.filterData.toDate ? true : false;
      }
    },
    /**
     * @memberOf module:ApplicantsData.components.filter_data_modal
     * @description Applies filter to the data
     */
    onApplyFilter() {
      let days;
      let curDate = new Date();
      switch (this.filterData.filterType) {
        case "Last One Week":
          days = 7;
          this.filterData.toDate = curDate;
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last One Month":
          days = 30;
          this.filterData.toDate = curDate;
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last Three Months":
          days = 90;
          this.filterData.toDate = curDate;
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last Six Months":
          days = 180;
          this.filterData.toDate = curDate;
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Last One Year":
          days = 365;
          this.filterData.toDate = curDate;
          this.filterData.fromDate = new Date(
            curDate.getTime() - days * 24 * 60 * 60 * 1000
          );
          break;
        case "Custom":
          if (this.filterData.toDate == null) {
            this.filterData.toDate = null;
          } else {
            this.filterData.toDate = new Date(this.filterData.toDate);
          }
          this.filterData.fromDate = new Date(this.filterData.fromDate);
          break;
        case "":
          this.filterData.fromDate = null;
          this.filterData.toDate = null;
          break;
      }
      this.$store.dispatch("$_applicants_data/storeFilterData", {
        applicationDate: this.filterData,
        moreFilters: this.moreFilters,
      });
      this.filterData = {
        filterType: "",
        fromDate: null,
        toDate: null,
      };
      this.moreFilters = {
        duplicateType: "",
        campusType: "",
      };
    },
    setFilterData() {
      if (this.filterOptions) {
        if (this.filterOptions.applicationDate) {
          if (this.filterOptions.applicationDate.filterType === "Custom") {
            if (this.filterOptions.applicationDate.toDate == null) {
              this.filterData = {
                filterType: this.filterOptions.applicationDate.filterType,
                fromDate: new Date(this.filterOptions.applicationDate.fromDate),
                toDate: null,
              };
            } else {
              this.filterData = {
                filterType: this.filterOptions.applicationDate.filterType,
                fromDate: new Date(this.filterOptions.applicationDate.fromDate),
                toDate: new Date(this.filterOptions.applicationDate.toDate),
              };
            }
          } else {
            this.filterData = {
              filterType: this.filterOptions.applicationDate.filterType,
              fromDate: null,
              toDate: null,
            };
          }
        } else {
          this.filterData = {
            filterType: "",
            fromDate: null,
            toDate: null,
          };
        }
        if (this.filterOptions.moreFilters) {
          this.moreFilters = this.filterOptions.moreFilters;
        } else {
          this.moreFilters = {
            duplicateType: "",
            campusType: "",
          };
        }
      } else {
        this.filterData = {
          filterType: "",
          fromDate: null,
          toDate: null,
        };
        this.moreFilters = {
          duplicateType: "",
          campusType: "",
        };
      }
    },
    onResetFilter() {
      this.$store.dispatch("$_applicants_data/resetFilterData");
    },
  },
  watch: {
    filterOptions() {
      this.setFilterData();
    },
  },
  mounted() {
    this.setFilterData();
  },
  beforeDestroy() {
    $("#filter-data-modal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
