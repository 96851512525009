<template>
  <div>
    <div class="content">
      <div class="page-header">
        <common-header :data="['home', 'manage', 'reviews']">
          <div>
            <a
              v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
              class="btn btn-cp"
              href="#"
              role="button"
              data-toggle="modal"
              data-target="#modal"
              data-backdrop="static"
              data-keyboard="false"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>Add Category</a
            >
          </div>
        </common-header>
      </div>
      <div class="container-fluid">
        <div v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })">
          <review-category-list-element
            v-for="(reviewCategory, index) in reviewCategoriesWithReviews"
            :key="reviewCategory.id"
            :reviewCategory="reviewCategory"
            :is-opened="index === 0"
          ></review-category-list-element>
        </div>
      </div>
    </div>
    <div
      v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
      class="modal fade"
      id="modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content m-t100">
          <div class="modal-header">
            <h3 class="modal-title" id="myModalLabel">Category Details</h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="resetValidations($v)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="content-form">
              <div class="form-group position-relative">
                <label class="control-label"
                  >Category <span class="text-danger"> *</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  name="review-category-name"
                  v-validate="'required|regex:^[^-\s][a-zA-Z ]*$'"
                  data-vv-as="Review Category Name"
                  data-vv-validate-on="blur|input"
                  :class="{ invalid: errors.first('review-category-name') }"
                  v-model="data.review_category_name"
                  maxlength="30"
                />
                <span
                  v-if="errors.first('review-category-name')"
                  class="valid-feedback alert-danger"
                  >{{ errors.first("review-category-name") }}</span
                >
              </div>
              <div class="form-group position-relative">
                <label class="control-label"
                  >Category Description
                  <span class="text-danger"> *</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  name="review-category-description"
                  v-validate="'required'"
                  data-vv-as="Review Category Description"
                  data-vv-validate-on="blur|input"
                  v-model="data.review_category_description"
                  :class="{
                    invalid: errors.first('review-category-description'),
                  }"
                />
                <span
                  v-if="errors.first('review-category-description')"
                  class="valid-feedback alert-danger"
                  >{{ errors.first("review-category-description") }}</span
                >
              </div>
            </form>
          </div>
          <div class="modal-footer text-center">
            <button
              :disabled="$v.data.$invalid"
              type="button"
              class="btn btn-cp"
              @click="
                addReviewCategory();
                resetValidations($v);
              "
              data-dismiss="modal"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>

    <add-review-modal></add-review-modal>
    <delete-modal></delete-modal>
    <edit-review-modal></edit-review-modal>
    <edit-review-category-modal></edit-review-category-modal>
  </div>
</template>

<script>
import ReviewCategoryListElement from "./review-category-list-element.vue";
import AddReviewModal from "./add-review-modal.vue";
import DeleteModal from "./delete-modal.vue";
import EditReviewModal from "./edit-review-modal.vue";
import EditReviewCategoryModal from "./edit-review-category-modal.vue";
import { mapGetters } from "vuex";
import { required, maxLength } from "vuelidate/lib/validators";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

export default {
  data() {
    return {
      data: {
        review_category_name: "",
        review_category_description: "",
        status: "active",
      },
    };
  },

  validations() {
    const data = {
      review_category_name: {
        required,
        maxLength: maxLength(30),
        reviewCategoryNameValidator: this.reviewCategoryNameValidator,
      },
      review_category_description: {
        required,
      },
    };
    return {
      data,
    };
  },
  components: {
    "review-category-list-element": ReviewCategoryListElement,
    "add-review-modal": AddReviewModal,
    "delete-modal": DeleteModal,
    "edit-review-modal": EditReviewModal,
    "edit-review-category-modal": EditReviewCategoryModal,
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    resetValidations(v) {
      v.$reset();
    },

    addReviewCategory() {
      this.$store.dispatch("$_manage_reviews/addReviewCategory", this.data);
      this.data = this.resetData();
      $("#modal").modal("hide");
      $(".modal-backdrop").hide();
    },

    reviewCategoryNameValidator(value) {
      return /^[^-\s][a-zA-Z ]*$/.test(value);
    },

    resetData() {
      return {
        review_category_name: "",
        review_category_description: "",
        status: "active",
      };
    },
    getReviews() {
      if (!hasAccess({ permissions: [this.BASE_PERMISSION.MENU] })) return;
      this.$store.dispatch("$_manage_reviews/fetchReviewCategoriesWithReviews");
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.REVIEW;
    },
    ...mapGetters({
      reviewCategoriesWithReviews:
        "$_manage_reviews/reviewCategoriesWithReviews",
    }),
  },

  created() {
    this.getReviews();
  },

  mounted() {
    $("#modal").on("hidden.bs.modal", () => {
      this.data = this.resetData();
    });
  },
  beforeDestroy() {
    $("#modal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
  padding: 10px 35px;
}

@media (max-width: 576px) {
  .container {
    padding: 10px 30px !important;
  }
}
</style>
