import Vue from "vue";
/**
 * @memberof module:ApplicantMaster.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set logged in applicant
 */
const SET_LOGGED_IN_APPLICANT = (state, data) => {
  state.loggedInApplicant = data;
};

const SET_APPLICANT_PDF_URL = (state, url) => {
  state.resumeLink = url;
};

/**
 * @memberof module:ApplicantMaster.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set applicant applications
 */
const SET_APPLICANT_APPLICATIONS = (state, data) => {
  state.applications = data;
};

/**
 * @memberof module:ApplicantMaster.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set can apply jobs
 */
const SET_CAN_APPLY_JOBS = (state, data) => {
  state.canApplyJobs = data;
};

/**
 * @memberof module:ApplicantMaster.store.mutations
 * @param {Object} state
 * @param {Object} data
 * @description Set applicant link requests
 */
const SET_APPLICANT_LINK_REQUESTS = (state, data) => {
  state.linkRequests = data;
};

/**
 * @memberof module:ApplicantMaster.store.mutations
 * @param {Object} state
 * @param {Object} formSubmitStatus
 * @description commits data of Job application to get Application Id
 */
const APPLICANT_DATA_SUBMITTED = (state, formSubmitStatus) => {
  state.formSubmitStatus = formSubmitStatus;
};

/**
 * @memberof module:ApplicantMaster
 * @namespace store.mutations
 * @description mutations for applicantMaster module
 */

const SET_JOB_IN_APPLICANT = (state, data) => {
  state.loggedInApplicant.jobDetails.push(data);
};

const UPDATE_JOB_IN_APPLICANT = (state, data) => {
  const jobIndex = state.loggedInApplicant.jobDetails.findIndex(
    (job) => job.id === data.id
  );
  Vue.set(state.loggedInApplicant.jobDetails, jobIndex, data);
};

const DELETE_JOB_IN_APPLICANT = (state, id) => {
  const jobIndex = state.loggedInApplicant.jobDetails.findIndex(
    (job) => job.id === id
  );
  Vue.delete(state.loggedInApplicant.jobDetails, jobIndex);
};

const SET_PROJECT_IN_APPLICANT = (state, data) => {
  state.loggedInApplicant.projectDetails.push(data);
};

const UPDATE_PROJECT_IN_APPLICANT = (state, data) => {
  const projectIndex = state.loggedInApplicant.projectDetails.findIndex(
    (project) => project.id === data.id
  );
  Vue.set(state.loggedInApplicant.projectDetails, projectIndex, data);
};
const DELETE_PROJECT_IN_APPLICANT = (state, id) => {
  const projectIndex = state.loggedInApplicant.projectDetails.findIndex(
    (project) => project.id === id
  );
  Vue.delete(state.loggedInApplicant.projectDetails, projectIndex);
};

const SET_COURSE_IN_APPLICANT = (state, data) => {
  state.loggedInApplicant.courseDetails.push(data);
};

const UPDATE_COURSE_IN_APPLICANT = (state, data) => {
  const courseIndex = state.loggedInApplicant.courseDetails.findIndex(
    (course) => course.id === data.id
  );
  Vue.set(state.loggedInApplicant.courseDetails, courseIndex, data);
};

const DELETE_COURSE_IN_APPLICANT = (state, id) => {
  const courseIndex = state.loggedInApplicant.courseDetails.findIndex(
    (course) => course.id === id
  );
  Vue.delete(state.loggedInApplicant.courseDetails, courseIndex);
};

const SET_APPLICANT_10TH_MARKSHEET_LINK = (state, url) => {
  state.sscMarksheetLink = url;
};

const SET_APPLICANT_12TH_MARKSHEET_LINK = (state, url) => {
  state.hsMarksheetLink = url;
};

const SET_APPLICANT_PROFILE_PIC = (state, data) => {
  state.profilePic = data;
};

const LOGOUT_APPLICANT = (state) => {
  state.loggedInApplicant = null;
  state.applications = [];
  state.linkRequests = [];
  state.canApplyJobs = [];
  state.resumeLink = null;
  state.sscMarksheetLink = null;
  state.hsMarksheetLink = null;
  state.formSubmitStatus = {
    status: "INITIAL",
    uid: null,
  };
};

const SET_APPLICANT_APPLICATION = (state, data) => {
  state.application = data;
};

export default {
  SET_LOGGED_IN_APPLICANT,
  SET_APPLICANT_APPLICATIONS,
  SET_APPLICANT_LINK_REQUESTS,
  SET_APPLICANT_PDF_URL,
  APPLICANT_DATA_SUBMITTED,
  SET_JOB_IN_APPLICANT,
  UPDATE_JOB_IN_APPLICANT,
  DELETE_JOB_IN_APPLICANT,
  SET_PROJECT_IN_APPLICANT,
  UPDATE_PROJECT_IN_APPLICANT,
  DELETE_PROJECT_IN_APPLICANT,
  SET_COURSE_IN_APPLICANT,
  UPDATE_COURSE_IN_APPLICANT,
  DELETE_COURSE_IN_APPLICANT,
  SET_APPLICANT_10TH_MARKSHEET_LINK,
  SET_APPLICANT_12TH_MARKSHEET_LINK,
  SET_CAN_APPLY_JOBS,
  LOGOUT_APPLICANT,
  SET_APPLICANT_APPLICATION,
  SET_APPLICANT_PROFILE_PIC,
};
