import Vue from "vue";
import moment from "moment";
/**
 * @param {string} value
 * @description dateFormat - Returns a formatted date
 */
Vue.filter("dateFormat", (value) => {
  return moment(value).format("D MMM YYYY");
});

/**
 * @param {string} value
 * @description dateFormat - Returns a formatted date and time
 */
Vue.filter("dateTimeFormat", (value) => {
  return moment(value).format("D MMM YYYY, h:mm A");
});
