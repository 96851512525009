<template>
  <div class="content">
    <common-header :data="['home', 'report', 'candidateReports']">
      <div
        class="d-flex"
        v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
      >
        <button
          class="btn btn-cp mx-1"
          type="button"
          v-tooltip="'Refresh'"
          @click="setDataSource"
        >
          <i class="fa fa-repeat" aria-hidden="true"></i>
        </button>
        <button
          class="btn btn-cp btn-filter ml-1"
          type="button"
          data-toggle="collapse"
          data-target="#collapseFilters"
          aria-expanded="false"
          aria-controls="collapseFilters"
          ref="filterBoxBtn"
        >
          <em class="fa fa-filter"></em>
          <span v-if="!isFilterOptionsEmpty" class="filter-indicator"></span>
        </button>
      </div>
    </common-header>

    <div class="container-fluid">
      <div
        v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
        class="mb-2"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex px-0 my-1">
            <div class="btn-group dropup">
              <button
                class="btn dropdown-toggle select-cp mr-1"
                type="button"
                data-toggle="dropdown"
                id="dropdownMenuButtonli"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-tasks" aria-hidden="true"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButtonli">
                <template
                  v-if="hasAccess({ permissions: [PERMISSIONS.MAIL.CREATE] })"
                  ><li class="divider"></li>
                  <li class="dropdown-header">Campaign</li>
                  <li class="dropdown-item">
                    <a @click="RunCampaign">Run Campaign</a>
                  </li></template
                >
                <template>
                  <li class="dropdown-header">Download</li>
                  <li class="dropdown-item" @click="exportToCSV">Download</li>
                </template>
              </ul>
            </div>
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Search by name, email"
                v-model="searchedText"
                @keypress.enter="search()"
              />
              <div class="input-group-append">
                <button
                  v-if="searchedText"
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="
                    searchedText = '';
                    search();
                  "
                >
                  <i class="fa fa-close" aria-hidden="false"></i>
                </button>
                <button class="btn btn-cp" type="button" @click="search()">
                  <i class="fa fa-search p-0" aria-hidden="false"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            class="px-0 d-flex justify-content-between justify-content-md-end my-1 ml-auto"
          >
            <p
              class="alert alert-info alert-sm mr-2"
              v-if="selectedRowCount > 0"
            >
              <span class="">{{ selectedRowCount }}</span>
            </p>
            <button
              class="btn btn-cp"
              v-if="
                selectedRowCount > 0 &&
                (rowCountOnCurrentPage === -1 ||
                  selectedRowCount < rowCountOnCurrentPage)
              "
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-minus-square" aria-hidden="true"></i> Select all
            </button>
            <button
              class="btn btn-cp"
              v-else-if="selectedRowCount && rowCountOnCurrentPage !== -1"
              @click="selectAllOnCurrentPage"
            >
              <i class="fa fa-check-square" aria-hidden="true"></i> Deselect all
            </button>
            <button class="btn btn-cp" v-else @click="selectAllOnCurrentPage">
              <i class="fa fa-square" aria-hidden="true"></i> Select all
            </button>
            <select
              v-on:change="onPageSizeChanged()"
              id="page-size"
              class="select-cp form-control ml-1 cst-width"
            >
              <option value="100" selected="">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
              <option value="2000">2000</option>
            </select>
          </div>
        </div>
      </div>
      <div class="my-2 collapse" id="collapseFilters">
        <div class="card card-body">
          <filterComponent @filters="applyFilters"></filterComponent>
        </div>
      </div>
      <div v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })">
        <ag-grid-vue
          class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
          rowSelection="multiple"
          @rowClicked="onRowClicked"
          @selection-changed="onSelectionChanged"
          :tooltipShowDelay="tooltipShowDelay"
          :tooltipHideDelay="tooltipHideDelay"
          :gridOptions="gridData"
          @gridReady="onGridReady"
          :key="paginationSize"
        ></ag-grid-vue>
      </div>
      <b-modal
        ref="candidateModal"
        title="Candidate Details"
        size="xl"
        :hide-footer="true"
      >
        <EventModal
          :isEdit="true"
          v-on:generateRound="generateRound($event)"
          v-on:updateEvent="updateEvent($event)"
          :editEventApplicant="editEventApplicantData"
        />
      </b-modal>
      <common-modal
        :id="commonModalData.id"
        :modal-title="commonModalData.title"
        :modal-body="commonModalData.body"
        :show-cancel="commonModalData.showCancel"
        :modal-color="commonModalData.color"
        :modal-size-class="commonModalData.sizeClass"
        @actionPerformed="emitOnCurrent"
      />
    </div>
  </div>
</template>

<script>
import CommonModal from "../../../Common/_components/modal.vue";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import getFilterByType from "../../../Common/_utils/get-filter-by-type";
import filterComponent from "./candidate-filter.vue";
import * as xlsx from "xlsx";
import EventModal from "../../../Applications/_components/event-modal.vue";
import PERMISSIONS from "../../../Common/permissions";
import { hasAccess } from "../../../User/_utils/actions";

export default {
  name: "candidate-report",
  components: {
    filterComponent,
    EventModal,
    "ag-grid-vue": AgGridVue,
    CommonModal,
  },
  data() {
    return {
      rowCountOnCurrentPage: -1,
      gridData: null,
      rowData: [],
      paginationSize: 100,
      applicants: [],
      searchedText: "",
      filterParams: [],
      editEventApplicantData: {},
      tooltipShowDelay: null,
      tooltipHideDelay: null,
      selectedApplicants: [],
      selectedRowCount: 0,
      columnDefs: [
        {
          headerName: "Name",
          field: "name",
          filter: true,
          floatingFilter: false,
          checkboxSelection: true,
          suppressHeaderMenuButton: true,
          minWidth: 200,
          valueGetter: function (params) {
            if (!params.data) return;
            return params.data.first_name + " " + params.data.last_name;
          },
        },
        {
          headerName: "Position",
          field: "position",
          filter: true,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          sortable: false,
          minWidth: 250,
        },
        {
          headerName: "Exp",
          field: "curr_experience",
          filter: true,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          minWidth: 100,
          maxWidth: 100,
          headerTooltip: "Experience (in Yrs.)",
        },
        {
          headerName: "R. Exp",
          field: "curr_relevant_experience",
          filter: true,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          minWidth: 100,
          maxWidth: 100,
          headerTooltip: "Relevant Experience (in Yrs.)",
        },
        {
          headerName: "C. CTC",
          field: "current_ctc",
          filter: true,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          minWidth: 100,
          maxWidth: 100,
          headerTooltip: "Current C.T.C (in L.P.A)",
        },
        {
          headerName: "E. CTC",
          field: "expected_ctc",
          filter: true,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          minWidth: 100,
          maxWidth: 100,
          headerTooltip: "Expected C.T.C (in L.P.A)",
        },
        {
          headerName: "N. Pd",
          field: "notice_period",
          filter: true,
          floatingFilter: false,
          minWidth: 100,
          maxWidth: 100,
          headerTooltip: "Notice Period (in Days)",
        },
        {
          headerName: "Skills",
          field: "skills",
          filter: true,
          minWidth: 350,
          sortable: false,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          cellRenderer: function (params) {
            const value = params.value || "";
            const maxLength = 3;
            let displayValue =
              value.length > maxLength
                ? value.slice(0, maxLength).join(", ") + "  ..."
                : value;
            const tooltipField =
              value.length > maxLength ? "skills" : undefined;
            return `<div class="custom-tooltip" title="${
              tooltipField ? params.data.skills : ""
            }">
              <p><span>${displayValue}</span></p>
            </div>`;
          },
        },
        {
          headerName: "App Status",
          field: "application_status",
          sortable: true,
          width: 130,
          minWidth: 130,
          maxWidth: 130,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          headerTooltip: "Application Status",
          cellStyle: function (params) {
            const status = params.value;
            const colorMap = {
              "Aptitude Scheduled": "#7E5109",
              Differed: "#FF0000",
              Held: "#993300",
              "Interview Cancelled": "#663300",
              "Interview Passed": "#66CC33",
              "Interview Scheduled": "#512E5F",
              Joined: "#4BB543",
              "Not Appeared": "#330033",
              Processed: "#0066FF",
              Received: "#3333CC",
              "Review Cancelled": "#003300",
              "Review Differed": "#990033",
              "Review Processed": "#333399",
              Shortlisted: "#0099CC",
              "Talent Pool": "#A23CC4",
              "Written Test Failed": "#FF0033",
              "Written Test Passed": "#336699",
              "Written Test Scheduled": "#17202A",
            };

            if (Object.prototype.hasOwnProperty.call(colorMap, status)) {
              return { color: colorMap[status] };
            }
            return null;
          },
        },
        {
          headerName: "Age",
          field: "age",
          filter: true,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          minWidth: 100,
          maxWidth: 100,
          headerTooltip: "Age (in Yrs.)",
          cellRenderer: this.dobCellRenderer,
        },
        {
          headerName: "Refs",
          field: "references",
          filter: true,
          floatingFilter: false,
          suppressHeaderMenuButton: true,
          sortable: false,
          minWidth: 250,
          cellRenderer: function (params) {
            if (!params.data || !params.data.references) return "";
            const references = params.value || {};
            const ref1 = references.ref1 ? references.ref1.name : "";
            const ref2 = references.ref2 ? references.ref2.name : "";

            let referenceNames = "";

            if (ref1 && ref2) {
              referenceNames = `${ref1}, ${ref2}`;
            } else if (ref1 || ref2) {
              referenceNames = ref1 || ref2;
            }

            if (referenceNames !== "") {
              return `<div style="white-space: pre-wrap;">${referenceNames}</div>`;
            } else {
              return "";
            }
          },
        },
      ],
      filterOpts: [],
    };
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.REPORT.CANDIDATE;
    },
    isFilterOptionsEmpty() {
      return JSON.stringify(this.filterOpts) == "[]";
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    updateEvent() {},
    generateRound() {},
    dobCellRenderer(params) {
      const age = params.value;
      const dob = params.data ? params.data.dob : null;
      const ageText = age ? `${age}` : "";
      if (dob) {
        return `
      <div title="${dob}">
        ${ageText}
      </div>
    `;
      } else {
        return ageText;
      }
    },
    isAtleastOneSelected() {
      if (this.selectedApplicants.length > 0) {
        return true;
      } else {
        this.openDialog(
          "commonModal",
          "Invalid action",
          "Please select atleast one applicant",
          false,
          "danger",
          "modal-m"
        );
        return false;
      }
    },
    RunCampaign() {
      if (!this.hasAccess({ permissions: [this.PERMISSIONS.MAIL.CREATE] }))
        return;
      if (this.isAtleastOneSelected()) {
        this.$store.dispatch("$_applicants_data/RunCampaign", {
          data: this.selectedApplicants,
          redirect: this.$route.fullPath,
        });
      }
    },
    selectAllOnCurrentPage() {
      const result = this.rowCountOnCurrentPage !== this.selectedRowCount;
      this.selectedRowCount = 0;
      this.gridData.api.forEachNode((node) => {
        node.setSelected(result);
        this.selectedRowCount++;
      });
      this.rowCountOnCurrentPage = this.selectedRowCount;
    },
    setDataSource(callback) {
      let dataSource = {
        searchText: this.searchedText,
        filterParams: this.filterParams,
        applicants: this.applicants,
        rowCount: null,
        rowCountPagination: 0,
        rowCountFiltering: 0,
        _self: this,
        getRows: function (params) {
          let includeAll = this._self.includeAll;
          let queryParams = {
            startRow: params.startRow,
            limit: params.endRow - params.startRow,
            includeAll: includeAll,
          };
          this.rowCountPagination = 0;
          if (params.startRow == 0) {
            this.rowCountFiltering = 0;
          }
          let queryObj = {};
          if (params.sortModel) {
            queryObj.sort = params.sortModel[0];
          }
          if (this.searchText && this.searchText.length > 0) {
            queryObj.searchedText = this.searchText;
          }
          if (this.filterParams && this.filterParams.length > 0) {
            queryObj.filterParams = this.filterParams;
          }
          for (const key in params.filterModel) {
            if (Object.prototype.hasOwnProperty.call(params.filterModel, key)) {
              const queryData = getFilterByType(params.filterModel[key]);
              queryObj[key] = queryData;
              break;
            }
          }

          if (
            Object.keys(params.filterModel).length != 0 ||
            Object.keys(params.sortModel).length != 0 ||
            (this.searchText && this.searchText.length > 0) ||
            (this.filterParams && this.filterParams.length > 0)
          ) {
            this.rowCountPagination = 0;
            if (params.startRow == 0) {
              this.rowCountFiltering = 0;
            }
            axios
              .post("/report/candidate/filterData", queryObj, {
                params: queryParams,
              })
              .then(
                (data) => {
                  this.applicants = data.data.rows.map((c) => ({ ...c }));
                  this.rowCountFiltering += data.data.rows.length;
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountFiltering,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data.rows, lastRow);
                  if (typeof callback === "function") {
                    callback(this.applicants);
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          } else {
            axios
              .post("/report/candidate/filterData", {}, { params: queryParams })
              .then(
                (data) => {
                  this.applicants = data.data.rows.map((c) => ({ ...c }));
                  this.rowCountFiltering += data.data.rows.length;
                  let lastRow = this._self.getLastRowValue(
                    this.rowCountFiltering,
                    params.endRow,
                    -1
                  );
                  params.successCallback(data.data.rows, lastRow);
                  if (typeof callback === "function") {
                    callback(this.applicants);
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        },
      };
      this.gridData.api.setGridOption("datasource", dataSource);
    },
    onSelectionChanged() {
      this.selectedApplicants = this.gridData.api.getSelectedRows();
      this.selectedRowCount = this.selectedApplicants.length;
    },
    getLastRowValue(rowCount, endRow, defaultValue = -1) {
      if (rowCount < endRow) return rowCount;
      return defaultValue;
    },

    onPageSizeChanged() {
      const rowCount = Number($("#page-size option:selected").val());
      this.gridData.paginationPageSize = rowCount;
      this.gridData.cacheBlockSize = rowCount;
      this.paginationSize = rowCount;
    },

    applyFilters(filterOpts) {
      this.filterOpts = filterOpts;
      this.startRow = 0;
      this.filterParams = [...filterOpts];
      this.setDataSource();
      this.$refs.filterBoxBtn.click();
    },

    search() {
      this.setDataSource();
    },

    exportToCSV() {
      const exportFile = "candidateData.xlsx";
      const handleApplicantsUpdated = (applicants) => {
        const candidateData = xlsx.utils.json_to_sheet(applicants);
        const candidateBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(
          candidateBook,
          candidateData,
          "candidateData"
        );
        xlsx.writeFile(candidateBook, exportFile);
      };
      this.setDataSource(handleApplicantsUpdated);
    },

    onRowClicked(event) {
      const rowData = event.data;
      this.showCandidateDetail(rowData);
    },
    showCandidateDetail(candidate) {
      this.$refs["candidateModal"].show();
      this.$nextTick(() => {
        this.editEventApplicantData = { ...candidate };
      });
    },
    onGridReady(params) {
      this.gridData.api = params.api;
      this.setDataSource();
    },
    configGridOptions() {
      this.gridData = {
        suppressPropertyNamesCheck: true,
        suppressRowClickSelection: true,
        enableServerSideFilter: true,
        enableCellTextSelection: true,
        enableColResize: true,
        columnDefs: this.columnDefs,
        rowModelType: "infinite",
        rowSelection: "multiple",
        infiniteInitialRowCount: 0,
        defaultColDef: {
          flex: 1,
          resizable: true,
          sortable: true,
          floatingFilter: true,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
          },
          filter: true,
        },
        autoHeight: true,
        animateRows: true,
        cacheOverflowSize: 2,
        maxConcurrentDatasourceRequests: 2,
        pagination: true,
        paginationAutoPageSize: false,
        paginationPageSizeSelector: false,
        paginationPageSize: 100,
        cacheBlockSize: 100,
        context: {
          componentParent: this,
        },
        getRowStyle: () => {
          return { cursor: "pointer" };
        },
      };
    },
  },
  beforeMount() {
    this.configGridOptions();
  },
  created() {
    this.tooltipShowDelay = 0;
    this.tooltipHideDelay = 2000;
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
  padding: 10px 20px;
}

@media (max-width: 576px) {
  .container {
    padding: 15px 14px;
  }
}

li {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}
.select-custom {
  display: flex;
  align-items: center;
  background: #8f1452 !important;
  color: #fff !important;
  padding: 2px 5px;
  border: 1px solid #903564;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 0px;
  height: 40px;
  width: 100px;
}
.select-custom:focus-visible,
.select-custom:focus {
  outline: none !important;
}
.select-custom option {
  background-color: white !important;
  color: black !important;
}
.custom-tooltip {
  width: 150px;
  padding: 5px;
  color: var(--ag-foreground-color);
  background-color: #5577cc;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}
.ag-grid-style {
  min-height: 485px;
  height: calc(100vh - 190px);
}
.dropdown-menu .dropdown-header {
  padding: 3px 20px;
  color: #823e5b;
  font-size: 16px;
}

/* MEDIA QUERY FOR MEDIUM SCREEN */
@media screen and (max-width: 768px) {
  .ag-grid-style {
    height: calc(100vh - 240px);
  }
}

/* MEDIA QUERY FOR SMALL SCREEN */
@media screen and (max-width: 448px) {
  .ag-grid-style {
    min-height: 300px;
    height: calc(100vh - 280px);
  }
}

/* MEDIA QUERY FOR SMALLER SCREEN */
@media screen and (max-width: 272px) {
  .ag-grid-style {
    min-height: 120px;
    height: calc(100vh - 305px);
  }
}
@media (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .hide-on-desktop {
    display: none !important;
  }
}
.dropdown-item:hover {
  background-color: #803059;
  color: white;
  cursor: pointer;
}
.cst-width {
  width: 74px;
}
</style>
