var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasAccess({ permissions: [_vm.PERMISSIONS.APPLICANT.READ] }))?_c('div',{staticClass:"row mx-0",staticStyle:{"width":"100%"}},[_vm._l((_vm.matchedApplicantsData),function(matchedApplicant,index){return _c('div',{key:index,staticClass:"col-lg-12"},[_c('div',{staticClass:"well container"},[_vm._m(0,true),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-condensed",staticStyle:{"font-size":"14px"},attrs:{"aria-label":"Matched Applicant's Details","aria-hidden":"true"}},[_c('tbody',[_c('tr',[_vm._m(1,true),_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(matchedApplicant.isDuplicate.first_name)+" "+_vm._s(matchedApplicant.isDuplicate.last_name)+" "),(
                    matchedApplicant.isDuplicate.belongsToCampusRecruitement
                  )?_c('em',{staticClass:"fa fa-graduation-cap fa-lg",attrs:{"title":"Belongs to Campus Drive"}}):_vm._e()]),_vm._m(2,true),_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(_vm._f("dateTimeFormat")(matchedApplicant.isDuplicate.createdAt))+" ")]),_vm._m(3,true),_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(matchedApplicant.isDuplicate.position)+" ")]),_vm._m(4,true),_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(matchedApplicant.isDuplicate.application_status)+" ")])]),_c('tr',[_vm._m(5,true),_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(matchedApplicant.isDuplicate.email)+" ")]),_vm._m(6,true),_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(matchedApplicant.isDuplicate.mobile_no)+" ")]),_vm._m(7,true),_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(matchedApplicant.isDuplicate.alternate_mobile_no)+" ")]),_vm._m(8,true),_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(matchedApplicant.isDuplicate.dob))+" ")])]),_c('tr',[_vm._m(9,true),_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(matchedApplicant.isDuplicate.gender)+" ")]),[_vm._m(10,true),(matchedApplicant.isDuplicate.address_line_1)?_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(matchedApplicant.isDuplicate.address_line_1)+", "+_vm._s(matchedApplicant.isDuplicate.address_line_2)+", "+_vm._s(matchedApplicant.isDuplicate.city)+", "+_vm._s(matchedApplicant.isDuplicate.state)+" ")]):_c('td',{staticClass:"col-md-2"},[_vm._v("-")])],_vm._m(11,true),(matchedApplicant.isDuplicate.current_address_line_1)?_c('td',{staticClass:"col-md-2"},[_vm._v(" "+_vm._s(matchedApplicant.isDuplicate.current_address_line_1)+", "+_vm._s(matchedApplicant.isDuplicate.current_address_line_2)+", "+_vm._s(matchedApplicant.isDuplicate.current_city)+", "+_vm._s(matchedApplicant.isDuplicate.current_state)+" ")]):_c('td',{staticClass:"col-md-2"},[_vm._v("-")]),_vm._m(12,true),_c('td',{staticClass:"col-md-2"},[_c('router-link',{attrs:{"target":"_blank","to":{
                    name: 'applicantprofile',
                    params: { slug: matchedApplicant.isDuplicate.slug },
                  }}},[_c('i',{staticClass:"fa fa-user-circle-o fa-lg",staticStyle:{"color":"#832e5b"},attrs:{"aria-hidden":"true"}}),_vm._v("View ")])],1)],2)])])]),_c('div',{staticClass:"text-center"},[(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.UPDATE] }))?_c('button',{staticClass:"btn btn-cp",staticStyle:{"margin":"5px"},on:{"click":function($event){return _vm.confirmDuplicate(
              matchedApplicant.newApplicantId,
              matchedApplicant.matchedApplicantId
            )}}},[_vm._v(" Confirm Duplicate ")]):_vm._e()])])])}),_c('common-modal',{attrs:{"id":_vm.commonModalData.id,"modal-title":_vm.commonModalData.title,"modal-body":_vm.commonModalData.body,"show-cancel":_vm.commonModalData.showCancel,"modal-color":_vm.commonModalData.color,"modal-size-class":_vm.commonModalData.sizeClass},on:{"actionPerformed":_vm.emitOnCurrent}})],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h4',[_c('strong',[_vm._v("Matched Applicant's Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Application Date")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Applied for Position")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Application Status")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Mobile No")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Alt. Mobile No")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Date Of Birth")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Gender")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Permenent Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("Current Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"col-md-1"},[_c('strong',[_vm._v("View Profile")])])
}]

export { render, staticRenderFns }