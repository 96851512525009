<!-- DeleteModal.vue -->
<template>
  <div class="modal fade" :id="modalId" tabindex="-1" role="dialog">
    <!-- Modal content goes here -->
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Delete {{ item }}</h3>
          <button
            type="button"
            class="close"
            @click="closeModal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h3 :style="'font-size:16px'">
            This action will delete the
            {{ item.toLowerCase() || "item" }} permanently. Are you sure?
          </h3>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-cp ml-2" @click="deleteItem">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalId: String, // ID for the modal element
    item: String, // Message to display in the modal body
    onDelete: Function, // Callback function for item deletion
  },
  methods: {
    closeModal() {
      // Close the modal
      $("#" + this.modalId).modal("hide");
    },
    deleteItem() {
      // Call the onDelete callback function
      this.onDelete();

      // Close the modal
      this.closeModal();
    },
  },
  beforeDestroy() {
    if (this.modalId) {
      $(`#${this.modalId}`).modal("hide");
      $(".modal-backdrop").hide();
      $("body").removeClass("modal-open");
    }
  },
};
</script>
