<template>
  <div class="content">
    <div class="page-header">
      <common-header :data="['home', 'applications', 'linkRequests']" />
    </div>
    <div class="container-fluid">
      <ag-grid-vue
        ref="applicantLinkRequests"
        :gridOptions="gridOptions"
        :rowData="linkRequests"
        class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
      >
      </ag-grid-vue>
      <b-modal ref="linkRequestComment" title="Link Request Status" hide-footer>
        <action-modal :submitForm="submitForm" />
      </b-modal>

      <b-modal
        ref="compareApplicantLinkRequest"
        title="Compare Link Request"
        size="xl"
        hide-footer
      >
        <applicant-compare-link-request
          :applicantMaster="applicantMaster"
          :applicantData="applicantData"
        ></applicant-compare-link-request>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import ActionModal from "./applicant-link-requests-modal.vue";
import ApplicantCompareLinkRequest from "./applicant-compare-data.vue";

export default {
  data() {
    return {
      id: null,
      linkRequests: [],
      gridOptions: null,
      applicantMaster: null,
      applicantData: null,
      pageSize: 10,
      columnDefs: [
        {
          cellRenderer: "profile-link",
          width: 35,
          minWidth: 35,
          maxWidth: 35,
          suppressHeaderMenuButton: true,
          valueGetter: "node.id",
          headerTooltip: "Profile Info",
          tooltipValueGetter: function (params) {
            if (params.data.applicantData != undefined) {
              return "Form response of " + params.data.applicantData.first_name;
            }
            return "";
          },
          cellStyle: { textAlign: "center", padding: 0 },
        },
        {
          cellRenderer: "compare-profile",
          cellRendererParams: {
            clicked: function (applicantMaster, applicantData) {
              this.applicantMaster = applicantMaster;
              this.applicantData = applicantData;
              this.$refs.compareApplicantLinkRequest.show();
            }.bind(this),
          },
          width: 35,
          minWidth: 35,
          maxWidth: 35,
          suppressHeaderMenuButton: true,
          valueGetter: "node.id",
          headerTooltip: "Compare Profile",
          tooltipValueGetter: function (params) {
            if (params.data.applicantData != undefined) {
              return "Compare both";
            }
            return "";
          },
          cellStyle: { textAlign: "center", padding: 0 },
        },
        {
          headerName: "Application Id",
          field: "applicationId",
          minWidth: 200,
          maxWidth: 200,
          width: 250,
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Status",
          field: "status",
          width: 100,
          minWidth: 100,
          maxWidth: 100,
          filter: "agTextColumnFilter",
        },
        {
          headerName: "Comments",
          field: "comments",
          width: 200,
          minWidth: 200,
          maxWidth: 200,
          filter: "agTextColumnFilter",
          valueGetter: function (params) {
            return params.data.comments ? params.data.comments : "-";
          },
        },
        {
          groupId: "applicantGroup",
          headerName: "Form Response",
          children: [
            {
              headerName: "Name",
              field: "applicantData",
              width: 200,
              minWidth: 200,
              comparator: (valueA, valueB) => {
                if (valueA && valueB) {
                  return valueA
                    .toLowerCase()
                    .localeCompare(valueB.toLowerCase());
                }
                return 0;
              },
              maxWidth: 250,
              filter: "agTextColumnFilter",
              valueGetter: function (params) {
                const firstName = params.data.applicantData?.first_name ?? "";
                const lastName = params.data.applicantData?.last_name ?? "";
                return firstName + " " + lastName;
              },
              suppressMovable: true,
            },
            {
              headerName: "Email",
              field: "applicantData.email",
              width: 200,
              minWidth: 200,
              maxWidth: 300,
              filter: "agTextColumnFilter",
              valueGetter: function (params) {
                return params.data.applicantData?.email
                  ? params.data.applicantData.email
                  : "-";
              },
              suppressMovable: true,
            },
            {
              headerName: "Phone No",
              field: "applicantData.mobile_no",
              width: 150,
              maxWidth: 150,
              minWidth: 150,
              filter: "agTextColumnFilter",
              valueGetter: function (params) {
                return params.data.applicantData?.mobile_no
                  ? params.data.applicantData.mobile_no
                  : "-";
              },
              suppressMovable: true,
            },
          ],
        },
        {
          groupId: "applicantMasterGroup",
          headerName: "Profile",
          children: [
            {
              headerName: "Name",
              field: "applicantMaster",
              comparator: (valueA, valueB) => {
                if (valueA && valueB) {
                  return valueA
                    .toLowerCase()
                    .localeCompare(valueB.toLowerCase());
                }
                return 0;
              },

              width: 200,
              minWidth: 200,
              maxWidth: 250,
              filter: "agTextColumnFilter",
              valueGetter: function (params) {
                const firstName = params.data.applicantMaster?.first_name ?? "";
                const lastName = params.data.applicantMaster?.last_name ?? "";
                return firstName + " " + lastName;
              },
              suppressMovable: true,
            },
            {
              headerName: "Email",
              field: "applicantMaster.email",
              comparator: (valueA, valueB) => {
                if (valueA && valueB) {
                  return valueA
                    .toLowerCase()
                    .localeCompare(valueB.toLowerCase());
                }
                return 0;
              },

              width: 200,
              minWidth: 200,
              maxWidth: 300,
              filter: "agTextColumnFilter",
              valueGetter: function (params) {
                return params.data?.applicantMaster?.email
                  ? params.data.applicantMaster.email
                  : "-";
              },
              suppressMovable: true,
            },
            {
              headerName: "Phone No",
              field: "applicantMaster.mobile_no",
              width: 150,
              minWidth: 150,
              maxWidth: 150,
              filter: "agTextColumnFilter",
              valueGetter: function (params) {
                return params.data?.applicantMaster?.mobile_no
                  ? params.data.applicantMaster.mobile_no
                  : "-";
              },
              suppressMovable: true,
            },
          ],
        },
        {
          headerName: "Actions",
          cellRenderer: "action-button",
          cellRendererParams: {
            clicked: function (id) {
              this.id = id;
              this.$refs.linkRequestComment.show();
            }.bind(this),
          },
          width: 150,
          minWidth: 150,
          sortable: false,
          maxWidth: 200,
          suppressMovable: true,
          suppressHeaderMenuButton: true,
          pinned: "right",
        },
      ],
    };
  },
  beforeMount() {
    this.configGridOptions();
  },
  mounted() {
    this.fetchLinkRequests();
  },
  components: {
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    "profile-link": {
      template: `<router-link v-if="params.data.applicantData" target='_blank' :to="{ name: 'applicantprofile', params: { slug: params.data.applicantData.slug } }"><i class="fa fa-user-circle-o" aria-hidden="true" style="color:#832e5b"></i></router-link>`,
    },
    // eslint-disable-next-line vue/no-unused-components
    "action-button": {
      template: `<button class="btn btn-cp"
      style="width: 100%; font-size: 15px; padding: 0px; margin: 0px" @click="btnClickedHandler()">Open</button>`,
      methods: {
        btnClickedHandler() {
          this.params.clicked(this.params.data.id);
        },
      },
    },
    // eslint-disable-next-line vue/no-unused-components
    "compare-profile": {
      template:
        '<b-button @click="compareProfileButtonHandler()" style="background: none; border: none; padding: 0; font-size: inherit; color: #832e5b;"><i class="fa fa-link" aria-hidden="true"></i></b-button>',
      methods: {
        compareProfileButtonHandler() {
          this.params.clicked(
            this.params.data.applicantMaster,
            this.params.data.applicantData
          );
        },
      },
    },
    // eslint-disable-next-line vue/no-unused-components
    ActionModal,
    // eslint-disable-next-line vue/no-unused-components
    ApplicantCompareLinkRequest,
  },
  computed: {
    ...mapGetters({
      linkRequestsFromStore: "$_applicants_data/getAllLinkRequests",
    }),
  },
  watch: {
    linkRequestsFromStore(val) {
      this.linkRequests = val;
    },
  },
  methods: {
    submitForm(formData) {
      if (!this.id && this.id !== 0) {
        this.$refs.linkRequestComment.hide();
        return;
      }
      const updateLinkRequestFormData = { id: this.id, ...formData };
      this.$store.dispatch(
        "$_applicants_data/updateLinkRequest",
        updateLinkRequestFormData
      );
      this.$refs.linkRequestComment.hide();
    },
    async fetchLinkRequests() {
      await this.$store.dispatch("$_applicants_data/fetchAllLinkRequests");
    },
    configGridOptions() {
      this.gridOptions = {
        colResizeDefault: true,
        enableCellTextSelection: true,
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "",
          menuTabs: [],
        },
        enableBrowserTooltips: true,
        columnDefs: this.columnDefs.map((columnDef) => ({
          ...columnDef,
          suppressMovable: true, // Disable column reordering
        })),
        pagination: true,
        paginationPageSizeSelector: false,
        paginationPageSize: this.pageSize, // Make sure pageSize is defined in your component
        context: {
          componentParent: this,
        },
      };
      if (this.gridOptions.api) {
        this.gridOptions.api.setRowData(
          Array.isArray(this.linkRequests) ? this.linkRequests : []
        );
      }
    },
    setRowData() {
      if (this.gridOptions && this.linkRequests) {
        this.gridOptions.api.setRowData(this.linkRequests);
      }
    },
  },
};
</script>

<style scoped>
.modal-content {
  padding: 0;
}
.modal-body {
  padding: 10px;
}
.custom-button {
  background-color: transparent;
  border: none;
  padding: 0;
  font-size: inherit;
  color: inherit;
  cursor: pointer;
}

.custom-button:hover {
  color: #832e5b;
}

.ag-grid-style {
  height: calc(100vh - 135px);
  min-height: 500px;
}

/* MEDIA QUERY FOR SMALLER SCREEN */
@media screen and (max-width: 280px) {
  .ag-grid-style {
    height: calc(100vh - 160px);
  }
}
</style>
