<template>
  <div>
    <div
      class="d-flex flex-row justify-content-between align-items-center shadow-sm p-2 rounded-2 hover"
    >
      <!-- Input / Title -->
      <div
        class="flex-grow-1 cursor-pointer"
        v-b-toggle="editMode ? '' : form.slug"
      >
        <input
          v-if="editMode"
          class="form-control"
          placeholder="Title"
          v-model="editTitle"
        />
        <h5 v-else class="mb-0 font-monospace lh-base">{{ form.title }}</h5>
      </div>
      <!-- Action Buttons -->
      <div>
        <template v-if="editMode">
          <template v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })">
            <button class="btn btn-sm" v-b-tooltip="'Save'" @click="onSave">
              <b-icon icon="check-circle" variant="success"></b-icon>
            </button>
            <span>|</span>
            <button class="btn btn-sm" v-b-tooltip="'Cancel'" @click="onCancel">
              <b-icon icon="x-circle" variant="danger"></b-icon>
            </button>
          </template>
        </template>
        <template v-else>
          <div class="d-none d-md-inline">
            <template
              v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
            >
              <router-link
                class="btn btn-sm"
                target="_blank"
                :to="{ name: 'preview-S', params: { slug: form.slug } }"
                v-b-tooltip="'View Latest'"
              >
                <b-icon icon="box-arrow-up-right" variant="info"></b-icon>
              </router-link>
              <span>|</span>
            </template>
            <template v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })">
              <button
                class="btn btn-sm"
                v-b-tooltip="'Copy Latest'"
                @click="onCopyMaster(form)"
              >
                <b-icon icon="files" variant="warning"></b-icon>
              </button>
              <span>|</span>
            </template>
            <template
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            >
              <button
                class="btn btn-sm"
                v-b-tooltip="statusTooltip"
                @click="onToggleStatus"
              >
                <b-icon icon="circle-fill" :variant="statusColor"></b-icon>
              </button>
              <span>|</span>
            </template>
            <template
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
            >
              <button class="btn btn-sm" v-b-tooltip="'Edit'" @click="onEdit">
                <b-icon icon="pencil-square" variant="primary"></b-icon>
              </button>
              <span>|</span>
            </template>
            <button
              v-if="hasAccess({ permissions: [BASE_PERMISSION.DELETE] })"
              class="btn btn-sm"
              v-b-tooltip="'Delete'"
              @click="onDelete"
            >
              <b-icon icon="trash" variant="danger"></b-icon>
            </button>
          </div>
          <b-dropdown
            size="sm"
            class="d-md-none"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            right
          >
            <template #button-content>
              <b-icon
                icon="three-dots-vertical"
                style="color: #8f1452"
              ></b-icon>
            </template>
            <b-dropdown-item
              v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
              :to="{ name: 'preview-S', params: { slug: form.slug } }"
              target="_blank"
            >
              <b-icon
                icon="box-arrow-up-right"
                class="mr-2"
                variant="info"
              ></b-icon>
              View Latest
            </b-dropdown-item>
            <b-dropdown-item
              v-if="hasAccess({ permissions: [BASE_PERMISSION.MENU] })"
              @click="() => onCopyMaster(form)"
              ><b-icon class="mr-2" icon="files" variant="warning"></b-icon>Copy
              Latest</b-dropdown-item
            >
            <b-dropdown-item
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
              @click="onToggleStatus"
              ><b-icon
                class="mr-2"
                icon="circle-fill"
                :variant="statusColor"
              ></b-icon
              >{{ statusTooltip }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="hasAccess({ permissions: [BASE_PERMISSION.UPDATE] })"
              @click="onEdit"
              ><b-icon
                class="mr-2"
                icon="pencil-square"
                variant="primary"
              ></b-icon
              >Edit</b-dropdown-item
            >
            <b-dropdown-item
              v-if="hasAccess({ permissions: [BASE_PERMISSION.DELETE] })"
              @click="onDelete"
            >
              <b-icon class="mr-2" icon="trash" variant="danger"></b-icon
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </div>
    </div>
    <b-collapse
      :id="form.slug"
      class="mt-2"
      accordion="form-master"
      role="tabpanel"
    >
      <b-card>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <span><strong>Slug:</strong> {{ form.slug }}</span>
          <div
            v-b-tooltip.left="addVersionBtnTooltip"
            v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
          >
            <router-link
              v-if="!newVersionDisable"
              class="btn text-primary"
              :to="{ name: 'editor-S', params: { slug: form.slug } }"
            >
              <b-icon icon="plus" variant="primary"></b-icon> New Blank
            </router-link>
            <button v-else class="btn text-primary" disabled>
              <b-icon icon="plus" variant="primary"></b-icon> New Blank
            </button>
          </div>
        </div>
        <table class="mt-2 w-100" aria-describedby="VersionTable">
          <thead>
            <tr class="fw-semibold">
              <th class="border-bottom">Form Version</th>
              <th class="text-center border-bottom" style="width: 128px">
                Published
              </th>
              <th class="text-center border-bottom" style="width: 94px">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(fv, idx) in formVersions" :key="idx">
              <td>v{{ idx + 1 }} - {{ toJSON(fv.form_json).title }}</td>
              <td class="text-center">
                {{
                  fv.status === "P" ? formatDate(fv.updatedAt) : "Not Published"
                }}
              </td>
              <td class="text-center">
                <template v-if="fv.status === 'S'">
                  <router-link
                    class="btn btn-sm"
                    :to="{
                      name: 'editor-MV',
                      params: { master: form.id, version: fv.id },
                    }"
                    v-b-tooltip="'Edit'"
                  >
                    <b-icon icon="pencil-square" variant="primary"></b-icon>
                  </router-link>
                  <button
                    class="btn pr-2"
                    v-b-tooltip="'Discard'"
                    @click="onDiscard(fv)"
                  >
                    <b-icon icon="trash" variant="danger"></b-icon>
                  </button>
                </template>
                <template v-else>
                  <router-link
                    class="btn btn-sm"
                    target="_blank"
                    :to="{
                      name: 'preview-MV',
                      params: { master: form.id, version: fv.id },
                    }"
                    v-b-tooltip="'View version'"
                  >
                    <b-icon icon="eye" variant="info"></b-icon>
                  </router-link>
                  <button
                    v-if="hasAccess({ permissions: [BASE_PERMISSION.CREATE] })"
                    class="btn btn-sm"
                    @click="copyForm(form.slug, fv)"
                    v-b-tooltip="'New from this'"
                  >
                    <b-icon icon="files" variant="success"></b-icon>
                  </button>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import PERMISSIONS from "../../Common/permissions";
import { hasAccess } from "../../User/_utils/actions";

export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      editStatus: "A",
      editTitle: "",
    };
  },

  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    onEdit() {
      this.editMode = true;
      this.editTitle = this.form.title;
    },
    onSave() {
      this.$store.dispatch("$_form_template/saveFormMaster", {
        id: this.form.id,
        title: this.editTitle,
      });
      this.editMode = false;
    },
    onCancel() {
      this.editMode = false;
    },
    onDiscard(fv) {
      this.$store.dispatch("$_form_template/discardFormVersion", fv.id);
    },
    onDelete() {
      this.$emit("deleteForm");
    },
    onToggleStatus() {
      this.editStatus = this.form.status === "A" ? "D" : "A";
      this.$store.dispatch("$_form_template/saveFormMaster", {
        id: this.form.id,
        status: this.editStatus,
      });
    },
    onCopyMaster(form) {
      let payload = {
        formMasterId: form.id,
        title: form.title + " - copy",
      };
      this.$store.dispatch("$_form_template/copyFormMaster", payload);
    },
    toJSON(data) {
      if (typeof data === "object") {
        return data;
      } else if (typeof data === "string") {
        try {
          const obj = JSON.parse(data);
          return obj;
        } catch (err) {
          console.error(err);
        }
      }
      return {};
    },
    formatDate(date) {
      date = new Date(date);
      return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    },
    copyForm(slug, fv) {
      this.$store.commit("$_form_template/setEditorMode", "C");
      this.$store.commit("$_form_template/setEditorActiveFormVersion", fv);
      this.$router.push({ name: "editor-S", params: { slug } });
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.FORM;
    },

    statusColor() {
      return this.form.status === "A" ? "success" : "danger";
    },
    statusTooltip() {
      return this.form.status === "A" ? "Click to disable" : "Click to enable";
    },
    newVersionDisable() {
      let flag = false;
      this.formVersions.forEach((ver) => {
        if (ver.status !== "P") {
          flag = true;
        }
      });
      return flag;
    },
    addVersionBtnTooltip() {
      return this.newVersionDisable
        ? "There is a version under progress either complete it or discard it"
        : null;
    },
    formVersions() {
      return this.$store.getters["$_form_template/getFormVersions"];
    },
  },
};
</script>

<style scoped>
.hover:hover {
  background: rgba(138, 233, 250, 0.2);
  transition: all ease-out 200ms;
}
</style>
