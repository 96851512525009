import {
  required,
  minLength,
  maxLength,
  email,
  minValue,
  maxValue,
  numeric,
} from "vuelidate/lib/validators";
import moment from "moment";
const { faker } = require("@faker-js/faker");
import * as XLSX from "xlsx";

const isValidDate = (value) => {
  return moment(value).isValid();
};

export const defaultRecruitmentFormData = () => {
  return {
    first_name: null,
    middle_name: null,
    last_name: null,
    gender: null,
    dob: null,
    email: null,
    mobile_no: null,
    degree: null,
    stream: null,
    ssc_percentage: null,
    entity_id: null,
    hsc_percentage: null,
    grad_percentage: null,
    aggregate_percentage: null,
    semester1: null,
    semester2: null,
    semester3: null,
    semester4: null,
    semester5: null,
    semester6: null,
    semester7: null,
    semester8: null,
  };
};

export const DateOfBirthValidations = {
  minDob: moment().subtract(50, "years"),
  maxDob: moment().subtract(18, "years"),
};

export const RECRUITMENT_FORM_DATA_VALIDATIONS = {
  email: {
    required,
    email,
  },
  first_name: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(50),
  },
  middle_name: {
    minLength: minLength(2),
    maxLength: maxLength(50),
  },
  last_name: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(50),
  },
  dob: {
    required,
    isValidDate,
  },
  mobile_no: {
    required,
    numeric,
    minLength: minLength(10),
    maxLength: maxLength(10),
  },
  gender: {
    required,
  },
  entity_id: {
    required,
  },
  ssc_percentage: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(100),
  },
  hsc_percentage: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(100),
  },
  degree: {
    required,
  },
  stream: {
    required,
  },
  grad_percentage: {
    minValue: minValue(0),
    maxValue: maxValue(100),
  },
  aggregate_percentage: {
    required,
    minValue: minValue(0),
    maxValue: maxValue(100),
  },
  semester1: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },
  semester2: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },
  semester3: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },

  semester4: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },

  semester5: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },

  semester6: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },

  semester7: {
    minValue: minValue(0),
    // maxValue: maxValue(10)
  },

  semester8: {
    minValue: minValue(0),
    maxValue: maxValue(10),
  },
};

export const VALIDATIONS_MESSAGES = {
  email: "Valid email required",
  first_name: "Min 2 char. and max 50 char. allowed",
  middle_name: "Not required OR Min 2 char. and max 50 char. allowed",
  last_name: "Min 2 char. and max 50 char. allowed",
  dob: "Valid DOB required, format=[month-day-year, year-month-day]",
  mobile_no: "Required, Only digits are allowed",
  gender: "Required",
  entity_id: "Required",
  degree: "Required",
  stream: "Required",
  ssc_percentage: "Required AND value should be between 0 and 100",
  hsc_percentage: "Required AND value should be between 0 and 100",
  grad_percentage: "Not required OR value should be between 0 and 100",
  aggregate_percentage: "Required AND value should be between 0 and 100",
  semester1: "Required AND value should be between 0 and 10",
  semester2: "Required AND value should be between 0 and 10",
  semester3: "Required AND value should be between 0 and 10",
  semester4: "Required AND value should be between 0 and 10",
  semester5: "Required AND value should be between 0 and 10",
  semester6: "Required AND value should be between 0 and 10",
  semester7: "Not required OR value should be between 0 and 10",
  semester8: "Not required OR value should be between 0 and 10",
};

export const generateRandomApplicantsData = (dummayDataCount = 500) => {
  const dummyApplicants = [];
  const maxNumbers = dummayDataCount;
  for (let i = 0; i < maxNumbers; i++) {
    let gender = faker.helpers.arrayElement(["male", "female"]);
    const first_name = faker.person.firstName(gender);
    const last_name = faker.person.lastName(gender);
    dummyApplicants.push({
      email: faker.internet
        .email({
          firstName: first_name,
          lastName: last_name + i,
          provider: "argusoftdummyprovider.com",
        })
        .replace("+", "_"),
      mobile_no: parseInt("99999" + i.toString().padStart(5, "0")),
      first_name: first_name,
      middle_name: null,
      last_name: last_name,
      gender: gender,
      degree: faker.helpers.arrayElement(["BTECH", "BE", "MTECH"]),
      dob: faker.date
        .between({
          from: new Date("1990-01-01T00:00:00.000Z"),
          to: new Date("2000-01-01T00:00:00.000Z"),
        })
        .toISOString(),
      stream: faker.helpers.arrayElement([
        "COMPUTER_SCIENCE",
        "INFORMATION_TECHNOLOGY",
        "MECHANICAL",
        "ELECTRONICS_COMMUNICATION",
      ]),
      ssc_percentage: faker.number.float({
        min: 60,
        max: 100,
        fractionDigits: 2,
      }),
      hsc_percentage: faker.number.float({
        min: 60,
        max: 100,
        fractionDigits: 2,
      }),
      aggregate_percentage: faker.number.float({
        min: 60,
        max: 100,
        fractionDigits: 2,
      }),
      grad_percentage: faker.number.float({
        min: 60,
        max: 100,
        fractionDigits: 2,
      }),
      semester1: faker.number.int({ min: 1, max: 10 }),
      semester2: faker.number.int({ min: 1, max: 10 }),
      semester3: faker.number.int({ min: 1, max: 10 }),
      semester4: faker.number.int({ min: 1, max: 10 }),
      semester5: faker.number.int({ min: 1, max: 10 }),
      semester6: faker.number.int({ min: 1, max: 10 }),
      semester7: faker.number.int({ min: 1, max: 10 }),
      semester8: faker.number.int({ min: 1, max: 10 }),
    });
  }
  var myFile = maxNumbers + "dummyApplicants.xlsx";
  var myWorkSheet = XLSX.utils.json_to_sheet(dummyApplicants);
  var myWorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "myWorkSheet");
  XLSX.writeFile(myWorkBook, myFile);
};
