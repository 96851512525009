<template>
  <div>
    <div class="content">
      <common-header
        :data="['home', 'report', 'auditTrail', 'history']"
        :componentName="this.auditId.toString()"
      />
    </div>
    <div v-if="filteredAllData.length">
      <div class="row category my-5">
        <div class="d-none d-md-block col-md-4">
          <div
            class="border rounded p-2 d-flex flex-column"
            style="max-width: 280px; height: 350px"
          >
            <div class="top-card-list flex-grow-1" style="overflow-y: auto">
              <button
                v-for="(item, index) in filteredAllData"
                :key="index"
                :class="{ active: item.version === rowData.version }"
                @click="selectVersion(item)"
                class="border rounded d-block btn-cp"
              >
                Version {{ item.version }}
              </button>
            </div>
          </div>
        </div>
        <div class="d-md-none col-12 my-3">
          <div class="pb-2 d-flex justify-content-end">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="versionDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style="background-color: #8f1452; border-color: #8f1452"
            >
              {{ rowData ? `Version ${rowData.version}` : "Select Version" }}
            </button>
            <div class="dropdown-menu" aria-labelledby="versionDropdown">
              <button
                v-for="(item, index) in filteredAllData"
                :key="index"
                :class="{ active: item.version === rowData.version }"
                @click="selectVersion(item)"
                class="dropdown-item"
              >
                Version {{ item.version }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-8" v-if="rowData">
          <div
            class="border rounded top-card"
            style="width: 100%; height: 350px"
          >
            <div class="card-header">
              <h4>
                Selected Version{{
                  rowData.version === filteredAllData[0].version
                    ? "(Latest)"
                    : ""
                }}
              </h4>
            </div>
            <div class="card-content">
              <div class="card-subtitle">Version: {{ rowData.version }}</div>
              <div class="card-subtitle">
                Created By:
                <div
                  v-if="rowData.created_by"
                  class="circle-icon"
                  style="margin: 3px"
                  v-b-tooltip.hover="
                    rowData.userType ? rowData.userType.toLowerCase() : ''
                  "
                >
                  {{
                    rowData.userType
                      ? rowData.userType.charAt(0).toUpperCase()
                      : ""
                  }}
                </div>
                <span style="margin: 3px" class="created-by-name">{{
                  rowData.created_by_displayName
                }}</span>
              </div>
              <div class="card-subtitle">
                Target Model: {{ rowData.targetModel }}
              </div>
              <div class="card-subtitle">Target Id: {{ rowData.targetId }}</div>
              <div class="card-subtitle">Action: {{ rowData.action }}</div>
              <div class="card-subtitle">
                Description: {{ rowData.description }}
              </div>
            </div>
            <div class="pb-4 d-flex justify-content-end pr-4">
              <button @click="openAuditModal(rowData)" class="btn btn-cp">
                Compare
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="category d-flex flex-wrap my-6"
        style="justify-content: space-between"
      >
        <div
          class="col-12 col-md-6 col-xl-4 p-2"
          v-for="(item, index) in filteredAllData"
          :key="index"
        >
          <div class="card border rounded">
            <div class="card-header p-2">
              <h4 class="m-0">Version {{ item.version }} -></h4>
            </div>
            <div class="card-content">
              <div class="card-subtitle">
                Created By:
                <div
                  v-if="item.created_by"
                  class="circle-icon"
                  style="margin-right: 3px; margin-left: 5px"
                  v-b-tooltip.hover="
                    item.userType ? item.userType.toLowerCase() : ''
                  "
                >
                  {{ item.userType.charAt(0).toUpperCase() }}
                </div>
                <div style="margin-left: 5px" class="created-by-name">
                  {{ item.created_by_displayName }}
                </div>
              </div>
              <div class="card-subtitle">Action: {{ item.action }}</div>
              <div class="card-subtitle">
                Created At : {{ valueFormatter(item.createdAt) }}
              </div>
              <div class="card-subtitle">
                Description: {{ item.description }}
              </div>
              <div class="mt-3 d-flex justify-content-end">
                <button @click="openAuditModal(item)" class="btn btn-cp">
                  Compare
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <audit-diff-modal :rowData="rowData" ref="auditDiffModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import api from "../_api/index";
import auditDiffModal from "./audit-diff-modal.vue";
import moment from "moment";

export default {
  name: "auditTrailDetail",
  components: {
    auditDiffModal,
  },
  data() {
    return {
      auditId: this.$route.params.auditId,
      rowData: [],
      filteredAllData: [],
    };
  },
  methods: {
    getData() {
      api
        .fetchAuditLogsById(this.auditId)
        .then((res) => {
          this.rowData = res.data;
          let filters = {
            targetId: this.rowData.targetId,
            targetModel: this.rowData.targetModel,
          };
          api
            .fetchAuditLogs(filters)
            .then((filteredData) => {
              this.filteredAllData = filteredData.data;
              if (
                typeof this.filteredAllData === "object" &&
                this.filteredAllData !== null &&
                !Array.isArray(this.filteredAllData)
              ) {
                this.filteredAllData = [filteredData.data];
              }
            })
            .catch((error) => {
              console.error("Error fetching filtered data:", error);
            });
        })
        .catch((error) => {
          console.error("Error fetching audit logs by ID:", error);
        });
    },

    selectVersion(selectedItem) {
      this.rowData = selectedItem;
    },
    openAuditModal(item) {
      this.rowData = item;
      $("#auditDiffModal").modal("show");
    },
    valueFormatter(value) {
      return moment(value).format("DD/MM/YYYY hh:mm:ss a");
    },
  },

  computed: {
    ...mapGetters({}),
  },
  created() {},

  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
.top-card {
  display: flex;
  /* max-width: 700px; */
  flex-direction: column;
}
.card-content {
  padding: 10px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-subtitle {
  font-size: 18px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.top-card-list button {
  padding: 4px;
  margin-bottom: 5px;
  background-color: #f8f8f8;
  text-align: center;
  width: 100%;
  line-height: 1.5;
  color: black !important;
}
.top-card-list button.active,
.top-card-list button.default-active {
  background-color: #8f1452;
  color: #fff;
}

.top-card-list button:hover {
  background-color: #ddd;
}

.top-card-list button.active:hover,
.top-card-list button.default-active:hover {
  background-color: #8f1452;
}

.card {
  flex-wrap: wrap;
  padding: 15px;
}

input[type="checkbox"] {
  margin-right: 8px;
}

label {
  font-size: 20px;
  color: #333;
}

input[type="checkbox"]:checked + label {
  font-weight: bold;
  color: #8f1452;
}

.card-subtitle span {
  margin-right: 5px;
}
.circle-icon {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
  background-color: #8f1452;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.created-by-name {
  white-space: nowrap;
}
</style>
