<template>
  <div>
    <common-header
      :data="['home', 'applications', 'applicants', 'singleApplicant']"
      :componentName="valueForCommonHeader"
    >
      <div class="d-flex">
        <div class="mt-2 mx-2">
          <em
            title="Belongs to Campus Drive"
            v-if="applicantInfoData.belongsToCampusRecruitement"
            class="fa fa-graduation-cap"
          ></em>
        </div>
        <router-link
          v-if="
            hasAccess({ permissions: [BASE_PERMISSION.UPDATE] }) &&
            applicantInfoData.slug
          "
          target="_blank"
          class="btn ml-2 btn-basic"
          :to="{
            name: 'editApplicantData',
            params: { slug: applicantInfoData.slug },
          }"
          ><i class="fa fa-pencil-square-o cst-color" aria-hidden="true"></i
        ></router-link>
      </div>
    </common-header>

    <div v-if="applicantInfoData" class="mt-2">
      <div class="well section-head mb-1 px-3 w-auto">
        <div class="row mx-0">
          <div class="col-md-5 col-8">
            Applied {{ applicationReceivedAgoFromNow }} ({{
              applicantInfoData.createdAt | dateFormat
            }})
          </div>
          <div class="col-md-7 col-4 text-right">
            <span>
              {{ applicantInfoData.application_status }}
            </span>
          </div>
        </div>
      </div>
      <router-link
        v-if="applicantInfoData.id"
        :to="{ name: 'resolveduplicate', params: { id: applicantInfoData.id } }"
      >
        <div class="text-center">
          <span
            v-if="
              applicantInfoData &&
              applicantInfoData.hasDuplicate &&
              applicantInfoData.hasDuplicate.length > 0
            "
            class="alert alert-danger"
            style="padding-top: 7px; padding-bottom: 7px"
          >
            <em class="fa fa-link"></em>Please resolve duplicates for this
            profile first</span
          >
        </div>
      </router-link>
      <div class="p-0">
        <div class="row mx-0">
          <div class="col-md-12">
            <div class="well mx-auto px-3">
              <div class="table-responsive">
                <table
                  class="table table-condensed table-compressed"
                  aria-label="Applicant Details"
                >
                  <thead>
                    <th colspan="10">
                      <h4
                        class="d-flex justify-content-between flex-column flex-md-row"
                      >
                        <div>
                          <strong>Application Details</strong>
                        </div>
                        <div>
                          <template
                            v-if="
                              applicantInfoData &&
                              applicantInfoData.hasDuplicate
                            "
                          >
                            <div
                              v-if="
                                hasAccess({
                                  permissions: [BASE_PERMISSION.UPDATE],
                                })
                              "
                              :title="
                                lastRoundResult === 'Pending'
                                  ? 'You cannot change this status while any round is Pending'
                                  : ''
                              "
                              class="btn-group btn-group-xs ml-1"
                            >
                              <button
                                class="btn btn-cp"
                                @click="
                                  openUpdateApplicationStatusModal('PROCESS')
                                "
                                :disabled="
                                  lastRoundResult === 'Pending' ||
                                  applicantInfoData.hasDuplicate.length > 0 ||
                                  applicantInfoData.application_status !==
                                    'Received'
                                "
                                v-if="
                                  applicantInfoData.application_status ===
                                    'Received' ||
                                  applicantInfoData.application_status ===
                                    'Processed'
                                "
                              >
                                {{ acceptValue }}
                                <em
                                  v-if="
                                    applicantInfoData.application_status ===
                                    'Processed'
                                  "
                                  class="fa fa-check"
                                >
                                </em>
                              </button>
                              <button
                                class="btn btn-success"
                                @click="
                                  openUpdateApplicationStatusModal('SHORTLIST')
                                "
                                :disabled="
                                  lastRoundResult === 'Pending' ||
                                  applicantInfoData.hasDuplicate.length > 0
                                "
                              >
                                {{ selectValue }}
                                <em
                                  v-if="
                                    applicantInfoData.application_status ===
                                    'Shortlisted'
                                  "
                                  class="fa fa-check"
                                >
                                </em>
                              </button>
                              <button
                                class="btn btn-secondary"
                                @click="
                                  openUpdateApplicationStatusModal('JOINED')
                                "
                                :disabled="
                                  lastRoundResult === 'Pending' ||
                                  applicantInfoData.hasDuplicate.length > 0
                                "
                              >
                                Joined
                                <em
                                  v-if="
                                    applicantInfoData.application_status ===
                                    'Joined'
                                  "
                                  class="fa fa-check"
                                >
                                </em>
                              </button>
                              <button
                                class="btn btn-danger"
                                @click="
                                  openUpdateApplicationStatusModal('DIFFER')
                                "
                                :disabled="
                                  lastRoundResult === 'Pending' ||
                                  applicantInfoData.hasDuplicate.length > 0
                                "
                              >
                                {{ rejectValue }}
                                <em
                                  v-if="
                                    applicantInfoData.application_status ===
                                    'Differed'
                                  "
                                  class="fa fa-check"
                                >
                                </em>
                              </button>
                              <button
                                class="btn btn-warning"
                                @click="
                                  openUpdateApplicationStatusModal('HOLD')
                                "
                                :disabled="
                                  lastRoundResult === 'Pending' ||
                                  applicantInfoData.hasDuplicate.length > 0
                                "
                              >
                                {{ holdValue }}
                                <em
                                  v-if="
                                    applicantInfoData.application_status ===
                                    'Held'
                                  "
                                  class="fa fa-check"
                                >
                                </em>
                              </button>
                              <button
                                class="btn btn-info"
                                @click="
                                  openUpdateApplicationStatusModal(
                                    'NOT APPEARED'
                                  )
                                "
                                :disabled="
                                  lastRoundResult === 'Pending' ||
                                  applicantInfoData.hasDuplicate.length > 0
                                "
                              >
                                {{ notAppearedValue }}
                                <em
                                  v-if="
                                    applicantInfoData.application_status ===
                                    'Not Appeared'
                                  "
                                  class="fa fa-check"
                                >
                                </em>
                              </button>
                              <button
                                class="btn btn-dark"
                                @click="
                                  openUpdateApplicationStatusModal(
                                    'TALENT POOL'
                                  )
                                "
                                :disabled="
                                  lastRoundResult === 'Pending' ||
                                  applicantInfoData.hasDuplicate.length > 0
                                "
                              >
                                {{ talentPoolValue }}
                                <em
                                  v-if="
                                    applicantInfoData.application_status ===
                                    'Talent Pool'
                                  "
                                  class="fa fa-check"
                                >
                                </em>
                              </button>
                              <button
                                @click="claimApplicant"
                                v-if="!applicantInfoData.claimed_by"
                                type="button"
                                class="btn btn-primary"
                              >
                                Claim
                              </button>
                              <button
                                @click="removeClaimedApplicant"
                                v-if="applicantInfoData.claimed_by"
                                :disabled="
                                  applicantInfoData.claimed_by !=
                                  loggedInUser.user_id
                                "
                                type="button"
                                class="btn btn-secondary"
                              >
                                Unclaim
                              </button>
                            </div>
                            <div class="float-right ml-2">
                              <safe-link
                                v-if="applicantInfoData.linkedWithRecord"
                                :link="previousRecord"
                              >
                                <em
                                  class="fa fa-chevron-circle-left"
                                  aria-hidden="true"
                                ></em>
                                &nbsp;Previous Record
                              </safe-link>
                            </div>
                          </template>
                        </div>
                      </h4>
                    </th>
                  </thead>
                  <tbody v-if="applicantInfoData">
                    <tr>
                      <td class="col-md-2">
                        <strong>Last Updated On</strong>
                      </td>
                      <td class="col-md-4">
                        {{ applicantInfoData.updatedAt | dateTimeFormat }}
                      </td>
                      <td class="col-md-2">
                        <strong>Created On</strong>
                      </td>
                      <td class="col-md-4">
                        {{ applicantInfoData.createdAt | dateTimeFormat }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-2">
                        <strong>Applied for Position</strong>
                      </td>
                      <td class="col-md-4">{{ applicantInfoData.position }}</td>
                      <td class="col-md-2">
                        <strong>Application Status</strong>
                      </td>
                      <td class="col-md-4">
                        {{ applicantInfoData.application_status }}
                      </td>
                    </tr>
                    <tr v-if="applicantInfoData.feedback">
                      <td colspan="1">
                        <strong>Applicant Feedback</strong>
                      </td>
                      <td colspan="3">
                        {{ applicantInfoData.feedback }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-2">
                        <strong>Application Comments</strong>
                      </td>
                      <td class="col-md-4">
                        <div id="application_comments">
                          <safe-content
                            v-if="applicantInfoData.application_comments"
                            :content="applicantInfoData.application_comments"
                            tag="p"
                          ></safe-content>
                          <p v-else>N/A</p>
                        </div>
                      </td>
                      <td class="col-md-2" v-if="applicantInfoData.claimed_by">
                        <strong>Claimed By</strong>
                      </td>
                      <td
                        v-if="applicantInfoData.claimed_by && claimedByDetails"
                        class="col-md-4"
                      >
                        {{
                          claimedByDetails.first_name +
                          " " +
                          claimedByDetails.last_name
                        }}
                      </td>
                    </tr>
                    <tr v-if="applicantInfoData.followup_status">
                      <td class="col-md-2">
                        <strong>Follow Up Status</strong>
                      </td>
                      <td class="col-md-4">
                        {{ applicantInfoData.followup_status }}
                      </td>
                      <td class="col-md-2"></td>
                      <td class="col-md-4"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="
              hasAccess({ permissions: [BASE_PERMISSION.READ] }) &&
              applicantInfoData.designation_offered
            "
          >
            <div class="well">
              <div class="px-3">
                <div class="table-responsive">
                  <table
                    class="table table-condensed"
                    aria-label="Offer Details"
                  >
                    <thead>
                      <th colspan="10">
                        <h4>
                          <strong>Offer Details</strong>
                        </h4>
                      </th>
                    </thead>
                    <tbody v-if="applicantInfoData">
                      <tr>
                        <td class="col-md-2">
                          <strong>Profile</strong>
                        </td>
                        <td class="col-md-4">
                          {{ getProfileName(applicantInfoData.jobId) }}
                        </td>
                        <td class="col-md-2">
                          <strong>Designation</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantInfoData.designation_offered }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Expected Joining</strong>
                        </td>
                        <td
                          v-if="applicantInfoData.expected_joining"
                          class="col-md-4"
                        >
                          {{ applicantInfoData.expected_joining | dateFormat }}
                        </td>
                        <td v-else class="col-md-4">NA</td>
                        <td class="col-md-2">
                          <strong>Package</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantInfoData.package_offered }} LPA
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="well container">
              <div class="table-responsive">
                <table
                  class="table table-condensed table-compressed"
                  aria-label="Personal Details"
                >
                  <thead>
                    <th colspan="10">
                      <h4>
                        <strong>Personal Details</strong>
                      </h4>
                    </th>
                  </thead>
                  <tbody v-if="applicantInfoData">
                    <tr v-if="applicantProfilePic">
                      <td class="col-md-4">
                        <strong>Profile Pic</strong>
                      </td>
                      <td class="td-text">
                        <img
                          :src="'data:image/jpeg;base64,' + applicantProfilePic"
                          alt="Profile"
                          class="d-block rounded-circle"
                          style="width: 60px; height: 60px; object-fit: cover"
                        />
                      </td>
                    </tr>
                    <tr v-if="applicantInfoData.belongsToCampusRecruitement">
                      <td class="col-md-4">
                        <strong>Recruitment Session Name</strong>
                      </td>
                      <td class="td-text">
                        {{
                          applicantInfoData.belongsToCampusRecruitement
                            .recruitment_session_name
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-4">
                        <strong>Name</strong>
                      </td>
                      <td class="td-text">
                        {{ applicantInfoData.first_name }}
                        {{ applicantInfoData.last_name }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-4">
                        <strong>Email</strong>
                      </td>
                      <td class="td-text">{{ applicantInfoData.email }}</td>
                    </tr>
                    <tr>
                      <td class="col-md-4">
                        <strong>Mobile No.</strong>
                      </td>
                      <td class="td-text">{{ applicantInfoData.mobile_no }}</td>
                    </tr>
                    <tr v-if="applicantInfoData.alternate_mobile_no">
                      <td class="col-md-4">
                        <strong>Alt. Mobile No.</strong>
                      </td>
                      <td class="td-text">
                        {{ applicantInfoData.alternate_mobile_no }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-4">
                        <strong>Date of Birth</strong>
                      </td>
                      <td class="td-text">
                        {{ applicantInfoData.dob | dateFormat }}
                      </td>
                    </tr>
                    <tr>
                      <td class="col-md-4">
                        <strong>Gender</strong>
                      </td>
                      <td class="td-text">{{ applicantInfoData.gender }}</td>
                    </tr>
                    <tr v-if="applicantInfoData.address_line_1">
                      <td class="col-md-4">
                        <strong>Permanent Address</strong>
                      </td>
                      <td class="td-text">
                        {{ applicantInfoData.address_line_1 }},
                        {{ applicantInfoData.address_line_2 }},
                        {{ applicantInfoData.city }},
                        {{ applicantInfoData.state }}
                      </td>
                    </tr>
                    <tr v-if="applicantInfoData.current_address_line_1">
                      <td class="col-md-4">
                        <strong>Current Address</strong>
                      </td>
                      <td class="td-text">
                        {{ applicantInfoData.current_address_line_1 }},
                        {{ applicantInfoData.current_address_line_2 }},
                        {{ applicantInfoData.current_city }},
                        {{ applicantInfoData.current_state }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="well container">
              <table
                class="table table-condensed table-compressed"
                aria-label="Professional Details"
              >
                <thead>
                  <th colspan="10">
                    <h4>
                      <strong>Professional Details</strong>
                      <p
                        style="float: right; font-size: medium; cursor: pointer"
                        v-if="applicantInfoData.resume !== 'none'"
                        @click="getResume"
                      >
                        &nbsp;&nbsp;
                        <em title="Resume" class="fa fa-book"></em>&nbsp;Resume
                      </p>
                    </h4>
                  </th>
                </thead>
                <tbody v-if="applicantInfoData">
                  <tr v-if="applicantInfoData.employment_status">
                    <td class="col-6 col-md-4">
                      <strong>Employment Status </strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.employment_status }}
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.experience">
                    <td class="col-6 col-md-4">
                      <strong>Experience</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.experience }} years
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.relevant_experience">
                    <td class="col-6 col-md-4">
                      <strong>Relevant Experiencee</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.relevant_experience }} years
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.skills">
                    <td class="col-6 col-md-4">
                      <strong>Skills</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.skills.join(", ") }}
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.current_ctc">
                    <td class="col-6 col-md-4">
                      <strong>Current CTC</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.current_ctc }} LPA
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.previous_ctc">
                    <td class="col-6 col-md-4">
                      <strong>Previous CTC</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.previous_ctc }} LPA
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.expected_ctc">
                    <td class="col-6 col-md-4">
                      <strong>Expected CTC</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.expected_ctc }} LPA
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.current_role">
                    <td class="col-6 col-md-4">
                      <strong>Current Role</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.current_role }}
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.previous_role">
                    <td class="col-6 col-md-4">
                      <strong>Previous Role</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.previous_role }}
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.notice_period">
                    <td class="col-6 col-md-4">
                      <strong>Notice Period</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.notice_period }} days
                    </td>
                  </tr>
                  <tr v-if="applicantInfoData.reason_for_leaving">
                    <td class="col-6 col-md-4">
                      <strong>Reasons to look for New Opportunity</strong>
                    </td>
                    <td class="td-text">
                      {{ applicantInfoData.reason_for_leaving }}
                    </td>
                  </tr>
                  <tr
                    v-if="
                      applicantInfoData.socialProfiles &&
                      applicantInfoData.socialProfiles.linkedin
                    "
                  >
                    <td class="col-6 col-md-4">
                      <strong>LinkedIn</strong>
                    </td>
                    <td class="td-text">
                      <safe-link
                        aria-label="Open LinkedIn"
                        v-if="
                          applicantInfoData.socialProfiles.linkedin &&
                          isValidUrl(applicantInfoData.socialProfiles.linkedin)
                        "
                        :link="applicantInfoData.socialProfiles.linkedin"
                      >
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </safe-link>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      applicantInfoData.socialProfiles &&
                      applicantInfoData.socialProfiles.github
                    "
                  >
                    <td class="col-6 col-md-4">
                      <strong>GitHub</strong>
                    </td>
                    <td class="td-text">
                      <safe-link
                        aria-label="Open GitHub"
                        v-if="
                          applicantInfoData.socialProfiles.github &&
                          isValidUrl(applicantInfoData.socialProfiles.github)
                        "
                        :link="applicantInfoData.socialProfiles.github"
                      >
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </safe-link>
                    </td>
                  </tr>
                  <template v-if="applicantInfoData.project_links">
                    <tr v-if="applicantInfoData.project_links.length">
                      <td class="col-6 col-md-4">
                        <strong>Project links</strong>
                      </td>
                      <td class="td-text">
                        {{ applicantInfoData.project_links.join(", ") }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <template v-if="applicantInfoData">
                <template v-if="applicantInfoData.references">
                  <template
                    v-if="
                      applicantInfoData.references.ref1.name ||
                      applicantInfoData.references.ref2.name
                    "
                  >
                    <h5>
                      <strong>References</strong>
                    </h5>
                    <div class="table-responsive">
                      <table
                        class="table table-condensed"
                        aria-label="References"
                      >
                        <thead>
                          <th id="refName">Name</th>
                          <th id="refDesignation">Designation</th>
                          <th id="refContactNo">Contact No</th>
                        </thead>
                        <tbody>
                          <tr v-if="applicantInfoData.references.ref1.name">
                            <td class="td-text">
                              {{ applicantInfoData.references.ref1.name }}
                            </td>
                            <td class="td-text">
                              {{
                                applicantInfoData.references.ref1.designation
                              }}
                            </td>
                            <td class="td-text">
                              {{ applicantInfoData.references.ref1.contact_no }}
                            </td>
                          </tr>
                          <tr v-if="applicantInfoData.references.ref2.name">
                            <td class="td-text">
                              {{ applicantInfoData.references.ref2.name }}
                            </td>
                            <td class="td-text">
                              {{
                                applicantInfoData.references.ref2.designation
                              }}
                            </td>
                            <td class="td-text">
                              {{ applicantInfoData.references.ref2.contact_no }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </div>
          <div
            v-if="
              applicantInfoData.education_details &&
              applicantInfoData.education_details.degree
            "
            class="col-md-12"
          >
            <div class="well container">
              <table
                class="table table-condensed table-compressed"
                aria-label="Education Details"
                aria-hidden="true"
              >
                <thead>
                  <th colspan="10">
                    <h4>
                      <strong>Education Details</strong>
                    </h4>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td class="col-md-2">
                      <strong>Institute Name</strong>
                    </td>
                    <td class="col-md-4">
                      {{
                        applicantInfoData.education_details.institute_name ||
                        "-"
                      }}
                    </td>
                    <td class="col-md-2">
                      <strong>Overall CGPA</strong>
                    </td>
                    <td class="col-md-4">
                      {{
                        applicantInfoData.education_details.grade_points || "-"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-md-2">
                      <strong>Degree</strong>
                    </td>
                    <td class="col-md-4">
                      {{ applicantInfoData.education_details.degree }}
                    </td>
                    <td class="col-md-2">
                      <strong>Stream</strong>
                    </td>
                    <td class="col-md-4">
                      {{ applicantInfoData.education_details.stream }}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-md-2">
                      <strong>Start Date</strong>
                    </td>
                    <td class="col-md-4">
                      {{
                        applicantInfoData.education_details.start_date || "-"
                      }}
                    </td>
                    <td class="col-md-2">
                      <strong>Expected End Date</strong>
                    </td>
                    <td class="col-md-4">
                      {{ applicantInfoData.education_details.end_date || "-" }}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-md-2">
                      <strong>SSC Percentage</strong>
                    </td>
                    <td class="col-md-4">
                      {{ applicantInfoData.education_details.ssc_percentage }} %
                    </td>

                    <td class="col-md-2">
                      <strong>HSC Percentage</strong>
                    </td>
                    <td class="col-md-4">
                      {{ applicantInfoData.education_details.hsc_percentage }} %
                    </td>
                  </tr>
                  <tr>
                    <td class="col-md-2">
                      <strong>Graduation Percentage</strong>
                    </td>
                    <td class="col-md-4">
                      <span
                        v-if="
                          applicantInfoData.education_details.grad_percentage
                        "
                      >
                        {{
                          applicantInfoData.education_details.grad_percentage
                        }}
                        %
                      </span>
                      <span v-else>N/A</span>
                    </td>
                    <td class="col-md-2">
                      <strong>Aggregate Percentage</strong>
                    </td>
                    <td class="col-md-4">
                      {{
                        applicantInfoData.education_details.aggregate_percentage
                      }}
                      %
                    </td>
                  </tr>
                  <tr>
                    <td class="col-md-2">
                      <strong>Semester 1 SPI</strong>
                    </td>
                    <td class="col-md-4">
                      {{
                        applicantInfoData.education_details.semester1 || "N/A"
                      }}
                    </td>
                    <td class="col-md-2">
                      <strong>Semester 2 SPI</strong>
                    </td>
                    <td class="col-md-4">
                      {{
                        applicantInfoData.education_details.semester2 || "N/A"
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-md-2">
                      <strong>Semester 3 SPI</strong>
                    </td>
                    <td class="col-md-4">
                      {{ applicantInfoData.education_details.semester3 }}
                    </td>
                    <td class="col-md-2">
                      <strong>Semester 4 SPI</strong>
                    </td>
                    <td class="col-md-4">
                      {{ applicantInfoData.education_details.semester4 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-md-2">
                      <strong>Semester 5 SPI</strong>
                    </td>
                    <td class="col-md-4">
                      {{ applicantInfoData.education_details.semester5 }}
                    </td>
                    <td class="col-md-2">
                      <strong>Semester 6 SPI</strong>
                    </td>
                    <td class="col-md-4">
                      {{ applicantInfoData.education_details.semester6 }}
                    </td>
                  </tr>
                  <tr>
                    <td class="col-md-2">
                      <strong>Semester 7 SPI</strong>
                    </td>
                    <td class="col-md-4">
                      {{
                        applicantInfoData.education_details.semester7 || "N/A"
                      }}
                    </td>
                    <td class="col-md-2">
                      <strong>Semester 8 SPI</strong>
                    </td>
                    <td class="col-md-4">
                      {{
                        applicantInfoData.education_details.semester8 || "N/A"
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="
              applicantInfoData.educationDetails &&
              applicantInfoData.employment_status === 'Fresher' &&
              applicantInfoData.educationDetails.institute_name
            "
          >
            <div class="well container">
              <div class="row">
                <div class="col-md-6">
                  <table
                    class="table table-condensed"
                    aria-label="Education Details"
                  >
                    <thead>
                      <th colspan="10">
                        <h4>
                          <strong>Education Details</strong>
                        </h4>
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="col-md-4">
                          <strong>Institute Name</strong>
                        </td>
                        <td class="td-text">
                          {{
                            applicantInfoData.educationDetails.institute_name
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Degree</strong>
                        </td>
                        <td class="td-text">
                          {{ applicantInfoData.educationDetails.degree }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Branch</strong>
                        </td>
                        <td class="td-text">
                          {{ applicantInfoData.educationDetails.branch }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Start Date</strong>
                        </td>
                        <td class="td-text">
                          {{ applicantInfoData.educationDetails.start_date }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>End Date</strong>
                        </td>
                        <td class="td-text">
                          {{ applicantInfoData.educationDetails.end_date }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>SSC Grades</strong>
                        </td>
                        <td class="td-text">
                          {{ applicantInfoData.educationDetails.ssc_grades }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>HSC Grades</strong>
                        </td>
                        <td class="td-text">
                          {{ applicantInfoData.educationDetails.hsc_grades }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Graduation Grades</strong>
                        </td>
                        <td class="td-text">
                          {{
                            applicantInfoData.educationDetails.graduation_grades
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-4">
                          <strong>Aggregate Grades</strong>
                        </td>
                        <td class="td-text">
                          {{
                            applicantInfoData.educationDetails.aggregate_grades
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Semester 1 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantInfoData.education_details.semester1 ||
                            "N/A"
                          }}
                        </td>
                        <td class="col-md-2">
                          <strong>Semester 2 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantInfoData.education_details.semester2 ||
                            "N/A"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Semester 3 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantInfoData.education_details.semester3 }}
                        </td>
                        <td class="col-md-2">
                          <strong>Semester 4 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantInfoData.education_details.semester4 }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Semester 5 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantInfoData.education_details.semester5 }}
                        </td>
                        <td class="col-md-2">
                          <strong>Semester 6 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{ applicantInfoData.education_details.semester6 }}
                        </td>
                      </tr>
                      <tr>
                        <td class="col-md-2">
                          <strong>Semester 7 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantInfoData.education_details.semester7 ||
                            "N/A"
                          }}
                        </td>
                        <td class="col-md-2">
                          <strong>Semester 8 SPI</strong>
                        </td>
                        <td class="col-md-4">
                          {{
                            applicantInfoData.education_details.semester8 ||
                            "N/A"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-6 mt-3"
            v-if="
              applicantInfoData.jobDetails &&
              applicantInfoData.jobDetails.length > 0
            "
          >
            <div class="well container">
              <h4>
                <strong>Job Details</strong>
              </h4>
              <div class="accordion pb-3" id="jobAccordion">
                <div
                  class="card"
                  v-for="(job, index) in applicantInfoData.jobDetails"
                  :key="job.id"
                >
                  <div class="card-header">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left"
                        type="button"
                        :aria-controls="'jobAccordioncollapse-' + index"
                        data-toggle="collapse"
                        :data-target="'#jobAccordioncollapse-' + index"
                        aria-expanded="false"
                      >
                        {{ job.title }}
                      </button>
                    </h2>
                  </div>

                  <div
                    :id="'jobAccordioncollapse-' + index"
                    class="collapse"
                    aria-labelledby="jobAccordionheading"
                    data-parent="#jobAccordion"
                  >
                    <div class="card-body">
                      <table
                        class="table table-condensed"
                        aria-label="Job Details"
                      >
                        <thead>
                          <tr>
                            <th class="col-md-4" scope="col">
                              <strong>Title</strong>
                            </th>
                            <td class="td-text">{{ job.title }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="col-md-4">
                              <strong>Description</strong>
                            </td>
                            <td class="td-text">{{ job.description }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Type</strong>
                            </td>
                            <td class="td-text">{{ job.type }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Company Name</strong>
                            </td>
                            <td class="td-text">{{ job.companyName }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Company Website</strong>
                            </td>
                            <td class="td-text">{{ job.companyWebsite }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Company Location</strong>
                            </td>
                            <td class="td-text">{{ job.companyLocation }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Start Date</strong>
                            </td>
                            <td class="td-text">{{ job.startDate }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>End Date</strong>
                            </td>
                            <td class="td-text">{{ job.endDate }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 mt-3"
            v-if="
              applicantInfoData.courseDetails &&
              applicantInfoData.courseDetails.length > 0
            "
          >
            <div class="well container">
              <h4>
                <strong>Course Details</strong>
              </h4>
              <div class="accordion pb-3" id="courseAccordion">
                <div
                  class="card"
                  v-for="(course, index) in applicantInfoData.courseDetails"
                  :key="course.id"
                >
                  <div class="card-header">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left"
                        type="button"
                        :aria-controls="'courseAccordioncollapse-' + index"
                        data-toggle="collapse"
                        :data-target="'#courseAccordioncollapse-' + index"
                        aria-expanded="false"
                      >
                        {{ course.title }}
                      </button>
                    </h2>
                  </div>

                  <div
                    :id="'courseAccordioncollapse-' + index"
                    class="collapse"
                    aria-labelledby="courseAccordionheading"
                    data-parent="#courseAccordion"
                  >
                    <div class="card-body">
                      <table
                        class="table table-condensed"
                        aria-label="Course Details"
                      >
                        <thead>
                          <tr>
                            <th class="col-md-4" scope="col">
                              <strong>Title</strong>
                            </th>
                            <td class="td-text">{{ course.title }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="col-md-4">
                              <strong>Description</strong>
                            </td>
                            <td class="td-text">{{ course.description }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Certification Link</strong>
                            </td>
                            <td class="td-text">
                              <safe-link
                                aria-label="Open course certificate"
                                v-if="
                                  course.certificationLink &&
                                  isValidUrl(course.certificationLink)
                                "
                                :link="course.certificationLink"
                              >
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                ></i>
                              </safe-link>
                              <span v-else>NA</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Start Date</strong>
                            </td>
                            <td class="td-text">{{ course.startDate }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>End Date</strong>
                            </td>
                            <td class="td-text">{{ course.endDate }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-6 my-3"
            v-if="
              applicantInfoData.projectDetails &&
              applicantInfoData.projectDetails.length > 0
            "
          >
            <div class="well container">
              <h4>
                <strong>Project Details</strong>
              </h4>
              <div class="accordion pb-3" id="projectAccordion">
                <div
                  class="card"
                  v-for="(project, index) in applicantInfoData.projectDetails"
                  :key="project.id"
                >
                  <div class="card-header">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left"
                        type="button"
                        :aria-controls="'projectAccordioncollapse-' + index"
                        data-toggle="collapse"
                        :data-target="'#projectAccordioncollapse-' + index"
                        aria-expanded="false"
                      >
                        {{ project.title }}
                      </button>
                    </h2>
                  </div>

                  <div
                    :id="'projectAccordioncollapse-' + index"
                    class="collapse"
                    aria-labelledby="projectAccordionheading"
                    data-parent="#projectAccordion"
                  >
                    <div class="card-body">
                      <table
                        class="table table-condensed"
                        aria-label="Job Details"
                      >
                        <thead>
                          <tr>
                            <th class="col-md-4" scope="col">
                              <strong>Title</strong>
                            </th>
                            <td class="td-text">{{ project.title }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="col-md-4">
                              <strong>Description</strong>
                            </td>
                            <td class="td-text">{{ project.description }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Tech Used</strong>
                            </td>
                            <td class="td-text">
                              <span
                                class="cardSkillTag"
                                v-for="(tech, techIndex) in project.techUsed"
                                :key="techIndex"
                              >
                                {{ tech }}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Demo Link</strong>
                            </td>
                            <td class="td-text">
                              <safe-link
                                aria-label="Open project demo in a new tab"
                                v-if="
                                  project.demoLink &&
                                  isValidUrl(project.demoLink)
                                "
                                :link="project.demoLink"
                              >
                                <i
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                ></i>
                              </safe-link>
                              <span v-else>NA</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>Start Date</strong>
                            </td>
                            <td class="td-text">{{ project.startDate }}</td>
                          </tr>
                          <tr>
                            <td class="col-md-4">
                              <strong>End Date</strong>
                            </td>
                            <td class="td-text">{{ project.endDate }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <to-be-mailed
        modal-id="ToBeMailedAppInfo"
        :flag="flagToBePassed"
        :applicantData="updatedApplicationData"
      ></to-be-mailed>
      <common-modal
        :id="commonModalData.id"
        :modal-title="commonModalData.title"
        :modal-body="commonModalData.body"
        :show-cancel="commonModalData.showCancel"
        :modal-color="commonModalData.color"
        :modal-size-class="commonModalData.sizeClass"
        @actionPerformed="emitOnCurrent"
      />
      <update-application-status-modal
        modal-id="UpdateApplicationStatusAppInfo"
        :actionType="updateStatusActionType"
        :application-data="applicantInfoData"
        :job-profiles="jobProfiles"
        @onConfirmUpdateApplicationStatus="updateApplicationStatus"
      />
      <pdf-modal
        :key="resumeUrl"
        v-if="resumeUrl"
        modalId="view-resume"
        :url="resumeUrl"
        :title="resumeModalTitle"
        :axios="axios"
      ></pdf-modal>
    </div>
    <div v-else class="text-center">
      <h4 class="well">No record found</h4>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
import ToBeMailed from "./to-be-mailed.vue";
import CommonModal from "../../Common/_components/modal";
import UpdateApplicationStatusModal from "./update-application-status-modal";
import PdfModal from "../../Common/_components/pdf-modal.vue";
import config from "../../../config";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";
import { isValidUrl } from "../../Common/_utils/sanitization";

/**
 * @memberOf module:ApplicantProfile
 * @namespace components.applicant_info
 * @description Applicant Info component
 */
export default {
  data() {
    return {
      config,
      axios,
      applicationReceivedAgoFromNow: null,
      flagToBePassed: null,
      updateStatusActionType: null,
      updatedApplicationData: {},
      resumeUrl: null,
      resumeModalTitle: null,
      previousRecord: null,
    };
  },
  components: {
    ToBeMailed,
    CommonModal,
    UpdateApplicationStatusModal,
    PdfModal,
  },
  created() {
    this.$store.dispatch(
      "$_applicant_profile/fetchApplicantData",
      this.$route.params.slug
    );
    this.$store.dispatch(
      "$_applicant_profile/fetchApplicantPic",
      this.$route.params.slug
    );
    this.$store.dispatch("$_applicant_profile/fetchJobProfiles");
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    valueForCommonHeader() {
      const firstName = this.applicantInfoData.first_name || "";
      const lastName = this.applicantInfoData.last_name || "";
      const fullName = `${firstName} ${lastName}`.trim();
      return fullName || "NA";
    },
    ...mapGetters({
      applicantInfoData: "$_applicant_profile/fetchApplicantData",
      applicantProfilePic: "$_applicant_profile/fetchApplicantPic",
      lastRoundResult: "$_applicant_profile/fetchLastRoundResult",
      jobProfiles: "$_applicant_profile/fetchJobProfiles",
      claimedByDetails: "$_applicant_profile/fetchClaimedByDetails",
      campusMails: "$_applicants_data/fetchCampusMails",
      loggedInUser: "user/loggedInUser",
    }),

    /**
     * @memberOf module:ApplicantProfile.components.applicant_info
     * @description Changes the display of application status based on the data
     */
    selectValue() {
      return this.applicantInfoData.application_status === "Shortlisted"
        ? "Shortlisted"
        : "Shortlist";
    },

    /**
     * @memberOf module:ApplicantProfile.components.applicant_info
     * @description Changes the display of application status based on the data
     */
    acceptValue() {
      return this.applicantInfoData.application_status === "Processed"
        ? "Processed"
        : "Process";
    },

    /**
     * @memberOf module:ApplicantProfile.components.applicant_info
     * @description Changes the display of application status based on the data
     */
    rejectValue() {
      return this.applicantInfoData.application_status === "Differed"
        ? "Differed"
        : "Differ";
    },
    /**
     * @memberOf module:ApplicantProfile.components.applicant_info
     * @description Changes the display of application status based on the data
     */
    holdValue() {
      return this.applicantInfoData.application_status === "Held"
        ? "Held"
        : "Hold";
    },

    /**
     * @memberOf module:ApplicantProfile.components.applicant_info
     * @description Changes the display of application status based on the data
     */
    notAppearedValue() {
      return this.applicantInfoData.application_status === "Not Appeared"
        ? "Not Appeared"
        : "Not Appeared?";
    },

    /**
     * @memberOf module:ApplicantProfile.components.applicant_info
     * @description Changes the display of application status based on the data
     */
    talentPoolValue() {
      return this.applicantInfoData.application_status === "Talent Pool"
        ? "Talent Pool"
        : "Move To Talent Pool";
    },
  },
  methods: {
    isValidUrl(url) {
      return url ? isValidUrl(url) : false;
    },
    getResume() {
      $("#view-resume").modal("show");
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    claimApplicant() {
      this.$store.dispatch("$_applicant_profile/updateClaimedById", {
        id: this.applicantInfoData.id,
        action: "claim",
        slug: this.applicantInfoData.slug,
      });
    },
    removeClaimedApplicant() {
      this.$store.dispatch("$_applicant_profile/updateClaimedById", {
        id: this.applicantInfoData.id,
        action: "unclaim",
        slug: this.applicantInfoData.slug,
      });
    },
    /**
     * @memberOf module:ApplicantProfile.components.applicant_info
     * @description Open update application status confirmation modal
     */
    openUpdateApplicationStatusModal(type) {
      this.updateStatusActionType = type;
      $("#UpdateApplicationStatusAppInfo").modal();
    },
    /**
     * @memberOf module:ApplicantProfile.components.applicant_info
     * @description get profile name for the given job id
     */
    getProfileName(jobId) {
      let profile = this.jobProfiles.find((jobProfile) => {
        return jobProfile.id === jobId;
      });
      return profile ? profile.job_name : "NA";
    },
    /**
     * @memberof module:ApplicanProfile.compoenents.applicant_info
     * @description Get New Application status by user selected action type
     */
    getNewApplicationStatusByAction() {
      let newApplicationStatus;
      switch (this.updateStatusActionType) {
        case "PROCESS":
          newApplicationStatus = "Processed";
          break;
        case "SHORTLIST":
          newApplicationStatus = "Shortlisted";
          break;
        case "JOINED":
          newApplicationStatus = "Joined";
          break;
        case "DIFFER":
          newApplicationStatus = "Differed";
          break;
        case "HOLD":
          newApplicationStatus = "Held";
          break;
        case "TALENT POOL":
          newApplicationStatus = "Talent Pool";
          break;
        case "NOT APPEARED":
          newApplicationStatus = "Not Appeared";
          break;
        default:
          break;
      }
      return newApplicationStatus;
    },
    /**
     * @memberof module:ApplicanProfile.compoenents.applicant_info
     * @description update flagToBePassed by user selected action type
     */
    updateFlagByActionForCampusRecruitments() {
      switch (this.updateStatusActionType) {
        case "PROCESS":
          this.flagToBePassed = "ProcessApplicant";
          this.updatedApplicationData.application_status = "Processed";
          break;
        case "SHORTLIST":
          this.flagToBePassed = "ShortlistApplicant";
          this.updatedApplicationData.application_status = "Shortlisted";
          break;
        case "JOINED":
          this.flagToBePassed = "JoinedApplicant";
          this.updatedApplicationData.application_status = "Joined";
          break;
        case "DIFFER":
          this.flagToBePassed = "DifferApplicant";
          this.updatedApplicationData.application_status = "Differed";
          break;
        case "HOLD":
          this.flagToBePassed = "HoldApplicant";
          this.updatedApplicationData.application_status = "Held";
          break;
        case "NOT APPEARED":
          this.flagToBePassed = "SetNotAppeared";
          this.updatedApplicationData.application_status = "Not Appeared";
          break;
        case "TALENT POOL":
          this.flagToBePassed = "MoveToTalentPool";
          this.updatedApplicationData.application_status = "Talent Pool";
          break;
        default:
          this.flagToBePassed = "";
          break;
      }
    },
    /**
     * @memberof module:ApplicanProfile.compoenents.applicant_info
     * @param {String} comments
     * @description Update Application Status
     */
    updateApplicationStatus(offerData) {
      // Set updated application data
      this.updatedApplicationData = {
        ...this.applicantInfoData,
        ...(this.updateStatusActionType === "SHORTLIST" && {
          designation_offered: offerData.designation_offered,
          package_offered: offerData.package_offered,
          expected_joining: offerData.expected_joining,
          jobId: offerData.jobId,
        }),
        application_comments: offerData.comments,
        application_status: this.getNewApplicationStatusByAction(),
      };

      // Check if application is from campus recruitments
      if (this.applicantInfoData.belongsToCampusRecruitement) {
        this.updateFlagByActionForCampusRecruitments();
        // $("#ToBeMailedAppInfo").modal();
        const payload = {
          data: {
            applicantData: this.updatedApplicationData,
          },
          redirect: this.$route.fullPath,
        };
        if (offerData.isCoordinator) {
          payload.data.toBeMailed = this.campusMails;
          payload.data.type = this.getKeyForMails(offerData);
        }
        if (offerData.isStudent && !offerData.isCoordinator) {
          payload.data.type = this.getKeyForMails(offerData);
        }
        this.$store.dispatch(
          "$_applicant_profile/editCampusApplicationStatus",
          payload
        );
      } else {
        this.$store.dispatch("$_applicant_profile/editApplicationStatus", {
          data: this.updatedApplicationData,
          ...(offerData.isStudent
            ? { type: this.getKeyForMails(offerData) }
            : {}),
          redirect: this.$route.fullPath,
        });
      }
    },
    getKeyForMails(data) {
      if (data.isCoordinator && data.isStudent) {
        return "both";
      } else if (data.isCoordinator) {
        return "coordinator";
      } else if (data.isStudent) {
        return "student";
      }
    },
  },
  watch: {
    /**
     * @memberOf module:ApplicantProfile.components.applicant_info
     * @param {Object} value
     * @description Calculates the duration of application received
     */
    applicantInfoData(value) {
      this.resumeUrl =
        this.config.ROOT_API + "/applicants/resume/" + value.slug;
      this.previousRecord = value.linkedWithRecord
        ? `./${value.linkedWithRecord.slug}`
        : null;
      this.resumeModalTitle = `${value.first_name} ${value.last_name}-Resume`;
      this.$store.dispatch("$_applicant_profile/setIDInState", value.id);
      if (value.claimed_by) {
        this.$store.dispatch(
          "$_applicant_profile/claimedByDetails",
          value.claimed_by
        );
      }
      if (value.belongsToCampusRecruitement) {
        this.$store.dispatch(
          "$_applicants_data/fetchCampusEmails",
          value.belongsToCampusRecruitement.id
        );
      }
      let applicationDate = new Date(value.createdAt);
      this.applicationReceivedAgoFromNow = moment([
        applicationDate.getFullYear(),
        applicationDate.getMonth(),
        applicationDate.getDate(),
        applicationDate.getHours(),
        applicationDate.getMinutes(),
        applicationDate.getSeconds(),
      ]).fromNow();
    },
  },
};
</script>

<style scoped>
.affix {
  top: 0;
  width: 100%;
  z-index: 1;
}

h4 {
  padding-top: 0.5rem;
}

.cardSkillTag {
  background-color: #f7f8fa;
  color: #566474;
  font-size: 1rem;
  border-radius: 20px;
  padding: 4px 12px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  .btn-group-xs .btn {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 0px;
  }
  .btn-group-xs {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
}

@media (max-width: 576px) {
  .well {
    border-radius: 0px;
  }
}

.fa-graduation-cap {
  font-size: 25px;
}

.cst-color {
  color: #8f1452;
  font-size: x-large;
}
.td-text {
  word-wrap: break-word !important;
  max-width: 250px !important;
}
</style>
