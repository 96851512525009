<template>
  <div class="content">
    <applications-list
      :columnDefs="columnDefs"
      :statusCount="statusCount"
      :positionCount="positionCount"
      :employmentStatusCount="employmentStatusCount"
      :applicationStatus="applicationStatus"
      :isApplicantView="isApplicantView"
    >
    </applications-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { APPLICANTS_DATA_COL_DEFS } from "../_utils/applicant-data-col-def";
import ApplicationsList from "../../Common/_components/applications-list.vue";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

/**
 * @memberOf module:ApplicantsData
 * @namespace components.applicant_data
 * @description Applicant data component
 */
export default {
  data() {
    return {
      gridOptions: null,
      columnDefs: null,
      isApplicantView: true,
      applicationStatus: null,
    };
  },
  components: {
    ApplicationsList,
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    ...mapGetters({
      campusMails: "$_applicants_data/fetchCampusMails",
      statusCount: "$_applicants_data/applicationStatusCount",
      positionCount: "$_applicants_data/applicationPositionCount",
      employmentStatusCount:
        "$_applicants_data/applicationEmploymentStatusCount",
      loggedInUser: "user/loggedInUser",
    }),
  },

  beforeMount() {
    this.columnDefs = APPLICANTS_DATA_COL_DEFS;
  },
};
</script>
