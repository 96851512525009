import axios from "axios";

const prefix = "/system-configs";

const fetchSystemConfigs = () => {
  return axios.get(prefix, { meta: { abortRequests: false } });
};
const fetchSystemConfigsObject = () => {
  return axios.get(prefix + "?response_type=object");
};

const fetchPublicSystemConfigsObject = () => {
  return axios.get(prefix + "/public-keys" + "?response_type=object", {
    meta: { abortRequests: false },
  });
};

const addSystemConfig = (data) => {
  return axios.post(prefix, { data });
};

const updateSystemConfig = (data) => {
  return axios.put(prefix + `/${data.id}`, { data });
};
const deleteSystemConfig = (data) => {
  return axios.delete(prefix + `/${data.id}`);
};

export default {
  fetchSystemConfigs,
  fetchSystemConfigsObject,
  fetchPublicSystemConfigsObject,
  addSystemConfig,
  updateSystemConfig,
  deleteSystemConfig,
};
