<template>
  <div class="commonPadding" v-if="!isMobile || !isSecondaryEmail">
    <b-modal
      id="mandatory-modal"
      no-close-on-esc
      no-close-on-backdrop
      ref="modal"
      title="Fill Details"
      @ok.prevent="submitDetails"
    >
      <template #modal-header="{}">
        <h4>Fill Mandatory details</h4>
      </template>
      <div class="col-12 pr-2 mb-4">
        <label class="required">Email</label>
        <input
          type="text"
          name="email"
          disabled
          class="form-control"
          :value="applicantData.email"
        />
      </div>
      <div class="d-flex">
        <div class="col-12 col-sm-6 pr-2 mb-4">
          <label class="required">First Name </label>
          <input
            type="text"
            name="first_name"
            key="first_name"
            placeholder="Enter First Name"
            v-validate="'required|alpha_spaces|min:2|max:50'"
            :class="{ invalid: errors.first('first_name') }"
            class="form-control"
            v-model="applicantData.first_name"
            :disabled="isFresher"
          />
          <span
            v-if="errors.first('first_name')"
            class="valid-feedback-sm alert-danger"
            >{{ errors.first("first_name") }}</span
          >
        </div>
        <div class="col-12 col-sm-6 pr-2 mb-4">
          <label class="required">Last Name </label>
          <input
            type="text"
            name="last_name"
            key="last_name"
            placeholder="Enter Last Name"
            v-validate="'required|alpha_spaces|min:2|max:50'"
            :class="{ invalid: errors.first('last_name') }"
            class="form-control"
            v-model="applicantData.last_name"
            :disabled="isFresher"
          />
          <span
            v-if="errors.first('last_name')"
            class="valid-feedback-sm alert-danger"
            >{{ errors.first("last_name") }}</span
          >
        </div>
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="required"> Mobile Number </label>
        <input
          type="text"
          name="mobile_no"
          key="mobile_no"
          placeholder="Enter Mobile Number"
          v-validate="`numeric|length:10|required`"
          :class="{
            invalid: errors.first('mobile_no'),
          }"
          v-model="applicantData.mobile_no"
          class="form-control"
          :disabled="isFresher"
        />
        <span
          v-if="errors.first('mobile_no')"
          class="valid-feedback alert-danger"
          >{{ errors.first("mobile_no") }}</span
        >
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="required"> Secondary Email </label>
        <input
          type="text"
          name="secondary_email"
          key="secondary_email"
          placeholder="Enter Email"
          v-validate="'required|email'"
          :class="{ invalid: errors.first('secondary_email') }"
          class="form-control"
          v-model="applicantData.secondary_email"
        />
        <span
          v-if="errors.first('secondary_email')"
          class="valid-feedback alert-danger"
          >{{ errors.first("secondary_email") }}</span
        >
      </div>
      <div class="col-12 pr-2 mb-4">
        <label class="control-label required">
          Current Employment Status
        </label>
        <select
          class="form-control"
          name="employment_status"
          key="employment_status"
          :class="{ invalid: errors.first('employment_status') }"
          v-model="applicantData.employment_status"
          v-validate="'required'"
          :disabled="isFresher"
        >
          <option
            v-for="(emp_status, index) in employmentStatus"
            :key="index"
            @mouseover="onMouseOver()"
          >
            {{ emp_status }}
          </option>
        </select>
        <span
          v-if="errors.first('employment_status')"
          class="valid-feedback alert-danger"
          >{{ errors.first("employment_status") }}</span
        >
      </div>
      <template #modal-footer="{ ok }">
        <b-button size="sm" @click="ok()" class="btn btn-cp ml-2"
          >Save</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Validator } from "vee-validate";
import { isSameAlternateNo, beforeDate } from "../../Common/_plugin/validation";

Validator.extend("sameAlternateNo", isSameAlternateNo);
Validator.extend("beforeDate", beforeDate);
export default {
  data() {
    return {
      applicantData: {},
      employmentStatus: ["Fresher", "Working", "Freelancer", "Other"],
    };
  },
  computed: {
    ...mapGetters({
      applicantDataFromStore: "$_applicant_master/getLoggedInApplicant",
    }),

    isMobile() {
      return this.applicantDataFromStore.mobile_no;
    },
    isSecondaryEmail() {
      return this.applicantDataFromStore.secondary_email;
    },
    isFresher() {
      return this.applicantDataFromStore.employment_status === "Fresher";
    },
  },

  watch: {
    applicantDataFromStore(val) {
      this.applicantData = { ...val };
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setApplicantDataFromStore();
  },
  created() {
    this.$nextTick(() => {
      this.$bvModal.show("mandatory-modal");
    });
  },
  methods: {
    setApplicantDataFromStore() {
      this.applicantData = { ...this.applicantDataFromStore };
    },
    async submitDetails() {
      await this.$validator.validateAll();
      if (this.$validator.errors.items.length > 0) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "Please enter valid values!",
        });
      } else {
        let data = {
          secondary_email: this.applicantData.secondary_email,
        };

        if (!this.isFresher) {
          data = Object.assign(data, {
            first_name: this.applicantData.first_name,
            last_name: this.applicantData.last_name,
            mobile_no: this.applicantData.mobile_no,
            employment_status: this.applicantData.employment_status,
          });
        }

        this.$store.dispatch("$_applicant_master/updateApplicantProfile", data);
        this.$nextTick(() => {
          this.$bvModal.hide("mandatory-modal");
        });
      }
    },
  },
};
</script>

<style scoped>
@import "./tabStyles.css";
</style>
