var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('common-header',{attrs:{"data":['home', 'campusDrive', 'moodleConfigurations']}},[(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.CREATE] }))?_c('div',[_c('button',{staticClass:"btn btn-cp",attrs:{"type":"button"},on:{"click":_vm.openMoodleInstanceModal}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v(" Add Instance ")])]):_vm._e()]),_c('div',{staticClass:"container-fluid d-flex flex-column"},[(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.MENU] }))?_c('div',{staticClass:"card",staticStyle:{"min-width":"100%"}},[_c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table",attrs:{"aria-describedby":"moodleTable"}},[_vm._m(0),_c('tbody',_vm._l((_vm.moodleInstances),function(instance,index){return _c('tr',{key:index},[_c('td',{staticClass:"font-weight-bold",class:{ 'border-top-0': index == 0 }},[_vm._v(" "+_vm._s(instance.label)+" ")]),_c('td',{staticClass:"custom-url",class:{ 'border-top-0': index == 0 }},[_vm._v(" "+_vm._s(instance.url)+" ")]),_c('td',{class:{ 'border-top-0': index == 0 }},[_c('span',{staticClass:"d-none d-md-inline"},[(
                      _vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.UPDATE] })
                    )?_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.openUpdateMoodleModal(instance)}}},[_c('b-icon',{attrs:{"icon":"pencil-fill","variant":"primary"}})],1):_vm._e(),(
                      _vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.DELETE] })
                    )?_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.openConfirmDeleteModal(instance.id)}}},[_c('b-icon',{attrs:{"icon":"trash","variant":"danger"}})],1):_vm._e()]),_c('span',{staticClass:"d-md-none"},[(
                      _vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.DELETE] }) ||
                      _vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.UPDATE] })
                    )?_c('button',{staticClass:"btn dropdown-toggle",attrs:{"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('b-icon',{staticStyle:{"color":"#8f1452"},attrs:{"icon":"three-dots-vertical"}})],1):_vm._e(),_c('div',{staticClass:"dropdown-menu"},[(
                        _vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.UPDATE] })
                      )?_c('button',{staticClass:"btn mr-1 dropdown-item",on:{"click":function($event){return _vm.openUpdateMoodleModal(instance)}}},[_c('b-icon',{attrs:{"icon":"pencil-fill","variant":"primary"}}),_vm._v(" Edit ")],1):_vm._e(),(
                        _vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.DELETE] })
                      )?_c('button',{staticClass:"btn mr-1 dropdown-item",on:{"click":function($event){return _vm.openConfirmDeleteModal(instance.id)}}},[_c('b-icon',{attrs:{"icon":"trash","variant":"danger"}}),_vm._v(" Delete ")],1):_vm._e()])])])])}),0)])])]):_vm._e()]),_c('add-moodle-instance-modal',{on:{"submitted":_vm.moodleInstanceChanged}}),_c('update-moodle-instance-modal',{attrs:{"data":{ ..._vm.selectedInstance }},on:{"submitted":_vm.moodleInstanceChanged}}),_c('b-modal',{ref:"confirmDeleteModal",attrs:{"id":"confirm-delete-modal","title":"Confirm Delete"},on:{"ok":function($event){$event.preventDefault();return _vm.confirmDelete.apply(null, arguments)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ ok, cancel }){return [_c('b-button',{attrs:{"size":"sm","variant":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"btn btn-cp ml-2",attrs:{"size":"sm"},on:{"click":function($event){return ok()}}},[_vm._v("Delete")])]}}])},[_c('div',{staticClass:"col-12 pr-sm-2 mb-3"},[_c('p',[_vm._v("Are you sure you want to delete this instance?")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Label")]),_c('th',[_vm._v("Url")]),_c('th',[_vm._v("Actions")])])])
}]

export { render, staticRenderFns }