/**
 * @memberof module:Common
 * @namespace snackbar_store
 * @description store for snackbar component
 */
export default {
  namespaced: true,

  state: {
    isVisible: false,
    message: "",
    type: "",
  },

  /**
   * @memberof module:Common.snackbar_store
   * @namespace getters
   */
  getters: {
    /**
     * @memberof module:Common.snackbar_store.getters
     * @param {Object} state holds store properties for current module
     * @returns {Boolean} visibility of snackbar
     */
    visibility(state) {
      return state.isVisible;
    },

    /**
     * @memberof module:Common.snackbar_store.getters
     * @param {Object} state holds store properties for current module
     * @returns {String} message to be displayed in snackbar
     */
    message(state) {
      return state.message;
    },

    /**
     * @memberof module:Common.snackbar_store.getters
     * @param {Object} state holds store properties for current module
     * @returns {Boolean} type of snackbar
     */
    type(state) {
      return state.type;
    },
  },

  /**
   * @memberof module:Common.snackbar_store
   * @namespace actions
   */
  actions: {
    /**
     * @memberof module:Common.snackbar_store.actions
     * @param {Object} context store context
     * @param {Object} snackbarData contains visibility, message and type
     * @description used for displaying snackbar
     */
    openSnackbar: {
      root: true,
      handler(context, snackbarData) {
        context.commit("DISPLAY_SNACKBAR", {
          visibility: true,
          message: snackbarData.message,
          type: snackbarData.type !== undefined ? snackbarData.type : "default",
        });
      },
    },

    /**
     * @memberof module:Common.snackbar_store.actions
     * @param {Object} context store context
     * @description used for hiding snackbar
     */
    close(context) {
      context.commit("DISPLAY_SNACKBAR", {
        visibility: false,
        message: "",
        type: "default",
      });
    },
  },

  /**
   * @memberof module:Common.snackbar_store
   * @namespace mutations
   */
  mutations: {
    /**
     * @memberof module:Common.snackbar_store.mutations
     * @param {Object} state holds store properties for current modul
     * @param {Object} snackbarData contains visibility, message and type
     * @description handles snackbar opening and closing
     */
    DISPLAY_SNACKBAR(state, snackbarData) {
      state.isVisible = snackbarData.visibility;
      state.message = snackbarData.message;
      state.type = snackbarData.type;
    },
  },
};
