<template>
  <div :class="[uiStyle === 'ROW' ? 'form-group' : '', containerClass]">
    <label v-if="label" class="control-label"
      >{{ label }}
      <span v-if="required" class="text-danger">*</span>
    </label>
    <div class="input-group">
      <date-picker
        :config="defaultConfig"
        v-on="listeners"
        v-bind="$attrs"
        @dp-change="updateValue"
        :value="value"
      >
      </date-picker>
      <span class="input-group-addon" @click="toggleDatePicker">
        <i class="fa fa-calendar m-2" aria-hidden="false"> </i>
      </span>
    </div>
    <slot name="error"></slot>
  </div>
</template>

<script>
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import { formFieldMixin } from "../_mixins/form-field-mixin";
const DATE_ONLY_FORMAT = "DD MMMM YY";
const DATE_WITH_TIME_FORMAT = "DD MMMM YY HH:mm";

export default {
  mixins: [formFieldMixin],
  props: [
    "datePickerType",
    "required",
    "uiStyle",
    "containerClass",
    "minDate",
    "maxDate",
  ],
  data() {
    return {
      defaultConfig: {
        format: DATE_WITH_TIME_FORMAT,
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: false,
      },
    };
  },
  components: {
    datePicker,
  },
  created() {
    if (this.datePickerType === "DATE") {
      this.defaultConfig.format = DATE_ONLY_FORMAT;
    }
    if (this.minDate) this.defaultConfig.minDate = this.minDate;
    if (this.maxDate) this.defaultConfig.maxDate = this.maxDate;
  },
  methods: {
    toggleDatePicker() {
      $(`#${this.$attrs.id}`).data("DateTimePicker").toggle();
    },
    moment: function () {
      return moment();
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      };
    },
  },
};
</script>
