<template>
  <div class="modal fade" id="updateStatusModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Confirmation</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5>Are you sure?</h5>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-cp ml-2"
            data-dismiss="modal"
            @click="updateStatus(campusId)"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

/**
 * @memberof module:campusRecruitment
 * @namespace components.update_status_modal
 */
export default {
  /**
   * @memberof module:campusRecruitment.components.update_status_modal
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  data() {
    return {
      campusId: null,
    };
  },

  computed: {
    ...mapGetters({
      campusrecruitmentId: "$_campus_recruitment/fetchCampusRecruitmentId",
    }),
  },
  watch: {
    /**
     * @memberof module:campusRecruitment.components.update_status_modal
     * @param {Object} data
     * @description Watch - campus recruitment id
     */
    campusrecruitmentId(value) {
      this.campusId = value;
    },
  },
  methods: {
    /**
     * @memberof module:campusRecruitment.components.update_status_modal
     * @param {Object} data
     * @description Send id to action for updating status of campus recruitment
     */
    updateStatus(id) {
      this.$store.dispatch(
        "$_campus_recruitment/updateCampusRecruitmentStatus",
        id
      );
    },
  },
  beforeDestroy() {
    $("#updateStatusModal").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
