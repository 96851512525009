/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches applicant data from store
 */
const fetchApplicantData = (state) => state.applicantData;

/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches applicant profile pic from store
 */
const fetchApplicantPic = (state) => state.applicantPic;

/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches round data from store
 */
const fetchRoundDetails = (state) => state.roundDetails;

/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches modal data from store for edit round
 */
const fetchModalRoundData = (state) => state.modalRoundData;

/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches last round of the applicant from store
 */
const fetchLastRoundResult = (state) => state.lastRoundResult;

/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches users list from store with their details
 */
const fetchUsersDetails = (state) => state.usersDetails;

const fetchID = (state) => state.applicantID;

/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches commentList from store
 */
const getCommentListByApplicantId = (state) => state.commentList;

/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetches job profiles list from store with their details
 */
const fetchJobProfiles = (state) => state.jobProfiles;

/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetch claimed by details
 */
const fetchClaimedByDetails = (state) => state.claimedByDetails;

/**
 * @memberOf module:ApplicantProfile.store.getters
 * @param {Object} state
 * @returns {Object}
 * @description Fetch forms to be shown in interview/review feedback
 */
const fetchForms = (state) => state.forms;

const fetchMoodleInstanceList = (state) => state.moodleInstanceList;

const getFollowUpDetails = (state) => state.followUpDetails;
/**
 * @memberOf module:ApplicantProfile
 * @namespace store.getters
 * @description getters for Applicant Profile
 */
export default {
  fetchApplicantData,
  fetchRoundDetails,
  fetchModalRoundData,
  fetchLastRoundResult,
  fetchUsersDetails,
  fetchJobProfiles,
  fetchID,
  getCommentListByApplicantId,
  fetchClaimedByDetails,
  fetchForms,
  fetchMoodleInstanceList,
  getFollowUpDetails,
  fetchApplicantPic,
};
