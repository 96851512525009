export default function formCleaner(form) {
  const sections = form.sections;
  for (let section of sections) {
    const body = section.body;
    for (let part of body) {
      const question = part;
      switch (question.type) {
        case "line":
          question.properties = {
            placeholder: question.properties.placeholder,
          };
          break;
        case "paragraph":
          question.properties = {
            placeholder: question.properties.placeholder,
          };
          break;
        case "rating":
          question.properties = {
            stars: question.properties.stars,
            lLabel: question.properties.lLabel,
            rLabel: question.properties.rLabel,
          };
          break;
        case "dropdown":
          question.properties = {
            placeholder: question.properties.placeholder,
            options: question.properties.options.map((o) => {
              return {
                label: o.label,
                value: o.value,
              };
            }),
          };
          break;
        case "radio":
        case "checkbox":
          question.properties = {
            options: question.properties.options.map((o) => {
              return {
                label: o.label,
                value: o.value,
              };
            }),
            otherOption: question.properties.otherOption,
          };
          break;
      }
    }
  }
}
