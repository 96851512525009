<template>
  <div
    class="modal fade"
    id="addRound"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Round Details</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="triggerReset"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group">
              <label class="col-sm-3 control-label"
                >Round Type
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <select class="form-control" v-model="round.round_name">
                  <option>Aptitude</option>
                  <option>Written Test</option>
                  <option>Interview</option>
                  <option>Review</option>
                </select>
              </div>
            </div>
            <div v-if="round.round_name === 'Interview'">
              <div class="form-group">
                <label class="col-sm-3 control-label"
                  >Interviewers
                  <span class="text-danger">*</span>
                </label>
                <div class="col-sm-9">
                  <select
                    id="interviewerpicker"
                    v-model="round.interviewers_id"
                    class="selectpicker"
                    data-live-search="true"
                    multiple
                    data-width="100%"
                  >
                    <option
                      v-for="(interviewer, index) in usersDetails"
                      :key="index"
                      :value="interviewer.user_id"
                    >
                      {{ interviewer.first_name }} {{ interviewer.last_name }}
                    </option>
                  </select>
                  <p
                    class="alert alert-danger"
                    v-if="
                      !$v.round.interviewers_id.required &&
                      isInterviewersTouched
                    "
                  >
                    This field is required
                  </p>
                </div>
              </div>
            </div>
            <div v-if="round.round_name === 'Review'">
              <div class="form-group">
                <label class="col-sm-3 control-label"
                  >Reviewers
                  <span class="text-danger">*</span>
                </label>
                <div class="col-sm-9">
                  <select
                    id="reviewerpicker"
                    v-model="round.reviewers_id"
                    class="selectpicker"
                    data-live-search="true"
                    multiple
                    data-width="100%"
                  >
                    <option
                      v-for="(reviewer, index) in usersDetails"
                      :key="index"
                      :value="reviewer.user_id"
                    >
                      {{ reviewer.first_name }} {{ reviewer.last_name }}
                    </option>
                  </select>
                  <p
                    class="alert alert-danger"
                    v-if="!$v.round.reviewers_id.required && isReviewersTouched"
                  >
                    This field is required
                  </p>
                </div>
              </div>
            </div>
            <div
              v-if="
                round.round_name === 'Interview' ||
                round.round_name === 'Review'
              "
            >
              <div class="form-group">
                <label class="col-sm-3 control-label">Template</label>
                <div class="col-sm-9">
                  <select
                    v-if="
                      round.round_name === 'Interview' ||
                      round.round_name === 'Review'
                    "
                    id="formTemplatepicker"
                    v-model="round.form_id"
                    class="selectpicker"
                    data-live-search="true"
                    data-width="100%"
                    multiple
                    data-max-options="1"
                  >
                    <option
                      v-for="(template, index) in formTemplates.filter(
                        (f) => f.status === 'A'
                      )"
                      :key="index"
                      :value="template.id"
                    >
                      {{ template.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label"
                >Schedule
                <span class="text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <div class="input-group">
                  <date-picker
                    v-model="round.round_schedule"
                    :config="config"
                    id="datePickerAdd"
                    @dp-hide="datePickerRequired"
                  >
                  </date-picker>
                  <span class="input-group-addon" @click="toggleDatePicker">
                    <i class="fa fa-calendar m-2" aria-hidden="false"> </i>
                  </span>
                </div>
                <p v-if="datePickerFlag" class="alert alert-danger">
                  This field is required
                </p>
              </div>
            </div>
            <div class="form-group" v-if="round.round_name === 'Interview'">
              <label class="col-sm-3 control-label">Duration</label>
              <div class="col-sm-9">
                <select v-model="round.interviewDuration" class="form-control">
                  <option
                    v-for="(minutes, index) in interviewDurationMinutes"
                    :key="index"
                    :value="minutes"
                  >
                    {{ minutes + " minutes" }}
                  </option>
                </select>
              </div>
            </div>
            <div
              class="form-group"
              v-if="
                round.round_name === 'Aptitude' ||
                round.round_name === 'Written Test'
              "
            >
              <div>
                <label class="col-sm-9 control-label">Select Instance</label>
                <div class="col-sm-9">
                  <select
                    class="custom-select main college mr-2"
                    v-model="round.instanceId"
                  >
                    <option disabled value="">Select Instance</option>
                    <option
                      v-for="instance in moodleInstanceList"
                      :value="instance.id"
                      :key="instance.id"
                    >
                      {{ instance.label }}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <label class="col-sm-9 control-label">Select Course</label>
                <div class="col-sm-9">
                  <select
                    class="custom-select main college mr-2"
                    v-model="round.courseId"
                  >
                    <option disabled value="">Select Course</option>
                    <option
                      v-for="course in courseList"
                      :value="course.id"
                      :key="course.id"
                    >
                      {{ course.displayname }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="applicantData.belongsToCampusRecruitement">
              <div class="form-group">
                <label
                  class="col-sm-3 control-label"
                  style="margin-left: 8px; margin-top: 5px"
                  >To be Mailed
                  <span class="text-danger">*</span>
                </label>
                <label
                  class="checkbox-inline"
                  style="margin-top: 5px; margin-left: 16px"
                >
                  <input
                    style="margin-top: 5px"
                    type="checkbox"
                    value="Coordinator"
                    v-model="isCoordinator"
                  />
                  Coordinator
                </label>
                <label class="checkbox-inline" style="margin-top: 5px">
                  <input
                    style="margin-top: 5px"
                    type="checkbox"
                    value="Students"
                    v-model="isStudent"
                  />
                  Students
                </label>
              </div>
            </div>
            <div class="form-group" v-if="round.round_name === 'Interview'">
              <label class="col-sm-9 control-label required">
                <input
                  style="margin-right: 5px"
                  type="checkbox"
                  v-model="round.isOnlineInterview"
                />Send meeting link (online interview)
              </label>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            type="button"
            class="btn btn-dark"
            @click="cancel"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            v-if="applicantData.belongsToCampusRecruitement"
            :disabled="
              $v.round.$invalid ||
              (isCoordinator == false && isStudent == false)
            "
            type="button"
            class="btn btn-cp ml-2"
            @click="addCampusRoundAndMail"
            data-dismiss="modal"
          >
            Generate and Mail
          </button>
          <button
            v-else
            :disabled="$v.round.$invalid"
            type="button"
            class="btn btn-cp ml-2"
            @click="createRoundAndMail"
            data-dismiss="modal"
          >
            Generate Round
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../_api";
import { mapGetters } from "vuex";
import datePicker from "vue-bootstrap-datetimepicker";
import { required } from "vuelidate/lib/validators";
import campusRecruitmentApi from "../../CampusRecruitment/_api/index";
/**
 * @memberOf module:ApplicantProfile
 * @namespace components.add_round_modal
 * @description Add round modal component
 */
export default {
  props: ["applicantData", "refreshParentPage"],
  data() {
    return {
      courseList: [],
      round: {
        round_name: "Aptitude",
        round_schedule: null,
        isMailed: false,
        round_result: null,
        interviewers_id: [],
        reviewers_id: [],
        form_id: [],
        applicantIterationMasterId: this.applicantData.id || null,
        interviewDuration: 30,
        instanceId: null,
        courseId: null,
        isOnlineInterview: true,
      },
      roundsMany: [],
      interviewers: [101, 102, 103],
      reviewers: [101, 102, 103],
      interviewDurationMinutes: [15, 30, 45, 60, 90],
      config: {
        format: "DD MMMM YYYY HH:mm",
        useCurrent: false,
        showClear: true,
        showClose: true,
        sideBySide: true,
        minDate: new Date(),
      },
      datePickerFlag: false,
      isCoordinator: false,
      isStudent: false,
      interviewerChecker: false,
      isInterviewersTouched: false,
      reviewerChecker: false,
      isReviewersTouched: false,
      formTemplateChecker: false,
    };
  },
  components: {
    datePicker,
  },
  computed: {
    ...mapGetters({
      campusMails: "$_applicants_data/fetchCampusMails",
      usersDetails: "$_applicant_profile/fetchUsersDetails",
      id: "$_applicant_profile/fetchID",
      formTemplates: "$_form_template/getForms",
      moodleInstanceList: "$_applicant_profile/fetchMoodleInstanceList",
    }),
  },
  watch: {
    "round.instanceId"(value) {
      if (value) {
        campusRecruitmentApi
          .getAllCourses(this.round.instanceId)
          .then((res) => {
            this.courseList = res.data;
          })
          .catch(() => {
            this.courseList = [];
          });
      }
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @param {Object} value
     * @description Fetch Co-ordinators emails as soon as applicant data arrives
     */
    applicantData(value) {
      if (value.belongsToCampusRecruitement) {
        this.$store.dispatch(
          "$_applicants_data/fetchCampusEmails",
          value.belongsToCampusRecruitement.id
        );
      }
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @param {String} value
     * @description Marks if the round is interview round
     */
    "round.round_name"(value) {
      if (value === "Interview") {
        this.interviewerChecker = true;
        this.formTemplateChecker = true;
      }
      if (value === "Review") {
        this.reviewerChecker = true;
        this.formTemplateChecker = true;
      }
    },
  },
  validations() {
    const round = {
      round_schedule: {
        required,
      },
    };
    if (this.round.round_name === "Interview") {
      round.interviewers_id = {
        required,
      };
    }
    if (this.round.round_name === "Review") {
      round.reviewers_id = {
        required,
      };
    }
    return {
      round,
    };
  },
  methods: {
    triggerReset() {
      if (typeof this.refreshParentPage === "function") {
        this.refreshParentPage();
      }
    },
    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description Required validation for date picker
     */
    datePickerRequired() {
      if (!this.round.round_schedule) {
        this.datePickerFlag = true;
      } else {
        this.datePickerFlag = false;
      }
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description Add round of applicant
     */
    createRound() {
      this.round.isMailed = false;
      this.round.applicantIterationMasterId = this.id;
      this.round.round_schedule = new Date(this.round.round_schedule);
      this.round.round_result = "Pending";
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description Add round of applicant
     */
    cancel() {
      let roundInfo = {
        round_name: "Aptitude",
        round_schedule: null,
        isMailed: false,
        round_result: null,
        interviewers_id: [],
        reviewers_id: [],
      };
      this.round = roundInfo;
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description Add round and mail
     */
    createRoundAndMail() {
      this.round.isMailed = false;
      this.round.applicantIterationMasterId = this.applicantData.id || null;
      this.round.round_schedule = new Date(this.round.round_schedule);
      this.round.round_result = "Pending";
      if (Array.isArray(this.applicantData)) {
        if (this.applicantData.length > 1) {
          this.roundsMany = this.applicantData.map((applicant) => ({
            ...this.round,
            applicantIterationMasterId: applicant.id,
          }));
          api
            .addRound(this.roundsMany)
            .then(() => {
              this.triggerReset();
              this.$store.dispatch(
                "openSnackbar",
                {
                  type: "success",
                  message: "Round added successfully",
                },
                {
                  root: true,
                }
              );
            })
            .catch(() => {
              this.$store.dispatch(
                "openSnackbar",
                {
                  type: "danger",
                  message: "Problem adding round! Try again later",
                },
                {
                  root: true,
                }
              );
            });
        } else {
          this.round.applicantIterationMasterId = this.applicantData[0].id;
          this.$store.dispatch("$_applicant_profile/addRoundAndMail", {
            data: this.round,
            redirect: this.$route.fullPath,
          });
          this.triggerReset();
        }
      } else {
        this.$store.dispatch("$_applicant_profile/addRoundAndMail", {
          data: this.round,
          redirect: this.$route.fullPath,
        });
      }
    },

    /**
     * @memberOf module:ApplicantProfile.components.add_round_modal
     * @description Add campus round and mail
     */
    addCampusRoundAndMail() {
      this.round.isMailed = false;
      this.round.applicantIterationMasterId = this.applicantData.id;
      this.round.round_schedule = new Date(this.round.round_schedule);
      this.round.round_result = "Pending";
      let key = null;
      if (this.isCoordinator && this.isStudent) {
        key = "both";
      } else if (this.isCoordinator) {
        key = "coordinator";
      } else if (this.isStudent) {
        key = "student";
      }
      this.$store.dispatch("$_applicant_profile/addCampusRoundAndMail", {
        data: {
          roundData: this.round,
          type: key,
          toBeMailed: this.campusMails,
        },
        redirect: this.$route.fullPath,
      });
    },

    toggleDatePicker() {
      $("#datePickerAdd").data("DateTimePicker").toggle();
    },
    getUsers() {
      this.$store.dispatch("$_applicant_profile/usersDetails");
    },
    getFormsTemplates() {
      this.$store.dispatch("$_form_template/getAllFormMasters");
    },
  },
  mounted() {
    $("#interviewerpicker").on("hidden.bs.select", () => {
      this.isInterviewersTouched = true;
    });
    $("#reviewerpicker").on("hidden.bs.select", () => {
      this.isReviewersTouched = true;
    });
  },
  created() {
    this.getUsers();
    this.getFormsTemplates();
  },
  updated() {
    if (this.interviewerChecker) {
      let interviewpicker = document.getElementById("interviewerpicker");
      if (interviewpicker != null) {
        $("#interviewerpicker")
          .selectpicker("destroy")
          .attr("title", "select interviewers")
          .selectpicker("render");
      }
      this.interviewerChecker = false;
    }
    if (this.reviewerChecker) {
      let reviewpicker = document.getElementById("reviewerpicker");
      if (reviewpicker != null) {
        $("#reviewerpicker")
          .selectpicker("destroy")
          .attr("title", "select reviewers")
          .selectpicker("render");
      }
      this.reviewerChecker = false;
    }
    if (this.formTemplateChecker) {
      let formpicker = document.getElementById("formTemplatepicker");
      if (formpicker != null) {
        $("#formTemplatepicker")
          .attr("title", "select template")
          .selectpicker("render");
      }
      this.formTemplateChecker = false;
    }
  },
  beforeDestroy() {
    $("#addRound").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>
