export const dictionary = {
  en: {
    messages: {
      required: (field) => `${getFieldName(field)} is Required!`,
      alpha_spaces: () => "Only alphabets and spaces are allowed!",
      min: (field, value) =>
        `${getFieldName(field)} must contain atleast ${value} letters!`,
      max: (field, value) =>
        `${getFieldName(field)} may not be greater than ${value} characters!`,
      email: () => `Email must be valid!`,
      numeric: (field) => `${getFieldName(field)} only contain numeric value!`,
      length: (field, value) =>
        `${getFieldName(field)} must be of ${value} digits!`,
    },
  },
};
function getFieldName(field) {
  return field
    .split("_")
    .map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1);
    })
    .join(" ");
}

export const isSameAlternateNo = {
  getMessage(field) {
    return `${getFieldName(field)} must be different!`;
  },
  validate(value, args) {
    return value != args[0];
  },
};
export const isextantionValid = {
  getMessage(field, args) {
    return `${getFieldName(field)} extension should be ${args.join(", ")}!`;
  },
  validate(value, args) {
    let isValid = true;
    for (let file of value) {
      const fileName = file.name;
      const ext = fileName.substring(fileName.lastIndexOf(".") + 1);
      if (args.findIndex((el) => el === ext) === -1) {
        isValid = false;
      }
    }
    return isValid;
  },
};

export const beforeDate = {
  getMessage(field, args) {
    return `${getFieldName(field)} must be before ${new Date(
      args
    ).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })}!`;
  },
  validate(value, args) {
    value = new Date(value);
    args = new Date(args);
    return value.getTime() < args.getTime();
  },
};

export const screenSizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
export const isScreenSizeGreaterThan = (
  screenSizeAbbreviation = "sm",
  currentScreenSize
) => {
  const screenSizePixels = screenSizes[screenSizeAbbreviation];
  return currentScreenSize >= screenSizePixels;
};

export const getFilterColumns = (
  columns,
  max_pinned = 0,
  checkScreenSize = "sm"
) => {
  try {
    if (isScreenSizeGreaterThan(checkScreenSize, window.innerWidth)) {
      return columns;
    }
    let pinned_count = 0;
    return columns.map((col) => {
      if (!max_pinned || pinned_count >= max_pinned) {
        return { ...col, pinned: null };
      } else {
        if (col.pinned) pinned_count++;
        return col;
      }
    });
  } catch (error) {
    console.log("error", error);
    return columns;
  }
};
