import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  forms: [],
  formVersions: [],
  formSaved: "false",
  editorMode: "N",
  editorFormFetchError: false,
  editorActiveFormMaster: {},
  editorActiveFormVersion: {
    formMasterId: null,
    version: null,
    form_json: {
      title: "Java Dev Form",
      description: "",
      sections: [
        {
          title: "Untitled Section",
          description: "",
          key: "af242270-4aa1-43f5-88bc-566c60726d28",
          body: [
            {
              key: "24df16cd-8316-478b-9a87-c0d3566603ff",
              label: "First Name",
              type: "line",
              properties: {},
              useValidation: false,
              validations: [],
            },
          ],
        },
      ],
    },
    status: "N",
    date: "31 Jan 2023",
  },
};

/**
 * @memberOf module:ManageJob
 * @namespace store
 * @description central store for manage job module
 */
export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
