/**
 * @memberOf module:ManageReview.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Review Catrgories and Reviews
 */
const reviewCategoriesWithReviews = (state) =>
  state.reviewCategoriesWithReviews;

/**
 * @memberOf module:ManageReview.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Review Category
 */
const reviewCategoryDataForAddReviewModal = (state) =>
  state.reviewCategoryDataForAddReviewModal;

/**
 * @memberOf module:ManageReview.store.getters
 * @param {Object} state holds store properties for current module
 * @description return updated data with deleted Review Category and Reviews
 */
const deleteItemModal = (state) => state.itemDeleteData;

/**
 * @memberOf module:ManageReview.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Review
 */
const editReviewModal = (state) => state.reviewDataForEditReviewModal;

/**
 * @memberOf module:ManageReview.store.getters
 * @param {Object} state holds store properties for current module
 * @description return data of Review Category
 */
const editReviewCategoryModal = (state) =>
  state.reviewCategoryDataForEditReviewCategoryModal;

/**
 * @memberOf module:ManageReview
 * @namespace store.getters
 * @description functions returning state values
 */
export default {
  reviewCategoriesWithReviews,
  reviewCategoryDataForAddReviewModal,
  deleteItemModal,
  editReviewModal,
  editReviewCategoryModal,
};
