import PERMISSIONS from "../Common/permissions";
import _applicationForm from "./index.vue";

export default {
  path: "applicants/:slug/update",
  component: _applicationForm,
  name: "editApplicantData",
  meta: {
    title: "Edit Applicant Data",
    user: "employee",
    permissions: [PERMISSIONS.APPLICANT.UPDATE],
  },
};
