import _mailbox_home from "./index.vue";
import _mail_dashboard from "./_components/mail-dashboard";
import _mailbox_parent from "./_components/mailbox-parent";
import PERMISSIONS from "../Common/permissions";
import MangaeHtmlTemplateRoutes from "../HtmlTemplate/_routes";

export default {
  path: "mails",
  component: _mailbox_home,

  children: [
    {
      path: "",
      component: _mail_dashboard,
      name: "mailDashboard",
      meta: {
        title: "Mail Status",
        user: "employee",
        permissions: [PERMISSIONS.MAIL.MENU],
        checkAll: true,
      },
    },
    {
      path: "compose-mail",
      name: "mailbox",
      props: { templateKey: null },
      component: _mailbox_parent,
      meta: {
        title: "Compose Mail",
        user: "employee",
        permissions: [
          { permissions: [PERMISSIONS.MAIL.TEMPLATE.READ], checkAll: true },
          { permissions: [PERMISSIONS.MAIL.MENU], checkAll: true },
        ],
        groupPermissions: true,
      },
    },
    MangaeHtmlTemplateRoutes,
  ],
};
