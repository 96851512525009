<template>
  <div v-if="params.data">
    <i
      v-if="params.data.status === 'Completed'"
      class="fa fa-clone fa-lg text-secondary"
      aria-hidden="true"
      :id="'copy-url-completed-popover-target-1' + params.rowIndex"
    ></i>
    <i
      v-else
      role="button"
      class="fa fa-clone fa-lg not-completed"
      aria-hidden="true"
      @click="copyURL()"
      :id="'copy-url-pending-popover-target-1' + params.rowIndex"
    ></i>
    <b-popover
      v-if="params.data.status === 'Completed'"
      :target="'copy-url-completed-popover-target-1' + params.rowIndex"
      triggers="hover"
      placement="right"
    >
      Drive finished already!
    </b-popover>
    <b-popover
      v-else
      :target="'copy-url-pending-popover-target-1' + params.rowIndex"
      triggers="hover"
      placement="right"
    >
      Copy URL
    </b-popover>
  </div>
</template>

<script>
import { BASE_URL } from "../../../config";
export default {
  methods: {
    /**
     * @memberof module:campusRecruitment.components.copy-form-url-button-render
     * @description handle click of copy url button
     */
    copyURL() {
      if (this.params && this.params.context) {
        this.params.context.componentParent.copyUrlFunction(
          `${BASE_URL}campus-recruitment-form/${this.params.data.unique_id}`
        );
      }
    },
  },
};
</script>
<style scoped>
.not-completed {
  color: #337ab7;
}
</style>
