import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  template: `
    <div class="ag-custom-filter">
    <input class="ag-text-field-input ml-2 mt-2" type="text" placeholder="Search Employees" v-model="searchInput"/>
      <ul class="selectable-list borderless">
        <li v-for="user in filteredUsers" :value="user.id" :key="user.id" @click="selectUser(user)" :class="{ active: user.id === selectedOption }">{{ user.fullName }}</li>
      </ul>
      <button @click="resetFilter" class="float-right mr-2 mb-2">Reset</button>
    </div>
  `,
  data() {
    return {
      usersFullName: [],
      selectedOption: "",
      searchInput: "",
    };
  },
  beforeMount() {
    this.getAllEmployees();
  },
  computed: {
    filteredUsers() {
      if (this.searchInput.trim() === "") {
        return this.usersFullName;
      } else {
        const searchKeyword = this.searchInput.toLowerCase().trim();
        return this.usersFullName.filter((user) => {
          return user.fullName.toLowerCase().includes(searchKeyword);
        });
      }
    },
  },
  methods: {
    getAllEmployees() {
      axios
        .get("/users")
        .then((response) => {
          const users = response.data;
          this.usersFullName = users.map((user) => ({
            id: user.user_id,
            fullName: user.first_name + " " + user.last_name,
          }));
        })
        .catch((error) => {
          console.error("Error fetching employees:", error);
        });
    },
    selectUser(user) {
      this.setModel(user);
      this.updateFilter(user);
    },
    updateFilter(user) {
      this.params.filterChangedCallback(user);
    },
    doesFilterPass(params) {
      const cellValue = params.data[this.params.column.field];
      return cellValue === this.selectedOption;
    },
    isFilterActive() {
      return this.selectedOption !== "";
    },
    getModel() {
      return this.selectedOption;
    },
    setModel(user) {
      this.selectedOption = user
        ? {
            filter: user.id,
            filterType: "userList",
            type: "equals",
            filterFullName: user.fullName,
          }
        : "";
    },
    resetFilter() {
      this.selectedOption = "";
      this.searchInput = "";
      this.updateFilter(null);
    },
  },
});
