<template>
  <div class="row mx-0" style="width: 100%" data-offset-top="55">
    <div
      class="col-lg-12"
      v-if="hasAccess({ permissions: [BASE_PERMISSION.READ] })"
    >
      <div class="well container">
        <h4 class="d-flex justify-content-between flex-wrap align-items-center">
          <strong>New Applicant's Details</strong>
          <button
            v-if="
              hasAccess({
                permissions: [BASE_PERMISSION.DUPLICATE.UPDATE],
              })
            "
            class="btn btn-dark ml-auto"
            @click="rejectAllMatchedApplications(newApplicantData.id)"
          >
            Differ All Matched
          </button>
        </h4>
        <div class="table-responsive">
          <table
            class="table table-condensed"
            style="font-size: 14px"
            aria-label="New Applicant's Details"
            aria-hidden="true"
          >
            <tbody v-if="newApplicantData">
              <tr>
                <td class="col-md-1">
                  <strong>Name</strong>
                </td>
                <td class="col-md-2">
                  {{ newApplicantData.first_name }}
                  {{ newApplicantData.last_name }}
                  <em
                    title="Belongs to Campus Drive"
                    v-if="newApplicantData.belongsToCampusRecruitement"
                    class="fa fa-graduation-cap fa-lg"
                  >
                  </em>
                </td>
                <td class="col-md-1">
                  <strong>Application Date</strong>
                </td>
                <td class="col-md-2">
                  {{ newApplicantData.createdAt | dateTimeFormat }}
                </td>

                <td class="col-md-1">
                  <strong>Applied for Position</strong>
                </td>
                <td class="col-md-2">{{ newApplicantData.position }}</td>
                <td class="col-md-1">
                  <strong>Application Status</strong>
                </td>
                <td class="col-md-2">
                  {{ newApplicantData.application_status }}
                </td>
              </tr>

              <tr>
                <td class="col-md-1">
                  <strong>Email</strong>
                </td>
                <td class="col-md-2">{{ newApplicantData.email }}</td>
                <td class="col-md-1">
                  <strong>Mobile No</strong>
                </td>
                <td class="col-md-2">{{ newApplicantData.mobile_no }}</td>

                <td class="col-md-1">
                  <strong>Alt. Mobile No</strong>
                </td>
                <td class="col-md-2">
                  {{ newApplicantData.alternate_mobile_no }}
                </td>
                <td class="col-md-1">
                  <strong>Date Of Birth</strong>
                </td>
                <td class="col-md-2">
                  {{ newApplicantData.dob | dateFormat }}
                </td>
              </tr>

              <tr>
                <td class="col-md-1">
                  <strong>Gender</strong>
                </td>
                <td class="col-md-2">{{ newApplicantData.gender }}</td>

                <td class="col-md-1">
                  <strong>Permenent Address</strong>
                </td>
                <td class="col-md-2" v-if="newApplicantData.address_line_1">
                  {{ newApplicantData.address_line_1 }},
                  {{ newApplicantData.address_line_2 }},
                  {{ newApplicantData.city }}, {{ newApplicantData.state }}
                </td>
                <td class="col-md-2" v-else>-</td>

                <td class="col-md-1">
                  <strong>Current Address</strong>
                </td>
                <td
                  class="col-md-2"
                  v-if="newApplicantData.current_address_line_1"
                >
                  {{ newApplicantData.current_address_line_1 }},
                  {{ newApplicantData.current_address_line_2 }},
                  {{ newApplicantData.current_city }},
                  {{ newApplicantData.current_state }}
                </td>
                <td class="col-md-2" v-else>-</td>

                <td class="col-md-1">
                  <strong>View Profile</strong>
                </td>
                <td class="col-md-2">
                  <router-link
                    target="_blank"
                    :to="{
                      name: 'applicantprofile',
                      params: { slug: newApplicantData.slug },
                    }"
                  >
                    <i
                      class="fa fa-user-circle-o fa-lg"
                      aria-hidden="true"
                      style="color: #114c47"
                    ></i
                    >View
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <common-modal
      :id="commonModalData.id"
      :modal-title="commonModalData.title"
      :modal-body="commonModalData.body"
      :show-cancel="commonModalData.showCancel"
      :modal-color="commonModalData.color"
      :modal-size-class="commonModalData.sizeClass"
      @actionPerformed="emitOnCurrent"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonModal from "../../Common/_components/modal";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

/**
 * @memberof module:ResolveDuplicate
 * @namespace components.new_applicant_data
 */
export default {
  components: {
    CommonModal,
  },

  /**
   * @memberof module:ResolveDuplicate.components.new_applicant_data
   * @description lifecycle method - dispatch request of fetching new applicant data
   */
  created() {
    this.getapplicantData();
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    BASE_PERMISSION() {
      return this.PERMISSIONS.APPLICANT;
    },
    ...mapGetters({
      newApplicantData: "$_resolve_duplicate/fetchNewApplicantData",
    }),
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    getapplicantData() {
      if (!this.hasAccess({ permissions: [this.BASE_PERMISSION.READ] })) return;
      this.$store.dispatch(
        "$_resolve_duplicate/fetchNewApplicantData",
        this.$route.params.id
      );
    },
    /**
     * @memberof module:ResolveDuplicate.components.new_applicant_data
     * @param {Number} newApplicantId Id of new applicant
     * @description method - handles to differ all matched applicants
     */
    rejectAllMatchedApplications(newApplicantId) {
      if (
        !this.hasAccess({
          permissions: [this.BASE_PERMISSION.DUPLICATE.UPDATE],
        })
      )
        return;
      this.openDialog(
        "commonModal2",
        "Confirmation",
        "Are you sure you want to differ all matched applicant(s) as duplicate ?",
        true,
        "warn",
        ""
      ).then(() => {
        this.$store.dispatch(
          "$_resolve_duplicate/rejectAllDuplicate",
          newApplicantId
        );
      });
    },
  },
};
</script>

<style scoped>
.well {
  background-color: transparent;
  margin-top: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 5px;
}

.well:hover {
  box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

h4 {
  padding-top: 5px;
}

.table > tbody > tr > td {
  border-top: 1px solid #878a91;
}
</style>
