<template>
  <div
    class="content"
    v-if="
      hasAccess({
        permissions: [PERMISSIONS['SHARED-TOKEN'].MENU],
        checkAll: false,
      })
    "
  >
    <div class="page-header">
      <common-header :data="['home', 'manage', 'sharedToken']">
        <div
          class="text-right"
          v-if="
            hasAccess({
              permissions: [PERMISSIONS['SHARED-TOKEN'].CREATE],
              checkAll: false,
            })
          "
        >
          <a class="btn btn-cp" @click="goToAddTokenPage">
            <i class="fa fa-plus mr-2" aria-hidden="true"></i>Create</a
          >
        </div>
      </common-header>
    </div>

    <div class="container-fluid py-2">
      <ag-grid-vue
        :gridOptions="gridOptions"
        :rowData="sharedTokens"
        :columnDefs="columnDefs"
        class="ag-theme-alpine ag-grid-style ag-grid-footer-style"
      >
      </ag-grid-vue>
    </div>
    <DeleteModal :id="id" />
    <EndpointModal
      :selectedEndpoints="selectedEndpoints"
      ref="endpointModal"
      @update:selectedEndpoints="updateSelectedEndpoints"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import DeleteModal from "./delete-token-modal.vue";
import EndpointModal from "./view-endpoint-modal.vue";
import { hasAccess } from "@/modules/User/_utils/actions";
import PERMISSIONS from "@/modules/Common/permissions";
import SharedTokenActionButtonCell from "./shared-token-action-cell.vue";

export default {
  data() {
    return {
      abc: "jsnskjcnjdknjk",
      id: null,
      check: false,
      sharedTokens: [],
      gridOptions: null,
      applicantMaster: null,
      applicantData: null,
      showAddToken: false,
      selectedEndpoints: [],
      pageSize: 10,
      columnDefs: [
        {
          headerName: "Title",
          field: "title",
          width: 200,
          minWidth: 150,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Description",
          field: "description",
          width: 200,
          minWidth: 150,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cellRenderer: this.descriptionCellRenderer,
        },
        {
          headerName: "Contact Email",
          field: "email",
          width: 200,
          minWidth: 150,
          filter: "agTextColumnFilter",
          floatingFilter: true,
        },
        {
          headerName: "Expiry Date",
          field: "expiredAt",
          width: 200,
          minWidth: 150,
          filter: "agDateColumnFilter",
          floatingFilter: true,
          valueFormatter: (params) => {
            const date = moment(params.value);
            const now = moment();
            if (date.isValid() && date.diff(now, "years") > 50) {
              return "Never";
            } else {
              return date.format("DD/MM/YYYY");
            }
          },
          filterParams: {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              const cellDate = new Date(cellValue);
              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              } else {
                return 0;
              }
            },
          },
        },
        {
          headerName: "Endpoints",
          cellRenderer: "view-endpoints-button",
          field: "endpoints",
          width: 200,
          minWidth: 150,
        },
        {
          headerName: "Active",
          cellRenderer: "active-button",
          field: "isActive",
          width: 200,
          minWidth: 150,
        },
        {
          headerName: "Actions",
          cellRenderer: "action-button",
          cellRendererParams: { handleDeleteToken: this.handleDeleteToken },
          pinned: "right",
          width: 200,
          minWidth: 150,
        },
      ],
      defaultColDef: {
        resizable: true,
        sortable: true,
        flex: 1,
        floatingFilter: true,
        filter: true,
      },
    };
  },
  beforeMount() {
    this.configGridOptions();
  },
  mounted() {
    this.fetchAllSharedTokens();
  },
  components: {
    AgGridVue,
    DeleteModal,
    EndpointModal,
    // eslint-disable-next-line vue/no-unused-components
    "action-button": SharedTokenActionButtonCell,
    // eslint-disable-next-line vue/no-unused-components
    "view-endpoints-button": {
      template: `
    <div style=" justify-content: start;">
      <button style="
        font-size: 12px;
        width:80px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s;"
        class="btn btn-outline-primary"
        @click="viewEndpointsHandler(params.value)"
      >
        View
      </button>
    </div>
  `,
      methods: {
        viewEndpointsHandler(endpoints) {
          this.params.context.componentParent.viewEndpointsHandler(endpoints);
        },
      },
    },
    // eslint-disable-next-line vue/no-unused-components
    "active-button": {
      template: `<div class="mt-2"><b-form-checkbox v-model="params.data.isActive" @change="activeHandler()" name="check-button" switch
        :disabled="new Date(params.data.expiredAt) < new Date()"
        />
    </div>`,
      methods: {
        activeHandler() {
          const { id, isActive } = this.params.data;
          this.$store.dispatch("$_shared_token/updateSharedToken", {
            id,
            isActive,
          });
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      sharedTokensFromStore: "$_shared_token/getAllSharedTokens",
    }),
    PERMISSIONS() {
      return PERMISSIONS;
    },
  },
  watch: {
    sharedTokensFromStore(val) {
      this.sharedTokens = val;
      this.componentKey++;
    },
  },
  methods: {
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    openEndpointModal() {
      $("#assignEndpointModal").modal("show");
    },
    viewEndpointsHandler(endpoints) {
      this.$refs.endpointModal.showModal(endpoints);
    },
    updateSelectedEndpoints(endpoints) {
      this.selectedEndpoints = endpoints;
    },
    handleDeleteToken(id) {
      this.id = id;
      $("#deleteModal").modal("show");
    },
    goToAddTokenPage() {
      this.$router.push({ name: "AddToken" });
    },
    submitForm(formData) {
      if (!this.id && this.id !== 0) {
        this.$refs.linkRequestComment.hide();
        return;
      }
      const updateLinkRequestFormData = { id: this.id, ...formData };
      this.$store.dispatch(
        "$_applicants_data/updateLinkRequest",
        updateLinkRequestFormData
      );
      this.$refs.linkRequestComment.hide();
    },
    async fetchAllSharedTokens() {
      await this.$store.dispatch("$_shared_token/fetchAllSharedTokens");
      this.$store.dispatch("$_shared_token/fetchEndpointObject");
    },
    redirectToAddToken() {
      this.$router.push({ name: "AddToken" });
    },
    descriptionCellRenderer(params) {
      const value = params.value || "";
      const maxLength = 20;
      let displayValue =
        value.length > maxLength ? value.slice(0, maxLength) + "..." : value;
      const tooltipField = value.length > maxLength ? "description" : undefined;
      return `<div class="custom-tooltip" title="${
        tooltipField ? params.data.description : ""
      }">${displayValue}</div>`;
    },
    configGridOptions() {
      this.gridOptions = {
        colResizeDefault: true,
        enableCellTextSelection: true,
        defaultColDef: {
          flex: 1,
          resizable: true,
          sortable: true,
          filter: "",
          menuTabs: [],
        },
        enableBrowserTooltips: true,
        columnDefs: this.columnDefs.map((columnDef) => ({
          ...columnDef,
          suppressMovable: true,
        })),
        pagination: false,
        getRowStyle: function (params) {
          if (new Date(params.data.expiredAt) < new Date()) {
            return { background: "#f2dede" };
          }
        },
        paginationPageSizeSelector: false,
        paginationPageSize: this.pageSize,
        context: {
          componentParent: this,
        },
      };
      if (this.gridOptions.api) {
        this.gridOptions.api.setRowData(
          Array.isArray(this.sharedTokens) ? this.sharedTokens : []
        );
      }
    },
    setRowData() {
      if (this.gridOptions && this.sharedTokens) {
        this.gridOptions.api.setRowData(this.sharedTokens);
      }
    },
  },
  beforeDestroy() {
    $("#endpointModal").modal("hide");
    $(".modal-backdrop").remove();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.ag-grid-style {
  min-height: 500px;
  height: calc(100vh - 130px);
}

/* MEDIA QUERY FOR SMALL SCREEN */
@media screen and (max-width: 336px) {
  .ag-grid-style {
    min-height: 400px;
    height: calc(100vh - 180px);
  }
}

/* MEDIA QUERY FOR SMALLER SCREEN */
@media screen and (max-width: 255px) {
  .ag-grid-style {
    min-height: 300px;
    height: calc(100vh - 210px);
  }
}
</style>
