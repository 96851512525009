var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.hasAccess({ permissions: [_vm.BASE_PERMISSION.READ] }))?[(_vm.params.data != undefined && _vm.params.data.hasDuplicate.length != 0)?_c('a',{on:{"click":function($event){return _vm.resolveDuplicate(_vm.params.data.id)}}},[_c('i',{staticClass:"fa fa-link",staticStyle:{"color":"red","cursor":"pointer"},attrs:{"data-toggle":"tooltip","data-placement":"right","title":'Resolve Duplicate for ' +
          _vm.params.data.first_name +
          ' ' +
          _vm.params.data.last_name,"aria-hidden":"true"}})]):_vm._e()]:_vm._e(),(
      _vm.params.data != undefined &&
      _vm.params.data.hasDuplicate.length == 0 &&
      _vm.params.data.status == 'Duplicate'
    )?_c('a',[_c('i',{staticClass:"fa fa-files-o",staticStyle:{"color":"red","cursor":"pointer"},attrs:{"data-toggle":"tooltip","data-placement":"right","title":"Duplicate applicant","aria-hidden":"true"}})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }