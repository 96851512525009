import api from "../_api";
import moment from "moment";
import {
  addSnackbarMessage,
  addSuccessSnackbarMessage,
} from "../../Common/_plugin/error-response";

const sortStatusCount = (data) => {
  const applicationStatusList = [
    "Received",
    "Shortlisted",
    "Joined",
    "Differed",
    "Held",
    "Aptitude Scheduled",
    "Aptitude Passed",
    "Aptitude Cancelled",
    "Aptitude Failed",
    "Written Test Scheduled",
    "Written Test Passed",
    "Written Test Cancelled",
    "Written Test Failed",
    "Interview Scheduled",
    "Interview Passed",
    "Interview Cancelled",
    "Interview Failed",
  ];
  applicationStatusList.forEach((status) => {
    const result = data.find((obj) => obj.status_name === status);
    let tempStatus = {};
    if (result == null) {
      tempStatus.status_name = status;
      tempStatus.status_count = "0";
      data.push(tempStatus);
    }
  });
  data.sort((a, b) => {
    return parseInt(b.status_count) - parseInt(a.status_count);
  });
  return data;
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterOptions
 * @description get data of each application status and their count
 */
const fetchApplicationStatusCount = (context, filterOptions) => {
  api
    .fetchApplicationStatusCount(filterOptions)
    .then((response) => {
      const data = response.data;
      let sortedData = sortStatusCount(data);
      context.commit("FETCH_APPLICATION_STATUS_COUNT", sortedData);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberOf module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} filterOptions
 * @description get data of each application status and their count
 */
const fetchApplicationPositionCount = (context, filterOptions) => {
  api
    .fetchApplicationPositionCount(filterOptions)
    .then((response) => {
      const data = response.data;
      context.commit("FETCH_APPLICATION_POSITION_COUNT", data);
    })
    .catch((error) => {
      console.log(error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

/**
 * @memberof module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} session
 * @description set selected session
 */
const setSelectedSession = (context, session) => {
  context.commit("SET_SELECTED_SESSION", session);
};

/**
 * @memberof module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} loggedInUser
 * @description set logged in user
 */
const setLoggedInUser = (context, loggedInUser) => {
  context.commit("SET_LOGGED_IN_USER", loggedInUser);
};

/**
 * @memberof module:ApplicantsData.store.actions
 * @param {Object} context
 * @description set entities, college and session
 */
const entityListWithSessions = (context) => {
  api
    .entityListWithSessions()
    .then((res) => {
      const entityListDetails = res.data;
      context.commit("ENTITY_LIST_DETAILS", entityListDetails);
      if (entityListDetails.entity_type === "University") {
        const collegeList = entityListDetails.childEntities;
        let sessionExist = false;
        let selectedCollege = "";

        for (let college of collegeList) {
          if (college.campusRecruitments.length > 0) {
            selectedCollege = college;
            sessionExist = true;
            break;
          }
        }

        if (!sessionExist && collegeList.length > 0) {
          selectedCollege = collegeList[0];
        }
        let sessionList = selectedCollege.campusRecruitments;
        context.commit("SET_SELECTED_COLLEGE", selectedCollege);
        let selectedSession = "";
        const currentDate = moment();
        if (sessionList) {
          selectedSession = setSelectedSessionHelper(sessionList, currentDate);
        }
        context.commit("SET_SELECTED_SESSION", selectedSession);
      } else {
        context.commit("SET_SELECTED_COLLEGE", entityListDetails);
        let sessionList = entityListDetails.campusRecruitments;
        let selectedSession = "";
        const currentDate = moment();
        if (sessionList) {
          selectedSession = setSelectedSessionHelper(sessionList, currentDate);
        }
        context.commit("SET_SELECTED_SESSION", selectedSession);
      }
    })
    .catch((error) => {
      console.log("error", error);
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};

const setSelectedSessionHelper = (sessionList, currentDate) => {
  let selectedSession = "",
    exist = false;

  for (let session of sessionList) {
    if (
      session.status === "inProgress" &&
      !moment(session.last_date).isBefore(currentDate)
    ) {
      exist = true;
      selectedSession = session;
      break;
    }
  }
  if (!exist && sessionList.length > 0) {
    selectedSession = sessionList[0];
  }
  return selectedSession;
};

/**
 * @memberof module:ApplicantsData.store.actions
 * @param {Object} context
 * @param {Object} entity
 * @description set selected college
 */
const setSelectedCollege = (context, entity) => {
  context.commit("SET_SELECTED_COLLEGE", entity);
  let exist = false;
  const sessionList = entity.campusRecruitments;
  let selectedSession = "";
  const currentDate = moment();
  if (sessionList) {
    for (let session of sessionList) {
      if (
        session.status === "inProgress" &&
        !moment(session.last_date).isBefore(currentDate)
      ) {
        selectedSession = session;
        exist = true;
        break;
      }
    }
    if (!exist && sessionList.length > 0) {
      selectedSession = sessionList[0];
    }
    context.commit("SET_SELECTED_SESSION", selectedSession);
  }
};

const setLoggedInRecruiter = (context) => {
  api
    .fetchRecruiterData()
    .then((res) => {
      context.commit("SET_LOGGED_IN_USER", res.data);
    })
    .catch((error) => {
      addSnackbarMessage(error, "Problem retrieving data!", true);
    });
};
/**
 * @memberof module:campusRecruitment
 * @namespace store.actions
 * @description actions for mailbox module
 */
const recruiterResetPasswordRequest = (context, data) => {
  return api
    .recruiterResetPasswordRequest(data)
    .then((response) => {
      addSuccessSnackbarMessage(response.data.message);
      return response;
    })
    .catch((error) => {
      console.log("recruiterResetPasswordRequest error", error);
      addSnackbarMessage(error, "Problem in sending forgot password request");
    });
};
export default {
  fetchApplicationStatusCount,
  fetchApplicationPositionCount,
  setSelectedSession,
  setLoggedInUser,
  entityListWithSessions,
  setSelectedCollege,
  setLoggedInRecruiter,
  recruiterResetPasswordRequest,
};
