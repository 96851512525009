var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('common-header',{attrs:{"data":['home', 'jobOpenings'],"type":"applicant"}}),_c('div',{staticClass:"container-fluid"},[(_vm.applicant.employment_status === 'Fresher')?_c('div',[_c('div',{staticClass:"job_card_wrapper"},[_c('div',{staticClass:"job_card"},[_vm._m(0),_c('p',{staticClass:"text-justify"},[_vm._v(" We are seeking passionate and driven freshers who are eager to learn, grow, and contribute to our team. While specific technical skills are a plus, we value qualities such as curiosity, adaptability, and a positive attitude. ")]),_c('div',{staticClass:"job_card_footer"},[(_vm.canApplyJobs['fresher'])?_c('strong',[_vm._v("Already Applied !")]):_c('router-link',{attrs:{"to":{
                name: 'jobApplicationForm',
                query: { isFresher: 'true' },
              },"tag":"a"}},[_vm._v("Apply Now "),_c('i',{staticClass:"fa fa-long-arrow-right",attrs:{"aria-hidden":"true"}})])],1)])])]):_c('div',[(_vm.isDisplayDataAvailable)?_c('div',{staticClass:"job_card_wrapper"},_vm._l((_vm.sortedData),function(job){return _c('div',{key:job.id,staticClass:"job_card"},[_c('div',{staticClass:"job_card_header"},[_c('img',{attrs:{"alt":"Job","src":require("../../../assets/images/job_icon.jpg")}}),_c('h2',[_vm._v(_vm._s(job.job_name))])]),_c('p',{staticStyle:{"height":"70px","white-space":"break-spaces"}},[_c('span',[_vm._v("Required Skills ")]),_vm._v(" "+_vm._s(job.required_skills.join(", "))+" ")]),_c('p',[_c('span',[_vm._v("Experience Required")]),_vm._v(" "+_vm._s(job.required_experience_min)+" "+_vm._s(_vm._f("expFormat")(job.required_experience_max,job.required_experience_min))+" years ")]),_c('div',{staticClass:"job_card_footer"},[_c('div',[(_vm.canApplyJobs[job.id])?_c('strong',[_vm._v("Already Applied !")]):_vm._e()]),_c('router-link',{attrs:{"to":{
                name: 'applicantJobProfile',
                params: { jobId: job.slug },
              },"tag":"a"}},[_vm._v("Specification "),_c('i',{staticClass:"fa fa-long-arrow-right",attrs:{"aria-hidden":"true"}})])],1)])}),0):_c('div',[_c('h4',{staticClass:"center-container"},[_vm._v("There are no Current Openings.")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"job_card_header"},[_c('img',{attrs:{"alt":"Job","src":require("../../../assets/images/job_icon.jpg")}}),_c('h2',[_vm._v("Fresher's Form")])])
}]

export { render, staticRenderFns }