<template>
  <home></home>
</template>

<script>
import Home from "./_components/home.vue";
import store from "./_store";

/**
 * @module ManageJob
 * @description manage job module
 */
export default {
  /**
   * @memberof module:ManageJob
   * @namespace components
   * @description components of manage job module
   */
  components: {
    Home,
  },
  created() {
    const STORE_KEY = "$_manage_static_template";

    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>
