<template>
  <div class="content">
    <common-header
      v-if="isApplicant"
      :data="['home', 'jobOpenings', 'singleJob']"
      :componentName="jobData.job_name"
      type="applicant"
    />
    <div :class="{ container: isApplicant }" v-if="jobData">
      <h1>{{ jobData.job_name }}</h1>
      <div class="wrapper-white-bg">
        <safe-content
          v-if="jobData.job_description"
          :content="jobData.job_description"
          className="description"
          tag="div"
        >
        </safe-content>
      </div>
      <div
        class="applybtn d-flex justify-content-center"
        v-if="!canApplyJobs[jobData.id]"
      >
        <router-link
          :to="{
            name: isApplicant ? 'jobApplicationForm' : 'applicationForm',
            query: { jobId },
          }"
          tag="a"
          class="btn btn-cp ml-2"
        >
          Apply Now</router-link
        >
      </div>
      <div v-else class="applybtn d-flex justify-content-center">
        <router-link
          :to="{
            name: 'applicantJobOpenings',
          }"
          tag="a"
          class="btn btn-cp ml-2"
        >
          Already applied go back</router-link
        >
      </div>
    </div>
    <div v-else class="inActiveJob">
      <h2>This opening is no longer available</h2>
      <div>
        We're sorry - the opening you were applying for has been filled! You can
        navigate back to the careers page to look for other opportunities
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @memberof module:Home
 * @namespace components.job_description
 */
import { mapGetters } from "vuex";

export default {
  /**
   * @memberof module:Home.components.job_description
   * @returns {Object} data object
   * @description data - returns data for current vue component
   */
  props: {
    isApplicant: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      jobId: this.$route.params.jobId,
      jobData: {},
    };
  },
  created() {
    this.$store.dispatch("$_home/fetchJobBySlug", this.jobId);
  },
  methods: {
    setJobData() {
      if (this.jobDetails && this.jobDetails.status === "active") {
        this.jobData = this.jobDetails;
      } else {
        this.jobData = null; //inactive job
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "This job is not available now!",
        });
        // this.$router.push({ name: "jobOpenings" });
      }
      if (!this.jobDetails) {
        this.$store.dispatch("openSnackbar", {
          type: "danger",
          message: "No such job exits!",
        });
        this.$router.push({ name: "jobOpenings" });
      }
    },
  },
  computed: {
    ...mapGetters({
      jobDetails: "$_home/getJobById",
    }),
    canApplyJobs() {
      return this.isApplicant
        ? this.$store.getters["$_applicant_master/getCanApplyJobs"]
        : {};
    },
  },
  watch: {
    /**
     * @memberof module:Home.components.job_description
     * @param {Object} value new value
     * @description watch - changes values of Current Opening when data is udpated from server
     */
    jobDetails() {
      this.setJobData();
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  destroyed() {
    $("#navmenu").removeClass("custom-header");
  },
};
</script>
<style scoped>
.description {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.applybtn {
  margin-top: 10px;
  margin-bottom: 10px;
}
.inActiveJob {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
</style>
