<template>
  <div
    id="addMoodleInstance"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title" id="myModalLabel">Add Moodle Instance</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="content-form">
            <div class="form-group position-relative">
              <label class="control-label" htmlFor=""
                >label
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                name="label"
                class="form-control"
                v-model="data.label"
                v-validate="'required'"
                data-vv-as="Label"
                data-vv-validate-on="blur|input"
                :class="{ invalid: errors.first('label') }"
              />
              <span
                v-if="errors.first('label')"
                class="valid-feedback alert-danger"
                >{{ errors.first("label") }}</span
              >
            </div>
            <div class="form-group position-relative">
              <label class="control-label" htmlFor="url"
                >url
                <span class="text-danger">*</span>
              </label>
              <input
                id="url"
                name="url"
                type="text"
                class="form-control"
                v-model="data.url"
                v-validate="'required'"
                data-vv-as="URL"
                data-vv-validate-on="blur|input"
                :class="{ invalid: errors.first('url') }"
              />
              <span
                v-if="errors.first('url')"
                class="valid-feedback alert-danger"
                >{{ errors.first("url") }}</span
              >
            </div>
            <div class="form-group position-relative">
              <label class="control-label" htmlFor="token"
                >ws_token
                <span class="text-danger">*</span>
              </label>
              <input
                id="token"
                name="token"
                type="text"
                class="form-control"
                v-model="data.ws_token"
                v-validate="'required'"
                data-vv-as="WS Token"
                data-vv-validate-on="blur|input"
                :class="{ invalid: errors.first('token') }"
              />
              <span
                v-if="errors.first('token')"
                class="valid-feedback alert-danger"
                >{{ errors.first("token") }}</span
              >
            </div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-success"
                @click="testConnection"
                :disabled="$v.data.$invalid"
              >
                Test Connection
              </button>
              <button
                type="button"
                class="btn btn-cp ml-2"
                @click="submitData"
                :disabled="$v.data.$invalid || !connectionOk"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button type="button" class="btn btn-dark" data-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import api from "../_api/index";

/**
 * @memberof module:campusRecruitment
 * @namespace components.add_campus_drive_modal
 */
export default {
  props: ["oldData"],
  data() {
    return {
      data: {
        label: "",
        url: "",
        ws_token: "",
      },
      connectionOk: false,
    };
  },
  validations() {
    const data = {
      label: {
        required,
      },
      url: {
        required,
      },
      ws_token: {
        required,
      },
    };
    return { data };
  },
  computed: {},
  components: {},
  mounted() {
    $("#addMoodleInstance").on("hidden.bs.modal", () => {
      this.data = {
        label: "",
        url: "",
        ws_token: "",
      };
      this.connectionOk = false;
    });
  },
  methods: {
    submitData() {
      api
        .addMoodleInstance(this.data)
        .then(() => {
          this.data = {
            label: "",
            url: "",
            ws_token: "",
          };
          this.connectionOk = false;
          $("#addMoodleInstance").modal("hide");
          this.$emit("submitted");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    testConnection() {
      api
        .testConnection(this.data)
        .then(() => {
          this.connectionOk = true;
        })
        .catch(() => {
          this.connectionOk = false;
          this.$store.dispatch(
            "openSnackbar",
            { type: "danger", message: "Connection can not be established" },
            { root: true }
          );
        });
    },
  },
  watch: {
    "data.label"() {
      this.connectionOk = false;
    },
    "data.url"() {
      this.connectionOk = false;
    },
    "data.ws_token"() {
      this.connectionOk = false;
    },
  },
  beforeDestroy() {
    $("#addMoodleInstance").modal("hide");
    $(".modal-backdrop").hide();
    $("body").removeClass("modal-open");
  },
};
</script>

<style scoped>
.m-15 {
  margin: 15px;
}
.bld {
  font-weight: bold;
}

#contanerFileUpload {
  margin: -15px;
}
</style>
