<template>
  <resolve-duplicate></resolve-duplicate>
</template>

<script>
import store from "./_store";
import ResolveDuplicate from "./_components/resolve-duplicate.vue";

/**
 * @module ResolveDuplicate
 * @description resolve_duplicate module
 */
export default {
  /**
   * @memberof module:ResolveDuplicate
   * @namespace components
   * @description components of resolve_duplicate module
   */
  components: {
    ResolveDuplicate,
  },
  created() {
    const STORE_KEY = "$_resolve_duplicate";

    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
};
</script>
