import _home from "./_components/home.vue";
import _applicationForm from "./_components/application-form.vue";
import _jobOpenings from "./_components/job-openings.vue";
import _jobDescription from "./_components/job-description.vue";
import _login from "../Login/index.vue";
import _registrationForm from "../ApplicantMaster/_components/registration-form.vue";
import _forgotPasswordRequest from "../ApplicantMaster/_components/forgot-password-request.vue";
import _applicantForgotPassword from "../ApplicantMaster/_components/forgot-password.vue";
import _401page from "../Login/401-page.vue";
import _403page from "../Login/403-page.vue";
import config from "../../config";
import VueCookie from "vue-cookie";
import _resetRecruiterPassword from "../Recruiter/_components/user-password-creation.vue";
import _recruiterResetPassword from "../Recruiter/_components/reset-password-request.vue";

export default [
  {
    path: "/",
    component: _home,
    name: "home",
    meta: {
      title: "Career@Argusoft",
    },
  },
  {
    path: "/401",
    component: _401page,
    name: "401",
    meta: {
      title: "Access Denied",
    },
  },
  {
    path: "/403",
    component: _403page,
    name: "403",
    meta: {
      title: "Access Forbidden",
    },
  },
  {
    path: "/application-form",
    component: _applicationForm,
    name: "applicationForm",
    meta: {
      title: "Apply for Job",
    },
  },
  {
    path: "/job-profile/:jobId",
    component: _jobDescription,
    name: "jobProfile",
    meta: {
      title: "Job Profile",
    },
  },
  {
    path: "/job-openings",
    component: _jobOpenings,
    name: "jobOpenings",
    meta: {
      title: "Current Openings",
    },
  },
  {
    path: "/login",
    component: _login,
    name: "login",
    meta: {
      title: "Login",
    },
    beforeEnter: (to, from, next) => {
      if (VueCookie.get("user")) {
        window.location = config.BASE_URL + "dashboard";
      } else if (VueCookie.get("RECRUITER_ACCESS_TOKEN")) {
        window.location = config.BASE_URL + "recruiter/dashboard";
        next();
      } else if (VueCookie.get("APPLICANT_ACCESS_TOKEN")) {
        window.location = config.BASE_URL + "applicant/dashboard";
        next();
      }
      next();
    },
  },
  {
    path: "register",
    component: _registrationForm,
    name: "applicantRegistration",
    meta: {
      title: "Applicant Registration",
      credRequired: false,
      user: "applicant",
    },
    beforeEnter: (to, from, next) => {
      if (VueCookie.get("APPLICANT_ACCESS_TOKEN")) {
        window.location = config.BASE_URL + "applicant/dashboard";
        next();
      }
      next();
    },
  },
  {
    path: "applicant/forgot-password",
    component: _forgotPasswordRequest,
    name: "applicantForgotPasswordRequest",
    meta: {
      title: "Forgot Password",
      credRequired: false,
      user: "applicant",
    },
  },
  {
    path: "applicant/reset-password/",
    component: _applicantForgotPassword,
    name: "applicantForgotPassword",
    meta: {
      title: "Reset Password",
      credRequired: false,
      user: "applicant",
    },
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: "recruiter/reset-password",
    component: _recruiterResetPassword,
    name: "recruiterResetPassword",
    meta: {
      title: "Reset Password",
      credRequired: false,
      user: "recruiter",
    },
  },
  {
    path: "recruiter/reset-password/:resetPasswordId",
    component: _resetRecruiterPassword,
    props: true,
    name: "resetRecruiterPassword",
    meta: {
      title: "Password Creation",
      user: "recruiter",
      credRequired: false,
    },
  },
];
