import PERMISSIONS from "../Common/permissions";
import _managereview from "./index";

export default {
  path: "reviews",
  component: _managereview,
  name: "managereviews",
  meta: {
    title: "Manage Reviews",
    user: "employee",
    permissions: [PERMISSIONS.REVIEW.MENU],
  },
};
