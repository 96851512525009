/**
 * @memberOf module:ResolveDuplicate.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of matched applicants to be get
 * @description commits data of matched applicants
 */
const FETCH_MATCHED_APPLICANTS = (state, data) => {
  state.matchedApplicantsData = data;
};

/**
 * @memberOf module:ResolveDuplicate.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of new applicant to be get
 * @description commits data of new applicant
 */
const FETCH_NEW_APPLICANT_DATA = (state, data) => {
  state.newApplicantData = data;
};

/**
 * @memberOf module:ResolveDuplicate.store.mutations
 * @param {Object} state holds store properties for current module
 * @param {Object} data Data of confirm duplicate status to be get
 * @description commits data of Job categories and Jobs
 */
const CONFIRM_DUPLICATE_STATUS = (state, data) => {
  state.confirmDuplicateStatus = data;
};

/**
 * @memberOf module:ResolveDuplicate
 * @namespace store.mutations
 * @description mutations for resolve_duplicate module
 */
export default {
  FETCH_MATCHED_APPLICANTS,
  FETCH_NEW_APPLICANT_DATA,
  CONFIRM_DUPLICATE_STATUS,
};
