import { addSnackbarMessage } from "../../Common/_plugin/error-response";
import api from "../api";

/**
 * @memberOf module:EditApplicantData.store.actions
 * @param {Object} context store context
 * @param {Object} slug slug to be sent
 * @description get data of user from server
 */
const getApplicantData = (context, slug) => {
  api
    .getApplicantData(slug)
    .then((res) => {
      const data = res.data;
      context.commit("SET_APPLICANT_DATA_TO_FORM", data);
    })
    .catch((error) => {
      addSnackbarMessage(
        error,
        "Problem retrieving Matched Applicants  Data!",
        true
      );
    });
};

/**
 * @memberOf module:Edit Applicant Data.store.actions
 * @param {Object} context store context
 * @param {Object} payload Job Application data to be sent
 * @description sends data of applicant to server
 */
const postUserdata = (context, payload) => {
  api
    .postUserdata(payload)
    .then(() => {
      const data = {
        status: "SUCCESS",
      };
      context.commit("APPLICANT_DATA_EDITED", data);
    })
    .catch((error) => {
      context.commit("APPLICANT_DATA_EDITED", {
        status: "FAILURE",
        error_data: error,
        uid: null,
      });
    });
};

/**
 * @memberOf module:Edit Applicant Data.store.actions
 * @param {Object} context store context
 * @param {Object} payload resume to upload
 * @description sends new resume to server to upload
 */
const uploadNewResume = (context, userData) => {
  api.uploadNewResume(userData).then((res) => {
    context.commit("SET_NEW_RESUME_DATA", res.data.data.resume);
  });
};

export default {
  getApplicantData,
  postUserdata,
  uploadNewResume,
};
