<template>
  <div>
    <div
      class="d-flex flex-row justify-content-between align-items-center shadow-sm p-2 rounded-2 hover"
    >
      <!-- Input / Title -->
      <div
        class="flex-grow-1 cursor-pointer"
        v-b-toggle="editMode ? '' : String(fileTemplate.id)"
      >
        <input
          v-if="editMode"
          class="form-control"
          placeholder="Title"
          v-model="editTitle"
        />
        <h5 v-else class="mb-0 font-monospace lh-base">
          {{ fileTemplate.file_name }}
        </h5>
      </div>
      <!-- Action Buttons -->
      <div>
        <template v-if="editMode">
          <template>
            <button class="btn btn-sm" v-b-tooltip="'Save'" @click="onSave">
              <b-icon icon="check-circle" variant="success"></b-icon>
            </button>
            <span>|</span>
            <button class="btn btn-sm" v-b-tooltip="'Cancel'" @click="onCancel">
              <b-icon icon="x-circle" variant="danger"></b-icon>
            </button>
          </template>
        </template>
        <template v-else>
          <div class="d-none d-md-inline">
            <template>
              <button
                class="btn btn-sm"
                v-b-tooltip="'View'"
                @click="handleTemplateView"
              >
                <b-icon icon="eye-fill" variant="secondary"></b-icon>
              </button>
              <button
                class="btn btn-sm"
                v-b-tooltip="'Edit name'"
                v-if="
                  hasAccess({
                    permissions: [PERMISSIONS['FILE-TEMPLATE'].UPDATE],
                    checkAll: true,
                  })
                "
                @click="onEdit"
              >
                <b-icon icon="pencil-square" variant="primary"></b-icon>
              </button>
              <button
                class="btn btn-sm"
                v-b-tooltip="'Download File'"
                v-if="
                  hasAccess({
                    permissions: [PERMISSIONS['FILE-TEMPLATE'].MENU],
                    checkAll: true,
                  })
                "
                @click="handleDownloadButton"
              >
                <b-icon icon="download" variant="info"></b-icon>
              </button>
            </template>
            <button
              v-if="
                hasAccess({
                  permissions: [PERMISSIONS['FILE-TEMPLATE'].DELETE],
                  checkAll: true,
                })
              "
              class="btn btn-sm"
              v-b-tooltip="'Delete'"
              @click="onDelete"
            >
              <b-icon icon="trash" variant="danger"></b-icon>
            </button>
          </div>
          <button
            type="button"
            class="btn btn-custom dropdown-toggle d-md-none"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <b-icon icon="three-dots-vertical" style="color: #8f1452"></b-icon>
          </button>
          <div class="dropdown-menu d-md-none">
            <a class="dropdown-item cursor" @click="handleTemplateView">
              <b-icon class="mr-2" icon="eye-fill" variant="secondary"></b-icon>
              View
            </a>
            <a
              class="dropdown-item cursor"
              v-if="
                hasAccess({
                  permissions: [PERMISSIONS['FILE-TEMPLATE'].UPDATE],
                  checkAll: true,
                })
              "
              @click="onEdit"
            >
              <b-icon
                class="mr-2"
                icon="pencil-square"
                variant="primary"
              ></b-icon>
              Edit Name
            </a>
            <a
              class="dropdown-item cursor"
              v-if="
                hasAccess({
                  permissions: [PERMISSIONS['FILE-TEMPLATE'].MENU],
                  checkAll: true,
                })
              "
              @click="handleDownloadButton"
            >
              <b-icon class="mr-2" icon="download" variant="info"></b-icon>
              Download File
            </a>
            <a
              class="dropdown-item cursor"
              v-if="
                hasAccess({
                  permissions: [PERMISSIONS['FILE-TEMPLATE'].DELETE],
                  checkAll: true,
                })
              "
              @click="onDelete"
            >
              <b-icon class="mr-2" icon="trash" variant="danger"></b-icon>
              Delete
            </a>
          </div>
        </template>
      </div>
    </div>
    <b-collapse
      :id="String(fileTemplate.id)"
      class="mt-2"
      accordion="template-master"
      role="tabpanel"
    >
      <b-card class="custom-card">
        <div class="d-flex flex-row justify-content-between align-items-center">
          <span><strong>Id:</strong> {{ fileTemplate.id }}</span>
          <div>
            <router-link
              class="btn text-primary"
              v-if="
                hasAccess({
                  permissions: [PERMISSIONS['FILE-TEMPLATE'].UPDATE],
                  checkAll: true,
                })
              "
              :to="{ name: 'edit-template', params: { id: fileTemplate.id } }"
            >
              <b-icon
                icon="pencil"
                variant="primary"
                class="small-icon"
              ></b-icon>
              Edit template
            </router-link>
          </div>
        </div>
        <div class="mt-3">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Variable</th>
                <th scope="col">Type</th>
                <th scope="col">Input Method</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(field, index) in formData" :key="index">
                <td>{{ field.name }}</td>
                <td>{{ field.type }}</td>
                <td>{{ field.inputType }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>
    </b-collapse>
    <template-preview-modal
      v-if="viewTemplate"
      @closeModal="closeModal"
      :formData="formData"
      modalId="preview-template"
      :pdfData="pdfData"
      :title="fileTemplate.file_name"
      :isTemplateOnly="true"
    />
    <document-test-modal
      v-if="download"
      @closeModal="download = false"
      modalId="downlaod-file"
      :id="fileTemplate.id"
    />
  </div>
</template>

<script>
import templatePreviewModal from "./template-preview-modal.vue";
import documentTestModal from "./document-test-modal.vue";
import { mapGetters } from "vuex";
import api from "../api";

export default {
  components: {
    templatePreviewModal,
    documentTestModal,
  },
  props: {
    fileTemplate: {
      type: Object,
      required: true,
    },
    PERMISSIONS: {
      type: Object,
      required: true,
      default: () => {},
    },
    hasAccess: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      editMode: false,
      editStatus: "A",
      editTitle: "",
      download: false,
      formData: [],
      viewTemplate: false,
      pdfData: null,
    };
  },

  methods: {
    onEdit() {
      this.editMode = true;
      this.editTitle = this.fileTemplate.file_name;
    },
    onSave() {
      this.fileTemplate.updated_by = this.loggedInUser.user_id;
      this.$store.dispatch("$_file_template/updateTemplateMaster", {
        ...this.fileTemplate,
        file_name: this.editTitle,
      });
      this.editMode = false;
    },
    onCancel() {
      this.editMode = false;
    },
    onDelete() {
      this.$emit("deleteTemplate");
    },
    openModal() {
      this.viewTemplate = true;
    },
    closeModal() {
      this.viewTemplate = false;
    },
    handleDownloadButton() {
      this.download = true;
    },
    async handleTemplateView() {
      try {
        const res = await api.fetchTemplateById(this.fileTemplate.id);
        const pdfBase64 = res.data.pdfBase64;
        const binary = atob(pdfBase64);
        const len = binary.length;
        const buffer = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          buffer[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([buffer], { type: "application/pdf" });
        this.pdfData = URL.createObjectURL(blob);
        this.viewTemplate = true;
      } catch (error) {
        console.error("Error:", error);
        this.viewTemplate = false;
      }
    },
  },
  computed: {
    statusColor() {
      return this.fileTemplate.status === "A" ? "success" : "danger";
    },
    statusTooltip() {
      return this.fileTemplate.status === "A"
        ? "Click to disable"
        : "Click to enable";
    },
    ...mapGetters({
      loggedInUser: "user/loggedInUser",
    }),
  },
  beforeMount() {
    if (this.fileTemplate) {
      this.formData = [
        ...this.fileTemplate.required_fields.single_input_fields.map(
          (field) => ({ ...field, inputType: "single" })
        ),
        ...this.fileTemplate.required_fields.multi_input_fields.map(
          (field) => ({ ...field, inputType: "multiple" })
        ),
      ];
    }
  },
};
</script>

<style scoped>
.hover:hover {
  background: rgba(138, 233, 250, 0.2);
  transition: all ease-out 200ms;
}
.btn-custom.dropdown-toggle::after {
  display: none;
}
.cursor {
  cursor: pointer;
}
.custom-card {
  border-top: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.small-icon {
  font-size: 1rem !important;
  margin-bottom: 1px;
}
</style>
