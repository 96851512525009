module.exports = {
  MAILING_FACILITY_PROCESS: {
    key: "MAILING_FACILITY_PROCESS",
    desc: "On true the mailing feature will be enabled throughout the website",
    default: ["false"],
    type: "boolean",
    options: ["true", "false"],
    isSingleValue: true,
    is_active: false,
  },
  DEVS_MAILING_LIST: {
    key: "DEVS_MAILING_LIST",
    desc: `Provide the list of email id's to be used as recipient for mailing facility`,
    default: [],
    type: "string",
    options: [],
    isSingleValue: false,
    is_active: false,
  },
  REVIEWERS_COUNT: {
    key: "REVIEWERS_COUNT",
    desc: "Provide the count of reviews needed to make a decision over the candidate",
    default: ["1"],
    type: "integer",
    options: ["1", "2", "3"],
    isSingleValue: true,
    is_active: false,
  },
  FRESHER_OPENING: {
    key: "FRESHER_OPENING",
    desc: "At true, positions with freshers as requirement will be visible to everyone visiting the website",
    default: ["false"],
    type: "boolean",
    options: ["true", "false"],
    isSingleValue: true,
    is_active: false,
  },
  PROFESSIONAL_OPENING: {
    key: "PROFESSIONAL_OPENING",
    desc: "At true, positions which allows experienced professional as candidate will be visible to everyone",
    default: ["false"],
    type: "boolean",
    options: ["true", "false"],
    isSingleValue: true,
    is_active: false,
  },
  SKILL_SET: {
    key: "SKILL_SET",
    desc: "This list acts as an pre-options for candidates to select their skillset",
    default: [],
    type: "string",
    options: [
      "JavaScript",
      "Python",
      "Java",
      "C#",
      "C++",
      "Go",
      "React.js",
      "Angular",
      "Vue.js",
      "Node.js",
      "AWS",
      "Azure",
      "GCP",
      "serverless computing",
      "MySQL",
      "PostgreSQL",
      "MongoDB",
      "Redis",
    ],
    isSingleValue: false,
    is_active: false,
  },

  APPLICATION_PROFILE_PIC: {
    isChild: true,
    key: "APPLICATION_PROFILE_PIC",
    desc: "Provide the folder id to store profile pictures from applications",
    default: ["1e8GiAap_6a5vNvJYht5SUg_kLBr73AKD"],
    type: "string",
    isSingleValue: true,
    isInput: true,
    is_active: true,
  },
  APPLICATION_RESUME: {
    isChild: true,
    key: "APPLICATION_RESUME",
    desc: "Provide the folder id to store resumes from applications",
    default: ["175I6wywwfhDNJF0v6nhL01SXUC0kvpKG"],
    type: "string",
    isSingleValue: true,
    isInput: true,
    is_active: true,
  },
  APPLICANT_MASTER_PROFILE_PIC: {
    isChild: true,
    key: "APPLICANT_MASTER_PROFILE_PIC",
    desc: "Provide the folder id to store profile pictures from applicant profiles",
    default: ["1ly4IjxaY3fa-RBOGqfXmy3fiB5X790Dd"],
    type: "string",
    isSingleValue: true,
    isInput: true,
    is_active: true,
  },
  APPLICANT_MASTER_RESUME: {
    isChild: true,
    key: "APPLICANT_MASTER_RESUME",
    desc: "Provide the folder id to store resumes from applicant profiles",
    default: ["1o5miSyACUg4K-tOAl87bu6O6KPeHPN0V"],
    type: "string",
    isSingleValue: true,
    isInput: true,
    is_active: true,
  },
  APPLICANT_MASTER_10TH_MARKSHEET: {
    isChild: true,
    key: "APPLICANT_MASTER_10TH_MARKSHEET",
    desc: "Provide the folder id to store 10th marksheets from applicant profiles",
    default: ["1QqdLuydWCfMtmzZ89OKl4kq6FSOrCX3E"],
    type: "string",
    isSingleValue: true,
    isInput: true,
    is_active: true,
  },
  APPLICANT_MASTER_12TH_MARKSHEET: {
    isChild: true,
    key: "APPLICANT_MASTER_12TH_MARKSHEET",
    desc: "Provide the folder id to store 12th marksheets from applicant profiles",
    default: ["1VO_jWlBv5NEw2EeT7CmisOkTZQ32d4qs"],
    type: "string",
    isSingleValue: true,
    isInput: true,
    is_active: true,
  },
  FILE_TEMPLATE_MASTER: {
    isChild: true,
    key: "FILE_TEMPLATE_MASTER",
    desc: "Provide the folder id to store file templates",
    default: ["1o5miSyACUg4K-tOAl87bu6O6KPeHPN0V"], //currently using resume folder id
    type: "string",
    isSingleValue: true,
    isInput: true,
    is_active: true,
  },
};
