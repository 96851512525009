<template>
  <div class="modal fade" id="systemConfigModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content m-t100">
        <div class="modal-header">
          <h3 class="modal-title">Update Configuration</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="resetValidations($v)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ws-normal">
          <form v-if="data.isInput" ass="content-form">
            <div class="form-group">
              <label class="control-label" for="keyInput"
                >Key<span class="text-danger"> *</span></label
              >
              <input
                disabled
                type="text"
                id="keyInput"
                class="form-control"
                v-model="data.key"
              />
            </div>
            <div class="form-group">
              <div>
                <label class="control-label" for="values"
                  >Values <span class="text-danger"> *</span></label
                >
                <input type="text" class="form-control" v-model="data.value" />
              </div>
            </div>
          </form>
          <form v-else class="content-form">
            <div class="form-group">
              <label class="control-label" for="keyInput"
                >Key<span class="text-danger"> *</span></label
              >
              <input
                disabled
                type="text"
                id="keyInput"
                class="form-control"
                v-model="data.key"
              />
            </div>
            <div class="form-group">
              <div>
                <label class="control-label" for="values"
                  >Values <span class="text-danger"> *</span></label
                >
                <multiselect
                  id="values"
                  :searchable="false"
                  :show-labels="false"
                  :multiple="!isSingleValue"
                  v-model="data.value"
                  :options="data.options || []"
                ></multiselect>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label" for="inpuType"
                >Type <span class="text-danger"> *</span></label
              >
              <input
                disabled
                type="text"
                id="inpuType"
                class="form-control"
                v-model="data.type"
              />
            </div>

            <div class="form-group">
              <TagsInput
                :isRequired="false"
                id="systemConfigOptions"
                label="Options"
                labelClasses="text-left"
                :setValues="data.options ? data.options : []"
                @valuesUpdated="updateOptionValues($event)"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer text-center">
          <button
            :disabled="$v.data.$invalid"
            type="button"
            class="btn btn-cp"
            @click="submitSystemConfig"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import TagsInput from "../../Common/_components/tags-input-v2.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    TagsInput,
    Multiselect,
  },
  data() {
    return {
      doRender: true,
      data: {
        key: "",
        value: [],
        options: [],
        type: "",
      },
      isSingleValue: false,
    };
  },
  props: {
    selectedSystemConfig: { type: Object },
  },

  validations() {
    const data = {
      key: {
        required,
      },
      value: {
        required,
      },
    };
    return {
      data,
    };
  },

  methods: {
    updateOptionValues(data) {
      if (data.values?.length) {
        this.data.options = data.values;
        this.data.value = this.data.value?.filter((val) => {
          return this.data.options.includes(val);
        });
      }
    },
    prepareEditData(selectedSystemConfig) {
      if (selectedSystemConfig) {
        this.data = {
          id: selectedSystemConfig?.id,
          key: selectedSystemConfig.key,
          value: selectedSystemConfig.value,
          options: selectedSystemConfig.options,
          type: selectedSystemConfig.type,
          isInput: selectedSystemConfig.isInput,
        };
        this.isSingleValue = selectedSystemConfig.isSingleValue;

        if (this.data.isInput) {
          this.data.is_active = true;
        }
      }
    },
    resetData() {
      this.data = {
        key: "",
        value: [],
        options: [],
      };
    },

    submitSystemConfig() {
      const callback = () => {
        this.$emit("updatedConfig");
        $("#systemConfigModal").modal("hide");
      };
      const newValueArray = Array.isArray(this.data.value)
        ? this.data.value
        : [this.data.value];
      this.data.value = newValueArray;

      delete this.data.isInput;

      if (this.data.id) {
        this.$store.dispatch("system_config/updateSystemConfig", {
          data: this.data,
          callback,
        });
      } else {
        this.$store.dispatch("system_config/createSystemConfig", {
          data: this.data,
          callback,
        });
      }
    },
    resetValidations(v) {
      v.$reset();
    },
  },
};
</script>

<style scoped>
.ws-normal {
  white-space: normal;
}
</style>
<style>
.bootstrap-tagsinput {
  width: 100%;
  min-height: 32px;
}
</style>
