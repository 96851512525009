<template>
  <home></home>
</template>

<script>
import Home from "./_components/new-home.vue";

/**
 * @module ManageJob
 * @description manage job module
 */
export default {
  /**
   * @memberof module:ManageJob
   * @namespace components
   * @description components of manage job module
   */
  components: {
    Home,
  },
};
</script>
