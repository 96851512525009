<template>
  <div class="mt-2">
    <div class="well section-head w-auto">
      <div class="row mx-0">
        <div class="col-5 px-0">
          <h4>
            <strong>Follow Up</strong>
          </h4>
        </div>
        <div class="col-7 px-0">
          <button
            class="btn btn-cp float-right"
            type="button"
            data-toggle="modal"
            data-target="#addFollowUp"
            @click="$refs.followUpModal.resetFollowUp()"
            v-if="
              hasAccess({
                permissions: [PERMISSIONS.APPLICANT.FOLLOWUP.CREATE],
              })
            "
          >
            <i class="fa fa-plus fa-lg" aria-hidden="true"></i>
            <strong>New Follow Up</strong>
          </button>
        </div>
      </div>
    </div>
    <div class="p-0 mx-3 mt-2">
      <div v-if="followUps.length > 0" class="well container-followup mt-0">
        <div class="table-responsive">
          <table
            class="table table-condensed"
            aria-describedby="followupStatus"
          >
            <thead>
              <th>Created Date</th>
              <th>Followup Status</th>
              <th>Remarks</th>
              <th>Next Scheduled FollowUp</th>
              <th>Status</th>
            </thead>
            <tbody>
              <tr v-for="(followUp, index) in followUps" :key="index">
                <td>{{ followUp.createdAt }}</td>
                <td>{{ followUp.status }}</td>
                <td>{{ followUp.remarks }}</td>
                <td>{{ followUp.schedule }}</td>
                <td>
                  {{ followUp.active_status === 0 ? "Pending" : "Completed" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <add-follow-up-modal
      ref="followUpModal"
      :applicantData="applicantInfoData"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import AddFollowUpModal from "./add-follow-up-modal.vue";
import { hasAccess } from "../../User/_utils/actions";
import PERMISSIONS from "../../Common/permissions";

export default {
  data() {
    return {
      followUps: [],
    };
  },
  components: {
    AddFollowUpModal,
  },
  computed: {
    PERMISSIONS() {
      return PERMISSIONS;
    },
    ...mapGetters({
      applicantInfoData: "$_applicant_profile/fetchApplicantData",
      followUpList: "$_applicant_profile/getFollowUpDetails",
    }),
  },
  watch: {
    applicantInfoData(value) {
      this.getFollowups(value);
    },

    followUpList(value) {
      if (value) {
        value.forEach((val) => {
          val.createdAt = this.formatDate(val.createdAt);
          if (val.schedule) val.schedule = this.formatDate(val.schedule);
        });
        this.followUps = value;
      }
    },
  },
  methods: {
    getFollowups(value) {
      this.$store.dispatch("$_applicant_profile/getFollowUpDetails", value.id);
    },
    hasAccess({ permissions, checkAll }) {
      return hasAccess({ permissions, checkAll });
    },
    formatDate(date) {
      return moment(date).format("llll");
    },
  },
};
</script>

<style scoped>
h4 {
  padding-top: 5px;
}

i {
  font-size: 18px;
}

@media (max-width: 576px) {
  .well {
    border-radius: 0px;
  }
}

.container-followup {
  padding: 10px;
  margin-bottom: 15px;
}
</style>
